import { useContext } from "react";

import { useSdk } from "./useSdk";
import { getEthereumAccountType, CONTRACT_ACCOUNT } from "../utils/walletUtils";
import { CurrentWeb3Context } from "../reducers/CurrentWeb3Provider";
import { identifier } from "../constants/identifier";
import { Web3Provider } from "@ethersproject/providers";
import { ProposalType } from "../types";

export const useCastVote = (
  cname: string,
  proposalId: string,
  adapterInstance: string,
  power: number,
  proposalRefId: string,
  web3?: Web3Provider,
  type?: ProposalType,
  isPrivate?: boolean,
) => {
  const sdk = useSdk();
  const { web3Instance } = useContext(CurrentWeb3Context);

  let isContractWallet = false;
  if (web3Instance) {
    const accountType = getEthereumAccountType(web3Instance);
    if (accountType === CONTRACT_ACCOUNT) {
      isContractWallet = true;
    }
  }
  if (!cname || !proposalId) {
    return { castVote: null };
  }
  return {
    castVote: async (choice: any) => {
      return (
        (await sdk
          ?.getProtocol(cname)
          .adapter("vote", adapterInstance)
          .castVote({
            proposalId,
            choice,
            power,
            proposalRefId,
            identifier,
            isContractWallet,
            web3,
            type: type as any,
            isPrivate,
          })) ?? null
      );
    },
  };
};
