import { useQuery } from "@tanstack/react-query";
import { apiClient } from "../utils/apiClient";
import { GetDelegatorCountResponse } from "@boardroom/boardroom-api";
import { getProtocolAdapterInstances } from "../constants/protocols";

export const useDelegatorCount = ({ address, protocol }: { address: string; protocol: string }) => {
  const adapterInstances = getProtocolAdapterInstances({ adapter: "delegation", protocol });
  const { data } = useQuery<GetDelegatorCountResponse, Error>(
    [`delegatorCount:${address}:${protocol}`],
    () =>
      apiClient.getDelegatorCount(address, {
        protocol,
        address,
        adapters: JSON.stringify(adapterInstances) as any as string[],
      }),
    {
      suspense: false,
      enabled: !!address && !!protocol,
    },
  );

  return { delegatorCount: data?.data };
};
