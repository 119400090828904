import { getAddress } from "ethers/lib/utils";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router";

import { protocols } from "../../constants/protocols";
import { useMixpanel } from "../../hooks";
import { useDelegatePosts } from "../../hooks/useDelegatePosts";
import { useGetEns, useGetEnsMultipleAddresses } from "../../hooks/useEns";
import { useGetTeam } from "../../hooks/useGetTeam";
import { CurrentAccountContext } from "../../reducers/CurrentAccount";
import { CurrentUserDetailsContext } from "../../reducers/CurrentUserDetails";
import { toShortAddress } from "../../utils";
import { isTeamView } from "../../utils/teamUtils";
import { PostModal } from "../PostModal/PostModal";
import ProtocolIcon from "../ProtocolIcon";
import {
  Circle,
  DropdownWrapper,
  FilterByText,
  PostButton,
  PostButtonWrapper,
  ProtocolName,
  ProtocolsSectionTitle,
  ProtocolsWrapper,
  ProtocolWrapper,
  WrapperProtocolAndCircle,
} from "./styles";
import { ProjectsListingIcon } from "../icons";
import { AlternateDropdown } from "../AlternateDropdown/AlternateDropdown";
import { ProtocolIconDiv } from "../NavRail/styles";
import { useProtocolIcon } from "../../hooks/useProtocolIcon";
import { isAddress } from "web3-utils";

interface Props {
  protocols: string[];
  delegateForProtocols: string[];
  selectedProtocol: string;
  addressBeingUsed: string;
  setSelectedProtocol: React.Dispatch<React.SetStateAction<string>>;
  showPostButton: boolean;
  updateAddressToUse: any;
  delegatedToYouProtocols: string[];
  delegatedToOthersProtocols: string[];
  undelegatedProtocols: string[];
}

const Protocol = ({
  protocol,
  isSelected,
  setSelectedProtocol,
  updateAddressToUse,
}: {
  protocol: string;
  isSelected: boolean;
  setSelectedProtocol: React.Dispatch<React.SetStateAction<string>>;
  updateAddressToUse: any;
}) => {
  const { search } = useLocation();
  const { account } = useContext(CurrentAccountContext);
  const { trackClickSelectProtocolDelegationTab } = useMixpanel();
  const protocolInfo = protocols[protocol];
  const onClick = useCallback(() => {
    trackClickSelectProtocolDelegationTab({
      protocol,
      userId: account,
    });
    setSelectedProtocol(protocol);
    const addressToSet = updateAddressToUse(protocol);
    window.history.pushState(null, "", `/people/${protocol}/${addressToSet}${search}`);
  }, [protocol, setSelectedProtocol, account, trackClickSelectProtocolDelegationTab, updateAddressToUse, search]);
  return (
    <WrapperProtocolAndCircle>
      {isSelected && <Circle />}
      <ProtocolWrapper onClick={onClick} $isSelected={isSelected}>
        <ProtocolIcon size="small" protocol={protocolInfo} />
        <ProtocolName>{protocolInfo?.name}</ProtocolName>
      </ProtocolWrapper>
    </WrapperProtocolAndCircle>
  );
};

const DelegateHub = ({
  protocols,
  selectedProtocol,
  setSelectedProtocol,
  showPostButton,
  delegateForProtocols,
  updateAddressToUse,
  addressBeingUsed,
  delegatedToYouProtocols,
  delegatedToOthersProtocols,
  undelegatedProtocols,
}: Props) => {
  const { search, pathname } = useLocation();
  const history = useHistory();

  const { account } = useContext(CurrentAccountContext);
  const { trackClickPostButtonDelegationTab, trackSwitchTeamWalletOnDelegateTab } = useMixpanel();
  const [postModalOpen, setPostModalOpen] = useState(false);

  const query = new URLSearchParams(search);
  const teamId = query.get("bundle");
  const teamWallet = query.get("bundleWallet");
  const teamDetails = useGetTeam(teamId || "");

  const teamWallets = teamDetails?.orgWallets;
  const addressesWithEns = useGetEnsMultipleAddresses({ addresses: teamWallets });
  const teamWalletEns = useGetEns(teamWallet || "", undefined, true);
  const isOnTeamView = isTeamView(pathname, search);
  const { userDetails } = useContext(CurrentUserDetailsContext);
  const orgMemberships = userDetails?.orgMemberships;
  const { refetch } = useDelegatePosts({
    author: addressBeingUsed,
    protocol: selectedProtocol,
    byPassCache: true,
    suspense: false,
  });

  const icon = useProtocolIcon({ protocolCname: selectedProtocol });

  useEffect(() => {
    if (!selectedProtocol) {
      setSelectedProtocol(protocols[0]);
      const addressToSet = updateAddressToUse(protocols[0]);
      window.history.pushState(null, "", `/people/${protocols[0]}/${addressToSet}${search}`);
    }
  }, [protocols, setSelectedProtocol, selectedProtocol, updateAddressToUse, search]);

  useEffect(() => {
    if (!addressBeingUsed) {
      const addressToSet = updateAddressToUse(selectedProtocol || protocols[0]);
      window.history.pushState(null, "", `/people/${selectedProtocol || protocols[0]}/${addressToSet}${search}`);
    }
  }, [addressBeingUsed, selectedProtocol, updateAddressToUse, protocols, search]);

  const OnPostClick = useCallback(() => {
    trackClickPostButtonDelegationTab({
      userId: account,
    });
    setPostModalOpen(true);
  }, [account, trackClickPostButtonDelegationTab]);

  const onClose = useCallback(() => {
    setPostModalOpen(false);
  }, []);

  const dropdownItems = useMemo(() => {
    const items: {
      name: string;
      value: string;
    }[] = [];

    if (!teamWallets) {
      return items;
    }
    const addressItems = teamWallets.map((address: string, index: number) => ({
      name:
        address === teamWallet
          ? teamWalletEns || toShortAddress(isAddress(address) ? getAddress(address) : address) || address
          : addressesWithEns?.[index] || toShortAddress(isAddress(address) ? getAddress(address) : address) || address,
      value: isAddress(address) ? getAddress(address) : address,
    }));

    return [...items, ...addressItems];
  }, [teamWallets, teamWallet, teamWalletEns, addressesWithEns]);

  const handleDropdownValueChange = useCallback(
    (item: any) => {
      history.push({
        search: search.includes("&bundleWallet=")
          ? search.split("&bundleWallet=")[0] + `&bundleWallet=${item.value}`
          : search + `&bundleWallet=${item.value}`,
      });
      setSelectedProtocol("your-projects");
      trackSwitchTeamWalletOnDelegateTab({
        userId: account,
      });
    },
    [history, search, account, trackSwitchTeamWalletOnDelegateTab, setSelectedProtocol],
  );

  const teamWalletItem = teamWallet
    ? {
        name:
          teamWalletEns || toShortAddress(isAddress(teamWallet) ? getAddress(teamWallet) : teamWallet) || teamWallet,
        value: isAddress(teamWallet) ? getAddress(teamWallet) : teamWallet,
      }
    : null;

  const onClick = useCallback(() => {
    setSelectedProtocol("your-projects");
    window.history.pushState(null, "", `/people/your-projects/${search}`);
  }, [setSelectedProtocol, search]);

  return (
    <>
      <ProtocolIconDiv style={{ zIndex: 2, filter: "saturate(0)" }} $url={icon?.url} />
      {isOnTeamView && !!orgMemberships && orgMemberships === teamId && dropdownItems?.length > 1 && (
        <DropdownWrapper style={{ margin: "0 0 0", padding: "0 8px 16px" }}>
          <FilterByText>Filter Team View by</FilterByText>
          <AlternateDropdown
            label={null}
            placeholder=""
            initialValue={teamWalletItem || dropdownItems[0]}
            items={dropdownItems}
            onChange={handleDropdownValueChange}
          />
        </DropdownWrapper>
      )}

      <ProtocolsWrapper>
        <WrapperProtocolAndCircle style={{ marginBottom: "8px" }}>
          {selectedProtocol === "your-projects" && <Circle />}
          <ProtocolWrapper onClick={onClick} $isSelected={selectedProtocol === "your-projects"}>
            <ProjectsListingIcon width={30} height={30} color="#4235e1" />
            <ProtocolName>Your Projects</ProtocolName>
          </ProtocolWrapper>
        </WrapperProtocolAndCircle>
        {!!delegatedToYouProtocols?.length && <ProtocolsSectionTitle>Delegated To You</ProtocolsSectionTitle>}
        {delegatedToYouProtocols?.map((protocol) => (
          <Protocol
            key={protocol}
            protocol={protocol}
            isSelected={protocol === selectedProtocol}
            setSelectedProtocol={setSelectedProtocol}
            updateAddressToUse={updateAddressToUse}
          />
        ))}
        {!!delegatedToOthersProtocols?.length && <ProtocolsSectionTitle>Delegated</ProtocolsSectionTitle>}
        {delegatedToOthersProtocols?.map((protocol) => (
          <Protocol
            key={protocol}
            protocol={protocol}
            isSelected={protocol === selectedProtocol}
            setSelectedProtocol={setSelectedProtocol}
            updateAddressToUse={updateAddressToUse}
          />
        ))}
        {!!undelegatedProtocols?.length && <ProtocolsSectionTitle>Undelegated</ProtocolsSectionTitle>}
        {undelegatedProtocols?.map((protocol) => (
          <Protocol
            key={protocol}
            protocol={protocol}
            isSelected={protocol === selectedProtocol}
            setSelectedProtocol={setSelectedProtocol}
            updateAddressToUse={updateAddressToUse}
          />
        ))}
      </ProtocolsWrapper>
      {showPostButton && (
        <>
          <PostButtonWrapper style={{ bottom: isOnTeamView ? "48px" : "0" }}>
            <PostButton onClick={OnPostClick}>Post</PostButton>
          </PostButtonWrapper>
          <PostModal
            addressToPostTo={addressBeingUsed}
            delegateForProtocols={delegateForProtocols}
            open={postModalOpen}
            refetch={refetch}
            onClose={onClose}
            protocol={delegateForProtocols?.includes(selectedProtocol) ? selectedProtocol : delegateForProtocols[0]}
          />
        </>
      )}
    </>
  );
};

export { DelegateHub };
