import React, { useEffect } from "react";
import Col from "antd/es/col";
import Row from "antd/es/row";
import styled from "styled-components";

import { COLORS } from "../../constants/colors";
import ProtocolIcon from "../ProtocolIcon";
import { protocols } from "../../constants/protocols";
import { useVotePower } from "../../hooks/useVotePower";
import formatValue from "../../utils/formatValue";
import media from "../../media-query";
import useScore from "../../hooks/useScore";
import { useVotePowerFromApi } from "../../hooks/useVotePowerFromApi";

interface Props {
  protocol: string;
  address: string;
  setNullChildren?: React.Dispatch<React.SetStateAction<string[]>>;
}

const StyledVotePowerRow = styled(Row)`
  padding: 16px 24px;
  ${media.lessThan("991px")`
     padding: 16px 8px;
  `}
`;

const StyledName = styled.span`
  padding-left: 8px;
  font-weight: 500;
  align-items: center;
  display: flex;
`;

const StyledPowerValue = styled.span`
  display: block;
  color: ${COLORS.primary.grayDark};
  font-weight: 600;
`;

const StyledCol = styled(Col)`
  display: flex;
`;

const StyledMakerCol = styled(StyledCol)`
  justify-content: flex-end;
  display: flex;
  gap: 20px;
`;

const StyledProtocolIcon = styled(ProtocolIcon)`
  display: flex;
  align-self: center;
`;

const StyledPwrCol = styled(Col)`
  text-align: right;
`;

const StyledText = styled.div`
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  color: ${COLORS.primary.grayDarkLightest};
`;

const VotePowerRowMaker = (props: Props) => {
  const { protocol, address } = props;
  const { score: pollingVp } = useScore(protocol, address, undefined, "onchain-secondary");
  const { score: executiveVp } = useScore(protocol, address, undefined, "onchain");
  if (!pollingVp && !executiveVp) {
    return <></>;
  }

  return (
    <StyledVotePowerRow>
      <StyledCol span={16}>
        <StyledProtocolIcon protocol={protocols[protocol]} size="xsmall" />
        <StyledName>{protocols[protocol].name}</StyledName>
      </StyledCol>
      <StyledMakerCol span={8}>
        <div>
          <StyledPowerValue>{formatValue(pollingVp) || 0.0}</StyledPowerValue>
          <StyledText>Polling</StyledText>
        </div>
        <div>
          <StyledPowerValue>{formatValue(executiveVp) || 0.0}</StyledPowerValue>
          <StyledText>Exec.</StyledText>
        </div>
      </StyledMakerCol>
    </StyledVotePowerRow>
  );
};

function VotePowerRow(props: Props) {
  const { protocol, address, setNullChildren } = props;
  const { power, isLoading } = useVotePower(protocol, false, address);
  const votePowerHardCoded = useVotePowerFromApi({
    addresses: [address],
  }); // used to get hard coded vote power from api for  safedao
  const votePowerSafeDao = votePowerHardCoded?.find((vp) => vp?.protocol === "safedao")?.votePower;

  useEffect(() => {
    if (
      (!protocols[protocol] || typeof power !== "number" || formatValue(power, 1)?.toString() === "0") &&
      !isLoading &&
      !(votePowerSafeDao && protocol === "safedao")
    ) {
      setNullChildren && setNullChildren((prev) => Array.from(new Set([...prev, protocol])));
    } else {
      setNullChildren && setNullChildren((prev) => [...prev].filter((p) => p !== protocol));
    }
  }, [isLoading, power, protocol, setNullChildren, votePowerSafeDao]);

  if (!protocols[protocol]) {
    return null;
  }

  if (protocol === "makerdao") {
    return <VotePowerRowMaker {...props} />;
  }

  return (
    <>
      {(protocol !== "makerdao" && typeof power === "number" && formatValue(power, 1)?.toString() !== "0") ||
      (votePowerSafeDao && protocol === "safedao") ? (
        <StyledVotePowerRow>
          <StyledCol span={16}>
            <StyledProtocolIcon protocol={protocols[protocol]} size="xsmall" />
            <StyledName>{protocols[protocol].name}</StyledName>
          </StyledCol>
          <StyledPwrCol span={8}>
            <StyledPowerValue>
              {votePowerSafeDao && protocol === "safedao" ? formatValue(votePowerSafeDao, 1) : formatValue(power, 1)}
            </StyledPowerValue>
          </StyledPwrCol>
        </StyledVotePowerRow>
      ) : null}
    </>
  );
}

export default React.memo(VotePowerRow);
