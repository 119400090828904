import React, { useContext, useState, createContext, SetStateAction } from "react";

interface Props {
  children: React.ReactNode;
}

interface CurrentSplitViewIndexType {
  currentSplitViewIndex: number | undefined;
  setCurrentSplitViewIndex: React.Dispatch<SetStateAction<number | undefined>>;
}

export const CurrentSplitViewIndexContext = createContext({} as CurrentSplitViewIndexType);

export const CurrentSplitViewIndexProvider = ({ children }: Props) => {
  const [currentSplitViewIndex, setCurrentSplitViewIndex] = useState<number | undefined>();
  return (
    <CurrentSplitViewIndexContext.Provider value={{ currentSplitViewIndex, setCurrentSplitViewIndex }}>
      {children}
    </CurrentSplitViewIndexContext.Provider>
  );
};

export const useCurrentSplitViewIndex = () => {
  const { currentSplitViewIndex, setCurrentSplitViewIndex } = useContext(CurrentSplitViewIndexContext);
  return { currentSplitViewIndex, setCurrentSplitViewIndex };
};
