import React from "react";
import styled from "styled-components";

import { COLORS } from "../../constants/colors";

const AddAnotherCommandContainer = styled.div<{ $disabled?: boolean; $removeSpacing?: boolean }>`
  margin: 0;
  margin-bottom: 22px;
  display: flex;
  cursor: ${({ $disabled }) => ($disabled ? "not-allowed" : "pointer")};
  padding-bottom: 40px;
  border-bottom: 1px solid #f0eff8;
  ${({ $removeSpacing }) =>
    $removeSpacing && "margin-bottom: 0; padding-bottom: 0; border-bottom: none; margin-top: 24px"}
`;

const AddAnotherCommandText = styled("span")<{ $disabled?: boolean }>`
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  color: ${({ $disabled }) => ($disabled ? COLORS.primary.gray : COLORS.primary.grayDark)};
  margin-left: 8px;
`;

const PlusCircle = styled("div")<{ $disabled?: boolean }>`
  width: 24px;
  height: 24px;
  padding: 8px;
  font-size: 18px;
  border: 1px solid ${({ $disabled }) => ($disabled ? COLORS.primary.gray : COLORS.primary.accent)};
  border-radius: 100px;
  color: ${({ $disabled }) => ($disabled ? COLORS.primary.gray : COLORS.primary.accent)};
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface Props {
  disabled?: boolean;
  addAnotherCommandFunction: () => void;
  text: string;
  removeSpacing?: boolean;
}

const AddAnotherCommandButton = ({ disabled, addAnotherCommandFunction, text, removeSpacing }: Props) => {
  return (
    <AddAnotherCommandContainer $removeSpacing={removeSpacing} onClick={addAnotherCommandFunction} $disabled={disabled}>
      <PlusCircle $disabled={disabled}>+</PlusCircle>
      <AddAnotherCommandText $disabled={disabled}>{text}</AddAnotherCommandText>
    </AddAnotherCommandContainer>
  );
};

export default AddAnotherCommandButton;
