import React, { Suspense, useCallback, useContext, useEffect, useState } from "react";
import { protocolInfoList } from "@boardroom/protocol-info";
import { ProposalDetails, Voter } from "@boardroom/boardroom-api";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { useMixpanel } from "../../hooks";
import { SearchItem } from "../SearchItem";
import ProtocolIcon from "../ProtocolIcon";
import { CurrentAccountContext } from "../../reducers/CurrentAccount";
import { ProtocolDescription } from "../../types";
import { apiClient } from "../../utils/apiClient";
import { useProtocols } from "../../hooks/useProtocols";
import { CategoryBadge } from "../CategoryBadge";
import { Loader } from "../Loader";
import { useUserDetails } from "../../hooks/useUserDetails";
import { useGetEns } from "../../hooks/useEns";
import { GlobalSearchContext } from "../../reducers/GlobalSearch";
import { GlobalSearchMobileContext } from "../../reducers/GlobalSearchMobile";
import { formatEns } from "../../utils";
import { Pfp } from "../Pfp/Pfp";

export interface Id {
  id: string;
}

interface Props {
  onResultSelect?: Function;
  hit: Id & ProtocolDescription & Voter & ProposalDetails & { ens: string };
}

const ProtocolIconWrapper = ({ protocolCname }: { protocolCname: string }) => {
  const { protocols: protocolDetails } = useProtocols(true);
  return <ProtocolIcon protocol={protocolDetails[protocolCname]} />;
};

const ProtocolCategoryWrapper = ({ protocolCname }: { protocolCname: string }) => {
  const { protocols: protocolDetails } = useProtocols(true);
  return <CategoryBadge category={protocolDetails[protocolCname]?.categories?.[0] || "uncategorized"} />;
};

const UserPfpWrapper = ({ userAddress }: { userAddress: string }) => {
  const { user } = useUserDetails({ address: userAddress });
  return <Pfp size="medium" dimension={40} address={userAddress} pfpUrl={user?.pfpUrl} />;
};

const Hit = (props: Props) => {
  const [proposalState, setProposalState] = useState("");
  const { dispatchGlobalSearchState } = useContext(GlobalSearchContext);
  const { dispatchGlobalSearchMobileState } = useContext(GlobalSearchMobileContext);
  const { hit, onResultSelect } = props;
  const protocols = protocolInfoList;

  const { account } = useContext(CurrentAccountContext);
  const { trackSelectVoter, trackSelectProject, trackSelectProposal } = useMixpanel();
  const history = useHistory();
  const ens = useGetEns(hit.address);

  const isProposal = !!hit.title;
  const isProtocol = !!hit.name;
  const isVoter = !!hit.address;

  useEffect(() => {
    const fetchProposal = async () => {
      if (isProposal) {
        try {
          const proposal = await apiClient.getProposal(hit.refId);
          setProposalState(proposal.data.currentState);
        } catch (error) {
          console.error(error);
        }
      }
    };

    fetchProposal();
  }, [isProposal, hit.refId]);

  const onClick = useCallback(
    (to: string) => {
      history.push(to);
      onResultSelect && onResultSelect();
    },
    [history, onResultSelect],
  );

  const selectVoter = useCallback(
    (protocol: string, voterAddress: string) => {
      dispatchGlobalSearchState({ type: "TOGGLE_STATE", data: false });
      dispatchGlobalSearchMobileState({ type: "TOGGLE_STATE", data: false });
      onClick(`/voter/${voterAddress}`);

      trackSelectVoter({
        protocol,
        voterAddress,
        userId: `${account}`,
        context: "Search Results",
      });
    },
    [account, onClick, trackSelectVoter, dispatchGlobalSearchMobileState, dispatchGlobalSearchState],
  );

  const selectProtocol = useCallback(
    (protocol: string) => {
      dispatchGlobalSearchState({ type: "TOGGLE_STATE", data: false });
      dispatchGlobalSearchMobileState({ type: "TOGGLE_STATE", data: false });
      trackSelectProject({
        protocol,
        userId: `${account}`,
        context: "Search Results",
      });
      onClick(`/${protocol}`);
    },
    [onClick, dispatchGlobalSearchMobileState, dispatchGlobalSearchState, trackSelectProject, account],
  );

  const selectProposal = useCallback(
    (protocol: string, refId: string) => {
      dispatchGlobalSearchState({ type: "TOGGLE_STATE", data: false });
      dispatchGlobalSearchMobileState({ type: "TOGGLE_STATE", data: false });
      trackSelectProposal({
        protocol,
        userId: `${account}`,
        context: "Search Results",
        proposalId: refId,
      });
      onClick(`/${protocol}/proposal/${refId}`);
    },
    [onClick, dispatchGlobalSearchMobileState, dispatchGlobalSearchState, trackSelectProposal, account],
  );

  if (isVoter) {
    const protocolName = hit.protocols[0].protocol;
    const protocolIsEnabled = protocols?.find((protocol) => protocol.cname === protocolName)?.isEnabled;

    if (!protocolIsEnabled) {
      return null;
    }
  }

  if (isProtocol) {
    const protocolName = hit.cname;
    const protocolIsEnabled = protocols?.find((protocol) => protocol.cname === protocolName)?.isEnabled;

    if (!protocolIsEnabled) {
      return null;
    }
  }

  if (isProposal) {
    const protocolName = hit.protocol;
    const protocolIsEnabled = protocols?.find((protocol) => protocol.cname === protocolName)?.isEnabled;

    if (!protocolIsEnabled) {
      return null;
    }
  }

  if (isProposal) {
    return (
      <SearchItem
        title={hit.title}
        tagLabel="proposal"
        status={proposalState}
        prefix={
          <Suspense fallback={<Loader />}>
            <ProtocolIconWrapper protocolCname={hit.protocol} />
          </Suspense>
        }
        onClick={() =>
          selectProposal(protocols?.find((protocol) => protocol.cname === hit.protocol)?.path || "", hit.refId)
        }
      />
    );
  } else if (isVoter) {
    return (
      <SearchItem
        title={formatEns(hit.ens) || formatEns(ens) || hit.address}
        tagLabel="profile"
        prefix={
          <Suspense fallback={<Loader />}>
            <UserPfpWrapper userAddress={hit.address} />
          </Suspense>
        }
        onClick={() => selectVoter(hit.protocols[0].protocol, hit.address)}
      />
    );
  } else if (isProtocol) {
    return (
      <SearchItem
        title={hit.name}
        tagLabel="protocol"
        category={
          <Suspense fallback={<div></div>}>
            <ProtocolCategoryWrapper protocolCname={hit.cname} />
          </Suspense>
        }
        prefix={
          <Suspense fallback={<Loader />}>
            <ProtocolIconWrapper protocolCname={hit.cname} />
          </Suspense>
        }
        onClick={() => selectProtocol(protocols?.find((protocol) => protocol.cname === hit.cname)?.path || "")}
      />
    );
  }

  return null;
};

export default React.memo(styled(Hit)`
  .ais-InfiniteHits-item,
  .ais-InfiniteResults-item,
  .ais-Hits-item,
  .ais-Results-item {
    width: 100%;
    margin: 0;

    :hover {
      cursor: pointer;
    }
  }
`);
