import React from "react";
import { useSelect } from "downshift";
import { Label } from "../Label";
import { DropdownItemList } from "./DropdownItemList";
import { DropdownButton } from "./DropdownButton";
import { getSingleSelectConfig, getMultiSelectConfig } from "./config";
import { DropdownProps, MultiDropdownProps } from "./types";

export const Dropdown = ({
  label,
  items,
  initialValue,
  placeholder = "Select option",
  prefix = "",
  icon,
  onChange,
  customArrowIconColor,
  buttonStyle,
  itemListStyle,
}: DropdownProps) => {
  const { isOpen, selectedItem, getToggleButtonProps, getLabelProps, getMenuProps, getItemProps } = useSelect(
    getSingleSelectConfig(items, initialValue, onChange),
  );

  return (
    <>
      {label && <Label {...getLabelProps()}>{label}</Label>}

      <DropdownButton
        selected={selectedItem}
        isOpen={isOpen}
        placeholder={placeholder}
        prefix={prefix}
        icon={icon}
        getButtonProps={getToggleButtonProps}
        customArrowIconColor={customArrowIconColor}
        buttonStyle={buttonStyle}
      />

      <DropdownItemList
        items={items}
        isOpen={isOpen}
        selected={selectedItem}
        getMenuProps={getMenuProps}
        getItemProps={getItemProps}
        itemListStyle={itemListStyle}
      />
    </>
  );
};

export const MultiDropdown = ({
  label,
  items,
  placeholder = "Select option",
  prefix = "",
  icon,
  onAdd,
  onRemove,
  selectedItems,
  customArrowIconColor,
  buttonStyle,
  itemListStyle,
  initialValue,
}: MultiDropdownProps) => {
  const { isOpen, getToggleButtonProps, getLabelProps, getMenuProps, getItemProps } = useSelect(
    getMultiSelectConfig(items, onAdd, initialValue),
  );

  const parsedSelect = selectedItems.map((item) => {
    return {
      name: item,
      value: item,
    };
  });

  return (
    <>
      {label && <Label {...getLabelProps()}>{label}</Label>}

      <DropdownButton
        selected={parsedSelect}
        isOpen={isOpen}
        placeholder={placeholder}
        prefix={prefix}
        icon={icon}
        getButtonProps={getToggleButtonProps}
        customArrowIconColor={customArrowIconColor}
        buttonStyle={buttonStyle}
        isProtocols
      />

      <DropdownItemList
        items={items}
        isOpen={isOpen}
        selected={parsedSelect}
        getMenuProps={getMenuProps}
        getItemProps={getItemProps}
        itemListStyle={itemListStyle}
        removeItem={onRemove}
      />
    </>
  );
};
