import * as React from "react";

const SvgThinShareIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" {...props}>
    <path
      d="M2.462 14A.462.462 0 012 13.539V8.003a.462.462 0 01.923 0v5.074h10.154V8A.462.462 0 0114 8v5.539a.461.461 0 01-.461.461H2.462zM4.904 5.557a.462.462 0 010-.653l2.77-2.769c.18-.18.472-.18.652 0l2.77 2.77a.462.462 0 01-.653.652L8 3.114 5.557 5.557a.462.462 0 01-.653 0z"
      fill="currentColor"
    />
    <path
      d="M7.997 10.923a.462.462 0 01-.461-.462v-8a.462.462 0 11.923 0v8a.462.462 0 01-.462.462z"
      fill="currentColor"
    />
  </svg>
);

export default SvgThinShareIcon;
