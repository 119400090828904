import React from "react";
import styled from "styled-components";

import { COLORS } from "../../constants/colors";
import { Button } from "../Button";
import InfoIcon from "../icons/InfoIcon";
import { Header, Paragraph } from "../Typography";

const StyledWrapper = styled("div")`
  text-align: center;
`;

const StyledButton = styled(Button)`
  margin: 10px;
`;

export const ComingSoonMessage = () => {
  const openDiscordLink = () => {
    window.open("https://discord.com/invite/CEZ8WfuK8s", "_blank");
  };

  const openMailToLink = () => {
    window.open("mailto:hello@boardroom.info", "_blank");
  };

  return (
    <StyledWrapper>
      <InfoIcon height="40px" width="40px" color={COLORS.primary.accent} />
      <Header level={5}>Coming soon</Header>
      <Paragraph>In the meantime, join the boardroom community and provide some feedback</Paragraph>
      <StyledButton type="primary" onClick={openDiscordLink}>
        Discord
      </StyledButton>
      <StyledButton type="secondary" onClick={openMailToLink}>
        Contact
      </StyledButton>
    </StyledWrapper>
  );
};
