import React from "react";
import styled from "styled-components";

interface Props {
  children: React.ReactNode;
  totalRows: number;
  seeMoreComponent: React.ReactNode;
  cutoffIndex: number;
}

interface RowProps {
  children: any;
  cutoffIndex: number;
  index: number;
  rowsBeingShown: number;
}

const ExpandCollapseDiv = styled.div`
  text-align: center;
`;

function ExpandCollapseSomeRows({ children, totalRows, seeMoreComponent, cutoffIndex }: Props) {
  return (
    <>
      {children}
      {totalRows > cutoffIndex && <ExpandCollapseDiv>{seeMoreComponent}</ExpandCollapseDiv>}
    </>
  );
}

function Row({ children, index, cutoffIndex, rowsBeingShown }: RowProps) {
  if (index >= cutoffIndex && index >= rowsBeingShown) {
    return <></>;
  }
  return children || null;
}

ExpandCollapseSomeRows.Row = Row;

export default ExpandCollapseSomeRows;
