export const coinGeckoApiUrl = "https://api.coingecko.com/api/v3";
export const coinGeckoPriceUrl = "/simple/price";
export const coinGeckoTokenDetailsUrl = "/coins";
export const boardRoomOrgGithubLink = "https://github.com/boardroom-inc";
export const boardRoomRepo = "boardroom-inc";
export const boardroomProtocolInfoRepo = "protocol-Info";
export const rawGithubBoardRoomOrgLink = "https://raw.githubusercontent.com/boardroom-inc/";
export const boardroomDiscordLink = "https://discord.gg/CEZ8WfuK8s";
export const boardroomTwitterLink = "https://twitter.com/boardroom_info";

export const APP_URL = "http://boardroom.io/";
export const API_URL = "https://api.boardroom.info/v1";
export const CONTACT_EMAIL = "hello@boardroom.info";
export const APP_NAME = "Boardroom";
export const DAO_ONBOARDING_FORM = "T1DZZaDr";

export const LOCAL_STORAGE_FAVORITES_KEY = "boardroom.FAVORITE_SPACES";
    
export const VOTER_PROFILE_SCROLL_SHRINK = 136;

export const VOTER_PROFILE_SCROLL_SHRINK_MOBILE = 90;

export const VOTER_PROFILE_SCROLL_SHRINK_PROPOSALS = 80;

export const DelegateFrameWorkFilteritems = [
  { name: "All Voting Types", value: "allFrameworks" },
  { name: "Offchain", value: "snapshot" },
  { name: "Onchain", value: "onchain" },
];

export const DelegateFrameWorkFilteritemsByValue = {
  allFrameworks: "All Voting Types",
  snapshot: "Offchain",
  onchain: "Onchain"
};

export const projectsFilterItems = [
  { name: "Your Projects", value: "custom" },
  { name: "All Projects", value: "all" },
];

export const proposalsTypeByValue = {
  pending: {name: "Pending", value: "pending"},
  closed: {name:"Closed", value: "closed"},
  all: {name: "All", value:"all"},
  today: {name:"Today", value: "today"},
  open: {name:"Open", value: "open"},
};

export const projectsFilterItemsByValue = {
  custom: "Your Projects",
  all: "All Projects"
};

export const DelegateSortByItems = [
  { name: "Vote Power", value: "votePower" },
  { name: "Votes Cast", value: "votesCast" },
  { name: "Proposals Made", value: "proposalsMade" },
  { name: "Addresses Delegating", value: "delegatorCount" },
];

export const adapters = [
  "onchain",
  "snapshot",
  "onchain-optimism",
  "onchain-arbitrum",
  "onchain-secondary",
];

let API_KEY = "c909030d97aaa3f635a09eb4a3df58fc";

if(process.env.REACT_APP_STANDALONE_PROTOCOL === "0xgov" || process.env.REACT_APP_STANDALONE_PROTOCOL === "0xgov-test") {
  API_KEY = "33caac300228fbb101f725f814324b9a";
}

export const isProd = process.env.REACT_APP_NODE_ENV === "production";

let baseUrl = "https://staging-api.boardroom.info/v1/";

if(isProd) {
  baseUrl = "https://api.boardroom.info/v1/";
} else if (process.env.REACT_APP_STANDALONE_PROTOCOL === "0xgov-test" ) {
  baseUrl = "https://382r0o636m.execute-api.us-east-1.amazonaws.com/v1/"; 
}

export const BOARDROOM_API_KEY = API_KEY;
export const baseAPIUrl = baseUrl;