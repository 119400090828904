import React, { ForwardedRef, forwardRef, useState, useEffect, useMemo, useCallback, useContext, useRef } from "react";
import Col from "antd/es/col";
import Row from "antd/es/row";
import styled, { css } from "styled-components";
import { getAddress } from "@ethersproject/address";
import { useCurrentWidth } from "react-socks";
import { isAddress } from "web3-utils";
import html2canvas from "html2canvas";

import { VoteHistory } from "./";
import { COLORS } from "../../constants/colors";
import media from "../../media-query";
import YourProposals from "./YourProposals";
import { CsvIcon } from "../icons";
import Button from "antd/es/button/button";
import { baseAPIUrl, BOARDROOM_API_KEY } from "../../constants/general";
import { toShortAddress } from "../../utils";
import { FilterDropdown } from "../../features/Dashboard/Dropdown";
import { useMixpanel } from "../../hooks";
import { CurrentAccountContext } from "../../reducers/CurrentAccount";

interface Props {
  activeProtocol: string;
  ens?: string;
  address?: string;
  teamWallets?: string[];
}

const StyledTab = styled("div")<{ $active?: boolean }>`
  margin-right: 16px;
  color: ${({ $active }) => ($active ? "#191540" : "rgba(25, 21, 64, 0.4);")};
  font-weight: 300;
  font-size: 18px;
  line-height: 30px;
  display: inline-block;
  width: auto;
  cursor: pointer;

  ${media.lessThan("991px")`
  `}

  &:after {
    ${(props) =>
      props.$active &&
      css`
        height: 8px;
        display: block;
        border-bottom: 4px solid ${COLORS.primary.accent};
        content: "";
        ${media.lessThan("640px")`
          height: 11px;
        `}
      `}
  }
`;

const TabsWrapper = styled.span`
  display: inline-block;
  margin-top: 12px;
  border-bottom: 1px solid ${COLORS.primary.grayLighter};
  width: 100%;
  ${media.lessThan("991px")`
      height: 42px;
      position: relative;
      left: 0px;
      width: 100%;
      display: flex;
      margin-right: 0px;
    `}
`;

const StyledButtonWrapper = styled(Button)`
  background: #fff;
  border-radius: 4px;
  width: 56px;
  height: 24px;
  display: flex;
  gap: 4px;
  padding: 0px;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 16px;
  align-items: center;
  border: none;
  box-shadow: none;
  ${media.lessThan("991px")`
    background: transparent;
    float: right;
    margin-right: 10px;
    top: 0px;
  `}
  fill: #4235E1;
  color: #4235e1;
  transition: none;
  &:focus {
    color: #4235e1;
  }
  &:hover {
    fill: #291fa7;
    color: #291fa7;
    border: 1px solid #f0eff8;
    box-shadow: 1px 1px 1px #f0eff8;
  }
  &:active {
    background-color: #f0eff8;
  }
`;

const StyledButtonText = styled.div`
  font-size: 10px;
`;

const CSVIconContainer = styled.div`
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FilterDropdownWrapper = styled.span`
  > div {
    display: inline-block;
    float: right;
  }
  button > span {
    font-size: 18px;
  }
  button {
    padding: 0;
  }
  ${media.lessThan("991px")`
    display: none;    
  `}
`;

const MobileFilterWrapper = styled.div<{ $width: number }>`
  width: ${({ $width }) => `${$width - 30}px`};
  overflow-x: hidden;
  ${media.greaterThan("991px")`
    display: none;
  `}
`;

const ScrollWrapper = styled.div`
  width: 100%;
  overflow-x: scroll;
  display: flex;
  margin-top: 20px;
`;

const StyledAddressFilter = styled.span<{ $active: boolean }>`
  flex: none;
  margin-right: 16px;
  font-weight: 500;
  font-size: 12px;
  line-height: 13px;
  border-radius: 100px;
  padding: 5px 18px;
  background: ${({ $active }) => ($active ? COLORS.primary.grayLighter : "none")};
  border: ${({ $active }) =>
    $active ? `1px solid ${COLORS.primary.accent}` : `1px solid ${COLORS.primary.grayDarkLightest}`};
  color: ${({ $active }) => ($active ? COLORS.primary.accent : COLORS.primary.grayDarkLightest)};
`;

function ProfileMainContent(props: Props, ref: ForwardedRef<HTMLDivElement>) {
  const printRef = useRef<HTMLDivElement | null>(null);
  const { account } = useContext(CurrentAccountContext);
  const [activeSection, setActiveSection] = useState("vote-history");
  const [csvDownloadUrl, setCsvDownloadUrl] = useState("");
  const { activeProtocol, teamWallets, address } = props;
  const [statusFilter, setStatusFilter] = useState<string>("allMembers");
  const width = useCurrentWidth();
  const { trackClickCsvDownloadButton } = useMixpanel();

  useEffect(() => {
    let url = baseAPIUrl;
    const addressToUse = address ?? (isAddress(statusFilter) ? statusFilter : teamWallets?.[0]);
    if (activeSection === "vote-history") {
      url += `voters/${addressToUse}/votesCSVExport?key=${BOARDROOM_API_KEY}`;
    } else if (activeSection === "proposals-created") {
      url += `proposalsCSVExport?proposer=${addressToUse}&key=${BOARDROOM_API_KEY}`;
    }
    setCsvDownloadUrl(url);
  }, [activeSection, address, statusFilter, teamWallets]);

  const dropdownItems = useMemo(() => {
    const items = [
      {
        name: "All Wallets",
        value: "allMembers",
      },
    ];

    if (!teamWallets) {
      return items;
    }

    const addressItems = teamWallets.map((address) => ({
      name: toShortAddress(isAddress(address) ? getAddress(address) : address) || address,
      value: isAddress(address) ? getAddress(address) : address,
    }));

    return [...items, ...addressItems];
  }, [teamWallets]);

  const handleDropdownValueChange = (item: any) => {
    setStatusFilter(item.value);
  };

  const filteredWallets = useMemo(() => {
    if (statusFilter === "allMembers") {
      return teamWallets;
    }
    return statusFilter ? [statusFilter] : [];
  }, [statusFilter, teamWallets]);

  const onClickCSV = useCallback(() => {
    trackClickCsvDownloadButton({
      userId: account,
    });
  }, [account, trackClickCsvDownloadButton]);

  const handleDownloadImage = useCallback(async () => {
    const element = printRef.current;
    if (element) {
      // height is used to limit to only 5 rows
      const height = activeSection === "vote-history" ? 113 * 5 : 124 * 5;
      const canvas = await html2canvas(element, {
        useCORS: true,
        height,
      });

      const data = canvas.toDataURL("image/jpg");
      const link = document.createElement("a");

      if (typeof link.download === "string") {
        link.href = data;
        link.download = "image.jpg";

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        window.open(data);
      }
    }
  }, [activeSection]);

  return (
    <>
      <Row gutter={[24, { xs: 12, sm: 12, md: 0 }]} justify="space-between">
        <Col span={24}>
          <TabsWrapper>
            <StyledTab $active={activeSection === "vote-history"} onClick={() => setActiveSection("vote-history")}>
              Votes
            </StyledTab>
            <StyledTab
              $active={activeSection === "proposals-created"}
              onClick={() => setActiveSection("proposals-created")}
            >
              Proposals
            </StyledTab>
            <StyledButtonWrapper style={{ right: "56px" }} onClick={handleDownloadImage}>
              <CSVIconContainer>
                <CsvIcon />
              </CSVIconContainer>
              <StyledButtonText>Image</StyledButtonText>
            </StyledButtonWrapper>
            <StyledButtonWrapper onClick={onClickCSV} href={csvDownloadUrl}>
              <CSVIconContainer>
                <CsvIcon />
              </CSVIconContainer>
              <StyledButtonText>CSV</StyledButtonText>
            </StyledButtonWrapper>
          </TabsWrapper>
          {teamWallets && teamWallets.length > 1 && (
            <FilterDropdownWrapper>
              <FilterDropdown
                items={dropdownItems}
                initialValue={dropdownItems[0]}
                onChange={handleDropdownValueChange}
                justifyEnd
              />
            </FilterDropdownWrapper>
          )}
          {teamWallets && teamWallets.length > 1 && (
            <MobileFilterWrapper $width={width}>
              <ScrollWrapper>
                {dropdownItems.map((item) => {
                  return (
                    <StyledAddressFilter
                      key={item.value}
                      $active={item.value === statusFilter}
                      onClick={() => handleDropdownValueChange(item)}
                    >
                      {item.name}
                    </StyledAddressFilter>
                  );
                })}
              </ScrollWrapper>
            </MobileFilterWrapper>
          )}
        </Col>
        <Col ref={printRef} span={24}>
          {activeSection === "vote-history" && (
            <VoteHistory address={address} protocol={activeProtocol} ref={ref} teamWallets={filteredWallets} />
          )}
          {activeSection === "proposals-created" && <YourProposals proposer={filteredWallets || address} />}
        </Col>
      </Row>
    </>
  );
}

export default React.memo(forwardRef(ProfileMainContent));
