import React from "react";

export const LogoWhite = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 374.26 66.74">
    <path
      d="M93.21,22.14c-4.29,0-7.4,1.58-9.51,4.28V11.22H76.12v41.1H83.7V48.86c2.11,2.7,5.22,4.28,9.51,4.28,7.87,0,14.33-6.75,14.33-15.5S101.08,22.14,93.21,22.14ZM91.8,45.92a7.88,7.88,0,0,1-8.1-8.28,8.13,8.13,0,1,1,16.26,0A7.89,7.89,0,0,1,91.8,45.92Zm35.06,7.22a15.5,15.5,0,1,0-15.5-15.5A15.39,15.39,0,0,0,126.86,53.14Zm0-7.4a7.79,7.79,0,0,1-7.93-8.1,8,8,0,1,1,15.91,0A7.8,7.8,0,0,1,126.86,45.74ZM170.06,23v3.46a11.68,11.68,0,0,0-9.57-4.28c-7.81,0-14.27,6.75-14.27,15.5s6.46,15.5,14.27,15.5a11.68,11.68,0,0,0,9.57-4.28v3.46h7.58V23Zm-8.16,23a7.88,7.88,0,0,1-8.1-8.28,8.13,8.13,0,1,1,16.26,0A7.89,7.89,0,0,1,161.9,45.92ZM192.08,28V23h-7.57V52.32h7.57v-14c0-6.17,5-7.93,8.93-7.46V22.37C197.31,22.37,193.61,24,192.08,28Zm34.53-16.79v15.2c-2.12-2.7-5.23-4.28-9.52-4.28-7.87,0-14.33,6.75-14.33,15.5s6.46,15.5,14.33,15.5c4.29,0,7.4-1.58,9.52-4.28v3.46h7.57V11.22Zm-8.11,34.7a7.89,7.89,0,0,1-8.16-8.28,8.14,8.14,0,1,1,16.27,0A7.89,7.89,0,0,1,218.5,45.92ZM248.68,28V23h-7.57V52.32h7.57v-14c0-6.17,5-7.93,8.93-7.46V22.37C253.91,22.37,250.21,24,248.68,28Zm26.25,25.13a15.5,15.5,0,1,0-15.51-15.5A15.39,15.39,0,0,0,274.93,53.14Zm0-7.4a7.79,7.79,0,0,1-7.93-8.1,8,8,0,1,1,15.91,0A7.8,7.8,0,0,1,274.93,45.74Zm34.86,7.4a15.5,15.5,0,1,0-15.5-15.5A15.39,15.39,0,0,0,309.79,53.14Zm0-7.4a7.78,7.78,0,0,1-7.92-8.1,8,8,0,1,1,15.91,0A7.8,7.8,0,0,1,309.79,45.74Zm53.37-23.6c-4,0-6.93,1.58-8.75,4.23a8.79,8.79,0,0,0-8-4.23c-3.76,0-6.52,1.47-8.16,3.93V23h-7.57V52.32h7.57V35.82c0-4.4,2.29-6.64,5.52-6.64s4.93,2.12,4.93,5.58V52.32h7.58V35.82c0-4.4,2.11-6.64,5.46-6.64,3.17,0,4.93,2.12,4.93,5.58V52.32h7.58V34.23C374.26,26.89,369.85,22.14,363.16,22.14Z"
      fill="#fff"
    />
    <path d="M16.8,40.36l12.1-7,12.1,7-12.1,7Z" fill="#fff" fillOpacity="0.88" fillRule="evenodd" />
    <path d="M41,40.36l-12.1-7v-14l12.1,7Z" fill="#fff" fillRule="evenodd" />
    <path d="M16.8,26.38l12.1-7v14l-12.1,7Z" fill="#fff" fillRule="evenodd" />
    <path d="M8.4,21.54V45.21L0,50.06V16.69l8.4-4.85ZM16.73,7,28.9,0V9.7l-12.17,7Z" fill="#fff" fillRule="evenodd" />
    <path
      d="M28.9,57,49.4,45.21l8.4,4.85L43.35,58.4,28.9,66.74,14.45,58.4,0,50.06l8.4-4.85Z"
      fill="#fff"
      fillOpacity="0.88"
      fillRule="evenodd"
    />
    <path d="M49.4,21.54,28.9,9.7V0L43.35,8.34,57.8,16.68V50.06l-8.4-4.85Z" fill="#fff" fillRule="evenodd" />
  </svg>
);
