import * as React from "react";

const SvgMultipleUsersIcons = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M5 0a3.333 3.333 0 100 6.667A3.333 3.333 0 005 0zM3 3.333a2 2 0 114 0 2 2 0 01-4 0zM10.272 3.479a1.334 1.334 0 00-.605-.146V2a2.666 2.666 0 11-1.572 4.821l.786-1.077a1.333 1.333 0 101.391-2.265zM12.332 12a2.666 2.666 0 00-2.665-2.665V8a4 4 0 014 4h-1.335zM9.667 12H8.333a3.333 3.333 0 00-6.666 0H.333a4.667 4.667 0 119.334 0z"
      fill="currentColor"
    />
  </svg>
);

export default SvgMultipleUsersIcons;
