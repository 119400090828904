import * as React from "react";

const SvgStarIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M0 14.01c5.492-.499 8.648-.958 10.585-2.475C12.862 9.76 13.4 6.506 14 0c.62 6.746 1.178 9.979 3.675 11.715C19.612 13.072 22.747 13.51 28 13.99c-5.472.499-8.648.958-10.565 2.455C15.138 18.24 14.6 21.474 14 28c-.56-6.007-1.059-9.22-2.916-11.076C9.227 15.068 5.991 14.549 0 14.01z"
      fill="currentColor"
    />
  </svg>
);

export default SvgStarIcon;
