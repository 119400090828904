import * as React from "react";

const SvgBoardroomLogo = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.06 12.094L9.703 10l3.644 2.093-3.644 2.094-3.645-2.094z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.348 12.094L9.704 10V5.813l3.644 2.093v4.188zM6.06 7.906l3.644-2.093V10L6.06 12.094V7.906zM3.53 6.454v7.094L1 15.001v-10L3.53 3.55v2.905zm2.508-4.347L9.703.002v2.906L6.038 5.013V2.107z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.704 17.094l6.174-3.547L18.408 15l-4.352 2.5L9.704 20l-4.352-2.5L1 15l2.53-1.453 6.174 3.547z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.878 6.453L9.704 2.906V0l4.352 2.5L18.407 5v10l-2.53-1.453V6.453z"
      fill="currentColor"
    />
  </svg>
);

export default SvgBoardroomLogo;
