import * as React from "react";

const SvgDelegateIconWithBg = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect x={0.5} y={0.5} width={19} height={19} rx={9.5} fill="#4235E1" />
    <path
      d="M11.548 8.48a3.592 3.592 0 002.524 1.07 2.63 2.63 0 00-.33-3.285 2.569 2.569 0 00-3.254-.333c.009.923.362 1.844 1.06 2.548z"
      fill="#fff"
    />
    <path
      d="M10.085 9.957a2.629 2.629 0 01-.09-3.598c.1.91.496 1.793 1.187 2.49a4.104 4.104 0 002.466 1.198 2.568 2.568 0 01-3.563-.09z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.805 9.405a.714.714 0 00-.022.025l-3.118 3.72a.71.71 0 00.04.953l.188.19c.257.26.668.277.945.04l3.684-3.147a.674.674 0 00.026-.023 2.604 2.604 0 01-1.743-1.759zm-.366 1.844a.257.257 0 01.366 0 .263.263 0 010 .37l-.366.369a.257.257 0 01-.366 0 .263.263 0 010-.37l.366-.369z"
      fill="#fff"
    />
    <rect x={0.5} y={0.5} width={19} height={19} rx={9.5} stroke="#fff" />
  </svg>
);

export default SvgDelegateIconWithBg;
