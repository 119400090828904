import { useQuery } from "@tanstack/react-query";
import { apiClient } from "../utils/apiClient";
import { GetDelegationResponse } from "@boardroom/boardroom-api";
import { useMemo } from "react";
import { adapters } from "../constants/general";

interface Props {
  address: string;
  suspense?: boolean;
  disable?: boolean;
}

export const useDelegatesFromAddress = ({ address, suspense, disable }: Props) => {
  const { data, refetch } = useQuery<GetDelegationResponse, Error>(
    [`delegationsFromAddress:${address}:onchain`],
    () =>
      apiClient.getDelegationsFromAddress(
        address,
        {
          adapters: JSON.stringify(adapters) as any as string[],
          address,
        },
        Date.now(),
      ),
    { enabled: !!address && !disable, suspense },
  );

  const delegationsFromAddress = useMemo(
    () =>
      [...(data?.data || [])].filter(
        (delegation) => delegation.addressDelegatedTo !== "0x0000000000000000000000000000000000000000",
      ),
    [data],
  );

  return { delegationsFromAddress, refetch };
};
