import { APIResponse } from "@boardroom/boardroom-api";
import { useQuery } from "@tanstack/react-query";
import { isAddress } from "web3-utils";
import { getAddress } from "ethers/lib/utils";
import { apiClient } from "../utils/apiClient";

interface Props {
  address: string;
  suspense?: boolean;
  disable?: boolean;
}

export interface DelegationPitch {
  protocol: string;
  address: string;
  delegationPitch: string;
}

interface DelegationPitchList {
  delegationPitches: DelegationPitch[];
}

export const useDelegationPitchesByAddress = ({ address, suspense, disable }: Props) => {
  const { data: delegationPitches, refetch } = useQuery<DelegationPitch[] | undefined, Error>(
    [`delegationPitches:${address?.toLowerCase()}`],
    async () => {
      try {
        const randomNumberToBypassCache = Math.floor(Math.random() * 1000000);
        const normalizedAddress = isAddress(address) ? getAddress(address) : address;
        const data: APIResponse<DelegationPitchList> = await apiClient.getDelegationPitchesByAddress(
          normalizedAddress,
          randomNumberToBypassCache,
        );
        const {
          data: { delegationPitches },
        } = data;
        return delegationPitches;
      } catch (error) {
        console.error(error);
      }
    },
    {
      cacheTime: 0,
      staleTime: 0,
      suspense,
      enabled: !!address && !disable,
    },
  );
  return { delegationPitches, refetch };
};

export const useDelegationPitchesByMultipleAddresses = ({ addresses }: { addresses: string[] }) => {
  const { data: delegationPitches, refetch } = useQuery<DelegationPitch[] | undefined, Error>(
    [`delegationPitchesMultipleAddresses:${addresses?.toString()}`],
    async () => {
      const promises = addresses?.map(async (address) => {
        try {
          const normalizedAddress = isAddress(address) ? getAddress(address) : address;
          const data: APIResponse<DelegationPitchList> = await apiClient.getDelegationPitchesByAddress(
            normalizedAddress,
          );
          const {
            data: { delegationPitches },
          } = data;
          return delegationPitches;
        } catch (error) {
          console.error(error);
          return [];
        }
      });
      const awaitedPromises = await Promise.all(promises);
      return awaitedPromises.flat().filter(Boolean);
    },
    {
      suspense: false,
      enabled: !!addresses?.length,
    },
  );
  return { delegationPitches, refetch };
};
