import Row from "antd/es/row";
import Col from "antd/es/col";
import React from "react";

import { BackgroundForMobile, MainContainer } from "../../components";
import { Container, PaddingContainer, PageMax, StyledAbsoluteCol, StyledCol, StyledRowNoMargins } from "./styles";
import { DelegateMessageBoardSkeleton } from "../../components/DelegateMessageBoard/DelegateMessageBoardSkeleton";
import { DelegateHubSkeleton } from "../../components/DelegateHub/DelegateHubSkeleton";
import { DelegatorProfileSidebarSkeleton } from "../../components/DelegatorProfileSidebar/DelegatorProfileSidebarSkeleton";
import { useIsSidebarExpanded } from "../../reducers/IsSidebarExpanded";
import { DelegateYourProjectsSkeleton } from "../../components/DelegateYourProjects/DelegateYourProjectsSkeleton";
import { CollapsedMainNavWidth, ExpandedMainNavWidth } from "../../constants/styles";

const Delegation = () => {
  const { isSidebarExpanded } = useIsSidebarExpanded();
  return (
    <>
      <MainContainer $isDashboard>
        <BackgroundForMobile />
        <Container>
          <PaddingContainer>
            <PageMax>
              <StyledRowNoMargins>
                <Col span={24}>
                  <Row>
                    <StyledAbsoluteCol
                      xs={24}
                      lg={4}
                      $left={isSidebarExpanded ? ExpandedMainNavWidth : CollapsedMainNavWidth}
                    >
                      <DelegateHubSkeleton />
                    </StyledAbsoluteCol>
                    {window.location.pathname.includes("your-projects") ? (
                      <>
                        <StyledCol
                          style={{ left: "216px", overflow: "initial", paddingBottom: "60px" }}
                          xs={24}
                          lg={{ span: 12, push: 0 }}
                        >
                          <DelegateYourProjectsSkeleton />
                        </StyledCol>
                      </>
                    ) : (
                      <>
                        <StyledCol style={{ left: "216px" }} xs={24} lg={12}>
                          <DelegateMessageBoardSkeleton />
                        </StyledCol>
                        <StyledCol style={{ background: "#fff", left: "216px" }} xs={24} lg={8}>
                          <DelegatorProfileSidebarSkeleton />
                        </StyledCol>
                      </>
                    )}
                  </Row>
                </Col>
              </StyledRowNoMargins>
            </PageMax>
          </PaddingContainer>
        </Container>
      </MainContainer>
    </>
  );
};

export default React.memo(Delegation);
