import * as React from "react";

const SvgTwitterIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.05 15.64" width="1em" height="1em" {...props}>
    <path
      d="M5.68 15.64c6.81 0 10.53-6 10.53-11.23V3.9a7.89 7.89 0 001.85-2 6.71 6.71 0 01-2.13.62A3.92 3.92 0 0017.55.29a7.24 7.24 0 01-2.35 1A3.6 3.6 0 0012.5 0a3.84 3.84 0 00-3.71 4 4.43 4.43 0 00.1.9A10.31 10.31 0 011.26.72a4.14 4.14 0 00-.51 2A4 4 0 002.4 6a3.53 3.53 0 01-1.67-.5 3.9 3.9 0 003 3.87 3.57 3.57 0 01-1 .14A4.05 4.05 0 012 9.49a3.74 3.74 0 003.46 2.74 7.11 7.11 0 01-4.6 1.69 6.4 6.4 0 01-.86-.06 10 10 0 005.68 1.78"
      fill="currentColor"
    />
  </svg>
);

export default SvgTwitterIcon;
