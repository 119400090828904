import * as React from "react";

const SvgLink2Icon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M3.643 11.69A3.333 3.333 0 011.286 6l1.415-1.415.942.943L2.23 6.942a2 2 0 002.829 2.829l1.414-1.414.943.943L6 10.714a3.313 3.313 0 01-2.358.976zm.472-2.862l-.943-.943 4.714-4.714.943.943-4.714 4.713v.001zM9.3 7.414l-.944-.943 1.414-1.414A2 2 0 106.943 2.23L5.53 3.643 4.586 2.7l1.415-1.415A3.333 3.333 0 0110.715 6L9.3 7.413v.001z"
      fill="currentColor"
    />
  </svg>
);

export default SvgLink2Icon;
