import React, { useCallback, useContext, useEffect, useState } from "react";

import { MonthProps } from "./ListView";
import { EventsWrapper, MonthContainer, SeeMoreText } from "./styles";
import SuspendedEventRow from "./EventRow";
import { NoProposalsFound } from "../../NoProposalsFound";
import ExpandCollapseSomeRows from "../../common/ExpandCollapseSomeRows";
import { useMixpanel } from "../../../hooks";
import { CurrentAccountContext } from "../../../reducers/CurrentAccount";

const Month = ({
  date,
  proposalData,
  votes,
  setSplitViewTab,
  monthEvents = [],
  flatProposalEvents,
  bookmarkedProposals,
}: MonthProps) => {
  const [rowsBeingShown, setRowsBeingShown] = useState(10);
  const { trackExpandEventsOnAnotherMonth } = useMixpanel();
  const { account } = useContext(CurrentAccountContext);

  const toggleSeeMore = useCallback(() => {
    if (rowsBeingShown >= monthEvents?.length) {
      setRowsBeingShown(10);
    } else {
      setRowsBeingShown((current) => current + 10);
    }
    trackExpandEventsOnAnotherMonth({
      userId: account,
    });
  }, [monthEvents?.length, rowsBeingShown, trackExpandEventsOnAnotherMonth, account]);

  useEffect(() => {
    setRowsBeingShown(10);
  }, [date]);

  return (
    <MonthContainer>
      <EventsWrapper>
        <ExpandCollapseSomeRows
          cutoffIndex={10}
          seeMoreComponent={
            <SeeMoreText onClick={toggleSeeMore}>
              {rowsBeingShown >= monthEvents?.length
                ? "see less"
                : `SEE ${monthEvents.length - rowsBeingShown} MORE EVENTS` || "see more"}
            </SeeMoreText>
          }
          totalRows={monthEvents?.length}
        >
          {monthEvents?.map((event, index) => (
            <ExpandCollapseSomeRows.Row index={index} cutoffIndex={10} rowsBeingShown={rowsBeingShown} key={event.id}>
              <SuspendedEventRow
                lastItemBorderWidth={1}
                votes={votes}
                proposalData={proposalData}
                event={event}
                key={event.id}
                setSplitViewTab={setSplitViewTab}
                flatProposalEvents={flatProposalEvents}
                bookmarkedProposals={bookmarkedProposals}
              />
            </ExpandCollapseSomeRows.Row>
          ))}
        </ExpandCollapseSomeRows>
      </EventsWrapper>
      {monthEvents.length === 0 && <NoProposalsFound isEvents />}
    </MonthContainer>
  );
};

export default Month;
