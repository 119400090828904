import { FormattedSubscriptionData } from "@boardroom/boardroom-api";
import notification from "antd/es/notification";
import axios from "axios";
import { baseAPIUrl, BOARDROOM_API_KEY } from "../constants/general";
import { apiClient } from "./apiClient";

interface Props {
  address: string;
  uuid: string;
  team_member_name?: string;
  team_member_address: string;
}

export const addTeamMemberToSubscription = async ({ address, uuid, team_member_address, team_member_name }: Props) => {
  const parsedData = {
    address,
    uuid,
    team_member_name,
    team_member_address,
  };

  return (await axios.post(`${baseAPIUrl}addTeamMemberToSubscription?key=${BOARDROOM_API_KEY}`, parsedData))?.data;
};

export const deleteTeamMemberFromSubscription = async ({ address, uuid, team_member_address }: Props) => {
  const parsedData = {
    address,
    uuid,
    team_member_address,
  };

  return (await axios.post(`${baseAPIUrl}deleteTeamMemberFromSubscription?key=${BOARDROOM_API_KEY}`, parsedData))?.data;
};

export const isTeamView = (pathname: string, search: string) => {
  const queryParams = new URLSearchParams(search);
  const isTeamPage = pathname.includes("/bundle/");
  const isOnboardPage = pathname.includes("/onboard");
  const isTeamFeed = queryParams.get("bundle");
  return isTeamFeed || isTeamPage || isOnboardPage;
};

export const isActiveTeamPlan = (subscriptionData?: FormattedSubscriptionData) => {
  if (!subscriptionData || !subscriptionData.subscription_items || !subscriptionData.subscription_items.length) {
    return false;
  }
  const subActive =
    subscriptionData.status === "active" ||
    subscriptionData.status === "non_renewing" ||
    subscriptionData.status === "in_trial";
  const isTeamPlan =
    subscriptionData.subscription_items[0].item_price_id === "Team-Subscription-USD-Monthly" ||
    subscriptionData.subscription_items[0].item_price_id === "Team-Subscription-USD-Yearly";
  return subActive && isTeamPlan;
};

export const isActiveUserPlan = (subscriptionData?: FormattedSubscriptionData) => {
  if (!subscriptionData || !subscriptionData.subscription_items || !subscriptionData.subscription_items.length) {
    return false;
  }
  const subActive =
    subscriptionData.status === "active" ||
    subscriptionData.status === "non_renewing" ||
    subscriptionData.status === "in_trial";
  const isUserPlan =
    subscriptionData.subscription_items[0].item_price_id !== "Team-Subscription-USD-Monthly" &&
    subscriptionData.subscription_items[0].item_price_id !== "Team-Subscription-USD-Yearly";
  return subActive && isUserPlan;
};

export const createTeamUtil = async ({
  orgName,
  address,
  uuid,
}: {
  orgName: string;
  address: string;
  uuid: string;
}) => {
  const returnedData = await apiClient.createOrg({
    uuid,
    address,
    orgWallets: [address],
    orgName,
    orgId: orgName.toLowerCase().replace(/[^\w]/gi, ""),
  });
  return returnedData;
};

export const addWalletUtil = async ({
  orgId,
  address,
  uuid,
  currentWallets,
  newWallet,
}: {
  orgId?: string;
  address: string;
  uuid: string;
  currentWallets?: string[];
  newWallet: string;
}) => {
  if (!orgId || !currentWallets) {
    notification.error({
      message: "Error adding bundle wallet",
      placement: "topRight",
      duration: 6,
    });
    return;
  }
  const returnedData = await apiClient.updateOrg({
    uuid,
    address,
    orgId,
    orgWallets: [...currentWallets, newWallet],
  });
  return returnedData;
};

export const removeWalletUtil = async ({
  orgId,
  address,
  uuid,
  currentWallets,
  removedWallet,
}: {
  orgId?: string;
  address: string;
  uuid: string;
  currentWallets?: string[];
  removedWallet: string;
}) => {
  if (!orgId || !currentWallets) {
    notification.error({
      message: "Error adding bundle wallet",
      placement: "topRight",
      duration: 6,
    });
    return;
  }
  const parsedOrgWallets = currentWallets.filter((wallet) => wallet !== removedWallet);
  const returnedData = await apiClient.updateOrg({
    uuid,
    address,
    orgId,
    orgWallets: parsedOrgWallets,
  });
  return returnedData;
};
