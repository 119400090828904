import List from "antd/es/list";
import styled from "styled-components";

import media from "../media-query";

export const ListItem = styled(List.Item)`
  padding: 10px 32px 16px 0px;
  button:hover,
  button:focus {
    color: white;
  }

  ${media.lessThan("991px")`
    padding: 15px;
  `}
`;

export const ClickableListItem = styled(ListItem)`
  padding: 30px;

  :hover {
    cursor: pointer;
  }
`;

export const ListItemMeta = styled(List.Item.Meta)`
  p {
    overflow: wrap;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  a {
    color: #000;
  }
  a:hover {
    color: #189eff;
  }
`;

export const ListWrapper = styled(List)`
  background: #fff;
  border-radius: 8px;
`;
