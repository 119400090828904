import * as React from "react";

const SvgAbstainIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx={8} cy={8} r={6} fill="#F0EFF8" />
    <circle cx={8} cy={8} r={3.5} stroke="#7B7893" />
    <path d="M11 5l-6 6 6-6z" fill="#fff" />
    <path d="M11 5l-6 6" stroke="#7B7893" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default SvgAbstainIcon;
