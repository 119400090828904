import * as React from "react";

const SvgThinLogOutIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" {...props}>
    <path
      d="M1.875 2.5c0-.345.28-.625.625-.625h7.497a.625.625 0 010 1.25H3.125v13.75H10a.625.625 0 110 1.25H2.5a.625.625 0 01-.625-.625v-15zM13.308 5.808a.625.625 0 01.884 0l3.75 3.75a.625.625 0 010 .884l-3.75 3.75a.625.625 0 11-.884-.884L16.616 10l-3.308-3.308a.625.625 0 010-.884z"
      fill="currentColor"
    />
    <path
      d="M6.042 9.997c0-.346.28-.625.625-.625H17.5a.625.625 0 110 1.25H6.667a.625.625 0 01-.625-.625z"
      fill="currentColor"
    />
  </svg>
);

export default SvgThinLogOutIcon;
