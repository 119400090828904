import * as React from "react";

const SvgSettingsIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M13.139 22h-3.46c-.448 0-.834-.327-.93-.786l-.387-1.884a7.524 7.524 0 01-1.459-.887l-1.746.585a.93.93 0 01-1.113-.453L2.31 15.424a1.044 1.044 0 01.184-1.239l1.354-1.3a8.517 8.517 0 010-1.772L2.494 9.816a1.044 1.044 0 01-.184-1.24l1.73-3.153a.93.93 0 011.113-.453l1.746.585c.232-.18.474-.348.724-.5.24-.142.486-.271.74-.386l.387-1.882c.095-.459.481-.787.928-.787h3.46c.447 0 .833.328.928.787l.392 1.883c.267.124.527.263.779.418.235.143.461.3.68.469l1.747-.585a.93.93 0 011.111.453l1.73 3.153c.222.407.146.922-.183 1.239l-1.355 1.3a8.519 8.519 0 010 1.772l1.355 1.3c.329.318.405.832.184 1.239l-1.73 3.153a.93.93 0 01-1.112.453l-1.747-.585a7.57 7.57 0 01-1.459.886l-.392 1.879c-.095.459-.481.786-.927.786zm-5.895-5.771l.78.6c.176.136.359.261.548.375.179.109.363.207.55.296l.888.409.434 2.091h1.93l.435-2.092.887-.409c.387-.18.755-.403 1.096-.666l.78-.6 1.94.65.966-1.758-1.505-1.443.106-1.012c.047-.443.047-.89 0-1.332l-.106-1.012 1.506-1.446-.966-1.759-1.94.65-.781-.6a5.86 5.86 0 00-1.096-.671l-.887-.409L12.374 4h-1.93l-.436 2.092-.885.408a5.691 5.691 0 00-1.096.666l-.78.6-1.94-.65L4.34 8.88l1.505 1.441-.107 1.013a6.34 6.34 0 000 1.332l.107 1.012-1.505 1.443.965 1.758 1.94-.65zm4.16-.229c-2.1 0-3.802-1.79-3.802-4s1.702-4 3.803-4c2.1 0 3.802 1.79 3.802 4-.002 2.208-1.703 3.997-3.803 4zm0-6c-1.038.001-1.884.879-1.899 1.971-.015 1.093.807 1.996 1.845 2.028 1.038.032 1.91-.818 1.956-1.909v.4V12c0-1.105-.851-2-1.901-2z"
      fill="currentColor"
    />
  </svg>
);

export default SvgSettingsIcon;
