import { useMemo } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { GetDelegatorCountsAcrossProtocolsResponse } from "@boardroom/boardroom-api";
import axios from "axios";
import { getAddress } from "ethers/lib/utils";

import { useDelegationPitchesByAddress } from "./useDelegationPitchesByAuthor";
import { BOARDROOM_API_KEY, adapters, baseAPIUrl } from "../constants/general";
import { useDelegatesFromAddress } from "./useDelegatesFromAddress";
import { isAddress } from "web3-utils";

interface Props {
  address: string;
  suspense?: boolean;
  disable?: boolean;
}

export const useDelegatesForProtocols = ({ address, suspense = true, disable }: Props) => {
  const { delegationPitches } = useDelegationPitchesByAddress({ address, suspense, disable });
  const { delegationsFromAddress } = useDelegatesFromAddress({ address, suspense, disable });

  const protocolsDelegatingToSelf = useMemo(() => {
    const protocols: string[] = [];
    delegationsFromAddress?.forEach((delegate) => {
      if (delegate?.addressDelegatedTo?.toLowerCase() === delegate?.address?.toLowerCase()) {
        protocols.push(delegate?.protocol);
      }
    });
    return protocols;
  }, [delegationsFromAddress]);

  const { data } = useQuery<GetDelegatorCountsAcrossProtocolsResponse, Error>(
    [`delegatorCountAcrossProtocols:${address}`],
    async () => {
      try {
        return (
          await axios.get(
            `${baseAPIUrl}delegates/${address}/getDelegatorCountsAcrossProtocols?adapters=${JSON.stringify(
              adapters,
            )}&key=${BOARDROOM_API_KEY}`,
          )
        ).data;
      } catch (error) {
        console.error(error);
        return { data: [] };
      }
    },
    {
      suspense,
      enabled: !!address && !disable,
    },
  );

  const delegateForProtocols = useMemo(
    () =>
      [
        ...(data?.data.map((delegate) => {
          if (!(delegate.delegatorCount === 1 && protocolsDelegatingToSelf?.includes(delegate.protocol))) {
            return delegate.protocol;
          }
        }) || []),
      ].filter(Boolean),
    [data?.data, protocolsDelegatingToSelf],
  );

  const delegateForProtocolsNoFilterSelf = useMemo(
    () =>
      [
        ...(data?.data.map((delegate) => {
          return delegate.protocol;
        }) || []),
      ].filter(Boolean),
    [data?.data],
  );

  const delegateForProtocolsFromPitches = useMemo(
    () => delegationPitches?.map(({ protocol }) => protocol),
    [delegationPitches],
  );

  const allDelegateForProtocols = useMemo(
    () =>
      Array.from(
        new Set([
          ...((delegateForProtocols.filter(Boolean) as string[]) || []),
          ...(delegateForProtocolsFromPitches || []),
        ]),
      ),
    [delegateForProtocols, delegateForProtocolsFromPitches],
  );

  return { delegateFor: allDelegateForProtocols, delegateForNoPitches: delegateForProtocolsNoFilterSelf };
};

export const useDelegatesForProtocolsMultipleAddresses = ({
  addresses,
  teamId,
}: {
  addresses: string[];
  teamId?: string;
}) => {
  const { delegationPitches } = useDelegationPitchesByAddress({ address: teamId || "", suspense: false });
  const queryClient = useQueryClient();

  const { data } = useQuery<Map<string, any>, Error>(
    [`useDelegatesForProtocolsMultipleAddresses:${addresses.toString()}:${teamId}${delegationPitches?.length}`],
    async () => {
      try {
        const delegatorCounts = new Map();
        await Promise.all(
          addresses.map(async (address) => {
            const data = (
              await axios.get(
                `${baseAPIUrl}delegates/${address}/getDelegatorCountsAcrossProtocols?adapters=${JSON.stringify(
                  adapters,
                )}&key=${BOARDROOM_API_KEY}`,
              )
            ).data;
            delegatorCounts.set(
              address,
              data?.data?.map((delegate: any) => delegate?.protocol),
            );
          }),
        );

        const normalizedAddresses = addresses
          ? addresses?.map((address) => (isAddress(address) ? getAddress(address) : address))
          : [];

        delegationPitches?.map(({ protocol }) => {
          const votePowerData: any = queryClient.getQueryData([
            `voterPowerWithScoresMap:${protocol}:${normalizedAddresses?.toString()}`,
          ]);
          const allScores: Map<string, number> = votePowerData?.scoresMap;
          let maxKey = null;
          let maxValue = -Infinity;

          for (const [key, value] of Array.from(allScores?.entries() || [])) {
            if (value > maxValue) {
              maxValue = value;
              maxKey = key;
            }
          }
          const current = delegatorCounts?.get(maxKey || addresses[0]);

          if (current) {
            delegatorCounts?.set(maxKey || addresses[0], [...current, protocol]);
          } else {
            delegatorCounts?.set(maxKey || addresses[0], [protocol]);
          }
        });
        return delegatorCounts;
      } catch (error) {
        console.error(error);
        return new Map();
      }
    },
    {
      suspense: false,
      enabled: !!addresses?.length,
    },
  );

  return { delegateFor: data };
};
