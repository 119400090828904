import * as React from "react";

const SvgCircleAddIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx={8} cy={8} r={6} stroke="currentColor" />
    <path d="M8 10V6M10 8H6" stroke="currentColor" strokeLinecap="square" />
  </svg>
);

export default SvgCircleAddIcon;
