import Avatar from "antd/es/avatar";
import Carousel from "antd/es/carousel";
import React, { Suspense, useCallback, useContext, useState } from "react";
import styled, { css } from "styled-components";
import parse from "html-react-parser";
import { useCurrentWidth } from "react-socks";
import { Org } from "@boardroom/boardroom-api/dist/orgs";

import { COLORS } from "../../constants/colors";
import {
  DelegationPitch,
  useDelegationPitchesByAddress,
  useDelegationPitchesByMultipleAddresses,
} from "../../hooks/useDelegationPitchesByAuthor";
import { useProtocolIcon } from "../../hooks/useProtocolIcon";
import { useProtocols } from "../../hooks/useProtocols";
import media from "../../media-query";
import formatValue from "../../utils/formatValue";
import { ExpandCollapseContent } from "../ExpandCollapseContent";
import SvgChevronRightIcon from "../icons/ChevronRightIcon";
import { Modal } from "../Modal";
import { renderMarkdown } from "../../utils/markdown";
import mediaQuery from "../../media-query";
import { useMixpanel } from "../../hooks";
import { CurrentAccountContext } from "../../reducers/CurrentAccount";

const TabsWrapper = styled.div`
  margin-top: 12px;
  margin-bottom: 16px;
  border-bottom: 1px solid #f0eff8;
  ${media.lessThan("991px")`
      height: 42px;
      position: relative;
      left: 0px;
      width: 100%;
      display: flex;
      margin-right: 0px;
       margin-bottom: 0;
    `}
`;

const AboutSectionWrapper = styled.div`
  border-bottom: 2px solid ${COLORS.primary.grayLighter};
  margin-bottom: 54px;
  margin-top: 16px;
  padding-bottom: 40px;
  ${media.lessThan("991px")`
        border-top: none;
        margin-top: 0px;
        border-bottom: none;
        margin-bottom: 4px;
        padding-bottom: 36px;
    `}
`;

const StyledTab = styled("span")<{ $active?: boolean }>`
  margin-right: 28px;
  color: ${({ $active }) => ($active ? COLORS.primary.grayDark : "rgba(25, 21, 64, 0.4);")};
  font-weight: 300;
  font-size: 18px;
  line-height: 30px;
  display: inline-block;
  cursor: pointer;
  min-width: auto;
  margin-bottom: -2px;
  ${media.lessThan("991px")`
    line-height: 30px;
    font-size: 20px;
    min-width: auto;
  `}

  &:after {
    ${(props) =>
      props.$active &&
      css`
        height: 8px;
        display: block;
        border-bottom: 4px solid ${COLORS.primary.accent};
        content: "";

        ${media.lessThan("640px")`
      height: 11px;
    `}
      `}
  }
`;

const StyledSpan = styled.span<{ $active: boolean }>`
  font-weight: 100;
  color: inherit;
`;

const DelegationPitchCardWrapper = styled.div`
  border: 1px solid #f0eff8;
  border-radius: 7px;
  height: 232px;
  overflow: hidden;
  width: 50%;
  margin-right: 16px;
  padding: 16px 20px;
  :hover {
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  }
  ${mediaQuery.lessThan("991px")`
    width: 100%;
  `}
`;

const DelegationPitchProtocolName = styled.span<{ $reduceFontSize?: boolean }>`
  font-weight: 500;
  font-size: ${({ $reduceFontSize }) => ($reduceFontSize ? "15px" : "16px")};
  line-height: 28px;
  color: #191540;
  margin-left: 8px;
`;

const DelegationPitchContent = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #191540;
  margin-top: 16px;
  height: 136px;
  overflow: hidden;
  text-overflow: ellipsis;
  width: calc(100%);
`;

const DelegationPitchContentInModal = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #191540;
  margin-top: 16px;
  margin-bottom: 28px;
`;

const StyledCarousel = styled(Carousel)`
  .slick-slide > div {
    display: flex !important;
    width: calc(100% - 48px);
  }
  .slick-arrow {
    color: #4235e1 !important;
  }
  .slick-next {
    right: 16px !important;
  }
  ${mediaQuery.lessThan("991px")`
    margin-top: 16px;
`}
`;

const ReadLessText = styled("span")`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #4235e1;
  cursor: pointer;
  text-align: center;
  display: flex;
  justify-content: center;
`;

interface Props {
  identifier: string;
  about?: string;
  isTeam?: boolean;
  teamDetails?: Org;
}

const DelegationPitchCard = ({
  delegationPitch,
  protocolName,
}: {
  delegationPitch: DelegationPitch;
  protocolName: string;
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { url } = useProtocolIcon({ protocolCname: delegationPitch.protocol });
  const delegationPitchLength = delegationPitch.delegationPitch.length;
  const content = renderMarkdown(delegationPitch?.delegationPitch || "");
  const isMobile = useCurrentWidth() < 991;

  return (
    <>
      <DelegationPitchCardWrapper>
        <Avatar alt={delegationPitch.protocol} src={url as unknown as string} size="large" />
        <DelegationPitchProtocolName $reduceFontSize={protocolName.length > 20}>
          {protocolName}
        </DelegationPitchProtocolName>
        <DelegationPitchContent
          onClick={() => {
            if (delegationPitchLength > 165) setIsModalVisible(true);
          }}
        >
          {delegationPitchLength > 165
            ? parse(content.slice(0, 165) + "... <a href='#'>Read More</a>")
            : parse(content)}
        </DelegationPitchContent>
      </DelegationPitchCardWrapper>
      <Modal
        size="small"
        customMinWidth={isMobile ? "auto" : "650px"}
        open={isModalVisible}
        onClose={() => setIsModalVisible(false)}
      >
        <div>
          <Avatar alt={delegationPitch.protocol} src={url as unknown as string} size="large" />
          <DelegationPitchProtocolName>{protocolName}</DelegationPitchProtocolName>
        </div>
        <DelegationPitchContentInModal>{parse(content)}</DelegationPitchContentInModal>
        <ReadLessText onClick={() => setIsModalVisible(false)}>Close</ReadLessText>
      </Modal>
    </>
  );
};

const About = ({ identifier, about, isTeam, teamDetails }: Props) => {
  const orgWallets = teamDetails?.orgWallets || [];
  const { delegationPitches } = useDelegationPitchesByAddress({
    address: isTeam ? "" : identifier || "",
    suspense: true,
  });
  const { delegationPitches: delegationPitchesMultipleAddresses } = useDelegationPitchesByMultipleAddresses({
    addresses: isTeam ? orgWallets : [],
  });
  const delegationPitchesToUse = isTeam ? delegationPitchesMultipleAddresses : delegationPitches;
  const [activeSection, setActiveSection] = useState("about");
  const { protocols } = useProtocols(true);
  const isMobile = useCurrentWidth() < 991;
  const aboutContent = parse(renderMarkdown(about || ""));
  const { account } = useContext(CurrentAccountContext);
  const { trackSwitchToDelegationPitchesInProfile, trackSwitchToAboutInProfile } = useMixpanel();

  const onAboutClick = useCallback(() => {
    setActiveSection("about");
    trackSwitchToAboutInProfile({
      userId: account,
    });
  }, [account, trackSwitchToAboutInProfile]);

  const onDelegationPitchesClick = useCallback(() => {
    setActiveSection("pitches");
    trackSwitchToDelegationPitchesInProfile({
      userId: account,
    });
  }, [account, trackSwitchToDelegationPitchesInProfile]);

  return (
    <>
      {(about || (!!delegationPitchesToUse && !!delegationPitchesToUse?.length)) && (
        <AboutSectionWrapper>
          <TabsWrapper>
            <StyledTab onClick={onAboutClick} $active={activeSection === "about"}>
              About
            </StyledTab>
            {!!delegationPitchesToUse && !!delegationPitchesToUse?.length && (
              <StyledTab onClick={onDelegationPitchesClick} $active={activeSection === "pitches"}>
                Delegation Pitch
                <StyledSpan $active={activeSection === "pitches"}>
                  {" "}
                  {formatValue(delegationPitchesToUse?.length || 0)}
                </StyledSpan>
              </StyledTab>
            )}
          </TabsWrapper>
          {activeSection === "about" && (
            <ExpandCollapseContent
              marginTopMobile={isMobile ? "16px" : "0px"}
              showMoreText="Continue Reading"
              height={240}
            >
              {aboutContent}
            </ExpandCollapseContent>
          )}
          {activeSection === "pitches" && (
            <>
              {!!delegationPitchesToUse && !!delegationPitchesToUse?.length && (
                <StyledCarousel
                  nextArrow={<SvgChevronRightIcon width={24} height={24} color="#4235e1" />}
                  prevArrow={<></>}
                  dots={false}
                  slidesPerRow={isMobile ? 1 : 2}
                  arrows={true}
                >
                  {delegationPitchesToUse?.map((delegationPitch) => (
                    <DelegationPitchCard
                      protocolName={protocols[delegationPitch.protocol]?.name}
                      delegationPitch={delegationPitch}
                      key={delegationPitch.protocol}
                    />
                  ))}
                </StyledCarousel>
              )}
            </>
          )}
        </AboutSectionWrapper>
      )}
    </>
  );
};

function AboutSection(props: Props) {
  return (
    <Suspense fallback={<></>}>
      <About {...props} />
    </Suspense>
  );
}

export default React.memo(AboutSection);
