import { useContext, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router";

import { CurrentAccountContext } from "../reducers/CurrentAccount";
import { useVoter } from "./useVoter";
import { useDelegateProtocolsByAddresses } from "./useDelegateVotingPowerByAddress";
import { useHasVotePowerMultipleProtocols } from "./useVotePower";
import { useProtocolsInWallets, useUserProtocols } from "./useUserProtocols";
import { protocols } from "../constants/protocols";
import { useGetTeam } from "./useGetTeam";

export const useGetProtocolsToAdd = () => {
  const protocolsArray = useMemo(() => Object.values(protocols), []);
  const { account } = useContext(CurrentAccountContext);
  const [activeProtocols, setActiveProtocols] = useState([""]);
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const teamId = query.get("bundle");

  const teamDetails = useGetTeam(teamId || "");
  const teamWallets = teamDetails?.orgWallets;
  const { voter } = useVoter({ address: account || "", suspense: false });
  const wallets = useMemo(
    () => (teamId && teamWallets?.length ? teamWallets : [account]),
    [teamWallets, account, teamId],
  );
  const { protocols: delegateProtocols } = useDelegateProtocolsByAddresses({ addresses: wallets, suspense: false });
  const { protocolsWithVotePower } = useHasVotePowerMultipleProtocols(
    Array.from(new Set([...activeProtocols, ...(delegateProtocols || [])])),
    account,
  );
  const protocolsInBundledWallet = useProtocolsInWallets({ addresses: wallets, suspense: false });
  const { protocolsInWallet } = useUserProtocols({
    address: account,
    suspense: false,
  });

  useEffect(() => {
    if (voter?.protocols && activeProtocols?.length === 1 && activeProtocols[0] === "") {
      const activeProtocols = voter.protocols.map((protocol) => {
        const activeProtocol = protocolsArray.find((protocolInArray) => protocolInArray.cname === protocol.protocol);
        return activeProtocol?.cname || "";
      });
      setActiveProtocols(activeProtocols.filter((protocol: string) => protocol));
    }
  }, [voter?.protocols, activeProtocols, protocolsArray]);

  useEffect(() => {
    setActiveProtocols([""]);
  }, [account]);

  const protocolsToAdd = useMemo(
    () =>
      teamId
        ? [...(protocolsInBundledWallet || []), ...(delegateProtocols || [])]
        : [...(protocolsWithVotePower || []), ...(protocolsInWallet || [])],
    [delegateProtocols, protocolsInBundledWallet, protocolsInWallet, protocolsWithVotePower, teamId],
  );

  return protocolsToAdd;
};
