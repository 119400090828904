import * as React from "react";

const SvgDropdownArrowDown = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M4.866 6.5a1 1 0 01-1.732 0l-1.732-3A1 1 0 012.268 2h3.464a1 1 0 01.866 1.5l-1.732 3z"
      fill="currentColor"
    />
  </svg>
);

export default SvgDropdownArrowDown;
