import React from "react";
import styled from "styled-components";

import { COLORS } from "../../constants/colors";
import { Text } from "../Typography";
import { LightningIcon } from "../icons";
import formatValue from "../../utils/formatValue";
import { DelegatesTableData } from "./types";

const VotePowerAmount = styled(Text)`
  display: inline-block;
  margin-left: 4px;
  font-size: 20px;
  font-weight: 300;
`;

const VoteWeight = styled("div")`
  font-size: 10px;
  margin-left: 4px;
  color: ${COLORS.primary.grayDarkLightest};
  font-weight: 300;
`;

const VotePowerWrapper = styled.div`
  white-space: nowrap;
  display: flex;
`;

interface Props {
  rowData: DelegatesTableData;
}

const VotePowerColumn = ({ rowData }: Props) => {
  return (
    <>
      <VotePowerWrapper>
        <LightningIcon width={16} height={16} color={COLORS.secondary.purple} style={{ marginTop: "2px" }} />
        <div>
          <VotePowerAmount>{formatValue(rowData.votePower)}</VotePowerAmount>
          <VoteWeight>
            <span style={{ fontWeight: 500 }}>Voting Weight: </span>
            {Number(rowData.votingWeight)?.toFixed(2)}%
          </VoteWeight>
        </div>
      </VotePowerWrapper>
    </>
  );
};

interface PropsPowerDelegated {
  rowData: any;
}

export const PowerDelegatedColumn = ({ rowData }: PropsPowerDelegated) => {
  return (
    <>
      <VotePowerWrapper>
        <LightningIcon width={16} height={16} color={COLORS.secondary.purple} style={{ marginTop: "2px" }} />
        <div>
          <VotePowerAmount>{formatValue(rowData?.amount)}</VotePowerAmount>
        </div>
      </VotePowerWrapper>
    </>
  );
};

export default VotePowerColumn;
