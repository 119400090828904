import React from "react";
import Button from "antd/es/button";
import styled from "styled-components";

import { COLORS } from "../constants/colors";

interface Props {
  handleClick?: any;
  title: string;
  subTitle: React.ReactNode | string;
  showButton?: boolean;
}

const StyledButton = styled(Button)`
  background: rgba(240, 239, 248, 0.2);
  border: 1px solid ${COLORS.secondary.purple};
  box-sizing: border-box;
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: ${COLORS.secondary.purple};
  width: 80%;
  margin: 0 auto;
  height: 40px;
`;

const StyledDiv = styled.div`
  text-align: center;
  margin-bottom: 160px;
`;

const EmptyStateText = styled.div`
  font-size: 16px;
  line-height: 20px;
  color: ${COLORS.primary.grayDarkLightest};
  margin: 24px auto 32px;
  max-width: 25rem;
`;

const StyledTitle = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: ${COLORS.secondary.purple};
`;

const StyledImg = styled("img")`
  margin: 32px 0;
`;

const UnconnectedWalletState = ({ handleClick, title, subTitle, showButton = true }: Props) => {
  return (
    <>
      <StyledDiv>
        <StyledImg src={`${process.env.PUBLIC_URL}/assets/VoteHistoryEmptyState.png`} />
        <StyledTitle>{title}</StyledTitle>
        <EmptyStateText>{subTitle}</EmptyStateText>
        {showButton && <StyledButton onClick={handleClick}>Connect Wallet</StyledButton>}
      </StyledDiv>
    </>
  );
};
export default UnconnectedWalletState;
