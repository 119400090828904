import React, { useMemo, useState } from "react";
import TimePicker from "antd/es/time-picker";
import AntdForm, { FormInstance } from "antd/es/form";
import Modal from "antd/lib/modal/Modal";
import Divider from "antd/es/divider";
import styled from "styled-components";

import { DatePickerCalendar } from "../../components/Calendar/DatePicker";
import { Button } from "../Button";
import { COLORS } from "../../constants/colors";
import { CrossIcon } from "../../components/icons";

interface Props {
  name: string;
  placeholder: string;
  isRequired?: boolean;
  form: FormInstance;
}

const FormItem = styled(AntdForm.Item)`
  margin-bottom: 0;
  position: relative;
`;

const TimePickerFormItem = styled(AntdForm.Item)`
  margin-bottom: 32px;
  width: 100%;
  .ant-form-item-explain-error {
    display: none;
  }
`;

const Container = styled.div`
  width: 400px;
  padding: 0 16px;
  margin: 0;
`;

const StyledDivider = styled(Divider)`
  margin: 28px -16px 32px;
  width: calc(100% + 32px);
  height: 1px;
  background: ${COLORS.primary.grayLighter};
  overflow: hidden;
`;

const Header = styled("div")`
  border-radius: 8px;
  border-bottom: 1px solid ${COLORS.primary.grayLighter};
  width: calc(100% + 32px);
  margin: 0 -18px;
  padding: 16px 24px 12px;
  overflow: hidden;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  color: ${COLORS.primary.grayDarkLightest};
  display: flex;
  justify-content: space-between;
`;

const StyledTimePicker = styled(TimePicker)`
  width: 100%;
  border-radius: 80px;
  border: 1px solid ${COLORS.primary.accent};
  svg {
    color: ${COLORS.primary.grayDarkLightest};
    width: 20px;
    height: 20px;
  }
  padding: 12px 20px;
`;

const StyledButton = styled(Button)<{ $isSelected: boolean; $isConfirmed: boolean }>`
  && {
    border-radius: 8px;
    font-style: normal;
    font-weight: ${(props) => (props.$isSelected ? "600" : "500")};
    font-size: 14px;
    line-height: 16px;
    color: ${(props) => (props.$isConfirmed ? COLORS.secondary.red : COLORS.primary.accent)};
    border-color: ${(props) => (props.$isConfirmed ? COLORS.secondary.red : COLORS.primary.accent)};
    :hover {
      background: #fff;
      color: ${(props) => (props.$isConfirmed ? COLORS.secondary.red : COLORS.primary.accent)};
    }
    :focus {
      background: #fff;
      color: ${(props) => (props.$isConfirmed ? COLORS.secondary.red : COLORS.primary.accent)};
    }
  }
`;

const StyledConfirmButton = styled(Button)`
  && {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    margin: 0 -16px 0;
    line-height: 16px;
    color: #fff;
    width: calc(100% + 32px);
  }
`;

const StyledModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 8px;
    width: 400px;
  }
  .ant-modal-close-x {
    display: none;
  }
  .ant-modal-body {
    padding: 0;
  }
  .ant-modal-footer {
    display: none;
  }
`;

const StyledCrossIcon = styled(CrossIcon)`
  cursor: pointer;
`;

const DateTimePicker = ({ name, placeholder, isRequired = false, form }: Props) => {
  const [visible, setVisible] = useState(false);
  const [isStartConfirmed, setIsStartConfirmed] = useState(false);
  const [isEndConfirmed, setIsEndConfirmed] = useState(false);
  const validationRules = useMemo(() => [{ required: isRequired, message: "" }], [isRequired]);

  const closePopover = () => {
    setVisible(false);
  };

  return (
    <FormItem name={name}>
      <AntdForm.Item shouldUpdate>
        {() => {
          const date = form.getFieldValue(`${name}Date`);
          const time = form.getFieldValue(`${name}Time`);

          return (
            <StyledButton
              $isConfirmed={
                name === "start" ? (!date || !time) && isStartConfirmed : (!date || !time) && isEndConfirmed
              }
              onClick={() => setVisible(!visible)}
              $isSelected={date && time}
              type="secondary"
              block
            >
              {date && time ? `${date.format("MMM D, YYYY")} | ${time.format("h:mm A")}` : placeholder}
            </StyledButton>
          );
        }}
      </AntdForm.Item>
      <StyledModal visible={visible} onCancel={closePopover} centered>
        <Container>
          <Header>
            Select {name} date & time
            <span>
              <StyledCrossIcon onClick={closePopover} />
            </span>
          </Header>
          <FormItem name={`${name}Date`}>
            <DatePickerCalendar
              isSelectable
              onSelect={(date) => {
                form.setFieldsValue({
                  [name]: date,
                  [`${name}Date`]: date,
                });
              }}
            />
          </FormItem>
          <StyledDivider />
          <TimePickerFormItem
            name={`${name}Time`}
            required={false}
            rules={validationRules}
            validateTrigger={["onChange", "onBlur", "onSelect"]}
          >
            <StyledTimePicker
              format="HH:mm"
              size="large"
              popupClassName="timepicker"
              onSelect={(time) => {
                form.setFieldsValue({
                  [name]: time,
                  [`${name}Time`]: time,
                });
              }}
            />
          </TimePickerFormItem>
          <StyledConfirmButton
            onClick={() => {
              closePopover();
              if (name === "start") setIsStartConfirmed(true);
              if (name === "end") setIsEndConfirmed(true);
            }}
          >
            Confirm {name}
          </StyledConfirmButton>
        </Container>
      </StyledModal>
    </FormItem>
  );
};

export default React.memo(DateTimePicker);
