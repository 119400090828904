import React, { Suspense, useCallback, useContext, useEffect, useMemo, useState } from "react";
import Row from "antd/es/row";
import Col from "antd/es/col";
import { useParams } from "react-router-dom";
import moment from "moment";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroller";
import axios from "axios";
import ContentLoader from "react-content-loader";

import { useMixpanel } from "../hooks";
import { useCurrentProtocol__Deprecated } from "../hooks/useCurrentProtocol__Deprecated";
import { CardsContainer } from "../components";
import { DiscourseCategory, DiscouseForumData, Topic } from "../types";
import { Table } from "../components/Table";
import { ComingSoonMessage } from "../components/ComingSoonMessage";
import { CurrentAccountContext } from "../reducers/CurrentAccount";
import { ProtocolOverviewContainerRefContext } from "../reducers/ProtocolOverviewContainerRef";
import { PinnedIcon } from "../components/icons";
import { LinkStyles } from "../components/Typography/Link";
import MetaTags from "../components/common/MetaTags";
import { boardroomProtocolInfoRepo, rawGithubBoardRoomOrgLink } from "../constants/general";
import ProtocolInnerTabNav from "../components/NavBar/ProtocolInnerTabNav";
import { ProtocolForumFilters } from "../components/ProtocolForumFilters";
import { Dot } from "../components/ProtocolForumFilters/ProtocolForumFilters";
import mediaQuery from "../media-query";
import { useDiscourseCategories, useDiscourseData } from "../hooks/useDiscourseData";
import { COLORS } from "../constants/colors";
import { TrackPageView } from "../components/TrackPageView";
import { ContentWrapper } from "./ProtocolOverview/styles";

export interface ParsedTopic {
  title: string;
  replies: number;
  views: number;
  id: number;
  activity: string;
  pinned: boolean;
  excerpt?: string;
  category?: DiscourseCategory;
  tags?: string[];
}

export const StyledTag = styled("span")`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 8px;
  border: 1px solid #9e97f3;
  border-radius: 40px;
  color: #9e97f3;
  font-size: 10px;
  line-height: 12px;
  margin-right: 8px;
  white-space: nowrap;
`;

export const CategoryAndTagsWrapper = styled("div")<{ $column?: boolean }>`
  display: flex;
  align-items: center;
  ${({ $column }) => $column && "overflow-x: auto;"}
  ${({ $column }) => $column && "width: 100%;"}
`;

export const StyledCategory = styled("span")`
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  color: #7b7893;
  margin-right: 8px;
  white-space: nowrap;
`;

export const TopicsTable = styled("div")`
  .ant-table {
    min-width: max-content;
    width: 100%;
    padding: 0 16px;
    background: #fafafa;
  }
  .ant-spin-container {
    overflow-x: auto;
  }
  .ant-table-container table > thead > tr:first-child th:first-child {
    padding: 20px 0;
  }
  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    display: none;
  }
  .ant-table-thead > tr > th {
    border-bottom: none;
  }
  .ant-table-tbody > tr > td {
    padding: 32px 16px 32px 0;
  }
  .ant-table-tbody > tr:hover {
    td {
      background: transparent !important;
    }
    cursor: pointer;
    background: linear-gradient(89.99deg, #ffffff 2.03%, #f0eff8 54.1%, #ffffff 99.99%);
    box-shadow: 0px 0px 10px rgba(25, 21, 64, 0.1);
    border-radius: 8px;
  }
  .topicStats {
    font-weight: 300;
    font-size: 14px;
    line-height: 28px;
    color: #7b7893;
  }
  .topicTitle {
    display: flex;
    h3 {
      ${LinkStyles}
      font-weight: 400;
      font-size: 14px;
      line-height: 28px;
      margin-bottom: 4px;
    }
    p {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #7b7893;
    }
    svg {
      margin-right: 12px;
      margin-top: 6px;
      width: 40px;
    }
  }
  ${mediaQuery.lessThan("991px")`
    .topicTitle {
      max-width: 18rem;
      text-overflow: ellipsis;
      overflow: hidden;
      p {
        max-width: 15rem;
        text-overflow: ellipsis;
      }
      h3 {
        line-height: 18px;
        max-height: 36px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  `}
`;

const ComingSoonContainer = styled.div`
  margin-top: 130px;
`;

const TableHeaderWrapper = styled.div`
  display: flex;
  padding: 20px 0px;
`;

const TableTopicHeader = styled.span`
  width: 75%;
  font-size: 12px;
  line-height: 14px;
  font-weight: 600;
  color: #7b7893;
`;

const TableHeaders = styled.span`
  width: 10%;
  font-size: 12px;
  line-height: 14px;
  font-weight: 600;
  color: #7b7893;
`;

const columns = [
  {
    title: "Topic",
    dataIndex: "title",
    key: "title",
    width: "200px",
    render: (text: React.ReactNode, data: ParsedTopic) => {
      return {
        children: (
          <div className="topicTitle">
            {data.pinned && <PinnedIcon width={16} height={16} />}
            <div>
              <h3>{text}</h3>
              {data.pinned ? (
                <p>{data.excerpt}</p>
              ) : (
                <CategoryAndTagsWrapper>
                  {data.category && (
                    <>
                      <Dot style={{ backgroundColor: `#${data.category?.color}` }} />
                      <StyledCategory>{data.category?.name}</StyledCategory>
                    </>
                  )}
                  {data.tags?.map((tag) => (
                    <StyledTag key={tag}>{tag}</StyledTag>
                  ))}
                </CategoryAndTagsWrapper>
              )}
            </div>
          </div>
        ),
      };
    },
  },
  {
    title: "Replies",
    dataIndex: "replies",
    key: "replies",
    width: "10%",
    render: (text: React.ReactNode) => {
      return {
        children: <div className="topicStats">{text}</div>,
      };
    },
  },
  {
    title: "Views",
    dataIndex: "views",
    key: "views",
    width: "10%",
    render: (text: React.ReactNode) => {
      return {
        children: <div className="topicStats">{text}</div>,
      };
    },
  },
  {
    title: "Activity",
    dataIndex: "activity",
    key: "activity",
    width: "10%",
    render: (text: React.ReactNode) => {
      return {
        children: <div className="topicStats">{text}</div>,
      };
    },
  },
];

interface ContentProps {
  selectedCategory: string;
  setSelectedCategory: any;
  selectedTag: string;
  setSelectedTag: any;
  latestOrTop: string;
  setLatestOrTop: any;
  topPeriod: string;
  setTopPeriod: any;
  url: string;
  protocolCname: string;
}

const ForumContentWrapper = ({
  url,
  protocolCname,
  selectedCategory,
  selectedTag,
  topPeriod,
  latestOrTop,
  setLatestOrTop,
  setSelectedCategory,
  setSelectedTag,
  setTopPeriod,
}: ContentProps) => {
  const [hasError, setHasError] = useState(false);
  const [tags, setTags] = useState<Array<string>>([]);
  const [forumData, setForumData] = useState<Array<Topic>>([]);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [nextPageUrl, setNextPageUrl] = useState<string | undefined>("");
  const { containerRef } = useContext(ProtocolOverviewContainerRefContext);
  const { account } = useContext(CurrentAccountContext);
  const history = useHistory();
  const { protocol } = useParams<any>();
  const { trackSelectForumTopic } = useMixpanel();

  const { discourseCategories: categories, errorCategories } = useDiscourseCategories({
    url: url || "",
  });

  const { discourseData, error } = useDiscourseData({
    categories: categories || [],
    category: selectedCategory,
    tag: selectedTag,
    latestOrTop,
    topPeriod,
    url: url || "",
  });

  useEffect(() => {
    setForumData(discourseData?.topics || []);
    setHasNextPage(!!discourseData?.more_topics_url);
    setNextPageUrl(discourseData?.more_topics_url?.replace("?", ".json?"));
    setTags(discourseData?.top_tags || []);
  }, [discourseData]);

  useEffect(() => {
    setHasError(!!error || !!errorCategories);
  }, [error, errorCategories]);

  const tableData = useMemo(() => {
    return forumData
      .sort((forumA, forumB) => +forumB.bumped_at - +forumA.bumped_at)
      .map((forum) => ({
        title: forum.title,
        replies: forum.posts_count - 1,
        views: forum.views,
        id: forum.id,
        activity: moment(forum.created_at).format("MMM YY"),
        pinned: forum.pinned,
        category: categories?.find((category) => category.id === forum.category_id),
        excerpt: forum.excerpt,
        tags: forum.tags,
      }));
  }, [forumData, categories]);

  const fetchNextPage = useCallback(async () => {
    try {
      const request = await axios.post("https://my-app.boardroom.workers.dev/", {
        apiUrl: `${url}${nextPageUrl}`,
      });

      const response: DiscouseForumData = await request.data;

      setForumData([...forumData, ...response.topic_list.topics]);
      setHasNextPage(!!response.topic_list.more_topics_url);
      setNextPageUrl(response.topic_list.more_topics_url?.replace("?", ".json?"));
    } catch (e) {
      console.error(e);
    }
  }, [forumData, nextPageUrl, url]);

  return (
    <>
      <Col span={24}>
        {!hasError && (
          <ProtocolForumFilters
            setSelectedCategory={setSelectedCategory}
            setSelectedTag={setSelectedTag}
            selectedCategory={selectedCategory}
            selectedTag={selectedTag}
            categories={categories || []}
            tags={tags}
            latestOrTop={latestOrTop}
            topPeriod={topPeriod}
            setLatestOrTop={setLatestOrTop}
            setTopPeriod={setTopPeriod}
          />
        )}
      </Col>
      {hasError ? (
        <Col span={24}>
          <ComingSoonContainer>
            <ComingSoonMessage />
          </ComingSoonContainer>
        </Col>
      ) : (
        <Col span={24}>
          {forumData.length ? (
            <InfiniteScroll
              getScrollParent={() => containerRef?.current || null}
              loadMore={() => fetchNextPage()}
              hasMore={hasNextPage}
              initialLoad={false}
              threshold={500}
              useWindow={false}
            >
              <TopicsTable>
                <Table
                  onRow={(topic: ParsedTopic) => {
                    return {
                      onClick: () => {
                        trackSelectForumTopic({
                          protocol: protocolCname || "",
                          topicId: String(topic.id),
                          userId: `${account}`,
                        });
                        history.push(`/${protocol}/forum/${topic.id}`);
                      },
                    };
                  }}
                  rowIsLink={true}
                  pagination={false}
                  columns={columns}
                  dataSource={tableData}
                  inACardContainer={true}
                  rowKey={(topic: ParsedTopic) => String(topic.id)}
                  backgroundColor="#fafafa"
                />
              </TopicsTable>
            </InfiniteScroll>
          ) : null}
        </Col>
      )}
    </>
  );
};

function ProtocolForum() {
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [selectedTag, setSelectedTag] = useState("all");
  const [latestOrTop, setLatestOrTop] = useState("latest");
  const [topPeriod, setTopPeriod] = useState("all");

  const protocolInfo = useCurrentProtocol__Deprecated();

  const protocolShareName = protocolInfo?.name;
  const metaTagImageUrl = `${rawGithubBoardRoomOrgLink}/${boardroomProtocolInfoRepo}/main/${protocolInfo?.folder}/header.png`;

  return (
    <CardsContainer>
      <MetaTags
        title={protocolShareName}
        description={`Checkout discussions of ${protocolShareName}`}
        imageUrl={metaTagImageUrl}
      />
      <TrackPageView name="Forum" protocol={protocolInfo?.cname} />
      <Row justify="start" gutter={[48, 48]} style={{ marginLeft: "0", marginRight: "0" }}>
        <Col lg={{ span: 16, push: 0 }} md={{ span: 24, push: 0 }} sm={{ span: 24, push: 0 }}>
          <ContentWrapper>
            <Row gutter={[12, { xs: 0, sm: 0, md: 12 }]}>
              <Col span={24}>
                <ProtocolInnerTabNav />
              </Col>
              <Suspense
                fallback={
                  <>
                    <Col span={24}>
                      <ProtocolForumFilters
                        setSelectedCategory={setSelectedCategory}
                        setSelectedTag={setSelectedTag}
                        setLatestOrTop={setLatestOrTop}
                        setTopPeriod={setTopPeriod}
                        selectedCategory={selectedCategory}
                        selectedTag={selectedTag}
                        categories={[]}
                        tags={[]}
                        latestOrTop={latestOrTop}
                        topPeriod={topPeriod}
                      />
                    </Col>
                    <Col span={24}>
                      <TableHeaderWrapper>
                        <TableTopicHeader>TOPIC</TableTopicHeader>
                        <TableHeaders>REPLIES</TableHeaders>
                        <TableHeaders>VIEWS</TableHeaders>
                        <TableHeaders>ACTIVITY</TableHeaders>
                      </TableHeaderWrapper>
                      <ContentLoader
                        speed={2}
                        width="100%"
                        height={340}
                        viewBox="0 0 100% 250"
                        backgroundColor={COLORS.primary.grayLight}
                        foregroundColor={COLORS.primary.grayLighter}
                      >
                        <rect x="2%" y="50" rx="5" ry="5" width="30%" height="12" />
                        <rect x="70%" y="60" rx="5" ry="5" width="3%" height="8" />
                        <rect x="80%" y="60" rx="5" ry="5" width="3%" height="8" />
                        <rect x="90%" y="60" rx="5" ry="5" width="3%" height="8" />
                        <rect x="2%" y="70" rx="5" ry="5" width="45%" height="12" />
                        <rect x="2%" y="110" rx="5" ry="5" width="30%" height="12" />
                        <rect x="70%" y="120" rx="5" ry="5" width="3%" height="8" />
                        <rect x="80%" y="120" rx="5" ry="5" width="3%" height="8" />
                        <rect x="90%" y="120" rx="5" ry="5" width="3%" height="8" />
                        <rect x="2%" y="130" rx="5" ry="5" width="45%" height="12" />
                        <rect x="2%" y="170" rx="5" ry="5" width="30%" height="12" />
                        <rect x="70%" y="180" rx="5" ry="5" width="3%" height="8" />
                        <rect x="80%" y="180" rx="5" ry="5" width="3%" height="8" />
                        <rect x="90%" y="180" rx="5" ry="5" width="3%" height="8" />
                        <rect x="2%" y="190" rx="5" ry="5" width="45%" height="12" />
                        <rect x="2%" y="230" rx="5" ry="5" width="30%" height="12" />
                        <rect x="70%" y="240" rx="5" ry="5" width="3%" height="8" />
                        <rect x="80%" y="240" rx="5" ry="5" width="3%" height="8" />
                        <rect x="90%" y="240" rx="5" ry="5" width="3%" height="8" />
                        <rect x="2%" y="250" rx="5" ry="5" width="45%" height="12" />
                      </ContentLoader>
                    </Col>
                  </>
                }
              >
                <ForumContentWrapper
                  protocolCname={protocolInfo?.cname}
                  url={protocolInfo?.discourseForum?.url || ""}
                  selectedCategory={selectedCategory}
                  setSelectedCategory={setSelectedCategory}
                  selectedTag={selectedTag}
                  setSelectedTag={setSelectedTag}
                  topPeriod={topPeriod}
                  setTopPeriod={setTopPeriod}
                  latestOrTop={latestOrTop}
                  setLatestOrTop={setLatestOrTop}
                />
              </Suspense>
            </Row>
          </ContentWrapper>
        </Col>
      </Row>
    </CardsContainer>
  );
}

export default React.memo(ProtocolForum);
