import styled from "styled-components";
import Row from "antd/es/row";

import media from "../../media-query";
import { COLORS } from "../../constants/colors";
import { pageHeaderHeight } from "../../constants/styles";

const MainTitle = styled.span<{ $isOpen?: boolean }>`
  margin-top: 58px;
  width: 100%;
  z-index: 1;
  min-height: 73px;
  padding: 0px 60px;
  ${media.lessThan("991px")`
    padding: 0px 16px;
    margin-top: -12px;
    margin-left: 0px;
    width: calc(100% + 20px);    
    background: white;
  `}
  ${media.lessThan("640px")`
    margin-left: -20px;
  `}
  position: relative;
`;

const Container = styled.div`
  margin: auto;
  height: calc(100vh - ${pageHeaderHeight});
  overflow-x: hidden;
  padding: 44px 0px 0px 0px;
  ${media.greaterThan("1750px")`
  `}
  ${media.lessThan("991px")`
    height: auto;
    padding: 0px;
    position: relative;
  `};
  ${media.lessThan("640px")`
    background: #fafafa;
  `};
`;

const StyledFooterRow = styled(Row)`
  margin-left: -320px;
  margin-top: 320px;
  ${media.greaterThan("1750px")`
    margin-left: -424px;
  `}
  ${media.lessThan("991px")`
    margin-left: 0;
    margin-top: 0;
  `}
`;

const PageMax = styled.div`
  min-height: calc(100% - 320px);
  ${media.lessThan("1100px")`
    min-height: calc(100% - 360px);
  `}
  ${media.lessThan("991px")`
   width: 100%;
   padding: 16px;
  `}
`;

const StyledVoterCardWrapper = styled.div`
  width: 100%;
  ${media.lessThan("640px")`
  margin-top: 16px;
  `};
`;

const PageHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  height: 180px;
  ${media.greaterThan("1400px")`
    width: 1400px;
    margin: 0 auto;
  `}
  ${media.lessThan("640px")`
    padding-bottom: 24px;
    height: auto;
  `}
  padding-bottom: 64px;
`;

const BackToTopSection = styled.div<{ $hasColumns?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 100px;
  cursor: pointer;
  margin-top: 50px;
  max-width: ${({ $hasColumns }) => ($hasColumns ? "660px" : "1048px")};
`;

const BackToTopText = styled.div`
  font-weight: 300;
  font-size: 12px;
  line-height: 28px;
  color: ${COLORS.primary.grayDarkLightest};
  margin-top: 10px;
`;

const ContentWrapper = styled.div`
  padding-left: 194px;
  ${media.lessThan("991px")`
    padding-left: 0px;
  `};
`;

export {
  MainTitle,
  Container,
  PageMax,
  StyledVoterCardWrapper,
  PageHeader,
  BackToTopSection,
  BackToTopText,
  StyledFooterRow,
  ContentWrapper,
};
