import moment, { Moment } from "moment";
import { CalendarEvent, CalendarEvents } from "../types";
import { dateKeyFormat } from "../constants/date";

export const formatEvents = (events: CalendarEvent[], protocolCname?: string) => {
  const utcOffset = moment().utcOffset();

  return events.reduce((events: CalendarEvents, event: CalendarEvent) => {
    const dateKey = moment.utc(event.date).utcOffset(utcOffset).format(dateKeyFormat);

    if (protocolCname) {
      event.protocolCname = protocolCname;
    }

    return {
      ...events,
      [dateKey]: events[dateKey] ? [...events[dateKey], event] : [event],
    };
  }, {});
};

export const getEventsByDate = (events: CalendarEvents, date: Moment) => {
  const utcOffset = moment().utcOffset();
  const dateKey = moment.utc(date).utcOffset(utcOffset).format(dateKeyFormat);

  return events[dateKey] || [];
};

export const getParsedEventsByDate = (events: CalendarEvents, proposalEvents: CalendarEvents, date: Moment) => {
  const utcOffset = moment().utcOffset();
  const dateKey = moment.utc(date).utcOffset(utcOffset).format(dateKeyFormat);
  const currentDate = moment();

  const eventsDay = events[dateKey] || [];
  const proposalEventsDay = proposalEvents[dateKey] || [];

  return [...eventsDay, ...proposalEventsDay]
    .filter((event) => moment(event.date).isAfter(currentDate))
    .sort((a, b) => (moment(a.date).isAfter(moment(b.date)) ? 1 : -1));
};

export const getEventsByMonth = (events: CalendarEvents, proposalEvents: CalendarEvents, date: Moment) => {
  const utcOffset = moment().utcOffset();
  const firstDayOfMonth = date.clone().startOf("month");
  const lastDayOfMonth = date.clone().endOf("month");
  const currentDayBeingFetched = firstDayOfMonth.clone();
  const allEvents = [];
  while (currentDayBeingFetched.isBefore(lastDayOfMonth, "day")) {
    const dateKey = moment.utc(currentDayBeingFetched).utcOffset(utcOffset).format(dateKeyFormat);
    const eventsDay = events[dateKey] || [];
    const proposalEventsDay = proposalEvents[dateKey] || [];
    allEvents.push(eventsDay, proposalEventsDay);
    currentDayBeingFetched.add(1, "day");
  }

  return allEvents.flat().sort((a, b) => (moment(a.date).isAfter(moment(b.date)) ? 1 : -1));
};

export const getCurrentMonthPastDaysEvents = (events: CalendarEvents, proposalEvents: CalendarEvents) => {
  const currentDate = moment();
  const utcOffset = moment().utcOffset();
  const firstDayOfMonth = currentDate.clone().startOf("month");
  const currentDayBeingFetched = firstDayOfMonth.clone();
  const allEvents = [];
  while (currentDayBeingFetched.isBefore(currentDate)) {
    const dateKey = moment.utc(currentDayBeingFetched).utcOffset(utcOffset).format(dateKeyFormat);
    const eventsDay = events[dateKey] || [];
    const proposalEventsDay = proposalEvents[dateKey] || [];
    allEvents.push(eventsDay, proposalEventsDay);
    currentDayBeingFetched.add(1, "day");
  }

  return allEvents
    .flat()
    .filter((event) => moment(event.date).isBefore(currentDate))
    .sort((a, b) => (moment(a.date).isAfter(moment(b.date)) ? -1 : 1));
};

export const getCurrentMonthNextDaysEvents = (events: CalendarEvents, proposalEvents: CalendarEvents) => {
  const currentDate = moment();
  const utcOffset = moment().utcOffset();
  const lastDayOfMonth = currentDate.clone().endOf("month");
  const currentDayBeingFetched = lastDayOfMonth.clone();
  if (currentDate.clone().add(7, "days").isAfter(lastDayOfMonth)) {
    currentDayBeingFetched.add(7, "days");
  }
  const allEvents = [];
  while (currentDayBeingFetched.isAfter(currentDate, "day")) {
    const dateKey = moment.utc(currentDayBeingFetched).utcOffset(utcOffset).format(dateKeyFormat);
    const eventsDay = events[dateKey] || [];
    const proposalEventsDay = proposalEvents[dateKey] || [];
    allEvents.push(eventsDay, proposalEventsDay);
    currentDayBeingFetched.subtract(1, "day");
  }

  return allEvents.flat().sort((a, b) => (moment(a.date).isAfter(moment(b.date)) ? 1 : -1));
};

export const getUpcomingEventsThisMonth = (events: CalendarEvents, month: number) => {
  const upcoming = [];

  Object.entries(events).map(([_, value]) => {
    value.map((event) => {
      if (moment(event.date).isAfter(moment()) && moment(event.date).month() === month) {
        upcoming.push(event);
      }
    });
  });

  return upcoming.length;
};
