import React from "react";
import { Helmet } from "react-helmet";

interface Props {
  title?: string;
  description?: string;
  imageUrl?: string;
}

function MetaTags(props: Props) {
  const { title, description, imageUrl } = props;

  return (
    <Helmet
      base={{ href: `${window.location.href}` }}
      title={title}
      meta={[
        { name: "twitter:card", content: "summary_large_image" },
        { property: "twitter:site", content: "@boardroom_info" },
        { property: "twitter:title", content: title },
        { property: "twitter:description", content: description },
        {
          property: "twitter:image",
          content: imageUrl,
        },
        { property: "og:url", content: `${window.location.href}` },
        { property: "og:site_name", content: "Boardroom.info" },
        { property: "og:title", content: title },
        { property: "og:description", content: description },
        { property: "og:type", content: "article" },
        {
          property: "og:image",
          content: imageUrl,
        },
      ]}
    />
  );
}

export default React.memo(MetaTags);
