import React, { Suspense, useCallback, useContext, useEffect, useMemo, useState } from "react";
import ContentLoader from "react-content-loader";
import styled from "styled-components";

import media from "../../media-query";
import { COLORS } from "../../constants/colors";
import { LOCAL_STORAGE_FAVORITES_KEY } from "../../constants/general";
import { getProtocolCnameFromPath, protocols } from "../../constants/protocols";
import { SearchDropdownContext } from "../../reducers/ProjectSearchDropdown";
import { ProtocolDescription } from "../../types";
import { ArrowIcon, CrossIcon } from "../icons";
import { SearchIcon } from "../icons";
import { Input } from "../Input";
import { Header } from "../Typography";
import { ProtocolListItem } from "./ProtocolListItem";
import { useParams } from "react-router";
import { useWindowDimensions } from "../../hooks/useWindowDimensions";

const MainTitle = styled.span<{ $isOpen?: boolean }>`
  margin: 0;
  width: 100%;
  z-index: 1;
  min-height: 93px;
  padding: ${({ $isOpen }) => ($isOpen ? "0px 0px" : "0px 60px")};
  ${media.lessThan("991px")`
    padding: 0px 16px;
    margin-top: -12px;
    margin-left: 0px;
    width: calc(100% + 20px);    
  `}
  ${media.lessThan("640px")`
    margin-left: -16px;
  `}
  position: ${({ $isOpen }) => ($isOpen ? "absolute" : "relative")};
`;

const ProjectsSearchDropdownWrapper = styled.div`
  max-height: 1000px;
  overflow-y: auto;
  margin-bottom: 40px;
  margin-top: 56px;
  ${media.lessThan("991px")`
      padding: 0px;
      margin-left: 0px;
      width: 100%;
      max-height: 80vh;
      margin-bottom: 20px;
  `}
  ${media.lessThan("640px")`
      max-height: 60vh;
  `}
  ${media.lessThan("375px")`
      max-height: 55vh;
  `}
`;

const SectionHeader = styled(Header)`
  font-size: 24px;
  font-weight: 400;
  line-height: 30px;
  color: rgba(25, 21, 64, 0.4);
  margin-bottom: 10px;
`;

const FavouritesSection = styled.div`
  margin-bottom: 40px;
`;

const SearchInput = styled(Input)`
  margin-top: -20px;
  && {
    border-radius: 40px;
  }
  height: 48px;
  ${media.lessThan("991px")`
      padding: 0px 20px;
      margin-left: 0px;
      width: 100%;
      margin-top: 0px;
  `}
`;

const StyledCrossIcon = styled.div`
  cursor: pointer;
  margin-right: 8px;
  margin-bottom: 8px;
  svg {
    color: ${COLORS.primary.grayDarkLightest};
  }
`;

const StyledListWrapper = styled.div`
  z-index: +2;
  border-radius: 0 0 8px 8px;
  transition: all 0.5s ease-in-out;
  ${media.lessThan("991px")`
    margin-bottom: 0px;
    margin-top: 40px;
  `}
`;

const ProtocolName = styled.h1`
  font-weight: 600;
  font-size: 28px;
  line-height: 24px;
  color: #191540;
  padding-bottom: 18px;
  width: 100%;
  border-bottom: 1px solid #f0eff8;
  margin-bottom: 12px;
  cursor: pointer;
  ${media.lessThan("991px")`
    margin-bottom: 0;
  `}
`;

const ProtocolItem = ({
  protocol,
  favorites,
  changeOfFav,
  onProtocolSelect,
}: {
  protocol: ProtocolDescription;
  favorites: Record<string, boolean>;
  changeOfFav: () => void;
  onProtocolSelect: () => void;
}) => {
  return (
    <Suspense
      key={protocol.cname}
      fallback={
        <ContentLoader
          speed={2}
          width="100%"
          height={30}
          backgroundColor={COLORS.primary.grayDarkLighter}
          foregroundColor={COLORS.primary.grayDarkLightest}
          style={{
            borderRadius: "8px",
          }}
        >
          <circle cx="12" cy="12" r="12" />
          <rect x="32" y="7" rx="5" ry="5" width="70%" height="10" />
        </ContentLoader>
      }
    >
      <ProtocolListItem
        protocol={protocol}
        favourite={favorites[protocol.cname]}
        onClick={onProtocolSelect}
        changeOfFav={changeOfFav}
      />
    </Suspense>
  );
};

interface Params {
  protocol: string;
}

interface Props {
  onClose?: any;
}

export const ProtocolNav = (props: Props) => {
  const { onClose } = props;
  const { protocol } = useParams<Params>();
  const protocolCname = getProtocolCnameFromPath(protocol);
  const protocolInfo = protocols[protocolCname];
  const [searchQuery, setSearchQuery] = useState("");
  const { width } = useWindowDimensions();
  const isMobile = width <= 991;

  const { searchDropDownState, dispatchSearchDropDownState } = useContext(SearchDropdownContext);
  const [favorites, setFavourites] = useState<Record<string, boolean>>({});

  useEffect(() => {
    const favs = localStorage.getItem(LOCAL_STORAGE_FAVORITES_KEY);

    if (favs) {
      const parsedFavourites = JSON.parse(favs);
      setFavourites(parsedFavourites);
    }
  }, []);

  useEffect(() => {
    return () => {
      dispatchSearchDropDownState({ type: "TOGGLE_STATE", data: false });
    };
  }, [dispatchSearchDropDownState]);

  const changeOfFav = useCallback(() => {
    const favs = localStorage.getItem(LOCAL_STORAGE_FAVORITES_KEY);

    if (favs) {
      const parsedFavourites = JSON.parse(favs);
      setFavourites(parsedFavourites);
    }
  }, []);

  const visibleProtocols = useMemo(
    () =>
      Object.values(protocols)
        .filter((protocol) => protocol.isEnabled && protocol.name.toLowerCase().includes(searchQuery.toLowerCase()))
        .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
        .sort((a, b) => a.order - b.order),
    [searchQuery],
  );

  const favoriteProtocols = useMemo(() => {
    return visibleProtocols.reduce<ProtocolDescription[]>((acc, protocol) => {
      if (favorites[protocol.cname]) {
        return [...acc, protocol];
      }
      return acc;
    }, []);
  }, [visibleProtocols, favorites]);

  const restOfProtocols = useMemo(() => {
    return visibleProtocols.reduce<ProtocolDescription[]>((acc, protocol) => {
      if (!favorites[protocol.cname]) {
        return [...acc, protocol];
      }
      return acc;
    }, []);
  }, [visibleProtocols, favorites]);

  const toggleProjectsDropdown = useCallback(() => {
    dispatchSearchDropDownState({ type: "TOGGLE_STATE", data: !searchDropDownState });
  }, [dispatchSearchDropDownState, searchDropDownState]);

  const onProtocolSelect = useCallback(() => {
    if (searchDropDownState) {
      dispatchSearchDropDownState({ type: "TOGGLE_STATE", data: false });
    }
    if (onClose) {
      onClose();
    }
    setSearchQuery("");
  }, [dispatchSearchDropDownState, onClose, searchDropDownState]);

  const handleSearchChange = useCallback((val: string) => {
    setSearchQuery(val);
  }, []);

  const toggleSearchDropdownState = useCallback(() => {
    dispatchSearchDropDownState({ type: "TOGGLE_STATE", data: !searchDropDownState });
  }, [dispatchSearchDropDownState, searchDropDownState]);

  return (
    <>
      {isMobile && (
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <ProtocolName onClick={toggleSearchDropdownState}>
            {protocolInfo?.name}{" "}
            <ArrowIcon style={{ transform: "rotate(180deg)" }} width={16} height={16} color="#4235e1" />
          </ProtocolName>
          <StyledCrossIcon>
            <CrossIcon width={15} height={15} onClick={toggleProjectsDropdown} />
          </StyledCrossIcon>
        </div>
      )}
      <StyledListWrapper>
        <SearchInput
          value={searchQuery}
          placeholder="Search for Projects"
          onChange={(e) => handleSearchChange(e.currentTarget.value)}
          suffix={<SearchIcon color={COLORS.primary.grayDarkLightest} height={16} width={16} />}
        />
        <ProjectsSearchDropdownWrapper>
          {favoriteProtocols.length > 0 && (
            <>
              <SectionHeader level={4}>Favorites</SectionHeader>

              <FavouritesSection>
                {favoriteProtocols.map((protocol) => (
                  <ProtocolItem
                    key={protocol.cname}
                    protocol={protocol}
                    onProtocolSelect={onProtocolSelect}
                    changeOfFav={changeOfFav}
                    favorites={favorites}
                  />
                ))}
              </FavouritesSection>
            </>
          )}
          {restOfProtocols.length > 0 && <SectionHeader level={4}>All Projects</SectionHeader>}
          <div>
            {restOfProtocols.map((protocol) => (
              <ProtocolItem
                key={protocol.cname}
                protocol={protocol}
                onProtocolSelect={onProtocolSelect}
                changeOfFav={changeOfFav}
                favorites={favorites}
              />
            ))}
          </div>
        </ProjectsSearchDropdownWrapper>
      </StyledListWrapper>
    </>
  );
};

export default React.memo((props: Props) => (
  <Suspense
    fallback={
      <MainTitle>
        <ContentLoader
          speed={2}
          width={"100%"}
          height={128}
          backgroundColor={COLORS.primary.grayLight}
          foregroundColor={COLORS.primary.grayLighter}
          style={{ borderRadius: "8px" }}
        >
          <rect x="136" y="46" rx="5" ry="5" width="203" height="26" />
          <circle cx="78" cy="60" r="28" />
        </ContentLoader>
      </MainTitle>
    }
  >
    <ProtocolNav {...props} />
  </Suspense>
));
