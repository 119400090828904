import { useInfiniteQuery } from "@tanstack/react-query";
import { GetDelegatedVotingPowerResponse } from "@boardroom/boardroom-api";

import { apiClient } from "../utils/apiClient";
import { useMemo } from "react";

interface Props {
  protocol: string;
  limit?: number;
  adapter?: string;
  suspense?: boolean;
}

export const useDelegatesByProtocol = (props: Props) => {
  const { protocol, suspense, adapter, limit } = props;
  const adapterToUse =
    adapter === "onchain"
      ? { adapters: JSON.stringify(["onchain", "onchain-secondary"]) as any as string[] }
      : { adapter };
  const { data, fetchNextPage, hasNextPage } = useInfiniteQuery<GetDelegatedVotingPowerResponse, Error>(
    [`delegates:${protocol}:${adapter}:${limit}`],
    ({ pageParam: cursor }) =>
      apiClient.getDelegatedVotingPowerByProtocol(protocol, {
        ...adapterToUse,
        ...(cursor ? { cursor } : {}),
        limit,
        protocol
      }),
    {
      enabled: !!protocol,
      getNextPageParam: (params) => params?.nextCursor,
      suspense: suspense ?? true,
    },
  );

  const delegates = useMemo(() => {
    const flatData = data?.pages.flat();

    if (flatData) {
      const nonEmptyData = flatData
        .filter((d) => d !== undefined)
        .map((d) => d.data)
        .flat();
      const sortSnapshotDataToBottom = nonEmptyData.sort((a, b) => {
        if (a.adapter === "snapshot" && b.adapter !== "snapshot") {
          return -1;
        }
        if (a.adapter !== "snapshot" && b.adapter === "snapshot") {
          return 1;
        }
        return 0;
      });
      return Array.from(new Map(sortSnapshotDataToBottom.map((item) => [item.address, item])).values());
    } else {
      return flatData;
    }
  }, [data]);

  return { delegates, fetchNextPage, hasNextPage };
};
