var __create = Object.create;
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __getOwnPropNames = Object.getOwnPropertyNames;
var __getProtoOf = Object.getPrototypeOf;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __commonJS = (cb, mod) => function __require() {
  return mod || (0, cb[__getOwnPropNames(cb)[0]])((mod = { exports: {} }).exports, mod), mod.exports;
};
var __export = (target, all) => {
  for (var name in all)
    __defProp(target, name, { get: all[name], enumerable: true });
};
var __copyProps = (to, from, except, desc) => {
  if (from && typeof from === "object" || typeof from === "function") {
    for (let key of __getOwnPropNames(from))
      if (!__hasOwnProp.call(to, key) && key !== except)
        __defProp(to, key, { get: () => from[key], enumerable: !(desc = __getOwnPropDesc(from, key)) || desc.enumerable });
  }
  return to;
};
var __toESM = (mod, isNodeMode, target) => (target = mod != null ? __create(__getProtoOf(mod)) : {}, __copyProps(
  // If the importer is in node compatibility mode or this is not an ESM
  // file that has been converted to a CommonJS file using a Babel-
  // compatible transform (i.e. "__esModule" has not been set), then set
  // "default" to the CommonJS "module.exports" for node compatibility.
  isNodeMode || !mod || !mod.__esModule ? __defProp(target, "default", { value: mod, enumerable: true }) : target,
  mod
));

// ../types/dist/types/unlockTypes.js
var require_unlockTypes = __commonJS({
  "../types/dist/types/unlockTypes.js"(exports) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.KeyStatus = exports.HooksName = exports.HookType = exports.TransactionStatus = exports.TransactionType = void 0;
    var TransactionType;
    (function(TransactionType2) {
      TransactionType2["LOCK_CREATION"] = "LOCK_CREATION";
      TransactionType2["KEY_PURCHASE"] = "KEY_PURCHASE";
      TransactionType2["WITHDRAWAL"] = "WITHDRAWAL";
      TransactionType2["UPDATE_KEY_PRICE"] = "UPDATE_KEY_PRICE";
    })(TransactionType = exports.TransactionType || (exports.TransactionType = {}));
    var TransactionStatus;
    (function(TransactionStatus2) {
      TransactionStatus2["SUBMITTED"] = "submitted";
      TransactionStatus2["PENDING"] = "pending";
      TransactionStatus2["MINED"] = "mined";
      TransactionStatus2["STALE"] = "stale";
      TransactionStatus2["FAILED"] = "failed";
      TransactionStatus2["NONE"] = "";
    })(TransactionStatus = exports.TransactionStatus || (exports.TransactionStatus = {}));
    var HookType11;
    (function(HookType12) {
      HookType12["CUSTOM_CONTRACT"] = "CUSTOM_CONTRACT";
      HookType12["PASSWORD"] = "PASSWORD";
      HookType12["PROMOCODE"] = "PROMOCODE";
      HookType12["CAPTCHA"] = "CAPTCHA";
      HookType12["GUILD"] = "GUILD";
    })(HookType11 = exports.HookType || (exports.HookType = {}));
    exports.HooksName = [
      "onKeyPurchaseHook",
      "onKeyCancelHook",
      "onValidKeyHook",
      "onTokenURIHook",
      "onKeyTransferHook",
      "onKeyExtendHook",
      "onKeyGrantHook"
    ];
    var KeyStatus;
    (function(KeyStatus2) {
      KeyStatus2["NONE"] = "none";
      KeyStatus2["CONFIRMING"] = "confirming";
      KeyStatus2["CONFIRMED"] = "confirmed";
      KeyStatus2["EXPIRED"] = "expired";
      KeyStatus2["VALID"] = "valid";
      KeyStatus2["SUBMITTED"] = "submitted";
      KeyStatus2["PENDING"] = "pending";
      KeyStatus2["FAILED"] = "failed";
    })(KeyStatus = exports.KeyStatus || (exports.KeyStatus = {}));
  }
});

// ../types/dist/index.js
var require_dist = __commonJS({
  "../types/dist/index.js"(exports) {
    "use strict";
    var __createBinding = exports && exports.__createBinding || (Object.create ? function(o, m, k, k2) {
      if (k2 === void 0)
        k2 = k;
      var desc = Object.getOwnPropertyDescriptor(m, k);
      if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
        desc = { enumerable: true, get: function() {
          return m[k];
        } };
      }
      Object.defineProperty(o, k2, desc);
    } : function(o, m, k, k2) {
      if (k2 === void 0)
        k2 = k;
      o[k2] = m[k];
    });
    var __exportStar = exports && exports.__exportStar || function(m, exports2) {
      for (var p in m)
        if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports2, p))
          __createBinding(exports2, m, p);
    };
    Object.defineProperty(exports, "__esModule", { value: true });
    __exportStar(require_unlockTypes(), exports);
  }
});

// src/networks/index.ts
var networks_exports = {};
__export(networks_exports, {
  arbitrum: () => arbitrum,
  avalanche: () => avalanche,
  base: () => base,
  baseGoerli: () => baseGoerli,
  bsc: () => bsc,
  celo: () => celo,
  gnosis: () => gnosis,
  goerli: () => goerli,
  localhost: () => localhost,
  mainnet: () => mainnet,
  mumbai: () => mumbai,
  optimism: () => optimism,
  palm: () => palm,
  polygon: () => polygon
});

// src/networks/localhost.ts
var localhost = {
  publicLockVersionToDeploy: 13,
  id: 31337,
  name: "localhost",
  chain: "localhost",
  provider: "http://127.0.0.1:8545",
  publicProvider: "http://127.0.0.1:8545",
  subgraph: {
    endpoint: "http://localhost:8000/subgraphs/name/unlock-protocol/unlock",
    endpointV2: "http://localhost:8000/subgraphs/name/testgraph"
  },
  nativeCurrency: {
    name: "ETH",
    symbol: "ETH",
    decimals: 18,
    coingecko: "ethereum"
  },
  serializerAddress: "0x959922bE3CAee4b8Cd9a407cc3ac1C251C2007B1",
  description: "Localhost network.",
  isTestNetwork: true,
  fullySubsidizedGas: true
};

// src/networks/mainnet.ts
var import_types = __toESM(require_dist());
var mainnet = {
  publicLockVersionToDeploy: 13,
  featured: true,
  id: 1,
  publicProvider: "https://cloudflare-eth.com/v1/mainnet",
  provider: "https://rpc.unlock-protocol.com/1",
  unlockAddress: "0xe79B93f8E22676774F2A8dAd469175ebd00029FA",
  previousDeploys: [
    {
      unlockAddress: "0x3d5409CcE1d45233dE1D4eBDEe74b8E004abDD13",
      startBlock: 7120795
    }
  ],
  multisig: "0x9168EABE624e9515f3836bA1716EC1DDd4C461D4",
  keyManagerAddress: "0x9A1f43090307034DBFBE2ba20320Ce815ff046D4",
  name: "Ethereum",
  chain: "ethereum",
  subgraph: {
    endpoint: "https://api.thegraph.com/subgraphs/name/unlock-protocol/unlock",
    endpointV2: "https://api.thegraph.com/subgraphs/name/unlock-protocol/mainnet-v2"
  },
  explorer: {
    name: "Etherscan",
    urls: {
      base: "https://etherscan.io/",
      address: (address) => `https://etherscan.io/address/${address}`,
      transaction: (hash) => `https://etherscan.io/tx/${hash}`,
      token: (address, holder) => `https://etherscan.com/token/${address}?a=${holder}`
    }
  },
  opensea: {
    tokenUrl: (lockAddress, tokenId) => `https://opensea.io/assets/${lockAddress}/${tokenId}`,
    collectionUrl: (lockAddress) => `https://opensea.io/assets/${lockAddress}`,
    profileUrl: (address) => `https://opensea.io/${address}`
  },
  blockScan: {
    url: (address) => `https://blockscan.com/address/${address}`
  },
  nativeCurrency: {
    name: "Ether",
    symbol: "ETH",
    decimals: 18,
    coingecko: "ethereum"
  },
  startBlock: 16989e3,
  description: "The original and most secure EVM network. Gas fees are expensive on this network.",
  url: "https://ethereum.org/en/",
  isTestNetwork: false,
  maxFreeClaimCost: 1,
  uniswapV2: {
    oracle: "0xE118d797E1c44F2e2A2823191a51D8b46a4A1D51"
  },
  uniswapV3: {
    subgraph: "https://api.thegraph.com/subgraphs/name/uniswap/uniswap-v3",
    factoryAddress: "0x1F98431c8aD98523631AE4a59f267346ea31F984",
    quoterAddress: "0xb27308f9F90D607463bb33eA1BeBb41C27CE5AB6",
    oracle: "0x951A807b523cF6e178e0ab80fBd2C9B035521931",
    universalRouterAddress: "0xEf1c6E67703c7BD7107eed8303Fbe6EC2554BF6B"
  },
  swapPurchaser: "0x02415541c7F4c976722493181cFdb0b46E1c94fb",
  wrappedNativeCurrency: {
    name: "Wrapped Ether",
    symbol: "WETH",
    decimals: 18,
    address: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2"
  },
  tokens: [
    {
      name: "Wrapped Ether",
      address: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
      symbol: "WETH",
      decimals: 18
    },
    {
      name: "Dai Stablecoin",
      address: "0x6B175474E89094C44Da98b954EedeAC495271d0F",
      symbol: "DAI",
      decimals: 18
    },
    {
      name: "USD Coin",
      address: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
      symbol: "USDC",
      decimals: 6
    },
    {
      address: "0x7D1AfA7B718fb893dB30A3aBc0Cfc608AaCfeBB0",
      name: "Polygon",
      symbol: "MATIC",
      decimals: 18
    },
    {
      name: "Tether USD",
      address: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
      symbol: "USDT",
      decimals: 6
    },
    {
      name: "Wrapped BTC",
      address: "0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599",
      symbol: "WBTC",
      decimals: 8
    },
    {
      address: "0x0D8775F648430679A709E98d2b0Cb6250d2887EF",
      name: "Basic Attention Token",
      symbol: "BAT",
      decimals: 18
    }
  ],
  hooks: {
    onKeyPurchaseHook: [
      {
        id: import_types.HookType.PASSWORD,
        name: "Password required",
        address: "0x936Ed3E71b5990bC9A94074835D08C6ca7bbFad0"
      },
      {
        id: import_types.HookType.CAPTCHA,
        name: "Captcha",
        address: "0xaF96721f8ffc136e4C170446E68Dc6744B6Ee4f4"
      },
      {
        id: import_types.HookType.GUILD,
        name: "Guild",
        address: "0x1d8DD27432cC0aCB93B93c4486F46b67E2208359"
      }
    ]
  }
  // universalCard: {
  //   cardPurchaserAddress: '0x49814dd8a03594bE78a18455CC4Df3876ecFbD69',
  //   stripeDestinationNetwork: 'ethereum',
  //   stripeDestinationCurrency: 'usdc',
  // },
};

// src/networks/gnosis.ts
var import_types2 = __toESM(require_dist());
var gnosis = {
  publicLockVersionToDeploy: 13,
  featured: true,
  publicProvider: "https://rpc.gnosischain.com",
  provider: "https://rpc.unlock-protocol.com/100",
  unlockAddress: "0x1bc53f4303c711cc693F6Ec3477B83703DcB317f",
  serializerAddress: "0x646E373EAf8a4AEc31Bf62B7Fd6fB59296d6CdA9",
  multisig: "0xfAC611a5b5a578628C28F77cEBDDB8C6159Ae79D",
  keyManagerAddress: "0xBa81C9379AC1221BF8C100800dD0B0b0b048ba14",
  id: 100,
  name: "Gnosis Chain",
  chain: "xdai",
  // This is used in llama pricing API so can't rename.
  subgraph: {
    endpoint: "https://api.thegraph.com/subgraphs/name/unlock-protocol/xdai",
    endpointV2: "https://api.thegraph.com/subgraphs/name/unlock-protocol/gnosis-v2"
  },
  explorer: {
    name: "Blockscout",
    urls: {
      base: `https://gnosisscan.io/`,
      address: (address) => `https://gnosisscan.io/address/${address}/transactions`,
      transaction: (hash) => `https://gnosisscan.io/tx/${hash}`,
      token: (address, _holder) => `https://gnosisscan.io/token/${address}/token-holders#holders`
    }
  },
  opensea: {
    tokenUrl: (_lockAddress, _tokenId) => null
  },
  nativeCurrency: {
    name: "xDAI",
    symbol: "xDAI",
    decimals: 18,
    coingecko: "xdai",
    coinbase: "DAI"
  },
  startBlock: 19338700,
  previousDeploys: [
    {
      unlockAddress: "0x14bb3586Ce2946E71B95Fe00Fc73dd30ed830863",
      startBlock: 14521200
    }
  ],
  description: "Gnosis Chain is one of the first Ethereum sidechains and has stayed true to its values.",
  url: "https://www.gnosis.io/",
  faucet: "https://gnosisfaucet.com/",
  isTestNetwork: false,
  maxFreeClaimCost: 100,
  tokens: [
    {
      name: "USD Coin",
      symbol: "USDC",
      decimals: 6,
      address: "0xddafbb505ad214d7b80b1f830fccc89b60fb7a83"
    },
    {
      name: "Tether USD",
      decimals: 6,
      address: "0x4ecaba5870353805a9f068101a40e0f32ed605c6",
      symbol: "USDT"
    }
  ],
  hooks: {
    onKeyPurchaseHook: [
      {
        id: import_types2.HookType.PASSWORD,
        name: "Password required",
        address: "0x927D68eAE936Ec0111f01Fc4Ddd9cC57DB3f0Af2"
      },
      {
        id: import_types2.HookType.CAPTCHA,
        name: "Captcha",
        address: "0xFb0657eAE55A4dd3E2317C9eCB311bA5Ecc62C9C"
      },
      {
        id: import_types2.HookType.GUILD,
        name: "Guild",
        address: "0xe20738d9798B5B5801aEEFDB81d80Fcce3a3Aa95"
      }
    ]
  }
};

// src/networks/polygon.ts
var import_types3 = __toESM(require_dist());
var polygon = {
  publicLockVersionToDeploy: 13,
  featured: true,
  publicProvider: "https://polygon-rpc.com/",
  provider: "https://rpc.unlock-protocol.com/137",
  unlockAddress: "0xE8E5cd156f89F7bdB267EabD5C43Af3d5AF2A78f",
  multisig: "0x479f3830fbd715342868BA95E438609BCe443DFB",
  serializerAddress: "0x646e373eaf8a4aec31bf62b7fd6fb59296d6cda9",
  keyManagerAddress: "0x7111a1aDfbED501beaAd556Bba7cB6dCa3296aa9",
  universalCard: {
    cardPurchaserAddress: "0xAB355a589CFbBA7a21b91E5B6063bF822dCc0016",
    stripeDestinationNetwork: "polygon",
    stripeDestinationCurrency: "usdc"
  },
  id: 137,
  name: "Polygon",
  chain: "polygon",
  subgraph: {
    endpoint: "https://api.thegraph.com/subgraphs/name/unlock-protocol/polygon",
    endpointV2: "https://api.thegraph.com/subgraphs/name/unlock-protocol/polygon-v2",
    networkName: "matic"
  },
  url: "https://polygon.technology/",
  explorer: {
    name: "Polygonscan",
    urls: {
      base: `https://polygonscan.com/`,
      address: (address) => `https://polygonscan.com/address/${address}`,
      transaction: (hash) => `https://polygonscan.com/tx/${hash}`,
      token: (address, holder) => `https://polygonscan.com/token/${address}?a=${holder}`
    }
  },
  opensea: {
    tokenUrl: (lockAddress, tokenId) => `https://opensea.io/assets/matic/${lockAddress}/${tokenId}`,
    collectionUrl: (lockAddress) => `https://opensea.io/assets/matic/${lockAddress}`
  },
  nativeCurrency: {
    name: "Matic",
    symbol: "MATIC",
    decimals: 18,
    coingecko: "matic-network"
  },
  startBlock: 21986688,
  previousDeploys: [
    {
      unlockAddress: "0x14bb3586Ce2946E71B95Fe00Fc73dd30ed830863",
      startBlock: 15714206
    }
  ],
  description: "Polygon is a side-chain to build and scale your projects on Ethereum, the world's largest blockchain ecosystem.",
  isTestNetwork: false,
  maxFreeClaimCost: 100,
  uniswapV2: {
    oracle: "0xE20ef269CE3ac2Af8107E706FC2Ec6E1831e3125"
  },
  uniswapV3: {
    factoryAddress: "0x1F98431c8aD98523631AE4a59f267346ea31F984",
    quoterAddress: "0xb27308f9F90D607463bb33eA1BeBb41C27CE5AB6",
    oracle: "0xE77c7F14e8EB9925ca418bF80c0a81a5B9C87683",
    universalRouterAddress: "0x4C60051384bd2d3C01bfc845Cf5F4b44bcbE9de5"
  },
  swapPurchaser: "0x33aC9CAE1Cd9CBB191116607f564F7381d81BAD9",
  wrappedNativeCurrency: {
    name: "Wrapped MATIC",
    symbol: "WMATIC",
    decimals: 18,
    address: "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270"
  },
  tokens: [
    {
      name: "Wrapped Ether",
      address: "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619",
      symbol: "WETH",
      decimals: 18
    },
    {
      name: "Dai Stablecoin",
      address: "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
      symbol: "DAI",
      decimals: 18
    },
    {
      name: "Tether USD",
      address: "0xc2132D05D31c914a87C6611C10748AEb04B58e8F",
      symbol: "USDT",
      decimals: 6
    },
    {
      name: "USD Coin",
      address: "0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174",
      symbol: "USDC",
      decimals: 6
    },
    {
      name: "Wrapped BTC",
      address: "0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6",
      symbol: "WBTC",
      decimals: 8
    }
  ],
  hooks: {
    onKeyPurchaseHook: [
      {
        id: import_types3.HookType.PASSWORD,
        name: "Password required",
        address: "0x9F4AE507d7E91Ab37CF35f792940fE079bd4E24d"
      },
      {
        id: import_types3.HookType.CAPTCHA,
        name: "Captcha",
        address: "0x64bDe27046F915e2BAb6339Ce4f737E34474344d"
      },
      {
        id: import_types3.HookType.GUILD,
        name: "Guild",
        address: "0xBfF080aB4600554c1e8c390d2e070CF423767B64"
      },
      {
        id: import_types3.HookType.PROMOCODE,
        name: "Discount code",
        address: "0x93E160838c529873cB7565106bBb79a3226FE07A"
      }
    ]
  }
};

// src/networks/bsc.ts
var import_types4 = __toESM(require_dist());
var bsc = {
  publicLockVersionToDeploy: 13,
  publicProvider: "https://bsc-dataseed.binance.org/",
  provider: "https://rpc.unlock-protocol.com/56",
  unlockAddress: "0xeC83410DbC48C7797D2f2AFe624881674c65c856",
  keyManagerAddress: "0x34EbEc0AE80A2d078DE5489f0f5cAa4d3aaEA355",
  id: 56,
  name: "BNB Chain",
  chain: "bsc",
  multisig: "0x373D7cbc4F2700719DEa237500c7a154310B0F9B",
  subgraph: {
    endpoint: "https://api.thegraph.com/subgraphs/name/unlock-protocol/bsc",
    endpointV2: "https://api.thegraph.com/subgraphs/name/unlock-protocol/bsc-v2"
  },
  explorer: {
    name: "BscScan",
    urls: {
      base: `https://bscscan.com/`,
      address: (address) => `https://bscscan.com/address/${address}`,
      transaction: (hash) => `https://bscscan.com/tx/${hash}`,
      token: (address, holder) => `https://bscscan.com/token/${address}?a=${holder}`
    }
  },
  opensea: {
    tokenUrl: (_lockAddress, _tokenId) => null
  },
  nativeCurrency: {
    name: "BNB",
    symbol: "BNB",
    decimals: 18,
    coingecko: "binancecoin"
  },
  uniswapV3: {
    universalRouterAddress: "0x4648a43B2C14Da09FdF82B161150d3F634f40491"
  },
  startBlock: 13079e3,
  // 12368889,
  previousDeploys: [
    {
      unlockAddress: "0x99b1348a9129ac49c6de7F11245773dE2f51fB0c",
      startBlock: 12396e3
    }
  ],
  description: "The best-performing EVM compatible layer 1. Fully compatible tooling for EVM with up to 35 times of capacity.",
  url: "https://www.bnbchain.org/en",
  isTestNetwork: false,
  maxFreeClaimCost: 1,
  swapPurchaser: "0x5Ad19758103D474bdF5E8764D97cB02b83c3c844",
  tokens: [
    {
      name: "Ethereum",
      symbol: "ETH",
      decimals: 18,
      address: "0x2170ed0880ac9a755fd29b2688956bd959f933f8"
    },
    {
      name: "USD Coin",
      symbol: "USDC",
      decimals: 18,
      address: "0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d"
    },
    {
      name: "Tether",
      symbol: "USDT",
      decimals: 18,
      address: "0x55d398326f99059ff775485246999027b3197955"
    },
    {
      name: "Dai",
      symbol: "DAI",
      decimals: 18,
      address: "0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3"
    }
  ],
  hooks: {
    onKeyPurchaseHook: [
      {
        id: import_types4.HookType.PASSWORD,
        name: "Password required",
        address: "0x338b1f296217485bf4df6CE9f93ab4C73F72b57D"
      },
      {
        id: import_types4.HookType.CAPTCHA,
        name: "Captcha",
        address: "0x80E085D7591C61153D876b5171dc25756a7A3254"
      },
      {
        id: import_types4.HookType.GUILD,
        name: "Guild",
        address: "0xF6963D3c395A7914De77f771C2fC44b47B8379AC"
      }
    ]
  }
};

// src/networks/optimism.ts
var import_types5 = __toESM(require_dist());
var optimism = {
  publicLockVersionToDeploy: 13,
  featured: true,
  publicProvider: "https://mainnet.optimism.io",
  provider: "https://rpc.unlock-protocol.com/10",
  unlockAddress: "0x99b1348a9129ac49c6de7F11245773dE2f51fB0c",
  multisig: "0x6E78b4447e34e751EC181DCBed63633aA753e145",
  keyManagerAddress: "0x8c5D54B2CAA4C2D08B0DDF82a1e6D2641779B8EC",
  id: 10,
  name: "Optimism",
  chain: "optimism",
  subgraph: {
    endpoint: "https://api.thegraph.com/subgraphs/name/unlock-protocol/optimism",
    endpointV2: "https://api.thegraph.com/subgraphs/name/unlock-protocol/optimism-v2"
  },
  explorer: {
    name: "Etherscan",
    urls: {
      base: `https://optimistic.etherscan.io/`,
      address: (address) => `https://optimistic.etherscan.io/address/${address}`,
      transaction: (hash) => `https://optimistic.etherscan.io/tx/${hash}`,
      token: (address, holder) => `https://optimistic.etherscan.io/token/${address}?a=${holder}`
    }
  },
  opensea: {
    tokenUrl: (_lockAddress, _tokenId) => `https://opensea.io/assets/optimism/${_lockAddress}/${_tokenId}`,
    collectionUrl: (lockAddress) => `https://opensea.io/assets/optimism/${lockAddress}`
  },
  nativeCurrency: {
    name: "Ether",
    symbol: "ETH",
    decimals: 18,
    coingecko: "ethereum"
  },
  description: "Optimism is a Layer 2 Optimistic Rollup network designed to utilize the strong security guarantees of Ethereum while reducing its cost and latency.",
  url: "https://www.optimism.io/",
  isTestNetwork: false,
  maxFreeClaimCost: 10,
  uniswapV3: {
    factoryAddress: "0x1F98431c8aD98523631AE4a59f267346ea31F984",
    quoterAddress: "0xb27308f9F90D607463bb33eA1BeBb41C27CE5AB6",
    oracle: "0x1FF7e338d5E582138C46044dc238543Ce555C963",
    universalRouterAddress: "0xb555edF5dcF85f42cEeF1f3630a52A108E55A654"
  },
  swapPurchaser: "0x72381052e4F7765A00a403891420BF75876c75bB",
  wrappedNativeCurrency: {
    name: "Wrapped Ether",
    symbol: "WETH",
    decimals: 18,
    address: "0x4200000000000000000000000000000000000006"
  },
  tokens: [
    {
      name: "Ethereum",
      symbol: "ETH",
      decimals: 18,
      address: "0x0000000000000000000000000000000000000000"
    },
    {
      name: "USD Coin",
      symbol: "USDC",
      decimals: 6,
      address: "0x7f5c764cbc14f9669b88837ca1490cca17c31607"
    },
    {
      name: "Tether",
      symbol: "USDT",
      decimals: 6,
      address: "0x94b008aa00579c1307b0ef2c499ad98a8ce58e58"
    },
    {
      name: "Dai",
      symbol: "DAI",
      decimals: 18,
      address: "0xda10009cbd5d07dd0cecc66161fc93d7c9000da1"
    }
  ],
  hooks: {
    onKeyPurchaseHook: [
      {
        id: import_types5.HookType.PASSWORD,
        name: "Password required",
        address: "0x34EbEc0AE80A2d078DE5489f0f5cAa4d3aaEA355"
      },
      {
        id: import_types5.HookType.CAPTCHA,
        name: "Captcha",
        address: "0xF6a9138b4ebEd2AcF651Cbd40B45584B4c625e87"
      },
      {
        id: import_types5.HookType.GUILD,
        name: "Guild",
        address: "0x1402D55BF0D6566ca8F569041000a8015b608632"
      },
      {
        id: import_types5.HookType.PROMOCODE,
        name: "Discount code",
        address: "0x8e0B46ec3B95c81355175693dA0083b00fCc1326"
      }
    ]
  }
};

// src/networks/mumbai.ts
var import_types6 = __toESM(require_dist());
var mumbai = {
  publicLockVersionToDeploy: 13,
  publicProvider: "https://matic-mumbai.chainstacklabs.com",
  provider: "https://rpc.unlock-protocol.com/80001",
  unlockAddress: "0x1FF7e338d5E582138C46044dc238543Ce555C963",
  multisig: "0x12E37A8880801E1e5290c815a894d322ac591607",
  keyManagerAddress: "0x8c5D54B2CAA4C2D08B0DDF82a1e6D2641779B8EC",
  id: 80001,
  name: "Mumbai (Polygon)",
  chain: "mumbai",
  subgraph: {
    endpoint: "https://api.thegraph.com/subgraphs/name/unlock-protocol/mumbai",
    endpointV2: "https://api.thegraph.com/subgraphs/name/unlock-protocol/mumbai-v2"
  },
  explorer: {
    name: "PolygonScan (Mumbai)",
    urls: {
      base: `https://mumbai.polygonscan.com/`,
      address: (address) => `https://mumbai.polygonscan.com/address/${address}`,
      transaction: (hash) => `https://mumbai.polygonscan.com/tx/${hash}`,
      token: (address, holder) => `https://mumbai.polygonscan.com/token/${address}?a=${holder}`
    }
  },
  opensea: {
    tokenUrl: (_lockAddress, _tokenId) => null
  },
  nativeCurrency: {
    name: "MATIC",
    symbol: "MATIC",
    decimals: 18,
    coingecko: "matic-network"
  },
  startBlock: 26584912,
  previousDeploys: [],
  description: "Polygon test network. Do not use for production.",
  url: "https://mumbai.polygonscan.com/",
  faucet: "https://faucet.polygon.technology/",
  isTestNetwork: true,
  fullySubsidizedGas: true,
  maxFreeClaimCost: 500,
  uniswapV3: {
    factoryAddress: "0x1F98431c8aD98523631AE4a59f267346ea31F984",
    quoterAddress: "0xb27308f9F90D607463bb33eA1BeBb41C27CE5AB6",
    oracle: "0x5108412Dd50A6ea79d2F13D5d1A23FDD9bF532db",
    universalRouterAddress: "0x4648a43B2C14Da09FdF82B161150d3F634f40491"
  },
  swapPurchaser: "0x302E9D970A657B42c1C124C69f3a1c1575CB4AD3",
  unlockOwner: "0xdc230F9A08918FaA5ae48B8E13647789A8B6dD46",
  wrappedNativeCurrency: {
    name: "Wrapped MATIC",
    symbol: "WMATIC",
    decimals: 18,
    address: "0x9c3C9283D3e44854697Cd22D3Faa240Cfb032889"
  },
  tokens: [
    {
      name: "USD Coin",
      address: "0x0FA8781a83E46826621b3BC094Ea2A0212e71B23",
      symbol: "USDC",
      decimals: 6,
      mainnetAddress: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48"
    },
    {
      name: "Wrapped Ether",
      address: "0xA6FA4fB5f76172d178d61B04b0ecd319C5d1C0aa",
      symbol: "WETH",
      decimals: 18,
      mainnetAddress: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2"
    },
    {
      name: "Wrapped Matic",
      address: "0x9c3C9283D3e44854697Cd22D3Faa240Cfb032889",
      symbol: "WMATIC",
      decimals: 18,
      mainnetAddress: "0x7D1AfA7B718fb893dB30A3aBc0Cfc608AaCfeBB0"
    },
    {
      name: "Dai Stablecoin",
      address: "0xcB1e72786A6eb3b44C2a2429e317c8a2462CFeb1",
      symbol: "DAI",
      decimals: 18,
      mainnetAddress: "0x6B175474E89094C44Da98b954EedeAC495271d0F"
    }
  ],
  bridge: {
    domainId: 9991,
    connext: "0x2334937846Ab2A3FCE747b32587e1A1A2f6EEC5a"
  },
  hooks: {
    onKeyPurchaseHook: [
      {
        id: import_types6.HookType.PASSWORD,
        name: "Password required",
        address: "0x34EbEc0AE80A2d078DE5489f0f5cAa4d3aaEA355"
      },
      {
        id: import_types6.HookType.CAPTCHA,
        name: "Captcha",
        address: "0xdd753E08BB09F22593537f29100F0eD98AfA57FA"
      }
    ]
  }
};

// src/networks/goerli.ts
var import_types7 = __toESM(require_dist());
var goerli = {
  publicLockVersionToDeploy: 13,
  universalCard: {
    cardPurchaserAddress: "0x061Bfda0446c5f71Da760BC31371CB95808f5d82",
    stripeDestinationNetwork: "ethereum",
    stripeDestinationCurrency: "usdc"
  },
  featured: true,
  publicProvider: "https://goerli.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
  provider: "https://rpc.unlock-protocol.com/5",
  unlockAddress: "0x627118a4fB747016911e5cDA82e2E77C531e8206",
  multisig: "0x95C06469e557d8645966077891B4aeDe8D55A755",
  keyManagerAddress: "0xc328aE7fc36f975BE120aaa99f2d96C3E732e5b6",
  id: 5,
  name: "Goerli (Testnet)",
  chain: "goerli",
  subgraph: {
    endpoint: "https://api.thegraph.com/subgraphs/name/unlock-protocol/goerli",
    endpointV2: "https://api.thegraph.com/subgraphs/name/unlock-protocol/goerli-v2"
  },
  explorer: {
    name: "Goerli (Testnet)",
    urls: {
      base: `https://goerli.etherscan.io/`,
      address: (address) => `https://goerli.etherscan.io/address/${address}`,
      transaction: (hash) => `https://goerli.etherscan.io/tx/${hash}`,
      token: (address, holder) => `https://goerli.etherscan.io/token/${address}?a=${holder}`
    }
  },
  opensea: {
    tokenUrl: (lockAddress, tokenId) => `https://testnets.opensea.io/assets/goerli/${lockAddress}/${tokenId}`,
    collectionUrl: (lockAddress) => `https://testnets.opensea.io/assets/goerli/${lockAddress}`
  },
  description: "Main Ethereum test network. Do not use for production.",
  faucet: "https://goerlifaucet.com/",
  nativeCurrency: {
    name: "ETH",
    symbol: "ETH",
    decimals: 18,
    coingecko: "ethereum"
  },
  startBlock: 7179039,
  previousDeploys: [],
  isTestNetwork: true,
  fullySubsidizedGas: true,
  maxFreeClaimCost: 1e5,
  uniswapV3: {
    factoryAddress: "0x1F98431c8aD98523631AE4a59f267346ea31F984",
    oracle: "0x25197CaCDE16500032EF4B35d60c6f7aEd4a38a5",
    universalRouterAddress: "0x4648a43B2C14Da09FdF82B161150d3F634f40491"
  },
  swapPurchaser: "0x49aD0039B30De002d4C27A6E8Fc026c7e23d083C",
  unlockOwner: "0x6E74DC46EbF2cDB75B72Ab1dCAe3C98c7E9d28a1",
  wrappedNativeCurrency: {
    name: "Wrapped Ether",
    symbol: "WETH",
    decimals: 18,
    address: "0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6"
  },
  tokens: [
    {
      name: "USD Coin",
      symbol: "USDC",
      address: "0x07865c6E87B9F70255377e024ace6630C1Eaa37F",
      decimals: 6,
      mainnetAddress: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48"
    },
    {
      name: "Wrapped Ether",
      address: "0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6",
      symbol: "WETH",
      decimals: 18,
      mainnetAddress: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2"
    },
    {
      name: "Uniswap",
      address: "0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984",
      symbol: "UNI",
      decimals: 18,
      mainnetAddress: "0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984"
    }
  ],
  bridge: {
    domainId: 1735353714,
    connext: "0xFCa08024A6D4bCc87275b1E4A1E22B71fAD7f649"
  },
  hooks: {
    onKeyPurchaseHook: [
      {
        id: import_types7.HookType.PASSWORD,
        name: "Password required",
        address: "0xCa837900f7DaB40787b608b6738d1B730f1d2759"
      },
      {
        id: import_types7.HookType.CAPTCHA,
        name: "Captcha",
        address: "0x0e646EF3F880eB9C5C97f0D6c113c40b2f442dbe"
      },
      {
        id: import_types7.HookType.GUILD,
        name: "Guild",
        address: "0x1AC9271D271b8E50537CAd54b330424C52A84822"
      },
      {
        id: import_types7.HookType.PROMOCODE,
        name: "Discount code",
        address: "0x850c015A6A88756a59Dc025fca988494fF90DBB7"
      }
    ]
  },
  defenderRelay: {
    apiKey: "ABoZPmooUbZNrMxjLfK5ZjAc3ojNtS9L"
  }
};

// src/networks/celo.ts
var import_types8 = __toESM(require_dist());
var celo = {
  publicLockVersionToDeploy: 13,
  publicProvider: "https://forno.celo.org",
  provider: "https://rpc.unlock-protocol.com/42220",
  unlockAddress: "0x1FF7e338d5E582138C46044dc238543Ce555C963",
  multisig: "0xc293E2da9E558bD8B1DFfC4a7b174729fAb2e4E8",
  keyManagerAddress: "0xF6963D3c395A7914De77f771C2fC44b47B8379AC",
  id: 42220,
  name: "Celo",
  chain: "celo",
  description: "Celo is the carbon-negative, mobile-first, EVM-compatible blockchain ecosystem leading a thriving new digital economy for all.",
  url: "https://celo.org",
  subgraph: {
    endpoint: "https://api.thegraph.com/subgraphs/name/unlock-protocol/celo",
    endpointV2: "https://api.thegraph.com/subgraphs/name/unlock-protocol/celo-v2"
  },
  explorer: {
    name: "Celoscan",
    urls: {
      base: `https://celoscan.io/`,
      address: (address) => `https://celoscan.io/address/${address}`,
      transaction: (hash) => `https://celoscan.io/tx/${hash}`,
      token: (address, holder) => `https://celoscan.io/token/${address}?a=${holder}`
    }
  },
  opensea: {
    tokenUrl: (_lockAddress, _tokenId) => null
  },
  nativeCurrency: {
    name: "CELO",
    symbol: "CELO",
    decimals: 18,
    coingecko: "celo"
  },
  startBlock: 7179039,
  previousDeploys: [],
  isTestNetwork: false,
  maxFreeClaimCost: 1,
  uniswapV3: {
    factoryAddress: "0xAfE208a311B21f13EF87E33A90049fC17A7acDEc",
    quoterAddress: "0x82825d0554fA07f7FC52Ab63c961F330fdEFa8E8",
    oracle: "0x5108412Dd50A6ea79d2F13D5d1A23FDD9bF532db",
    universalRouterAddress: "0xC73d61d192FB994157168Fb56730FdEc64C9Cb8F"
  },
  swapPurchaser: "0x42F5c7839Bf00FAea6ca09517E96E82e7364384D",
  wrappedNativeCurrency: {
    name: "Celo native asset",
    symbol: "CELO",
    decimals: 18,
    address: "0x471ece3750da237f93b8e339c536989b8978a438"
  },
  tokens: [
    {
      name: "USD Coin",
      symbol: "USDC",
      decimals: 6,
      address: "0xef4229c8c3250c675f21bcefa42f58efbff6002a",
      mainnetAddress: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48"
    },
    {
      name: "Dai Stablecoin",
      address: "0xE4fE50cdD716522A56204352f00AA110F731932d",
      symbol: "DAI",
      decimals: 18,
      mainnetAddress: "0x6B175474E89094C44Da98b954EedeAC495271d0F"
    }
  ],
  hooks: {
    onKeyPurchaseHook: [
      {
        id: import_types8.HookType.CAPTCHA,
        name: "Captcha",
        address: "0x1Bc951F8ed90F6c135F01Fe62CA348F4c3F43D00"
      },
      {
        id: import_types8.HookType.GUILD,
        name: "Guild",
        address: "0xdd753E08BB09F22593537f29100F0eD98AfA57FA"
      }
    ]
  }
};

// src/networks/arbitrum.ts
var import_types9 = __toESM(require_dist());
var arbitrum = {
  publicLockVersionToDeploy: 13,
  featured: true,
  publicProvider: "https://arb1.arbitrum.io/rpc",
  provider: "https://rpc.unlock-protocol.com/42161",
  unlockAddress: "0x1FF7e338d5E582138C46044dc238543Ce555C963",
  multisig: "0x310e9f9E3918a71dB8230cFCF32a083c7D9536d0",
  keyManagerAddress: "0x520294E736167303efa038205D4391b58261BC9c",
  id: 42161,
  name: "Arbitrum",
  chain: "arbitrum",
  subgraph: {
    endpoint: "https://api.thegraph.com/subgraphs/name/unlock-protocol/arbitrum",
    networkName: "arbitrum-one",
    endpointV2: "https://api.thegraph.com/subgraphs/name/unlock-protocol/arbitrum-v2"
  },
  explorer: {
    name: "Arbitrum",
    urls: {
      base: `https://arbiscan.io/`,
      address: (address) => `https://arbiscan.io/address/${address}`,
      transaction: (hash) => `https://arbiscan.io/tx/${hash}`,
      token: (address, holder) => `https://arbiscan.io/token/${address}?a=${holder}`
    }
  },
  opensea: {
    tokenUrl: (_lockAddress, _tokenId) => `https://opensea.io/assets/arbitrum/${_lockAddress}/${_tokenId}`,
    collectionUrl: (lockAddress) => `https://opensea.io/assets/arbitrum/${lockAddress}`
  },
  nativeCurrency: {
    name: "ETH",
    symbol: "ETH",
    decimals: 18,
    coingecko: "ethereum"
  },
  startBlock: 17429533,
  previousDeploys: [],
  isTestNetwork: false,
  maxFreeClaimCost: 100,
  description: "Arbitrum One is a Layer 2 (L2) chain running on top of Ethereum Mainnet that enables high-throughput, low cost smart contracts operations.",
  url: "https://arbitrum.io/",
  uniswapV3: {
    factoryAddress: "0x1F98431c8aD98523631AE4a59f267346ea31F984",
    quoterAddress: "0xb27308f9F90D607463bb33eA1BeBb41C27CE5AB6",
    oracle: "0x821d830a7b9902F83359Bf3Ac727B04b10FD461d",
    universalRouterAddress: "0x4C60051384bd2d3C01bfc845Cf5F4b44bcbE9de5"
  },
  swapPurchaser: "0x0C33884Ab3eE799E7628FA3fCF20B81997745a72",
  wrappedNativeCurrency: {
    name: "Wrapped Ether",
    symbol: "WETH",
    decimals: 18,
    address: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1"
  },
  tokens: [
    {
      name: "Ethereum",
      symbol: "WETH",
      decimals: 18,
      address: "0x82af49447d8a07e3bd95bd0d56f35241523fbab1"
    },
    {
      name: "USD Coin",
      symbol: "USDC",
      decimals: 6,
      address: "0xff970a61a04b1ca14834a43f5de4533ebddb5cc8"
    },
    {
      name: "Tether",
      symbol: "USDT",
      decimals: 6,
      address: "0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9"
    },
    {
      name: "Dai",
      symbol: "DAI",
      decimals: 18,
      address: "0xda10009cbd5d07dd0cecc66161fc93d7c9000da1"
    },
    {
      name: "Wrapped Bitcoin",
      symbol: "WBTC",
      decimals: 8,
      address: "0x2f2a2543b76a4166549f7aab2e75bef0aefc5b0f"
    }
  ],
  hooks: {
    onKeyPurchaseHook: [
      {
        id: import_types9.HookType.PASSWORD,
        name: "Password required",
        address: "0xd0b14797b9D08493392865647384974470202A78"
      },
      {
        id: import_types9.HookType.CAPTCHA,
        name: "Captcha",
        address: "0xD925Ac2887Ba4372849F0fd64217A6749552bb21"
      },
      {
        id: import_types9.HookType.GUILD,
        name: "Guild",
        address: "0x06538095ae3B5123e440D9991377B85C3BC6E6FF"
      }
    ]
  }
};

// src/networks/avalanche.ts
var import_types10 = __toESM(require_dist());
var avalanche = {
  publicLockVersionToDeploy: 13,
  publicProvider: "https://api.avax.network/ext/bc/C/rpc",
  provider: "https://rpc.unlock-protocol.com/43114",
  unlockAddress: "0x70cBE5F72dD85aA634d07d2227a421144Af734b3",
  multisig: "0xEc7777C51327917fd2170c62873272ea168120Cb",
  keyManagerAddress: "0x8e0B46ec3B95c81355175693dA0083b00fCc1326",
  id: 43114,
  name: "Avalanche (C-Chain)",
  chain: "avax",
  subgraph: {
    endpoint: "https://api.thegraph.com/subgraphs/name/unlock-protocol/avalanche",
    endpointV2: "https://api.thegraph.com/subgraphs/name/unlock-protocol/avalanche-v2"
  },
  explorer: {
    name: "Snowtrace (Avalanche)",
    urls: {
      base: `https://snowtrace.io/`,
      address: (address) => `https://snowtrace.io/address/${address}`,
      transaction: (hash) => `https://snowtrace.io/tx/${hash}`,
      token: (address, holder) => `https://snowtrace.io/token/${address}?a=${holder}`
    }
  },
  opensea: {
    tokenUrl: (_lockAddress, _tokenId) => null
  },
  nativeCurrency: {
    name: "AVAX",
    symbol: "AVAX",
    decimals: 18,
    coingecko: "avalanche-2"
  },
  startBlock: 17188332,
  previousDeploys: [],
  isTestNetwork: false,
  maxFreeClaimCost: 1,
  description: "Avalanche is an open, programmable smart contracts platform for decentralized applications.",
  url: "https://www.avalabs.org/",
  tokens: [
    {
      name: "Ethereum",
      symbol: "WETH",
      decimals: 18,
      address: "0x49d5c2bdffac6ce2bfdb6640f4f80f226bc10bab"
    },
    {
      name: "USD Coin",
      symbol: "USDC",
      decimals: 6,
      address: "0xa7d7079b0fead91f3e65f86e8915cb59c1a4c664"
    },
    {
      name: "Tether",
      symbol: "USDT",
      decimals: 6,
      address: "0xc7198437980c041c805a1edcba50c1ce5db95118"
    },
    {
      name: "Dai",
      symbol: "DAI",
      decimals: 18,
      address: "0xd586e7f844cea2f87f50152665bcbc2c279d8d70"
    },
    {
      name: "Wrapped Bitcoin",
      symbol: "WBTC",
      decimals: 8,
      address: "0x50b7545627a5162f82a992c33b87adc75187b218"
    }
  ],
  hooks: {
    onKeyPurchaseHook: [
      {
        id: import_types10.HookType.CAPTCHA,
        name: "Captcha",
        address: "0x2499D94880B30fA505543550ac8a1e24cfFeFe78"
      }
    ]
  }
};

// src/networks/palm.ts
var palm = {
  publicLockVersionToDeploy: 13,
  fullySubsidizedGas: true,
  publicProvider: "https://palm-mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
  provider: "https://rpc.unlock-protocol.com/11297108109",
  unlockAddress: "0x0314E34345C2327aC753C5Bf0D0751Cf6C1BfdE2",
  multisig: "0xABEAf8F93bEA2a2E65866CccC3060626eEc7d304",
  id: 11297108109,
  name: "Palm",
  chain: "palm",
  keyManagerAddress: "0x70cBE5F72dD85aA634d07d2227a421144Af734b3",
  subgraph: {
    endpoint: "https://graph.palm.io/subgraphs/name/unlock-protocol/Palm-mainnet",
    networkName: "palm-mainnet",
    endpointV2: "https://graph.palm.io/subgraphs/name/unlock-protocol/Palm-mainnet"
  },
  explorer: {
    name: "Palm Explorer",
    urls: {
      base: `https://explorer.palm.io/`,
      address: (address) => `https://explorer.palm.io/address/${address}`,
      transaction: (hash) => `https://explorer.palm.io/tx/${hash}`,
      token: (address, holder) => `https://explorer.palm.io/token/${address}?a=${holder}`
    }
  },
  opensea: {
    tokenUrl: (_lockAddress, _tokenId) => null
  },
  erc20: null,
  nativeCurrency: {
    name: "PALM",
    symbol: "PALM",
    decimals: 18,
    coingecko: "palm"
  },
  startBlock: 9856400,
  previousDeploys: [],
  isTestNetwork: false,
  maxFreeClaimCost: 1,
  description: "The Palm network is an Ethereum-compatible sidechain, built to serve as the foundation of a new scalable and sustainable ecosystem for NFTs. It is designed by, and for, Ethereum developers and features low gas costs and fast transaction finality.",
  uniswapV3: {
    factoryAddress: "0x",
    quoterAddress: "0x",
    oracle: "0x"
  },
  wrappedNativeCurrency: {
    name: "Wrapped PALM",
    symbol: "WPALM",
    decimals: 18,
    address: "0xF98cABF0a963452C5536330408B2590567611a71"
  },
  tokens: [
    {
      name: "Wrapped ETH",
      symbol: "WETH",
      decimals: 18,
      address: "0x726138359C17F1E56bA8c4F737a7CAf724F6010b"
    },
    {
      name: "Dai",
      symbol: "DAI",
      decimals: 18,
      address: "0x4C1f6fCBd233241bF2f4D02811E3bF8429BC27B8"
    }
  ]
};

// src/networks/base-goerli.ts
var baseGoerli = {
  publicLockVersionToDeploy: 13,
  publicProvider: "https://goerli.base.org",
  provider: "https://rpc.unlock-protocol.com/84531",
  unlockAddress: "0x51A1ceB83B83F1985a81C295d1fF28Afef186E02",
  multisig: "",
  keyManagerAddress: "0x70cBE5F72dD85aA634d07d2227a421144Af734b3",
  id: 84531,
  name: "Base Goerli (Testnet)",
  chain: "base-goerli",
  subgraph: {
    networkName: "base-testnet",
    endpoint: "https://api.studio.thegraph.com/query/44190/unlock-protocol-base-goerli/0.0.2",
    endpointV2: "https://api.studio.thegraph.com/query/44190/unlock-protocol-base-goerli/0.0.2"
  },
  explorer: {
    name: "Base Goerli",
    urls: {
      base: `https://goerli.basescan.org/`,
      address: (address) => `https://goerli.basescan.org/address/${address}`,
      transaction: (hash) => `https://goerli.basescan.org/tx/${hash}`,
      token: (address, holder) => `https://goerli.basescan.org/token/${address}?a=${holder}`
    }
  },
  opensea: {
    tokenUrl: (lockAddress, tokenId) => `https://testnets.opensea.io/assets/goerli/${lockAddress}/${tokenId}`,
    collectionUrl: (lockAddress) => `https://testnets.opensea.io/assets/goerli/${lockAddress}`
  },
  description: "Main Ethereum test network. Do not use for production.",
  nativeCurrency: {
    name: "ETH",
    symbol: "ETH",
    decimals: 18,
    coingecko: "ethereum"
  },
  startBlock: 2247300,
  isTestNetwork: true,
  fullySubsidizedGas: true,
  maxFreeClaimCost: 1e4
};

// src/networks/base.ts
var base = {
  publicLockVersionToDeploy: 13,
  publicProvider: "https://developer-access-mainnet.base.org",
  provider: "https://rpc.unlock-protocol.com/8453",
  unlockAddress: "0xd0b14797b9D08493392865647384974470202A78",
  multisig: "0x8149FeaFa41DD1ee3CA62299b9c67e9ac12FA340",
  keyManagerAddress: "0xD26c05a33349a6DeD02DD9360e1ef303d1246fb6",
  id: 8453,
  name: "Base",
  chain: "base",
  subgraph: {
    networkName: "base",
    endpoint: "https://api.studio.thegraph.com/query/44190/unlock-protocol-base/v0.0.1",
    endpointV2: "https://api.studio.thegraph.com/query/44190/unlock-protocol-base/v0.0.1"
  },
  explorer: {
    name: "Basescan",
    urls: {
      base: `https://basescan.org/`,
      address: (address) => `https://basescan.org/address/${address}`,
      transaction: (hash) => `https://basescan.org/tx/${hash}`,
      token: (address, holder) => `https://basescan.org/token/${address}?a=${holder}`
    }
  },
  description: "Base is a secure, low-cost, builder-friendly Ethereum L2 built to bring the next billion users onchain.  ",
  nativeCurrency: {
    name: "ETH",
    symbol: "ETH",
    decimals: 18,
    coingecko: "ethereum"
  },
  startBlock: 175e4,
  isTestNetwork: false,
  fullySubsidizedGas: false,
  maxFreeClaimCost: 10
};

// src/index.ts
var networks = {};
Object.keys(networks_exports).forEach((networkName) => {
  const network = networks_exports[networkName];
  networks[network.id] = network;
});
var src_default = networks;
export {
  arbitrum,
  avalanche,
  base,
  baseGoerli,
  bsc,
  celo,
  src_default as default,
  gnosis,
  goerli,
  localhost,
  mainnet,
  mumbai,
  networks,
  optimism,
  palm,
  polygon
};
