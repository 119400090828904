import { User } from "@boardroom/boardroom-api";
import React, { useReducer, createContext } from "react";

interface Props {
  children: React.ReactNode;
}

type Action = { type: "SAVE_USER_DETAILS"; data: User | null };

interface CurrentUserDetailsContextType {
  userDetails: User | null;
  dispatchUserDetails: React.Dispatch<any>;
}

const initialState = null;

export const CurrentUserDetailsContext = createContext({} as CurrentUserDetailsContextType);

const reducer = (state: User | null, action: Action) => {
  switch (action.type) {
    case "SAVE_USER_DETAILS":
      return action.data;
    default:
      return state;
  }
};

export const CurrentUserDetailsProvider = (props: Props) => {
  const [userDetails, dispatchUserDetails] = useReducer(reducer, initialState);

  return (
    <CurrentUserDetailsContext.Provider value={{ userDetails, dispatchUserDetails }}>
      {props.children}
    </CurrentUserDetailsContext.Provider>
  );
};
