import fetch from "node-fetch";
import { useQuery } from "@tanstack/react-query";
import { PaginatedAPIResponse } from "@boardroom/boardroom-api";

import { BOARDROOM_API_KEY, baseAPIUrl } from "../constants/general";

export interface VotePowerData {
  adapter: string;
  votePower: number;
  lastUpdatedTime: number;
  protocol: string;
}

export const useVotePowerFromApi = ({ addresses }: { addresses: string[] }) => {
  const { data } = useQuery<VotePowerData[], Error>(
    [`${addresses?.toString()}:votePowerFromApi`],
    async () => {
      const promises = addresses?.map(async (address) => {
        const responseData = await fetch(`${baseAPIUrl}voters/${address}/votePowers?key=${BOARDROOM_API_KEY}`);
        const parsedResponseData: PaginatedAPIResponse<VotePowerData> = await responseData.json();

        return parsedResponseData?.data;
      });

      const votePowers = await Promise.all(promises);
      return votePowers.flat();
    },
    {
      suspense: false,
      enabled: addresses?.filter((address) => address !== "").length > 0,
    },
  );

  return data;
};
