import * as React from "react";

const SvgRocketBoostIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.166 2.626a.75.75 0 00-.832 0l-.715.477A9.25 9.25 0 006.5 10.799v4.398H17V10.8a9.25 9.25 0 00-4.119-7.696l-.715-.477zm.832-1.248l.715.477a10.75 10.75 0 014.787 8.944v5.148a.75.75 0 01-.75.75h-12a.75.75 0 01-.75-.75V10.8a10.75 10.75 0 014.787-8.944l.715-.477a2.25 2.25 0 012.496 0z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.75 7.197a2.25 2.25 0 100 4.5 2.25 2.25 0 000-4.5zM8 9.447a3.75 3.75 0 117.5 0 3.75 3.75 0 01-7.5 0z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.27 9.908a.75.75 0 01.02 1.06l-2.79 2.89v1.34H20v-1.34l-2.79-2.89a.75.75 0 011.08-1.042l3 3.11a.75.75 0 01.21.52v2.391a.75.75 0 01-.75.75h-18a.75.75 0 01-.75-.75v-2.391a.75.75 0 01.21-.52l3-3.11a.75.75 0 011.06-.018zM11.75 17.697a.75.75 0 01.75.75v3a.75.75 0 01-1.5 0v-3a.75.75 0 01.75-.75zM7.75 18.697a.75.75 0 01.75.75v1a.75.75 0 01-1.5 0v-1a.75.75 0 01.75-.75zM15.75 18.697a.75.75 0 01.75.75v1a.75.75 0 01-1.5 0v-1a.75.75 0 01.75-.75z"
      fill="currentColor"
    />
  </svg>
);

export default SvgRocketBoostIcon;
