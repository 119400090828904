import { useContext, useMemo } from "react";
import { useQuery } from "@tanstack/react-query";

import { CurrentAccountContext } from "../reducers/CurrentAccount";
import { useSdkWithoutSigner } from "./useSdkWithoutSigner";

export type Score = Record<string, string>;
export type Scores = Score[];

export default function useScore(
  protocol?: string,
  voterAddresses?: string | string[],
  blockNumber?: number,
  adapter?: string,
) {
  const { account } = useContext(CurrentAccountContext);
  const sdk = useSdkWithoutSigner();

  const addresses: string[] = useMemo(() => {
    if (voterAddresses && Array.isArray(voterAddresses)) {
      return voterAddresses;
    } else if (voterAddresses) {
      return [voterAddresses];
    } else if (account) {
      return [account];
    }
    return [];
  }, [account, voterAddresses]);

  const enabled = !!sdk && !!protocol && addresses.length > 0;

  const {
    data: combinedScore,
    isError,
    isLoading,
  } = useQuery(
    [`votePower:${protocol}:${addresses}:${blockNumber}:${!!sdk}:${adapter}`],
    async () => {
      const protocolInSdk = sdk?.getProtocol(protocol || "");
      if (adapter) {
        const adapterFramework = protocolInSdk?.adapterInstances("votePower");
        const hasRespectiveAdapter = adapterFramework?.includes(adapter);
        if (!hasRespectiveAdapter) {
          return 0;
        }
      }

      if (!protocolInSdk) {
        return 0;
      }

      const response = await protocolInSdk
        .adapter("votePower", adapter || "snapshot")
        .getVotePower(addresses, blockNumber);

      const scoresByAddress = response?.map((scores) => {
        const found = addresses.find((address) => scores.address === address);
        if (found) {
          return scores.power;
        }
      });
      const combinedScore =
        scoresByAddress?.reduce((acc, score) => {
          return (acc || 0) + (score || 0);
        }, 0) || 0;

      return combinedScore;
    },
    {
      enabled,
      suspense: false,
      retry: 1,
    },
  );

  return { score: (combinedScore || 0).toString(), breakdown: [], isError, isLoading: isLoading && enabled };
}
