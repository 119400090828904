import * as React from "react";

const SvgGithubIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.97 23.43" width="1em" height="1em" {...props}>
    <path
      d="M12 0a12 12 0 00-3.72 23.41h.29a1 1 0 001.06-.94.24.24 0 000-.12v-1.3a.5.5 0 00-.2-.33.48.48 0 00-.42-.1c-2.68.58-3.25-1.1-3.29-1.21A4.58 4.58 0 004 17.22a.58.58 0 00-.15-.11.63.63 0 01.38-.11 1.48 1.48 0 011.15.88 3 3 0 004 1.16.5.5 0 00.29-.36 2 2 0 01.56-1.23.51.51 0 000-.71.58.58 0 00-.32-.16c-2.37-.27-4.79-1.1-4.79-5.19a4 4 0 011-2.78.49.49 0 00.09-.53 3.59 3.59 0 010-2.4 5.48 5.48 0 012.63 1.19.49.49 0 00.42.07 10.37 10.37 0 015.49 0 .5.5 0 00.41-.07 5.63 5.63 0 012.52-1.15 3.48 3.48 0 010 2.38.51.51 0 00.09.53 3.94 3.94 0 011.05 2.75c0 4.09-2.43 4.91-4.81 5.18a.5.5 0 00-.44.55.47.47 0 00.17.33 2.2 2.2 0 01.61 1.74v3.18a1.08 1.08 0 00.36.83 1.19 1.19 0 001.06.19A12 12 0 0012 0z"
      fill="currentColor"
    />
  </svg>
);

export default SvgGithubIcon;
