import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { COLORS } from "../../constants/colors";
import { BriefContent } from "./DailyBriefsEventRow";
import { protocols } from "../../constants/protocols";

const SectionTitle = styled.span`
  color: ${COLORS.primary.grayDark}
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  display: block;
  margin-bottom: 8px;
`;

const UpdateTitle = styled.span`
  font-size: 16px;
  color: ${COLORS.primary.grayDark};
  font-weight: 700;
  line-height: 22px;
  display: block;
  margin-bottom: 2px;
`;

const UpdateContent = styled.span`
  font-weight: 400;
  color: ${COLORS.primary.grayDark};
  font-size: 14px;
  line-height: 22px;
`;

const SectionWrapper = styled.div<{ $addBorderTop?: boolean }>`
  margin-bottom: 20px;
  padding-top: ${({ $addBorderTop }) => ($addBorderTop ? "12px" : "0")};
  border-top: ${({ $addBorderTop }) => ($addBorderTop ? "1px solid #f0eff8" : "0")};
`;

const Wrapper = styled.div`
  padding: 20px;
`;

const UpdateWrapper = styled.div`
  margin-bottom: 16px;
`;

const Title = styled.span`
  font-size: 24px;
  font-weight: 700;
  line-height: 22px;
  display: block;
  margin-bottom: 24px;
  color: ${COLORS.primary.grayDark};
  border-bottom: 1px solid #f0eff8;
  padding-bottom: 6px;
`;

const SeeMore = styled(Link)`
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
  display: block;
  color: ${COLORS.primary.accent};
  margin-top: 8px;
`;

export const DailyBriefContent = ({ briefContent, title }: { briefContent: BriefContent; title: string }) => {
  return (
    <Wrapper>
      <Title>{title}</Title>
      <SectionWrapper>
        <SectionTitle>Proposals</SectionTitle>
        {briefContent.proposalSummaries?.map((proposalSummary, i) => (
          <UpdateWrapper key={i}>
            <UpdateTitle>{proposalSummary.title}</UpdateTitle>
            <UpdateContent>{proposalSummary.proposalSummary}</UpdateContent>
            {!!proposalSummary.refId && (
              <SeeMore
                rel="noopener noreferrer"
                target="_blank"
                to={`/${protocols[proposalSummary.protocol].path}/proposal/${proposalSummary.refId}`}
              >
                See more
              </SeeMore>
            )}
          </UpdateWrapper>
        ))}
        {!briefContent?.proposalSummaries?.length && <span>No updates on proposals</span>}
      </SectionWrapper>
      <SectionWrapper $addBorderTop>
        <SectionTitle>Discussions</SectionTitle>
        {briefContent.forumSummaries?.map((forumSummary, i) => (
          <UpdateWrapper key={i}>
            <UpdateTitle>{forumSummary.title}</UpdateTitle>
            <UpdateContent>{forumSummary.summary}</UpdateContent>
            {!!forumSummary.topicId && (
              <SeeMore
                target="_blank"
                to={`/${protocols[forumSummary.protocol].path}/discussions/${forumSummary.topicId}`}
                rel="noopener noreferrer"
              >
                See more
              </SeeMore>
            )}
          </UpdateWrapper>
        ))}
        {!briefContent?.forumSummaries?.length && <span>No updates on discussions</span>}
      </SectionWrapper>
    </Wrapper>
  );
};
