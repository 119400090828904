import React, { useReducer, createContext, useEffect, useContext } from "react";
import { FormattedSubscriptionData } from "@boardroom/boardroom-api/dist/subscriptions";
import { CurrentAccountContext } from "./CurrentAccount";

interface Props {
  children: React.ReactNode;
}

type Action = { type: "SAVE_SUBSCRIPTION_DATA"; data: FormattedSubscriptionData | null };

interface CurrentSubscriptionDataType {
  subscriptionData: FormattedSubscriptionData | null;
  dispatchSubscriptionData: React.Dispatch<any>;
}

const initialState = {} as FormattedSubscriptionData;

export const CurrentSubscriptionDataContext = createContext({} as CurrentSubscriptionDataType);

const reducer = (state: FormattedSubscriptionData | null, action: Action) => {
  switch (action.type) {
    case "SAVE_SUBSCRIPTION_DATA":
      return action.data;
    default:
      return state;
  }
};

export const CurrentSubscriptionDataProvider = (props: Props) => {
  const [subscriptionData, dispatchSubscriptionData] = useReducer(reducer, initialState);
  const { account } = useContext(CurrentAccountContext);

  useEffect(() => {
    dispatchSubscriptionData({ type: "SAVE_SUBSCRIPTION_DATA", data: null });
  }, [account]);

  return (
    <CurrentSubscriptionDataContext.Provider value={{ subscriptionData, dispatchSubscriptionData }}>
      {props.children}
    </CurrentSubscriptionDataContext.Provider>
  );
};
