import * as React from "react";

const SvgProjectsIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" {...props}>
    <rect x={8} y={1} width={12} height={4} rx={1} fill="currentColor" />
    <rect x={8} y={8} width={12} height={4} rx={1} fill="currentColor" />
    <rect x={8} y={15} width={12} height={4} rx={1} fill="currentColor" />
    <circle cx={2.857} cy={2.857} r={2.857} fill="currentColor" />
    <circle cx={2.857} cy={10} r={2.857} fill="currentColor" />
    <circle cx={2.857} cy={17.143} r={2.857} fill="currentColor" />
  </svg>
);

export default SvgProjectsIcon;
