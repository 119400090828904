import * as React from "react";

const SvgParachuteWithPlusIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 1c.978 0 1.91.2 2.755.563-.242.232-.439.51-.577.82a6.025 6.025 0 00-8.106 4.546c.132-.09.26-.17.383-.241.372-.213.749-.36 1.105-.36.357 0 .734.147 1.106.36.29.165.605.387.947.661C6.362 6.696 7.159 6.328 8 6.328s1.638.368 2.387 1.021c.342-.274.658-.496.947-.661.372-.213.75-.36 1.106-.36.356 0 .733.147 1.105.36.123.07.25.15.383.241a5.982 5.982 0 00-.31-1.107 2.61 2.61 0 00.82-.576C14.798 6.09 15 7.022 15 8a.488.488 0 01-.146.348L8.35 14.852a.488.488 0 01-.7 0L1.146 8.348A.483.483 0 011 8a7 7 0 017-7zm1.8 7.134L8 13.083l-1.8-4.95c.65-.583 1.248-.829 1.8-.829s1.15.246 1.8.83zM9.21 12.61l1.572-4.322a7.34 7.34 0 011.036-.752c.318-.182.517-.232.62-.232.105 0 .303.05.622.232.21.12.45.285.725.5L9.21 12.61zM5.217 8.288a7.344 7.344 0 00-1.036-.752c-.318-.182-.517-.232-.62-.232-.105 0-.303.05-.622.232-.21.12-.45.285-.725.5L6.79 12.61 5.217 8.288z"
      fill="currentColor"
    />
    <path
      d="M13.5 1.25a.25.25 0 10-.5 0V2.5h-1.25a.25.25 0 100 .5H13v1.25a.25.25 0 10.5 0V3h1.25a.25.25 0 100-.5H13.5V1.25z"
      fill="currentColor"
    />
  </svg>
);

export default SvgParachuteWithPlusIcon;
