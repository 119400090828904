import * as React from "react";

const SvgSuccessIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M27.26 49.749l12.686 12.686 29.409-29.409" stroke="currentColor" strokeWidth={2} strokeLinecap="round" />
    <path
      d="M35.433 4.603c.295-3.837 5.435-4.88 7.203-1.464 2.18 4.214 8.215 4.189 10.361-.042 1.74-3.431 6.89-2.43 7.215 1.404.402 4.728 5.987 7.014 9.589 3.926 2.92-2.504 7.294.392 6.128 4.059-1.438 4.521 2.847 8.77 7.356 7.296 3.657-1.195 6.589 3.154 4.108 6.095-3.058 3.627-.726 9.193 4.004 9.556 3.837.295 4.88 5.435 1.464 7.203-4.214 2.18-4.189 8.215.042 10.361 3.431 1.74 2.43 6.89-1.404 7.215-4.727.402-7.014 5.987-3.926 9.589 2.504 2.92-.392 7.294-4.059 6.128-4.521-1.438-8.77 2.847-7.296 7.356 1.195 3.657-3.154 6.589-6.095 4.108-3.627-3.058-9.193-.726-9.556 4.004-.295 3.837-5.435 4.88-7.203 1.464-2.18-4.214-8.215-4.189-10.361.042-1.74 3.431-6.89 2.43-7.215-1.404-.402-4.727-5.987-7.014-9.589-3.926-2.92 2.504-7.294-.392-6.128-4.059 1.438-4.521-2.847-8.77-7.356-7.296-3.657 1.195-6.589-3.154-4.108-6.095 3.058-3.627.726-9.193-4.004-9.556-3.837-.295-4.88-5.435-1.464-7.203 4.214-2.18 4.189-8.215-.042-10.361-3.431-1.74-2.43-6.89 1.404-7.215 4.728-.402 7.014-5.987 3.926-9.589-2.504-2.92.392-7.294 4.059-6.128 4.521 1.438 8.77-2.847 7.296-7.356-1.195-3.657 3.154-6.589 6.095-4.108 3.627 3.058 9.193.726 9.556-4.004z"
      stroke="currentColor"
      strokeWidth={2}
    />
  </svg>
);

export default SvgSuccessIcon;
