import React, { useCallback, useContext, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import styled from "styled-components";
import Button from "antd/es/button/button";

import { COLORS } from "../../constants/colors";
import TwitterShare from "../common/TwitterShareButton";
import { CircularShareIcon } from "../icons";
import { Popover } from "../Popover";
import media from "../../media-query";
import { CopyIcon } from "../icons";
import { useMixpanel } from "../../hooks";
import { CurrentAccountContext } from "../../reducers/CurrentAccount";
import { useCurrentWidth } from "react-socks";
import { VOTER_PROFILE_SCROLL_SHRINK } from "../../constants/general";

interface Props {
  activeProtocol: string;
  address?: string;
  team?: string;
  ens?: string;
  scrolledHeight: number;
}

const StyledCopyIcon = styled(CopyIcon)`
  width: 16px;
  height: 16px;
  color: ${COLORS.primary.accent};
  vertical-align: text-bottom;
  margin-right: 8px;
`;

const StyledCopiedIcon = styled(CopyIcon)`
  width: 16px;
  height: 16px;
  vertical-align: text-bottom;
  margin-right: 8px;
  color: ${COLORS.primary.gray};
`;

const StyledButtonWrapper = styled(Button)`
  background: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 40px;
  color: ${COLORS.primary.grayDarkLightest};
  height: 48px;
  display: flex;
  width: 154px;
  justify-content: center;
  ${media.lessThan("991px")`
    box-shadow: none;
    border: none;
    background: transparent;
    float: right;
    margin-right: 10px;
  `}
`;

const StyledButtonText = styled.p`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  margin: auto 0 auto 4px;
`;

const StyledShareIcon = styled(CircularShareIcon)`
  width: 24px;
  height: 24px;
  margin: auto 0;
  ${media.lessThan("991px")`
    margin: 0px;
  `}
`;

const StyledCopyText = styled.div`
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  cursor: pointer;
  margin-bottom: 16px;
  color: ${COLORS.primary.accent};
`;

const StyledCopiedText = styled.div`
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 16px;
  color: ${COLORS.primary.gray};
`;

const ProfileTwitterShareButton = styled(TwitterShare)`
  vertical-align: text-top;
  display: inline-flex;
  height: 21px;
  overflow: hidden;
  color: ${COLORS.primary.accent};
`;

function ProfileShareButton(props: Props) {
  const { address, activeProtocol, scrolledHeight, team } = props;
  const [copied, setCopied] = useState(false);
  const { trackShareVoterProfileOnTwitter, trackClickCopyProfileLink } = useMixpanel();
  const { account } = useContext(CurrentAccountContext);
  const isMobile = useCurrentWidth() < 991;
  const imageMarginTop = isMobile ? 25 - (scrolledHeight / VOTER_PROFILE_SCROLL_SHRINK) * 15 : 0;

  const handleVisibleChange = (visible: boolean) => {
    if (!visible) {
      setTimeout(() => {
        setCopied(false);
      }, 100);
    }
  };

  const onCopy = useCallback(() => {
    setCopied(true);
    trackClickCopyProfileLink({
      userId: account,
    });
  }, [setCopied, account, trackClickCopyProfileLink]);

  return (
    <Popover
      trigger="hover"
      placement="topRight"
      zIndex={1000}
      onVisibleChange={handleVisibleChange}
      content={
        <div>
          {!copied ? (
            <CopyToClipboard
              text={
                address ? `${window.location.hostname}/voter/${address}` : `${window.location.hostname}/bundle/${team}`
              }
              onCopy={onCopy}
            >
              <StyledCopyText>
                <StyledCopyIcon />
                Copy Profile Link
              </StyledCopyText>
            </CopyToClipboard>
          ) : (
            <StyledCopiedText>
              <StyledCopiedIcon />
              Link Copied!
            </StyledCopiedText>
          )}
          <ProfileTwitterShareButton
            type="voter"
            title="Check out this voter profile on the @boardroom_info app"
            url={address ? `${window.location.hostname}/voter/${address}` : `${window.location.hostname}/bundle/${team}`}
            clickCallBack={() => {
              trackShareVoterProfileOnTwitter({
                protocol: activeProtocol || "",
                voterAddress: address || team || "",
                userId: `${account}`,
              });
            }}
            text={"Tweet This Profile"}
            hashtags={[]}
          />
        </div>
      }
    >
      <StyledButtonWrapper style={{ marginTop: imageMarginTop }}>
        <StyledShareIcon />
        {!isMobile && <StyledButtonText>Share Profile</StyledButtonText>}
      </StyledButtonWrapper>
    </Popover>
  );
}

export default React.memo(ProfileShareButton);
