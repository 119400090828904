import React from "react";
import { ProposalState } from "@boardroom/gov-sdk";
import styled from "styled-components";

import { COLORS } from "../../constants/colors";
import StatusTag from "../StatusTag";
import { Paragraph } from "../Typography";

interface SearchItemProps {
  title: string;
  tagLabel: "proposal" | "profile" | "protocol";
  prefix?: React.ReactNode;
  onClick?: Function;
  category?: React.ReactNode;
  status?: string;
}

const StyledContent = styled("div")`
  display: inline-block;
  width: calc(100% - 120px);
  vertical-align: middle;
  padding: 24px 16px;
`;

const StyledSearchItem = styled("div")`
  padding: 0 0 0 12px;
  border-radius: 8px;
  background: white;
  display: flex;
  align-items: center;
  &:hover {
    background: linear-gradient(
      90deg,
      rgba(240, 239, 248, 0) 2.25%,
      ${COLORS.primary.grayLighter} 51.22%,
      rgba(240, 239, 248, 0) 97.23%
    );
    border-radius: 8px;
    cursor: pointer;
    p {
      font-weight: 600;
    }
  }
`;

const StyledTitle = styled(Paragraph)`
  max-width: 25rem;
  font-size: 18px;
  line-height: 30px;
  font-weight: normal;
  color: ${COLORS.primary.grayDark};
`;

function SearchItem(props: SearchItemProps) {
  const onClick = (e: React.MouseEvent) => {
    if (props.onClick) {
      props.onClick(e);
    }
  };

  return (
    <StyledSearchItem onClick={onClick}>
      {props.prefix}

      <StyledContent>
        <StyledTitle ellipsis bold>
          {props.title}
        </StyledTitle>
      </StyledContent>
      {props.tagLabel === "protocol" && props.category}
      {props.tagLabel === "proposal" && <StatusTag status={props.status?.toLowerCase() as ProposalState} />}
    </StyledSearchItem>
  );
}

export default SearchItem;
