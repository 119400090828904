import * as React from "react";

const SvgCommunicationBoldIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#CommunicationBoldIcon_svg__clip0_6192_6088)">
      <path
        d="M11.203 19.993c-3.62 0-7.242.002-10.864.007-.277 0-.34-.062-.339-.34.01-3.62.013-7.241.007-10.863.023-.13.048-.26.07-.391.445-2.608 1.715-4.726 3.791-6.357C5.321.907 6.978.256 8.804 0h2.488c.1.022.2.049.302.067 2.693.459 4.855 1.787 6.497 3.966C19.138 5.42 19.733 7 20 8.708v2.577l-.115.564c-.605 2.976-2.208 5.247-4.809 6.811-1.195.727-2.5 1.125-3.873 1.333zm4.443-9.973c.005-1.736-.744-3.34-2.05-4.394C11.518 3.95 8.661 3.903 6.56 5.51 4.487 7.098 3.78 9.865 4.847 12.293c.139.294.156.631.047.938-.245.76-.472 1.526-.702 2.292-.036.121-.147.26-.033.373.088.088.225 0 .335-.029.863-.21 1.727-.415 2.585-.644.298-.086.616-.069.903.048 3.692 1.424 7.65-1.294 7.664-5.252z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="CommunicationBoldIcon_svg__clip0_6192_6088">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgCommunicationBoldIcon;
