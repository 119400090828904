import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useHover } from "use-events";
import { COLORS } from "../../constants/colors";
import { ExpandCollapseArrowThin } from "../icons";

interface Props {
  height: number;
  children: React.ReactNode;
  content?: string;
  useIcon?: boolean;
  showMoreText?: string;
  marginTopMobile?: string;
}

const Container = styled("div")<{ isCollapsed: boolean; containerHeight: string | number; marginTopMobile?: string }>`
  height: ${({ isCollapsed, containerHeight }) => (isCollapsed ? `${containerHeight}px` : "auto")};
  overflow-y: ${({ isCollapsed }) => (isCollapsed ? "hidden" : "initial")};
  ${({ marginTopMobile }) => marginTopMobile && `margin-top: ${marginTopMobile};`}
  overflow-x: hidden;
`;

const StyledTrigger = styled("a")`
  position: relative;
  top: 12px;
  display: block;
  margin-top: 20px;
  margin-bottom: 30px;
  text-align: center;
`;

const ResultsExpandCollapseIcon = styled(ExpandCollapseArrowThin)<{ $isExpanded: boolean }>`
  margin-left: auto;
  transform: rotate(${({ $isExpanded }) => ($isExpanded ? "180deg" : "0deg")});
  transition: transform 0.2s;
  cursor: pointer;
`;

const ExpandCollapseContent = ({ height, children, content = "", useIcon, showMoreText, marginTopMobile }: Props) => {
  const containerElement = useRef<HTMLDivElement>(null);
  const [contentOverflow, setContentOverflow] = useState(false);
  const [collapsedState, setCollapsedState] = useState(true);
  const [isExpandButtonHovered, ExpandButtonBind] = useHover();

  const containerHeight =
    containerElement.current?.clientHeight !== undefined
      ? Math.min(containerElement.current?.clientHeight || 0, height)
      : "auto";

  useEffect(() => {
    if ((containerElement.current?.clientHeight || 0) > height) {
      setContentOverflow(true);
    } else {
      setContentOverflow(false);
    }
  }, [height, content]);

  const toggleContent = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setCollapsedState(!collapsedState);
  };

  return (
    <>
      <Container marginTopMobile={marginTopMobile} isCollapsed={collapsedState} containerHeight={containerHeight}>
        <div ref={containerElement}>{children}</div>
      </Container>
      {contentOverflow ? (
        useIcon ? (
          <StyledTrigger onClick={toggleContent}>
            <ResultsExpandCollapseIcon
              $isExpanded={!collapsedState}
              width={12}
              height={12}
              color={isExpandButtonHovered ? COLORS.primary.accent : COLORS.primary.accentDarker}
              onClick={toggleContent}
              {...ExpandButtonBind}
            />
          </StyledTrigger>
        ) : (
          <StyledTrigger onClick={toggleContent}>
            {collapsedState ? showMoreText || "Show More..." : "Show Less..."}
          </StyledTrigger>
        )
      ) : null}
    </>
  );
};

export default ExpandCollapseContent;
