import { useQuery } from "@tanstack/react-query";
import { GetProposalResponse, ProposalDetails } from "@boardroom/boardroom-api";
import { apiClient } from "../utils/apiClient";
import base64 from "base-64";
import utf8 from "utf8";

interface Props {
  refId: string;
  suspense?: boolean;
}

export const useProposal = ({ refId, suspense = true }: Props) => {
  const { data: proposal } = useQuery<GetProposalResponse | undefined, Error>(
    [`proposal:${refId}`],
    () => {
      let proposalRefId = refId;
      if (!proposalRefId) {
        return;
      }
      const decodedBytes = base64.decode(refId);
      const decodedBase64String = utf8.decode(decodedBytes);
      if (decodedBase64String.includes("default")) {
        const encodedBytes = utf8.encode(decodedBase64String.replace("default", "snapshot"));
        proposalRefId = base64.encode(encodedBytes);
      }
      const response = apiClient.getProposal(proposalRefId);
      return response;
    },
    {
      enabled: !!refId,
      suspense,
    },
  );

  const parsedProposal:
    | undefined
    | (ProposalDetails & {
        quorum?: number;
        txHash?: string;
        executables?: {
          target: string;
          params: any;
          signature: string;
          calldata: string;
          value?: any;
        }[];
      }) = proposal?.data as any;

  return { proposal: parsedProposal };
};
