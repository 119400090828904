import axios from "axios";
import { useQuery } from "@tanstack/react-query";

const useCyberConnectTwitterVerifiedBatch = ({ addresses }: { addresses: string[] }) => {
  const { data } = useQuery<any, Error>([`fetchCyberConnectBatch:${addresses.toString()}`], async () => {
    try {
      const response = await axios.post("https://api.cyberconnect.dev/", {
        query:
          "query BatchGetAddresses($addresses: [AddressEVM!]!, $chainId: ChainID!) {\n  batchGetAddresses(addresses: $addresses, chainID: $chainId) {\n    address\n    identity {\n      socialInfo {\n        twitter {\n          verified\n        }\n      }\n    }\n  }\n}",
        operationName: "BatchGetAddresses",
        variables: {
          addresses,
          chainId: 1,
        },
      });
      const addressesFetched = response?.data?.data?.batchGetAddresses;
      const parsedAddresses = {};
      addressesFetched.map((wallet: any) => {
        const walletAddress = wallet?.address;
        const walletHasTwitterVerified = wallet?.identity?.socialInfo?.twitter?.verified;
        Object.assign(parsedAddresses, {
          [walletAddress]: walletHasTwitterVerified,
        });
      });
      return { parsedAddresses, parsedAddressesLength: addressesFetched?.length };
    } catch (error) {
      console.error(error);
      return {};
    }
  }, {
    suspense: false,
    keepPreviousData: true,
  });
  return data || {};
};

export { useCyberConnectTwitterVerifiedBatch };
