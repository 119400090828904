import protocolIcons from "../icons.json";

interface Props {
  protocolCname?: string | null;
}

type Icons = { url: string; size: string }[];

type ProtocolIcons = Record<string, Icons>;

export const useProtocolIcon = ({ protocolCname }: Props) => {
  let iconUrl = `${process.env.PUBLIC_URL}/assets/boardroomCard.png`;
  if (protocolCname) {
    const icons = (protocolIcons as ProtocolIcons)[protocolCname];
    if (icons) {
      iconUrl = icons[icons.length - 1].url;
    }
  }

  return { url: iconUrl };
};
