import React, { createContext, useRef, useReducer } from "react";

interface Props {
  children: React.ReactNode;
}

type Action = { type: "SAVE_CURRENT_RESULT_REF"; data: React.RefObject<HTMLDivElement> };

interface CurrentResultRefType {
  currentResultRef: React.RefObject<HTMLDivElement>;
  dispatchCurrentResultRef: React.Dispatch<any>;
}

export const CurrentResultRefContext = createContext({} as CurrentResultRefType);

const reducer = (state: React.RefObject<HTMLDivElement>, action: Action) => {
  switch (action.type) {
    case "SAVE_CURRENT_RESULT_REF":
      return action.data;
    default:
      return state;
  }
};

export const CurrentResultRefProvider = (props: Props) => {
  const initialState = useRef(null);
  const [currentResultRef, dispatchCurrentResultRef] = useReducer(reducer, initialState);

  return (
    <CurrentResultRefContext.Provider value={{ currentResultRef, dispatchCurrentResultRef }}>
      {props.children}
    </CurrentResultRefContext.Provider>
  );
};
