import React from "react";

import { useCurrentProtocol } from "../hooks/useCurrentProtocol";
import { useHasDelegation } from "../hooks/useHasDelegation";
import { SetupDelegation } from "./Voting";

interface Props {
  protocolName: string;
}

function Delegate(props: Props) {
  const { protocolName } = props;
  const protocolInfo = useCurrentProtocol();
  const hasDelegation = useHasDelegation(protocolInfo?.cname);

  if (!hasDelegation) {
    return null;
  }

  return <SetupDelegation protocolName={protocolName} />;
}

export default React.memo(Delegate);
