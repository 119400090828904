import React, { useContext, useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import styled, { css } from "styled-components";

import { useCurrentProtocol } from "../../hooks";
import { useDelegatesByProtocol } from "../../hooks/useDelegatesByProtocol";
import { SidebarContext } from "./SidebarContext";
import { COLORS } from "../../constants/colors";
import { useDiscourseData } from "../../hooks/useDiscourseData";
import { useGetResourceFolders } from "../../hooks/useGetResourceFolders";
import { useGetProposalBriefs } from "../../hooks/useGetProposalBriefs";
interface Params {
  protocol: string;
}

export const StyledTab = styled(Link)<{ $active?: boolean }>`
  color: ${({ $active }) => ($active ? "white" : "rgba(25, 21, 64, 0.4)")};
  font-weight: 300;
  font-size: 18px;
  line-height: 30px;

  border: 1px solid rgba(123, 120, 147, 0.2);
  border-radius: 20px;
  padding: 0px 12px;
  height: 32px;
  font-size: 16px;
  margin: 0 8px 0 0;
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: 4px;

  ${(props) =>
    props.$active &&
    css`
      background: ${COLORS.primary.accent};
      font-weight: 300;
      border: none;
    `}
  :active {
    color: ${({ $active }) => ($active ? "white" : "rgba(25, 21, 64, 0.4)")};
  }
  ${({ $active }) =>
    !$active &&
    css`
      :hover {
        background: ${COLORS.primary.grayLighter};
        color: #4235e1;
      }
    `}
  ${({ $active }) =>
    $active &&
    css`
      :hover {
        color: ${$active ? "white" : "rgba(25, 21, 64, 0.4)"};
      }
    `}
`;

const protocolInsights = ["optimism", "nounsdao", "compound", "aave", "uniswap"];

export const NavLinks = () => {
  const { activeSection } = useContext(SidebarContext);
  const { protocol } = useParams<Params>();
  const protocolInfo = useCurrentProtocol();
  const resourceFolders = useGetResourceFolders({ protocol: protocolInfo?.cname });

  const proposalBriefsData = useGetProposalBriefs(protocolInfo?.path, false);
  const hasProposalBriefs = useMemo(() => {
    return !!proposalBriefsData && !!proposalBriefsData.items && !!proposalBriefsData.items.length;
  }, [proposalBriefsData]);

  const { discourseData } = useDiscourseData({
    categories: [],
    category: "all",
    tag: "all",
    latestOrTop: "latest",
    topPeriod: "all",
    url: protocolInfo?.discourseForum?.url || "",
    suspense: false,
  });

  const { delegates } = useDelegatesByProtocol({
    protocol: protocolInfo?.cname || "",
    limit: 24,
    suspense: false,
  });

  return (
    <>
      <StyledTab
        id="vote"
        to={`/${protocol}/proposals`}
        $active={activeSection === "proposals" || activeSection === "proposal"}
      >
        Proposals
      </StyledTab>
      {!!discourseData?.topics?.length && (
        <StyledTab id="discussions" to={`/${protocol}/discussions`} $active={activeSection === "discussions"}>
          Discussions
        </StyledTab>
      )}
      {
        <StyledTab
          id="vote"
          to={delegates?.length ? `/${protocol}/delegates` : `/${protocol}/voters`}
          $active={activeSection === "delegates" || activeSection === "voters"}
        >
          Members
        </StyledTab>
      }
      <StyledTab
        id="overview"
        to={`/${protocol}/overview`}
        $active={activeSection === "overview" || activeSection?.includes("calendar")}
      >
        Information
      </StyledTab>
      {protocolInsights.includes(protocol) && (
        <StyledTab
          id="insights"
          to={
            resourceFolders !== undefined ? `/${protocol}/resources/${resourceFolders?.[0]}` : `/${protocol}/insights`
          }
          $active={activeSection === "insights" || activeSection?.includes("resources")}
        >
          Insights
        </StyledTab>
      )}
      {!protocolInsights.includes(protocol) && hasProposalBriefs && (
        <StyledTab
          id="insights"
          to={`/${protocol}/insights/briefs`}
          $active={activeSection === "insights" || activeSection?.includes("resources")}
        >
          Insights
        </StyledTab>
      )}
    </>
  );
};
