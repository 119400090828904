import * as React from "react";

const SvgSquareShareIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M2.5 18.125a.625.625 0 01-.625-.625v-7.496a.625.625 0 111.25 0v6.871h13.75V10a.625.625 0 111.25 0v7.5c0 .345-.28.625-.625.625h-15zM5.808 6.692a.625.625 0 010-.884l3.75-3.75a.625.625 0 01.884 0l3.75 3.75a.625.625 0 11-.884.884L10 3.384 6.692 6.692a.625.625 0 01-.884 0z"
      fill="currentColor"
    />
    <path
      d="M9.997 13.958a.625.625 0 01-.625-.625V2.5a.625.625 0 111.25 0v10.833c0 .345-.28.625-.625.625z"
      fill="currentColor"
    />
  </svg>
);

export default SvgSquareShareIcon;
