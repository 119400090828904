import Badge from "antd/es/badge";
import React from "react";
import styled from "styled-components";

import { CalendarEvent } from "../../../types";
import { COLORS } from "../../../constants/colors";
import { eventTypeColorCoding } from "../utils";
import { useProtocolIcon } from "../../../hooks/useProtocolIcon";
import { useMixpanel } from "../../../hooks";
import { useContext } from "react";
import { CurrentAccountContext } from "../../../reducers/CurrentAccount";

interface Props {
  event: CalendarEvent;
  shouldLink?: boolean;
  isDisabled: boolean;
}

const ListItem = styled.li<Pick<Props, "isDisabled">>`
  opacity: ${({ isDisabled }) => (isDisabled ? 0.4 : 1)};
  margin: 4px 0;

  .ant-badge-status {
    padding-right: 5px;
    width: 100%;
    font-size: 12px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .ant-badge-status,
  .ant-badge-status-text {
  }

  .ant-badge-status-dot {
    width: 8px;
    height: 8px;
  }
`;

const EventTitle = styled.span<{ shouldLink: boolean }>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 13px;
  font-weight: 500;
  font-style: normal;
  width: ${(props) => (props.shouldLink ? "300px" : "inherit")}

  color: ${COLORS.primary.grayDarkLightest};

  > * {
    margin: 0 3px;
  }

  > p {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;

const Avatar = styled.img`
  height: 18px;
  width: 18px;
  border-radius: 50%;
`;

const Event = ({ event, shouldLink, isDisabled }: Props) => {
  const { account } = useContext(CurrentAccountContext);
  const { trackClickCalendarEvent } = useMixpanel();
  const icon = useProtocolIcon({ protocolCname: event?.protocolCname });

  return (
    <ListItem
      isDisabled={isDisabled}
      onClick={() =>
        trackClickCalendarEvent({
          userId: account,
          event,
          fromMini: false,
          fromWeekly: false,
          fromMonthly: true,
        })
      }
    >
      {shouldLink ? (
        <a href={event.url} target="_blank">
          <EventTitle shouldLink={true}>
            <Avatar src={icon.url as unknown as string} alt={event.protocolCname} />
            <Badge color={eventTypeColorCoding[event.type]} text={event.title} />
          </EventTitle>
        </a>
      ) : (
        <EventTitle shouldLink={false}>
          <Avatar src={icon.url as unknown as string} alt={event.protocolCname} />
          <Badge color={eventTypeColorCoding[event.type]} text={event.title} />
        </EventTitle>
      )}
    </ListItem>
  );
};

export default React.memo(Event);
