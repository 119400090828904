import React from "react";
import styled from "styled-components";
import { COLORS } from "../../constants/colors";

interface Props {
  htmlFor: string;
  id: string;
  children: React.ReactNode;
}

const StyledLabel = styled.label`
  margin-bottom: 12px;
  display: block;
  font-size: 14px;
  line-height: 16px;
  font-style: normal;
  font-weight: 600;
  color: ${COLORS.primary.grayDarkLight};
`;

export const Label = ({ htmlFor, id, children }: Props) => (
  <StyledLabel htmlFor={htmlFor} id={id}>
    {children}
  </StyledLabel>
);
