import * as React from "react";

const SvgThinSearchIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M9.6 2.4a7.2 7.2 0 100 14.4 7.2 7.2 0 000-14.4zM0 9.6a9.6 9.6 0 0119.2 0 9.558 9.558 0 01-2.016 5.887l6.464 6.464a1.2 1.2 0 11-1.697 1.697l-6.464-6.464A9.558 9.558 0 019.6 19.2 9.6 9.6 0 010 9.6z"
      fill="currentColor"
    />
  </svg>
);

export default SvgThinSearchIcon;
