import * as React from "react";

const SvgWarningIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 30 28" fill="none" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" {...props}>
    <path
      d="M29.118 24.125L17.25 1.46a2.54 2.54 0 00-4.499 0L.881 24.125a2.54 2.54 0 002.25 3.718h23.737a2.54 2.54 0 002.25-3.718zM13.73 9.425a1.27 1.27 0 112.54 0v7.619a1.27 1.27 0 11-2.54 0V9.425zm1.333 14.615h-.035a1.94 1.94 0 01-1.933-1.866 1.874 1.874 0 011.839-1.943h.036a1.939 1.939 0 011.935 1.863 1.87 1.87 0 01-1.842 1.946z"
      fill="currentColor"
    />
  </svg>
);

export default SvgWarningIcon;
