import React, { Suspense, useCallback, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import parse from "html-react-parser";
import styled from "styled-components";
import ContentLoader from "react-content-loader";

import { useProposal } from "../../hooks/useProposal";
import { ExpandCollapseContent } from "../ExpandCollapseContent";
import { renderMarkdown } from "../../utils/markdown";
import { COLORS } from "../../constants/colors";
import toShortAddress from "../../utils/toShortAddress";
import media from "../../media-query";
import { useGetEns } from "../../hooks/useEns";
import { Header } from "../Typography";
import { protocols } from "../../constants/protocols";
import { ArrowRightIcon } from "../icons";
import { formatEns } from "../../utils";
import { useMixpanel } from "../../hooks";
import { CurrentAccountContext } from "../../reducers/CurrentAccount";

const StyledAddress = styled(Link)`
  color: ${COLORS.primary.accent};
  cursor: pointer;
`;

const StyledAuthor = styled.span`
  color: ${COLORS.primary.grayDarkLightest};
`;

const Description = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
  color: ${COLORS.primary.grayDark};
`;

const StyledLink = styled(Link)`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #4235e1;
  text-align: center;
  text-transform: uppercase;
`;

const StyledDescriptionWrapper = styled.div`
  overflow: hidden;
  ${media.lessThan("640px")`
    height: auto;
  `}
`;

interface ProposalDescriptionProps {
  refId: string;
}

export const ProposalDescriptionModal = ({ refId }: ProposalDescriptionProps) => {
  const { search } = useLocation();
  const { proposal } = useProposal({ refId });
  const { account } = useContext(CurrentAccountContext);
  const protocolDetails = protocols[proposal?.protocol || ""];
  const { trackClickSeeFullProposalOnVotingModal } = useMixpanel();

  const handleClickSeeFullProposal = useCallback(() => {
    trackClickSeeFullProposalOnVotingModal({
      userId: account,
      proposalRefId: refId,
    });
  }, [account, refId, trackClickSeeFullProposalOnVotingModal]);
  if (!proposal) {
    return null;
  }

  return (
    <>
      <Header level={3}>{proposal.title}</Header>
      <StyledDescriptionWrapper>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "22px",
            paddingBottom: "8px",
          }}
        >
          <StyledLink
            onClick={handleClickSeeFullProposal}
            target="_blank"
            to={{ pathname: `/${protocolDetails.path}/proposal/${refId}`, search }}
          >
            SEE FULL PROPOSAL
          </StyledLink>
          <span style={{ display: "flex", marginLeft: "8px" }}>
            <ArrowRightIcon color={COLORS.primary.accent} width={18} />
          </span>
        </div>
      </StyledDescriptionWrapper>
    </>
  );
};

export const ProposalDescription = ({ refId }: ProposalDescriptionProps) => {
  const { proposal } = useProposal({ refId });
  const content = renderMarkdown(proposal?.content || "");
  const ens = useGetEns(proposal?.proposer);

  if (!proposal) {
    return null;
  }

  return (
    <>
      <StyledDescriptionWrapper>
        <StyledAuthor>
          By&nbsp;
          <StyledAddress to={`/voter/${proposal.proposer}`}>
            {formatEns(ens) || toShortAddress(proposal.proposer)}
          </StyledAddress>
        </StyledAuthor>
        <ExpandCollapseContent showMoreText="Continue Reading" height={240}>
          <Description>{parse(content)}</Description>
        </ExpandCollapseContent>
      </StyledDescriptionWrapper>
    </>
  );
};

export default (props: ProposalDescriptionProps) => (
  <Suspense
    fallback={
      <ContentLoader
        speed={2}
        width="100%"
        height={120}
        backgroundColor={COLORS.primary.grayLight}
        foregroundColor={COLORS.primary.grayLighter}
      >
        <rect x="0" y="10" rx="5" ry="5" width="100%" height="10" />
        <rect x="0" y="32" rx="5" ry="5" width="100%" height="10" />
        <rect x="0" y="54" rx="5" ry="5" width="100%" height="10" />
        <rect x="0" y="76" rx="5" ry="5" width="100%" height="10" />
        <rect x="0" y="98" rx="5" ry="5" width="60%" height="10" />
      </ContentLoader>
    }
  >
    <ProposalDescription {...props} />
  </Suspense>
);

export type { ProposalDescriptionProps };
