import { useLocation, useParams } from "react-router-dom";
import { useProtocol } from "./useProtocol";
import { getProtocolCnameFromPath } from "../constants/protocols";

interface Params {
  protocol?: string;
}

export const useCurrentProtocol = () => {
  const { protocol } = useParams<Params>();

  const query = new URLSearchParams(useLocation().search);
  const protocolQuery = query.get("protocol");
  const cname = getProtocolCnameFromPath(protocol || protocolQuery || "");
  const protocolDetails = useProtocol({ protocol: cname });

  return protocolDetails;
};
