import React, { Suspense, useState } from "react";
import styled from "styled-components";
import ContentLoader from "react-content-loader";

import Blockie from "../Blockie";
import media from "../../media-query";
import { DelegateIcon } from "../icons";
import { COLORS } from "../../constants/colors";

interface Props {
  address?: string;
  style?: React.CSSProperties;
  size?: "big" | "small" | "xsmall";
  pfp?: any;
  customImageDimensions?: number;
  isDelegate?: boolean;
}

const ProfileImageBorder = styled.div`
  border-radius: 50%;
  border: 4px solid white;
  display: inline-block;
  float: right;
  img {
    object-fit: cover;
  }
  ${media.lessThan("991px")`
    float: left;
    border-width: 2px;
  `}
`;

const StyledImg = styled.img`
  border-radius: 50%;
`;

const ImgWrapper = styled.div`
  position: relative;
`;

const StyledDelegateIcon = styled(DelegateIcon)`
  position: absolute;
  color: #ffffff;
  top: 40px;
  right: -2px;
  background: ${COLORS.primary.accent};
  border: 2px solid #ffffff;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  padding: 6px;
  ${media.lessThan("991px")`
    width: 28px;
  height: 28px;
  padding: 5px;
  top: 32px;
  left: 36px
  `}
`;

const ProfileImage = ({
  pfp,
  address,
  imageDimensions,
  addDefaultSrc,
  showImage,
}: {
  pfp: any;
  address?: string;
  imageDimensions: number;
  addDefaultSrc: () => void;
  showImage: boolean;
}) => {
  if (pfp && showImage) {
    return (
      <StyledImg
        src={pfp}
        style={{
          width: `${imageDimensions}px`,
          height: `${imageDimensions}px`,
        }}
        onError={addDefaultSrc}
      />
    );
  }

  return <Blockie seed={address} dimension={imageDimensions} />;
};

function ProfileHeader(props: Props) {
  const { address, style, size = "big", pfp, customImageDimensions, isDelegate } = props;
  const [showImage, setShowImage] = useState(true);

  const addDefaultSrc = () => {
    setShowImage(false);
  };
  let imageDimensions = 72;
  if (size === "small") {
    imageDimensions = 54;
  } else if (size === "xsmall") {
    imageDimensions = 24;
  }

  if (customImageDimensions) {
    imageDimensions = customImageDimensions;
  }

  return (
    <>
      {isDelegate ? (
        <>
          <ImgWrapper>
            <ProfileImageBorder style={style}>
              <ProfileImage
                pfp={pfp}
                address={address}
                showImage={showImage}
                addDefaultSrc={addDefaultSrc}
                imageDimensions={imageDimensions}
              />
            </ProfileImageBorder>
            <StyledDelegateIcon />
          </ImgWrapper>
        </>
      ) : (
        <ProfileImageBorder style={style}>
          <ProfileImage
            pfp={pfp}
            address={address}
            showImage={showImage}
            addDefaultSrc={addDefaultSrc}
            imageDimensions={imageDimensions}
          />
        </ProfileImageBorder>
      )}
    </>
  );
}

function ProfileHeaderWrapper(props: Props) {
  return (
    <Suspense
      fallback={
        <ContentLoader
          speed={2}
          width={850}
          height={167}
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
          {...props}
        >
          <circle cx="35" cy="70" r="36" />
        </ContentLoader>
      }
    >
      <ProfileHeader {...props} />
    </Suspense>
  );
}

export default React.memo(ProfileHeaderWrapper);
