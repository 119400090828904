import { useQuery } from "@tanstack/react-query";

import { apiClient } from "../utils/apiClient";

interface Props {
  protocol?: string;
  suspense?: boolean;
}

export const useDiscourseCategories = ({ protocol, suspense = true }: Props) => {
  const { data } = useQuery(
    [`discourseCategories:${protocol}`],
    async () => {
      try {
        const returnedData = await apiClient.getDiscourseCategories({
          protocol,
        });
        return returnedData;
      } catch (error) {
        console.error(error);
        return null;
      }
    },
    {
      suspense,
    },
  );

  return { discourseCategories: data?.data };
};
