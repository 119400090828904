import { ProposalDetails, VoteDetails } from "@boardroom/boardroom-api";
import Col from "antd/es/col";
import { useLocation } from "react-router-dom";
import React, { useContext, useMemo } from "react";

import { CalendarEvent } from "../../types";
import { useGetTeam } from "../../hooks/useGetTeam";
import { CurrentAccountContext } from "../../reducers/CurrentAccount";
import { CastVote, CurrentResults } from "../Proposals";
import { ProposalDescriptionModal } from "../Proposals/ProposalDescription";
import { VotingPowerCard } from "../VotingPowerCard";

const ProposalModal = ({
  refId,
  flatProposalEvents,
  proposalData,
  votes,
  protocolCname,
}: {
  refId: string;
  flatProposalEvents?: CalendarEvent[];
  proposalData?: ProposalDetails[];
  votes?: VoteDetails[];
  protocolCname: string;
}) => {
  const query = new URLSearchParams(useLocation().search);
  const { account } = useContext(CurrentAccountContext);
  const teamId = query.get("bundle");
  const teamDetails = useGetTeam(teamId || "");

  const teamWallets = teamDetails?.orgWallets;
  const wallets = useMemo(() => (teamId && teamWallets ? teamWallets : [account]), [teamWallets, account, teamId]);

  return (
    <>
      <Col style={{ paddingLeft: 0, paddingRight: 0, marginTop: "16px" }} span={24}>
        <ProposalDescriptionModal refId={refId} />
      </Col>
      <Col style={{ paddingLeft: 0, paddingRight: 0 }} span={24}>
        <CastVote
          removeDividers
          refId={refId}
          flatProposalEvents={flatProposalEvents}
          proposalData={proposalData}
          votesData={votes}
          teamWallets={wallets}
        />
      </Col>
      <Col style={{ paddingLeft: 0, paddingRight: 0, marginTop: "72px" }} span={24}>
        <CurrentResults removeGoVoteButton refId={refId} />
      </Col>
      <Col style={{ paddingLeft: 0, paddingRight: 0, marginTop: "32px" }} span={24}>
        <VotingPowerCard protocolCname={protocolCname} address={account} teamWallets={wallets} />
      </Col>
    </>
  );
};

export default React.memo(ProposalModal);
