import React from "react";
import styled from "styled-components";
import { FormInstance } from "antd/es/form/Form";
import AntdFormItem from "antd/es/form/FormItem";
import { Button, ButtonProps } from "../Button";
import { COLORS } from "../../constants/colors";

interface Props {
  form: FormInstance;
  children: React.ReactNode;
  loading?: boolean;
  size?: ButtonProps["size"];
  block?: boolean;
  editorContent?: string;
}

const FormItem = styled(AntdFormItem)`
  margin-bottom: 0;
`;

const StyledButton = styled(Button)`
  && {
    background: #948fb9;
    color: white;
    border-radius: 8px !important;
    transition: 0.3s all;
    :hover {
      background: #4235e1;
      color: white;
    }
    :focus,
    :active {
      background: #291fa7;
      color: white;
    }
  }
  &[disabled] {
    border-radius: 8px;
    background: ${COLORS.primary.grayDarkLightest} !important;
    color: #fff;
    :hover {
      color: #fff;
    }
  }
`;

const SubmitButton = ({ form, children, loading = false, size, block = false, editorContent = "" }: Props) => {
  return (
    <FormItem shouldUpdate>
      {() => {
        const isDisabled = () => {
          const fieldsError = form.getFieldsError();
          const areAllFieldsTouched = fieldsError.reduce(
            (isTouched, field) => isTouched && (form.isFieldTouched(field.name) || form.getFieldValue(field.name)),
            true,
          );
          const hasAnyFieldError = !!fieldsError.filter(({ errors }) => errors.length).length;

          return (
            !areAllFieldsTouched ||
            hasAnyFieldError ||
            !(Array.from(editorContent.matchAll(/[a-zA-Z0-9áàâãéèêíïóôõöúçñ]/g)).length > 0)
          );
        };

        return (
          <StyledButton
            type="primary"
            htmlType="submit"
            loading={loading}
            size={size}
            block={block}
            disabled={isDisabled()}
          >
            {children}
          </StyledButton>
        );
      }}
    </FormItem>
  );
};

export default React.memo(SubmitButton);
