import React from "react";
import ContentLoader from "react-content-loader";
import { COLORS } from "../../constants/colors";
import { ProtocolsWrapper, ProtocolWrapper } from "./styles";

const DelegateHubSkeleton = () => {
  return (
    <>
      <ProtocolsWrapper>
        {[1, 2, 3, 4, 5]?.map((value) => (
          <ProtocolWrapper key={value}>
            <ContentLoader
              speed={2}
              height={"40px"}
              backgroundColor={COLORS.primary.grayLight}
              foregroundColor={COLORS.primary.grayLighter}
            >
              <circle cx={16} cy={16} r={16} />
              <rect x="45" y="8" rx="5" ry="5" width="60px" height="16" />
            </ContentLoader>
          </ProtocolWrapper>
        ))}
      </ProtocolsWrapper>
    </>
  );
};

export { DelegateHubSkeleton };
