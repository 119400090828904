import Col from "antd/es/col";
import Row from "antd/es/row";
import React, { useEffect, useState, useRef, useCallback, useContext } from "react";
import styled from "styled-components";
import { isAddress } from "web3-utils";

import { MainContainer, BackgroundForMobile } from "../components";
import { COLORS } from "../constants/colors";
import { ArrowUpIcon, CopyIcon, LightningIcon, SettingsAlternateIcon, ThinChevronUpIcon } from "../components/icons";
import MetaTags from "../components/common/MetaTags";
import { VoteTxnHashProvider } from "../reducers/VoteTxnHash";
import {
  Container,
  PageMax,
  BackToTopSection,
  BackToTopText,
  StyledRowNoMargins,
  PaddingContainer,
  MobileBgWrapper,
} from "./Dashboard/styles";
import { TrackPageView } from "../components/TrackPageView";
import mediaQuery from "../media-query";
import { useParams } from "react-router";
import { useGetTeam } from "../hooks/useGetTeam";
import { Link } from "react-router-dom";
import { useUserDetails } from "../hooks/useUserDetails";
import { useGetEns } from "../hooks/useEns";
import { Pfp } from "../components/Pfp/Pfp";
import { formatEns, toShortAddress } from "../utils";
import ProtocolIcon from "../components/ProtocolIcon";
import { protocols } from "../constants/protocols";
import { useMultipleAddressesVoter } from "../hooks/useVoter";
import { getAddress } from "ethers/lib/utils";
import CopyToClipboard from "react-copy-to-clipboard";
import { notification } from "antd";
import { useVotePower } from "../hooks/useVotePower";
import formatValue from "../utils/formatValue";
import { useGetTeamMembers } from "../hooks/useGetTeamMembers";
import { CurrentAccountContext } from "../reducers/CurrentAccount";
import { CurrentUuidContext } from "../reducers/CurrentUuid";
import ExpandCollapseRows from "../components/common/ExpandCollapseRows";
import { useDelegatesForProtocols } from "../hooks/useDelegateForProtocols";
import { pageHeaderHeight } from "../constants/styles";

const StyledColMain = styled(Col)`
  margin-left: 112px;
  padding-bottom: 100px;
  height: calc(100vh - ${pageHeaderHeight});
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  ${mediaQuery.lessThan("991px")`
    width: 100%;
    margin-left: 0;
    padding-left: 12px !important;
`}
`;

const TeamName = styled.div`
  padding-top: 44px;
  font-weight: 600;
  font-size: 28px;
  line-height: 24px;
  color: #191540;
  padding-bottom: 18px;
  width: 100%;
  border-bottom: 1px solid #f0eff8;
  span {
    font-weight: 200;
  }
`;

const TabsWrapper = styled.div<{ $addPaddingBottom?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
  border-bottom: 1px solid ${COLORS.primary.grayLighter};
  position: relative;
  padding-top: 12px;
`;

const StyledTabNoLink = styled("span")`
  color: #191540;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  display: inline-block;
  min-width: auto;
  span {
    font-weight: 200;
  }
  ${mediaQuery.lessThan("991px")`
    line-height: 30px;
    font-size: 20px;
    min-width: auto;
  `}

  &:after {
    height: 8px;
    display: block;
    border-bottom: 4px solid ${COLORS.primary.accent};
    content: "";
    ${mediaQuery.lessThan("640px")`
        height: 16px;
        border-bottom: 4px solid ${COLORS.primary.accent};
    `}
  }
`;

const ManageTeam = styled(Link)`
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #4235e1;
  gap: 4px;
`;

const WalletsWrapper = styled.div`
  gap: 12px;
  display: flex;
  flex-direction: column;
`;

const WalletWrapper = styled.div`
  border: 1px solid #f0eff8;
  border-radius: 4px;
  background: #ffffff;
  padding: 8px 0px 16px 8px;
  display: flex;
  align-items: center;
  height: 88px;
`;

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
`;

const Username = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  color: #191540;
`;

const MoreProtocols = styled.div`
  border: 1px solid #7b7893;
  border-radius: 8px;
  padding: 0 4px;
  font-weight: 500;
  font-size: 7px;
  line-height: 30px;
  color: #7b7893;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ActiveProtocolsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const StyledCopyIcon = styled(CopyIcon)`
  width: 16px;
  height: 16px;
  color: ${COLORS.primary.accent};
  vertical-align: text-bottom;
  margin-left: 8px;
`;

const StyledCopyText = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  cursor: pointer;
  color: ${COLORS.primary.accent};
  cursor: pointer;
`;

const StyledExpandCollapseIcon = styled(ThinChevronUpIcon)<{ $isExpanded?: boolean }>`
  width: 24px;
  height: 24px;
  margin-left: auto;
  margin-right: 24px;
  cursor: pointer;
  color: ${COLORS.primary.accent};
  transform: ${({ $isExpanded }) => ($isExpanded ? "rotate(0)" : "rotate(180deg)")};
`;

const ProtocolsCardWrapper = styled.div`
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  background: #ffffff;
  border: 1px solid #f0eff8;
  padding: 24px 80px 24px 32px;
  margin-top: -13px;
`;

const VotePowerText = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  margin-left: 4px;
`;

const ProtocolCardWrapper = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 16px;
  margin-bottom: 18px;
  border-bottom: 1px solid #f0eff8;
  transition: 0.3s all;
  color: #191540;
  :hover {
    color: #4235e1;
    border-bottom: 1px solid #4235e1;
  }
  :active,
  :focus {
    color: #291fa7;
    border-bottom: 1px solid #291fa7;
  }
`;

const ProtocolName = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  margin-left: 8px;
`;

const UsersCard = styled.div`
  margin-top: 160px;
  border: 1px solid #ffffff;
  border-radius: 8px;
  padding: 12px 16px;
  background: #ffffff;
  position: relative;
  width: 100%;
`;

const UsersText = styled.div`
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #7b7893;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 16px;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: #f0eff8;
  position: absolute;
  top: 42px;
  margin-left: -16px;
`;

const TeamMemberAddress = styled.span`
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
`;

const FlexColumnDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
`;

const UserWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 16px 0;
  border-bottom: 1px solid #f0eff8;
`;

const TeamMemberName = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  color: #7b7893;
  white-space: nowrap;
  max-width: 12rem;
  text-overflow: ellipsis;
  overflow: hidden;
  display: flex;
  align-items: center;
  gap: 4px;
`;

const TeamMemberLabel = styled.span`
  max-width: 7rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const DelegateText = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #7b7893;
`;

const UserContent = ({
  address,
  name,
  activeProtocolsSize,
  numberOfTeamMembers,
  isTeamAdmin,
  walletIndex,
}: {
  address: string;
  name: string;
  activeProtocolsSize?: number;
  numberOfTeamMembers: number;
  isTeamAdmin?: boolean;
  walletIndex: number;
}) => {
  const { user } = useUserDetails({ address, suspense: false });
  const ens = useGetEns(address, undefined, false);

  const onCopy = useCallback(() => {
    notification.success({
      message: "Copied to clipboard",
      type: "success",
    });
  }, []);

  return (
    <UserWrapper style={{ borderBottom: walletIndex === numberOfTeamMembers - 1 ? "none" : "" }}>
      <Pfp address={address} pfpUrl={user?.pfpUrl} size="medium" dimension={40} />
      <FlexColumnDiv>
        <FlexDiv>
          <TeamMemberName>
            <span style={{ color: isTeamAdmin ? "#191540" : "", fontWeight: "500" }}>
              {walletIndex + 1}/{numberOfTeamMembers}
            </span>{" "}
            <TeamMemberLabel style={{ color: isTeamAdmin ? "#D225BE" : "" }}>
              {isTeamAdmin ? "Admin" : name}
            </TeamMemberLabel>
            <span> · {activeProtocolsSize} Projects</span>
          </TeamMemberName>
        </FlexDiv>
        <FlexDiv>
          <TeamMemberAddress>{user?.username || ens || toShortAddress(address)}</TeamMemberAddress>
          <CopyToClipboard text={address} onCopy={onCopy}>
            <StyledCopyText>
              <StyledCopyIcon />
            </StyledCopyText>
          </CopyToClipboard>
        </FlexDiv>
      </FlexColumnDiv>
    </UserWrapper>
  );
};

const ProtocolCard = ({ protocol, address }: { protocol: string; address: string; isDelegate?: boolean }) => {
  const protocolInfo = protocols[protocol];
  const { power: votePower } = useVotePower(protocol, false, address);
  const { delegateFor } = useDelegatesForProtocols({ address, suspense: false });

  if (votePower === 0 || typeof votePower !== "number") return null;
  return (
    <ProtocolCardWrapper to={`/${protocolInfo?.path}/proposals`}>
      <FlexDiv style={{ width: "200px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
        <ProtocolIcon size="standard" protocol={protocolInfo} />
        <ProtocolName>{protocolInfo?.name}</ProtocolName>
      </FlexDiv>
      {delegateFor?.includes(protocol) && <DelegateText>Delegate</DelegateText>}
      <FlexDiv style={{ width: "88px" }}>
        <LightningIcon width={20} height={20} color="#7B7893" />
        <VotePowerText>{formatValue(votePower, 2)}</VotePowerText>
      </FlexDiv>
    </ProtocolCardWrapper>
  );
};

const WalletContent = ({ address, activeProtocols }: { address: string; activeProtocols?: string[] }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { user } = useUserDetails({ address, suspense: false });
  const ens = useGetEns(address, undefined, false);

  const onCopy = useCallback(() => {
    notification.success({
      message: "Copied to clipboard",
      type: "success",
    });
  }, []);

  const toggleExpand = useCallback(() => {
    if (!activeProtocols?.length) return;
    setIsExpanded((prev) => !prev);
  }, [activeProtocols?.length]);

  return (
    <>
      <WalletWrapper style={{ cursor: activeProtocols?.length ? "pointer" : "default" }} onClick={toggleExpand}>
        <FlexDiv style={{ width: "100%" }}>
          <Pfp shape="square" address={address} size="large" dimension={56} pfpUrl={user?.pfpUrl} noShrink />
          <div style={{ marginLeft: "12px" }}>
            <FlexDiv>
              <Username className="hoverable">{formatEns(ens) || toShortAddress(address, true)}</Username>
              <CopyToClipboard text={address} onCopy={onCopy}>
                <StyledCopyText>
                  <StyledCopyIcon />
                </StyledCopyText>
              </CopyToClipboard>
            </FlexDiv>
            <FlexDiv>
              <ActiveProtocolsWrapper>
                {!!activeProtocols?.length &&
                  activeProtocols
                    ?.slice(0, 10)
                    ?.map((protocol) => <ProtocolIcon size="xxsmall" protocol={protocols[protocol]} key={protocol} />)}
                {!!activeProtocols?.length && activeProtocols?.length > 5 && (
                  <MoreProtocols>+{activeProtocols?.length - 5}</MoreProtocols>
                )}
              </ActiveProtocolsWrapper>
            </FlexDiv>
          </div>
          {!!activeProtocols?.length && <StyledExpandCollapseIcon $isExpanded={isExpanded} />}
        </FlexDiv>
      </WalletWrapper>
      {isExpanded && (
        <ProtocolsCardWrapper>
          <>
            {activeProtocols?.map((protocol) => (
              <ProtocolCard key={protocol} protocol={protocol} address={address} />
            ))}
          </>
        </ProtocolsCardWrapper>
      )}
    </>
  );
};

function TeamDetails() {
  const { account } = useContext(CurrentAccountContext);
  const { uuid } = useContext(CurrentUuidContext);
  const dashboardSection = useRef<HTMLDivElement>(null);
  const { identifier } = useParams<{ identifier: string }>();
  const teamDetails = useGetTeam(identifier || "", true);
  const isTeamAdmin = teamDetails?.adminAddress?.toLowerCase() === account?.toLowerCase();
  const teamMembers = useGetTeamMembers({ address: account, uuid, isTeamAdmin });
  const teamMembersAddresses = teamMembers?.team_members?.map((member) => member.address);
  const votedProtocols = useMultipleAddressesVoter({
    addresses: [...(teamDetails?.orgWallets || []), ...(teamMembersAddresses || [])] || [],
    suspense: false,
  });
  const [isMembersExpanded, setIsMembersExpanded] = useState(false);

  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const handleScroll = (e: any) => {
      if (e.srcElement.classList?.contains("DashboardContainer")) {
        const containerScrollFromTop = e.srcElement.scrollTop;
        if (containerScrollFromTop > 1500) {
          setShowButton(true);
        } else {
          setShowButton(false);
        }
      }
    };

    window.addEventListener("scroll", handleScroll, true);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [showButton]);

  const scrollToTop = () => {
    if (dashboardSection.current) {
      dashboardSection.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const toggleMembers = useCallback(() => {
    setIsMembersExpanded((prev) => !prev);
  }, []);

  return (
    <>
      <TrackPageView name={"Team Details"} />
      <MetaTags
        title="Team Details | Boardroom"
        description="Boardroom is a web3 governance management portal helping users make faster, smarter, and more informed decisions"
        imageUrl="https://uploads-ssl.webflow.com/5fea803f5ceaedd1ffd21e65/602447d1a489bc75f4f2478a_Twitter%20Banner%203.png"
      />
      <VoteTxnHashProvider>
        <MainContainer $isDashboard>
          <MobileBgWrapper>
            <BackgroundForMobile />
          </MobileBgWrapper>
          <Container
            className="DashboardContainer"
            ref={dashboardSection}
            id="scrollElement"
            isSettings={false}
            $isCalendar={true}
          >
            <PaddingContainer>
              <PageMax>
                <StyledRowNoMargins justify={"start"} align="middle">
                  <Col style={{ position: "relative" }} span={24}>
                    <Row style={{ marginLeft: "0", marginRight: "0" }} justify="start" gutter={[24, 24]}>
                      <StyledColMain xs={24} lg={15}>
                        <TeamName>
                          {teamDetails?.orgName} <span>Bundle</span>
                        </TeamName>
                        <TabsWrapper>
                          <StyledTabNoLink>
                            Wallets <span>{teamDetails?.orgWallets?.length}</span>
                          </StyledTabNoLink>
                          <ManageTeam to={"/settings/bundle-profile"}>
                            <SettingsAlternateIcon width={16} height={16} /> Manage Wallets
                          </ManageTeam>
                        </TabsWrapper>
                        <WalletsWrapper>
                          {teamDetails?.orgWallets?.map((wallet) => {
                            const parsedAddress = isAddress(wallet) ? getAddress(wallet) : wallet;
                            return (
                              <WalletContent
                                key={wallet}
                                address={wallet}
                                activeProtocols={votedProtocols?.data?.votesByAddress[parsedAddress]?.protocols?.map(
                                  (protocol) => protocol.protocol,
                                )}
                              />
                            );
                          })}
                        </WalletsWrapper>
                      </StyledColMain>
                      {isTeamAdmin && !!teamMembers?.team_members?.length && (
                        <Col xs={24} lg={6}>
                          <UsersCard>
                            <UsersText>
                              USERS <span style={{ fontWeight: "300" }}>{teamMembers?.team_members?.length}</span>
                            </UsersText>
                            <Divider />
                            <ExpandCollapseRows
                              isExpanded={isMembersExpanded}
                              totalRows={teamMembers?.team_members?.length}
                              onToggle={toggleMembers}
                            >
                              {teamMembers?.team_members?.map((member, index) => {
                                const parsedAddress = isAddress(member.address)
                                  ? getAddress(member.address)
                                  : member.address;
                                return (
                                  <ExpandCollapseRows.Row
                                    key={member.address}
                                    isExpanded={isMembersExpanded}
                                    index={index}
                                    cutoffIndex={6}
                                  >
                                    <UserContent
                                      address={parsedAddress}
                                      name={member.name}
                                      activeProtocolsSize={
                                        votedProtocols?.data?.votesByAddress[parsedAddress]?.protocols?.length
                                      }
                                      numberOfTeamMembers={teamMembers?.team_members?.length}
                                      isTeamAdmin={member.address?.toLowerCase() === account?.toLowerCase()}
                                      walletIndex={index}
                                    />
                                  </ExpandCollapseRows.Row>
                                );
                              })}
                            </ExpandCollapseRows>
                          </UsersCard>
                        </Col>
                      )}
                    </Row>
                    {showButton && (
                      <BackToTopSection onClick={scrollToTop}>
                        <ArrowUpIcon color={COLORS.primary.accent} width={48} height={48} />
                        <BackToTopText>Back to Top</BackToTopText>
                      </BackToTopSection>
                    )}
                  </Col>
                </StyledRowNoMargins>
              </PageMax>
            </PaddingContainer>
          </Container>
        </MainContainer>
      </VoteTxnHashProvider>
    </>
  );
}

export default React.memo(TeamDetails);
