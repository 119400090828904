import React, { useState, useContext, createContext, ReactNode } from "react";

interface Props {
  children: ReactNode;
}

interface FilterContextType {
  showFilters: boolean;
  setShowFilters: React.Dispatch<boolean>;
}

export const FilterContext = createContext({} as FilterContextType);

export const ShowFilter = ({ children }: Props) => {
  const [showFilters, setShowFilters] = useState(false);

  return <FilterContext.Provider value={{ showFilters, setShowFilters }}>{children}</FilterContext.Provider>;
};

export const useMobileFilters = () => {
  const { showFilters, setShowFilters } = useContext(FilterContext);
  return { showFilters, setShowFilters };
};
