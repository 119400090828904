import { gnosisDelegatorsFor } from "@spruceid/ssx-gnosis-extension";
import { AlchemyProvider } from "@ethersproject/providers";
import { useQuery } from "@tanstack/react-query";
import { getAddress } from "ethers/lib/utils";
import { isAddress } from "web3-utils";

const providerUrl = process.env.REACT_APP_RPC_URL_1;
const providerUrlSplitted = providerUrl?.split("/");
const alchemyProvider = new AlchemyProvider(undefined, providerUrlSplitted?.[providerUrlSplitted?.length - 1]);

export const useSiweDelegators = ({ address }: { address: string }) => {
  const { data: delegators } = useQuery<string[], Error>(
    [`siweDelegators:${address}`],
    async () => {
      try {
        const normalizedAddress = isAddress(address) ? getAddress(address) : address;
        return await gnosisDelegatorsFor(normalizedAddress, alchemyProvider);
      } catch {
        return [];
      }
    },
    {
      enabled: !!address,
      suspense: false,
    },
  );

  return delegators;
};
