import * as React from "react";

const SvgPersonCircleIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.042 10a8.958 8.958 0 1117.917 0 8.958 8.958 0 01-17.917 0zM10 2.292a7.708 7.708 0 100 15.416 7.708 7.708 0 000-15.416z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.292 7.5a2.708 2.708 0 115.417 0 2.708 2.708 0 01-5.417 0zM10 6.042a1.458 1.458 0 100 2.916 1.458 1.458 0 000-2.916zM8.333 12.708A3.542 3.542 0 004.8 16.013a.625.625 0 01-1.248-.082 4.792 4.792 0 014.781-4.473h3.334c2.536 0 4.61 1.97 4.78 4.463a.625.625 0 11-1.247.085 3.542 3.542 0 00-3.533-3.298H8.333z"
      fill="currentColor"
    />
  </svg>
);

export default SvgPersonCircleIcon;
