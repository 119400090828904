import React, { Suspense, useCallback, useContext, useEffect, useMemo, useState } from "react";
import styled from "styled-components";

import { Loader } from "../Loader";
import { useProtocols } from "../../hooks/useProtocols";
import ProtocolIcon from "../ProtocolIcon";
import { ToggleSwitch } from "../Checkbox/ToggleSwitch";
import { FilterDropdown } from "../../features/Dashboard/Dropdown";
import { COLORS } from "../../constants/colors";
import { CurrentAccountContext } from "../../reducers/CurrentAccount";
import { useVoter } from "../../hooks/useVoter";
import { CATEGORIES } from "../../constants/categories";
import { Input } from "..";
import media from "../../media-query";
import { CrossIcon, SearchIcon } from "../icons";
import { NoProposalsFound } from "../NoProposalsFound";
import { CurrentUserDetailsContext } from "../../reducers/CurrentUserDetails";
import { LOCAL_STORAGE_FAVORITES_KEY } from "../../constants/general";
import { useWindowDimensions } from "../../hooks/useWindowDimensions";
import { useGetTeam } from "../../hooks/useGetTeam";

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  justify-content: center;
  margin-bottom: 28px;
  padding-bottom: 20px;
  border-bottom: 1px solid ${COLORS.primary.grayLighter};
  margin-top: 54px;
`;

const ListItem = styled.div`
  display: flex;
  padding: 14px 16px;
  align-items: center;
  :hover {
    background: linear-gradient(89.99deg, #ffffff 2.03%, ${COLORS.primary.grayLighter} 54.1%, #ffffff 99.99%);
    box-shadow: 0px 0px 10px rgba(25, 21, 64, 0.1);
    border-radius: 8px;
  }
`;

const FilterWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const FilterLabel = styled.span`
  font-weight: 300;
  font-size: 18px;
  line-height: 28px;
  color: ${COLORS.primary.grayDark};
`;

const ProtocolName = styled.span`
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  color: ${COLORS.primary.grayDark};
  margin-left: 20px;
  margin-right: auto;
`;

const SearchInput = styled(Input)`
  width: 50%;
  border-radius: 40px;
  height: 48px;
  margin-right: auto;
  background: transparent;
  ${media.lessThan("991px")`
    width: 100%;
  `}
`;

const SubscribeToAllWrapper = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  padding: 0 16px 10px;
`;

const SubscribeToAllText = styled.span`
  font-size: 12px;
  line-height: 30px;
  color: ${COLORS.primary.grayDarkLightest};
  margin-right: 16px;
`;

const LoaderWrapper = styled.div`
  margin: 24px 0;
`;

interface Props {
  newlySavedProtocols?: string[];
  checkedList: string[];
  setCheckedList: React.Dispatch<React.SetStateAction<string[]>>;
  userPropKey: "notificationPreferences" | "savedProtocols";
  isTeam?: boolean;
}

const NotificationsProtocolsList = ({
  newlySavedProtocols,
  checkedList,
  setCheckedList,
  userPropKey,
  isTeam,
}: Props) => {
  const favorites = Object.keys(JSON.parse(localStorage.getItem(LOCAL_STORAGE_FAVORITES_KEY) || "{}"));
  const { protocols } = useProtocols(true);
  const { account } = useContext(CurrentAccountContext);
  const { voter } = useVoter({ address: account || "" });
  const [categoryFilter, setCategoryFilter] = useState("All");
  const [searchQuery, setSearchQuery] = useState("");
  const { userDetails } = useContext(CurrentUserDetailsContext);
  const team = useGetTeam(userDetails?.orgMemberships || "");
  const [initialCheckedList, setInitialCheckedList] = useState<string[]>([]);
  const { width } = useWindowDimensions();
  const isMobile = width < 991;

  useEffect(() => {
    if (isTeam && team && userPropKey === "savedProtocols") {
      const parsedDataFromAPI = JSON.parse(team[userPropKey] || "{}");
      setCheckedList((currentCheckedList) => [...currentCheckedList, ...Object.keys(parsedDataFromAPI)]);
      setInitialCheckedList((currentCheckedList) => [...currentCheckedList, ...Object.keys(parsedDataFromAPI)]);
    }
    if (userDetails && !isTeam) {
      const parsedDataFromAPI = JSON.parse(userDetails[userPropKey] || "{}");
      setCheckedList((currentCheckedList) => [...currentCheckedList, ...Object.keys(parsedDataFromAPI)]);
      setInitialCheckedList((currentCheckedList) => [...currentCheckedList, ...Object.keys(parsedDataFromAPI)]);
    }
  }, [userDetails, setCheckedList, userPropKey, isTeam, team]);

  const handleSearchChange = useCallback((val: string) => {
    setSearchQuery(val);
  }, []);

  const parsedProtocols = useMemo(
    () =>
      Object.values(protocols)
        .filter((a) => a.isEnabled)
        .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
        .sort((a, b) => a.order - b.order)
        .sort((a) => (favorites.includes(a.cname) ? -1 : 1))
        .sort((a) =>
          voter ? (voter?.protocols.findIndex((protocol) => protocol.protocol === a.cname) >= 0 ? -1 : 1) : 1,
        )
        .sort((a) => (initialCheckedList?.includes(a.cname) ? -1 : 1))
        .sort((a) => (newlySavedProtocols?.includes(a.cname) ? -1 : 1))
        .filter((a) => {
          if (categoryFilter === "All") return true;
          if (categoryFilter) {
            return a.categories.includes(categoryFilter);
          } else {
            return true;
          }
        })
        .filter((a) => a.name.toLowerCase().includes(searchQuery.toLowerCase())),
    [categoryFilter, favorites, initialCheckedList, newlySavedProtocols, protocols, searchQuery, voter],
  );

  const toggleItem = useCallback(
    (item: string) => {
      if (checkedList.includes(item)) {
        setCheckedList(checkedList.filter((selected) => selected !== item));
      } else {
        setCheckedList([...checkedList, item]);
      }
    },
    [checkedList, setCheckedList],
  );

  const toggleAll = useCallback(() => {
    if (!checkedList.includes("subscribe to all")) {
      setCheckedList([
        ...Object.values(protocols)
          .filter((a) => a.isEnabled)
          .map((protocol) => protocol.cname),
        "subscribe to all",
      ]);
    } else {
      setCheckedList([""]);
    }
  }, [checkedList, protocols, setCheckedList]);

  return (
    <>
      <HeaderWrapper>
        <SearchInput
          value={searchQuery}
          placeholder="Search"
          onChange={(e) => handleSearchChange(e.currentTarget.value)}
          suffix={
            searchQuery.length ? (
              <CrossIcon
                onClick={() => setSearchQuery("")}
                color={COLORS.primary.grayDarkLightest}
                height={20}
                width={20}
              />
            ) : (
              <SearchIcon color={COLORS.primary.grayDarkLightest} height={20} width={20} />
            )
          }
        />
        {!isMobile && (
          <FilterWrapper>
            <FilterLabel>Filter by</FilterLabel>
            <FilterDropdown
              onChange={(value) => setCategoryFilter(value?.value || "All")}
              items={CATEGORIES}
              initialValue={CATEGORIES[0]}
              size="18px"
            />
          </FilterWrapper>
        )}
      </HeaderWrapper>
      <SubscribeToAllWrapper onClick={toggleAll}>
        <SubscribeToAllText>Check All Projects</SubscribeToAllText>
        <ToggleSwitch onClick={toggleItem} checkedList={checkedList} removeMarginBottom value="subscribe to all" />
      </SubscribeToAllWrapper>
      {parsedProtocols.length > 0 ? (
        parsedProtocols.map((protocol) => (
          <ListItem key={protocol.cname}>
            <ProtocolIcon size="medium" protocol={protocol} />
            <ProtocolName>{protocol.name}</ProtocolName>
            <Suspense fallback={<></>}>
              <ToggleSwitch onClick={toggleItem} checkedList={checkedList} removeMarginBottom value={protocol.cname} />
            </Suspense>
          </ListItem>
        ))
      ) : (
        <NoProposalsFound />
      )}
    </>
  );
};

export default ({ ...props }: Props) => (
  <Suspense
    fallback={
      <LoaderWrapper>
        <Loader />
      </LoaderWrapper>
    }
  >
    <NotificationsProtocolsList {...props} />
  </Suspense>
);
