import * as React from "react";

const SvgExternalLinkIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" {...props}>
    <path
      d="M13.065 8.895v3.393a.75.75 0 01-.75.75h-10.5a.75.75 0 01-.75-.75v-10.5a.75.75 0 01.75-.75h3.393M13.065 5.538v-4.5h-4.5M13.065 1.038l-8.25 8.25"
      stroke="currentColor"
      strokeWidth={1.2}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
  </svg>
);

export default SvgExternalLinkIcon;
