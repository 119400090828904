import * as React from "react";

const SvgMultisigIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" {...props}>
    <path
      d="M7.875 2a3.542 3.542 0 100 7.083 3.542 3.542 0 000-7.083zM5.583 5.542a2.292 2.292 0 114.584 0 2.292 2.292 0 01-4.584 0zm2.236 5.625c.91 0 1.632 0 2.213.047.594.049 1.097.15 1.557.384.745.38 1.35.985 1.73 1.73.234.46.335.962.383 1.557.048.581.048 1.302.048 2.212v.528c0 .345-.28.625-.625.625h-10.5A.625.625 0 012 17.625v-.527c0-.91 0-1.632.047-2.213.05-.595.15-1.098.384-1.557.38-.745.985-1.35 1.73-1.73.46-.234.963-.335 1.557-.384.581-.047.303-.047 1.213-.047h.888zm-3.09 1.545c-.51.26-.924.674-1.184 1.183-.13.254-.21.574-.252 1.092-.04.5-.043 1.135-.043 2.013h9.25c0-.878-.002-1.512-.043-2.013-.043-.518-.123-.838-.252-1.091a2.709 2.709 0 00-1.184-1.184c-.254-.13-.574-.21-1.09-.252-.525-.043-1.196-.043-2.14-.043h-.833c-.943 0-.614 0-1.138.043-.517.042-.838.123-1.091.252zM13.049 2.719a.625.625 0 01.858-.213 3.54 3.54 0 010 6.07.625.625 0 11-.646-1.07 2.29 2.29 0 000-3.93.625.625 0 01-.212-.857zM15.954 11.871a.625.625 0 01.84-.273c.745.38 1.351.985 1.73 1.73.235.46.336.962.384 1.557.048.581.048 1.302.048 2.213v.527a.625.625 0 11-1.25 0v-.5c0-.944 0-1.614-.043-2.139-.043-.517-.123-.837-.252-1.09l-1.184-1.184a.625.625 0 01-.273-.84z"
      fill="currentColor"
    />
  </svg>
);

export default SvgMultisigIcon;
