import { GetProposalsResponse, ProposalDetails } from "@boardroom/boardroom-api";
import { useMemo } from "react";
import { FetchNextPageOptions, InfiniteQueryObserverResult, useInfiniteQuery } from "@tanstack/react-query";

import { apiClient } from "../utils/apiClient";
import { protocols } from "../constants/protocols";

interface Props {
  limit?: number;
  status?: "pending" | "active" | "closed";
  excludeVotedOnBy?: string;
  lite?: boolean;
}

interface Response {
  proposals?: ProposalDetails[];
  fetchNextPage: (
    options?: FetchNextPageOptions | undefined,
  ) => Promise<InfiniteQueryObserverResult<GetProposalsResponse, Error>> | undefined;
  hasNextPage?: boolean;
}

export const useAllProposals = ({ limit, status, excludeVotedOnBy, lite }: Props): Response => {
  const { data, fetchNextPage, hasNextPage } = useInfiniteQuery<GetProposalsResponse, Error>([`proposals-${limit}-${status}`], async ({ pageParam: cursor }) => {
    const proposals = await apiClient.getProposals({
      cnames: Object.values(protocols)
        .filter((protocolDescription) => protocolDescription.isEnabled)
        .map((protocolDescription) => protocolDescription.cname),
      limit,
      ...(cursor ? { cursor } : {}),
      status,
      excludeVotedOnBy,
      lite,
    });

    return proposals;
  }, {
    getNextPageParam: (params) => params?.nextCursor,
    keepPreviousData: true,
  });

  const isFirefox = "InstallTrigger" in window;

  const proposals = useMemo(
    () =>
      data?.pages
        .flat()
        .map((d) => d.data)
        .flat()
        .sort((a, b) => {
          if (isFirefox) {
            return a.endTimestamp - b.endTimestamp;
          } else {
            return b.endTimestamp - a.endTimestamp;
          }
        }),
    [data, isFirefox],
  );

  return { proposals: proposals || [], fetchNextPage, hasNextPage };
};
