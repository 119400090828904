import React, { useReducer, createContext, useContext } from "react";
import Cookies from "universal-cookie";
import { CurrentAccountContext } from "./CurrentAccount";

interface Props {
  children: React.ReactNode;
}

type Action = { type: "CLAIMED_NFT"; data: boolean };

interface ClaimedNFTContextType {
  claimedNFT: boolean;
  dispatchClaimedNFT: React.Dispatch<any>;
}

const cookies = new Cookies();

export const ClaimedNFTContext = createContext({} as ClaimedNFTContextType);

const reducer = (state: boolean, action: Action) => {
  switch (action.type) {
    case "CLAIMED_NFT":
      return action.data;
    default:
      return state;
  }
};

export const ClaimedNFTProvider = (props: Props) => {
  const { account } = useContext(CurrentAccountContext);
  const initialState = cookies.get(`claimedNFT:${account?.toLowerCase()}`) || false;
  const [claimedNFT, dispatchClaimedNFT] = useReducer(reducer, initialState);

  return (
    <ClaimedNFTContext.Provider value={{ claimedNFT, dispatchClaimedNFT }}>{props.children}</ClaimedNFTContext.Provider>
  );
};
