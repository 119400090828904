import * as React from "react";

const SvgProjectsListingIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.333 0a5.333 5.333 0 100 10.667A5.333 5.333 0 005.333 0zM2.667 5.333a2.667 2.667 0 115.333 0 2.667 2.667 0 01-5.333 0zm2.666 8a5.333 5.333 0 100 10.667 5.333 5.333 0 000-10.667zm-2.666 5.334a2.667 2.667 0 115.333 0 2.667 2.667 0 01-5.333 0zm10.666 0a5.333 5.333 0 1110.667 0 5.333 5.333 0 01-10.667 0zM18.667 16a2.667 2.667 0 100 5.333 2.667 2.667 0 000-5.333zm0-16a5.333 5.333 0 100 10.667 5.333 5.333 0 000-10.667zM16 5.333a2.667 2.667 0 115.333 0 2.667 2.667 0 01-5.333 0z"
      fill="currentColor"
    />
  </svg>
);

export default SvgProjectsListingIcon;
