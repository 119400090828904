import * as React from "react";

const SvgPinnedIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M9.301 2.91c.653-.436.98-.654 1.335-.618.356.035.634.312 1.189.867l1.014 1.015c.555.555.833.832.868 1.188.035.356-.182.683-.618 1.336l-.796 1.193a8 8 0 00-1.301 3.141l-.127.637a.668.668 0 01-1.036.419A23.393 23.393 0 013.911 6.17a.668.668 0 01.418-1.036l.637-.127.293-.06A8 8 0 007.858 3.87l.25-.165L9.3 2.91z"
      fill="#4235E1"
      stroke="#4235E1"
    />
    <path d="M3.332 12.666l3-3" stroke="#4235E1" strokeLinecap="round" />
  </svg>
);

export default SvgPinnedIcon;
