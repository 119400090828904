import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import notification from "antd/es/notification";
import axios from "axios";
import { useQueryClient } from "@tanstack/react-query";

import { COLORS } from "../../constants/colors";
import media from "../../media-query";
import { InfoCard } from "..";
import { SettingsAlternateIcon } from "../icons";
import { useMixpanel } from "../../hooks";
import { CurrentAccountContext } from "../../reducers/CurrentAccount";
import { CurrentUserDetailsContext } from "../../reducers/CurrentUserDetails";
import { Text } from "../Typography";
import { CurrentUuidContext } from "../../reducers/CurrentUuid";
import { updateUserDetails } from "../../utils/updateUserDetails";
import { Loader } from "../Loader";
import { useSiweFunction } from "../../hooks/useSiweFunction";
import { BOARDROOM_API_KEY, baseAPIUrl } from "../../constants/general";
import { ToggleSwitch } from "../Checkbox/ToggleSwitch";
import { protocols } from "../../constants/protocols";
import { RoutePaths } from "../../constants/Routes";

const StyledMoreResourcesCardWrapper = styled.div`
  width: 100%;
  margin-bottom: 32px;
  ${media.lessThan("640px")`
  margin-top: 16px;
  `};
`;

const CardHeaderSmaller = styled.span`
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: left;
  color: ${COLORS.primary.grayDark};
  margin-top: 6px;
  display: flex;
`;

const CardParagraph = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: ${COLORS.primary.grayDarkLightest};
  margin: 12px auto;
`;

const WeeklyNewsletters = styled.button`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${COLORS.primary.accent};
  text-align: center;
  display: flex;
  align-items: center;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background: #fff;
  position: absolute;
  border: none;
  margin-left: -24px;
  width: 100%;
  padding: 12px 0;
  border-top: 1px solid #f0eff8;
  cursor: pointer;
  svg {
    margin-right: 8px;
  }
  justify-content: center;
  transition: 0.3s all;
  :hover {
    background: #4235e1;
    color: #fff;
  }

  :active {
    outline: none;
    background: #291fa7;
    color: #fff;
  }
`;

const StyledInfoCard = styled(InfoCard)`
  .ant-card-head-title {
    letter-spacing: 0.1em;
  }
`;

const EmailInput = styled.input`
  border: none;
  background: transparent;
  width: 75%;
  padding: 8px 0px 8px 8px;
  :focus {
    outline: none;
  }
`;

const InputWrapper = styled.div`
  border: 1px solid #f0eff8;
  border-radius: 4px;
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 24px;
  transition: 0.3s all;
  :hover {
    border: 1px solid #4235e1;
    button {
      color: #4235e1;
      border-left: 1px solid #4235e1;
    }
  }
`;

const SaveButton = styled.button<{ $isEntering?: boolean }>`
  background: transparent;
  border: none;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #f0eff8;
  border-left: 1px solid #f0eff8;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25%;
  padding: 11px 0;
  cursor: pointer;
  transition: 0.3s all;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  ${({ $isEntering }) => $isEntering && "background: #4235E1; color: #fff !important;"}
  :active {
    outline: none;
    background: #291fa7;
    color: #fff;
  }
  :disabled {
    cursor: not-allowed;
  }
`;

const AddAlertButton = styled(SaveButton)`
  :hover {
    background: #4235e1;
    color: #fff !important;
  }
`;

const ToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  justify-content: center;
  span {
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    color: ${COLORS.primary.grayDark};
    margin: 0 8px;
  }
`;

export const NotificationsCard = ({ onClick, protocolsToAdd }: { onClick: () => void; protocolsToAdd: string[] }) => {
  const { userDetails } = useContext(CurrentUserDetailsContext);
  const [email, setEmail] = useState(userDetails?.email || "");
  const [saving, setIsSaving] = useState(false);
  const [saved, setSaved] = useState(false);
  const { trackClickNotificationsSignUpButton, trackClickSavePreferencesOnNotificationsCard } = useMixpanel();
  const hasSavedPreferences = !!userDetails?.notificationPreferences && userDetails?.notificationPreferences !== "{}";
  const { account } = useContext(CurrentAccountContext);
  const { uuid } = useContext(CurrentUuidContext);
  const siweFunction = useSiweFunction();

  useEffect(() => {
    setEmail(userDetails?.email || "");
  }, [userDetails?.email]);

  const emailRegex = useMemo(() => /^[^\s@]+@[^\s@]+\.[^\s@]+$/, []);

  const handleClick = useCallback(() => {
    onClick();
    trackClickNotificationsSignUpButton({
      userId: account,
    });
  }, [account, onClick, trackClickNotificationsSignUpButton]);

  const saveEmail = useCallback(async () => {
    setIsSaving(true);
    if (!emailRegex.test(email || "")) {
      notification.error({
        message: "Error",
        description: <Text>Please enter a valid email</Text>,
      });
      setIsSaving(false);
      return;
    }
    if (!account) {
      notification.error({
        message: "Error",
        description: <Text>Please connect your wallet to save your preferences</Text>,
      });
      setIsSaving(false);
      return;
    }
    if (account && email) {
      const checkedList = [...(protocolsToAdd || [])];
      const parsedProtocolsPreference: {
        [key: string]: boolean;
      } = {};
      checkedList?.filter(Boolean).map((protocolCname) => (parsedProtocolsPreference[protocolCname] = true));
      const parsedNotificationPreferences = JSON.stringify(parsedProtocolsPreference);
      try {
        const data = {
          address: account,
          ...userDetails,
          email,
          notificationPreferences: hasSavedPreferences
            ? userDetails?.notificationPreferences
            : parsedNotificationPreferences,
          uuid,
        };
        await updateUserDetails({
          address: account,
          userData: data,
          siweFunction,
          siweErrorDescription: "You need to sign in with wallet to save your preferences",
        });
        notification.success({
          message: "Success",
          description: <Text>Your preferences have been saved</Text>,
          duration: 10,
        });
        trackClickSavePreferencesOnNotificationsCard({
          userId: account,
        });
        setSaved(true);
      } catch (e: any) {
        console.error(e);
        if (e.message !== "SIWE Error") {
          notification.error({
            message: "Error",
            description: <Text>Something went wrong... Please try again later</Text>,
          });
        }
      } finally {
        setIsSaving(false);
      }
    }
  }, [
    account,
    email,
    emailRegex,
    hasSavedPreferences,
    siweFunction,
    userDetails,
    uuid,
    trackClickSavePreferencesOnNotificationsCard,
    protocolsToAdd,
  ]);

  return (
    <StyledMoreResourcesCardWrapper>
      <StyledInfoCard style={{ overflow: "visible" }} title="NOTIFICATIONS">
        <CardHeaderSmaller>
          {hasSavedPreferences ? "Update Your Daily Email Digests" : "Daily Email Digests"}
        </CardHeaderSmaller>
        <CardParagraph>
          {hasSavedPreferences
            ? "Customize project notifications anytime and stay on top of only projects you want to see in daily roundups."
            : "Set up custom notifications to receive daily roundups of your new and upcoming proposals."}
        </CardParagraph>
        <InputWrapper>
          <EmailInput
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email"
            type="text"
            name="email"
          />
          <SaveButton
            onClick={saveEmail}
            disabled={(!email?.length && !hasSavedPreferences) || saving}
            $isEntering={!!email?.length}
          >
            {saving && <Loader size="xsmall" />}
            {hasSavedPreferences || saved ? "Edit" : "Save"}
          </SaveButton>
        </InputWrapper>
        <WeeklyNewsletters onClick={handleClick}>
          <SettingsAlternateIcon />
          Customize Notifications
        </WeeklyNewsletters>
      </StyledInfoCard>
    </StyledMoreResourcesCardWrapper>
  );
};

export const AlertsCard = ({ onClick, protocolsToAdd }: { onClick: () => void; protocolsToAdd: string[] }) => {
  const [keyword, setKeyword] = useState("");
  const [saving, setIsSaving] = useState(false);
  const { account } = useContext(CurrentAccountContext);
  const { uuid } = useContext(CurrentUuidContext);
  const siweFunction = useSiweFunction();
  const [isAllProjects, setIsAllProjects] = useState(false);
  const queryClient = useQueryClient();

  const handleClick = useCallback(() => {
    onClick();
  }, [onClick]);

  const saveKeyword = useCallback(async () => {
    if (!account || !uuid) {
      notification.error({
        message: "Error",
        description: "You need to sign in with wallet to update your alerts",
      });
      siweFunction();
      return;
    }
    setIsSaving(true);
    if (account && keyword) {
      const protocolsToUse = isAllProjects ? Object.keys(protocols) : [...(protocolsToAdd || [])];

      try {
        const data = {
          address: account,
          protocols: protocolsToUse,
          keyword,
          uuid,
        };
        if (!data.protocols.length) {
          notification.error({
            message: "Error",
            description: (
              <Text>
                Please select at least one protocol on your{" "}
                <a href={`https://boardroom.io${RoutePaths.settingsFeed}`}>Feed Preferences</a> to add alert with the
                Your Projects filter selected
              </Text>
            ),
          });
          setIsSaving(false);
          return;
        }
        await axios.post(`${baseAPIUrl}discourse/createDiscourseKeywordToSearch?key=${BOARDROOM_API_KEY}`, data);
        notification.success({
          message: "Success",
          description: <Text>Your preferences have been saved</Text>,
          duration: 10,
        });
        queryClient.invalidateQueries(["getAlertsByAddress", account]);
      } catch (e: any) {
        console.error(e);
        if (e.message === "SIWE Error") {
          notification.error({
            message: "Error",
            description: <Text>You need to sign in with wallet to save your preferences</Text>,
          });
          siweFunction();
        }
        if (e.message !== "SIWE Error") {
          notification.error({
            message: "Error",
            description: <Text>Something went wrong... Please try again later</Text>,
          });
        }
      } finally {
        setIsSaving(false);
      }
    }
  }, [account, isAllProjects, keyword, protocolsToAdd, queryClient, siweFunction, uuid]);

  return (
    <StyledMoreResourcesCardWrapper>
      <StyledInfoCard style={{ overflow: "visible" }} title="ALERTS">
        <CardHeaderSmaller>Search</CardHeaderSmaller>
        <CardParagraph>Set up alerts to track discussions that contain your saved key words.</CardParagraph>
        <InputWrapper>
          <EmailInput
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
            placeholder="Enter a Key Word"
            type="text"
            name="keyword"
          />
          <AddAlertButton
            onClick={saveKeyword}
            disabled={(!keyword?.length && !(keyword?.length < 5)) || saving}
            $isEntering={!!keyword?.length}
          >
            {saving && <Loader size="xsmall" />}
            Add
          </AddAlertButton>
        </InputWrapper>
        <ToggleWrapper>
          <span>Your Projects</span>
          <ToggleSwitch
            value="all"
            checkedList={isAllProjects ? ["all"] : []}
            onClick={() => setIsAllProjects((c) => !c)}
            removeMarginBottom
          />
          <span>All Projects</span>
        </ToggleWrapper>
        <WeeklyNewsletters onClick={handleClick}>
          <SettingsAlternateIcon />
          Customize Alerts
        </WeeklyNewsletters>
      </StyledInfoCard>
    </StyledMoreResourcesCardWrapper>
  );
};
