import * as React from "react";

const SvgLockAlternateIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.778 5.454a1.954 1.954 0 113.909 0v1.352H5.778V5.454zm-1.5 1.487V5.454a3.454 3.454 0 116.909 0v1.487a2 2 0 011.278 1.865v3.437a2 2 0 01-2 2H5a2 2 0 01-2-2V8.806a2 2 0 011.278-1.865zM5 8.306h5.465a.5.5 0 01.5.5v3.437a.5.5 0 01-.5.5H5a.5.5 0 01-.5-.5V8.806a.5.5 0 01.5-.5z"
      fill="currentColor"
    />
  </svg>
);

export default SvgLockAlternateIcon;
