import { ApolloClient, gql, InMemoryCache } from "@apollo/client";

const client = new ApolloClient({
  uri: "https://api.cybertino.io/connect/",
  cache: new InMemoryCache(),
});

export default client;

export const FULL_IDENTITY_QUERY = gql`
  query FullIdentityQuery($address: String!) {
    identity(address: $address, network: ETH) {
      address
      domain
      social {
        twitter
      }
      avatar
      joinTime
      followerCount(namespace: "Boardroom")
      followingCount(namespace: "Boardroom")
      followings(namespace: "Boardroom") {
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        list {
          address
          domain
          avatar
          alias
          namespace
          lastModifiedTime
          verifiable
        }
      }
      followers(namespace: "Boardroom") {
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        list {
          address
          domain
          avatar
          alias
          namespace
          lastModifiedTime
          verifiable
        }
      }
      friends(namespace: "Boardroom") {
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
        list {
          address
          domain
          avatar
          alias
          namespace
          lastModifiedTime
          verifiable
        }
      }
    }
  }
`;

export const RECOMMENDATIONS = gql`
  query Recommendations($address: String!) {
    recommendations(address: $address, network: ETH) {
      result
      data {
        list {
          address
          domain
          avatar
          recommendationReason
          followerCount
        }
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
      }
    }
  }
`;
