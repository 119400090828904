import React, { useCallback, useContext, useMemo, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import notification from "antd/es/notification";
import styled, { css } from "styled-components";

import { CurrentAccountContext } from "../../reducers/CurrentAccount";
import { CurrentUuidContext } from "../../reducers/CurrentUuid";
import { useGetAddressWebhooks } from "../../hooks/useGetAddressWebhooks";
import { useOpenZeppelinProtocols } from "../../hooks/useOpenZeppelinProtocols";
import ProtocolIcon from "../ProtocolIcon";
import { protocols } from "../../constants/protocols";
import { BOARDROOM_API_KEY, baseAPIUrl } from "../../constants/general";
import { COLORS } from "../../constants/colors";
import { Input } from "../Input";
import { Dropdown, MultiDropdown } from "../Dropdown";
import { DashedCircleIcon } from "../icons";
import { CreateCardHeaderItem, events } from "../../pages/Webhooks";
import { Loader } from "../Loader";

const CreateCardRow = styled.div`
  display: flex;
  align-items: center;
`;

const ErrorMessage = styled.div`
  color: ${COLORS.secondary.red};
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: 18px;
  margin-top: -16px;
`;

const StyledInput = styled(Input)`
  border: none;
  box-shadow: none;
  padding: 0;
  height: 36px;
  :hover,
  :focus {
    border: none;
    box-shadow: none;
  }
`;

const AddButton = styled.button<{
  $hasError?: boolean;
}>`
  height: 40px;
  border-radius: 4px;
  background: ${COLORS.primary.accent};
  border: none;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  cursor: pointer;
  width: 100px;
  transition: 0.3s all;
  margin-top: -10px;
  :hover {
    background: ${COLORS.primary.accentDark};
  }
  :disabled {
    background: #f6f6f6;
    color: ${COLORS.primary.grayDarkLightest};
    cursor: not-allowed;
  }
  ${({ $hasError }) =>
    $hasError &&
    css`
      border: 1px solid ${COLORS.secondary.red};
      background: #fff;
      color: ${COLORS.secondary.red};
      cursor: pointer;
      :disabled {
        background: #fff;
        color: ${COLORS.secondary.red};
        cursor: pointer;
      }
      :hover {
        background: #fff;
        color: ${COLORS.secondary.red};
      }
    `}
`;

export const CreateWebhookRow = ({
  webhookIdToEdit,
  closeDrawer,
}: {
  webhookIdToEdit?: string;
  closeDrawer: () => void;
}) => {
  const { account } = useContext(CurrentAccountContext);
  const { uuid } = useContext(CurrentUuidContext);
  const webhooks = useGetAddressWebhooks({
    address: account,
    uuid,
  });
  const queryClient = useQueryClient();
  const webhookToEdit = useMemo(() => {
    return webhooks?.find((webhook) => webhook.id === webhookIdToEdit);
  }, [webhooks, webhookIdToEdit]);

  const [url, setUrl] = useState(webhookToEdit?.url ?? "");
  const [protocol, setProtocol] = useState(
    webhookToEdit?.protocols
      ? new Map(webhookToEdit.protocols.split(",").map((protocol) => [protocol, true]))
      : new Map(),
  );
  const [event, setEvent] = useState(webhookToEdit?.type ?? "");
  const [urlError, setUrlError] = useState(false);
  const [protocolError, setProtocolError] = useState(false);
  const [eventError, setEventError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const initialEvent = useMemo(() => {
    return events.find((event) => event.value === webhookToEdit?.type);
  }, [webhookToEdit]);

  const { openZeppelinProtocols } = useOpenZeppelinProtocols();

  const openZeppelinProtocolsItems = useMemo(
    () =>
      openZeppelinProtocols?.map((protocol) => ({
        name: (
          <div>
            <ProtocolIcon protocol={protocols[protocol]} size="xsmall" />
            <span style={{ marginLeft: "8px" }}>{protocols[protocol].name}</span>
          </div>
        ),
        value: protocol,
      })) || [],
    [openZeppelinProtocols],
  );

  const initialProtocol = useMemo(() => {
    return openZeppelinProtocolsItems.find((protocol) => protocol.value === webhookToEdit?.protocols.split(",")[0]);
  }, [openZeppelinProtocolsItems, webhookToEdit?.protocols]);

  const validateUrl = useCallback(() => {
    const regex = new RegExp(
      /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
    );
    if (regex.test(url) && url.length > 0) {
      setUrlError(false);
    } else {
      setUrlError(true);
    }
    return !regex.test(url) && url.length > 0;
  }, [url]);

  const validateProtocol = useCallback(() => {
    if (protocol.size > 0) {
      setProtocolError(false);
    } else {
      setProtocolError(true);
    }
    return protocol.size > 0;
  }, [protocol]);

  const validateEvent = useCallback(() => {
    if (event.length > 0) {
      setEventError(false);
    } else {
      setEventError(true);
    }
    return event.length > 0;
  }, [event]);

  const submit = useCallback(async () => {
    setIsLoading(true);
    const urlValid = validateUrl();
    const protocolValid = validateProtocol();
    const eventValid = validateEvent();
    if (urlValid && protocolValid && eventValid) {
      try {
        await axios.post(`${baseAPIUrl}webhooks/updateSubscriberDetails?key=${BOARDROOM_API_KEY}&address=${account}`, {
          uuid,
          url,
          type: event,
          status: webhookToEdit?.status ?? "active",
          protocols: Array.from(protocol.keys()).join(","),
          ...(webhookToEdit && {
            id: webhookToEdit.id,
          }),
        });
        notification.success({
          message: `Webhook ${webhookIdToEdit ? "Edited" : "Created"}`,
          description: `Your webhook has been successfully ${webhookIdToEdit ? "edited" : "created"}.`,
        });
        closeDrawer();
        setUrl("");
        setProtocol(new Map());
        setEvent("");
        queryClient.invalidateQueries(["webhooks", account, uuid]);
      } catch (e) {
        console.error(e);
        notification.error({
          message: "Error",
          description: "Something went wrong. Please try again.",
        });
      } finally {
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  }, [
    account,
    closeDrawer,
    event,
    protocol,
    queryClient,
    url,
    uuid,
    validateEvent,
    validateProtocol,
    validateUrl,
    webhookIdToEdit,
    webhookToEdit,
  ]);

  return (
    <CreateCardRow>
      <CreateCardHeaderItem style={{ width: "30%" }}>
        {protocolError && <ErrorMessage>No Protocol Selected</ErrorMessage>}
        <MultiDropdown
          buttonStyle={{
            height: "36px",
            border: "none",
            background: "transparent",
            boxShadow: "none",
            padding: "0",
          }}
          itemListStyle={{
            width: "100%",
            maxHeight: "15rem",
            overflow: "auto",
          }}
          initialValue={initialProtocol}
          customArrowIconColor="#4235e1"
          items={openZeppelinProtocolsItems}
          onAdd={(item) => {
            setProtocol((prev) => {
              const newMap = new Map(prev);
              newMap.set(item?.value, true);
              return newMap;
            });
            setProtocolError(false);
          }}
          onRemove={(item) => {
            setProtocol((prev) => {
              const newMap = new Map(prev);
              newMap.delete(item?.value);
              return newMap;
            });
            setProtocolError(false);
          }}
          selectedItems={Array.from(protocol.keys())}
          placeholder={
            <span style={{ display: "flex", alignItems: "center" }}>
              <DashedCircleIcon
                color={COLORS.primary.grayDarkLightest}
                style={{ marginRight: "8px" }}
                width={16}
                height={16}
              />
              Select Protocol
            </span>
          }
          label={null}
        />
      </CreateCardHeaderItem>
      <CreateCardHeaderItem style={{ width: "30%" }}>
        {eventError && <ErrorMessage>No Event Selected</ErrorMessage>}
        <Dropdown
          buttonStyle={{
            height: "36px",
            border: "none",
            background: "transparent",
            boxShadow: "none",
            padding: "0",
          }}
          itemListStyle={{
            width: "100%",
          }}
          initialValue={initialEvent}
          customArrowIconColor="#4235e1"
          items={events}
          onChange={(e) => {
            setEvent(e!.value);
            setEventError(false);
          }}
          placeholder="Select Event Type"
          label={null}
        />
      </CreateCardHeaderItem>
      <CreateCardHeaderItem
        style={{ width: "40%", display: "flex", alignItems: "center", justifyContent: "space-between" }}
      >
        <div>
          {urlError && <ErrorMessage>Invalid URL</ErrorMessage>}
          <StyledInput
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            onBlur={validateUrl}
            placeholder="http://urlhere.io"
          />
        </div>
        <AddButton onClick={submit} disabled={urlError || protocolError || eventError || isLoading}>
          {isLoading ? (
            <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
              <Loader size="xsmall" />
              <span>{webhookIdToEdit ? "Editing" : "Adding"}</span>
            </div>
          ) : webhookIdToEdit ? (
            "Edit"
          ) : (
            "Add"
          )}
        </AddButton>
      </CreateCardHeaderItem>
    </CreateCardRow>
  );
};
