import React, { useReducer, createContext } from "react";

interface Props {
  children: React.ReactNode;
}

type Action = { type: "TOGGLE_STATE"; data: boolean };

interface GlobalSearchContextType {
  globalSearchState: boolean;
  dispatchGlobalSearchState: React.Dispatch<any>;
}

const initialState = false;

export const GlobalSearchContext = createContext({} as GlobalSearchContextType);

const reducer = (state: boolean, action: Action) => {
  switch (action.type) {
    case "TOGGLE_STATE":
      return action.data;
    default:
      return state;
  }
};

export const GlobalSearchStateProvider = (props: Props) => {
  const [globalSearchState, dispatchGlobalSearchState] = useReducer(reducer, initialState);

  return (
    <GlobalSearchContext.Provider value={{ globalSearchState, dispatchGlobalSearchState }}>
      {props.children}
    </GlobalSearchContext.Provider>
  );
};
