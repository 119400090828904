import * as React from "react";

const SvgLightningIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M4.234 9.6H2.799c-1.514 0-2.271 0-2.561-.493-.29-.493.078-1.155.813-2.478l2.746-4.942c.375-.675.562-1.013.75-.964.187.048.187.434.187 1.207V5.9c0 .236 0 .354.073.427.073.073.191.073.427.073h1.434c1.514 0 2.271 0 2.561.493.29.493-.077 1.155-.813 2.478l-2.745 4.942c-.375.675-.563 1.013-.75.964-.187-.048-.187-.434-.187-1.207V10.1c0-.236 0-.354-.074-.427-.073-.073-.19-.073-.426-.073z"
      fill="currentColor"
    />
  </svg>
);

export default SvgLightningIcon;
