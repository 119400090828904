export const CATEGORIES = [
  {
    name: "All Projects",
    value: "All",
  },
  {
    name: "Protocol",
    value: "Protocol",
  },
  {
    name: "Investment",
    value: "Investment",
  },
  {
    name: "Social",
    value: "Social",
  },
  {
    name: "Product",
    value: "Product",
  },
  {
    name: "Service",
    value: "Service",
  },
  {
    name: "Grants",
    value: "Grants",
  },
  {
    name: "Collector",
    value: "Collector",
  },
  {
    name: "Media",
    value: "Media",
  },
  {
    name: "Uncategorized",
    value: "Uncategorized",
  },
];