import Tooltip from "antd/es/tooltip";
import Empty from "antd/es/empty";
import React, { Suspense, useCallback, useContext, useState, useRef, useEffect, useMemo } from "react";
import styled from "styled-components";
import ContentLoader from "react-content-loader";

import InfoCard from "../InfoCard";
import { ProgressBar } from "../ProgressBar";
import { useProposal } from "../../hooks/useProposal";
import { COLORS } from "../../constants/colors";
import { Paragraph } from "../Typography";
import ExpandCollapseRows from "../common/ExpandCollapseRows";
import formatValue from "../../utils/formatValue";
import { CurrentResultRefContext } from "../../reducers/CurrentResultRef";
import mediaQuery from "../../media-query";
import { getProposalMainStatus } from "../../utils/getProposalTimeAgoText";
import { LockAlternateIcon } from "../icons";

interface Props {
  protocol?: string;
  refId: string;
  showOnlyIfClosed?: boolean;
  removeGoVoteButton?: boolean;
}

const ResultTitle = styled(Paragraph)`
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #191540;
  display: flex;
  align-items: center;
`;

const StyledInfoCard = styled(InfoCard)<{ withVoteButton: boolean; $hasQuorum?: boolean }>`
  margin-top: 24px;
  border-radius: ${({ withVoteButton }) => (withVoteButton ? "8px 8px 0 0" : "8px")};
  ${({ $hasQuorum }) => $hasQuorum && "padding-bottom: 80px;"}

  .ant-card-body {
    padding-top: 16px;
  }

  ${mediaQuery.lessThan("991px")`
    margin-top: 42px;
  `}
`;

const ResultsValue = styled(Paragraph)`
  font-size: 14px;
  line-height: 21px;
  font-weight: 600;
  color: #7b7893;
`;

const ProgressBarWrapper = styled("div")`
  margin-bottom: 24px;
  display: flex;
  align-items: center;
`;

const ResultsWrapper = styled.div<{ $isExpanded: boolean }>`
  max-height: ${({ $isExpanded }) => ($isExpanded ? "auto" : "300px")};
  overflow: hidden;
`;

const QuorumWrapper = styled.div`
  border-top: 1px solid #f0eff8;
  padding-top: 16px;
  position: absolute;
  width: 100%;
  margin-left: -24px;
  padding: 16px 24px 0;
`;

const QuorumContent = styled.div`
  display: flex;
  align-items: center;
`;

const QuorumTitle = styled.span`
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #7b7893;
  margin-right: 4px;
`;

const QuorumValue = styled.span`
  font-weight: 100;
  font-size: 12px;
  line-height: 21px;
  color: #7b7893;
`;

const ShutterWrapper = styled.div`
  border-radius: 4px;
  border: 1px solid #f0eff8;
  background: #fafafa;
  padding: 4px;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 32px;
  svg {
    color: #ee3fd9;
    flex-shrink: 0;
  }
`;

const ShutterContent = styled.div`
  display: flex;
  flex-direction: column;
  color: #7b7893;
  font-size: 10px;
  font-weight: 600;
  line-height: 14px;
`;

const ResultTitleContent = ({ index, choice }: { index: number; choice: string }) => {
  return (
    <ResultTitle>
      <span style={{ fontWeight: "600" }}>{index}-</span>
      {choice}
    </ResultTitle>
  );
};

const CurrentResults = (props: Props) => {
  const { refId } = props;
  const [areResultsExpanded, setAreResultsExpanded] = useState(false);
  const { proposal } = useProposal({ refId });

  const toggleResultsExpandCollapse = useCallback(() => {
    setAreResultsExpanded(!areResultsExpanded);
  }, [areResultsExpanded]);

  const indexedResult = useMemo(
    () =>
      proposal?.indexedResult
        ?.map((result) => ({
          total: Number(result?.total),
          choice: proposal.choices[result?.choice],
        }))
        .sort((a, b) => b?.total - a?.total) || [],
    [proposal?.choices, proposal?.indexedResult],
  );

  const normalResults = useMemo(
    () =>
      proposal?.results
        .map((result) => ({
          total: Number(result?.total),
          choice: proposal.choices[result.choice],
        }))
        .sort((a, b) => b?.total - a?.total),
    [proposal?.choices, proposal?.results],
  );

  const results = useMemo(
    () => (indexedResult?.length > 0 ? indexedResult : normalResults || []).sort((a, b) => b?.total - a?.total),
    [indexedResult, normalResults],
  );

  const forVotes = useMemo(() => results.find((item) => item.choice?.toLowerCase() === "for")?.total || 0, [results]);

  const totalVotes =
    proposal?.protocol === "radicle"
      ? forVotes
      : indexedResult?.reduce((votingPower, result) => Number(votingPower) + Number(result?.total), 0) ||
        normalResults?.reduce((votingPower, result) => Number(votingPower) + Number(result?.total), 0);
  const unselectedChoices = useMemo(
    () => proposal?.choices.filter((choice) => !results.find((item: any) => item.choice === choice)) || [],
    [proposal?.choices, results],
  );
  if (!proposal) {
    return null;
  }

  if (proposal?.totalVotes === 0) {
    return <Empty style={{ color: "black" }} description="No Votes Received Yet!" />;
  }
  const totalVotingPower =
    proposal.indexedResult?.reduce((votingPower, result) => Number(votingPower) + Number(result?.total), 0) ||
    proposal.results.reduce((votingPower, result) => Number(votingPower) + Number(result?.total), 0);

  return (
    <>
      {proposal?.privacy === "shutter" && proposal?.currentState === "active" && (
        <ShutterWrapper>
          <LockAlternateIcon width={16} height={16} />
          <ShutterContent>
            <span>This proposal has Shutter privacy enabled.</span>{" "}
            <span style={{ fontWeight: "400", lineHeight: "12px" }}>
              All votes will be encrypted until the voting period has ended and the final score is calculated
            </span>
          </ShutterContent>
        </ShutterWrapper>
      )}
      <ResultsWrapper $isExpanded={areResultsExpanded}>
        <ExpandCollapseRows
          isExpanded={areResultsExpanded}
          totalRows={results.length + unselectedChoices.length}
          onToggle={toggleResultsExpandCollapse}
          cutoffIndex={3}
        >
          {results.map(({ total, choice }, index) => (
            <>
              {(!!total || (proposal?.privacy === "shutter" && proposal?.currentState === "active")) && (
                <ExpandCollapseRows.Row index={index} cutoffIndex={3} isExpanded={areResultsExpanded} key={choice}>
                  <ResultTitleContent index={index + 1} choice={choice} />
                  <ResultsValue>
                    {total === 0 ? (
                      <span>
                        <span style={{ fontWeight: "400" }}>N/A</span> Tokens
                      </span>
                    ) : (
                      formatValue(total)
                    )}
                  </ResultsValue>
                  <ProgressBarWrapper>
                    <ProgressBar
                      trailColorCustom="#F0EFF8"
                      strokeColorCustom="#DBD8FA"
                      showInfo={proposal?.privacy === "shutter" && proposal?.currentState === "active" ? false : true}
                      percent={parseFloat(((total / totalVotingPower) * 100)?.toFixed(2))}
                    />
                    {proposal?.privacy === "shutter" && proposal?.currentState === "active" && (
                      <Tooltip
                        overlayInnerStyle={{ width: "20rem" }}
                        placement="topLeft"
                        title="This proposal has Shutter privacy enabled. All votes will be encrypted until the voting period has ended and the final score is calculated"
                      >
                        <LockAlternateIcon
                          style={{ cursor: "help", marginLeft: "8px", flexShrink: "0" }}
                          width={16}
                          height={16}
                          color="#7b7893"
                        />
                      </Tooltip>
                    )}
                  </ProgressBarWrapper>
                </ExpandCollapseRows.Row>
              )}
            </>
          ))}
          {unselectedChoices.map((item, index) => (
            <>
              {!!item && (
                <ExpandCollapseRows.Row
                  index={index}
                  cutoffIndex={3 - results?.length}
                  isExpanded={areResultsExpanded}
                  key={item}
                >
                  <ResultTitleContent index={index + 1 + results?.length} choice={item} />

                  <ResultsValue>
                    <span style={{ fontWeight: "400" }}>N/A</span> Tokens
                  </ResultsValue>
                  <ProgressBarWrapper>
                    <ProgressBar
                      trailColorCustom="#F0EFF8"
                      strokeColorCustom="#DBD8FA"
                      showInfo={proposal?.privacy === "shutter" && proposal?.currentState === "active" ? false : true}
                      percent={parseFloat(((0 / totalVotingPower) * 100).toFixed(2))}
                    />

                    {proposal?.privacy === "shutter" && proposal?.currentState === "active" && (
                      <Tooltip
                        overlayInnerStyle={{ width: "20rem" }}
                        placement="topLeft"
                        title="This proposal has Shutter privacy enabled. All votes will be encrypted until the voting period has ended and the final score is calculated"
                      >
                        <LockAlternateIcon
                          style={{ cursor: "help", marginLeft: "8px", flexShrink: "0" }}
                          width={16}
                          height={16}
                          color="#7b7893"
                        />
                      </Tooltip>
                    )}
                  </ProgressBarWrapper>
                </ExpandCollapseRows.Row>
              )}
            </>
          ))}
        </ExpandCollapseRows>
        {proposal?.protocol === "optimism" && proposal?.type === "approvalVoting"
          ? null
          : !!proposal?.quorum && (
              <QuorumWrapper>
                <QuorumContent>
                  <QuorumTitle>Quorum</QuorumTitle>{" "}
                  <QuorumValue>
                    {formatValue(totalVotes)}/{formatValue(proposal?.quorum)}
                  </QuorumValue>
                </QuorumContent>
                <ProgressBarWrapper>
                  <ProgressBar
                    strokeColorCustom="rgba(123, 120, 147, 0.3)"
                    trailColorCustom="rgba(123, 120, 147, 0.1)"
                    percent={parseFloat((((totalVotes || 0) / proposal?.quorum) * 100).toFixed(1))}
                  />
                </ProgressBarWrapper>
              </QuorumWrapper>
            )}
      </ResultsWrapper>
    </>
  );
};

export default (props: Props) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { dispatchCurrentResultRef } = useContext(CurrentResultRefContext);

  const { proposal } = useProposal({ refId: props.refId });

  useEffect(() => {
    dispatchCurrentResultRef({ type: "SAVE_CURRENT_RESULT_REF", data: wrapperRef });
  }, [wrapperRef, dispatchCurrentResultRef]);

  if (getProposalMainStatus(proposal?.currentState || "") !== "closed" && props.showOnlyIfClosed) {
    return null;
  }

  return (
    <div
      ref={wrapperRef}
      style={{
        marginBottom: "24px",
        position: "sticky",
        minHeight: "auto",
        boxSizing: "border-box",
      }}
    >
      <StyledInfoCard
        $hasQuorum={!!proposal?.quorum && !(proposal?.protocol === "optimism" && proposal?.type === "approvalVoting")}
        id="currentResults"
        title="Current Results"
        withVoteButton={false}
      >
        <Suspense
          fallback={
            <ContentLoader
              speed={2}
              width="100%"
              height={120}
              backgroundColor={COLORS.primary.grayLight}
              foregroundColor={COLORS.primary.grayLighter}
            >
              <rect x="0" y="5" rx="5" ry="5" width="60%" height="16" />
              <rect x="0" y="31" rx="5" ry="5" width="100%" height="10" />

              <rect x="0" y="75" rx="5" ry="5" width="60%" height="16" />
              <rect x="0" y="101" rx="5" ry="5" width="100%" height="10" />
            </ContentLoader>
          }
        >
          <CurrentResults {...props} />
        </Suspense>
      </StyledInfoCard>
    </div>
  );
};
