import * as React from "react";

const SvgBookmarkIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M2.667 2.667c0-.737.597-1.334 1.333-1.334h8c.736 0 1.333.597 1.333 1.334V14a.667.667 0 01-1.054.542L8 11.486l-4.28 3.056A.666.666 0 012.668 14V2.667zm9.333 0H4v10.037l3.613-2.58a.667.667 0 01.775 0L12 12.704V2.667z"
      fill="currentColor"
    />
  </svg>
);

export default SvgBookmarkIcon;
