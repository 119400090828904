import ExportOutlined from "@ant-design/icons/ExportOutlined";
import Col from "antd/es/col";
import Row from "antd/es/row";
import Tooltip from "antd/es/tooltip";
import Typography from "antd/es/typography";
import { Content } from "antd/lib/layout/layout";
import React from "react";
import styled from "styled-components";
import media from "styled-media-query";

import { COLORS } from "../constants/colors";

const { Text } = Typography;

export const VoteTooltip = styled(Tooltip)`
  border-radius: 5rem;
  cursor: pointer;
`;

export const Container = styled.div`
  padding: 40px;
`;

export const ContentArea = styled(Content)`
  padding 0 0rem;
  ${media.lessThan("large")`
  padding: 0 0;
`}
`;

interface Copyable {
  text: string;
}

interface RowofTwoProps {
  title: any;
  content?: any;
  copyable?: boolean | Copyable;
  link?: string;
  onClick?: (value?: any) => void;
  styleRightColumn?: React.CSSProperties;
}

export const RowOfTwo = ({ title, content, copyable = false, link = "", styleRightColumn }: RowofTwoProps) => {
  return (
    <Row align="stretch" justify="space-between" style={{ paddingBottom: 3 }} key={title}>
      <Col>
        <b>{title}</b>
      </Col>
      {content ? (
        !link ? (
          <Col style={styleRightColumn}>
            <Text copyable={copyable} style={{ paddingLeft: "15px" }}>
              {content}
            </Text>
          </Col>
        ) : (
          <Col style={styleRightColumn}>
            <a href={link} rel="noopener noreferrer" target="_blank">
              <Text copyable={copyable} style={{ paddingLeft: "15px" }}>
                {content}&nbsp;
                <ExportOutlined color={COLORS.primary.accent} />
              </Text>
            </a>
          </Col>
        )
      ) : null}
    </Row>
  );
};
