import React from 'react';

function useHover() {
  const [isHovered, setHovered] = React.useState(false);
  const bind = React.useMemo(() => ({
    onMouseEnter: e => void setHovered(true),
    onMouseLeave: e => void setHovered(false)
  }), []);
  return [isHovered, bind];
}

export default useHover;