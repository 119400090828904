import React, { Suspense, useCallback, useMemo, useRef, useState } from "react";
import moment from "moment";

import { useCurrentRefId } from "../../reducers/CurrentRefId";
import { ThinCrossIcon, ArrowIcon, ThinChevronUpIcon } from "../icons";
import { protocols } from "../../constants/protocols";
import ProtocolIcon from "../ProtocolIcon";
import { eventTypeColorCoding, eventTypeColorCodingText, isDateToday } from "../Calendar/utils";
import { CalendarEvent } from "../../types";
import {
  BottomNavigationItemWrapper,
  CloseSplitViewWrapper,
  Event,
  EventColoredMarker,
  EventDate,
  EventDateValue,
  EventHour,
  EventMetaInfo,
  EventPadding,
  EventProtocolAndType,
  EventTitle,
  FlexDiv,
  NextProposalHeaderWrapper,
  ProtocolPageLink,
  ProtocolPageLinksWrapper,
  SplitViewBottomNavigationWrapper,
  SplitViewHeaderNavigationWrapper,
  SplitViewHeaderWrapper,
  SplitViewProtocolDropdownToggleWrapper,
} from "../Calendar/ListView/styles";
import { useWindowDimensions } from "../../hooks/useWindowDimensions";
import { useDelegatesByProtocol } from "../../hooks/useDelegatesByProtocol";
import { useCurrentSplitViewIndex } from "../../reducers/CurrentSplitViewIndex";
import { EventRowSuspense } from "../Calendar/ListView/EventRow";
import { useDiscourseTopics } from "../../hooks/useDiscourseTopics";
import { DailyBrief } from "../../hooks/useGetDailyBriefs";
import { DailyBriefContent } from "./DailyBriefContent";

interface EventRowProps {
  event: CalendarEvent;
  briefsData?: DailyBrief[];
  isLastItem?: boolean;
  isFirstItem?: boolean;
  lastItemBorderWidth?: number;
  isToday?: boolean;
  briefEvents: CalendarEvent[];
}

export interface BriefContent {
  title: string;
  proposalSummaries: {
    title: string;
    proposalSummary: string;
    refId: string;
    protocol: string;
  }[];
  forumSummaries: {
    title: string;
    summary: string;
    topicId: string;
    protocol: string;
  }[];
}

export const SplitViewContent = ({ briefEvents, brief }: { briefEvents: CalendarEvent[]; brief: DailyBrief }) => {
  const protocolCname = brief.protocol;
  const [isSplitViewHeaderExpanded, setIsSplitViewHeaderExpanded] = useState(false);
  const { currentRefId, setCurrentRefId } = useCurrentRefId();
  const briefContent: BriefContent = JSON.parse(brief.dailyBrief || "{}");

  const { setCurrentSplitViewIndex, currentSplitViewIndex } = useCurrentSplitViewIndex();

  const { discourseTopics } = useDiscourseTopics({
    suspense: false,
    protocols: [protocolCname || ""],
  });

  const { delegates } = useDelegatesByProtocol({
    protocol: protocolCname || "",
    limit: 24,
    suspense: false,
  });

  const handleClickNextProposal = useCallback(() => {
    if (briefEvents) {
      if (currentSplitViewIndex) {
        const nextProposal = briefEvents[currentSplitViewIndex + 1];
        setCurrentRefId(nextProposal?.proposalRefId || "");
        setCurrentSplitViewIndex(currentSplitViewIndex + 1);
      } else {
        const currProposalIndex = briefEvents.findIndex((event) => event.proposalRefId === currentRefId);
        const nextProposal = briefEvents[currProposalIndex + 1];
        setCurrentRefId(nextProposal?.proposalRefId || "");
        setCurrentSplitViewIndex(currProposalIndex + 1);
      }
    }
  }, [currentRefId, setCurrentRefId, briefEvents, currentSplitViewIndex, setCurrentSplitViewIndex]);

  const handleClickPreviousProposal = useCallback(() => {
    if (briefEvents) {
      if (currentSplitViewIndex) {
        const previousProposal = briefEvents[currentSplitViewIndex - 1];
        setCurrentRefId(previousProposal?.proposalRefId || "");
        setCurrentSplitViewIndex(currentSplitViewIndex - 1);
      } else {
        const currProposalIndex = briefEvents.findIndex((event) => event.proposalRefId === currentRefId);
        const previousProposal = briefEvents[currProposalIndex - 1];
        setCurrentRefId(previousProposal?.proposalRefId || "");
        setCurrentSplitViewIndex(currProposalIndex - 1);
      }
    }
  }, [currentRefId, setCurrentRefId, briefEvents, setCurrentSplitViewIndex, currentSplitViewIndex]);

  const handleClose = useCallback(() => {
    setCurrentRefId("");
    setCurrentSplitViewIndex(undefined);
  }, [setCurrentRefId, setCurrentSplitViewIndex]);

  const toggleSplitViewHeader = useCallback(() => {
    setIsSplitViewHeaderExpanded((curr) => !curr);
  }, []);

  return (
    <div>
      <SplitViewHeaderWrapper $isExpanded={isSplitViewHeaderExpanded}>
        <SplitViewHeaderNavigationWrapper>
          <NextProposalHeaderWrapper onClick={handleClickPreviousProposal} $type="previous">
            <ThinChevronUpIcon />
          </NextProposalHeaderWrapper>
          <NextProposalHeaderWrapper onClick={handleClickNextProposal} $type="next">
            <ThinChevronUpIcon />
          </NextProposalHeaderWrapper>
        </SplitViewHeaderNavigationWrapper>
        <div style={{ width: "100%" }}>
          <SplitViewProtocolDropdownToggleWrapper
            onClick={toggleSplitViewHeader}
            $isExpanded={isSplitViewHeaderExpanded}
          >
            <ProtocolIcon size="small" protocol={protocols[protocolCname]} />
            <span>{protocols[protocolCname]?.name}</span>
            <ArrowIcon width={14} height={14} color="#4235e1" />
          </SplitViewProtocolDropdownToggleWrapper>
        </div>
        <div style={{ width: "112px" }}>
          <CloseSplitViewWrapper onClick={handleClose}>
            <ThinCrossIcon />
          </CloseSplitViewWrapper>
        </div>
      </SplitViewHeaderWrapper>
      {isSplitViewHeaderExpanded && (
        <ProtocolPageLinksWrapper>
          <ProtocolPageLink target="_blank" to={`/${protocols[protocolCname]?.path}/proposals`}>
            Governance
          </ProtocolPageLink>
          {!!discourseTopics?.length && (
            <ProtocolPageLink target="_blank" to={`/${protocols[protocolCname]?.path}/discussions`}>
              Discussions
            </ProtocolPageLink>
          )}
          <ProtocolPageLink
            target="_blank"
            to={
              delegates?.length
                ? `/${protocols[protocolCname]?.path}/delegates`
                : `/${protocols[protocolCname]?.path}/voters`
            }
          >
            Members
          </ProtocolPageLink>
          <ProtocolPageLink target="_blank" to={`/${protocols[protocolCname]?.path}/overview`}>
            Overview
          </ProtocolPageLink>
        </ProtocolPageLinksWrapper>
      )}
      <div style={{ marginTop: isSplitViewHeaderExpanded ? "122px" : "64px" }} />
      <div style={{ marginTop: "24px" }} />
      <DailyBriefContent briefContent={briefContent} title={briefEvents[currentSplitViewIndex || 0]?.title} />
      <div style={{ marginTop: "96px" }} />
      <SplitViewBottomNavigationWrapper style={{ margin: "0", width: "100%" }}>
        <BottomNavigationItemWrapper $type="previous" onClick={handleClickPreviousProposal}>
          <ThinChevronUpIcon />
          <span>Previous Brief</span>
        </BottomNavigationItemWrapper>
        <BottomNavigationItemWrapper onClick={handleClickNextProposal}>
          <ThinChevronUpIcon />
          <span>Next Brief</span>
        </BottomNavigationItemWrapper>
      </SplitViewBottomNavigationWrapper>
    </div>
  );
};

const EventRow = ({ event, isLastItem, isFirstItem, lastItemBorderWidth, briefEvents }: EventRowProps) => {
  const eventRef = useRef<HTMLDivElement | null>(null);

  const { currentRefId, setCurrentRefId } = useCurrentRefId();
  const { setCurrentSplitViewIndex } = useCurrentSplitViewIndex();
  const { width } = useWindowDimensions();

  const eventIndex = briefEvents?.findIndex(
    (eventInArray) => eventInArray.proposalRefId === event.proposalRefId && eventInArray.type === event.type,
  );

  const handleClick = useCallback(() => {
    setCurrentRefId(event.proposalRefId || "");
    setCurrentSplitViewIndex(eventIndex > -1 ? eventIndex : undefined);
  }, [event.proposalRefId, setCurrentRefId, eventIndex, setCurrentSplitViewIndex]);

  const isActive = useMemo(() => currentRefId === event.proposalRefId, [currentRefId, event.proposalRefId]);
  return (
    <>
      <Event ref={eventRef} key={event.id} onClick={handleClick}>
        <EventColoredMarker
          $isTodayAndIsFirstItem={isDateToday(moment(event.date)) && isFirstItem}
          $isTodayAndIsLastItem={isDateToday(moment(event.date)) && isLastItem}
          $color={eventTypeColorCoding[event.type]}
        />
        <EventPadding
          $isActive={isActive}
          $lastItem={isLastItem}
          $firstItem={isFirstItem}
          $lastItemBorderBottomWidth={lastItemBorderWidth}
        >
          <EventMetaInfo>
            <ProtocolIcon protocol={protocols[event.protocolCname]} />
            <EventDate>
              <EventHour>{moment(event.date).utcOffset(0).format("HH:mm")}</EventHour>
              <EventDateValue>{moment(event.date).utcOffset(0).format("MMM DD")?.toUpperCase()}</EventDateValue>
            </EventDate>
          </EventMetaInfo>
          <FlexDiv>
            <div className="opacityForArchiving">
              <EventTitle $width={width} $isActive={isActive}>
                {event.title}
              </EventTitle>
              <EventProtocolAndType $color={eventTypeColorCodingText[event.type]}>
                {protocols[event.protocolCname]?.name} · Daily Brief
              </EventProtocolAndType>
            </div>
          </FlexDiv>
        </EventPadding>
      </Event>
    </>
  );
};

const SuspendedEventRow = (props: EventRowProps) => {
  return (
    <Suspense fallback={<EventRowSuspense />}>
      <EventRow {...props} />
    </Suspense>
  );
};

export default SuspendedEventRow;
