import { Adapters, DelegationAdapter, StakingTokenAdapter, VoteAdapter } from "@boardroom/gov-sdk";
import { sdk } from "../constants/protocols";
import { checkAdapterFrameworkToUse } from "../hooks/useVotePowerAdapter";

const chainIds = {
  1: "0x1",
  42161: "0xa4b1",
  10: "0xa",
  5: "0x5",
};

interface Props {
  protocolCname?: string;
  adapterType: keyof Adapters;
}

export const getConnectedChainId = async ({ protocolCname, adapterType }: Props): Promise<string | undefined> => {
  if (!protocolCname) return (chainIds as Record<number, string>)[1];
  const protocolInSdk = sdk.getProtocol(protocolCname);
  const adapterFrameworks = protocolInSdk?.adapterInstances(adapterType);
  const framework = checkAdapterFrameworkToUse(adapterFrameworks);

  const chainId = await (
    protocolInSdk.adapter(adapterType, framework) as VoteAdapter | DelegationAdapter | StakingTokenAdapter
  ).getChainId();
  return (chainIds as Record<number, string>)[chainId];
};
