import React, { createContext, useEffect, useState } from "react";
import mixpanel, { Mixpanel } from "mixpanel-browser";
import { isStaging, isProduction } from "../utils";

interface MixpanelContextType {
  mixpanel: Mixpanel;
  ip: string;
}

if (isStaging || isProduction) {
  mixpanel.init(process.env.REACT_APP_MIXPANEL_PROJECT_ID!, {
    api_host: "https://mixpanel-api-proxy-3eeql.ondigitalocean.app/",
    ip: false,
  });
}

export const MixpanelContext = createContext({ ip: "" } as MixpanelContextType);

interface Props {
  children: React.ReactNode;
}

export const MixpanelContextProvider = (props: Props) => {
  const { children } = props;
  const [ipAddress, setIpAddress] = useState("");
  useEffect(() => {
    let ipAddress = "";

    async function getIp() {
      try {
        const ipResponse = await fetch("https://api64.ipify.org?format=json").then((response) => response.json());
        ipAddress = ipResponse.ip;
        setIpAddress(ipAddress);
      } catch (error) {
        console.error(error);
      }
    }

    if (isStaging || isProduction) {
      getIp();
    }
  }, [setIpAddress]);

  return <MixpanelContext.Provider value={{ mixpanel, ip: ipAddress }}>{children}</MixpanelContext.Provider>;
};
