import * as React from "react";

const SvgSearchIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 19" width="1em" height="1em" {...props}>
    <path
      d="M8.43 16.87a8.44 8.44 0 118.44-8.44 8.45 8.45 0 01-8.44 8.44zM8.43 3a5.44 5.44 0 105.44 5.43A5.44 5.44 0 008.43 3z"
      fill="currentColor"
    />
    <path
      d="M17.5 19a1.51 1.51 0 01-1.06-.44l-4.16-4.16a1.5 1.5 0 012.12-2.12l4.16 4.16a1.49 1.49 0 010 2.12 1.51 1.51 0 01-1.06.44z"
      fill="currentColor"
    />
  </svg>
);

export default SvgSearchIcon;
