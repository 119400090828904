import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { ProposalDetails, VoteDetails } from "@boardroom/boardroom-api";
import moment, { Moment } from "moment";

import { CalendarEvents, CalendarEvent } from "../../../types";
import { CurrentAccountContext } from "../../../reducers/CurrentAccount";
import { useVoterVotes } from "../../../hooks/useVoterVotes";
import CurrentMonth from "./CurrentMonth";
import Month from "./Month";
import { PendingVoteDetailsContext } from "../../../reducers/PendingVotes";
import { usePendingVotesByAddress } from "../../../hooks/usePendingVotes";
import { useWindowDimensions } from "../../../hooks/useWindowDimensions";
import { SplitViewDrawer } from "./styles";
import { useCurrentRefId } from "../../../reducers/CurrentRefId";
import { SplitViewContent } from "./EventRow";
import { useLocation } from "react-router-dom";
import {
  getCurrentMonthNextDaysEvents,
  getCurrentMonthPastDaysEvents,
  getEventsByMonth,
  getParsedEventsByDate,
} from "../../../utils/events";
import { useCurrentSplitViewIndex } from "../../../reducers/CurrentSplitViewIndex";
import { isTeamView } from "../../../utils/teamUtils";
import { WalletDrawerContext } from "../../../reducers/WalletDrawer";
import { CurrentUserDetailsContext } from "../../../reducers/CurrentUserDetails";

export interface MonthProps {
  proposalEvents?: CalendarEvents;
  date: Moment;
  proposalData?: ProposalDetails[];
  votes: (VoteDetails | undefined)[] | undefined;
  setSplitViewTab: React.Dispatch<React.SetStateAction<"summary" | "vote" | "notes">>;
  proposalTypeFilter?: "all" | "today" | "open" | "pending" | "closed";
  nextEventsToday?: CalendarEvent[];
  nextEvents?: CalendarEvent[];
  pastEvents?: CalendarEvent[];
  monthEvents?: CalendarEvent[];
  flatProposalEvents: CalendarEvent[];
  bookmarkedProposals: string[];
}

interface Props {
  proposalEvents?: CalendarEvents;
  proposalData?: ProposalDetails[];
  selectedDate: Moment;
  proposalTypeFilter: "all" | "today" | "open" | "pending" | "closed";
}

const CalendarListView = ({ proposalEvents = {}, proposalData, selectedDate, proposalTypeFilter }: Props) => {
  const currentDate = moment();
  const isSameMonth = currentDate.isSame(selectedDate, "month");
  const { account } = useContext(CurrentAccountContext);
  const { userDetails } = useContext(CurrentUserDetailsContext);
  const { isWalletDrawerOpen } = useContext(WalletDrawerContext);
  const { votes } = useVoterVotes({ address: account, suspense: false });
  const { dispatchPendingVoteDetails } = useContext(PendingVoteDetailsContext);
  const pendingVotes = usePendingVotesByAddress({ address: account });
  const { width } = useWindowDimensions();
  const { currentRefId, setCurrentRefId } = useCurrentRefId();
  const { setCurrentSplitViewIndex } = useCurrentSplitViewIndex();
  const isMobile = width <= 991;
  const { search, pathname } = useLocation();
  const query = new URLSearchParams(search);
  const teamId = query.get("bundle");
  const isOnTeamView = isTeamView(pathname, search);

  const [splitViewTab, setSplitViewTab] = useState<"summary" | "vote" | "notes">(teamId ? "notes" : "summary");

  const nextEventsToday = useMemo(() => {
    return getParsedEventsByDate({}, proposalEvents, currentDate);
  }, [proposalEvents, currentDate]);

  const pastEvents = useMemo(() => {
    return getCurrentMonthPastDaysEvents({}, proposalEvents);
  }, [proposalEvents]);

  const nextEvents = useMemo(() => {
    return getCurrentMonthNextDaysEvents({}, proposalEvents);
  }, [proposalEvents]);

  const eventsMonth = useMemo(() => {
    return getEventsByMonth(proposalEvents, {}, selectedDate);
  }, [proposalEvents, selectedDate]);

  const flatProposalEvents = useMemo(() => {
    if (!isSameMonth) {
      return eventsMonth;
    } else {
      return [...nextEventsToday, ...nextEvents, ...pastEvents];
    }
  }, [eventsMonth, nextEvents, nextEventsToday, pastEvents, isSameMonth]);

  useEffect(() => {
    dispatchPendingVoteDetails({
      type: "SAVE_PENDING_VOTE_DETAILS",
      data: pendingVotes,
    });
  }, [pendingVotes, dispatchPendingVoteDetails]);

  const proposal = useMemo(
    () => proposalData?.find((proposalInArray) => proposalInArray.refId === currentRefId),
    [proposalData, currentRefId],
  );

  const handleClose = useCallback(() => {
    setCurrentRefId("");
    setCurrentSplitViewIndex(undefined);
  }, [setCurrentRefId, setCurrentSplitViewIndex]);

  const parsedBookmarkedProposals = useMemo(() => {
    return userDetails?.bookmarkedProposals?.split(",") || [];
  }, [userDetails?.bookmarkedProposals]);

  return (
    <>
      {isSameMonth ? (
        <>
          <CurrentMonth
            proposalData={proposalData}
            proposalEvents={proposalEvents}
            date={currentDate}
            votes={votes}
            setSplitViewTab={setSplitViewTab}
            proposalTypeFilter={proposalTypeFilter}
            nextEvents={nextEvents}
            nextEventsToday={nextEventsToday}
            pastEvents={pastEvents}
            flatProposalEvents={flatProposalEvents}
            bookmarkedProposals={parsedBookmarkedProposals}
          />
        </>
      ) : (
        <Month
          votes={votes}
          proposalData={proposalData}
          proposalEvents={proposalEvents}
          date={selectedDate}
          setSplitViewTab={setSplitViewTab}
          monthEvents={eventsMonth}
          flatProposalEvents={flatProposalEvents}
          bookmarkedProposals={parsedBookmarkedProposals}
        />
      )}
      {!isMobile && proposal && proposal.refId === currentRefId && (
        <SplitViewDrawer
          zIndex={currentRefId ? 16 : -1}
          visible={!!currentRefId}
          onClose={handleClose}
          placement="right"
          contentWrapperStyle={{ width: "100%" }}
          destroyOnClose
          $isTeamView={!!isOnTeamView && !!account}
          $isWalletDrawerOpen={isWalletDrawerOpen}
          $width={width}
        >
          <SplitViewContent
            splitViewTab={splitViewTab}
            proposal={proposal}
            flatProposalEvents={flatProposalEvents}
            setSplitViewTab={setSplitViewTab}
          />
        </SplitViewDrawer>
      )}
    </>
  );
};

export default CalendarListView;
