import { useQuery } from "@tanstack/react-query";
import { Contract } from "@ethersproject/contracts";
import { JsonRpcProvider } from "@ethersproject/providers";
import lock from "@unlock-protocol/contracts/dist/abis/PublicLock/PublicLockV13.json";
import { useContext } from "react";
import { NftSubscriptionContext } from "../reducers/NftSubscription";
import { isProduction } from "../utils";

export const monthlySubscriptionLockAddress = isProduction
  ? "0x1756c6d0d80cbd4b82e85adae1468d0a60de81ee"
  : "0xc11bf98142ef6a3c33b0d278d44125be8cae1713";
export const yearlySubscriptionLockAddress = isProduction
  ? "0x91c58cea6aad23b9d8d8b6772d7c158196b13b58"
  : "0x166fbe3dce333626f9843e199f79e7bc61f406d9";

export const networkId = isProduction ? 10 : 5;
const rpcUrl = isProduction ? process.env.REACT_APP_RPC_URL_10 : process.env.REACT_APP_RPC_URL_5;

export const checkNftKeysForSubscription = async (address: string): Promise<boolean> => {
  try {
    const monthlyContract = new Contract(monthlySubscriptionLockAddress, lock.abi, new JsonRpcProvider(rpcUrl));

    const yearlyContract = new Contract(yearlySubscriptionLockAddress, lock.abi, new JsonRpcProvider(rpcUrl));

    const hasValidKeyMonthlyPromise = monthlyContract.getHasValidKey(address);
    const hasValidKeyYearlyPromise = yearlyContract.getHasValidKey(address);
    const isValidKeyMonthly = await hasValidKeyMonthlyPromise;
    const isValidKeyYearly = await hasValidKeyYearlyPromise;
    const hasValidKey = isValidKeyMonthly || isValidKeyYearly;

    return hasValidKey;
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const useHasValidNftSubscription = (address: string) => {
  const { dispatchNftSubscriptionIsValid } = useContext(NftSubscriptionContext);

  const { data } = useQuery<boolean, Error>(
    [`useHasValidNftSubscription:${address}`],
    async () => {
      try {
        const hasValidKey = await checkNftKeysForSubscription(address);
        dispatchNftSubscriptionIsValid({
          type: "NFT_SUBSCRIPTION_IS_VALID",
          data: hasValidKey,
        });
        return hasValidKey;
      } catch (e) {
        console.log(e);
        return false;
      }
    },
    {
      enabled: !!address,
      suspense: false,
    },
  );

  return data;
};
