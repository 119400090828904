import React from "react";
import styled from "styled-components";
import { COLORS } from "../../../constants/colors";

const Table = styled.table`
  width: 100%;
  table-layout: fixed;
  border: 0;
  border-bottom: 0.5px solid rgba(25, 21, 64, 0.5);
`;

const Weekday = styled.th`
  padding: 12px 0;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  font-style: normal;
  color: ${COLORS.primary.grayDarkLightest};
  text-align: center;
`;

export const WeekdayHeading = () => (
  <Table>
    <thead>
      <Weekday>S</Weekday>
      <Weekday>M</Weekday>
      <Weekday>T</Weekday>
      <Weekday>W</Weekday>
      <Weekday>T</Weekday>
      <Weekday>F</Weekday>
      <Weekday>S</Weekday>
    </thead>
  </Table>
);
