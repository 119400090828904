import * as React from "react";

const SvgStake = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width={24} height={24} rx={12} fill="#fff" />
    <path
      d="M2.4 14.8c0-.394.335-.714.75-.714h18c.414 0 .75.32.75.715 0 .395-.336.715-.75.715h-18c-.415 0-.75-.32-.75-.715z"
      fill="#C8C8C8"
    />
    <path
      d="M2.62 14.295a.776.776 0 011.06 0l6 5.72a.692.692 0 010 1.011.776.776 0 01-1.06 0l-6-5.72a.692.692 0 010-1.01z"
      fill="#C8C8C8"
    />
    <path
      d="M2 10.035c0-.395.336-.715.75-.715h18c.414 0 .75.32.75.715 0 .395-.336.715-.75.715h-18c-.414 0-.75-.32-.75-.715z"
      fill="#35C9F8"
    />
    <path
      d="M14.22 3.81a.776.776 0 011.06 0l6 5.72a.692.692 0 010 1.01.776.776 0 01-1.06 0l-6-5.72a.692.692 0 010-1.01z"
      fill="#35C9F8"
    />
  </svg>
);

export default SvgStake;
