export const COLORS = {
  blue: "#1890ff",
  green: "#27AE60",
  red: "#FF4D4F",
  violet: "#7D76CC",
  primary: {
    accent: "#4235E1",
    accentMedium: "#4E28CC",
    accentDark: "#291FA7", 
    accentDarker: "#130C6C",
    accentLight: "#C8C4F6",
    accentLighter: "#F9F9FF",
    gray: "#AFADC3",
    grayLight: "#DDDCEA",
    grayLighter: "#F0EFF8",
    grayLightest: "#FBFBFE",
    grayDark: "#191540",
    grayDarkLight: "#343151",
    grayDarkLightest: "#7B7893",
    grayDarkLighter: "#5A5774",
  },
  secondary: {
    red: "#FF4141",
    redLight: "#FFBEBE",
    redLighter: "#FFF1F1",
    redDark: "#C22020",
 
    green: "#43BC2C",
    greenLight: "#98DD8B",
    greenLighter: "#F2FEEF",
    greenDark: "#108043",
 
    blue: "#35C9F8",
    blueLight: "#86DCF8",
    blueLighter: "#EEFBFF",
    blueDark: "#1487B8",
    blueDarker: "#4A11CA",
    blueDarkest: "#420BBC",
    blueRoyal: "#5625d6",

    yellow: "#FFD105",
    yellowLight: "#FFE881",
    yellowLighter: "#FAF6D3",
    
    orange: "#FF7D34",
    orangeLight: "#FFC09D",
    orangeLighter: "#FFF5F0",

    teal: "#52DBCA",
    tealLight: "#A2EFE6", 
    tealLighter: "#EFFEFC",
    tealDark: "#2AD0CA", 
    indigo: "#5162F9",
    indigoLight: "#8D98FA",
    indigoLighter: "#EEF0FF",

    pink: "#FC66AE",
    pinkLight: "#FFBEBE",
    pinkLighter: "#FFF0F7",
    pinkDark: "#EE3FD9",

    purple: "#9E97F3",
    purpleLighter: "#DBD8Fa",
    purpleLightest: "#EDECFD",
  },
};

export const StatusTagColorMap: Record<string, string> = {
  active: "green",
  approved: "lime",
  executed: "blue",

  canceled: "yellow",
  expired: "magenta",
  rejected: "red",

  proposed: "purple",
  wip: "cyan",
};

export const OverviewGraphColors = [
  COLORS.primary.accent,
  COLORS.primary.accentLight,
  COLORS.secondary.blue,
  "#EE3FD9",
  COLORS.secondary.teal,
  COLORS.secondary.yellow,
];