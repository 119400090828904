import { identifier } from "../constants/identifier";
import { useSdk } from "./useSdk";

export const useDelegateVotingPower = (cname: string) => {
  const sdk = useSdk();

  return {
    delegateVotingPower: async (delegatee: string, framework: string, amount?: number) =>
      framework === "default" || framework === ""
        ? (await sdk?.getProtocol(cname).adapter("delegation").delegateVotingPower(delegatee, identifier, amount)) ??
          null
        : (await sdk
            ?.getProtocol(cname)
            .adapter("delegation", framework)
            .delegateVotingPower(delegatee, identifier, amount)) ?? null,
  };
};
