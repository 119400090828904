import React, { useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import notification from "antd/es/notification";

import { COLORS } from "../../constants/colors";
import { Loader } from "../Loader";
import { Input } from "../Input";
import { apiClient } from "../../utils/apiClient";
import { Text } from "../Typography";

const StyledDiv = styled("div")``;

const StyledTitle = styled("span")`
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
  color: #191540;
  margin-bottom: 12px;
  display: block;
`;

const StyledInput = styled(Input)<{ $validEmail?: boolean; $invalidEmail?: boolean }>`
  border: 1px solid
    ${(props) =>
      !props.$validEmail && !props.$invalidEmail
        ? `${COLORS.primary.accent}`
        : props.$validEmail
        ? `${COLORS.primary.accent}`
        : "#FF0000"};
  border-radius: 8px;
  height: 56px;
  background: ${(props) => (props.$validEmail ? `${COLORS.primary.grayLighter}` : "transparent")};
`;

const ErrorMessage = styled("span")`
  font-style: italic;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #ff0000;
  margin-top: 8px;
  margin-left: 20px;
  display: flex;
`;

const StyledSaveButton = styled.button`
  width: 100%;
  color: white;
  cursor: pointer;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  background: linear-gradient(90.48deg, ${COLORS.primary.accent} 13.09%, ${COLORS.secondary.purple} 101.13%);
  border-radius: 8px;
  border: 0;
  :disabled {
    cursor: not-allowed;
  }
  display: flex;
  padding: 12px 40px;
  span {
    margin: auto;
  }
`;

const EmailLoginModal = () => {
  const [email, setEmail] = useState<string | undefined>();
  //address is used when the same email is set on more than 1 address, then the address field will be used to select which address will be logged in
  const [address, setAddress] = useState<string | undefined>();
  const [isEmailValid, setIsEmailValid] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const emailRegex = useMemo(() => /^[^\s@]+@[^\s@]+\.[^\s@]+$/, []);

  const validateEmail = useCallback(() => {
    if (!email?.length) {
      setIsEmailValid("Email is required");
      return;
    }
    const isValid = emailRegex.test(email);
    if (!isValid) {
      setIsEmailValid("That doesn’t look like a valid email address");
    } else {
      setIsEmailValid("valid");
    }
  }, [email, emailRegex, setIsEmailValid]);

  const SignInWithEmail = useCallback(async () => {
    setIsLoading(true);
    if (!emailRegex.test(email || "")) {
      notification.error({
        message: "Error",
        description: "Please enter a valid email",
      });
      setIsLoading(false);
      return;
    }
    try {
      const isValid = (
        await apiClient.identify(
          {
            address: address,
            email,
          },
          Date.now(),
        )
      )?.data?.success;
      if (isValid) {
        notification.success({
          message: "Success",
          description: "An email has been sent to your email address",
          duration: 10,
        });
        setIsLoading(false);
      } else {
        notification.error({
          message: "Error",
          description: "Something went wrong... Please try again later",
        });
        setIsLoading(false);
      }
    } catch (error: any) {
      if (typeof error.message?.getReader === "function") {
        const reader = await error.message?.getReader();
        const read = await reader?.read()?.then((response: any) => response?.value);
        const errorMessage = ArrayBuffer.isView(read) ? new TextDecoder().decode(read) : "";
        if (
          errorMessage === "No login email setup for this account." ||
          errorMessage === "Error. User has no login email setup"
        ) {
          notification.error({
            message: "Error",
            description: (
              <Text>
                No login email setup for this account. You can
                <a href="https://boardroom.io/settings/email" rel="noopener" target="_blank">
                  {" "}
                  click here{" "}
                </a>
                to set up your email
              </Text>
            ),
          });
        } else if (errorMessage === "Invalid address provided.") {
          notification.error({
            message: "Error",
            description: <Text>Invalid address provided.</Text>,
          });
        } else {
          notification.error({
            message: "Error",
            description: "Something went wrong... Please try again later",
          });
        }
      } else {
        notification.error({
          message: "Error",
          description: "Something went wrong... Please try again later",
        });
      }
      setIsLoading(false);
    }
  }, [email, emailRegex, address]);

  return (
    <StyledDiv>
      <StyledTitle>Sign in with email</StyledTitle>
      <StyledInput
        type="text"
        onChange={(event) => setAddress(event.target.value)}
        $removeBorder
        value={address}
        placeholder="Wallet address"
        name="address"
      />
      <StyledInput
        type="email"
        onChange={(event) => setEmail(event.target.value)}
        $removeBorder
        value={email}
        style={{ marginTop: "16px" }}
        suffix={
          <StyledSaveButton disabled={isLoading} onClick={SignInWithEmail}>
            {isLoading && <Loader size="xsmall" />}
            <span style={{ marginLeft: isLoading ? "8px" : "0" }}>Sign In</span>
          </StyledSaveButton>
        }
        onBlur={validateEmail}
        $validEmail={isEmailValid === "valid"}
        $invalidEmail={
          isEmailValid === "Email is required" || isEmailValid === "That doesn’t look like a valid email address"
        }
        placeholder="Your Email"
        name="email"
      />
      {isEmailValid === "Email is required" || isEmailValid === "That doesn’t look like a valid email address" ? (
        <ErrorMessage>{isEmailValid}</ErrorMessage>
      ) : null}
    </StyledDiv>
  );
};

export { EmailLoginModal };
