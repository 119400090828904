import Col from "antd/es/col";
import Row from "antd/es/row";
import React, { Suspense, useCallback, useState } from "react";
import styled from "styled-components";
import ContentLoader from "react-content-loader";

import { Footer, MainContainer } from "../components";

import { COLORS } from "../constants/colors";
import { CATEGORIES } from "../constants/categories";

import { Input } from "../components/Input";
import { SearchIcon } from "../components/icons";

import MetaTags from "../components/common/MetaTags";
import { BackgroundForMobile } from "../components/NavBar";
import media from "../media-query";
import { handleColorType as handleCategoryType } from "../components/CategoryBadge/CategoryBadge";
import { LeanFooter } from "../components/Footer/LeanFooter";
import { useWindowDimensions } from "../hooks/useWindowDimensions";

const ProtocolsList = React.lazy(() => import("../components/ProtocolList/ProtocolList"));

const handleColorType = (category?: string) => {
  switch (category) {
    case "Uncategorized":
      return "background: linear-gradient(180deg, #F0F0F0 0%, #FFFFFF 54.71%);";
    case "Protocol":
      return "background: linear-gradient(180deg, #EEF0FF 0%, #FFFFFF 53.9%);";
    case "Product":
      return "background: linear-gradient(180deg, #EFFEFC 0%, #FFFFFF 54.71%);";
    case "Investment":
      return "background: linear-gradient(180deg, #E7FFE3 0%, #FFFFFF 53.9%);";
    case "Grants":
      return "background: linear-gradient(180deg, #D9FFF6 0%, #FFFFFF 53.9%);";
    case "Social":
      return "background: linear-gradient(180deg, #EEFBFF 0%, #FFFFFF 54.71%);";
    case "Collector":
      return "background: linear-gradient(180deg, #EFFEFC 0%, #FFFFFF 54.71%);";
    case "Service":
      return "background: linear-gradient(180deg, #FFF0FD 0%, #FFFFFF 53.9%);";
    case "Media":
      return "background: linear-gradient(180deg, #FFF5F0 0%, #FFFFFF 53.9%);";
    default:
      return "background: linear-gradient(180deg, #EEF0FF 0%, #FFFFFF 53.9%);";
  }
};

export const ProtocolCard = styled.div<{ category?: string }>`
  position: relative;
  padding: 10px 18px;
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  border: 1px solid ${COLORS.primary.grayLighter};
  border-radius: 6px;
  transition: transform ease-in 0.2s;
  &:hover {
    box-shadow: 0px 6px 14px rgba(7, 3, 40, 0.04);
    transform: translateY(-2px);
  }
  ${({ category }) => handleColorType(category)};
`;

const Container = styled.div`
  margin: auto;
  height: calc(100vh - 166px);
  overflow-y: scroll;
  overflow-x: hidden;
  width: fit-content;
  ${media.lessThan("991px")`
    padding: 0px;
    height: auto;
  `}
`;

const SearchInput = styled(Input)`
  width: 100%;
  border-radius: 40px;
  height: 48px;
  margin-top: 20px;

  ${media.greaterThan("767px")`
    display: none;
  `}

  ${media.lessThan("991px")`
    width: 100%;
  `}
`;

const PageMax = styled.div`
  width: 100%;
  min-height: calc(100% - 320px);
  ${media.lessThan("1100px")`
    min-height: calc(100% - 360px);
  `}
`;

const CategoriesWrapper = styled.div`
  display: flex;
  gap: 8px;
  ${media.lessThan("1400px")`
    margin-left: 0;
    margin-top: 12px;
  `}
  ${media.lessThan("1200px")`
    flex-wrap: wrap;
  `}
`;

const CategoryText = styled.div<{ selected?: boolean; category: string }>`
  cursor: pointer;
  font-family: Inter;
  font-style: normal;
  font-weight: ${(props) => (props.selected ? 500 : 400)};
  font-size: 12px;
  line-height: 13px;
  border-radius: 4px;
  height: 24px;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  ${(props) => (props.selected ? "box-shadow: 0px 0px 4px rgba(25, 21, 64, 0.4);" : "")}
  ${(props) =>
    props.selected
      ? handleCategoryType(props.category)
      : "color: #7B7893; background: #fff; border: 1px solid rgba(123, 120, 147, 0.1);"}
  &:hover {
    color: ${COLORS.primary.grayDark};
    ${(props) => handleCategoryType(props.category)}
    background: #fff;
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-top: 28px;
  padding-bottom: 36px;
  ${media.lessThan("1400px")`
    flex-direction: column;
    align-items: start;
    max-height: 88px;
  `}
  ${media.lessThan("991px")`
    margin-left: 0;
    padding: 28px 20px 20px;
    max-height: initial;
  `}
`;

const ClearFilters = styled("div")`
  font-weight: 400;
  font-size: 12px;
  line-height: 13px;
  color: #7b7893;
  padding-left: 24px;
  border-left: 1px solid #f0eff8;
  margin-left: 32px;
  cursor: pointer;
  span {
    display: flex;
    align-items: center;
    height: 24px;
    white-space: nowrap;
    padding: 0 10px;
  }
  :hover {
    color: #4235e1;
  }
  :active,
  :focus {
    span {
      background: #f0eff8;
      border-radius: 4px;
    }
  }
  ${media.lessThan("1200px")`
    margin-left: 16px;
    padding-left: 12px;
  `}
  ${media.lessThan("1100px")`
    margin-left: 0;
    padding-left: 0;
    border-left: none;
  `}
`;

export default () => {
  const { width } = useWindowDimensions();
  const [searchQuery, setSearchQuery] = useState("");
  const [categoriesState, setCategoriesState] = useState<string[]>([]);

  const handleSearchChange = (val: string) => {
    setSearchQuery(val);
  };

  const toggleCategory = useCallback(
    (value: string) => {
      if (categoriesState.includes(value)) {
        const newCategoriesState = [...categoriesState].filter((category) => category !== value);
        setCategoriesState(newCategoriesState);
      } else {
        setCategoriesState((curr) => [...curr, value]);
      }
    },
    [categoriesState],
  );

  const ClearCategoryState = useCallback(() => {
    setCategoriesState([]);
  }, []);

  return (
    <MainContainer $isDashboard>
      <MetaTags
        title="Projects | Boardroom"
        description="Boardroom is a web3 governance management portal helping users make faster, smarter, and more informed decisions"
        imageUrl="https://uploads-ssl.webflow.com/5fea803f5ceaedd1ffd21e65/602447d1a489bc75f4f2478a_Twitter%20Banner%203.png"
      />
      <BackgroundForMobile />
      <Row justify="start" gutter={[48, 48]} style={{ marginLeft: "0", marginRight: "0" }}>
        <Col xl={{ span: 20, offset: 2 }} md={24}>
          <HeaderWrapper>
            <CategoriesWrapper>
              {CATEGORIES.slice(1).map((categoryValue) => (
                <CategoryText
                  key={categoryValue.value}
                  selected={categoriesState.includes(categoryValue.value)}
                  category={categoryValue.value}
                  onClick={() => toggleCategory(categoryValue.value)}
                >
                  {categoryValue.name}
                </CategoryText>
              ))}
              <ClearFilters onClick={ClearCategoryState}>
                <span>Clear Filters</span>
              </ClearFilters>
            </CategoriesWrapper>
          </HeaderWrapper>
          <SearchInput
            value={searchQuery}
            placeholder="Search for Projects"
            onChange={(e) => handleSearchChange(e.currentTarget.value)}
            suffix={<SearchIcon color={COLORS.primary.grayDarkLightest} height={18} width={18} />}
          />
          <Container id="scrollElement">
            <PageMax>
              <Row>
                <Suspense
                  fallback={
                    <>
                      {new Array(16).fill(null).map((_, index) => (
                        <Col
                          xs={{ span: 20, push: 2, pull: 2 }}
                          md={{ span: 12, push: 0, pull: 0 }}
                          xl={{ span: 6, push: 0, pull: 0 }}
                          key={index}
                          style={{ padding: "0" }}
                        >
                          <ProtocolCard style={{ margin: "16px" }}>
                            <ContentLoader
                              speed={2}
                              width="100%"
                              height={250}
                              backgroundColor={COLORS.primary.grayLight}
                              foregroundColor={COLORS.primary.grayLighter}
                            >
                              <circle cx="50%" cy="66" r="36" />
                              <rect x="20%" y="115" rx="5" ry="5" width="60%" height="24" />

                              <rect x="25%" y="170" rx="5" ry="5" width="20%" height="10" />
                              <rect x="25%" y="190" rx="5" ry="5" width="20%" height="10" />

                              <rect x="55%" y="170" rx="5" ry="5" width="20%" height="10" />
                              <rect x="55%" y="190" rx="5" ry="5" width="20%" height="10" />
                            </ContentLoader>
                          </ProtocolCard>
                        </Col>
                      ))}
                    </>
                  }
                >
                  <ProtocolsList searchQuery={searchQuery} categories={categoriesState} />
                </Suspense>
              </Row>
            </PageMax>
            <Row>
              <Col span={24}>{width <= 991 ? <Footer /> : <LeanFooter />}</Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </MainContainer>
  );
};
