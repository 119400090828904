import React, { Suspense, useCallback, useContext, useMemo, useState } from "react";
import styled from "styled-components";
import ContentLoader from "react-content-loader";
import { useHover } from "use-events";
import { Link, useLocation } from "react-router-dom";
import CopyToClipboard from "react-copy-to-clipboard";

import { toShortAddress, formatEns } from "../../utils";
import { RowOfTwo } from "../Shared.styles";
import { COLORS } from "../../constants/colors";
import { ExternalLinkIcon } from "../icons";
import media from "../../media-query";
import CollapsibleMobileInfoCard from "../common/CollapsibleMobileInfoCard";
import { useHasDelegation } from "../../hooks/useHasDelegation";
import SetupDelegation from "../Voting/SetupDelegation";
import { ExpandCollapseArrowThin } from "../icons";
import { useUserProposals } from "../../hooks/useUserProposals";
import Avatar from "../Avatar/Avatar";
import { useProtocols } from "../../hooks/useProtocols";
import { useGetEns } from "../../hooks/useEns";
import { protocols } from "../../constants/protocols";
import { useMixpanel } from "../../hooks";
import { CurrentAccountContext } from "../../reducers/CurrentAccount";
import { Popover } from "../Popover";
import { StyledCopyText, StyledCopyIcon, StyledCopiedText, StyledCopiedIcon } from "../DelegateMessageBoard/styles";

interface Props {
  activeProtocol?: string;
  address?: string | undefined;
  ens?: string;
  setActiveProtocol: (arg0: string) => void;
  website?: string;
  delegateForProtocols?: string[] | Map<string, any>;
  delegateToAddresses?: any;
  teamWallets?: string[];
}

const StyledItem = styled("div")`
  margin-bottom: 5px;
  text-align: center;
  margin-left: 5px;
  svg {
    margin-left: 5px;
  }
`;

const StyledInfoCard = styled(CollapsibleMobileInfoCard)`
  && {
    .ant-card-body {
      min-height: initial;
    }
    .ant-card-header {
      font-size: 12px;
    }
  }
  ${media.lessThan("991px")`
    .ant-card-head-wrapper {
      flex-direction: inherit;
    }
  `}
`;

const StyledDelegationWrapper = styled.div`
  width: 100%;
  background: #fff;
  color: white;
  height: 49px;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  font-weight: 600;
  font-size: 14px;
`;

const ResultsExpandCollapseIcon = styled(ExpandCollapseArrowThin)<{ $isCollapsed: boolean }>`
  margin-left: auto;
  transform: rotate(${({ $isCollapsed }) => ($isCollapsed ? "0deg" : "180deg")});
  transition: transform 0.2s;
  cursor: pointer;
`;

const StyledMemberAddress = styled(Link)`
  display: block;
`;

const StyledPadding = styled.div`
  padding: 10px 24px;
  ${media.lessThan("640px")`
    padding: 10px 0 0;
    }
  `}
`;

const StyledDiv = styled.div`
  background: rgba(240, 239, 248, 0.5);
  border-radius: 4px;
  width: 100%;
  padding: 8px 12px;
  margin-bottom: 6px;
  margin-top: 8px;
  display: inline-flex;
  flex-direction: column;
  align-items: end;
`;

const DelegateItem = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  width: 100%;
  &:last-child {
    margin-bottom: 0;
  }
`;

const StyledText = styled.div<{ color: string }>`
  margin: auto 0;
  font-size: 14px;
  line-height: 16px;
  color: ${({ color }) => color};
`;

const MemberAddressWithEns = ({ address }: { address: string }) => {
  const ens = useGetEns(address);
  return (
    <StyledMemberAddress to={`/voter/${address}`} target="_blank" key={address} rel="noopener noreferrer">
      {formatEns(ens) || toShortAddress(address)}
    </StyledMemberAddress>
  );
};

const GroupContent = ({
  group,
  title,
  isProtocolGroup = false,
  delegateForByAddress,
}: {
  group: string[];
  title: string;
  isProtocolGroup?: boolean;
  delegateForByAddress?: Map<string, any>;
}) => {
  const { pathname } = useLocation();
  const { protocols } = useProtocols();
  const [isResultsExpandButtonHovered, resultsExpandButtonBind] = useHover();

  const [isGroupCollapsed, setToggleGroupContent] = useState(true);
  const [copied, setCopied] = useState(false);

  const toggleWalletContent = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    setToggleGroupContent(!isGroupCollapsed);
  };
  const onCopy = useCallback(() => {
    setCopied(true);
  }, []);

  const handleVisibleChange = useCallback((visible: boolean) => {
    if (!visible) {
      setTimeout(() => {
        setCopied(false);
      }, 100);
    }
  }, []);

  return (
    <>
      <a href="#" onClick={toggleWalletContent} style={{ float: "right" }}>
        {group.length} {title}
        <ResultsExpandCollapseIcon
          $isCollapsed={isGroupCollapsed}
          width={12}
          height={12}
          color={isResultsExpandButtonHovered ? COLORS.primary.accent : COLORS.primary.accentDarker}
          {...resultsExpandButtonBind}
        />
      </a>
      {!isProtocolGroup && !isGroupCollapsed && (
        <>
          <StyledDiv>
            {group.map((address) => (
              <MemberAddressWithEns key={address} address={address} />
            ))}
          </StyledDiv>
        </>
      )}

      {isProtocolGroup && !isGroupCollapsed && (
        <>
          <StyledDiv>
            {group.map((protocol) => {
              const entries = delegateForByAddress?.entries();
              let address = "";
              for (const [key, value] of Array.from(entries || [])) {
                if (value.includes(protocol)) {
                  address = key;
                }
              }
              return (
                <Popover
                  placement="top"
                  zIndex={1000}
                  key={protocol}
                  trigger="hover"
                  onVisibleChange={handleVisibleChange}
                  content={
                    <div style={{ width: "12rem", display: "flex", alignItems: "center", justifyContent: "center" }}>
                      {!copied ? (
                        <CopyToClipboard
                          text={`https://boardroom.io${pathname}?protocol=${protocol}&delegationModal=true&addressToDelegate=${address}`}
                          onCopy={onCopy}
                        >
                          <StyledCopyText style={{ marginBottom: 0 }}>
                            <StyledCopyIcon />
                            Copy 1-click delegation link
                          </StyledCopyText>
                        </CopyToClipboard>
                      ) : (
                        <StyledCopiedText style={{ marginBottom: 0 }}>
                          <StyledCopiedIcon />
                          Link Copied!
                        </StyledCopiedText>
                      )}
                    </div>
                  }
                >
                  <DelegateItem>
                    <Avatar
                      src={protocols[protocol]?.icons?.[(protocols[protocol]?.icons?.length || 0) - 1]?.url}
                      size="xxsmall"
                    />
                    <StyledText color={COLORS.primary.grayDarkLightest}>{protocols[protocol]?.name}</StyledText>
                  </DelegateItem>
                </Popover>
              );
            })}
          </StyledDiv>
        </>
      )}
    </>
  );
};

function ProfileInfoCard(props: Props) {
  const { activeProtocol, address, website, ens, delegateForProtocols, delegateToAddresses, teamWallets } = props;
  const hasDelegation = useHasDelegation(activeProtocol);
  const isGroup = teamWallets && teamWallets.length > 1;
  const { trackClickProfileWebsite } = useMixpanel();
  const { account } = useContext(CurrentAccountContext);

  const proposers = useMemo(() => {
    if (teamWallets) {
      return teamWallets.join(",");
    }
    return address;
  }, [address, teamWallets]);

  const { proposals } = useUserProposals({ proposers: proposers });
  const filteredProposals = useMemo(
    () => proposals?.filter((proposal) => protocols[proposal.protocol]?.isEnabled),
    [proposals],
  );

  const websiteUrl = useMemo(() => {
    if (!website) {
      return website;
    }
    if (!website?.startsWith("http")) {
      return `http://${website}`;
    }

    return website;
  }, [website]);

  const style: React.CSSProperties = {
    maxWidth: "79%",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  };

  const onWebsiteClick = useCallback(() => {
    trackClickProfileWebsite({
      userId: account,
    });
  }, [account, trackClickProfileWebsite]);

  const allProtocols = useMemo(() => {
    if (delegateForProtocols instanceof Map) {
      const protocols = Array.from(delegateForProtocols?.values() || []).flat();
      return Array.from(new Set(protocols));
    }
  }, [delegateForProtocols]);

  return (
    <StyledInfoCard title="Information" $noPaddingBody={true}>
      <StyledPadding>
        <StyledItem>
          {!!ens && (
            <RowOfTwo
              styleRightColumn={style}
              content={
                <a href={`https://app.ens.domains/name/${ens}`} rel="noopener noreferrer" target="_blank">
                  {formatEns(ens)}
                  <ExternalLinkIcon height="12" width="12" color={COLORS.primary.accent} />
                </a>
              }
              title="ENS"
            />
          )}
        </StyledItem>
        {address && (
          <StyledItem>
            <RowOfTwo
              content={
                <a
                  href={`https://etherscan.io/address/${address}`}
                  target="_blank"
                  key={address}
                  rel="noopener noreferrer"
                >
                  {toShortAddress(address)}
                  <ExternalLinkIcon height="12" width="12" color={COLORS.primary.accent} />
                </a>
              }
              title={"Address"}
            />
          </StyledItem>
        )}
        {isGroup && (
          <StyledItem>
            <RowOfTwo
              content={<GroupContent title="Wallets" group={teamWallets || []} />}
              title={"Bundle Wallets"}
              styleRightColumn={{ width: "100%", marginTop: "-22px" }}
            />
          </StyledItem>
        )}
        {!!websiteUrl && (
          <StyledItem>
            <RowOfTwo
              styleRightColumn={style}
              content={
                <a onClick={onWebsiteClick} href={websiteUrl} rel="noopener noreferrer" target="_blank">
                  {websiteUrl}
                  <ExternalLinkIcon height="12" width="12" color={COLORS.primary.accent} />
                </a>
              }
              title="Website"
            />
          </StyledItem>
        )}
        {!(delegateForProtocols instanceof Map) && !!delegateForProtocols?.length && (
          <>
            <StyledItem>
              <RowOfTwo
                content={
                  <GroupContent
                    isProtocolGroup
                    title={delegateForProtocols?.length > 1 ? "Protocols" : "Protocol"}
                    group={delegateForProtocols || []}
                  />
                }
                title="Delegate For"
                styleRightColumn={{ width: "100%", marginTop: "-22px" }}
              />
            </StyledItem>
          </>
        )}
        {!!(delegateForProtocols instanceof Map) && !!allProtocols?.length && (
          <>
            <StyledItem>
              <RowOfTwo
                content={
                  <GroupContent
                    isProtocolGroup
                    title={allProtocols?.length > 1 ? "Protocols" : "Protocol"}
                    group={allProtocols || []}
                    delegateForByAddress={delegateForProtocols}
                  />
                }
                title="Delegate For"
                styleRightColumn={{ width: "100%", marginTop: "-22px" }}
              />
            </StyledItem>
          </>
        )}
        <StyledItem>
          <RowOfTwo content={<span>{filteredProposals?.length || 0} Proposals</span>} title="Proposal Created" />
        </StyledItem>
        {!!delegateToAddresses?.length && (
          <StyledItem>
            <RowOfTwo
              content={
                <GroupContent
                  title={delegateToAddresses?.length > 1 ? "Delegates" : "Delegate"}
                  group={delegateToAddresses || ["Delegating to", "Delegating to", "Delegating to"]}
                />
              }
              title={"Delegating to"}
              styleRightColumn={{ width: "100%", marginTop: "-22px" }}
            />
          </StyledItem>
        )}
      </StyledPadding>
      {hasDelegation && activeProtocol && address && (
        <StyledDelegationWrapper>
          <SetupDelegation
            protocolName={activeProtocol}
            address={address}
            triggerText="Manage Delegation"
            zIndex={19}
          />
        </StyledDelegationWrapper>
      )}
    </StyledInfoCard>
  );
}

function ProfileInfoCardWrapper(props: Props) {
  return (
    <Suspense
      fallback={
        <CollapsibleMobileInfoCard title="Profile" $noPaddingBody={true}>
          <ContentLoader
            speed={2}
            width="100%"
            height={111}
            backgroundColor={COLORS.primary.grayLight}
            foregroundColor={COLORS.primary.grayLighter}
          >
            <rect x="286" y="189" rx="0" ry="0" width="0" height="1" />
            <rect x="13" y="36" rx="0" ry="0" width="94" height="11" />
            <rect x="198" y="32" rx="0" ry="0" width="136" height="9" />
            <rect x="13" y="77" rx="0" ry="0" width="114" height="9" />
            <rect x="242" y="73" rx="0" ry="0" width="94" height="11" />
            <rect x="15" y="117" rx="0" ry="0" width="94" height="11" />
            <rect x="202" y="117" rx="0" ry="0" width="136" height="9" />
          </ContentLoader>
        </CollapsibleMobileInfoCard>
      }
    >
      <ProfileInfoCard {...props} />
    </Suspense>
  );
}

export default React.memo(ProfileInfoCardWrapper);
