import React, { useContext, useEffect } from "react";
import Col from "antd/es/col";
import Row from "antd/es/row";
import styled from "styled-components";

import { TrackPageView } from "../components/TrackPageView";
import { CurrentAccountContext } from "../reducers/CurrentAccount";
import MetaTags from "../components/common/MetaTags";
import { MainContainer } from "../components";
import mediaQuery from "../media-query";
import { useSiwe } from "../hooks/useSiwe";
import { pageHeaderHeight } from "../constants/styles";
import { useGetApiSubscriptionDetails } from "../hooks/useGetApiMembership";
import { CurrentUuidContext } from "../reducers/CurrentUuid";
import { CurrentSubscriptionDataContext } from "../reducers/SubscriptionData";
import { PaymentGate } from "../components/PaymentGate";
import { COLORS } from "../constants/colors";
import ManageSubscription from "../components/PaymentGate/ManageSubscription";
import { CalendarSuspense } from "./DashboardPages/Calendar";

export const Container = styled.div`
  margin: auto;
  height: calc(100vh - ${pageHeaderHeight});
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;
  background-color: #fafafa;
`;

export const PaddingContainer = styled.div`
  padding-top: 36px;
  ${mediaQuery.lessThan("991px")`
    padding: 0;
  `}
`;

export const PageMax = styled.div`
  width: 100%;
  min-height: calc(100% - 320px);
  ${mediaQuery.lessThan("1100px")`
    min-height: calc(100% - 360px);
  `}
`;

export const HeaderText = styled.h1`
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #191540;
  margin-right: 24px;
  margin-bottom: 0;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  margin-bottom: 24px;
`;

export const StyledDiv = styled("div")`
  padding: 20px;
  height: 100%;
  overflow: auto;
  max-height: 65vh;
  min-height: 40vh;
  overflow-x: hidden;
`;

export const StyledTitle = styled("span")`
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
  color: #191540;
  margin-bottom: 12px;
  display: block;
`;

const BillingContent = () => {
  const { account } = useContext(CurrentAccountContext);
  const { uuid } = useContext(CurrentUuidContext);
  const { data: apiSubscriptionDetails, isLoading: isLoadingAPISubDetails } = useGetApiSubscriptionDetails(
    account,
    uuid,
  );

  const { dispatchSubscriptionData } = useContext(CurrentSubscriptionDataContext);

  useEffect(() => {
    if (apiSubscriptionDetails) {
      dispatchSubscriptionData({
        type: "SAVE_SUBSCRIPTION_DATA",
        data: apiSubscriptionDetails,
      });
    }
  }, [dispatchSubscriptionData, apiSubscriptionDetails]);

  if (isLoadingAPISubDetails) {
    return (
      <div style={{ marginLeft: "-88px", width: "95%" }}>
        <CalendarSuspense />
      </div>
    );
  }
  if (!apiSubscriptionDetails?.status || apiSubscriptionDetails?.status === "cancelled") {
    return (
      <>
        <Col
          style={{
            background: "white",
            padding: 0,
            borderRadius: "8px",
            width: "100%",
            minHeight: "60vh",
            border: `1px solid ${COLORS.primary.grayLighter}`,
          }}
          xl={18}
          xxl={16}
        >
          <PaymentGate />
        </Col>
      </>
    );
  }

  return (
    <>
      <Col
        style={{
          background: "white",
          padding: 0,
          borderRadius: "8px",
          width: "100%",
          minHeight: "60vh",
          border: `1px solid ${COLORS.primary.grayLighter}`,
        }}
        xl={18}
        xxl={16}
      >
        <StyledDiv>
          <StyledTitle style={{ marginBottom: "-12px" }}>Manage your subscription</StyledTitle>
          <ManageSubscription />
        </StyledDiv>
      </Col>
    </>
  );
};

const Billing = () => {
  useSiwe({});

  return (
    <>
      <TrackPageView name="Billing" />
      <MetaTags
        title="Billing | Boardroom"
        description="Boardroom is a web3 governance management portal helping users make faster, smarter, and more informed decisions"
        imageUrl="https://uploads-ssl.webflow.com/5fea803f5ceaedd1ffd21e65/602447d1a489bc75f4f2478a_Twitter%20Banner%203.png"
      />
      <MainContainer $isDashboard>
        <Container>
          <Row justify="start" gutter={[48, 48]} style={{ marginLeft: "0", marginRight: "0" }}>
            <Col lg={{ span: 20, offset: 4 }} xs={24}>
              <PaddingContainer>
                <PageMax>
                  <Row>
                    <Col style={{ marginBottom: "40px" }} span={24}>
                      <HeaderWrapper>
                        <HeaderText>Billing</HeaderText>
                      </HeaderWrapper>

                      <BillingContent />
                    </Col>
                  </Row>
                </PageMax>
              </PaddingContainer>
            </Col>
          </Row>
        </Container>
      </MainContainer>
    </>
  );
};

export default React.memo(Billing);
