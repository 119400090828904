import React, { Suspense, useCallback, useContext, useEffect, useRef, useState } from "react";
import FormItem from "antd/es/form/FormItem";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import Row from "antd/es/row";
import Col from "antd/es/col";
import AntdForm from "antd/es/form";
import { Loader } from "../Loader";
import message from "antd/es/message";

import { Form, ProposalInput, ProposalChoices, DateTimePicker, SubmitButton } from "../../components";
import { useCurrentBlock, useMixpanel } from "../../hooks";
import { useCurrentProtocol } from "../../hooks/useCurrentProtocol";
import { useCreateProposal } from "../../hooks/useCreateProposal";
import { CurrentWeb3Context } from "../../reducers/CurrentWeb3Provider";
import { CurrentAccountContext } from "../../reducers/CurrentAccount";
import { COLORS } from "../../constants/colors";
import { ContentTitle, ContentWrapper } from "../PostModal/PostModal";
import { StyledRichMarkdownEditor } from "./CreateOnChainProposalForm";

const Container = styled.div`
  margin-top: 10px;
`;

const Actions = styled.div`
  margin: 10px 0;
`;

const InputLabel = styled("span")`
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: ${COLORS.primary.grayDark};
  margin: 32px 0 12px;
  display: block;
`;

export const ReviewInputWrapper = styled.div`
  display: flex;
  align-items: center;
  span {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #191540;
    margin-left: 16px;
  }
`;

export const CreateProposalFormContent = ({ protocolCname }: { protocolCname: string }) => {
  const [editorContent, setEditorContent] = useState<string | undefined>("");
  const [isEditorEverFocused, setIsEditorEverFocused] = useState(false);
  const [formValues, setFormValues] = useState({} as any);

  const history = useHistory();
  const { account } = useContext(CurrentAccountContext);
  const { web3Instance } = useContext(CurrentWeb3Context);
  const [form] = AntdForm.useForm();
  const [createProposal, { data, loading, error }] = useCreateProposal(protocolCname);
  const { trackCreateProposal } = useMixpanel();
  const currentBlockNumber = useCurrentBlock();

  const onSubmit = useCallback(
    ({ name, choices, startDate, startTime, endDate, endTime }: any) => {
      if (account && web3Instance && editorContent) {
        createProposal({
          title: name,
          content: editorContent,
          choices,
          startTime: { timestamp: startDate.hours(startTime.hours()).minutes(startTime.minutes()).unix() },
          endTime: { timestamp: endDate.hours(endTime.hours()).minutes(endTime.minutes()).unix() },
          blockNumber: currentBlockNumber,
        });
      }
    },
    [account, createProposal, web3Instance, editorContent, currentBlockNumber],
  );

  useEffect(() => {
    if (data) {
      history.push("../proposals");
      message.success("Proposal created");

      trackCreateProposal({
        protocol: protocolCname || "",
        proposalId: data,
        userId: `${account}`,
      });
    }
  }, [account, data, history, protocolCname, trackCreateProposal]);

  useEffect(() => {
    if (error) {
      message.error("Proposal creation failed");
    }
  }, [error, history]);

  return (
    <Form
      name="create-proposal-form"
      form={form}
      onSubmit={onSubmit}
      onValuesChange={(_, values) => setFormValues(values)}
    >
      <ContentWrapper style={{ marginBottom: "20px" }}>
        <ContentTitle>2. PROPOSAL BASICS</ContentTitle>
        <InputLabel style={{ marginTop: "20px" }}>Proposal Title</InputLabel>
        <ProposalInput name="name" label="Proposal Title" isRequired />
        <InputLabel>Proposal Description</InputLabel>
        <StyledRichMarkdownEditor
          onChange={(value) => setEditorContent(value)}
          $isErrored={
            isEditorEverFocused &&
            !(Array.from(editorContent?.matchAll(/[a-zA-Z0-9áàâãéèêíïóôõöúçñ]/g) || []).length > 0)
          }
          onFocus={() => setIsEditorEverFocused(true)}
          placeholder="Your proposal description"
        />
      </ContentWrapper>
      <ContentWrapper $isDeactivated={!formValues?.name || !editorContent} style={{ marginBottom: "20px" }}>
        <ContentTitle>3. ACTIONS</ContentTitle>
        <InputLabel style={{ marginTop: "20px" }}>Choices</InputLabel>
        <ProposalChoices />
        <Actions>
          <InputLabel style={{ marginTop: 0 }}>Start and End Dates</InputLabel>
          <Row justify="space-between">
            <Col span={11}>
              <DateTimePicker name="start" placeholder="Select start date" isRequired form={form} />
            </Col>
            <Col span={11}>
              <DateTimePicker name="end" placeholder="Select end date" isRequired form={form} />
            </Col>
          </Row>
        </Actions>
      </ContentWrapper>
      <ContentWrapper
        $isDeactivated={
          !formValues?.name ||
          !editorContent ||
          !formValues.startTime ||
          !formValues.endTime ||
          !formValues.startDate ||
          !formValues.endDate ||
          !formValues.choices?.[0] ||
          !formValues.choices?.[1]
        }
        style={{ marginBottom: "20px" }}
      >
        <ContentTitle>4. REVIEW</ContentTitle>
        <InputLabel style={{ marginTop: "20px" }}>Confirm Proposal Details</InputLabel>
        <ReviewInputWrapper>
          <FormItem style={{ marginBottom: 0 }} rules={[{ required: true, message: "" }]}>
            <input name="reviewed" required type="checkbox" />
          </FormItem>
          <span>I have reviewed the contents of my proposal and am ready to publish.</span>
        </ReviewInputWrapper>
      </ContentWrapper>
      <SubmitButton editorContent={editorContent} form={form} loading={loading} block>
        Publish Proposal
      </SubmitButton>
    </Form>
  );
};

const CreateProposalForm = () => {
  const [editorContent, setEditorContent] = useState<string | undefined>("");
  const [isEditorEverFocused, setIsEditorEverFocused] = useState(false);

  const protocolInfo = useCurrentProtocol();
  const history = useHistory();
  const { account } = useContext(CurrentAccountContext);
  const { web3Instance } = useContext(CurrentWeb3Context);
  const [form] = AntdForm.useForm();
  const containerElement = useRef<HTMLDivElement>(null);
  const [createProposal, { data, loading, error }] = useCreateProposal(protocolInfo?.cname);
  const { trackCreateProposal } = useMixpanel();
  const currentBlockNumber = useCurrentBlock();

  const onSubmit = useCallback(
    ({ name, choices, startDate, startTime, endDate, endTime }: any) => {
      if (account && web3Instance && editorContent) {
        createProposal({
          title: name,
          content: editorContent,
          choices,
          startTime: { timestamp: startDate.hours(startTime.hours()).minutes(startTime.minutes()).unix() },
          endTime: { timestamp: endDate.hours(endTime.hours()).minutes(endTime.minutes()).unix() },
          blockNumber: currentBlockNumber,
        });
      }
    },
    [account, createProposal, web3Instance, editorContent, currentBlockNumber],
  );

  useEffect(() => {
    if (data) {
      history.push("../proposals");
      message.success("Proposal created");

      trackCreateProposal({
        protocol: protocolInfo?.cname || "",
        proposalId: data,
        userId: `${account}`,
      });
    }
  }, [account, data, history, protocolInfo, trackCreateProposal]);

  useEffect(() => {
    if (error) {
      message.error("Proposal creation failed");
    }
  }, [error, history]);

  return (
    <Container ref={containerElement}>
      <Form name="create-proposal-form" form={form} onSubmit={onSubmit}>
        <Row>
          <Col span={24}>
            <InputLabel>Proposal Title</InputLabel>
            <ProposalInput addMarginBottom name="name" label="Proposal Title" isRequired />
            <InputLabel>Proposal Description</InputLabel>
            <StyledRichMarkdownEditor
              onChange={(value) => setEditorContent(value)}
              $isErrored={
                isEditorEverFocused &&
                !(Array.from(editorContent?.matchAll(/[a-zA-Z0-9áàâãéèêíïóôõöúçñ]/g) || []).length > 0)
              }
              onFocus={() => setIsEditorEverFocused(true)}
              placeholder="Your proposal description"
            />
            <InputLabel>Choices</InputLabel>
            <ProposalChoices />
            <Actions>
              <Row justify="space-between">
                <Col span={11}>
                  <DateTimePicker name="start" placeholder="Select start date" isRequired form={form} />
                </Col>
                <Col span={11}>
                  <DateTimePicker name="end" placeholder="Select end date" isRequired form={form} />
                </Col>
              </Row>

              <SubmitButton editorContent={editorContent} form={form} loading={loading} block>
                Publish Proposal
              </SubmitButton>
            </Actions>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export default () => (
  <Suspense fallback={<Loader />}>
    <CreateProposalForm />
  </Suspense>
);
