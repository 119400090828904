import * as React from "react";

const SvgThreeDotMenuIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 4 21" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" {...props}>
    <circle cx={2} cy={2.103} r={2} fill="currentColor" />
    <circle cx={2} cy={10.103} r={2} fill="currentColor" />
    <circle cx={2} cy={18.103} r={2} fill="currentColor" />
  </svg>
);

export default SvgThreeDotMenuIcon;
