import React, { useCallback, useContext, useMemo, useState } from "react";
import styled from "styled-components";
import Tooltip from "antd/es/tooltip";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useCurrentWidth } from "react-socks";
import { GetVoterResponse } from "@boardroom/boardroom-api";
import { getAddress } from "@ethersproject/address";

import { COLORS } from "../../constants/colors";
import { Header } from "../Typography";
import media from "../../media-query";
import toShortAddress from "../../utils/toShortAddress";
import { VOTER_PROFILE_SCROLL_SHRINK } from "../../constants/general";
import { CopyIcon, TwitterIcon, Link2Icon } from "../../components/icons";
import { formatEns } from "../../utils";
import Blockie from "../Blockie";
import { Popover } from "../Popover";
import { ProtocolIcon } from "../ProtocolIcon/ProtocolIcon";
import { protocols } from "../../constants/protocols";
import { useGetEns, useGetEnsAvatar } from "../../hooks/useEns";
import { CurrentAccountContext } from "../../reducers/CurrentAccount";
import { useMixpanel } from "../../hooks";
import { isAddress } from "web3-utils";

interface Props {
  address?: string;
  ens?: string;
  scrolledHeight: number;
  ensAvatar?: string;
  profileName?: string;
  teamWallets?: string[];
  votesByAddress?: Record<string, GetVoterResponse["data"]>;
  twitter?: string;
  website?: string;
  username?: string;
}

const FlexItem = styled.div<{ marginBottom?: boolean; cursor?: boolean }>`
  display: flex;
  margin-bottom: ${({ marginBottom }) => (marginBottom ? "8px" : 0)};
  cursor: ${({ cursor }) => (cursor ? "pointer" : "")};
  ${media.lessThan("991px")`
    display: inline-block;
    margin-right: 15px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  `}
`;

const ProfileName = styled(Header)<{ $lengthBiggerThan20?: boolean; $lengthBiggerThan200?: boolean }>`
  margin-top: 34px;
  font-size: ${({ $lengthBiggerThan20 }) => ($lengthBiggerThan20 ? "32px" : "36px")};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 44px;
  width: ${({ $lengthBiggerThan200 }) => ($lengthBiggerThan200 ? "100%" : "max-content")};
  ${media.lessThan("991px")`
    font-size: 20px;
    display: contents;
  `}
`;

const StyledContentWrapper = styled.div`
  margin-bottom: 16px;
  position: relative;
  ${media.lessThan("991px")`
    margin-bottom: 10px; 
  `}
`;

const StyledCopyIcon = styled(CopyIcon)`
  width: 20px;
  height: 20px;
  margin: 40px 0 auto 12px;
  color: ${COLORS.primary.accent};
  cursor: pointer;
  ${media.lessThan("991px")`
    margin-top: 0px;
  `}
`;

const StyledDiv = styled.div`
  position: absolute;
  left: 440px;
  top: 80px;
  &:before {
    height: 40px;
    display: block;
    position: absolute;
    width: 42px;
    border-left: 1px solid ${COLORS.primary.grayLighter};
    content: "";
  }
  ${media.lessThan("991px")`
    position: relative;
    left: 0px;
    top: 6px;
    &:before {
      display: none;
    }  
  `}
`;

const StyledContainer = styled.div`
  padding-left: 20px;
  ${media.lessThan("991px")`
    padding-left: 0px;
  `}
`;

const StyledText = styled.span`
  font-size: 14px;
  line-height: 16px;
  color: ${COLORS.primary.grayDarkLightest};
  margin: auto 0 auto 10px;
  display: inline-block;
`;

const StyledAvatarsWrapper = styled.div<{ $isOrcaPod?: boolean }>`
  padding: 10px 16px;
  margin-top: 28px;
  margin-left: 10px;
  box-shadow: 0px 1px 6px 4px rgb(0 0 0 / 5%);
  position: relative;
  border-radius: 40px;
  display: flex;
  align-items: center;
  ${({ $isOrcaPod }) => $isOrcaPod && "border: 1px solid #791FFF;"}
  > span {
    margin-right: -3px;
    float: right;
  }
`;

const StyledCursorPointer = styled.span`
  cursor: pointer;
`;

const StyledPopoverWrapper = styled.div`
  width: 240px;
  text-align: center;
`;

const StyledProfileBanner = styled.div`
  height: 72px;
  background: url(${process.env.PUBLIC_URL}/assets/ProfileBanner${Math.ceil(Math.random() * 5)}.png);
  background-size: cover;
  margin-bottom: -36px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;

const StyledPopOverAddressWrapper = styled.a`
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: ${COLORS.primary.accent};
  margin-top: 4px;
  display: block;
  width: 13rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0 auto;
`;

const StyledPopOverProjectsInfo = styled.span`
  font-size: 14px;
  line-height: 22px;
  color: ${COLORS.primary.grayDarkLightest};
  span {
    font-weight: 600;
  }
`;

const StyledProtocolIconsWrapper = styled.div`
  max-width: 192px;
  margin: auto;
`;

const StyledProtocolIcon = styled(ProtocolIcon)`
  margin-right: 4px;
  margin-left: 4px;
  margin-top: 8px;
`;

const StyledNumberOfVotes = styled.div`
  font-size: 14px;
  line-height: 24px;
  color: ${COLORS.primary.grayDark};
  margin-top: 4px;
`;

const StyledVoteInfo = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  color: ${COLORS.primary.grayDarkLightest};
  margin-top: 16px;
`;

const MoreConnectedAddressesText = styled.span`
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  color: #4235e1;
  margin-left: 4px;
  cursor: pointer;
`;

const StyledImg = styled.img<{ size: string }>`
  height: ${({ size }) => (size === "xsmall" ? "20px" : "72px")};
  width: ${({ size }) => (size === "xsmall" ? "20px" : "72px")};
  border-radius: 50%;
  border: ${({ size }) => (size === "xsmall" ? "2px solid white" : "6px solid white")};
`;

const AvatarImg = ({ address, size }: { address: string; size: "xsmall" | "xlarge" }) => {
  const ensAvatar = useGetEnsAvatar(address);
  const [showImage, setShowImage] = useState(true);

  const addDefaultSrc = () => {
    setShowImage(false);
  };
  if (ensAvatar && showImage) {
    return <StyledImg src={ensAvatar} onError={addDefaultSrc} size={size} />;
  }
  return (
    <Blockie
      key={address}
      seed={address}
      dimension={size === "xsmall" ? 20 : 72}
      border={size === "xsmall" ? "2px solid white" : "6px solid white"}
    />
  );
};

const ActiveProtocols = ({
  address,
  votesByAddress,
}: {
  address: string;
  votesByAddress?: Record<string, GetVoterResponse["data"]>;
}) => {
  return (
    <StyledProtocolIconsWrapper>
      {votesByAddress &&
        votesByAddress[isAddress(address) ? getAddress(address) : address].protocols.map((votes) => {
          return <StyledProtocolIcon size="xxsmall" protocol={protocols[votes.protocol]} key={votes.protocol} />;
        })}
    </StyledProtocolIconsWrapper>
  );
};

const VoterPopoverContent = ({
  address,
  votesByTheAddress,
  votesByAddress,
}: {
  address: string;
  votesByTheAddress?: GetVoterResponse["data"];
  votesByAddress?: Record<string, GetVoterResponse["data"]>;
}) => {
  const ens = useGetEns(address);

  return (
    <StyledPopoverWrapper>
      <StyledProfileBanner />
      <AvatarImg address={address} size="xlarge" />
      <StyledPopOverAddressWrapper href={`/voter/${address}`} target="_blank">
        {formatEns(ens) || toShortAddress(address)}
      </StyledPopOverAddressWrapper>
      <StyledPopOverProjectsInfo>
        <span>{votesByTheAddress && votesByTheAddress.protocols.length}</span> Projects
      </StyledPopOverProjectsInfo>
      {<ActiveProtocols address={address} votesByAddress={votesByAddress} />}
      <StyledVoteInfo>Votes</StyledVoteInfo>
      <StyledNumberOfVotes>{votesByTheAddress && votesByTheAddress.totalVotesCast}</StyledNumberOfVotes>
    </StyledPopoverWrapper>
  );
};

function ProfileHeader(props: Props) {
  const { address, ens, scrolledHeight, profileName, teamWallets, votesByAddress, twitter, website, username } = props;
  const isMobile = useCurrentWidth() < 991;
  const { account } = useContext(CurrentAccountContext);
  const { trackClickProfileWebsite, trackClickProfileTwitter, trackClickCopyProfileAddress } = useMixpanel();

  const [copied, setCopied] = useState(false);
  const connectedAddresses = useMemo(() => {
    return teamWallets?.filter((associatedAddress) => associatedAddress !== address);
  }, [teamWallets, address]);

  const twitterHandle = twitter?.startsWith("http") ? twitter.slice(20) : twitter;
  const twitterLink = twitter?.startsWith("http") ? twitter : `https://twitter.com/${twitter}`;
  const parsedWebsite = website?.startsWith("http") ? website : `http://${website}`;

  const imageMarginTop = isMobile ? "0" : 22 - (scrolledHeight / VOTER_PROFILE_SCROLL_SHRINK) * 15;

  const handleVisibleChange = useCallback((visible: boolean) => {
    if (!visible) {
      setTimeout(() => {
        setCopied(false);
      }, 100);
    }
  }, []);
  const onCopy = useCallback(() => {
    setCopied(true);
    trackClickCopyProfileAddress({
      userId: account,
    });
  }, [setCopied, account, trackClickCopyProfileAddress]);
  const profileNameValue =
    profileName || username || (ens !== "" && !!ens && formatEns(ens)) || toShortAddress(address);

  const onWebsiteClick = useCallback(() => {
    trackClickProfileWebsite({
      userId: account,
    });
  }, [account, trackClickProfileWebsite]);

  const onTwitterClick = useCallback(() => {
    trackClickProfileTwitter({
      userId: account,
    });
  }, [account, trackClickProfileTwitter]);

  return (
    <StyledContentWrapper>
      <FlexItem>
        <Tooltip title={address}>
          <ProfileName
            $lengthBiggerThan20={(profileNameValue?.length || 0) >= 20}
            $lengthBiggerThan200={(profileNameValue?.length || 0) >= 200}
            level={3}
            style={{ marginTop: imageMarginTop }}
          >
            {profileNameValue}
          </ProfileName>
        </Tooltip>
        {address && (
          <CopyToClipboard text={address} onCopy={onCopy}>
            <Tooltip title={copied ? "copied" : "copy"} onVisibleChange={handleVisibleChange}>
              <StyledCopyIcon />
            </Tooltip>
          </CopyToClipboard>
        )}
        <>
          {connectedAddresses && !!connectedAddresses.length && !isMobile && (
            <StyledAvatarsWrapper>
              {connectedAddresses?.slice(0, 5).map((address) => {
                let votesByTheAddress;
                if (votesByAddress && votesByAddress[isAddress(address) ? getAddress(address) : address]) {
                  votesByTheAddress = votesByAddress[isAddress(address) ? getAddress(address) : address];
                }
                return (
                  <Popover
                    content={
                      <VoterPopoverContent
                        votesByAddress={votesByAddress}
                        votesByTheAddress={votesByTheAddress}
                        address={address}
                      />
                    }
                    placement="bottom"
                    trigger="hover"
                    key={address}
                    zIndex={20}
                    overlayClassName={"profilePopOver"}
                  >
                    <StyledCursorPointer as="a" href={`/voter/${address}`} target="_blank">
                      <AvatarImg address={address} size="xsmall" />
                    </StyledCursorPointer>
                  </Popover>
                );
              })}
              {connectedAddresses?.length > 5 && (
                <MoreConnectedAddressesText>+{connectedAddresses?.length - 5}</MoreConnectedAddressesText>
              )}
            </StyledAvatarsWrapper>
          )}
        </>
      </FlexItem>
      {twitter && website && (
        <StyledDiv>
          <StyledContainer>
            {twitter && (
              <a onClick={onTwitterClick} href={twitterLink} target="_blank">
                <FlexItem marginBottom cursor>
                  <TwitterIcon color={COLORS.primary.accent} />
                  <StyledText>{twitterHandle}</StyledText>
                </FlexItem>
              </a>
            )}
            {website && (
              <a onClick={onWebsiteClick} href={parsedWebsite} target="_blank">
                <FlexItem cursor>
                  <Link2Icon color={COLORS.primary.accent} />
                  <StyledText>{website}</StyledText>
                </FlexItem>
              </a>
            )}
          </StyledContainer>
        </StyledDiv>
      )}
    </StyledContentWrapper>
  );
}

function ProfileHeaderWrapper(props: Props) {
  return <ProfileHeader {...props} />;
}

export default React.memo(ProfileHeaderWrapper);
