import React, { useEffect } from "react";
import Col from "antd/es/col";
import Row from "antd/es/row";
import styled from "styled-components";

import { COLORS } from "../../constants/colors";
import ProtocolIcon from "../ProtocolIcon";
import { protocols } from "../../constants/protocols";
import { useMultipleAddressVotePower } from "../../hooks/useVotePower";
import formatValue from "../../utils/formatValue";
import media from "../../media-query";
import { useVotePowerFromApi } from "../../hooks/useVotePowerFromApi";

interface Props {
  protocol: string;
  addresses: string[];
  setNullChildren?: React.Dispatch<React.SetStateAction<string[]>>;
}

const StyledVotePowerRow = styled(Row)`
  padding: 16px 24px;
  ${media.lessThan("991px")`
     padding: 16px 8px;
  `}
`;

const StyledName = styled.span`
  padding-left: 8px;
  font-weight: 500;
  align-items: center;
  display: flex;
`;

const StyledPowerValue = styled.span`
  display: block;
  color: ${COLORS.primary.grayDark};
  font-weight: 600;
`;

const StyledCol = styled(Col)`
  display: flex;
`;

const StyledProtocolIcon = styled(ProtocolIcon)`
  display: flex;
  align-self: center;
`;

const StyledPwrCol = styled(Col)`
  text-align: right;
`;

function MultipleVotePowerRow(props: Props) {
  const { protocol, addresses, setNullChildren } = props;
  const { power, isLoading } = useMultipleAddressVotePower({ protocol, suspense: false, addresses });
  const votePowerHardCoded = useVotePowerFromApi({
    addresses,
  }); // used to get hard coded vote power from api for safedao
  const votePowerSafeDao = votePowerHardCoded
    ?.filter((vp) => vp?.protocol === "safedao")
    ?.map((vp) => vp.votePower)
    .reduce((a, b) => (a || 0) + (b || 0), 0);

  useEffect(() => {
    if (
      (!protocols[protocol] || typeof power !== "number" || formatValue(power, 1)?.toString() === "0") &&
      !isLoading &&
      !(votePowerSafeDao && protocol === "safedao")
    ) {
      setNullChildren && setNullChildren((prev) => Array.from(new Set([...prev, protocol])));
    } else {
      setNullChildren && setNullChildren((prev) => [...prev].filter((p) => p !== protocol));
    }
  }, [isLoading, power, protocol, setNullChildren, votePowerSafeDao]);

  if (!protocols[protocol]) {
    return null;
  }

  return (
    <>
      {(typeof power === "number" && formatValue(power, protocol === "makerdao" ? 3 : 1)?.toString() !== "0") ||
      (votePowerSafeDao && protocol === "safedao") ? (
        <StyledVotePowerRow>
          <StyledCol span={16}>
            <StyledProtocolIcon protocol={protocols[protocol]} size="xsmall" />
            <StyledName>{protocols[protocol].name}</StyledName>
          </StyledCol>
          <StyledPwrCol span={8}>
            <StyledPowerValue>
              {votePowerSafeDao && protocol === "safedao"
                ? formatValue(votePowerSafeDao, 1)
                : formatValue(power, protocol === "makerdao" ? 3 : 1)}
            </StyledPowerValue>
          </StyledPwrCol>
        </StyledVotePowerRow>
      ) : null}
    </>
  );
}

export default React.memo(MultipleVotePowerRow);
