import { useQuery } from "@tanstack/react-query";
import { getAddress } from "@ethersproject/address";
import { apiClient } from "../utils/apiClient";
import { protocols } from "../constants/protocols";
import { Voter } from "@boardroom/boardroom-api";
import { isAddress } from "web3-utils";

interface Props {
  address: string;
  suspense?: boolean;
}

interface MultipleAddressProps {
  addresses: Array<string>;
  suspense?: boolean;
}

export const fetchActiveProtocolsForMultipleAddresses = async (addresses: Array<string>) => {
  const responseArray = await Promise.all(
    addresses.map(async (address) => {
      try {
        return await apiClient.getVoter(address);
      } catch (e) {
        return {
          data: {
            address,
            totalVotesCast: 0,
            lastVoteCast: 0,
            firstVoteCast: 0,
            protocols: [],
          },
        };
      }
    }),
  );
  return responseArray;
};

export const useMultipleAddressesVoter = ({ addresses, suspense = true }: MultipleAddressProps) => {
  const votedProtocols = useQuery(
    [`voter:${addresses.toLocaleString()}`],
    async () => {
      try {
        const normalizedAddresses = addresses.map((address: string) =>
          isAddress(address) ? getAddress(address) : address,
        );
        const responseArray = await fetchActiveProtocolsForMultipleAddresses(normalizedAddresses);
        const flatProtocolsArray = responseArray
          .map((response) => response.data.protocols)
          .flat()
          .sort((a, b) => b.totalVotesCast - a.totalVotesCast);
        const uniqueProtocols = Array.from(new Set(flatProtocolsArray.map(({ protocol }) => protocol)));
        const enabledProtocols = uniqueProtocols.filter((protocol) => protocols[protocol]?.isEnabled);

        return {
          allProtocols: enabledProtocols,
          votesByAddress: responseArray.reduce((acc, voteStats) => {
            const enabledProtocols = voteStats.data.protocols.filter(({ protocol }) => protocols[protocol]?.isEnabled);
            const newData = Object.assign(voteStats.data, {
              protocols: enabledProtocols,
            });
            return {
              ...acc,
              [isAddress(voteStats.data.address) ? getAddress(voteStats.data.address) : voteStats.data.address]:
                newData,
            };
          }, {}) as Record<string, Voter>,
        };
      } catch (error) {
        console.error(error);
      }
    },
    {
      suspense,
      enabled: addresses.filter((address) => !!address).length > 0,
    },
  );

  return votedProtocols;
};

export const useVoter = ({ address, suspense = true }: Props) => {
  const { data: voter } = useQuery(
    [`voter-${address?.toLowerCase()}`],
    async () => {
      const normalizedAddress = isAddress(address) ? getAddress(address) : address;
      return await apiClient.getVoter(normalizedAddress);
    },
    {
      suspense,
      enabled: !!address,
      useErrorBoundary: false,
    },
  );

  return { voter: voter?.data };
};
