import * as React from "react";

const SvgDelegateIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.864 4.08a3.887 3.887 0 002.718 1.143 2.786 2.786 0 00-3.86-3.86c.01.985.39 1.966 1.142 2.718zM5.288 5.658a2.786 2.786 0 01-.096-3.838c.108.97.534 1.912 1.278 2.656a4.438 4.438 0 002.657 1.278 2.786 2.786 0 01-3.839-.096zm-1.365-.545a1.001 1.001 0 00-.212.188L.621 8.953a.908.908 0 001.279 1.28l3.653-3.091c.075-.063.138-.135.189-.212a2.773 2.773 0 01-1.132-.687 2.773 2.773 0 01-.687-1.131zm-.408 1.924a.279.279 0 01.394.394l-.394.393a.279.279 0 01-.394-.394l.394-.393z"
      fill="currentColor"
    />
  </svg>
);

export default SvgDelegateIcon;
