import * as React from "react";

const SvgPortfolioFilledIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M16 3h-5v18h5V3z" fill="currentColor" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.25 3a.75.75 0 01.75-.75h5a.75.75 0 01.75.75v18a.75.75 0 01-.75.75h-5a.75.75 0 01-.75-.75V3zm1.5.75v16.5h3.5V3.75h-3.5z"
      fill="currentColor"
    />
    <path d="M21 3h-5v18h5V3z" fill="currentColor" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.25 3a.75.75 0 01.75-.75h5a.75.75 0 01.75.75v18a.75.75 0 01-.75.75h-5a.75.75 0 01-.75-.75V3zm1.5.75v16.5h3.5V3.75h-3.5z"
      fill="currentColor"
    />
    <path d="M5 3l4 .5L7.25 21 3 20.5 5 3z" fill="currentColor" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.536 2.41a.75.75 0 01.557-.154l4 .5a.75.75 0 01.653.819l-1.75 17.5a.75.75 0 01-.834.67l-4.25-.5a.75.75 0 01-.657-.83l2-17.5a.75.75 0 01.281-.505zM5.66 3.838L3.83 19.843l2.75.323L8.18 4.153l-2.522-.315z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.5 6.75a.75.75 0 01.75.75V9a.75.75 0 01-1.5 0V7.5a.75.75 0 01.75-.75zM13.5 6.75a.75.75 0 01.75.75V9a.75.75 0 01-1.5 0V7.5a.75.75 0 01.75-.75z"
      fill="#fff"
    />
  </svg>
);

export default SvgPortfolioFilledIcon;
