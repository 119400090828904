import * as React from "react";

const SvgArrowUpIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M25 16.83l2.59 2.58L29 18l-5-5-5 5 1.41 1.41L23 16.83V33h2V16.83z" fill="currentColor" />
    <circle cx={24} cy={24} r={23.5} stroke="currentColor" />
  </svg>
);

export default SvgArrowUpIcon;
