import React, { Suspense } from "react";
import { ProtocolDetail } from "@boardroom/boardroom-api";
import { ErrorBoundary } from "react-error-boundary";
import ContentLoader from "react-content-loader";

import { useProtocolIcon } from "../../hooks/useProtocolIcon";
import { Avatar, AvatarDimensions as dimensions, AvatarProps } from "../Avatar";
import { COLORS } from "../../constants/colors";
import { ProtocolDescription } from "../../types";

interface Props {
  protocol: ProtocolDescription | ProtocolDetail | null;
  theme?: "light" | "dark";
  size?: AvatarProps["size"];
}

export const ProtocolIcon = ({ protocol, size, ...props }: Props) => {
  const icon = useProtocolIcon({ protocolCname: protocol?.cname });

  return (
    <Avatar
      style={{
        flexShrink: 0,
      }}
      src={icon?.url as unknown as string}
      size={size}
      backgroundColor="transparent"
      {...props}
    />
  );
};

export default ({ protocol, theme = "light", size, ...props }: Props) => {
  const iconSize = size ? dimensions[size] : dimensions.medium;

  return (
    <ErrorBoundary
      fallback={
        <Avatar src={`${process.env.PUBLIC_URL}/assets/boardroomCard.png`} size={size} backgroundColor="transparent" />
      }
    >
      <Suspense
        fallback={
          <ContentLoader
            speed={2}
            width={`${iconSize}px`}
            height={`${iconSize}px`}
            viewBox={`0 0 ${iconSize} ${iconSize}`}
            backgroundColor={theme === "light" ? COLORS.primary.grayLight : COLORS.primary.grayDarkLighter}
            foregroundColor={theme === "light" ? COLORS.primary.grayLighter : COLORS.primary.grayDarkLightest}
            {...props}
          >
            <circle cx={iconSize / 2} cy={iconSize / 2} r={iconSize / 2} />
          </ContentLoader>
        }
      >
        <ProtocolIcon protocol={protocol} theme={theme} size={size} {...props} />
      </Suspense>
    </ErrorBoundary>
  );
};
