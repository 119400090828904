import { useQuery } from "@tanstack/react-query";
import { APIResponse } from "@boardroom/boardroom-api";

import { BOARDROOM_API_KEY, baseAPIUrl } from "../constants/general";

export interface ProfileTag {
  tag: string;
  protocol: string;
  address: string;
  timestamp: number;
  id: string;
}

export const useGetProfileTagsByAddress = ({ address }: { address: string }) => {
  const { data } = useQuery<APIResponse<ProfileTag>>({
    queryKey: ["getProfileTagsByAddress", address],
    queryFn: async () => {
      const response = await fetch(
        `${baseAPIUrl}profileTags/getProfileTagsByAddress/${address}?key=${BOARDROOM_API_KEY}`,
      );
      const data = await response.json();
      return data;
    },
    enabled: !!address,
  });

  return {
    profileTags: data?.data,
  };
};

export const useGetProfileTagsByProtocol = ({ protocol }: { protocol: string }) => {
  const { data } = useQuery<APIResponse<ProfileTag[]>>({
    queryKey: ["getProfileTagsByProtocol", protocol],
    queryFn: async () => {
      const response = await fetch(
        `${baseAPIUrl}profileTags/getProfileTagsByProtocol/${protocol}?key=${BOARDROOM_API_KEY}`,
      );
      const data = await response.json();
      return data;
    },
    enabled: !!protocol,
  });

  return {
    profileTags: data?.data,
  };
};

export const useGetProfileTagsByProtocolAndTag = ({ protocol, tag }: { protocol: string; tag: string }) => {
  const { data } = useQuery<APIResponse<ProfileTag[]>>({
    queryKey: ["getProfileTagsByProtocolAndTag", protocol, tag],
    queryFn: async () => {
      const response = await fetch(
        `${baseAPIUrl}profileTags/getProfileTagsByProtocolAndTag/${protocol}/${tag}?key=${BOARDROOM_API_KEY}`,
      );
      const data = await response.json();
      return data;
    },
    enabled: !!protocol && !!tag,
  });

  return {
    profileTags: data?.data,
  };
};
