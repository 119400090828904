import { generateMedia, defaultBreakpoints } from "styled-media-query";

export default generateMedia({
  ...defaultBreakpoints,
  "320px": "320px",
  "375px": "375px",
  "500px": "500px",
  "640px": "640px",
  "767px": "767px",
  "860px": "860px",
  "870px": "870px",
  "991px": "991px",
  "1100px": "1100px",
  "1200px": "1200px",
  "1260px": "1260px",
  "1400px": "1400px",
  "1459px": "1459px",
  "1460px": "1460px",
  "1550px": "1550px",
  "1600px": "1600px",
  "1680px": "1680px",
  "1750px": "1750px",
  "1800px": "1800px",
  "1880px": "1880px",
  "2000px": "2000px",
  "2200px": "2200px",
  "2300px": "2300px",
  "2400px": "2400px",
  "2600px": "2600px",
  "2800px": "2800px",
});
