import React from "react";
import styled from "styled-components";
import { ThinChevronUpIcon } from "../../icons";

interface Props {
  type: "prev" | "next";
  onClick?: () => void;
}

const StyledChevron = styled(ThinChevronUpIcon)<{ $type: "prev" | "next" }>`
  cursor: pointer;
  transform: ${({ $type }) => ($type === "prev" ? "rotate(-90deg)" : "rotate(90deg)")};
`;

const Container = styled.div<{ $type: "prev" | "next" }>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  border: 1px solid #7b7893;
  box-shadow: 0px 2px 6px 0px rgba(25, 21, 64, 0.05);
  width: 24px;
  height: 24px;
  margin-right: ${({ $type }) => ($type === "prev" ? "8px" : "0")};
  color: #7b7893;
  :hover {
    background-color: #4235e1;
    color: white;
    border-color: #4235e1;
  }
  :active,
  :focus {
    border-color: #4235e1;
    color: #4235e1;
    background-color: #fff;
  }
`;

export const NavigationControl = ({ type, onClick }: Props) => {
  return (
    <Container $type={type}>
      <StyledChevron width={20} height={20} onClick={onClick} $type={type} />
    </Container>
  );
};
