import moment, { Moment } from "moment";

import { COLORS } from "../../constants/colors";
import { CalendarEventTypes } from "../../types";

export function humanizeEventTypeText(text: CalendarEventTypes, isMobile: boolean = false) {
  switch (text) {
    case "call":
      return "Event";
    case "event":
      return "Event";
    case "govWeekly":
      return "Meeting";
    case "meeting":
      return "Meeting";
    case "proposalEnd":
      return isMobile ? "Prop. Ends" : "Proposal Ends";
    case "proposalStart":
      return isMobile ? "Prop. Starts" : "Proposal Starts";
  }
}

export const isDateToday = (date: Moment) => moment(date).isSame(moment(), "day");

export const eventTypeColorCoding: Record<string, string> = {
  call: COLORS.secondary.yellowLight,
  event: COLORS.secondary.yellowLight,
  proposalEnd: COLORS.secondary.pink,
  proposalStart: COLORS.secondary.greenLight,
  govWeekly: COLORS.secondary.blueLight,
  meeting: COLORS.secondary.blueLight,
};
