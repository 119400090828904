import React from "react";
import Badge from "antd/es/badge";
import Row from "antd/es/row";
import styled from "styled-components";

import { eventTypeColorCoding, humanizeEventTypeText } from "../Calendar/utils";
import { COLORS } from "../../constants/colors";
import mediaQuery from "../../media-query";

const Label = styled.span`
  font-family: Inter;
  font-style: normal;
  margin-right: 12px;
  margin-bottom: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: ${COLORS.primary.grayDarkLightest};
`;

const StyledRow = styled(Row)`
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
`;

const RowsWrapper = styled("div")`
  display: flex;
  justify-content: space-around;
  ${mediaQuery.lessThan("991px")`
    flex-direction: column;
  `}
`;

const Color = styled(Badge)``;

function CalendarLegend() {
  return (
    <RowsWrapper>
      <StyledRow align="middle">
        <div>
          <Color color={eventTypeColorCoding.proposalStart} />
          <Label>{humanizeEventTypeText("proposalStart")}</Label>
        </div>
        <div>
          <Color color={eventTypeColorCoding.proposalEnd} />
          <Label>{humanizeEventTypeText("proposalEnd")}</Label>
        </div>
      </StyledRow>
      <StyledRow>
        <div>
          <Color color={eventTypeColorCoding.call} />
          <Label>{humanizeEventTypeText("call")}</Label>
        </div>
        <div>
          <Color color={eventTypeColorCoding.govWeekly} />
          <Label>{humanizeEventTypeText("govWeekly")}</Label>
        </div>
      </StyledRow>
    </RowsWrapper>
  );
}

export default CalendarLegend;
