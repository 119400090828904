import * as React from "react";

const SvgCameraIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M3 9.854C3 8.83 3.83 8 4.854 8v0c.702 0 1.344-.397 1.658-1.025L7 6c.434-.868.65-1.302 1.01-1.58a2 2 0 01.36-.223C8.78 4 9.266 4 10.236 4h3.528c.97 0 1.455 0 1.866.197.127.061.247.136.36.222.36.28.576.713 1.01 1.581l.488.975A1.854 1.854 0 0019.146 8v0C20.17 8 21 8.83 21 9.854V16c0 1.886 0 2.828-.586 3.414C19.828 20 18.886 20 17 20H7c-1.886 0-2.828 0-3.414-.586C3 18.828 3 17.886 3 16V9.854z"
      stroke="currentColor"
    />
    <circle cx={12} cy={13} r={3.5} stroke="currentColor" />
  </svg>
);

export default SvgCameraIcon;
