import React from "react";
import styled from "styled-components";
import toast, { Toaster } from "react-hot-toast";
import { COLORS } from "../../constants/colors";
import { Avatar } from "../Avatar";
import { Header, Paragraph } from "../Typography";
import { CheckCircleIcon, CrossIcon, CrossCircleIcon, WarningIcon, InfoIcon } from "../icons";

const StyledToast = styled("div")`
  padding: 20px 36px 20px 16px;
  display: flex;
`;

const StyledContent = styled("span")`
  margin-left: 10px;
`;

const CloseIcon = styled("span")`
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
`;

const StyledIcon = styled(Avatar)`
  width: 97px;
  height: 46px;
  line-height: 54px;
  box-sizing: border-box;
`;

interface ToastArgs {
  title: string;
  message: string;
  showIcon: boolean;
}

interface GenerateToastArgs extends ToastArgs {
  icon: React.ReactNode;
  toastBackgroundColor: string;
  iconBackgroundcolor: string;
}

const dismissToast = (toastId: string | undefined) => {
  toast.dismiss(toastId);
};

const generateToast = ({
  title,
  message,
  icon,
  iconBackgroundcolor,
  toastBackgroundColor,
  showIcon = true,
}: GenerateToastArgs) => {
  const toastId = toast(
    <StyledToast>
      {showIcon && <StyledIcon size="large" icon={icon} backgroundColor={iconBackgroundcolor} color="white" />}

      <StyledContent>
        <Header level={5}>{title}</Header>
        <Paragraph>{message}</Paragraph>
      </StyledContent>
      <CloseIcon onClick={() => dismissToast(toastId)}>
        <CrossIcon />
      </CloseIcon>
    </StyledToast>,
    {
      duration: 5000,
      style: {
        padding: "0px",
        background: toastBackgroundColor,
      },
    },
  );
};

const successToast = ({ title, message, showIcon }: ToastArgs) => {
  generateToast({
    title,
    message,
    icon: <CheckCircleIcon />,
    iconBackgroundcolor: COLORS.secondary.green,
    toastBackgroundColor: COLORS.secondary.greenLighter,
    showIcon,
  });
};

const errorToast = ({ title, message, showIcon }: ToastArgs) => {
  generateToast({
    title,
    message,
    icon: <CrossCircleIcon />,
    iconBackgroundcolor: COLORS.secondary.red,
    toastBackgroundColor: COLORS.secondary.redLighter,
    showIcon,
  });
};

const alertToast = ({ title, message, showIcon }: ToastArgs) => {
  generateToast({
    title,
    message,
    icon: <WarningIcon />,
    iconBackgroundcolor: COLORS.secondary.yellow,
    toastBackgroundColor: COLORS.secondary.yellowLighter,
    showIcon,
  });
};

const infoToast = ({ title, message, showIcon }: ToastArgs) => {
  generateToast({
    title,
    message,
    icon: <InfoIcon />,
    iconBackgroundcolor: COLORS.secondary.blue,
    toastBackgroundColor: COLORS.secondary.blueLighter,
    showIcon,
  });
};

function Toast() {
  return (
    <Toaster
      position="top-right"
      toastOptions={{
        style: {
          boxShadow: "0px 5px 14px rgba(16, 128, 67, 0.08)",
        },
      }}
    />
  );
}

export { Toast, successToast, errorToast, alertToast, infoToast };
