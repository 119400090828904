import { PendingVoteDetails } from "@boardroom/boardroom-api";
import React, { useContext, useEffect, useState } from "react";
import { BOARDROOM_API_KEY, baseAPIUrl } from "../../constants/general";
import { NotificationsContext } from "../../contexts";
import { useTransactionVoteStatus } from "../../hooks/useTransactionStatus";
import { PendingVoteDetailsContext } from "../../reducers/PendingVotes";

export interface Props {
  vote: PendingVoteDetails;
}

export const PendingVoteToast = ({ vote }: Props) => {
  const { txnHash, protocol } = vote;
  const { dispatch } = useContext(NotificationsContext);
  const { data } = useTransactionVoteStatus(txnHash, protocol);
  const [status, setStatus] = useState("PENDING");
  const { dispatchPendingVoteDetails } = useContext(PendingVoteDetailsContext);

  useEffect(() => {
    const updateVote = async (status: string) => {
      await fetch(`${baseAPIUrl}/voters/addOrUpdatePendingVote?key=${BOARDROOM_API_KEY}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ...vote,
            cname: vote.protocol,
            status,
          }),
        },
      );
    };

    const checkStatus = async (data: any) => {
      if (!data.result) {
        if (status !== "PENDING") {
          setStatus("PENDING");
        }
      } else if (data.result.status === "0x0" && status == "PENDING") {
        setStatus("FAILED");
        dispatchPendingVoteDetails({
          type: "SAVE_PENDING_VOTE_DETAILS",
          data: [
            {
              ...vote,
              status: "failed",
            },
          ],
        });
        await updateVote("failed");
        dispatch({
          type: "failedOnChain",
          payload: {
            confirmationNumber: txnHash,
            isOnChainVote: true,
            message: "Transaction failed",
          },
        });
      } else if (status == "PENDING") {
        setStatus("SUCCESS");
        dispatchPendingVoteDetails({
          type: "SAVE_PENDING_VOTE_DETAILS",
          data: [
            {
              ...vote,
              status: "success",
            },
          ],
        });
        await updateVote("success");
        dispatch({
          type: "successOnChain",
          payload: {
            confirmationNumber: txnHash,
            isOnChainVote: true,
            message: "Transaction Success",
          },
        });
      }
    };
    if (data) {
      checkStatus(data.data);
    }
  }, [status, data, dispatch, txnHash, vote, dispatchPendingVoteDetails]);
  return <></>;
};
