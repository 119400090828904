import React, { useCallback, useContext, useState } from "react";
import { DelegatePost } from "@boardroom/boardroom-api";
import Comment from "antd/es/comment";
import Popover from "antd/es/popover";
import parse from "html-react-parser";
import styled from "styled-components";
import moment from "moment";
import { useCurrentWidth } from "react-socks";
import CopyToClipboard from "react-copy-to-clipboard";
import { TwitterShareButton } from "react-share";

import { useMixpanel, useRenderMd } from "../../hooks";
import { useGetEns } from "../../hooks/useEns";
import { useUserDetails } from "../../hooks/useUserDetails";
import { CurrentAccountContext } from "../../reducers/CurrentAccount";
import ProfileAvatar from "../VoterProfile/ProfileAvatar";
import { Link } from "../Typography";
import {
  CopyIcon,
  EditIcon,
  ThinShareIcon,
  ThreeDotMenuIcon,
  TrashIcon,
  TwitterIcon,
  TwitterOutlinedIcon,
} from "../icons";
import { formatEns, toShortAddress } from "../../utils";
import { COLORS } from "../../constants/colors";
import { PostModal } from "../PostModal/PostModal";
import { useDelegatePosts } from "../../hooks/useDelegatePosts";
import { protocols } from "../../constants/protocols";
import { ShareText, StyledCopiedIcon, StyledCopiedText, StyledCopyText } from "../DelegateMessageBoard/styles";

export const StyledTrashIcon = styled(TrashIcon)`
  cursor: pointer;
  margin-right: 8px;
`;

export const StyledEditIcon = styled(EditIcon)`
  cursor: pointer;
  margin-right: 4px;
`;

export const StyledTwitterIcon = styled(TwitterIcon)`
  cursor: pointer;
  margin-right: 8px;
`;

export const StyledCopyIcon = styled(CopyIcon)`
  width: 16px;
  height: 16px;
  vertical-align: text-bottom;
  cursor: pointer;
  margin-right: 8px;
`;

export const StyledComment = styled(Comment)<{ $removeBorderBottom?: boolean }>`
  border-bottom: ${({ $removeBorderBottom }) => ($removeBorderBottom ? "0px" : "1px solid #f0eff8")};
  padding: 16px 20px;
  background: transparent;
  .ant-comment-actions {
    display: flex;
    justify-content: end;
  }
  .ant-comment-inner {
    padding: 0;
  }
  .ant-comment-nested {
    margin-left: 0;
    padding-left: 12px;
    border-left: 1px solid #f0eff8;
    border-bottom: 0;
  }
  .ant-comment-actions {
    gap: 40px;
  }
  .ant-comment-actions > li {
    display: flex;
    align-items: center;
    cursor: pointer;
    > span {
      color: #4235e1;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
    }
  }
  .markdown-body p {
    color: #7b7893;
  }
  .ant-comment-content-author {
    align-items: center;
    > span {
      font-size: 12px;
      line-height: 16px;
      color: #7b7893;
      padding-right: 4px;
    }
  }
  .ant-comment-content-author-time {
    width: 100%;
    display: contents;
  }
  .ant-comment-content-author-name > * {
    color: #7b7893;
  }
  .ant-comment-avatar {
    margin-right: 8px;
  }
`;

export const AuthorWrapper = styled.div`
  display: flex;
`;

export const PostedByText = styled.span`
  margin-right: 4px;
`;

export const StyledThreeDotMenuIcon = styled(ThreeDotMenuIcon)`
  transform: rotate(90deg);
  color: #7b7893;
  margin-left: auto;
`;

export const StyledPopover = styled(Popover)`
  cursor: pointer;
  &:hover {
    color: ${COLORS.secondary.purple};
  }
`;

export const PopoverContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const PopoverAction = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  color: ${COLORS.primary.accent};
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  &:hover {
    background: ${COLORS.primary.grayLighter};
    color: ${COLORS.primary.grayDarkLighter};
  }
`;

export const StyledTwitterShareButton = styled(TwitterShareButton)`
  display: flex;
  align-items: center;
`;

export const OpTag = styled.span`
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  color: ${COLORS.blue};
  margin-left: 2px;
  margin-top: -2px;
`;

export const SharedTag = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: ${COLORS.blue};
  margin-left: 6px;
  text-transform: uppercase;
`;

export const StyledCommentWrapper = styled.div<{ $isAuthor?: boolean; $isToday?: boolean; $isShared?: boolean }>`
  background: inherit;
  ${({ $isToday }) => $isToday && "background: rgba(240, 239, 248, 0.5);"}
  ${({ $isShared }) => $isShared && "background: rgba(66, 53, 225, 0.2);"}
  border-radius: 8px;
  margin: 12px 0;
`;

const StyledLink = styled(Link)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 12rem;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #191540;
`;

const MarkdownBodyDiv = styled.div``;

const MessageOnMessageBoard = ({
  post,
  removePost,
  removeBorderBottom,
  isShared,
  onMessagesDrawerOpen,
  isDrawer,
}: {
  post: DelegatePost & { parentPostId?: string; postedForAddress?: string };
  removePost: any;
  removeBorderBottom?: boolean;
  isShared?: boolean;
  onMessagesDrawerOpen?: any;
  isDrawer?: boolean;
}) => {
  const protocolInfo = protocols[post.protocol];
  const { account } = useContext(CurrentAccountContext);
  const author = post.postedForAddress || post.author;
  const { user: userDetails } = useUserDetails({ address: author, suspense: false });
  const summary = useRenderMd(post.postBody);
  const ens = useGetEns(author);
  const isMobile = useCurrentWidth() < 991;
  const isAuthor = account?.toLowerCase() === author?.toLowerCase();
  const isMessageCreator = account?.toLowerCase() === post.author?.toLowerCase();
  const shortAddress = String(toShortAddress(author));
  const parsedCreatedAt = Number(post.createdAt);
  const [postModalOpen, setPostModalOpen] = useState(false);
  const [copied, setCopied] = useState(false);
  const { refetch } = useDelegatePosts({
    author: post.author,
    protocol: post.protocol,
    byPassCache: true,
  });
  const { trackShareDelegationTabPost, trackOpenPostModalForEditingDelegationTab } = useMixpanel();

  const onClose = useCallback(() => {
    setPostModalOpen(false);
  }, []);

  const onOpen = useCallback(() => {
    setPostModalOpen(true);
    trackOpenPostModalForEditingDelegationTab({
      userId: account,
      protocol: post.protocol,
    });
  }, [account, post.protocol, trackOpenPostModalForEditingDelegationTab]);

  const currentDate = moment();

  const dateString = moment(parsedCreatedAt).calendar({
    sameDay: "[Today]",
    nextDay: "[Tomorrow]",
    nextWeek: "dddd",
    lastDay: "[Yesterday]",
    lastWeek: "[Last] dddd",
    sameElse: "DD/MM/YYYY",
  });

  const twitterShareClick = useCallback(() => {
    trackShareDelegationTabPost({
      userId: account,
      protocol: post.protocol,
    });
  }, [account, post.protocol, trackShareDelegationTabPost]);

  const handleVisibleChange = useCallback((visible: boolean) => {
    if (!visible) {
      setTimeout(() => {
        setCopied(false);
      }, 100);
    }
  }, []);

  const onCopy = useCallback(() => {
    setCopied(true);
  }, []);

  return (
    <StyledCommentWrapper
      $isToday={moment(parsedCreatedAt).isSame(currentDate, "day")}
      $isShared={isShared}
      $isAuthor={isAuthor}
      id={`post${post.postId}`}
    >
      <PostModal refetch={refetch} post={post} protocol={post.protocol} onClose={onClose} open={postModalOpen} />
      <StyledComment
        $removeBorderBottom={removeBorderBottom}
        author={
          <AuthorWrapper>
            <StyledLink to={`/voter/${author}`}>{userDetails?.username || formatEns(ens) || shortAddress}</StyledLink>
          </AuthorWrapper>
        }
        avatar={<ProfileAvatar address={author} customImageDimensions={32} pfp={userDetails?.pfpUrl} />}
        content={
          <MarkdownBodyDiv onClick={onMessagesDrawerOpen} className="markdown-body">
            {post.postBody?.length > 270 && !isDrawer
              ? parse(
                  summary.slice(0, 270) +
                    " <span style='display: block; color: #4235e1; position: absolute; left: 0; margin-top: 12px; cursor: pointer;'>Continue Reading</span>",
                )
              : parse(summary)}
          </MarkdownBodyDiv>
        }
        actions={[
          <Popover
            trigger="hover"
            placement="topRight"
            zIndex={1000}
            onVisibleChange={handleVisibleChange}
            key="share"
            content={
              <div>
                {!copied ? (
                  <CopyToClipboard
                    text={`https://boardroom.io/people/${post.protocol}/${author}?post=${post.postId}`}
                    onCopy={onCopy}
                  >
                    <StyledCopyText>
                      <StyledCopyIcon />
                      Copy Link
                    </StyledCopyText>
                  </CopyToClipboard>
                ) : (
                  <StyledCopiedText>
                    <StyledCopiedIcon />
                    Link Copied!
                  </StyledCopiedText>
                )}
                <TwitterShareButton
                  url={`https://boardroom.io/people/${post.protocol}/${author}?post=${post.postId}`}
                  via={"boardroom_info"}
                  title={`View this delegate post on ${protocolInfo?.name}`}
                  style={{ display: "flex", alignItems: "center" }}
                  key="share"
                  onClick={twitterShareClick}
                >
                  <TwitterOutlinedIcon width={16} height={16} color="#4235E1" />
                  <ShareText>Tweet</ShareText>
                </TwitterShareButton>
              </div>
            }
          >
            <ThinShareIcon width={16} height={16} color="#7B7893" />
          </Popover>,
        ]}
        datetime={
          <>
            ·
            <span
              style={{ marginLeft: "4px", color: dateString !== "Today" ? "inherit" : "#D225BE" }}
              key={parsedCreatedAt}
            >
              {dateString.toString()} {dateString !== "Today" && `at ${moment(parsedCreatedAt).format("HH:mm")}`}
              {isShared && <OpTag>· SHARED</OpTag>}
            </span>
            {isMessageCreator && (
              <StyledPopover
                zIndex={20}
                trigger="hover"
                placement="topRight"
                overlayInnerStyle={{
                  boxShadow: "0px 4px 7.68px rgba(7, 3, 40, 0.1)",
                  borderRadius: "6px",
                }}
                content={
                  <PopoverContainer>
                    {!isMobile && (
                      <>
                        {account?.toLowerCase() === post?.author?.toLowerCase() && (
                          <PopoverAction onClick={onOpen}>
                            <StyledEditIcon width={20} height={20} />
                            <span>Edit</span>
                          </PopoverAction>
                        )}

                        {account?.toLowerCase() === post?.author?.toLowerCase() && (
                          <PopoverAction onClick={() => removePost(post.postId)}>
                            <StyledTrashIcon key="delete-comment" width={16} height={16} color="red" />
                            <span>Delete</span>
                          </PopoverAction>
                        )}
                      </>
                    )}
                  </PopoverContainer>
                }
              >
                <StyledThreeDotMenuIcon width={4} height={20} />
              </StyledPopover>
            )}
          </>
        }
      ></StyledComment>
    </StyledCommentWrapper>
  );
};

export default MessageOnMessageBoard;
