import axios from "axios";
import { useQuery } from "@tanstack/react-query";

interface GnosisSafeResponse {
  owners: string[];
}

export const useGnosisSafeOwners = (safeAddress?: string) => {
  const { data } = useQuery<GnosisSafeResponse, Error>([`${safeAddress}:gnosisOwners`], async () => {
    try {
      return (await axios.get(`https://safe-transaction.mainnet.gnosis.io/api/v1/safes/${safeAddress}`))?.data;
    } catch (_) {
      return { owners: [] };
    }
  });

  return { gnosisOwners: data?.owners };
};
