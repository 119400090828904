import React from "react";
import styled from "styled-components";
import AntdStatistic, { StatisticProps as AntdStatisticProps } from "antd/es/statistic";
import Card from "antd/es/card";

import { COLORS } from "../../constants/colors";
import media from "../../media-query";

interface StatisticProps extends AntdStatisticProps {
  image?: string;
  inline?: boolean;
  contentColor?: string;
  bordered?: boolean;
  centered?: boolean;
}

export const StyledCard = styled(Card)<{ $inline: StatisticProps["inline"] }>`
  padding: ${({ $inline }) => ($inline ? "20px" : "0")};
  border-radius: 0;
  text-align: left;
  background: inherit;
  .ant-card-body {
    padding: 0px;
  }
  ${media.lessThan("640px")`
    padding: 6px 12px;
    background: inherit;
  `}
`;

const StyledStatistic = styled(AntdStatistic)<{ $contentColor: StatisticProps["contentColor"] }>`
  display: inline-block;
  .ant-statistic-title {
    color: ${COLORS.primary.grayDarkLightest};
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    margin-bottom: 8px;
  }
  .ant-statistic-content {
    color: ${({ $contentColor }) => $contentColor || COLORS.primary.grayDark};
    font-size: 20px;
    font-weight: 300;
    line-height: 16px;
  }
  .ant-statistic-content-suffix {
    margin-left: 8px;
  }
`;

function Statistic(props: StatisticProps) {
  const { inline = false, bordered = false, centered = false, contentColor, ...restOfTagProps } = props;
  let statsStyles = {};
  if (centered) {
    statsStyles = { textAlign: "center", width: "100%" };
  }
  return (
    <StyledCard bordered={bordered} $inline={inline}>
      <StyledStatistic {...restOfTagProps} $contentColor={contentColor} style={statsStyles} />
    </StyledCard>
  );
}

export default Statistic;
