import { isProd } from "../constants/general";

const loadChargeBee = (callback?: Function) => {
  const existingScript = document.getElementById("chargebee-script");
  if (!existingScript) {
    const script = document.createElement("script");
    script.src = "https://js.chargebee.com/v2/chargebee.js";
    script.id = "chargebee-script";
    document.body.appendChild(script);
    script.onload = () => {
      script.setAttribute("script-loaded", "true");
      // @ts-ignore
      window.Chargebee.init({
        site: isProd ? "boardroom" : "boardroom-test",
      });
      if (callback) {
        callback();
      }
    };
  }

  if (existingScript && callback && existingScript.getAttribute("script-loaded") === "true") callback();
};

export default loadChargeBee;
