import React from "react";
import styled from "styled-components";
import Row from "antd/es/row";
import Col from "antd/es/col";

import { Button } from "../Button";
import { COLORS } from "../../constants/colors";
import VerifyAddress from "./VerifyAddress";

const InnerInputLabel = styled("div")<{ $disabled?: boolean }>`
  font-size: 14px;
  line-height: 18px;
  color: ${({ $disabled }) => ($disabled ? COLORS.primary.gray : COLORS.primary.grayDark)};
  margin: 24px 0 12px;
  display: flex;
  justify-content: space-between;
`;

const StyledReviewButton = styled(Button)`
  border-radius: 8px !important;
  margin: 4px 0 4px auto;
  display: block;
  width: 90%;
  &[disabled] {
    background: ${COLORS.secondary.purple};
    color: #fff;
    :hover {
      background: ${COLORS.secondary.purple};
      color: #fff;
    }
  }
`;

const StyledBackButton = styled(Button)`
  border-radius: 8px !important;
  margin: 4px auto 4px;
  width: 90%;
  border: 0 !important;
  font-weight: 500 !important;
  font-size: 14px;
  line-height: 16px;
`;

const ReviewContainer = styled("div")`
  padding: 32px;
  border: 1px solid ${COLORS.primary.accent};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const ReviewLabel = styled("span")`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: ${COLORS.primary.grayDark};
  margin: 0 0 4px 10px;
`;

const ReviewValue = styled("span")`
  font-size: 14px;
  line-height: 18px;
  color: ${COLORS.primary.grayDarkLightest};
  margin: 0 0 24px 10px;
  max-width: 65%;
  overflow-wrap: break-word;
`;

const ContractAddressContainer = styled("div")`
  display: flex;
  justify-content: space-between;
`;

interface Props {
  target: string;
  value: string;
  method: string;
  callDataTopicValues: string[];
  goBack: () => void;
  confirmCommand: () => void;
}

const ReviewCommand = ({ target, value, method, callDataTopicValues, goBack, confirmCommand }: Props) => {
  return (
    <>
      <InnerInputLabel>Review and Confirm Proposal Command</InnerInputLabel>
      <ReviewContainer>
        <ReviewLabel>Contract Address</ReviewLabel>
        <ContractAddressContainer>
          <ReviewValue>{target}</ReviewValue> <VerifyAddress state="verified" addMargin />
        </ContractAddressContainer>
        <ReviewLabel>Value</ReviewLabel>
        <ReviewValue>{value} Wei</ReviewValue>
        <ReviewLabel>Signature</ReviewLabel>
        <ReviewValue>{method}</ReviewValue>
        <ReviewLabel>Call Data</ReviewLabel>
        {callDataTopicValues.map((value) => (
          <ReviewValue key={value}>{value}</ReviewValue>
        ))}
      </ReviewContainer>
      <Row>
        <Col span={12}>
          <StyledBackButton onClick={goBack} type="secondary">
            Back
          </StyledBackButton>
        </Col>
        <Col span={12}>
          <StyledReviewButton onClick={confirmCommand}>Confirm</StyledReviewButton>
        </Col>
      </Row>
    </>
  );
};

export default ReviewCommand;
