import * as React from "react";

const SvgExploreTabOutlinedIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M9.667 7.145l.347-.664-.347.664zm4.931 3.355l.65-.375-.65.375zM8.963 7.552l-.75-.031.75.03zm.439 5.948l-.65.375.65-.375zm4.932 3.355l.347-.665-.347.665zm.704-.407l.749.031-.75-.031zM21.25 12A9.25 9.25 0 0112 21.25v1.5c5.937 0 10.75-4.813 10.75-10.75h-1.5zM12 2.75A9.25 9.25 0 0121.25 12h1.5c0-5.937-4.813-10.75-10.75-10.75v1.5zM2.75 12A9.25 9.25 0 0112 2.75v-1.5C6.063 1.25 1.25 6.063 1.25 12h1.5zM12 21.25A9.25 9.25 0 012.75 12h-1.5c0 5.937 4.813 10.75 10.75 10.75v-1.5zM9.319 7.81c.671.351 1.74.93 2.706 1.54.484.306.93.61 1.284.893.373.297.571.513.64.632l1.299-.75c-.219-.379-.611-.742-1.004-1.055-.414-.33-.91-.668-1.418-.988a42.2 42.2 0 00-2.812-1.601L9.32 7.81zm.393-.227a.272.272 0 01-.393.227l.695-1.33a1.228 1.228 0 00-1.8 1.04l1.498.063zm.34 5.542c-.069-.119-.157-.399-.228-.87a14.708 14.708 0 01-.131-1.558 40.749 40.749 0 01.019-3.114L8.213 7.52a42.228 42.228 0 00-.02 3.235c.024.6.069 1.2.148 1.722.075.497.193 1.018.411 1.397l1.3-.75zm4.63 3.065c-.672-.351-1.74-.93-2.707-1.54a14.74 14.74 0 01-1.283-.893c-.374-.297-.572-.514-.64-.632l-1.3.75c.22.379.611.741 1.004 1.055.414.33.91.668 1.418.988a42.251 42.251 0 002.812 1.601l.695-1.329zm-.394.227a.272.272 0 01.393-.227l-.695 1.329a1.228 1.228 0 001.8-1.04l-1.498-.062zm-.34-5.542c.069.118.157.398.228.87.068.447.109.986.131 1.558a40.749 40.749 0 01-.019 3.114l1.499.062c.032-.772.066-2.034.02-3.235-.024-.6-.069-1.2-.148-1.722-.074-.498-.193-1.018-.411-1.397l-1.3.75z"
      fill="currentColor"
    />
  </svg>
);

export default SvgExploreTabOutlinedIcon;
