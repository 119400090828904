import Col from "antd/es/col";
import Row from "antd/es/row";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

import media from "../../media-query";
import { ArrowLeftThinIcon } from "../../components/icons";
import { COLORS } from "../../constants/colors";
import mediaQuery from "../../media-query";
import { pageHeaderHeight } from "../../constants/styles";

const Container = styled.div<{ isSettings: boolean; $isCalendar?: boolean; $width?: number; $isSplitView?: boolean }>`
  margin: auto;
  height: ${({ isSettings, $isSplitView }) =>
    isSettings ? `calc(100vh - ${pageHeaderHeight})` : $isSplitView ? `calc(100vh - ${pageHeaderHeight})` : "auto"};
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;
  background-color: #fafafa;
  padding-top: 0;
  ${media.lessThan("991px")`
    padding: 0px;
    height: calc(100vh - ${pageHeaderHeight});
  `}
`;

const PageHeader = styled.div<{ $scrollFromTop: number; $isPortal?: boolean }>`
  width: 100%;
  padding-top: 24px;
  padding-bottom: ${({ $isPortal }) => ($isPortal ? (mediaQuery.lessThan("991px") ? "32px" : "36px") : "0px")};
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  background: #fafafa;
  ${mediaQuery.lessThan("991px")`
    padding-top: 0;
  `}
`;

const PaddingContainer = styled.div<{ $isSettings?: boolean }>`
  ${({ $isSettings }) => $isSettings && "padding-left: 200px;"}
  ${mediaQuery.lessThan("991px")`
    padding: 0;
  `}
  ${({ $isSettings }) =>
    !$isSettings
      ? css`
          ${mediaQuery.greaterThan("1750px")`
    padding-right: 0;
    padding-left: 0;
  `}
        `
      : css`
          ${mediaQuery.greaterThan("1750px")`
    `}
        `}
`;

const PageMax = styled.div`
  width: 100%;
  min-height: calc(100% - 320px);
  ${media.lessThan("1100px")`
    min-height: calc(100% - 360px);
  `}
`;

const PageHeaderWrapper = styled.div`
  padding: 0px 80px;
  ${media.lessThan("991px")`
    padding: 0px;
  `}
`;

const FullWidthDiv = styled("div")`
  width: 100%;
  ${media.lessThan("991px")`
    padding-top: 12px;
  `}
`;

const StyledCol = styled(Col)`
  display: flex;
  justify-content: space-between;
`;

const StyledRow = styled(Row)`
  margin-left: 32px;
  display: flex;
  align-items: center;
  width: 100%;
  ${media.lessThan("991px")`
    margin-left: 0;
    padding: 0 12px;
  `}
`;

const StyledRowNoMargins = styled(Row)`
  ${media.lessThan("640px")`
    margin-left: 0 !important;
    margin-right: 0 !important;
  `}
`;

const NewStyledTab = styled(Link)<{ $active: boolean; $removeMarginLeft?: boolean; $isSettingsTab?: boolean }>`
  padding: 3px 12px;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: ${({ $active }) => ($active ? "#fff" : "rgba(25, 21, 64, 0.4)")};
  display: flex;
  align-items: center;
  text-align: center;
  gap: 10px;
  border: 1px solid rgb(123, 120, 147, 0.2);
  border-radius: 20px;
  background-color: ${({ $active, $isSettingsTab }) => ($active ? ($isSettingsTab ? "#7B7893" : "#4235E1") : "#fff")};
  margin: ${({ $removeMarginLeft }) => ($removeMarginLeft ? "0 8px 0 0" : "0 8px")};
  z-index: 11;
  position: relative;
  :hover {
    color: ${({ $active }) => ($active ? "#fff" : "rgba(25, 21, 64, 0.4)")};
  }
`;

const FlexDivCentered = styled("div")<{ $addMarginBottom?: boolean }>`
  display: flex;
  align-items: center;
  ${({ $addMarginBottom }) => $addMarginBottom && "margin-bottom: 12px;"}
  ${media.lessThan("991px")`
    flex-direction: column;
    align-items: start;
  `}
`;

const FlexDivMarginAuto = styled("div")`
  display: flex;
  margin: auto;
  ${media.lessThan("991px")`
    margin: 0;
    z-index: 9;
  `}
`;

const SettingsTitle = styled("span")`
  font-weight: 900;
  font-size: 22px;
  line-height: 28px;
  color: #000000;
  font-family: "MATROSKA";
  text-align: center;
  display: block;
  margin-bottom: 12px;
`;

const AbsoluteLink = styled(Link)`
  position: absolute;
  ${media.lessThan("991px")`
    position: relative;
    margin-bottom: 12px;
  `}
`;

const StyledArrowLeftIcon = styled(ArrowLeftThinIcon)`
  transition: transform 0.3s ease-out;
  width: 20px;
  height: 20px;
`;

const BackButton = styled.div`
  display: flex;
  cursor: pointer;
  a {
    height: 24px;
    display: flex;
  }
  span {
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    color: ${COLORS.primary.accent};
    display: inline-block;
    margin-left: 18px;
    ${media.lessThan("640px")`
       font-size: 14px;
       margin-left: 18px;
    `}
  }
  ${media.lessThan("640px")`
       margin-top: 16px;
       width: 100vw;
    `}
  &:hover {
    ${StyledArrowLeftIcon} {
      transform: translateX(-4px);
      color: ${COLORS.primary.accent};
    }
  }
`;

const BackToTopSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0 auto 64px;
  cursor: pointer;
`;

const BackToTopText = styled.div`
  font-weight: 300;
  font-size: 12px;
  line-height: 28px;
  color: ${COLORS.primary.grayDarkLightest};
  margin-top: 10px;
`;

const MobileBgWrapper = styled.div`
  ${media.greaterThan("991px")`
      display: none;
  `};
`;

export {
  Container,
  BackButton,
  AbsoluteLink,
  StyledArrowLeftIcon,
  SettingsTitle,
  FlexDivCentered,
  FlexDivMarginAuto,
  NewStyledTab,
  StyledCol,
  StyledRow,
  PageHeader,
  PageHeaderWrapper,
  PageMax,
  FullWidthDiv,
  BackToTopSection,
  BackToTopText,
  StyledRowNoMargins,
  PaddingContainer,
  MobileBgWrapper,
};
