import React, { useCallback, useContext } from "react";
import { Moment } from "moment";
import styled from "styled-components";
import { useLocation } from "react-router";

import { NavigationControl } from "./NavigationControl";
import { COLORS } from "../../../constants/colors";
import CalendarLegend from "../../common/CalendarLegend";
import { useMixpanel } from "../../../hooks";
import { CurrentAccountContext } from "../../../reducers/CurrentAccount";
import mediaQuery from "../../../media-query";
import { RoutePaths } from "../../../constants/Routes";
import { CalendarWithBoxesIcon } from "../../icons";
import { useWindowDimensions } from "../../../hooks/useWindowDimensions";

interface Props {
  date?: Moment;
  setSelectedDate?: Function;
  goToPreviousMonth?: () => void;
  goToNextMonth?: () => void;
  addLabel?: boolean;
  addPaddingTop?: boolean;
}

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 16px;
`;

const NavigationControlContainer = styled.div`
  margin: 0;
  display: flex;
  align-items: center;
`;

const MonthAndYearContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Month = styled.span`
  margin: 0;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: ${COLORS.primary.grayDark};
  margin-left: 8px;
`;

const Year = styled.span`
  font-weight: 200;
  font-size: 20px;
  line-height: 24px;
  color: #191540;
  padding-right: 20px;
  padding-left: 4px;
`;

const FirstLineWrapper = styled("div")<{ $addPaddingTop?: boolean }>`
  display: flex;
  width: 100%;
  padding-bottom: 16px;
  ${({ $addPaddingTop }) => $addPaddingTop && "padding-top: 8px;"}
  ${mediaQuery.lessThan("640px")`
    padding-top: 0;
    padding-bottom: 8px;
  `}
`;

const LegendWrapper = styled("div")`
  width: 33%;
  margin-left: auto;
`;

const Header = ({ date, goToPreviousMonth, goToNextMonth, addLabel = true, addPaddingTop }: Props) => {
  const { pathname } = useLocation();
  const { account } = useContext(CurrentAccountContext);
  const { trackNavigateMonths } = useMixpanel();
  const { width } = useWindowDimensions();
  const isMobile = width < 991;

  const NavigateNextMonth = useCallback(() => {
    trackNavigateMonths({
      userId: account,
      page: pathname === RoutePaths.feed ? "Portal" : `Protocol: ${pathname}`,
    });
    goToNextMonth && goToNextMonth();
  }, [trackNavigateMonths, goToNextMonth, account, pathname]);

  const NavigatePreviousMonth = useCallback(() => {
    trackNavigateMonths({
      userId: account,
      page: pathname === RoutePaths.feed ? "Portal" : `Protocol: ${pathname}`,
    });
    goToPreviousMonth && goToPreviousMonth();
  }, [trackNavigateMonths, goToPreviousMonth, account, pathname]);

  return (
    <Container style={{ paddingBottom: pathname === "/" ? "0" : "" }}>
      <FirstLineWrapper $addPaddingTop={addPaddingTop && !(pathname === "/")}>
        <NavigationControlContainer>
          <MonthAndYearContainer>
            <CalendarWithBoxesIcon width={20} height={20} color="#191540" />
            <Month>{date?.format(isMobile ? "MMM" : "MMMM")}</Month>
            <Year> {date?.format("YYYY")}</Year>
          </MonthAndYearContainer>
          <NavigationControl type="prev" onClick={NavigatePreviousMonth} />
          <NavigationControl type="next" onClick={NavigateNextMonth} />
        </NavigationControlContainer>
        {addLabel && (
          <LegendWrapper>
            <CalendarLegend />
          </LegendWrapper>
        )}
      </FirstLineWrapper>
    </Container>
  );
};

export default React.memo(Header);
