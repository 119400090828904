import * as React from "react";

const SvgParachuteOutlinedIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 8a7 7 0 1114 0 .488.488 0 01-.806.37c-.448-.383-.825-.658-1.133-.834-.319-.182-.517-.232-.621-.232-.104 0-.303.05-.621.232-.309.176-.686.451-1.133.835a.488.488 0 01-.663-.026C9.286 7.61 8.614 7.305 8 7.305c-.614 0-1.286.304-2.023 1.04a.488.488 0 01-.663.026c-.447-.384-.824-.659-1.133-.835-.318-.182-.517-.232-.62-.232-.105 0-.303.05-.622.232-.308.176-.685.451-1.133.835A.488.488 0 011 8zm1.072-1.071c.132-.09.26-.17.383-.241.372-.213.749-.36 1.105-.36.357 0 .734.147 1.106.36.29.165.605.387.947.661C6.362 6.696 7.159 6.328 8 6.328s1.638.368 2.387 1.021c.342-.274.658-.496.947-.661.372-.213.75-.36 1.106-.36.356 0 .733.147 1.105.36.123.07.25.15.383.241a6.025 6.025 0 00-11.856 0z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.465 7.541a.488.488 0 01.626.292l2.368 6.512a.488.488 0 01-.804.512L1.143 8.345a.488.488 0 01.69-.69L6.79 12.61 5.173 8.167a.488.488 0 01.292-.626z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.535 7.541a.488.488 0 01.292.626L9.21 12.61l4.955-4.955a.488.488 0 01.691.69l-6.512 6.512a.488.488 0 01-.804-.512l2.368-6.512a.488.488 0 01.626-.292z"
      fill="currentColor"
    />
  </svg>
);

export default SvgParachuteOutlinedIcon;
