import AntdTag from "antd/es/tag";
import React, { MouseEventHandler } from "react";
import styled from "styled-components";

import { COLORS } from "../constants/colors";
import media from "../media-query";
import { ThinCrossIcon } from "./icons";

interface UtilityTagProps {
  type: string;
  size?: "small" | "medium";
  style?: React.CSSProperties;
  label: string | number | React.ReactNode;
  borderRadius?: string;
}

interface ClosableTagProps {
  label: string;
  onClick?: () => void;
  closeable?: boolean;
  onClose?: () => void;
}

type TagProps = Omit<UtilityTagProps, "type"> & {
  onClick?: MouseEventHandler<HTMLSpanElement> & Function;
  outlined?: boolean;
  color: string;
};

const TagPrimaryColors: Record<string, string> = {
  accent: COLORS.primary.accent,
  red: COLORS.secondary.red,
  orange: COLORS.secondary.orange,
  yellow: COLORS.secondary.yellow,
  teal: COLORS.secondary.teal,
  blue: COLORS.secondary.blue,
  indigo: COLORS.secondary.indigo,
  pink: COLORS.secondary.pink,
  gray: COLORS.primary.gray,
};

const TagBorders: Record<string, string> = {
  accent: COLORS.primary.accentLight,
  red: COLORS.secondary.redLight,
  orange: COLORS.secondary.orangeLight,
  yellow: COLORS.secondary.yellowLight,
  teal: COLORS.secondary.tealLight,
  blue: COLORS.secondary.blueLight,
  indigo: COLORS.secondary.indigoLight,
  pink: COLORS.secondary.pinkLight,
  gray: COLORS.primary.grayLight,
};

const TagOutlinedBackgrounds: Record<string, string> = {
  accent: COLORS.primary.accentLighter,
  red: COLORS.secondary.redLighter,
  orange: COLORS.secondary.orangeLighter,
  yellow: COLORS.secondary.yellowLighter,
  teal: COLORS.secondary.tealLighter,
  blue: COLORS.secondary.blueLighter,
  indigo: COLORS.secondary.indigoLighter,
  pink: COLORS.secondary.pinkLighter,
  gray: COLORS.primary.grayLighter,
};

const UtilityTagBackgrounds: Record<string, string> = {
  active: COLORS.secondary.greenLighter,
  closed: COLORS.primary.grayLighter,
  noStatus: COLORS.primary.grayLighter,
  pending: COLORS.secondary.blueLighter,
};

const UtilityTagBorders: Record<string, string> = {
  active: COLORS.secondary.greenLight,
  closed: COLORS.primary.grayDarkLightest,
  noStatus: COLORS.primary.grayDarkLightest,
  pending: COLORS.secondary.blue,
};

const UtilityTagColors: Record<string, string> = {
  active: COLORS.secondary.greenDark,
  closed: COLORS.primary.grayDarkLightest,
  noStatus: COLORS.primary.grayDarkLightest,
  pending: COLORS.secondary.blueDark,
};

const StatusTagALias: Record<string, string> = {
  active: "active",
  approved: "active",
  executed: "active",
  canceled: "closed",
  expired: "closed",
  rejected: "closed",
  proposed: "pending",
  wip: "pending",
  "No status": "noStatus",
};

const StyledTag = styled(AntdTag)`
  border-radius: 40px;
  padding: ${({ size }: TagProps) => (size !== "medium" ? "5px 10px" : "10px 14px")};
  font-size: ${({ size }: TagProps) => (size !== "medium" ? "10px" : "12px")};
  border: ${({ outlined, color }: TagProps) => (outlined ? `1px solid ${TagBorders[color]}` : "none")};
  color: ${({ outlined, color }: TagProps) => (outlined ? TagPrimaryColors[color] : "white")};
  background: ${({ outlined, color }: TagProps) =>
    outlined ? TagOutlinedBackgrounds[color] : TagPrimaryColors[color]};
  font-weight: ${({ outlined }: TagProps) => (outlined ? 400 : 500)};
  line-height: ${({ size }: TagProps) => (size !== "medium" ? "13px" : "15px")};
  ${media.lessThan("640px")`
     padding: 4px 6px;
  `}
`;

const StyledUtilitytag = styled(AntdTag)`
  font-weight: 500;
  border-radius: ${({ borderRadius }) => (borderRadius ? borderRadius : "4px")};
  border: ${({ type }: UtilityTagProps) => `1px solid ${UtilityTagBorders[StatusTagALias[type]]}`};
  padding: ${({ size }: UtilityTagProps) => (size !== "medium" ? "4px" : "10px 14px")};
  font-size: ${({ size }: UtilityTagProps) => (size !== "medium" ? "10px" : "12px")};
  color: ${({ type }: UtilityTagProps) => UtilityTagColors[StatusTagALias[type]]};
  background: ${({ type }: UtilityTagProps) => UtilityTagBackgrounds[StatusTagALias[type]]};
  line-height: 15px;
  text-transform: capitalize;
  ${media.lessThan("640px")`
     padding: 4px 6px;
  `}
`;

const StyledClosableTag = styled(AntdTag)<{ closeable: boolean }>`
  background: #f5f5fe;
  border-radius: 8px;
  color: ${COLORS.primary.accent};
  font-size: 14px;
  line-height: 28px;
  border: 1px solid ${COLORS.primary.accent};
  padding: 0px 12px;
  align-self: center;
  text-align: center;
  cursor: ${({ closeable }) => (closeable ? "" : "pointer")};
`;

const StyledCrossIcon = styled(ThinCrossIcon)`
  width: 10px;
  height: 10px;
  margin-left: 6px;
  cursor: pointer;
`;

function Tag(props: TagProps) {
  return <StyledTag {...props}>{props.label}</StyledTag>;
}

function UtilityTag(props: UtilityTagProps) {
  return <StyledUtilitytag {...props}>{props.label}</StyledUtilitytag>;
}

function ClosableTag(props: ClosableTagProps) {
  const { label, closeable = false, onClick, onClose } = props;
  return (
    <StyledClosableTag onClick={onClick} closeable={closeable}>
      {label}
      {closeable && <StyledCrossIcon onClick={onClose} />}
    </StyledClosableTag>
  );
}

export { Tag, UtilityTag, ClosableTag };
export type { UtilityTagProps, TagProps };
