import notification from "antd/es/notification";
import React, { createContext, useReducer } from "react";
import Text from "antd/es/typography/Text";
import { toShortAddress } from "../utils";

interface NotificationsState {
  info?: string;
  success?: string;
  pending?: string;
  error?: string;
}

interface Action {
  type: string;
  payload: {
    message: string;
    confirmationNumber?: string;
    isOnChainVote?: boolean;
    chainId?: number;
  };
}

interface NotificationsContextType {
  state: NotificationsState;
  dispatch: React.Dispatch<any>;
}

interface Props {
  children: React.ReactNode;
}

export const NotificationsContext = createContext({} as NotificationsContextType);

const initialState: NotificationsState = {
  info: undefined,
  success: undefined,
  pending: undefined,
  error: undefined,
};

const formatExternalTransactionLink = (txnHash?: string, chainId?: number) => {
  if (chainId === 10) {
    return `https://optimistic.etherscan.io/tx/${txnHash}`;
  }
  return `https://etherscan.io/tx/${txnHash}`;
};

function reducer(_state: NotificationsState, action: Action): NotificationsState {
  switch (action.type) {
    case "onSuccess":
      notification.success({
        message: "Transaction Success",
        description: (
          <div>
            <Text>{action.payload.message}</Text>
            <br></br>
            <a
              href={
                action.payload.isOnChainVote
                  ? action.payload.chainId === 10
                    ? `https://optimistic.etherscan.io/tx/${action.payload.confirmationNumber}`
                    : action.payload.chainId === 42161
                    ? `https://arbiscan.io/tx/${action.payload.confirmationNumber}`
                    : `https://etherscan.io/tx/${action.payload.confirmationNumber}`
                  : `https://ipfs.io/ipfs/${action.payload.confirmationNumber}`
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              {toShortAddress(action.payload.confirmationNumber)}
            </a>
          </div>
        ),
        duration: 10,
      });

      return {
        success: action.payload.message,
      };
    case "onInfo":
      notification.info({
        key: action.payload.message,
        message: "Transaction Submitted",
        description: (
          <div>
            <Text>{action.payload.message}</Text>
            <br></br>
            <a
              href={
                action.payload.isOnChainVote
                  ? action.payload.chainId === 10
                    ? `https://optimistic.etherscan.io/tx/${action.payload.confirmationNumber}`
                    : action.payload.chainId === 42161
                    ? `https://arbiscan.io/tx/${action.payload.confirmationNumber}`
                    : `https://etherscan.io/tx/${action.payload.confirmationNumber}`
                  : `https://ipfs.io/ipfs/${action.payload.confirmationNumber}`
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              {toShortAddress(action.payload.confirmationNumber)}
            </a>
          </div>
        ),
        duration: 10,
      });

      return {
        success: action.payload.message,
      };

    case "onPending":
      notification.info({
        key: action.payload.message,
        message: "Transaction Submitted",
        description: <Text>{action.payload.message}</Text>,
        duration: 10,
      });

      return {
        pending: action.payload.message,
      };

    case "failedOnChain":
      notification.error({
        key: action.payload.message,
        message: "Transaction Failed",
        description: (
          <div>
            <br></br>
            <a
              href={formatExternalTransactionLink(action.payload.confirmationNumber, action.payload.chainId)}
              target="_blank"
              rel="noopener noreferrer"
            >
              {toShortAddress(action.payload.confirmationNumber)}
            </a>
          </div>
        ),
        duration: 0,
      });

      return {
        error: `Transaction with ID: ${action.payload.confirmationNumber} failed`,
      };

    case "successOnChain":
      notification.success({
        key: action.payload.message,
        message: "Transaction Success",
        description: (
          <div>
            <br></br>
            <a
              href={formatExternalTransactionLink(action.payload.confirmationNumber, action.payload.chainId)}
              target="_blank"
              rel="noopener noreferrer"
            >
              {toShortAddress(action.payload.confirmationNumber)}
            </a>
          </div>
        ),
        duration: 15,
      });

      return {
        error: `Transaction with ID: ${action.payload.confirmationNumber} is successful`,
      };

    case "onError":
      notification.error({
        key: action.payload.message,
        message: "Something Went Wrong",
        description: <Text>{action.payload.message}</Text>,
        duration: 6,
      });

      return {
        error: action.payload.message,
      };
    case "onSuccessOptimismDelegation":
      notification.info({
        key: action.payload.message,
        message: "Delegation Submitted",
        duration: 10,
        description: (
          <a
            href={`https://optimistic.etherscan.io/tx/${action.payload.confirmationNumber}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {toShortAddress(action.payload.confirmationNumber)}
          </a>
        ),
      });
      return {
        error: action.payload.message,
      };
    case "onSuccessArbitrumDelegation":
      notification.info({
        key: action.payload.message,
        message: "Delegation Submitted",
        duration: 10,
        description: (
          <a
            href={`https://arbiscan.io/tx/${action.payload.confirmationNumber}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {toShortAddress(action.payload.confirmationNumber)}
          </a>
        ),
      });
      return {
        error: action.payload.message,
      };
    default:
      throw new Error();
  }
}

export const NotificationsContextProvider = (props: Props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  return <NotificationsContext.Provider value={{ state, dispatch }}>{children}</NotificationsContext.Provider>;
};
