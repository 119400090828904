import React, { useReducer, createContext } from "react";
interface Props {
  children: React.ReactNode;
}

type Action = { type: "SAVE_IS_LANDING_PAGE"; data: boolean };

interface IsLandingPageType {
  isLandingPage: boolean;
  dispatchIsLandingPage: React.Dispatch<any>;
}

const initialState = false;

export const IsLandingPageContext = createContext({} as IsLandingPageType);

const reducer = (state: boolean, action: Action) => {
  switch (action.type) {
    case "SAVE_IS_LANDING_PAGE":
      return action.data;
    default:
      return state;
  }
};

export const IsLandingPageProvider = (props: Props) => {
  const [isLandingPage, dispatchIsLandingPage] = useReducer(reducer, initialState);

  return (
    <IsLandingPageContext.Provider value={{ isLandingPage, dispatchIsLandingPage }}>
      {props.children}
    </IsLandingPageContext.Provider>
  );
};
