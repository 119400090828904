import { useSelect, DownshiftState, UseSelectStateChangeOptions, UseSelectStateChange } from "downshift";
import { DropdownProps, Item } from "./types";

export const getSingleSelectConfig = (items: Item[], initialValue?: Item, onChange?: DropdownProps["onChange"]) => ({
  items,
  initialSelectedItem: initialValue,

  onSelectedItemChange: ({ selectedItem }: UseSelectStateChange<Item>) => onChange && onChange(selectedItem || null),
});

export const getMultiSelectConfig = (items: Item[], addItem: (item: Item) => void, initialValue?: Item) => ({
  items,
  initialSelectedItem: initialValue,
  stateReducer: (_state: DownshiftState<Item>, actionAndChanges: UseSelectStateChangeOptions<Item>) => {
    const { changes, type } = actionAndChanges;
    switch (type) {
      case useSelect.stateChangeTypes.MenuKeyDownEnter:
      case useSelect.stateChangeTypes.MenuKeyDownSpaceButton:
      case useSelect.stateChangeTypes.ItemClick:
        return {
          ...changes,
          isOpen: true,
        };
    }

    return changes;
  },
  onStateChange: ({ type, selectedItem }: UseSelectStateChange<Item>) => {
    switch (type) {
      case useSelect.stateChangeTypes.MenuKeyDownEnter:
      case useSelect.stateChangeTypes.MenuKeyDownSpaceButton:
      case useSelect.stateChangeTypes.ItemClick:
        if (selectedItem) {
          addItem(selectedItem);
        }
        break;
      default:
        break;
    }
  },
});
