import * as React from "react";

const SvgEyeIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" {...props}>
    <path
      d="M12 19c-1.64.021-3.263-.333-4.746-1.034a10.073 10.073 0 01-3.041-2.282A10.588 10.588 0 012.1 12.317L2 12l.105-.316a10.662 10.662 0 012.109-3.367 10.074 10.074 0 013.04-2.282A10.785 10.785 0 0112 5c1.64-.02 3.263.333 4.746 1.035 1.15.56 2.181 1.335 3.041 2.282a10.5 10.5 0 012.113 3.367L22 12l-.105.316A10.423 10.423 0 0112 19zm0-12a8.308 8.308 0 00-7.883 5A8.307 8.307 0 0012 17a8.309 8.309 0 007.883-5A8.3 8.3 0 0012 7zm0 8a3.02 3.02 0 112.115-.883A2.976 2.976 0 0112 15z"
      fill="currentColor"
    />
  </svg>
);

export default SvgEyeIcon;
