import React from "react";
import styled from "styled-components";
import { COLORS } from "../../../constants/colors";

const Table = styled.table`
  width: 100%;
  table-layout: fixed;
  border: 1px solid ${COLORS.primary.grayLight};
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  border-collapse: separate;
  border-bottom: 0;
`;

const Weekday = styled.th`
  padding: 6px 0;
  font-size: 15px;
  line-height: 22px;
  font-weight: 500;
  font-style: normal;
  color: ${COLORS.primary.gray};
  text-align: center;
`;

export const WeekdayHeading = () => (
  <Table>
    <thead>
      <Weekday>Sun</Weekday>
      <Weekday>Mon</Weekday>
      <Weekday>Tue</Weekday>
      <Weekday>Wed</Weekday>
      <Weekday>Thu</Weekday>
      <Weekday>Fri</Weekday>
      <Weekday>Sat</Weekday>
    </thead>
  </Table>
);
