import Row from "antd/es/row";
import Col from "antd/es/col";
import React, { useState, useCallback, useContext, useMemo, useEffect } from "react";
import moment from "moment";
import styled, { css } from "styled-components";
import { Link, useHistory, useLocation } from "react-router-dom";

import Header from "../../../components/Calendar/Monthly/Header";
import { CurrentAccountContext } from "../../../reducers/CurrentAccount";
import { TrackPageView } from "../../../components/TrackPageView";
import { proposalsAsEvents } from "../../ProtocolCalendar";
import { useAllProposals } from "../../../hooks/useAllProposals";
import { useVoter } from "../../../hooks/useVoter";
import { savedProtocolsToArray } from "../../../utils/savedProtocolsToArray";
import { useUserProtocols } from "../../../hooks/useUserProtocols";
import { useDelegateProtocolsByAddresses } from "../../../hooks/useDelegateVotingPowerByAddress";
import { CalendarFilters } from "../../../components/ProposalsFilters/ProposalsFilters";
import { CalendarListView, Modal } from "../../../components";
import { CurrentProjectsFilterContext } from "../../../reducers/CurrentProjectsFilter";
import { SettingsNotificationsModal } from "../Settings/SettingsNotifications";
import VotePowerCard from "../../../components/VoterProfile/VotePowerCard";
import { NotificationsCard } from "../../../components/MoreResourcesCard/MoreResourcesCard";
import mediaQuery from "../../../media-query";
import { useWindowDimensions } from "../../../hooks/useWindowDimensions";
import { CurrentRefIdContext } from "../../../reducers/CurrentRefId";
import { boardroomDiscordLink, boardRoomOrgGithubLink, boardroomTwitterLink } from "../../../constants/general";
import {
  DiscordIcon,
  GithubIcon,
  TwitterIcon,
  BoardroomFooterLogo,
  EyeIcon,
  ExpandNavRailIcon,
  ArrowRightIcon,
  ThinCrossIcon,
} from "../../../components/icons";
import MultipleAddressVotePowerCard from "./MultipleAddressVotePowerCard";
import { useSiwe } from "../../../hooks/useSiwe";
import { dateKeyFormat } from "../../../constants/date";
import useEvent from "../../../hooks/useEvent";
import { useHasVotePowerMultipleProtocols } from "../../../hooks/useVotePower";
import { getProposalMainStatus } from "../../../utils/getProposalTimeAgoText";
import { useGetTeam } from "../../../hooks/useGetTeam";
import { protocolOnchainInstances, protocols } from "../../../constants/protocols";
import { RoutePaths } from "../../../constants/Routes";

import { useVoterVotes } from "../../../hooks/useVoterVotes";
import { CurrentUserDetailsContext } from "../../../reducers/CurrentUserDetails";
import { mainGridStrucutre, pageHeaderHeight, mainGridOffset } from "../../../constants/styles";
import { COLORS } from "../../../constants/colors";
import { NftSubscriptionContext } from "../../../reducers/NftSubscription";
import { useHasValidNftSubscription } from "../../../hooks/useHasValidNftSubscription";

const standAloneProtocolCname = process.env.REACT_APP_STANDALONE_PROTOCOL;

const StyledRow = styled(Row)``;

const StyledDiv = styled.div<{ marginTop: string }>`
  margin-top: ${({ marginTop }) => marginTop};
`;

export const StyledCol = styled(Col)<{ $isSplitView?: boolean; $isTeamView?: boolean; $hasMintBanner?: boolean }>`
  position: sticky;
  align-self: flex-start;
  top: 0;
  background: #fafafa;
  padding-top: ${({ $isTeamView, $hasMintBanner }) => ($isTeamView ? "66px" : $hasMintBanner ? "48px" : "0px")};
  flex: 0 0 100%;
  max-width: 26%;
  height: calc(100vh - ${pageHeaderHeight});
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  ${({ $isTeamView }) =>
    $isTeamView &&
    `
  .vote-power-card-team {
    margin-top: 0;
  }
  `}
  ${({ $isSplitView }) => $isSplitView && "display: none; transition: 0.3s all;"}
  ${mediaQuery.lessThan("991px")`
    max-width: 100%;
    padding-right: 12px !important;
  `}
  ${mediaQuery.lessThan("1260px")`
    max-width: 33.3%;
  `}
`;

export const StickyDiv = styled.div<{ $isTeamView?: boolean; $hasMintBanner?: boolean }>`
  position: sticky;
  align-self: flex-start;
  z-index: 9;
  background: #fafafa;
  top: 0;
  padding-top: ${({ $isTeamView, $hasMintBanner }) => ($isTeamView ? "88px" : $hasMintBanner ? "76px" : "28px")};
  ${mediaQuery.lessThan("640px")`
  padding-top: 8px;
  `}
  ${({ $hasMintBanner }) =>
    $hasMintBanner &&
    css`
      ${mediaQuery.lessThan("640px")`
  padding-top: 56px;
  `}
    `}
`;

export const StyledColSplitView = styled(Col)<{ $isSplitView?: boolean; $width: number }>`
  height: calc(100vh - ${pageHeaderHeight});
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  border-right: 1px solid ${COLORS.primary.grayLighter};
  &::-webkit-scrollbar {
    display: none;
  }
  ${({ $isSplitView, $width }) =>
    $isSplitView &&
    $width <= 2100 &&
    $width > 1600 &&
    "transition: 0.3s all; margin-left: 0 !important; max-width: calc(45% + 120px);"}
  ${({ $isSplitView, $width }) =>
    $isSplitView && $width <= 1600 && $width > 991 && "transition: 0.3s all; max-width: calc(45% + 65px);"}
`;

const StyledGithubIcon = styled(GithubIcon)`
  width: 24px;
  height: 24px;
  cursor: pointer;
  transition: transform 0.3s ease-out;
  :hover {
    transform: scale(1.2);
  }
`;

const StyledTwitterIcon = styled(TwitterIcon)`
  width: 24px;
  height: 24px;
  cursor: pointer;
  transition: transform 0.3s ease-out;
  :hover {
    transform: scale(1.2);
  }
`;

const StyledDiscordIcon = styled(DiscordIcon)`
  width: 24px;
  height: 24px;
  cursor: pointer;
  transition: transform 0.3s ease-out;
  :hover {
    transform: scale(1.2);
  }
`;

const StyledFooterColumns = styled.div`
  font-weight: 400;
  font-size: 12px;
  color: #4235e1;
  width: 100%;
  gap: 12px;
  white-space: nowrap;
  display: flex;
  padding-top: 20px;
`;

const StyledFooterRows = styled.div`
  font-weight: 400;
  font-size: 12px;
  color: #4235e1;
  width: fit-content;
  gap: 12px;
  white-space: nowrap;
  display: flex;
  flex-direction: column;
`;

const StyledFooter = styled.div`
  padding-top: 16px;
  position: sticky;
  bottom: 0;
  padding-bottom: 32px;
  background: #fafafa;
  width: 100%;
  z-index: 5;
`;

export const StyledTeamTxt = styled.div`
  position: fixed;
  left: 0;
  z-index: 11;
  background: #f0eff8;
  color: #191540;
  top: 64px;
  transition: 0.3s all;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  padding: 12px 0;
  height: 64px;
`;

export const TeamFeedText = styled.span`
  font-weight: 400;
  padding-right: 24px;
  margin-left: 4px;
`;

export const ViewOnlyWrapper = styled.div`
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #7b7893;
  padding-left: 24px;
  border-left: 1px solid #7b7893;
  svg {
    margin-right: 8px;
  }
`;

const StyledDrawer = styled.div<{ $sideContentExpanded: boolean }>`
  display: none;
  position: absolute;
  -webkit-transition: all 0.5s ease-out;
  ${mediaQuery.between("991px", "1200px")`
    top: 0px;
    background: white;
    height: 100%;
    bottom: 0px;
    width: 368px;
    z-index: 10;
    padding: 16px 40px 0px 24px;
    border-left: 1px solid #F0EFF8;
    display: block;
  `};
  right: ${({ $sideContentExpanded }) => ($sideContentExpanded ? "0px" : "-300px")};
`;

const DrawerContentWrapper = styled.div<{ $hide: boolean }>`
  display: ${({ $hide }) => ($hide ? "block" : "none")};
`;

const MintBanner = styled.div`
  position: fixed;
  left: 0;
  z-index: 11;
  top: 64px;
  transition: 0.3s all;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  padding: 12px 0;
  height: 48px;
  background: linear-gradient(79deg, #191540 0%, #4235e1 51.04%, #191540 100%);
  width: 100%;
  cursor: pointer;
  color: white;
  padding: 4px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  :hover {
    color: #35c9f8;
    .joinText {
      border-color: #35c9f8;
      text-decoration: underline;
    }
  }
  ${mediaQuery.lessThan("991px")`
    top: 71px;
  `}
`;

const JoinBannerText = styled.span`
  font-size: 14px;
  font-weight: 300;
  line-height: 18px;
  padding-left: 4px;
  padding-right: 8px;
`;

const StyledImg = styled.img`
  width: 40px;
  margin-right: 8px;
`;

const StyledThinCrossIcon = styled(ThinCrossIcon)`
  position: absolute;
  right: 40px;
  ${mediaQuery.lessThan("991px")`
  right: 16px;
  `}
`;

export function FeedFooter() {
  return (
    <StyledFooter>
      <StyledFooterColumns style={{ borderTop: "1px solid #F0EFF8" }}>
        <StyledFooterRows>
          <a href="https://governance.substack.com/" target="_blank" rel="noopener">
            Subscribe
          </a>
          <a href={boardroomDiscordLink} target="_blank" rel="noopener">
            Contact Us
          </a>
        </StyledFooterRows>
        <StyledFooterRows>
          <a href="https://home.boardroom.io/developers/" target="_blank" rel="noopener">
            Developers
          </a>
          <a href="https://roadmap.boardroom.info/b/bugs/" target="_blank" rel="noopener">
            Submit an Issue
          </a>
        </StyledFooterRows>
        <StyledFooterRows>
          <a href="https://docs.boardroom.io/" target="_blank" rel="noopener">
            Documentation
          </a>
          <a
            href="https://www.notion.so/boardroom/Job-Board-433b71bbb7424eaf910d9791e7a4fbd2"
            target="_blank"
            rel="noopener"
          >
            Careers
          </a>
        </StyledFooterRows>
      </StyledFooterColumns>
      <StyledFooterColumns style={{ paddingRight: "20px" }}>
        <Link style={{ marginRight: "auto", display: "flex", alignItems: "end" }} to={RoutePaths.feed}>
          <BoardroomFooterLogo height={12} width={84} />
        </Link>
        <a style={{ display: "flex", alignItems: "center" }} href={boardRoomOrgGithubLink} target="_blank">
          <StyledGithubIcon width={20} height={20} color="#4235e1" />
        </a>
        <a style={{ display: "flex", alignItems: "center" }} href={boardroomTwitterLink} target="_blank">
          <StyledTwitterIcon width={20} height={20} color="#4235e1" />
        </a>
        <a style={{ display: "flex", alignItems: "center" }} href={boardroomDiscordLink} target="_blank">
          <StyledDiscordIcon width={20} height={20} color="#4235e1" />
        </a>
      </StyledFooterColumns>
    </StyledFooter>
  );
}

interface Props {
  isSelectable?: boolean;
}
// const protocolsWithVotePower: string[] = [];

const Calendar = ({ isSelectable = false }: Props) => {
  const [selectedDate, setSelectedDate] = useState(isSelectable ? moment() : moment().startOf("month"));
  const { account } = useContext(CurrentAccountContext);

  const { proposals, hasNextPage, fetchNextPage } = useAllProposals({
    limit: 1000,
    lite: true,
  });

  const [proposalTypeFilter, setProposalTypeFilter] = useState<"all" | "today" | "open" | "pending" | "closed">("all");
  const [savedOrVotedFilter, setSavedOrVotedFilter] = useState<"saved" | "voted" | "">("");
  const { userDetails } = useContext(CurrentUserDetailsContext);
  const savedProtocolsArray = savedProtocolsToArray(userDetails?.savedProtocols);
  const { projectsFilter } = useContext(CurrentProjectsFilterContext);
  const [votingSystemsFilter, setVotingSystemsFilter] = useState<"allFrameworks" | "snapshot" | "onchain">(
    "allFrameworks",
  );

  const [activeProtocols, setActiveProtocols] = useState([""]);
  const [dismissedNFTBanner, setDismissedNFTBanner] = useState(false);

  const { voter } = useVoter({ address: account || "", suspense: false });
  const { votes } = useVoterVotes({ address: account, suspense: false });
  const { protocolsInWallet, status: userProtocolsQueryStatus } = useUserProtocols({
    address: account,
    suspense: false,
  });

  const { search } = useLocation();
  const [sideContentExpanded, setSideContentExpanded] = useState(false);
  const query = new URLSearchParams(search);
  const teamId = query.get("bundle");
  const notificationsModal = Boolean(query.get("notificationsModal"));
  const proposalIdInUrl = query.get("proposalId");
  const teamDetails = useGetTeam(teamId || "");
  const teamSavedProtocolsArray = savedProtocolsToArray(teamDetails?.savedProtocols);
  const history = useHistory();

  const teamWallets = teamDetails?.orgWallets;
  const wallets = useMemo(
    () => (teamId && teamWallets?.length ? teamWallets : [account]),
    [teamWallets, account, teamId],
  );
  const { hasValidKey } = useContext(NftSubscriptionContext);

  const { protocols: delegateProtocols } = useDelegateProtocolsByAddresses({ addresses: wallets, suspense: false });
  const [isNotificationsModalOpen, setIsNotificationsModalOpen] = useState(notificationsModal ?? false);
  const { width } = useWindowDimensions();
  const isMobile = width <= 991;
  const { currentRefId, setCurrentRefId } = useContext(CurrentRefIdContext);
  useSiwe({ trigger: isNotificationsModalOpen });

  useHasValidNftSubscription(account);
  const { protocolsWithVotePower, status: votePowerQueryStatus } = useHasVotePowerMultipleProtocols(
    Array.from(new Set([...activeProtocols, ...(delegateProtocols || [])])),
    account,
  );
  const votesMap = useMemo(() => {
    const votesMap = new Map<string, boolean>();
    votes?.forEach((vote) => {
      if (vote?.proposalRefId) {
        votesMap.set(vote?.proposalRefId, true);
      }
    });
    return votesMap;
  }, [votes]);

  useEffect(() => {
    setCurrentRefId("");
  }, [proposalTypeFilter, votingSystemsFilter, projectsFilter, setCurrentRefId]);

  const toggleSideContentExpand = useCallback(() => {
    setSideContentExpanded(!sideContentExpanded);
  }, [sideContentExpanded]);

  useEffect(() => {
    if (proposalIdInUrl) {
      setCurrentRefId(proposalIdInUrl);
    }
  }, [proposalIdInUrl, setCurrentRefId]);

  const resetProposalTypeFilter = useEvent((date: moment.Moment) => {
    const currentDate = moment();
    const isSameMonth = currentDate.isSame(date, "month");
    if (!(proposalTypeFilter === "today" || proposalTypeFilter === "open") || !isSameMonth) {
      setProposalTypeFilter("all");
    }
  });

  // triggered when navigating months
  useEffect(() => {
    resetProposalTypeFilter(selectedDate);
  }, [selectedDate, resetProposalTypeFilter]);

  useEffect(() => {
    if (proposalTypeFilter === "today" || proposalTypeFilter === "open") {
      setSelectedDate(moment());
    }
  }, [proposalTypeFilter]);

  useEffect(() => {
    if (teamId && savedOrVotedFilter === "saved") {
      setSavedOrVotedFilter("");
    }
  }, [savedOrVotedFilter, teamId]);

  const goToPreviousMonth = useCallback(() => {
    setSelectedDate(moment(selectedDate).startOf("month").subtract(1, "month"));
    if (hasNextPage) fetchNextPage();
  }, [selectedDate, setSelectedDate, fetchNextPage, hasNextPage]);

  const goToNextMonth = useCallback(() => {
    setSelectedDate(moment(selectedDate).startOf("month").add(1, "month"));
  }, [selectedDate, setSelectedDate]);

  const protocolsArray = useMemo(() => Object.values(protocols), []);

  useEffect(() => {
    if (voter?.protocols && activeProtocols?.length === 1 && activeProtocols[0] === "") {
      const activeProtocols = voter.protocols.map((protocol) => {
        const activeProtocol = protocolsArray.find((protocolInArray) => protocolInArray.cname === protocol.protocol);
        return activeProtocol?.cname || "";
      });
      setActiveProtocols(activeProtocols.filter((protocol: string) => protocol));
    }
  }, [voter?.protocols, activeProtocols, protocolsArray]);

  useEffect(() => {
    setActiveProtocols([""]);
  }, [account]);

  const utcOffset = moment().utcOffset();
  const currentDate = useMemo(() => moment(), []);
  const dateKey = moment.utc(currentDate).utcOffset(utcOffset).format(dateKeyFormat);
  const proposalData = useMemo(() => {
    const bookmarkedProposals = userDetails?.bookmarkedProposals?.split(",") || [];
    const bookmarkedMap = new Map<string, boolean>();
    bookmarkedProposals?.forEach((proposalRefId) => {
      if (proposalRefId) {
        bookmarkedMap.set(proposalRefId, true);
      }
    });
    return proposals
      ?.filter((proposal) => {
        if (projectsFilter === "custom" && !teamWallets?.length && savedProtocolsArray.length) {
          return savedProtocolsArray.includes(proposal.protocol);
        } else if (
          projectsFilter === "custom" &&
          !teamWallets?.length &&
          !savedProtocolsArray.length &&
          (protocolsWithVotePower.length ||
            protocolsInWallet.length ||
            (userProtocolsQueryStatus === "success" && votePowerQueryStatus === "success"))
        ) {
          return [...(protocolsWithVotePower || []), ...(protocolsInWallet || [])].includes(proposal.protocol);
        } else if (projectsFilter === "custom" && teamWallets?.length && teamSavedProtocolsArray?.length) {
          return teamSavedProtocolsArray.includes(proposal.protocol);
        } else {
          return true;
        }
      })
      .filter((proposal) => {
        if (votingSystemsFilter === "allFrameworks") {
          return true;
        } else if (proposal.adapter === "snapshot" && votingSystemsFilter === "snapshot") {
          return true;
        } else if (
          protocolOnchainInstances[proposal.protocol].includes(proposal.adapter) &&
          votingSystemsFilter === "onchain"
        ) {
          return true;
        } else if (
          proposal.adapter === "archive" &&
          votingSystemsFilter === "onchain" &&
          !["babydogearmy", "ybaby"].includes(proposal.protocol)
        ) {
          return true;
        } else if (
          proposal.adapter === "archive" &&
          votingSystemsFilter === "snapshot" &&
          ["babydogearmy", "ybaby"].includes(proposal.protocol)
        ) {
          return true;
        }
      })
      .filter((proposal) => {
        if (savedOrVotedFilter === "") {
          return true;
        } else if (bookmarkedMap.has(proposal?.refId) && savedOrVotedFilter === "saved") {
          return true;
        } else if (votesMap.has(proposal?.refId) && savedOrVotedFilter === "voted") {
          return true;
        }
      })
      .filter((proposal) => {
        const endKey = moment(proposal.endTimestamp * 1000).format(dateKeyFormat);
        if (proposalTypeFilter === "all") {
          return true;
        } else if (proposalTypeFilter === "today" && endKey === dateKey) {
          return true;
        } else if (proposalTypeFilter === "open" && getProposalMainStatus(proposal.currentState) === "active") {
          return true;
        } else if (proposalTypeFilter === "pending" && getProposalMainStatus(proposal.currentState) === "pending") {
          return true;
        } else if (
          proposalTypeFilter === "closed" &&
          (getProposalMainStatus(proposal.currentState) === "closed" ||
            getProposalMainStatus(proposal.currentState) === "executed")
        ) {
          return true;
        }
      })
      .sort((a, b) => b.endTimestamp - a.endTimestamp)
      .sort((a) => (a.currentState === "active" ? -1 : 1))
      .filter(Boolean);
  }, [
    userDetails?.bookmarkedProposals,
    proposals,
    projectsFilter,
    teamWallets?.length,
    savedProtocolsArray,
    protocolsWithVotePower,
    protocolsInWallet,
    userProtocolsQueryStatus,
    votePowerQueryStatus,
    teamSavedProtocolsArray,
    votingSystemsFilter,
    savedOrVotedFilter,
    votesMap,
    proposalTypeFilter,
    dateKey,
  ]);

  const proposalEvents = useMemo(() => {
    return proposalsAsEvents({
      proposals: proposalData!,
      removeProposalStart:
        proposalTypeFilter === "today" || proposalTypeFilter === "open" || proposalTypeFilter === "closed",
      removeProposalEnd: proposalTypeFilter === "pending",
    });
  }, [proposalData, proposalTypeFilter]);

  const handleCloseModal = useCallback(() => {
    setIsNotificationsModalOpen(false);
  }, []);

  const handleOpenModal = useCallback(() => {
    setIsNotificationsModalOpen(true);
  }, []);

  const protocolsToAdd = useMemo(
    () => [...(protocolsWithVotePower || []), ...(protocolsInWallet || [])],
    [protocolsInWallet, protocolsWithVotePower],
  );

  const routeToNftModal = useCallback(() => {
    history.push("/discuss?nftModal=true");
  }, [history]);

  return (
    <>
      {teamWallets && account ? (
        <StyledTeamTxt>
          {teamDetails.orgName} <TeamFeedText>Feed</TeamFeedText>{" "}
          {activeProtocols?.length === 0 && protocolsToAdd?.length === 0 && (
            <ViewOnlyWrapper>
              <EyeIcon width={20} height={20} /> View Only
            </ViewOnlyWrapper>
          )}
        </StyledTeamTxt>
      ) : (
        <></>
      )}
      {!teamWallets && account && !hasValidKey && !dismissedNFTBanner ? (
        <MintBanner onClick={routeToNftModal}>
          <StyledImg src={`${process.env.PUBLIC_URL}/assets/boardroomCard.png`} />
          <JoinBannerText className="joinText">Mint Your Boardroom Access Card</JoinBannerText> <ArrowRightIcon />
          <StyledThinCrossIcon
            onClick={(e) => {
              e.stopPropagation();
              setDismissedNFTBanner(true);
            }}
          />
        </MintBanner>
      ) : (
        <></>
      )}
      <TrackPageView name={"Dashboard Calendar"} />
      <StyledRow style={{ marginLeft: "0", marginRight: "0" }} justify="start" gutter={[48, 48]}>
        <StyledColSplitView
          sm={24}
          md={22}
          xl={{ span: mainGridStrucutre(!!currentRefId), offset: mainGridOffset(!!currentRefId) }}
          $width={width}
          $isSplitView={!!currentRefId}
        >
          <StickyDiv
            $hasMintBanner={!!(!teamWallets && account && !hasValidKey)}
            $isTeamView={!!teamWallets && !!account}
          >
            <Header
              addPaddingTop
              date={selectedDate}
              goToPreviousMonth={goToPreviousMonth}
              goToNextMonth={goToNextMonth}
              addLabel={false}
              setSelectedDate={setSelectedDate}
            />
            <CalendarFilters
              tab={proposalTypeFilter}
              onTabClick={setProposalTypeFilter}
              votingSystems={votingSystemsFilter}
              setVotingSystemsFilter={setVotingSystemsFilter}
              margin={isMobile ? "0 8px 0 0" : "0 16px 0 0"}
              savedOrVotedFilter={savedOrVotedFilter}
              setSavedOrVotedFilter={setSavedOrVotedFilter}
            />
          </StickyDiv>

          <StyledDiv marginTop="20px" />
          <CalendarListView
            proposalTypeFilter={proposalTypeFilter}
            proposalData={proposalData}
            proposalEvents={proposalEvents}
            selectedDate={selectedDate}
          />
        </StyledColSplitView>
        <StyledCol
          $hasMintBanner={!!(!teamWallets && account && !hasValidKey)}
          $isTeamView={!!teamWallets && !!account}
          sm={24}
          xl={8}
          style={{ paddingTop: !!teamWallets && !!account ? "136px" : "76px", zIndex: "8" }}
        >
          {!teamWallets && !standAloneProtocolCname ? (
            <>
              <NotificationsCard protocolsToAdd={protocolsToAdd} onClick={handleOpenModal} />
            </>
          ) : (
            <></>
          )}
          {teamWallets ? (
            <MultipleAddressVotePowerCard removeMarginTop wallets={wallets} />
          ) : (
            <VotePowerCard removeMarginTop={!!standAloneProtocolCname} address={account} />
          )}
          <StyledDiv marginTop="32px" />
          <FeedFooter />
        </StyledCol>
      </StyledRow>
      <StyledDrawer $sideContentExpanded={sideContentExpanded}>
        <ExpandNavRailIcon
          style={{ marginBottom: "16px" }}
          width={24}
          height={24}
          color="#7B7893"
          onClick={toggleSideContentExpand}
        />
        <DrawerContentWrapper $hide={sideContentExpanded}>
          {!teamWallets && !standAloneProtocolCname ? (
            <>
              <NotificationsCard protocolsToAdd={protocolsToAdd} onClick={handleOpenModal} />
            </>
          ) : (
            <></>
          )}
          {teamWallets ? (
            <MultipleAddressVotePowerCard removeMarginTop wallets={wallets} />
          ) : (
            <VotePowerCard removeMarginTop={!!standAloneProtocolCname} address={account} />
          )}
        </DrawerContentWrapper>
      </StyledDrawer>
      <Modal
        customMinWidth={isMobile ? "0" : undefined}
        customMaxHeight={isMobile ? "650px" : undefined}
        zIndex={20}
        removePaddingBottom
        size="large"
        open={isNotificationsModalOpen}
        onClose={handleCloseModal}
      >
        <SettingsNotificationsModal />
      </Modal>
    </>
  );
};

export default React.memo(Calendar);
