import React, { useMemo } from "react";
import styled from "styled-components";
import { PropGetters } from "downshift";
import { useHover } from "use-events";
import { ThinChevronUpIcon } from "../icons";
import { COLORS } from "../../constants/colors";

export interface Item {
  name: string;
  value: string;
}

interface PropsDropdown<T> {
  label: React.ReactNode;
  items: Item[];
  initialValue?: T;
  placeholder?: React.ReactNode;
  prefix?: React.ReactNode;
  icon?: React.FC<React.SVGProps<SVGSVGElement>>;
  onChange?: (selectedItem: T | null) => void;
}

export type DropdownProps = PropsDropdown<Item>;

interface Props {
  selected: Item | Item[] | null;
  isOpen: boolean;
  placeholder?: React.ReactNode;
  prefix?: React.ReactNode;
  icon?: React.FC<React.SVGProps<SVGSVGElement>>;
  getButtonProps: PropGetters<Item>["getToggleButtonProps"];
}

export const Button = styled.button<{ isOpen: boolean }>`
  padding: 8px 10px;
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;
  text-align: left;
  background: #fff;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ isOpen }) => (isOpen ? COLORS.primary.accent : COLORS.primary.grayDarkLightest)};
  border-radius: 4px;
  font-size: 14px;
  line-height: 16px;
  font-style: normal;
  font-weight: normal;
  color: ${({ isOpen }) => (isOpen ? COLORS.primary.grayDarkLight : COLORS.primary.grayDarkLightest)};
  outline: 0;
  transition: all 0.2s;

  :hover {
    border-color: ${COLORS.primary.accent};
    color: ${COLORS.primary.grayDark};
    cursor: pointer;
  }
  :active,
  :focus {
    border-color: ${COLORS.primary.accent};
    color: ${COLORS.primary.grayDark};
    .arrow-container {
      background: #f0eff8;
      border-radius: 30px;
    }
  }
`;

export const ButtonText = styled.span`
  margin-right: auto;
  width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const InputIconContainer = styled.span`
  margin-right: 8px;
  line-height: 0;
`;

export const ArrowIconContainer = styled.div<{ isOpen: boolean }>`
  transform: rotate(${({ isOpen }) => (isOpen ? "0" : "180deg")});
  transition: transform 0.2s;
  margin-left: 5px;
  display: flex;
  align-items: center;
`;

export const DropdownButton = ({
  selected,
  isOpen,
  placeholder = "",
  prefix = "",
  icon: Icon,
  getButtonProps,
}: Props) => {
  const [isHovered, bind] = useHover();

  const buttonText = useMemo(() => {
    if (Array.isArray(selected)) {
      return selected.length > 0 ? selected.map((item) => item.name).join(", ") : placeholder;
    }

    return selected?.name || placeholder;
  }, [placeholder, selected]);

  const inputIconColor = isOpen || isHovered ? COLORS.primary.accent : COLORS.primary.grayDarkLightest;
  const arrowIconColor = isOpen || isHovered ? COLORS.primary.accent : COLORS.primary.grayDarkLightest;

  return (
    <Button isOpen={isOpen} {...getButtonProps()} {...bind}>
      {Icon && (
        <InputIconContainer>
          <Icon color={inputIconColor} width={19} height={19} />
        </InputIconContainer>
      )}

      <ButtonText>
        {prefix}
        {buttonText}
      </ButtonText>

      <ArrowIconContainer className="arrow-container" isOpen={isOpen}>
        <ThinChevronUpIcon width={20} height={20} color={arrowIconColor} />
      </ArrowIconContainer>
    </Button>
  );
};
