import * as React from "react";

const SvgToggleOffIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 6a2.167 2.167 0 000 4.333h8A2.167 2.167 0 1012 6H4zM.833 8.167A3.167 3.167 0 014 5h8a3.167 3.167 0 010 6.333H4A3.167 3.167 0 01.833 8.167z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.833 8.167a1.167 1.167 0 112.334 0 1.167 1.167 0 01-2.333 0zM4 8a.167.167 0 100 .333A.167.167 0 004 8zM12 8.53l.823.824.354-.354-.823-.823.823-.823L12.823 7 12 7.823 11.177 7l-.354.354.823.823-.823.823.354.354L12 8.53z"
      fill="currentColor"
    />
  </svg>
);

export default SvgToggleOffIcon;
