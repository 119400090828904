import Alert from "antd/es/alert";
import Card from "antd/es/card";
import Space from "antd/es/space";
import Typography from "antd/es/typography";
import Notify from "bnc-notify";
import React, { useContext, useState, useCallback, useEffect, Suspense } from "react";
import { useCurrentWidth } from "react-socks";
import { useSetChain } from "@web3-onboard/react";
import notification from "antd/es/notification";
import styled from "styled-components";

import { NotificationsContext } from "../../contexts";
import { useMixpanel } from "../../hooks";
import { useDelegateVotingPower } from "../../hooks/useDelegateVotingPower";
import { Input } from "../Input";
import { Button } from "../Button";
import { RowOfTwo } from "../Shared.styles";
import { Modal } from "../Modal";
import { CurrentAccountContext } from "../../reducers/CurrentAccount";
import { useAdapterFramework } from "../../hooks/useAdapterFramework";
import { aaveAdapterProtocols, protocols } from "../../constants/protocols";
import { updateDelegation } from "../PendingTransactionsStatus/PendingDelegationToast";
import { useDelegatesFromAddress } from "../../hooks/useDelegatesFromAddress";
import { CurrentTxHashContext } from "../../reducers/CurrentTxHash";
import { useIsMultisigSignIn } from "../../hooks/useIsMultisigSignIn";
import { useSdkWithoutSigner } from "../../hooks/useSdkWithoutSigner";
import { useIsEmailSignIn } from "../../hooks/useIsEmailSignIn";
import { useIsAccountConnectedWallet } from "../../hooks/useIsAccountConnectedWallet";
import { useOnboardWallet } from "../../hooks/useOnboardWallet";
import { WalletIcon } from "../icons";
import { COLORS } from "../../constants/colors";
import { getConnectedChainId } from "../../utils/checkChain";
import { checkAdapterFrameworkToUse } from "../../hooks/useVotePowerAdapter";
import { useQueryClient } from "@tanstack/react-query";
import { VotingPowerCardIsLoadingContext } from "../../reducers/VotingPowerCard";

const ConnectWalletWrapper = styled.div<{ $removeMarginRight?: boolean }>`
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  border: 1px solid #f0eff8;
  box-shadow: 0px 2px 6px rgba(7, 3, 40, 0.05);
  border-radius: 6px;
  width: 100%;
  height: 40px;
  color: #9e97f3;
  background: white;
  :hover {
    color: ${COLORS.primary.accent};
  }
`;

const ConnectWalletText = styled.span`
  margin-left: 8px;
`;

const { Text, Title } = Typography;

interface DelegateOtherProps {
  handleDelegate: any;
  protocolName: any;
  address?: string;
}

function DelegateOther({ handleDelegate, protocolName, address }: DelegateOtherProps) {
  const { account } = useContext(CurrentAccountContext);
  const [value, setValue] = useState<string>(address || "");
  const protocol = protocols[protocolName];
  const path = protocol.path;
  const wallet = useOnboardWallet();

  const handleChange = useCallback(
    (val: string) => {
      setValue(val);
    },
    [setValue],
  );

  const handleClickDelegate = useCallback(() => {
    handleDelegate(value);
  }, [handleDelegate, value]);

  return (
    <Space direction="vertical">
      <Title level={5}>Input an Address</Title>
      <Text>
        If you know the address you wish to delegate to, enter it below. If not, you can browse the delegates page to
        find one.{" "}
      </Text>

      <RowOfTwo content="Browse Delegates" link={`/${path}/delegates`} title="Delegate Address" />

      <Input
        value={value}
        placeholder="Enter an address"
        onChange={(e) => handleChange(e.target.value)}
        name="wallet-address"
      />

      {account ? (
        <Button onClick={handleClickDelegate} style={{ width: "100%" }} type="primary" disabled={!value}>
          Delegate Votes
        </Button>
      ) : (
        <ConnectWalletWrapper
          onClick={() => {
            wallet?.openWalletModal();
          }}
        >
          <WalletIcon height={20} width={20} /> <ConnectWalletText>Connect Wallet to Delegate</ConnectWalletText>
        </ConnectWalletWrapper>
      )}
    </Space>
  );
}

interface Props {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  address?: string;
  protocolName: string;
  updateAddressFunc?: any;
  disableSelfDelegation?: boolean;
  zIndex?: number;
}

function DelegationModal({
  visible,
  setVisible,
  address,
  protocolName,
  updateAddressFunc,
  disableSelfDelegation,
  zIndex,
}: Props) {
  const currentWidth = useCurrentWidth();
  const { dispatch } = useContext(NotificationsContext);
  const { account } = useContext(CurrentAccountContext);
  const { dispatchTxHash } = useContext(CurrentTxHashContext);
  const { delegateVotingPower } = useDelegateVotingPower(protocolName);
  const { adapterFramework } = useAdapterFramework(protocolName);
  const [framework, setFramework] = useState<string>("");
  const [{ connectedChain }, setChain] = useSetChain();
  const queryClient = useQueryClient();

  const [shouldShowDelegateOther, setShowDelegateOther] = useState(false);
  const { trackCancelSetupDelegation } = useMixpanel();
  const { refetch } = useDelegatesFromAddress({ address: account });
  const isMultisigSignIn = useIsMultisigSignIn();
  const IsEmailSignIn = useIsEmailSignIn();
  const isAccountConnectedWallet = useIsAccountConnectedWallet();
  const { dispatchVotingPowerCardIsLoading } = useContext(VotingPowerCardIsLoadingContext);
  //check if delegation exists chore
  const sdk = useSdkWithoutSigner();
  let protocolInSdk;
  try {
    protocolInSdk = sdk?.getProtocol(protocolName);
  } catch (error) {
    console.error(error);
  }
  const adapterInstances = protocolInSdk?.adapterInstances("delegation");

  useEffect(() => {
    async function checkChainId() {
      const chainIdForProtocol = await getConnectedChainId({
        protocolCname: protocolName,
        adapterType: "delegation",
      });
      if (chainIdForProtocol && chainIdForProtocol !== connectedChain?.id) {
        await setChain({ chainId: chainIdForProtocol });
        notification.success({
          message: "Pushed a request to your wallet... Please switch networks to delegate",
          placement: "topLeft",
          duration: 6,
        });
      }
    }
    if (account && visible && isAccountConnectedWallet) {
      checkChainId();
    }
  }, [account, connectedChain?.id, protocolName, setChain, visible, isAccountConnectedWallet]);

  const closeSetupDelegationModal = useCallback(() => {
    setVisible(false);
    setFramework("");
    setShowDelegateOther(false);

    trackCancelSetupDelegation({
      protocol: protocolName,
      userId: `${account}`,
    });
  }, [account, protocolName, trackCancelSetupDelegation, setVisible]);

  const updateVotePower = useCallback(
    async (blockNumber?: number) => {
      const protocolInSdk = sdk?.getProtocol(protocolName);
      const adapterFramework = protocolInSdk?.adapterInstances("votePower");
      const adapterFrameworkToBeUsed = checkAdapterFrameworkToUse(adapterFramework);
      const chainId = await protocolInSdk?.adapter("votePower", adapterFrameworkToBeUsed).getChainId();
      if (blockNumber) {
        let isChecking = false;
        const interval = setInterval(() => {
          const checkBlocks = async () => {
            if (isChecking) {
              return;
            }

            isChecking = true;

            try {
              const rpc = sdk?.transports.get("rpc").network(chainId || 1);
              const latestBlock = await rpc?.getBlockNumber();
              if (latestBlock && blockNumber < latestBlock) {
                clearInterval(interval);
                queryClient.removeQueries([`voterPower:${protocolName}:${account}`]);
                dispatchVotingPowerCardIsLoading({
                  type: "SHOW_LOADER",
                  data: false,
                });
              }
            } catch (error) {
              console.log(error);
            } finally {
              isChecking = false;
            }
          };
          checkBlocks();
        }, 3000);
      } else {
        setTimeout(
          () => {
            const removeVpQuery = () => {
              queryClient.removeQueries([`voterPower:${protocolName}:${account}`]);
              dispatchVotingPowerCardIsLoading({
                type: "SHOW_LOADER",
                data: false,
              });
            };
            removeVpQuery();
          },
          chainId === 5 ? 15000 : 0,
        );
      }
    },
    [account, dispatchVotingPowerCardIsLoading, protocolName, queryClient, sdk],
  );

  const handleDelegate = useCallback(
    async (delegationTarget: string, frameworkParam?: string) => {
      const chainIdForProtocol = await getConnectedChainId({
        protocolCname: protocolName,
        adapterType: "delegation",
      });

      if (connectedChain?.id !== chainIdForProtocol && isAccountConnectedWallet) {
        return;
      }

      if (isMultisigSignIn) {
        dispatch({
          type: "onError",
          payload: { message: "Please connect the multisig wallet to delegate." },
        });
        return;
      }
      if (IsEmailSignIn) {
        dispatch({
          type: "onError",
          payload: { message: "Please connect the wallet to delegate." },
        });
        return;
      }
      try {
        if (!delegationTarget) {
          return;
        }

        const hash = await delegateVotingPower(delegationTarget, frameworkParam || framework);
        const chainId = (await sdk?.getProtocol(protocolName).adapter("delegation", frameworkParam)?.getChainId()) || 1;
        // Blocknative Options
        const options = {
          dappId: process.env.REACT_APP_BLOCKNATIVE_API_KEY!,
          networkId: chainId,
        };

        // initialize and connect to the api
        const notify = Notify(options);
        if (hash) {
          dispatchTxHash({
            type: "SAVE_TXHASH",
            data: {
              txHash: hash,
              protocolName,
            },
          });
          const { emitter } = notify.hash(hash);
          emitter.on("txConfirmed", (txnDetails) => {
            dispatchVotingPowerCardIsLoading({
              type: "SHOW_LOADER",
              data: true,
            });
            updateVotePower(txnDetails.blockNumber);
          });
          setVisible(false);
          await updateDelegation(
            {
              userAddress: account,
              adapter: framework || (frameworkParam as string),
              delegateAddress: delegationTarget,
              protocol: protocolName,
            },
            refetch,
            updateAddressFunc,
          );
        } else {
          dispatch({
            type: "onError",
            payload: { message: "Cannot delegate. If you think this is a bug please report to us on Discord." },
          });
        }
      } catch (error) {
        const errMessage =
          JSON.parse(JSON.stringify(error))?.reason ||
          "Cannot delegate. If you think report this is a bug please report to us on Discord.";
        console.error(error);
        dispatch({
          type: "onError",
          payload: {
            message: errMessage === "resolver or addr is not configured for ENS name" ? "Invalid Address" : errMessage,
          },
        });
      }
    },
    [
      protocolName,
      connectedChain?.id,
      isAccountConnectedWallet,
      isMultisigSignIn,
      IsEmailSignIn,
      dispatch,
      delegateVotingPower,
      framework,
      sdk,
      dispatchTxHash,
      setVisible,
      account,
      refetch,
      updateAddressFunc,
      dispatchVotingPowerCardIsLoading,
      updateVotePower,
    ],
  );

  const handleDelegateSelf = useCallback(async () => {
    const chainIdForProtocol = await getConnectedChainId({
      protocolCname: protocolName,
      adapterType: "delegation",
    });
    if (chainIdForProtocol && chainIdForProtocol !== connectedChain?.id) {
      await setChain({ chainId: chainIdForProtocol });
      notification.success({
        message: "Pushed a request to your wallet... Please switch networks to delegate",
        placement: "topLeft",
        duration: 6,
      });
    }

    account &&
      handleDelegate(
        account,
        adapterInstances?.includes("onchain")
          ? "onchain"
          : adapterInstances?.includes("onchain-optimism")
          ? "onchain-optimism"
          : adapterInstances?.includes("onchain-arbitrum")
          ? "onchain-arbitrum"
          : undefined,
      );
  }, [protocolName, connectedChain?.id, account, handleDelegate, adapterInstances, setChain]);

  const handleDelegateOther = useCallback(
    async (delegationTarget: string) => {
      const chainIdForProtocol = await getConnectedChainId({
        protocolCname: protocolName,
        adapterType: "delegation",
      });
      if (chainIdForProtocol && chainIdForProtocol !== connectedChain?.id) {
        await setChain({ chainId: chainIdForProtocol });
        notification.success({
          message: "Pushed a request to your wallet... Please switch networks to delegate",
          placement: "topLeft",
          duration: 6,
        });
      }
      account &&
        handleDelegate(
          delegationTarget,
          adapterInstances?.includes("onchain")
            ? "onchain"
            : adapterInstances?.includes("onchain-optimism")
            ? "onchain-optimism"
            : adapterInstances?.includes("onchain-arbitrum")
            ? "onchain-arbitrum"
            : undefined,
        );
    },
    [protocolName, connectedChain?.id, account, handleDelegate, adapterInstances, setChain],
  );

  const showDelegateOther = useCallback((value: any) => {
    setShowDelegateOther(true);
    setFramework(value);
  }, []);

  return (
    <>
      <Modal
        zIndex={zIndex ?? 14}
        onClose={closeSetupDelegationModal}
        open={visible}
        size={currentWidth < 640 ? "small" : "medium"}
      >
        <Modal.Title>Choose Delegation Type</Modal.Title>
        <Modal.Body>
          {shouldShowDelegateOther ? (
            <DelegateOther handleDelegate={handleDelegateOther} protocolName={protocolName} address={address} />
          ) : (
            <Space direction="vertical">
              {!account && <Alert message="Connect wallet to continue setting up delegation." type="warning" />}
              <Alert
                message={`In order to activate your voting power, you may have to delegate your votes to ${
                  adapterInstances?.includes("onchain") ||
                  adapterInstances?.includes("onchain-optimism") ||
                  adapterInstances?.includes("onchain-arbitrum")
                    ? "yourself or"
                    : ""
                } someone else. This may incur a gas fee, but unless you change wallets or delegates, you'll only have to do it once.`}
                showIcon
                type="info"
              />
              {protocolName !== "makerdao" && !disableSelfDelegation
                ? (adapterFramework !== "snapshot" ||
                    adapterInstances?.includes("onchain") ||
                    adapterInstances?.includes("onchain-optimism") ||
                    adapterInstances?.includes("onchain-arbitrum")) && (
                    <Card hoverable onClick={handleDelegateSelf}>
                      <Title level={4}>Delegate to Self</Title>
                      <Text>
                        Choose this option if you want to vote on proposals directly by delegating voting rights to
                        yourself.
                      </Text>
                    </Card>
                  )
                : ""}
              {adapterInstances?.map((item) => {
                return (
                  <Card hoverable onClick={() => showDelegateOther(item)} key={item}>
                    <Title level={4}>
                      Delegate to Another Address{" "}
                      {item === "onchain-secondary" && aaveAdapterProtocols.includes(protocolName) && "(Staked Token)"}
                    </Title>
                    <Text>
                      {item === "onchain"
                        ? "Choose this option if you want to delegate your votes to another address. You won't send any tokens and you can re-delegate at any time."
                        : item === "snapshot"
                        ? "Choose this option if you want to delegate your votes to another address for future Snapshot votes."
                        : "Choose this option if you want to delegate your votes to another address. You won't send any tokens and you can re-delegate at any time."}
                    </Text>
                  </Card>
                );
              })}
            </Space>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default (props: Props) => (
  <Suspense fallback={<></>}>
    <DelegationModal {...props} />
  </Suspense>
);
