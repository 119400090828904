import { useMemo } from "react";
import { PaginatedAPIResponse, VoteDetails } from "@boardroom/boardroom-api";
import { useInfiniteQuery } from "@tanstack/react-query";

import { apiClient } from "../utils/apiClient";
import { useProposal } from "./useProposal";

interface Props {
  refId: string;
  limit?: number;
}

type GetVotesResponse = PaginatedAPIResponse<
  VoteDetails & {
    privacy: string;
  }
>;

export const useProposalVotes = ({ refId, limit }: Props) => {
  const { proposal } = useProposal({ refId });

  const { data, fetchNextPage, hasNextPage } = useInfiniteQuery<GetVotesResponse, Error>(
    [`proposals:${refId}:votes`],
    ({ pageParam: cursor }) =>
      apiClient.getProposalVotes(refId, {
        limit,
        ...(cursor ? { cursor } : {}),
      }) as any,
    {
      enabled: !!proposal,
      getNextPageParam: (params) => params?.nextCursor,
    },
  );

  const votes = useMemo(() => data?.pages.map((page) => page.data).flat() || [], [data]);

  return { votes, fetchNextPage, hasNextPage };
};
