import React from "react";
import ContentLoader from "react-content-loader";
import Row from "antd/es/row";
import Col from "antd/es/col";
import { useCurrentWidth } from "react-socks";

import { MainContainer } from "../../components/layout";
import { ContentArea } from "../../components/Shared.styles";
import CardsContainer from "../../components/layout/CardsContainer";
import { COLORS } from "../../constants/colors";
import { ProposalsListSkeleton } from "../../components/ProposalsListing/ProposalsListSkeleton";
import InfoCard from "../../components/InfoCard";
import { Container, ContentWrapper, PageMax, StyledVoterCardWrapper } from "./styles";

function ProposalOverviewSkeleton(): React.ReactElement {
  const currentWidth = useCurrentWidth();

  return (
    <>
      <MainContainer $isDashboard>
        <ContentArea>
          <Container className="ProposalContainer">
            <PageMax>
              <CardsContainer>
                <Row justify="start" gutter={[48, 48]} style={{ marginLeft: "0", marginRight: "0" }}>
                  <Col lg={{ span: 16, push: 0 }} md={{ span: 24, push: 0 }} sm={{ span: 24, push: 0 }}>
                    <ContentWrapper>
                      <Col lg={{ span: 11, push: 0 }} md={{ span: 24, push: 0 }} sm={{ span: 24, push: 0 }}>
                        <Row gutter={[12, { xs: 12, sm: 12, md: 12 }]}>
                          <Col span={24}>
                            <ContentLoader
                              speed={2}
                              width={"100%"}
                              height={40}
                              backgroundColor={COLORS.primary.grayLight}
                              foregroundColor={COLORS.primary.grayLighter}
                              style={{ borderRadius: "8px" }}
                            >
                              <rect x="0" y="0" rx="5" ry="5" width="203" height="18" />
                              <rect x="0" y="28" rx="5" ry="5" width="100%" height="2" />
                            </ContentLoader>
                          </Col>
                          <Col span={24}>
                            <ContentLoader
                              speed={2}
                              width="100%"
                              height={75}
                              backgroundColor={COLORS.primary.grayLight}
                              foregroundColor={COLORS.primary.grayLighter}
                            >
                              <rect x="640" y="23" rx="5" ry="5" width="7%" height="12" />
                              <rect x="510" y="23" rx="5" ry="5" width="15%" height="12" />
                            </ContentLoader>
                            <ProposalsListSkeleton />
                          </Col>
                        </Row>
                      </Col>
                    </ContentWrapper>
                  </Col>
                  {currentWidth >= 991 && (
                    <Col
                      style={{ marginLeft: currentWidth >= 767 ? "28px" : "" }}
                      md={{ span: 6, push: 0 }}
                      sm={{ span: 24, push: 0 }}
                    >
                      <Row gutter={[12, { xs: 12, sm: 12, md: 12 }]}>
                        <Col span={24}>
                          <StyledVoterCardWrapper>
                            <InfoCard title="VOTE POWER" $noPaddingBody={true}>
                              <ContentLoader
                                speed={2}
                                width="100%"
                                height={80}
                                backgroundColor={COLORS.primary.grayLight}
                                foregroundColor={COLORS.primary.grayLighter}
                              >
                                <rect x="39%" y="40" rx="5" ry="5" width="20" height="25" />
                                <rect x="47%" y="40" rx="5" ry="5" width="20" height="25" />
                                <rect x="55%" y="40" rx="5" ry="5" width="20" height="25" />
                              </ContentLoader>
                            </InfoCard>
                          </StyledVoterCardWrapper>
                        </Col>
                      </Row>
                    </Col>
                  )}
                </Row>
              </CardsContainer>
            </PageMax>
          </Container>
        </ContentArea>
      </MainContainer>
    </>
  );
}

export default ProposalOverviewSkeleton;
