import React from "react";
import Col from "antd/es/col";
import Row from "antd/es/row";
import Button from "antd/es/button";
import { Link, useLocation, useParams } from "react-router-dom";
import styled from "styled-components";
import { useCurrentWidth } from "react-socks";

import { CardsContainer } from "../components";
import media from "../media-query";
import { COLORS } from "../constants/colors";
import { RoutePaths } from "../constants/Routes";

const StyledButtonForMainButtons = styled(Button)`
  display: flex;
  height: 40px;
  width: 176px;
  justify-content: center;
  align-items: center;
  ${media.lessThan("640px")`
    width: 221px;
    margin: 0 auto;
  `}
`;

const FilledButton = styled(StyledButtonForMainButtons)`
  background: linear-gradient(89.89deg, #4235e1 0.96%, #9443d5 97.68%);
  border: 1px solid #9e97f3;
  box-shadow: 0px 2px 4px rgba(25, 21, 64, 0.15);
  border-radius: 8px;
  color: white;
  transition: all 0.3s;
  &:hover {
    background: #191540;
    color: white;
    border: 1px solid #9e97f3;
  }
`;

const OutlinedButton = styled(StyledButtonForMainButtons)`
  background: #191540;
  color: white;
  border: 1px solid #9e97f3;
  border-radius: 8px;
  transition: 0.3s all;
  &:hover {
    background: linear-gradient(89.89deg, #4235e1 0.96%, #9443d5 97.68%);
    border: 1px solid #9e97f3;
    color: white;
  }
`;

const FlexItem = styled(Link)`
  width: 50%;
  ${media.lessThan("640px")`
    width: 100%;
    text-align: center;
  `}
`;

const StyledTextButton = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
`;

export const MainButtons = () => {
  return (
    <>
      <FlexItem to={RoutePaths.feed}>
        <FilledButton>
          <StyledTextButton color="white">Delegate Portal</StyledTextButton>
        </FilledButton>
      </FlexItem>
      <FlexItem as="a" href="https://home.boardroom.io/developers/">
        <OutlinedButton>
          <StyledTextButton color="white">Developer Portal</StyledTextButton>
        </OutlinedButton>
      </FlexItem>
    </>
  );
};

const LinksWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 32px;
  ${media.lessThan("640px")`
    justify-content: center;
    flex-direction: column;
    gap: 14px;
  `}
`;

const StyledNavWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 28px auto 100px;
  align-items: center;
`;

const StyledText = styled.div<{ fontSize: string; color?: string; marginTop?: string }>`
  text-align: center;
  font-size: ${({ fontSize }) => fontSize};
  color: ${({ color }) => (color ? color : "")};
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : "")};
`;

const StyledLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #4235e1;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  border-radius: 8px;
  transition: all 0.3s;
  padding: 8px 0;
  width: 176px;
  &:hover {
    background: linear-gradient(89.89deg, #4235e1 0.96%, #9443d5 97.68%);
    color: white;
    border: 1px solid #9e97f3;
  }
`;

interface Params {
  protocol: string;
}

const Custom404 = ({ isVoter = false }: { isVoter?: boolean }) => {
  const { pathname } = useLocation();
  const { protocol } = useParams<Params>();
  const currentWidth = useCurrentWidth();

  return (
    <>
      <CardsContainer>
        <Row
          justify="start"
          style={{
            ...(isVoter ? { background: "#fff", justifyContent: "center", height: "100%", padding: "40px 0" } : {}),
          }}
        >
          <Col lg={{ span: 17, push: 0 }} md={{ span: 17, push: 0 }} sm={{ span: 17, push: 0 }}>
            <StyledText fontSize={currentWidth < 640 ? "24px" : "36px"} marginTop={currentWidth < 640 ? "40px" : ""}>
              404 - Oh no, this page does not exist
            </StyledText>
            <StyledText fontSize="24px" color={COLORS.primary.grayDarkLightest}>
              {isVoter ? pathname : `/${protocol}`} is not a page on boardroom.io
            </StyledText>
            <StyledText fontSize="24px" color={COLORS.primary.grayDarkLightest} marginTop="40px">
              While you are here...
            </StyledText>
            <StyledNavWrapper>
              <LinksWrapper>
                <MainButtons />
              </LinksWrapper>
              <StyledLink
                style={{ margin: "20px auto" }}
                href="https://roadmap.boardroom.info/b/bugs/"
                target="_blank"
                rel="noopener"
              >
                Submit an Issue
              </StyledLink>
            </StyledNavWrapper>
          </Col>
        </Row>
      </CardsContainer>
    </>
  );
};

export default Custom404;
