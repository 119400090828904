import React, { Suspense } from "react";
import ContentLoader from "react-content-loader";

import ProposalVotersTable from "./ProposalVotersTable";
import { COLORS } from "../../constants/colors";

export interface Props {
  refId: string;
}

const ProposalVoters = (props: Props) => {
  return <ProposalVotersTable {...props} />;
};

export default (props: Props) => (
  <Suspense
    fallback={
      <ContentLoader
        speed={2}
        width="100%"
        height={290}
        backgroundColor={COLORS.primary.grayLight}
        foregroundColor={COLORS.primary.grayLighter}
      >
        <circle x="0" cx="30" cy="30" r="16" />
        <rect x="60" y="22" rx="5" ry="5" width="80%" height="16" />

        <circle x="0" cx="30" cy="90" r="16" />
        <rect x="60" y="82" rx="5" ry="5" width="80%" height="16" />

        <circle x="0" cx="30" cy="150" r="16" />
        <rect x="60" y="142" rx="5" ry="5" width="80%" height="16" />

        <circle x="0" cx="30" cy="210" r="16" />
        <rect x="60" y="202" rx="5" ry="5" width="80%" height="16" />

        <circle x="0" cx="30" cy="270" r="16" />
        <rect x="60" y="262" rx="5" ry="5" width="80%" height="16" />
      </ContentLoader>
    }
  >
    <ProposalVoters {...props} />
  </Suspense>
);
