import { useContext, useState } from "react";
import { CreateProposalPayload } from "@boardroom/gov-sdk";

import { useSdk } from "./useSdk";
import { getEthereumAccountType, CONTRACT_ACCOUNT } from "../utils/walletUtils";
import { CurrentWeb3Context } from "../reducers/CurrentWeb3Provider";
import { identifier } from "../constants/identifier";
import { Web3Provider } from "@ethersproject/providers";
import { useIsMultisigSignIn } from "./useIsMultisigSignIn";
import { NotificationsContext } from "../contexts";
import { useIsEmailSignIn } from "./useIsEmailSignIn";

interface CreateProposal {
  data?: string;
  loading: boolean;
  error?: string;
}

export const useCreateProposal = (
  cname?: string,
): [(payload: CreateProposalPayload) => Promise<void>, CreateProposal] => {
  const sdk = useSdk();
  const [data, setData] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string>();
  const { web3Instance } = useContext(CurrentWeb3Context);
  const isMultisigSignIn = useIsMultisigSignIn();
  const isEmailSignIn = useIsEmailSignIn();
  const { dispatch } = useContext(NotificationsContext);

  const createProposal = async (payload: CreateProposalPayload) => {
    if (isMultisigSignIn) {
      dispatch({
        type: "onError",
        payload: { message: "Please connect the multisig wallet to create a proposal." },
      });
      return;
    }
    if (isEmailSignIn) {
      dispatch({
        type: "onError",
        payload: { message: "Please connect the wallet to create a proposal." },
      });
      return;
    }
    if (cname) {
      try {
        let isContractWallet = false;
        if (web3Instance) {
          const accountType = getEthereumAccountType(web3Instance);
          if (accountType === CONTRACT_ACCOUNT) {
            isContractWallet = true;
          }
        }
        setIsLoading(true);
        const requestId = await sdk
          ?.getProtocol(cname)
          .adapter("createProposal", "snapshot")
          .createProposal(payload, isContractWallet, identifier, web3Instance as Web3Provider);

        setData(requestId);
        setIsLoading(false);
      } catch (e: any) {
        setError(e.message);
      }
    }
  };

  return [createProposal, { data, loading: isLoading, error }];
};
