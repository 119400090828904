import React, { createContext, useContext, useState } from "react";

interface Props {
  children: React.ReactNode;
}

interface ScrolledHeightContextType {
  scrolledHeight: number;
  setScrolledHeight: React.Dispatch<any>;
}

export const ScrolledHeightContext = createContext({} as ScrolledHeightContextType);

export const ScrolledHeightProvider = ({ children }: Props) => {
  const [scrolledHeight, setScrolledHeight] = useState(0);

  return (
    <ScrolledHeightContext.Provider value={{ scrolledHeight, setScrolledHeight }}>
      {children}
    </ScrolledHeightContext.Provider>
  );
};

export const useScrolledHeight = () => {
  const { scrolledHeight, setScrolledHeight } = useContext(ScrolledHeightContext);
  return { scrolledHeight, setScrolledHeight };
};
