import React, { Suspense, useMemo } from "react";
import styled from "styled-components";
import { ErrorBoundary } from "react-error-boundary";

import { Dropdown } from "../Dropdown";
import { Item } from "../Dropdown/types";
import { protocols } from "../../constants/protocols";
import media from "../../media-query";
import { COLORS } from "../../constants/colors";
import { Header } from "../Typography";
import Col from "antd/lib/col";

interface Props {
  activeProtocol: string;
  setActiveProtocol: (arg0: string) => void;
  votedProtocols?: Array<string>;
}

const FilterDropdownContainer = styled.div<Pick<Props, "activeProtocol">>`
  width: 100%;
  margin-top: 20px;
  margin-bottom: 32px;
  h3 {
    display: inline-block;
    font-weight: 300;
    font-size: 32px;
    ${media.lessThan("991px")`
    font-size: 24px;
  `}
  }
  button {
    display: inline;
    width: auto;
    border: none;
    background: transparent;
    box-shadow: none;
    padding: 0px;
    padding-left: 5px;
    > div {
      display: inline-block;
      svg {
        color: ${COLORS.primary.accent};
      }
    }
    > span {
      font-size: 32px;
      color: ${COLORS.primary.accent};
      font-weight: 300;
      ${media.lessThan("991px")`
    font-size: 24px;
  `}
    }
    &:hover {
      box-shadow: none;
    }
    & + div {
      left: 100px;
      top: -25px;
    }
  }
  label {
    display: inline;
  }
  ul {
    width: auto;
  }
  ${media.lessThan("991px")`
    margin-left: 0;
    width: auto;
    margin-top: 36px;
    margin-bottom: 8px;
  `}
`;

const ALL_PROTOCOLS_DROPDOWN_ITEM = {
  name: "All Projects",
  value: "all",
};

function ProtocolDropdown(props: Props) {
  const { activeProtocol, votedProtocols = [] } = props;

  const initialProtocolDropdownValue = useMemo(() => {
    if (!activeProtocol) {
      return ALL_PROTOCOLS_DROPDOWN_ITEM;
    }

    return {
      name: protocols[activeProtocol].name,
      value: activeProtocol,
    };
  }, [activeProtocol]);

  const allDropdownItems = useMemo(() => {
    const allProtocols = votedProtocols.reduce((acc: Item[], protocol) => {
      if (protocols[protocol]?.isEnabled) {
        return [
          ...acc,
          {
            name: protocols[protocol]?.name,
            value: protocol,
          },
        ];
      }
      return acc;
    }, []);

    return [ALL_PROTOCOLS_DROPDOWN_ITEM, ...allProtocols];
  }, [votedProtocols]);

  const onProjectSelect = (item: Item | null) => {
    if (item?.value) {
      if (item.value === "all") {
        props.setActiveProtocol("");
      } else {
        props.setActiveProtocol(item.value);
      }
    }
  };

  if (votedProtocols) {
    if (allDropdownItems.length < 2) {
      return null;
    }

    return (
      <Col md={24}>
        <FilterDropdownContainer activeProtocol={activeProtocol}>
          <Header level={3}>Activity</Header>
          <Dropdown
            items={allDropdownItems}
            initialValue={initialProtocolDropdownValue}
            onChange={(item: Item | null) => onProjectSelect(item)}
            label=""
          />
        </FilterDropdownContainer>
      </Col>
    );
  } else {
    return null;
  }
}

function ProtocolDropdownWrapper(props: Props) {
  return (
    <Suspense fallback={<></>}>
      <ProtocolDropdown {...props} />
    </Suspense>
  );
}

function ProtocolDropdownErrorBoundary(props: Props) {
  return (
    <ErrorBoundary fallback={<></>}>
      <ProtocolDropdownWrapper {...props} />
    </ErrorBoundary>
  );
}

export default React.memo(ProtocolDropdownErrorBoundary);
