import React from "react";
import { Label } from "../Label";
import { DropdownItemList } from "./DropdownItemList";
import { DropdownButton, DropdownProps, Item } from "./DropdownButton";
import { useSelect, UseSelectStateChange } from "downshift";

export const getSingleSelectConfig = (items: Item[], initialValue?: Item, onChange?: DropdownProps["onChange"]) => ({
  items,
  initialSelectedItem: initialValue,
  onSelectedItemChange: ({ selectedItem }: UseSelectStateChange<Item>) => onChange && onChange(selectedItem || null),
});

export const AlternateDropdown = ({
  label,
  items,
  initialValue,
  placeholder = "Select option",
  prefix = "",
  icon,
  onChange,
}: DropdownProps) => {
  const { isOpen, selectedItem, getToggleButtonProps, getLabelProps, getMenuProps, getItemProps } = useSelect(
    getSingleSelectConfig(items, initialValue, onChange),
  );

  return (
    <>
      {label && <Label {...getLabelProps()}>{label}</Label>}

      <DropdownButton
        selected={selectedItem}
        isOpen={isOpen}
        placeholder={placeholder}
        prefix={prefix}
        icon={icon}
        getButtonProps={getToggleButtonProps}
      />

      <DropdownItemList
        items={items}
        isOpen={isOpen}
        selected={selectedItem}
        getMenuProps={getMenuProps}
        getItemProps={getItemProps}
      />
    </>
  );
};
