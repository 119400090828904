import { useInfiniteQuery } from "@tanstack/react-query";
import { GetProposalsResponse } from "@boardroom/boardroom-api";
import { apiClient } from "../utils/apiClient";
import { useMemo } from "react";

interface Props {
  proposer?: string;
  proposers?: string;
}

export const useUserProposals = ({ proposer, proposers }: Props) => {
  const { data, fetchNextPage, hasNextPage } = useInfiniteQuery<GetProposalsResponse, Error>(
    [`proposals:${proposer || proposers}}`],
    ({ pageParam: cursor }) =>
      apiClient.getProposals({
        proposer,
        proposers,
        limit: 1000,
        ...(cursor ? { cursor } : {}),
      }),
    {
      enabled: !!proposer || !!proposers,
      getNextPageParam: (params) => params?.nextCursor,
    },
  );

  const proposals = useMemo(() => {
    return data?.pages
      .flat()
      .map((d) => d.data)
      .flat();
  }, [data]);

  return { proposals, fetchNextPage, hasNextPage };
};
