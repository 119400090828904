import * as React from "react";

const SvgSettingsAlternateIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M2.415 9.945c-.529-.95-.793-1.426-.793-1.945 0-.519.264-.994.793-1.944l.539-.97.57-.95c.558-.934.838-1.4 1.287-1.66.45-.259.993-.268 2.08-.285L8 2.173l1.108.018c1.088.017 1.632.026 2.08.285.45.26.73.726 1.288 1.66l.57.95.539.97c.529.95.793 1.425.793 1.944 0 .519-.264.994-.793 1.945l-.539.968-.57.951c-.558.934-.838 1.4-1.287 1.66-.45.259-.993.268-2.08.285l-1.11.018-1.108-.018c-1.087-.017-1.63-.026-2.08-.285-.45-.26-.729-.726-1.288-1.66l-.57-.95-.538-.97z"
      stroke="currentColor"
    />
    <circle cx={8} cy={8} r={2} stroke="currentColor" />
  </svg>
);

export default SvgSettingsAlternateIcon;
