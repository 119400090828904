import React from "react";
import AntdProgressBar from "antd/es/progress";
import { COLORS } from "../../constants/colors";
import styled from "styled-components";

interface ProgressBarProps {
  percent: number;
  showInfo?: boolean;
  status?: "success" | "error" | "normal" | "active";
  strokeColorCustom?: string;
  trailColorCustom?: string;
}

const StyledProgressBar = styled(AntdProgressBar)`
  .ant-progress-outer {
    margin-right: calc(-4em - 8px);
    padding-right: calc(4em + 8px);
  }
`;

export const ProgressBar = ({
  percent,
  showInfo = true,
  status = "active",
  strokeColorCustom,
  trailColorCustom,
}: ProgressBarProps) => {
  let strokeColor = strokeColorCustom ?? COLORS.primary.accent;
  let trailColor = trailColorCustom ?? COLORS.primary.accentLighter;
  if (status === "success") {
    strokeColor = COLORS.secondary.green;
    trailColor = COLORS.secondary.greenLighter;
  } else if (status === "error") {
    strokeColor = COLORS.secondary.red;
    trailColor = COLORS.secondary.redLighter;
  } else if (status === "normal") {
    strokeColor = COLORS.secondary.blue;
    trailColor = COLORS.secondary.blueLighter;
  }
  return (
    <StyledProgressBar
      className=""
      percent={percent}
      showInfo={showInfo}
      strokeColor={strokeColor}
      trailColor={trailColor}
    />
  );
};

export type { ProgressBarProps };
