import * as React from "react";

const SvgOrgIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.751 1.512c0-.283.23-.512.512-.512h4.774c.283 0 .512.229.512.512V4.58a.512.512 0 01-.512.511H5.263a.512.512 0 01-.512-.511V1.51zm1.023.511V4.07h3.752V2.023H5.774zM1 10.72c0-.283.229-.512.512-.512h4.774c.282 0 .511.229.511.511v3.07a.512.512 0 01-.511.511H1.512A.512.512 0 011 13.789v-3.07zm1.023.51v2.047h3.751V11.23h-3.75zM8.503 10.72c0-.283.229-.512.511-.512h4.775c.282 0 .511.229.511.511v3.07a.511.511 0 01-.511.511H9.014a.512.512 0 01-.511-.511v-3.07zm1.023.51v2.047h3.75V11.23h-3.75z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.65 4.41c.283 0 .512.23.512.512V7.65a.512.512 0 11-1.024 0V4.922c0-.283.23-.512.512-.512z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.387 7.65c0-.283.23-.512.512-.512H11.4c.283 0 .512.23.512.512v3.07a.512.512 0 11-1.023 0V8.161H4.41v2.557a.512.512 0 01-1.023 0V7.65z"
      fill="currentColor"
    />
  </svg>
);

export default SvgOrgIcon;
