import React, { createContext, useState } from "react";

interface Props {
  children: React.ReactNode;
}

interface CurrentProjectsFilterContextType {
  projectsFilter: "custom" | "all";
  setProjectsFilter: React.Dispatch<React.SetStateAction<"custom" | "all">>;
}

export const CurrentProjectsFilterContext = createContext({} as CurrentProjectsFilterContextType);

export const CurrentProjectsFilterProvider = (props: Props) => {
  const [projectsFilter, setProjectsFilter] = useState<"custom" | "all">("all");

  return (
    <CurrentProjectsFilterContext.Provider value={{ projectsFilter, setProjectsFilter }}>
      {props.children}
    </CurrentProjectsFilterContext.Provider>
  );
};
