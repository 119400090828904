import Button from "antd/es/button";
import Col from "antd/es/col";
import Row from "antd/es/row";
import React, { useCallback, useContext, useState } from "react";
import styled from "styled-components";

import { MainContainer } from "../components";
import { Input } from "../components/Input";
import { ContentArea } from "../components/Shared.styles";
import media from "../media-query";
import { BoardroomLogo, BoardroomFooterLogo } from "../components/icons";
import { useOnboardWallet } from "../hooks/useOnboardWallet";
import { CurrentAccountContext } from "../reducers/CurrentAccount";
import { MintSubscriptionButton } from "../components/MintSubscriptionButton";
import {
  PlanItems,
  PlanTitle,
  PriceDetailsMonthly,
  PriceText,
  StyledPlanWrapper,
} from "../components/PaymentGate/PaymentGate";
import { getAddress } from "ethers/lib/utils";
import { checkNftKeysForSubscription } from "../hooks/useHasValidNftSubscription";
import { COLORS } from "../constants/colors";

const Container = styled.div`
  margin: -19px auto auto;
  height: calc(100vh - 140px);
  overflow-y: scroll;
  overflow-x: hidden;
  background: linear-gradient(180deg, #191540 0%, #4a11ca 100%);
  ${media.lessThan("991px")`
    padding: 0px;
    height: calc(100vh - 4px);
  `}
`;

const PageMax = styled.div`
  width: 100%;
  min-height: calc(100% - 320px);
  ${media.lessThan("1100px")`
    min-height: calc(100% - 360px);
  `}
`;

const StyledDiv = styled.div`
  height: auto;
  margin: 0px 80px 80px;
  background: #5625d6;
  border-width: 4px;
  border-style: solid;
  border-image: linear-gradient(180deg, #4f18cd 0%, #35c9f8 76.38%, #ee3fd9 100%) 1;
  border-top: 0;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  color: white;
  padding-top: 60px;
  padding-bottom: 100px;
  text-align: center;
  ${media.lessThan("991px")`
    margin: 0px 20px 32px;
  `}
`;

const PageHeader = styled.div`
  width: 100%;
  padding-top: 18px;
  padding-bottom: 8px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  border: 4px solid #4f18cd;
  border-bottom: 0;
  background: #5625d6;
  ${media.lessThan("991px")`
    padding-bottom: 10px;
  `}
  ${media.greaterThan("1400px")`
    max-width: 1280px;
    margin: 0 auto;
  `}
`;

const PageHeaderWrapper = styled.div`
  padding: 0px 80px;

  ${media.lessThan("991px")`
    padding: 0px 20px;
    background: #191540;
  `}
`;

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  z-index: +10;
  position: absolute;
  background: inherit;
  width: fit-content;
  border-radius: 16px;
  padding: 4px 4px 4px 8px;
  transform: translate(50%, 50%);
  right: 50%;
  color: #fff;
`;

const StyledLogo = styled(BoardroomFooterLogo)`
  margin: auto 8px;
`;

const StyledTitle = styled.div`
  font-family: MATROSKA;
  font-size: 40px;
  font-style: normal;
  font-weight: 900;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: center;
  padding: 32px 0;
  z-index: 1;
  position: relative;
  ${media.lessThan("991px")`
    font-size: 24px;
  `}
`;

const VideoWrapper = styled.div`
  height: 240px;
  background: #5625d6;
  border-radius: 8px;
  margin: 0 auto 52px;
  ${media.lessThan("991px")`
    width: 85%;
    height: 220px;
    background: #5625d6;
  `}
`;

const StyledButton = styled(Button)<{ bg: string }>`
  margin: 20px auto 20px;
  background: ${({ bg }) => bg};
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 8px;
  color: white;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  width: 340px;
  height: 40px;
  &:hover {
    background: ${({ bg }) => bg};
    color: white;
  }
  ${media.lessThan("991px")`
    width: 85%;
  `}
`;

const StyledText = styled.div<{ color?: string; margin?: string; fontSize?: string }>`
  color: ${({ color }) => (color ? color : "white")};
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "16px")};
  line-height: 24px;
  margin: ${({ margin }) => (margin ? margin : 0)};
`;

const StyledWrapper = styled.div<{ margin?: string }>`
  width: 560px;
  font-weight: 300;
  margin: ${({ margin }) => (margin ? margin : "0 auto")};
  ${media.lessThan("991px")`
    width: 85%;
  `}
`;

const MobileHeader = styled.div`
  height: 28px;
  background: #191540;
  margin-top: -12px;
`;

const FlexDiv = styled("div")`
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 12px;
  padding: 8px;
  ${media.lessThan("991px")`
    flex-direction: column;
  `}
`;

const StyledInput = styled(Input)`
  width: 250px;
  margin: 0 20px 20px 0px;
  font-size: 14px;
  height: 40px;
  &:hover {
    border-color: #40a9ff !important;
  }
  color: #fff;
`;

const StyledVerifyButton = styled(Button)`
  height: 40px;
  border-radius: 6px;
  width: 76px;
`;

const PlansWrapper = ({ planType }: { planType: string }) => {
  return (
    <StyledPlanWrapper>
      <PlanTitle>{planType === "monthly" ? "MONTHLY" : "ANNUAL - 25% DISCOUNT"}</PlanTitle>
      <PriceText>
        {planType === "monthly" ? "$99" : "$75"}
        <PriceDetailsMonthly>/mo</PriceDetailsMonthly>
      </PriceText>
      <PlanItems>
        {planType === "monthly" ? (
          <>
            <li>Discussion Alerts</li>
            <li>Daily Briefs</li>
          </>
        ) : (
          <>
            <li>Same as Monthly</li>
            <li>Private Channels</li>
          </>
        )}
      </PlanItems>
      <MintSubscriptionButton type={planType} />
    </StyledPlanWrapper>
  );
};

interface Props {
  hasValidKey?: boolean;
  showAccessInfo?: boolean;
  onClose: () => void;
}

const MintNFT = (props: Props) => {
  const { hasValidKey, showAccessInfo, onClose } = props;
  const { account } = useContext(CurrentAccountContext);
  const wallet = useOnboardWallet();
  const showPlans = !hasValidKey && !showAccessInfo && account;

  const [addressValue, setAddressValue] = useState("");
  const [invalidAddress, setInvalidAddress] = useState(false);
  const [addressHasvalidNftKey, setAddressHasValidNftKey] = useState<undefined | boolean>(undefined);
  const [addressVeirificationInProgress, setAddressVerificationInProgress] = useState(false);

  const handleAddressChange = useCallback((e: React.ChangeEvent<any>) => {
    setAddressValue(e.target.value);
  }, []);

  const verifyAddressForNft = useCallback(async () => {
    try {
      const address = getAddress(addressValue);
      setInvalidAddress(false);
      setAddressHasValidNftKey(undefined);
      setAddressVerificationInProgress(true);
      const validNftKey = await checkNftKeysForSubscription(address);
      setAddressHasValidNftKey(validNftKey);
    } catch (e) {
      setInvalidAddress(true);
      setAddressHasValidNftKey(false);
    } finally {
      setAddressVerificationInProgress(false);
    }
  }, [addressValue]);

  return (
    <>
      <MainContainer>
        <ContentArea>
          <MobileHeader></MobileHeader>
          <PageHeaderWrapper>
            <PageHeader>
              <Row align="top" gutter={[0, 16]}>
                <Col span={24}>
                  <LogoWrapper>
                    <BoardroomLogo color="white" width={24} height={24} />
                    <StyledLogo color="white" height={16} width={114} />
                  </LogoWrapper>
                </Col>
              </Row>
            </PageHeader>
          </PageHeaderWrapper>
          <Container>
            <PageMax>
              <Row justify="center" gutter={[0, 16]}>
                <Col md={24}>
                  <StyledDiv>
                    {showAccessInfo || hasValidKey ? (
                      <StyledTitle>
                        WELCOME TO
                        <br />
                        BOARDROOM
                      </StyledTitle>
                    ) : (
                      <StyledTitle>
                        JOIN THE
                        <br />
                        COMMUNITY
                      </StyledTitle>
                    )}
                    <VideoWrapper>
                      <video
                        src={`${process.env.PUBLIC_URL}/assets/br-access-gate-test3-optim.mp4`}
                        width="100%"
                        height="100%"
                        autoPlay
                        loop
                        muted
                        style={{ borderRadius: "8px", background: "transparent" }}
                      />
                    </VideoWrapper>
                    <StyledWrapper>
                      {showAccessInfo || hasValidKey ? (
                        <>
                          <StyledText margin="24px 0">You Have Verified Ownership of an Access Card</StyledText>
                        </>
                      ) : (
                        <>
                          <StyledText fontSize="24px">Mint Or Reveal Your Access Card</StyledText>
                          <StyledText margin="24px 0">
                            Join a community actively participating in governance across hundreds of DAOs. Unlock{" "}
                            <b>forum discussions</b>, <b>advanced search</b>, <b>additional wallet tracking</b>, and{" "}
                            <b>AI generated summaries</b>.
                          </StyledText>
                        </>
                      )}
                    </StyledWrapper>
                    {!account && (
                      <>
                        <StyledButton
                          onClick={wallet?.openWalletModal}
                          bg="linear-gradient(270deg, #4235E1 0%, #9E97F3 100%)"
                        >
                          Connect Your Wallet to Mint
                        </StyledButton>
                        <div style={{ position: "relative" }}>
                          <StyledInput
                            placeholder="Enter Addess to Check Ownership"
                            onChange={handleAddressChange}
                            value={addressValue}
                          />
                          <StyledVerifyButton onClick={verifyAddressForNft}>
                            {addressVeirificationInProgress ? "..." : "Verify"}
                          </StyledVerifyButton>
                        </div>
                        {invalidAddress && (
                          <StyledText color={COLORS.secondary.red}>Please enter a valid ETH address.</StyledText>
                        )}
                        {addressHasvalidNftKey && (
                          <>
                            <StyledText color={COLORS.secondary.green}>
                              Your address has a valid access card! Connect your wallet to explore.
                            </StyledText>
                          </>
                        )}
                        {addressHasvalidNftKey === false && invalidAddress === false && (
                          <>
                            <StyledText color={COLORS.secondary.red}>
                              Your address does not have a valid Boardroom Access Card.
                            </StyledText>
                          </>
                        )}
                      </>
                    )}
                    {showPlans && (
                      <FlexDiv>
                        <PlansWrapper planType="monthly" />
                        <PlansWrapper planType="yearly" />
                      </FlexDiv>
                    )}
                    {(showAccessInfo || hasValidKey) && (
                      <StyledButton onClick={onClose} bg="linear-gradient(270deg, #4235E1 0%, #9E97F3 100%)">
                        Start Exploring
                      </StyledButton>
                    )}
                  </StyledDiv>
                </Col>
              </Row>
            </PageMax>
          </Container>
        </ContentArea>
      </MainContainer>
    </>
  );
};
export default MintNFT;
