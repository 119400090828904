import React from "react";
import { useLocation } from "react-router";
import { TwitterShareButton } from "react-share";
import styled from "styled-components";

import { COLORS } from "../../constants/colors";
import { useVoteTxnHash } from "../../reducers/VoteTxnHash";
import { LogOutIcon, NoteIcon, ThinCheckIcon } from "../icons";

export const VoteButton = styled.div<{ $voted?: boolean }>`
  border: 1px solid ${({ $voted }) => ($voted ? "#291fa7" : COLORS.primary.accent)};
  color: ${({ $voted }) => ($voted ? "#fff" : COLORS.primary.accent)};
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  border-radius: 4px;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 20px;
  cursor: pointer;
  transition: all 0.3s;
  background: ${({ $voted }) => ($voted ? "#291fa7" : "transparent")};
  svg {
    margin-right: 8px;
  }
  :hover {
    color: white;
    background: ${({ $voted }) => ($voted ? "#291fa7" : "linear-gradient(90deg, #9e97f3 3.41%, #4235e1 100%)")};
    border: ${({ $voted }) => ($voted ? "1px solid #291fa7" : "1px solid transparent")};
    border-left: ${({ $voted }) => ($voted ? "1px solid #291fa7" : "0")};
  }
  :focused {
    color: white;
    background: #4235e1;
  }
`;

const FlexDiv = styled.div<{ $reducedMarginTop?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: ${({ $reducedMarginTop }) => ($reducedMarginTop ? "16px" : "20px")};
`;

const FlexItem = styled.div`
  display: flex;
  align-items: center;
  color: ${COLORS.primary.grayDarkLightest};
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  svg {
    margin-right: 8px;
  }
  &:hover {
    color: ${COLORS.primary.accent};
    svg {
      color: ${COLORS.primary.accent};
    }
  }
`;

const Flex = styled.div<{ gap: string }>`
  display: flex;
  gap: ${({ gap }) => gap};
`;

const PendingVoteState = styled.div<{ $smallerSize?: boolean }>`
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  background-color: white;
  display: flex;
  align-items: center;
  ${({ $smallerSize }) => $smallerSize && "max-width: 128px;"}
  padding: 4px ${({ $smallerSize }) => ($smallerSize ? "0" : "8px")};
  border-radius: 4px;
  svg {
    margin-left: 8px;
  }
`;

const ArchiveAnim = styled.img`
  height: 12px;
  margin-left: 4px;
  vertical-align: baseline;
  padding-top: 2px;
`;

const StyledLogOutIcon = styled(LogOutIcon)`
  transform: rotate(-90deg);
`;

export const SummaryButton = styled.div<{ $addMarginRight?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: ${COLORS.primary.accent};
  ${({ $addMarginRight }) => $addMarginRight && "margin-right: 16px;"}
  &:hover {
    color: ${COLORS.primary.accentDark};
  }
`;

const StyledLink = styled("a")`
  color: ${COLORS.primary.grayDarkLightest};
  &:hover {
    color: ${COLORS.primary.accent};
  }
`;

interface Props {
  onClick: any;
  voted?: boolean;
  protocolPath?: string;
  protocolName?: string;
  refId?: string;
  handleMemoClick?: any;
  summary?: string;
  handleNoteClick?: any;
  isProposalActive: boolean;
  notes?: number;
  pendingVote?: boolean;
  reducedMarginTop?: boolean;
}

export const VoteInfo = ({
  pendingVote = false,
  protocolName,
  voted,
  onClick,
  smallerSize,
  hideReceipt,
}: {
  pendingVote?: boolean;
  protocolName?: string;
  voted?: boolean;
  onClick: any;
  smallerSize?: boolean;
  hideReceipt?: boolean;
}) => {
  const { voteTxnHash } = useVoteTxnHash();
  if (pendingVote) {
    return (
      <>
        <PendingVoteState $smallerSize={smallerSize}>
          <ArchiveAnim src={`${process.env.PUBLIC_URL}/assets/Archiving.gif`} />
          <ThinCheckIcon color="#191540" width={16} height={16} />
        </PendingVoteState>
        {voteTxnHash.txnHash && !hideReceipt && (
          <PendingVoteState>
            <StyledLink
              target={"_blank"}
              href={
                voteTxnHash.isSnapshotProposal
                  ? `https://ipfs.io/ipfs/${voteTxnHash.txnHash}`
                  : protocolName === "optimism"
                  ? `https://optimistic.etherscan.io/tx/${voteTxnHash.txnHash}`
                  : protocolName === "arbitrum"
                  ? `https://arbiscan.io/tx/${voteTxnHash.txnHash}`
                  : `https://etherscan.io/tx/${voteTxnHash.txnHash}`
              }
              onClick={(e) => e.preventDefault()}
            >
              View Receipt
            </StyledLink>
          </PendingVoteState>
        )}
      </>
    );
  } else if (voted) {
    return (
      <VoteButton $voted={voted} onClick={onClick}>
        <ThinCheckIcon style={{ marginRight: "4px" }} width={16} height={16} />
        <span>Edit Vote</span>
      </VoteButton>
    );
  }
  return (
    <VoteButton $voted={voted} onClick={onClick}>
      <ThinCheckIcon style={{ marginRight: "4px" }} width={20} height={20} /> Vote
    </VoteButton>
  );
};

const VoteButtonOnCard = ({
  voted,
  onClick,
  protocolName,
  protocolPath,
  refId,
  pendingVote = false,
  handleMemoClick,
  summary,
  handleNoteClick,
  isProposalActive,
  notes,
  reducedMarginTop,
}: Props) => {
  const { pathname } = useLocation();
  const isFeed = pathname === "/feed/proposals";

  return (
    <FlexDiv $reducedMarginTop={reducedMarginTop}>
      <Flex gap="12px">
        <>
          <VoteInfo pendingVote={pendingVote} protocolName={protocolName} voted={voted} onClick={onClick} />
          {!pendingVote && summary && isFeed && <SummaryButton onClick={handleMemoClick}>View Summary</SummaryButton>}
        </>
      </Flex>
      <Flex gap="18px">
        {isFeed && (
          <FlexItem onClick={handleNoteClick}>
            <NoteIcon width={16} height={16} />
            {notes === 0 || notes === undefined ? "Add Note" : `${notes} Notes`}
          </FlexItem>
        )}
        {isProposalActive && (
          <FlexItem>
            <TwitterShareButton
              url={`https://boardroom.io/${protocolPath}/proposal/${refId}`}
              via={"boardroom_info"}
              title={`View this proposal from the ${protocolName} project:`}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <StyledLogOutIcon width={16} height={16} color={COLORS.primary.grayDarkLightest} /> <span>Share</span>
              </div>
            </TwitterShareButton>
          </FlexItem>
        )}
      </Flex>
    </FlexDiv>
  );
};

export default VoteButtonOnCard;
