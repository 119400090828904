import React from "react";
import styled from "styled-components";
import Event from "./Event";
import { CalendarEvent } from "../../../types";
import { COLORS } from "../../../constants/colors";

interface Props {
  events: CalendarEvent[];
  max?: number | null;
  shouldLink?: boolean;
  isDisabled?: boolean;
}

const List = styled.ul<{ shouldLink: boolean }>`
  margin-bottom: 0;
  margin-left: 4px;
  padding: 0;
  list-style: none;
  line-height: 18px;
  width: ${(props) => (props.shouldLink ? "300px" : "inherit")};
`;

const OverflowText = styled.div<Pick<Props, "isDisabled">>`
  position: absolute;
  bottom: 10px;
  right: 15px;
  font-size: 10px;
  font-weight: 500;
  color: ${COLORS.primary.grayDarkLightest};
  text-align: right;
  opacity: ${({ isDisabled }) => (isDisabled ? 0.4 : 1)};
`;

export const EventList = ({ events, max = null, shouldLink = false, isDisabled = false }: Props) => {
  const visibleEvents = max ? events.slice(0, max) : events;
  const hasOverflow = max && events.length - max > 0;
  const overflowText = max ? `and ${events.length - max} more...` : "";

  return (
    <>
      <List shouldLink={shouldLink}>
        {visibleEvents.map((event, i) => (
          <Event
            key={`${event.date}-${event.title}-${i}`}
            event={event}
            shouldLink={shouldLink}
            isDisabled={isDisabled}
          />
        ))}
      </List>

      {hasOverflow && <OverflowText isDisabled={isDisabled}>{overflowText}</OverflowText>}
    </>
  );
};
