import React from "react";
import ContentLoader from "react-content-loader";

import { COLORS } from "../../constants/colors";
import {
  ActivitySectionTitle,
  BannerImage,
  BannerWrapper,
  DelegationStatsHeader,
  DelegationStatsHeaderTitle,
  DelegationStatsWrapper,
  FirstSectionWrapper,
  FlexDiv,
  FlexItem,
  ImgWrapper,
  PitchText,
  ProfileName,
  SecondSectionWrapper,
  StyledDelegateIcon,
  StyledInfo,
  VisitProfile,
  Wrapper,
} from "./styles";
import { LightningIcon } from "../icons";

export const FirstSectionLoader = () => {
  return (
    <FirstSectionWrapper>
      <BannerWrapper>
        <BannerImage src={`${process.env.PUBLIC_URL}/assets/ProfileBanner${1}.png`} />
      </BannerWrapper>
      <ImgWrapper>
        <ContentLoader
          speed={2}
          height={"72px"}
          backgroundColor={COLORS.primary.grayLight}
          foregroundColor={COLORS.primary.grayLighter}
        >
          <circle cx={36} cy={36} r={36} />
        </ContentLoader>
        <StyledDelegateIcon />
      </ImgWrapper>
      <FlexItem>
        <ProfileName level={3}>
          <ContentLoader
            speed={2}
            backgroundColor={COLORS.primary.grayLight}
            foregroundColor={COLORS.primary.grayLighter}
            height={40}
          >
            <rect x="0" y="8" rx="5" ry="5" width="100px" height="16" />
          </ContentLoader>
        </ProfileName>
      </FlexItem>
      <StyledInfo>
        <ContentLoader
          speed={2}
          backgroundColor={COLORS.primary.grayLight}
          foregroundColor={COLORS.primary.grayLighter}
          height={40}
        >
          <rect x="0" y="8" rx="5" ry="5" width="60px" height="16" />
        </ContentLoader>
      </StyledInfo>
    </FirstSectionWrapper>
  );
};

export const ActivityLoader = () => {
  return (
    <>
      <div>
        <FlexDiv>
          <div>
            <ActivitySectionTitle style={{ display: "flex", alignItems: "center", gap: "8px" }}>
              Votes{" "}
              <span style={{ fontWeight: 300 }}>
                <ContentLoader
                  speed={2}
                  backgroundColor={COLORS.primary.grayLight}
                  foregroundColor={COLORS.primary.grayLighter}
                  height={32}
                >
                  <rect x="0" y="8" rx="5" ry="5" width="24px" height="16" />
                </ContentLoader>
              </span>
            </ActivitySectionTitle>
          </div>
          <div>
            <ActivitySectionTitle style={{ display: "flex", alignItems: "center", gap: "8px" }}>
              VP
              <span
                style={{ fontWeight: 300, color: "#4235e1", display: "flex", alignItems: "center", marginLeft: "4px" }}
              >
                <LightningIcon />
                <ContentLoader
                  speed={2}
                  backgroundColor={COLORS.primary.grayLight}
                  foregroundColor={COLORS.primary.grayLighter}
                  height={32}
                >
                  <rect x="0" y="8" rx="5" ry="5" width="24px" height="16" />
                </ContentLoader>
              </span>
            </ActivitySectionTitle>
          </div>
        </FlexDiv>
        <FlexDiv style={{ marginTop: "6px" }}>
          <div>
            <ActivitySectionTitle style={{ display: "flex", alignItems: "center", gap: "8px" }}>
              Proposals{" "}
              <span style={{ fontWeight: 300 }}>
                {" "}
                <ContentLoader
                  speed={2}
                  backgroundColor={COLORS.primary.grayLight}
                  foregroundColor={COLORS.primary.grayLighter}
                  height={32}
                >
                  <rect x="0" y="8" rx="5" ry="5" width="24px" height="16" />
                </ContentLoader>
              </span>
            </ActivitySectionTitle>
          </div>
          <div>
            <ActivitySectionTitle style={{ display: "flex", alignItems: "center", gap: "8px" }}>
              Delegators{" "}
              <span style={{ fontWeight: 300 }}>
                <ContentLoader
                  speed={2}
                  backgroundColor={COLORS.primary.grayLight}
                  foregroundColor={COLORS.primary.grayLighter}
                  height={32}
                >
                  <rect x="0" y="8" rx="5" ry="5" width="24px" height="16" />
                </ContentLoader>
              </span>
            </ActivitySectionTitle>
          </div>
        </FlexDiv>
      </div>
    </>
  );
};

export const PitchLoader = () => {
  return (
    <DelegationStatsWrapper style={{ paddingBottom: "64px" }}>
      <DelegationStatsHeader>
        <DelegationStatsHeaderTitle>Delegation Pitch</DelegationStatsHeaderTitle>
      </DelegationStatsHeader>
      <PitchText>
        <ContentLoader
          speed={2}
          backgroundColor={COLORS.primary.grayLight}
          foregroundColor={COLORS.primary.grayLighter}
          height={40}
        >
          <rect x="0" y="8" rx="5" ry="5" width="100%" height="16" />
        </ContentLoader>
      </PitchText>
    </DelegationStatsWrapper>
  );
};

const DelegatorProfileSidebarSkeleton = () => {
  return (
    <>
      <Wrapper style={{ maxWidth: "350px" }}>
        <FirstSectionLoader />
        <SecondSectionWrapper>
          <ActivityLoader />
          <VisitProfile to={"#"}>Visit Profile</VisitProfile>
        </SecondSectionWrapper>
      </Wrapper>
    </>
  );
};

export { DelegatorProfileSidebarSkeleton };
