import { useEffect, useState } from "react";
import { useCurrentProtocol } from "./useCurrentProtocol";
import { sdk } from "../constants/protocols";

export const useAdapterFramework = (protocolCname?: string) => {
  const [adapterFramework, setAdapterFramework] = useState("");
  const protocolInfo = useCurrentProtocol();
  useEffect(() => {
    let protocolInSdk;
    try {
      protocolInSdk = sdk?.getProtocol(protocolCname || protocolInfo?.cname || "");
    } catch (error) {
      console.error(error);
    }

    for (const instance of protocolInSdk?.adapterInstances("proposals") || []) {
      try {
        const adapter = protocolInSdk?.adapter("proposals", instance);
        adapter?.getFramework().then((response) => {
          setAdapterFramework(response);
        });
      } catch (error) {
        console.error(error);
      }
    }
  }, [protocolInfo?.cname, protocolCname]);

  return { adapterFramework };
};
