import React from "react";
import ContentLoader from "react-content-loader";
import { COLORS } from "../../constants/colors";

export const ProposalsListSkeleton = () => (
  <ContentLoader
    speed={2}
    width="100%"
    height={375}
    backgroundColor={COLORS.primary.grayLight}
    foregroundColor={COLORS.primary.grayLighter}
  >
    <circle cx="20" cy="38" r="20" />
    <rect x="60" y="23" rx="5" ry="5" width="50%" height="12" />
    <rect x="60" y="43" rx="5" ry="5" width="70%" height="12" />

    <circle cx="20" cy="114" r="20" />
    <rect x="60" y="99" rx="5" ry="5" width="50%" height="12" />
    <rect x="60" y="119" rx="5" ry="5" width="70%" height="12" />

    <circle cx="20" cy="190" r="20" />
    <rect x="60" y="175" rx="5" ry="5" width="50%" height="12" />
    <rect x="60" y="195" rx="5" ry="5" width="70%" height="12" />

    <circle cx="20" cy="266" r="20" />
    <rect x="60" y="251" rx="5" ry="5" width="50%" height="12" />
    <rect x="60" y="271" rx="5" ry="5" width="70%" height="12" />

    <circle cx="20" cy="342" r="20" />
    <rect x="60" y="327" rx="5" ry="5" width="50%" height="12" />
    <rect x="60" y="347" rx="5" ry="5" width="70%" height="12" />
  </ContentLoader>
);
