import { useQuery } from "@tanstack/react-query";
import axios from "axios";

import { BOARDROOM_API_KEY, baseAPIUrl } from "../constants/general";

interface Props {
  address: string;
  uuid: string;
}

export interface Webhook {
  id: string;
  url: string;
  protocols: string;
  status: string;
  secret: string;
  type: string;
}

export const useGetAddressWebhooks = ({ address, uuid }: Props) => {
  const { data } = useQuery<Webhook[]>(
    ["webhooks", address, uuid],
    async () => {
      try {
        const response = await axios(
          `${baseAPIUrl}webhooks/getSubscribersWebhooks?key=${BOARDROOM_API_KEY}&address=${address}&uuid=${uuid}&bypassCache=${Date.now()}`,
        );
        return response.data || [];
      } catch (error) {
        console.error(error);
        return [];
      }
    },
    {
      enabled: !!address && !!uuid,
    },
  );
  return data;
};
