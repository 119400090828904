import * as React from "react";

const SvgExploreIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 16A8 8 0 108 0a8 8 0 000 16zm2.078-9.2c-.44-.764-2.675-2.013-3.812-2.614a.476.476 0 00-.702.406c-.049 1.284-.084 3.844.357 4.608.442.764 2.676 2.013 3.813 2.614a.476.476 0 00.702-.406c.048-1.284.084-3.844-.358-4.608z"
      fill="currentColor"
    />
  </svg>
);

export default SvgExploreIcon;
