import { protocolInfoList } from "@boardroom/protocol-info";
export const savedProtocolsToArray = (savedProtocols?: string) => {
  if (!savedProtocols) {
    return [];
  }
  const parsedData = JSON.parse(savedProtocols || "{}");
  const protocols: string[] = [];

  for (const protocolName in parsedData) {
    if (parsedData[protocolName]) {
      const protocolObj = protocolInfoList.find((protocol) => protocol.cname === protocolName);
      if (protocolObj) {
        protocols.push(protocolObj.cname);
      }
    }
  }
  return protocols;
};
