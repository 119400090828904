import React from "react";
import styled from "styled-components";
import { useSpring, animated } from "react-spring";
import { COLORS } from "../../../constants/colors";

interface Props {
  day: string;
  isCompact: boolean;
  isInFirstRow: boolean;
}

const Container = styled.div<Pick<Props, "isCompact">>`
  position: absolute;
  top: ${({ isCompact }) => (isCompact ? "5px" : "10px")};
  right: ${({ isCompact }) => (isCompact ? "calc(50% - 6px)" : "12px")};
  width: ${({ isCompact }) => (isCompact ? "12px" : "auto")};
  font-size: ${({ isCompact }) => (isCompact ? "12px" : "15px")};
  line-height: 22px;
  font-weight: 500;
  font-style: normal;
  color: ${COLORS.primary.gray};
  text-align: center;
`;

export const Weekday = ({ day, isCompact, isInFirstRow }: Props) => {
  const animationProps = useSpring({
    opacity: isInFirstRow ? 1 : 0,
  });

  return (
    <animated.div style={animationProps}>
      <Container isCompact={isCompact}>{day}</Container>
    </animated.div>
  );
};
