import { APIResponse, FormattedSubscriptionData } from "@boardroom/boardroom-api";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { baseAPIUrl, BOARDROOM_API_KEY } from "../constants/general";

export const useGetApiSubscriptionDetails = (address: string, uuid: string) => {
  const { data, isLoading } = useQuery<APIResponse<FormattedSubscriptionData>, Error>(
    [`${address}:apiSubDetails:${uuid}`],
    async () => {
      try {
        return (
          await axios.get(`${baseAPIUrl}getAPISubscriptionDetails/${address}?key=${BOARDROOM_API_KEY}&uuid=${uuid}`)
        )?.data;
      } catch (_) {
        return { data: {} };
      }
    },
    {
      enabled: !!uuid && !!address,
    },
  );

  return {
    data: data?.data,
    isLoading: isLoading && !!uuid && !!address,
  };
};
