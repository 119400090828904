import React from "react";
import styled from "styled-components";
import { darken } from "polished";
import AntdButton from "antd/es/button";
import { AnchorButtonProps, NativeButtonProps } from "antd/es/button/button";
import { COLORS } from "../../constants/colors";

type ButtonProps = Omit<AnchorButtonProps & NativeButtonProps, "type" | "size"> & {
  type?: "primary" | "secondary"; //this is done so we can map secondary to default button styles in antd
  size?: "small";
};
interface PropsToButton extends AnchorButtonProps, NativeButtonProps {}

const getBorderColor = (props: Partial<PropsToButton>) => {
  if (props.type === "default") {
    if (props.disabled) {
      return `1px solid ${COLORS.primary.grayDarkLighter}`;
    }
    return `1px solid ${COLORS.primary.accent}`;
  }
  return "none";
};

const getHoverBackground = (props: Partial<PropsToButton>) => {
  if (props.disabled) {
    return "intial";
  }

  if (props.color) {
    return darken(0.1, props.color);
  }

  if (props.type === "default") {
    return COLORS.primary.accent;
  }
  return COLORS.primary.accentDark;
};

const getColor = (props: Partial<PropsToButton>) => {
  if (props.disabled) {
    if (props.type === "primary") {
      return COLORS.primary.grayDarkLightest;
    }
    return COLORS.primary.grayDarkLighter;
  }
  if (props.type === "primary") {
    return "white";
  }

  return COLORS.primary.accentDark;
};
const getHoverColor = (props: Partial<PropsToButton>) => {
  if (props.type === "primary" && props.disabled) {
    return COLORS.primary.grayDarkLightest;
  }
  return "white";
};

const getBackgroundForDisabledButton = (props: Partial<PropsToButton>) => {
  if (props.type === "default") {
    return "white";
  }
};

const ButtonStyles = styled(AntdButton)`
  && {
    border: none;

    padding: ${(props) => (props.size !== "small" ? "16px 44px" : "12px 20px")};
    height: ${(props) => (props.size !== "small" ? "48px" : "38px")};
    font-size: ${(props) => (props.size !== "small" ? "14px" : "12px")};
    line-height: ${(props) => (props.size !== "small" ? "16px" : "14px")};
    font-weight: ${(props) => (props.type === "primary" ? "600" : "400")};

    ${(props) =>
      props.type === "primary" &&
      !props.disabled &&
      `
      background: ${props.color || COLORS.primary.accent};
    `}
    :hover,
    :focus {
      background: ${getHoverBackground};
      color: ${getHoverColor};
    }
    border: ${getBorderColor};
    color: ${getColor};
    &[disabled] {
      background: ${getBackgroundForDisabledButton};
    }
  }
`;

const Button = ({ type, size, color, ...restOfProps }: Partial<ButtonProps>) => {
  return (
    <ButtonStyles
      shape="round"
      {...restOfProps}
      type={type === "secondary" ? "default" : "primary"}
      size={size !== "small" ? "large" : "small"}
      color={color}
    />
  );
};

export default Button;
export type { ButtonProps };
