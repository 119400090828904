import { baseAPIUrl, BOARDROOM_API_KEY } from "../../constants/general";

export interface BodyDataProps {
  protocol: string;
  userAddress: string;
  adapter: string;
  delegateAddress: string;
}

export const updateDelegation = async (
  bodyData: BodyDataProps,
  refetchFunc: any,
  updateAddressFunc?: any,
  retries?: number,
) => {
  try {
    const response = await fetch(`${baseAPIUrl}updateUserDelegation?key=${BOARDROOM_API_KEY}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(bodyData),
    });
    const hasSucceeded = (await response?.clone().json())?.data?.success;
    if (!hasSucceeded && (retries || 0) < 3) {
      setTimeout(() => {
        updateDelegation(bodyData, refetchFunc, updateAddressFunc, retries ? retries + 1 : 1);
      }, 5000);
      return;
    }
    if (refetchFunc) {
      refetchFunc();
    }
    if (hasSucceeded && updateAddressFunc) {
      updateAddressFunc(bodyData.delegateAddress);
    }
  } catch (err) {
    console.log("Sorry, an error occured: ", err);
  }
};
