import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { baseAPIUrl } from "../constants/general";
import { BOARDROOM_API_KEY } from "../constants/general";

export const useGetProposalBriefs = (protocol: string | undefined, suspense = true) => {
  const { data } = useQuery([`${protocol}:briefs`], async () => {
    try {
      return (
        await axios.get(`${baseAPIUrl}blogPosts?protocol=${protocol}&key=${BOARDROOM_API_KEY}`))?.data;
    } catch (_) {
      return { data: {} };
    }
  }, {
    enabled: !!protocol,
    suspense,
  });
  return data;
};