import * as React from "react";

const SvgCsvIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.024 2.878a.146.146 0 00-.146.146v9.366c0 .081.066.147.146.147h9.952c.08 0 .146-.066.146-.147V4.78a.146.146 0 00-.146-.146H8a.439.439 0 01-.337-.158L6.33 2.878H3.024zM2 3.024C2 2.46 2.459 2 3.024 2h3.513c.13 0 .253.058.337.158l1.332 1.598h4.77c.565 0 1.024.459 1.024 1.024v7.61c0 .566-.459 1.025-1.024 1.025H3.024A1.024 1.024 0 012 12.39V3.024z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.067 8.568a.439.439 0 010 .62l-1.759 1.757a.439.439 0 01-.62 0L5.934 9.193a.439.439 0 11.62-.622l1.444 1.443 1.448-1.447a.439.439 0 01.62 0z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 6.098c.242 0 .439.196.439.439v4.097a.44.44 0 11-.878 0V6.537c0-.243.197-.44.439-.44z"
      fill="currentColor"
    />
  </svg>
);

export default SvgCsvIcon;
