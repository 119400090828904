import React, { Suspense, useCallback, useContext } from "react";
import styled from "styled-components";
import ContentLoader from "react-content-loader";
import Tooltip from "antd/es/tooltip";
import { ErrorBoundary } from "react-error-boundary";
import { useCurrentWidth } from "react-socks";

import { COLORS } from "../../constants/colors";
import { Link } from "../Typography";
import ProtocolIcon from "../ProtocolIcon";
import { protocols } from "../../constants/protocols";
import media from "../../media-query";
import { Popover } from "../Popover";
import { DelegateIcon, MultipleUsersIcons, WalletIcon } from "../icons";
import { useMixpanel } from "../../hooks";
import { CurrentAccountContext } from "../../reducers/CurrentAccount";

const ProjectIconsWrapper = styled.span``;

const Wrapper = styled.div`
  margin-bottom: 34px;
  max-width: 400px;
  ${media.lessThan("991px")`
    margin-bottom: 0px;
  `}
`;

const StyledProtocolIcon = styled(ProtocolIcon)`
  margin-right: 8px;
  :hover {
    cursor: pointer;
  }
`;

const StyledPopOverProtocolIcon = styled(StyledProtocolIcon)`
  margin-bottom: 8px;
`;

const StyledInfo = styled.span`
  color: ${COLORS.primary.grayDarkLightest};
  font-size: 14px;
  line-height: 22px;
  margin-right: 8px;
`;

const StyledBold = styled.span`
  font-weight: 600;
`;

const StyledInfoWrapper = styled.div`
  margin-bottom: 20px;
`;

const StyledPopOverWrapper = styled.div`
  max-width: 206px;
  padding: 22px 16px 16px 22px;
  margin: auto;
`;

const StyledMoreProtocolsTxt = styled.span`
  color: ${COLORS.primary.accent};
  border: 1px solid ${COLORS.primary.accent};
  font-weight: 500;
  font-size: 10px;
  border-radius: 10px;
  display: inline-block;
  line-height: 20px;
  padding: 0px 7px;
  cursor: pointer;
`;

const StyledDelegateIcon = styled(DelegateIcon)`
  margin-right: 4px;
`;

const StyledMultipleUsersIcons = styled(MultipleUsersIcons)`
  vertical-align: -2px;
  margin-right: 5px;
  display: inline-block;
`;

const StyledWalletIcon = styled(WalletIcon)`
  vertical-align: -2px;
  margin-right: 5px;
  display: inline-block;
`;

interface Props {
  activeProtocols?: Array<string>;
  addressType?: string;
  isDelegate?: boolean;
  teamWallets?: string[];
}

function ProfileActiveProtocols(props: Props) {
  const { activeProtocols, isDelegate, teamWallets, addressType } = props;
  const isMobile = useCurrentWidth() < 991;
  const { trackNavigateToProtocolFromActiveProtocolsInProfile } = useMixpanel();
  const { account } = useContext(CurrentAccountContext);

  const navigateToProtocol = useCallback(
    (protocol: string) => {
      trackNavigateToProtocolFromActiveProtocolsInProfile({
        userId: account,
        protocol,
      });
    },
    [account, trackNavigateToProtocolFromActiveProtocolsInProfile],
  );

  return (
    <Wrapper>
      <ProjectIconsWrapper>
        <StyledInfoWrapper>
          {teamWallets && teamWallets.length > 1 && (
            <>
              {teamWallets.length > 1 ? (
                addressType === "Org" ? (
                  <StyledMultipleUsersIcons color="#EE3FD9" />
                ) : (
                  <StyledWalletIcon color="#7B7893" />
                )
              ) : null}
              <StyledInfo>
                {teamWallets && teamWallets.length > 1 && (
                  <>
                    <StyledBold>{teamWallets.length}</StyledBold> Wallets
                  </>
                )}
              </StyledInfo>
            </>
          )}

          {!isMobile && (
            <StyledInfo>
              Active in <StyledBold>{activeProtocols?.length || 0}</StyledBold>{" "}
              {activeProtocols?.length == 1 ? "Protocol" : "Protocols"}
            </StyledInfo>
          )}
          {isMobile && isDelegate && (
            <StyledInfo>
              <StyledDelegateIcon color="#7B7893" width={12} height={12} />
              Delegate
            </StyledInfo>
          )}
        </StyledInfoWrapper>
        {activeProtocols &&
          activeProtocols.length > 0 &&
          activeProtocols.map((protocol, index) => {
            const protocolInfo = protocols[protocol];
            if (protocolInfo && index < 8) {
              return (
                <Tooltip title={protocolInfo.name} key={protocolInfo.cname}>
                  <Link onClick={() => navigateToProtocol(protocolInfo?.cname)} to={`/${protocolInfo.path}/overview`}>
                    <StyledProtocolIcon size="xsmall" protocol={protocolInfo} />
                  </Link>
                </Tooltip>
              );
            }
          })}
        {activeProtocols && activeProtocols.length > 8 && (
          <Popover
            content={
              <StyledPopOverWrapper>
                {activeProtocols.map((protocol, index) => {
                  const protocolInfo = protocols[protocol];
                  if (protocolInfo && index >= 8) {
                    return (
                      <Tooltip title={protocolInfo.name} key={protocolInfo.cname}>
                        <Link to={`/${protocolInfo.path}/overview`}>
                          <StyledPopOverProtocolIcon size="xsmall" protocol={protocolInfo} />
                        </Link>
                      </Tooltip>
                    );
                  }
                })}
              </StyledPopOverWrapper>
            }
            placement="topLeft"
            trigger="hover"
            zIndex={20}
          >
            <StyledMoreProtocolsTxt>+{activeProtocols.length - 8}</StyledMoreProtocolsTxt>
          </Popover>
        )}
      </ProjectIconsWrapper>
    </Wrapper>
  );
}

function ProfileActiveProtocolsWrapper(props: Props) {
  return (
    <Suspense
      fallback={
        <div>
          <ContentLoader
            speed={2}
            width="100%"
            height={36}
            backgroundColor={COLORS.primary.grayLight}
            foregroundColor={COLORS.primary.grayLighter}
          >
            <rect x="5" y="12" rx="0" ry="0" width="229" height="18" />
          </ContentLoader>
        </div>
      }
    >
      <ErrorBoundary fallback={<></>} resetKeys={[props.teamWallets]}>
        <ProfileActiveProtocols {...props} />
      </ErrorBoundary>
    </Suspense>
  );
}

export default ProfileActiveProtocolsWrapper;
