import React, { createContext, useState, useContext, useCallback } from "react";

interface Props {
  children: React.ReactNode;
}

interface MobileGlobalMenuType {
  isMenuOpen: boolean;
  setMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleOpen: () => void;
}

export const MobileGlobalMenuContext = createContext({} as MobileGlobalMenuType);

export const MobileGlobalMenuProvider = ({ children }: Props) => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const handleOpen = useCallback(() => {
    setMenuOpen(!isMenuOpen);
  }, [isMenuOpen]);

  return (
    <MobileGlobalMenuContext.Provider value={{ isMenuOpen, setMenuOpen, handleOpen }}>
      {children}
    </MobileGlobalMenuContext.Provider>
  );
};

export const useMobileGlobalMenu = () => {
  const { isMenuOpen, setMenuOpen, handleOpen } = useContext(MobileGlobalMenuContext);
  return { isMenuOpen, setMenuOpen, handleOpen };
};
