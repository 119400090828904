import { APIResponse } from "@boardroom/boardroom-api";
import { useQuery } from "@tanstack/react-query";
import { apiClient } from "../utils/apiClient";
import { Org } from "@boardroom/boardroom-api/dist/orgs";

export const useGetTeam = (id?: string, suspense = false) => {
  const { data: teamDetails } = useQuery<APIResponse<Org>, Error>(
    [`teamDetails:${id}`],
    () => apiClient.getOrgById(id || "", Date.now()),
    {
      enabled: !!id,
      suspense,
    },
  );

  return teamDetails?.data;
};
