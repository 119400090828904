import React, { useMemo } from "react";
import ReactModal from "react-modal";
import styled from "styled-components";

import { Header } from "../../components/Typography";
import { COLORS } from "../../constants/colors";
import { useWindowDimensions } from "../../hooks/useWindowDimensions";
import { CrossIcon } from "../icons";

interface ModalProps {
  children: React.ReactNode;
  open: boolean;
  onClose: () => void;
  size?: "small" | "medium" | "large" | "xlarge" | "xxlarge";
  zIndex?: number;
  withPadding?: boolean;
  background?: string;
  border?: boolean;
  boxShadow?: boolean;
  customMinWidth?: string;
  customMinHeight?: string;
  customMaxHeight?: string;
  customMaxWidth?: string;
  removePaddingBottom?: boolean;
  left?: string;
  closeButtonRight?: string;
  closeButtonTop?: string;
  closeButtonColor?: string;
}

const StyledCloseIcon = styled(CrossIcon)`
  position: fixed;
  top: 24px;
  z-index: 11;
  right: 24px;
  cursor: pointer;
`;

const StyledModal = styled("div")`
  position: relative;
  min-height: 200px;
  min-width: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 99999;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const StyledHeader = styled("div")`
  border-bottom: 1px solid ${COLORS.primary.grayLight};
  padding-bottom: 12px;
  margin-bottom: 20px;
`;

const StyledFooter = styled.div<{ align?: string; justify?: string; $noBorder?: boolean }>`
  border-top: ${(props) => (!props.$noBorder ? `1px solid ${COLORS.primary.grayLight}` : "none")};
  padding-top: 15px;
  margin-top: 15px;
  display: flex;
  justify-content: ${(props) => props.justify || "flex-start"};
  align-items: ${(props) => props.align || "flex-start"};
`;

const getMinHeight = (size: string) => {
  switch (size) {
    case "small":
      return "200px";
    case "medium":
      return "300px";
    case "large":
      return "450px";
    case "xlarge":
      return "600px";
    case "xxlarge":
      return "900px";
  }
};

const getMinWidth = (size: string) => {
  switch (size) {
    case "small":
      return "200px";
    case "medium":
      return "400px";
    case "large":
      return "650px";
    case "xlarge":
      return "800px";
    case "xxlarge":
      return "1100px";
  }
};

const getMaxHeight = (size: string) => {
  if (size === "small") {
    return "600px";
  } else if (size === "medium") {
    return "600px";
  } else if (size === "large") {
    return "800px";
  } else if (size === "xlarge") {
    return "900px";
  } else if (size === "xxlarge") {
    return "1200px";
  } else {
    return "100%";
  }
};

const maxHeightObj = {
  small: 600,
  medium: 600,
  large: 800,
  xlarge: 900,
  xxlarge: 1200,
};

const getMaxWidth = (size: string) => {
  if (size === "small") {
    return "400px";
  } else if (size === "medium") {
    return "600px";
  } else if (size === "large") {
    return "800px";
  } else if (size === "xlarge") {
    return "1200px";
  } else if (size === "xxlarge") {
    return "1400px";
  } else {
    return "100%";
  }
};

function Modal({
  children,
  open,
  onClose,
  size = "medium",
  zIndex = 1000,
  withPadding = true,
  background = "white",
  customMinWidth,
  customMinHeight,
  customMaxHeight,
  customMaxWidth,
  removePaddingBottom,
  left,
  closeButtonRight,
  closeButtonTop,
  closeButtonColor,
}: ModalProps) {
  const { height } = useWindowDimensions();
  const customStyles = useMemo(() => {
    return {
      overlay: {
        background: "rgba(0, 0, 0, 0.3)",
        overflow: "hidden",
        zIndex,
      },
      content: {
        top: "50%",
        left: left ?? "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        maxWidth: "90%",
        transform: "translate(-50%, -50%)",
        borderRadius: "8px",
        boxShadow: "0px 6px 14px rgba(7, 3, 40, 0.05)",
        padding: withPadding ? "24px" : "0px",
        paddingBottom: removePaddingBottom ? "0" : "auto",
        maxHeight: "100vh",
        background: background,
        border: "1px solid rgb(204, 204, 204)",
      },
    };
  }, [zIndex, withPadding, background, removePaddingBottom, left]);

  const maxHeight = maxHeightObj[size];

  return (
    <ReactModal ariaHideApp={false} isOpen={open} onRequestClose={onClose} style={customStyles}>
      <StyledModal
        style={{
          maxHeight: customMaxHeight ? customMaxHeight : maxHeight > height ? height : getMaxHeight(size),
          maxWidth: customMaxWidth ?? getMaxWidth(size),
          minHeight: customMinHeight ? customMinHeight : getMinHeight(size),
          minWidth: customMinWidth ? customMinWidth : getMinWidth(size),
        }}
      >
        {withPadding ? (
          <StyledCloseIcon
            style={{ right: closeButtonRight ?? "", top: closeButtonTop ?? "" }}
            onClick={onClose}
            height={12}
            width={12}
            color={closeButtonColor ?? COLORS.primary.grayDarkLighter}
          />
        ) : null}
        {children}
      </StyledModal>
    </ReactModal>
  );
}

function ModalHeader({ children }: { children: React.ReactNode }) {
  return (
    <StyledHeader>
      <Header level={4}>{children}</Header>
    </StyledHeader>
  );
}

function ModalBody({ children }: { children: React.ReactNode }) {
  return <div>{children}</div>;
}

function ModalFooter({
  children,
  justify,
  align,
  noBorder,
}: {
  children: React.ReactNode;
  justify?: string;
  align?: string;
  noBorder?: boolean;
}) {
  return (
    <StyledFooter align={align} justify={justify} $noBorder={noBorder}>
      {children}
    </StyledFooter>
  );
}

Modal.Title = ModalHeader;
Modal.Body = ModalBody;
Modal.Footer = ModalFooter;

export default Modal;
