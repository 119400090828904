import React, { useReducer, createContext, useContext, useEffect } from "react";
import { CurrentAccountContext } from "./CurrentAccount";

interface Props {
  children: React.ReactNode;
}

type Action = { type: "TOGGLE_WALLET_DRAWER"; data: boolean };

interface WalletDrawerContextType {
  isWalletDrawerOpen: boolean;
  dispatchIsWalletDrawerOpen: React.Dispatch<any>;
}

export const WalletDrawerContext = createContext({} as WalletDrawerContextType);

const reducer = (state: boolean, action: Action) => {
  switch (action.type) {
    case "TOGGLE_WALLET_DRAWER":
      return action.data;
    default:
      return state;
  }
};

export const WalletDrawerProvider = (props: Props) => {
  const { account } = useContext(CurrentAccountContext);
  const [isWalletDrawerOpen, dispatchIsWalletDrawerOpen] = useReducer(reducer, false);

  useEffect(() => {
    dispatchIsWalletDrawerOpen({ type: "TOGGLE_WALLET_DRAWER", data: false });
  }, [account]);

  return (
    <WalletDrawerContext.Provider value={{ isWalletDrawerOpen, dispatchIsWalletDrawerOpen }}>
      {props.children}
    </WalletDrawerContext.Provider>
  );
};
