import * as React from "react";

const SvgNoteIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 2.386c0-.439.356-.795.795-.795h2.228v.954H.955v8.5h8.59v-8.5H7.477v-.954h2.228c.439 0 .795.356.795.795v8.819c0 .439-.356.795-.795.795H.795A.795.795 0 010 11.204V2.386z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.25.955a.477.477 0 00-.477.477v.62a.493.493 0 01-.493.493H3.5V3.5H7v-.955h-.78a.493.493 0 01-.493-.493v-.62A.477.477 0 005.25.955zm-1.432.477a1.432 1.432 0 112.864 0v.159h1.273v2.068c0 .44-.357.796-.796.796H3.341a.795.795 0 01-.796-.796V1.591h1.273v-.16z"
      fill="currentColor"
    />
  </svg>
);

export default SvgNoteIcon;
