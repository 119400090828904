import * as React from "react";

const SvgPapers = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M1.214 17.5H2.43v1.25c0 .338.113.625.341.86.253.26.544.39.873.39h12.143c.329 0 .607-.13.835-.39.253-.235.379-.521.379-.86v-15c0-.339-.127-.625-.38-.86a1.068 1.068 0 00-.834-.39H14.57V1.25c0-.339-.126-.625-.379-.86a1.068 1.068 0 00-.835-.39H1.214C.885 0 .594.13.342.39.114.626 0 .912 0 1.25v15c0 .338.114.638.342.898.252.235.543.352.872.352zm12.143-1.25H1.214v-15h12.143v15zm2.429-12.5v15H3.643V17.5h9.714c.329 0 .607-.117.835-.352.253-.26.38-.56.38-.898V3.75h1.214zm-10.777.195c-.101.104-.152.248-.152.43s.05.339.152.469A.693.693 0 005.464 5h6.072a.555.555 0 00.417-.156.649.649 0 00.19-.469.527.527 0 00-.19-.43.5.5 0 00-.417-.195H5.464a.612.612 0 00-.455.195zM3.036 8.75h8.5a.555.555 0 00.417-.156.649.649 0 00.19-.469.527.527 0 00-.19-.43.5.5 0 00-.417-.195h-8.5a.612.612 0 00-.456.195c-.1.104-.151.248-.151.43s.05.339.151.469a.693.693 0 00.456.156zm0 2.5h8.5a.555.555 0 00.417-.156.65.65 0 00.19-.469.527.527 0 00-.19-.43.5.5 0 00-.417-.195h-8.5a.612.612 0 00-.456.195c-.1.104-.151.248-.151.43s.05.338.151.469a.694.694 0 00.456.156zm0 2.5h8.5a.555.555 0 00.417-.156.65.65 0 00.19-.469.527.527 0 00-.19-.43.5.5 0 00-.417-.195h-8.5a.612.612 0 00-.456.195c-.1.104-.151.248-.151.43s.05.338.151.469a.694.694 0 00.456.156z"
      fill="#4235E1"
    />
  </svg>
);

export default SvgPapers;
