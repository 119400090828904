import { DefaultTheme } from "styled-components";
import { COLORS } from "./constants/colors";

const myTheme: DefaultTheme = {
  colors: {
    ...COLORS,
  },
};

export default myTheme;
