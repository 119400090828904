import * as React from "react";

const SvgLongArrowLeftIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M2.15 12a.75.75 0 01.75-.75h18a.75.75 0 010 1.5h-18a.75.75 0 01-.75-.75z" fill="currentColor" />
    <path
      d="M9.43 5.47a.75.75 0 010 1.06L3.96 12l5.47 5.47a.75.75 0 11-1.06 1.06l-6-6a.75.75 0 010-1.06l6-6a.75.75 0 011.06 0z"
      fill="currentColor"
    />
  </svg>
);

export default SvgLongArrowLeftIcon;
