import React from "react";
import ContentLoader from "react-content-loader";

import { COLORS } from "../../constants/colors";
import {
  HeaderWrapper,
  ProjectsFilterWrapper,
  ProtocolCardsWrapper,
  SectionTitle,
  ShareDiv,
  Wrapper,
  YourProjectsTitle,
} from "./styles";
import { ThinShareIcon } from "../icons";
import { DropDown } from "../ProposalsFilters/ProposalsFilters";

export const DelegateYourProjectsSkeleton = () => {
  return (
    <Wrapper>
      <HeaderWrapper>
        <YourProjectsTitle>Your Projects</YourProjectsTitle>
        <ShareDiv>
          <ThinShareIcon /> Share
        </ShareDiv>
      </HeaderWrapper>
      <ProjectsFilterWrapper style={{ marginTop: "110px" }}>
        <DropDown
          onChange={() => null}
          currentValue={{
            name: "All Projects",
            value: "all",
          }}
          items={[]}
        />
      </ProjectsFilterWrapper>
      <div>
        <SectionTitle>Delegated to you</SectionTitle>
        <ProtocolCardsWrapper>
          <ContentLoader
            speed={2}
            width="100%"
            height={100}
            backgroundColor={COLORS.primary.grayLight}
            foregroundColor={COLORS.primary.grayLighter}
          >
            <circle x="0" cx="30" cy="30" r="16" />
            <rect x="60" y="22" rx="5" ry="5" width="80%" height="16" />
          </ContentLoader>
        </ProtocolCardsWrapper>
      </div>
      <div style={{ marginTop: "36px" }}>
        <SectionTitle>Delegated</SectionTitle>
        <ProtocolCardsWrapper>
          <ContentLoader
            speed={2}
            width="100%"
            height={100}
            backgroundColor={COLORS.primary.grayLight}
            foregroundColor={COLORS.primary.grayLighter}
          >
            <circle x="0" cx="30" cy="30" r="16" />
            <rect x="60" y="22" rx="5" ry="5" width="80%" height="16" />
          </ContentLoader>
        </ProtocolCardsWrapper>
      </div>
      <div style={{ marginTop: "36px" }}>
        <SectionTitle>Undelegated</SectionTitle>
        <ProtocolCardsWrapper>
          <ContentLoader
            speed={2}
            width="100%"
            height={100}
            backgroundColor={COLORS.primary.grayLight}
            foregroundColor={COLORS.primary.grayLighter}
          >
            <circle x="0" cx="30" cy="30" r="16" />
            <rect x="60" y="22" rx="5" ry="5" width="80%" height="16" />
          </ContentLoader>
        </ProtocolCardsWrapper>
      </div>
    </Wrapper>
  );
};
