import React, { useCallback } from "react";
import styled from "styled-components";
import { PropGetters } from "downshift";
import { CheckCircleIcon } from "../icons";
import { COLORS } from "../../constants/colors";
import { Item } from "./DropdownButton";

interface Props {
  items: Item[];
  selected: Item | Item[] | null;
  isOpen: boolean;
  getMenuProps: PropGetters<Item>["getMenuProps"];
  getItemProps: PropGetters<Item>["getItemProps"];
  removeItem?: (item: Item) => void;
}

const Container = styled.div`
  position: relative;
  z-index: 5;
`;

const List = styled.ul<{ isOpen: boolean }>`
  position: absolute;
  margin-top: 10px;
  padding: 10px;
  width: fit-content;
  list-style: none;
  background: #fff;
  box-shadow: 0px 4px 8px rgba(7, 3, 40, 0.09);
  border-radius: 8px;
  text-align: left;
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  display: ${({ isOpen }) => (isOpen ? "block" : "none")};
  visibility: ${({ isOpen }) => (isOpen ? "visible" : "hidden")};
  transition: ${({ isOpen }) => (isOpen ? "all 0.2s" : "none")};
`;

const DropdownItem = styled.li`
  padding: 10px;
  height: 36px;
  display: flex;
  align-items: center;
  background: none;
  color: ${COLORS.primary.grayDarkLightest};
  border-radius: 5px;
  font-size: 14px;
  line-height: 16px;
  font-style: normal;
  font-weight: normal;
  cursor: pointer;
  transition: all 0.2s;
  user-select: none;

  :hover {
    background: ${COLORS.primary.accentLighter};
    color: ${COLORS.primary.accent};
  }
`;

const DropdownItemText = styled.span`
  margin-right: auto;
  width: 85%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const SelectedItemIconContainer = styled.div<{ isVisible: boolean }>`
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  line-height: 0;
  transition: opacity 0.2s;
  padding-left: 5px;
`;

export const DropdownItemList = ({ items, selected, isOpen, getMenuProps, getItemProps, removeItem }: Props) => {
  const isSelected = useCallback(
    (item: Item) => {
      if (Array.isArray(selected)) {
        return !!selected.find((selectedItem) => selectedItem.value === item.value);
      }

      return selected?.value === item.value;
    },
    [selected],
  );

  const getRemoveItemClickHandler = useCallback(
    (item: Item) => {
      if (isSelected(item)) {
        return {
          onClick: () => removeItem && removeItem(item),
        };
      }

      return {};
    },
    [isSelected, removeItem],
  );

  return (
    <Container>
      <List isOpen={isOpen} {...getMenuProps()}>
        {items.map((item, index) => (
          <DropdownItem
            key={`${item.value}-${index}`}
            {...getItemProps({ item, index })}
            {...getRemoveItemClickHandler(item)}
          >
            <DropdownItemText>{item.name}</DropdownItemText>

            <SelectedItemIconContainer isVisible={isSelected(item)}>
              <CheckCircleIcon width={20} height={20} color="#4235e1" />
            </SelectedItemIconContainer>
          </DropdownItem>
        ))}
      </List>
    </Container>
  );
};
