import React, { useCallback, useState } from "react";
import { useHistory, useLocation } from "react-router";
import CopyToClipboard from "react-copy-to-clipboard";
import { TwitterShareButton } from "react-share";
import moment from "moment";

import {
  ProjectCardWrapper,
  ProtocolImg,
  Wrapper,
  ProtocolName,
  DelegatorCount,
  ProtocolInfoWrapper,
  VotePowerWrapper,
  VotePowerName,
  VotePowerValue,
  ProtocolCardsWrapper,
  SectionTitle,
  SectionInfo,
  ExploreDelegates,
  SeeActivity,
  EditDelegation,
  ShareWrapper,
  HeaderWrapper,
  ProjectsFilterWrapper,
  NewUpdatesText,
  PinkCircle,
} from "./styles";
import { useProtocolIcon } from "../../hooks/useProtocolIcon";
import { protocols } from "../../constants/protocols";
import { useDelegatorCount } from "../../hooks/useDelegatorCount";
import { LightningIcon, ThinShareIcon, ThreeDotMenuIcon, TwitterOutlinedIcon } from "../icons";
import { useVotePower } from "../../hooks/useVotePower";
import formatValue from "../../utils/formatValue";
import { Pfp } from "../Pfp/Pfp";
import { useUserDetails } from "../../hooks/useUserDetails";
import { useGetEns } from "../../hooks/useEns";
import { toShortAddress } from "../../utils";
import DelegationModal from "../Voting/DelegationModal";
import { DropDown } from "../ProposalsFilters/ProposalsFilters";
import { Popover } from "../Popover";
import {
  ShareText,
  StyledCopiedIcon,
  StyledCopiedText,
  StyledCopyIcon,
  StyledCopyText,
} from "../DelegateMessageBoard/styles";
import { useDelegatePosts } from "../../hooks/useDelegatePosts";

const DelegatedToSelfCard = ({
  project,
  address,
  onClick,
}: {
  project: string;
  address: string;
  onClick: (protocol: string) => void;
}) => {
  const { url } = useProtocolIcon({ protocolCname: project });
  const protocolInfo = protocols[project];
  const { delegatorCount } = useDelegatorCount({ address, protocol: project });
  const votePower = useVotePower(project, false, address);
  return (
    <ProjectCardWrapper onClick={() => onClick(project)}>
      <ProtocolImg src={url} alt={`${protocolInfo?.name} Logo`} />
      <ProtocolInfoWrapper>
        <ProtocolName>{protocolInfo?.name}</ProtocolName>
        <DelegatorCount className="hoverable">
          {delegatorCount?.delegatorCount} Delegator{(delegatorCount?.delegatorCount || 0) > 1 ? "s" : ""}
        </DelegatorCount>
      </ProtocolInfoWrapper>
      {!!votePower && typeof votePower === "number" && (
        <VotePowerWrapper>
          <VotePowerName>
            VP{" "}
            <VotePowerValue>
              <LightningIcon />
              {formatValue(votePower, 2)}
            </VotePowerValue>
          </VotePowerName>
        </VotePowerWrapper>
      )}
    </ProjectCardWrapper>
  );
};

const DelegatedToOtherCard = ({
  project,
  address,
  onClick,
}: {
  project: string;
  address: string;
  onClick: (protocol: string) => void;
}) => {
  const [copied, setCopied] = useState(false);
  const [isDelegationModalVisible, setIsDelegationModalVisible] = useState(false);
  const { url } = useProtocolIcon({ protocolCname: project });
  const protocolInfo = protocols[project];
  const { user } = useUserDetails({ address: address, suspense: false });
  const ens = useGetEns(address);
  const { delegatePosts } = useDelegatePosts({ author: address, protocol: project, suspense: false });

  const hasDelegatePostInLastWeek = delegatePosts?.some((post) => {
    const postDate = moment(Number(post.createdAt) || 0);
    const today = moment();
    return postDate.isAfter(today.subtract(7, "days"));
  });

  const handleVisibleChange = useCallback((visible: boolean) => {
    if (!visible) {
      setTimeout(() => {
        setCopied(false);
      }, 100);
    }
  }, []);

  const onCopy = useCallback(() => {
    setCopied(true);
  }, []);

  return (
    <>
      <DelegationModal
        visible={isDelegationModalVisible}
        setVisible={setIsDelegationModalVisible}
        protocolName={project}
      />
      <ProjectCardWrapper onClick={() => onClick(project)}>
        <ProtocolImg src={url} alt={`${protocolInfo?.name} Logo`} />
        <ProtocolInfoWrapper>
          {hasDelegatePostInLastWeek && <NewUpdatesText>NEW UPDATES</NewUpdatesText>}
          <ProtocolName>{protocolInfo?.name}</ProtocolName>
          <DelegatorCount className="hoverable">{user?.username || ens || toShortAddress(address)}</DelegatorCount>
        </ProtocolInfoWrapper>
        <VotePowerWrapper className="hideOnHover">
          {hasDelegatePostInLastWeek && <PinkCircle />}
          <Pfp pfpUrl={user?.pfpUrl} size="medium" address={address} dimension={40} />
          <ThreeDotMenuIcon style={{ marginLeft: "20px" }} color="#7B7893" width={16} height={16} />
        </VotePowerWrapper>
        <div className="showOnHover">
          <SeeActivity onClick={(e) => e.stopPropagation()} to={`/voter/${address}`}>
            See Activity
          </SeeActivity>
          <EditDelegation
            onClick={(e) => {
              e.stopPropagation();
              setIsDelegationModalVisible(true);
            }}
          >
            Edit Delegation
          </EditDelegation>
          <Popover
            trigger="hover"
            placement="topRight"
            zIndex={1000}
            onVisibleChange={handleVisibleChange}
            content={
              <div onClick={(e) => e.stopPropagation()}>
                {!copied ? (
                  <CopyToClipboard text={`https://boardroom.io/people/${project}/${address}`} onCopy={onCopy}>
                    <StyledCopyText>
                      <StyledCopyIcon />
                      Copy Link
                    </StyledCopyText>
                  </CopyToClipboard>
                ) : (
                  <StyledCopiedText>
                    <StyledCopiedIcon />
                    Link Copied!
                  </StyledCopiedText>
                )}
                <TwitterShareButton
                  url={`https://boardroom.io/people/${project}/${address}`}
                  via={"boardroom_info"}
                  title={`View my delegation page for ${protocolInfo?.name} on Boardroom`}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <TwitterOutlinedIcon width={16} height={16} color="#4235E1" />
                  <ShareText>Tweet</ShareText>
                </TwitterShareButton>
              </div>
            }
          >
            <ShareWrapper>
              <ThinShareIcon width={20} height={20} />
            </ShareWrapper>
          </Popover>
        </div>
      </ProjectCardWrapper>
    </>
  );
};

const UndelegatedCard = ({ project }: { project: string }) => {
  const { url } = useProtocolIcon({ protocolCname: project });
  const protocolInfo = protocols[project];
  return (
    <ProjectCardWrapper as="a" href={`/${protocolInfo?.path}/delegates`}>
      <ProtocolImg src={url} alt={`${protocolInfo?.name} Logo`} />
      <ProtocolInfoWrapper>
        <ProtocolName>{protocolInfo?.name}</ProtocolName>
        <DelegatorCount className="hoverable">Undelegated</DelegatorCount>
      </ProtocolInfoWrapper>
      <ExploreDelegates to={`/${protocolInfo?.path}/delegates`}>Explore Delegates</ExploreDelegates>
    </ProjectCardWrapper>
  );
};

const projectsFilterItems = [
  {
    name: "All Projects",
    value: "all",
  },
  {
    name: "Delegated to You",
    value: "delegatedToSelf",
  },
  {
    name: "Delegated Projects",
    value: "delegatedToOthers",
  },
  {
    name: "Undelegated Projects",
    value: "undelegated",
  },
];

export const DelegateYourProjects = ({
  delegatedProjects,
  undelegatedProjects,
  delegatedToSelfProjects,
  address,
  setSelectedProtocol,
  updateAddressToUse,
  hasBanner,
}: {
  delegatedProjects: Map<string, string>;
  undelegatedProjects: string[];
  delegatedToSelfProjects: string[];
  address: string;
  setSelectedProtocol: React.Dispatch<React.SetStateAction<string>>;
  updateAddressToUse: any;
  hasBanner?: boolean;
}) => {
  const [projectsFilter, setProjectsFilter] = useState("all");
  const { search } = useLocation();
  const history = useHistory();

  const onClick = useCallback(
    (protocol: string) => {
      setSelectedProtocol(protocol);
      const addressToSet = updateAddressToUse(protocol);
      history.push(`/people/${protocol}/${addressToSet}${search}`);
    },
    [setSelectedProtocol, updateAddressToUse, history, search],
  );

  return (
    <Wrapper>
      <HeaderWrapper $hasBanner={hasBanner}>
        <ProjectsFilterWrapper>
          <DropDown
            onChange={(value) => setProjectsFilter(value?.value || "all")}
            currentValue={projectsFilterItems?.find((item) => item.value === projectsFilter) || projectsFilterItems[0]}
            items={projectsFilterItems}
          />
        </ProjectsFilterWrapper>
      </HeaderWrapper>
      {delegatedToSelfProjects.length > 0 && (projectsFilter === "all" || projectsFilter === "delegatedToSelf") && (
        <div>
          <SectionTitle>
            Delegated to you ·{" "}
            <SectionInfo>
              <span style={{ fontWeight: "600" }}>{delegatedToSelfProjects.length}</span> Projects
            </SectionInfo>{" "}
          </SectionTitle>
          <ProtocolCardsWrapper>
            {delegatedToSelfProjects?.map((project) => (
              <DelegatedToSelfCard onClick={onClick} address={address} key={project} project={project} />
            ))}
          </ProtocolCardsWrapper>
        </div>
      )}
      {delegatedProjects.size > 0 && (projectsFilter === "all" || projectsFilter === "delegatedToOthers") && (
        <div style={{ marginTop: projectsFilter === "all" ? "36px" : "" }}>
          <SectionTitle>
            Delegated ·{" "}
            <SectionInfo>
              <span style={{ fontWeight: "600" }}>{delegatedProjects.size}</span> Projects
            </SectionInfo>
          </SectionTitle>
          <ProtocolCardsWrapper>
            {Array.from(delegatedProjects?.keys())?.map((project) => (
              <DelegatedToOtherCard
                onClick={onClick}
                address={delegatedProjects.get(project) || ""}
                key={project}
                project={project}
              />
            ))}
          </ProtocolCardsWrapper>
        </div>
      )}
      {undelegatedProjects.length > 0 && (projectsFilter === "all" || projectsFilter === "undelegated") && (
        <div style={{ marginTop: projectsFilter === "all" ? "36px" : "" }}>
          <SectionTitle>
            Undelegated ·{" "}
            <SectionInfo>
              <span style={{ fontWeight: "600" }}>{undelegatedProjects.length}</span> Projects
            </SectionInfo>
          </SectionTitle>
          <ProtocolCardsWrapper>
            {undelegatedProjects?.map((project) => (
              <UndelegatedCard key={project} project={project} />
            ))}
          </ProtocolCardsWrapper>
        </div>
      )}
    </Wrapper>
  );
};
