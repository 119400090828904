import { PendingVoteDetails } from "@boardroom/boardroom-api";
import React, { useReducer, createContext } from "react";

interface Props {
  children: React.ReactNode;
}

type Action = { type: "SAVE_PENDING_VOTE_DETAILS"; data: Array<PendingVoteDetails> };

interface PendingVoteDetailsContextType {
  pendingVoteDetails: Record<string, PendingVoteDetails>;
  dispatchPendingVoteDetails: React.Dispatch<any>;
}

const initialState = {};

export const PendingVoteDetailsContext = createContext({} as PendingVoteDetailsContextType);

const reducer = (state: Record<string, PendingVoteDetails> | {}, action: Action) => {
  switch (action.type) {
    case "SAVE_PENDING_VOTE_DETAILS": {
      const indexByProposalId = action.data?.reduce(
        (acc, vote) => ({
          ...acc,
          [vote.proposalRefId]: vote,
        }),
        {},
      );
      return {
        ...state,
        ...indexByProposalId,
      };
    }
    default:
      return state;
  }
};

export const PendingVoteDetailsProvider = (props: Props) => {
  const [pendingVoteDetails, dispatchPendingVoteDetails] = useReducer(reducer, initialState);

  return (
    <PendingVoteDetailsContext.Provider value={{ pendingVoteDetails, dispatchPendingVoteDetails }}>
      {props.children}
    </PendingVoteDetailsContext.Provider>
  );
};
