import * as React from "react";

const SvgBookmarkFilledIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M3 14V3.333C3 2.597 3.597 2 4.333 2H11c.736 0 1.333.597 1.333 1.333V14l-3.945-2.537a1.333 1.333 0 00-1.442 0L3 14z"
      fill="currentColor"
    />
  </svg>
);

export default SvgBookmarkFilledIcon;
