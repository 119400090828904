import React, { useState, useCallback, useRef } from "react";
import styled from "styled-components";
import AntdCalendar from "antd/es/calendar";
import moment, { Moment } from "moment";

import Header from "./Header";
import { WeekdayHeading } from "./WeekdayHeading";
import DateCell from "./DateCell";
import { getEventsByDate } from "../../../utils";
import { COLORS } from "../../../constants/colors";
import { CalendarEvents } from "../../../types";
import { isDateToday } from "../utils";

interface Props {
  events?: CalendarEvents;
  isSelectable?: boolean;
  onSelect?: (date: Moment) => void;
}

const Container = styled.div`
  user-select: none;
  padding: 40px 0 0;

  && .ant-picker-body {
    padding: 0;
    border-top: 1px solid ${COLORS.primary.grayLight};
    overflow: hidden;
  }

  .ant-picker-content thead {
    display: none;
  }

  .ant-picker-content tr:first-child td {
    border-top: 0;
  }

  .ant-picker-content tr th:first-child,
  .ant-picker-content tr td:first-child {
    border-left: 0;
  }

  .ant-picker-content tr th:last-child,
  .ant-picker-content tr td:last-child {
    border-right: 0;
  }

  .ant-picker-content tr:last-child td {
    border-bottom: 0;
  }
`;

const Calendar = ({ events = {}, onSelect }: Props) => {
  const [selectedDate, setSelectedDate] = useState(moment());
  const containerElement = useRef<HTMLDivElement>(null);

  const selectDate = useCallback(
    (date: Moment) => {
      setSelectedDate(moment(date));

      if (onSelect) {
        onSelect(date);
      }
    },
    [onSelect, setSelectedDate],
  );

  const goToPreviousMonth = useCallback(() => {
    setSelectedDate(moment(selectedDate).startOf("month").subtract(1, "month"));
  }, [selectedDate, setSelectedDate]);

  const goToNextMonth = useCallback(() => {
    setSelectedDate(moment(selectedDate).startOf("month").add(1, "month"));
  }, [selectedDate, setSelectedDate]);

  const isDateSelected = useCallback(
    (date: Moment) => moment(date).isSame(moment(selectedDate), "day"),
    [selectedDate],
  );

  const isDateInView = useCallback(
    (date: Moment) => moment(date).isSame(moment(selectedDate), "month"),
    [selectedDate],
  );

  const isDateInFirstRow = useCallback(
    (date: Moment) =>
      moment(date).isSame(moment(selectedDate).startOf("month"), "week") &&
      moment(date).subtract(1, "week").isSame(moment(selectedDate).subtract(1, "month"), "month"),
    [selectedDate],
  );

  const isDateDisabled = useCallback(
    (date: Moment) => {
      return !isDateInView(date);
    },
    [isDateInView],
  );

  return (
    <Container ref={containerElement}>
      <AntdCalendar
        value={selectedDate}
        fullscreen
        onSelect={selectDate}
        disabledDate={isDateDisabled}
        headerRender={() => (
          <>
            <Header date={selectedDate} goToPreviousMonth={goToPreviousMonth} goToNextMonth={goToNextMonth} />
            <WeekdayHeading />
          </>
        )}
        dateFullCellRender={(date) => (
          <DateCell
            date={date}
            events={getEventsByDate(events, date)}
            isToday={isDateToday(date)}
            isSelected={isDateSelected(date)}
            isInView={isDateInView(date)}
            isInFirstRow={isDateInFirstRow(date)}
            container={containerElement.current}
            containerWidth={containerElement.current?.offsetWidth || 0}
          />
        )}
      />
    </Container>
  );
};

export default React.memo(Calendar);
