import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { DiscourseCategory, DiscouseForumData } from "../types";

interface Props {
  latestOrTop: string;
  category: string;
  tag: string;
  url: string;
  topPeriod: string;
  categories: DiscourseCategory[];
  suspense?: boolean;
}

export const useDiscourseData = ({
  latestOrTop,
  category,
  tag,
  url,
  topPeriod,
  categories,
  suspense = true,
}: Props) => {
  const { data: discourseData, error } = useQuery<DiscouseForumData["topic_list"], Error>(
    [`discourse:${latestOrTop}:${category}:${tag}:${topPeriod}:${url}`],
    async () => {
      if (category === "all" && tag === "all") {
        if (latestOrTop === "latest") {
            const request = await axios.post("https://my-app.boardroom.workers.dev/", {
              apiUrl: `${url}/latest.json?order=activity&ascending=false`,
            });
            const response = await request.data;
            return response.topic_list;

        } else {
            const request = await axios.post("https://my-app.boardroom.workers.dev/", {
              apiUrl: `${url}/top.json?period=${topPeriod}`,
            });
            const response = await request.data;
            return response.topic_list;

        }
      } else if (category !== "all" && tag === "all") {
          const request = await axios.post("https://my-app.boardroom.workers.dev/", {
            apiUrl: `${url}/c/${category}/${
              categories.find((categoryInArray) => categoryInArray.slug === category)?.id
            }.json`,
          });
          const response = await request.data;
          return response.topic_list;

      } else if (tag !== "all" && category === "all") {
          const request = await axios.post("https://my-app.boardroom.workers.dev/", {
            apiUrl: `${url}/tag/${tag}.json`,
          });
          const response = await request.data;
          return response.topic_list;

      }
    },
    { suspense: suspense, useErrorBoundary: false }
  );

  return { discourseData, error };
};

export const useDiscourseCategories = ({ url }: { url: string }) => {
  const { data: discourseCategories, error: errorCategories } = useQuery<DiscourseCategory[], Error>([`discourse:categories:${url}`], async () => {
    try {
      const requestCategories = await axios.post("https://my-app.boardroom.workers.dev/", {
        apiUrl: `${url}/categories.json`,
      });
      const responseCategories = await requestCategories.data?.category_list?.categories;
      return responseCategories;
    } catch (error) {
      console.error(error);
    }
  });

  return { discourseCategories, errorCategories };
};
