import React, { useReducer, createContext } from "react";

interface Props {
  children: React.ReactNode;
}

type Action = { type: "SAVE_PROTOCOL_OVERVIEW_CONTAINER_REF"; data: React.RefObject<HTMLElement> | null };

interface ProtocolOverviewContainerRefContextType {
  containerRef: React.RefObject<HTMLElement> | null;
  dispatchContainerRef: React.Dispatch<any>;
}

const initialState = null;

export const ProtocolOverviewContainerRefContext = createContext({} as ProtocolOverviewContainerRefContextType);

const reducer = (state: React.RefObject<HTMLElement> | null, action: Action) => {
  switch (action.type) {
    case "SAVE_PROTOCOL_OVERVIEW_CONTAINER_REF":
      return action.data;
    default:
      return state;
  }
};

export const ProtocolOverviewContainerRefProvider = (props: Props) => {
  const [containerRef, dispatchContainerRef] = useReducer(reducer, initialState);

  return (
    <ProtocolOverviewContainerRefContext.Provider value={{ containerRef, dispatchContainerRef }}>
      {props.children}
    </ProtocolOverviewContainerRefContext.Provider>
  );
};
