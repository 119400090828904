import * as React from "react";

const SvgWalletVariationIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M2.5 5.417V3.833c0-.235 0-.353.073-.426.073-.074.191-.074.427-.074h11.667c.943 0 1.414 0 1.707.293.293.293.293.765.293 1.707V7c0 .236 0 .354-.074.427-.073.073-.19.073-.426.073H12.5m-10-2.083V7c0 .236 0 .354.073.427.073.073.191.073.427.073h12.5c.943 0 1.414 0 1.707.293.293.293.293.764.293 1.707v1.333m-15-5.416V15.5c0 .943 0 1.414.293 1.707.293.293.764.293 1.707.293H17c.236 0 .354 0 .427-.073.073-.073.073-.191.073-.427v-2.833m0 0H13c-.236 0-.354 0-.427-.074-.073-.073-.073-.19-.073-.426v-2.334c0-.235 0-.353.073-.426.073-.074.191-.074.427-.074h4.5m0 3.334v-3.334"
      stroke="currentColor"
      strokeWidth={1.25}
    />
  </svg>
);

export default SvgWalletVariationIcon;
