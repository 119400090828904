import { ReactNode } from "react";

type Voting = {
  title: string;
  desc: string;
};

type VotingType = {
  basic: Voting;
  approval: Voting;
  approvalVoting: Voting;
  weighted: Voting;
  "single-choice": Voting;
  "ranked-choice": Voting;
  quadratic: Voting;
};

export type VotingTypeUI = {
  basic: ReactNode;
  approval: ReactNode;
  approvalVoting: ReactNode;
  weighted: ReactNode;
  "single-choice": ReactNode;
  "ranked-choice": ReactNode;
  quadratic: ReactNode;
};

export const votingTypes: VotingType = {
  basic: {
    title: "Basic Voting",
    desc: "Single choice voting with three choices",
  },
  approval: {
    title: "Approval Voting",
    desc: "Multiple choices may be selected",
  },
  approvalVoting: {
    title: "Approval Voting",
    desc: "Multiple choices may be selected",
  },
  weighted: {
    title: "Weighted Voting",
    desc: "Distribute your vote power across choices",
  },
  "single-choice": {
    title: "Single Choice Voting",
    desc: "Only one choice may be selected",
  },
  "ranked-choice": {
    title: "Ranked Choice Voting",
    desc: "Move or drag choices to rank your vote.",
  },
  quadratic: {
    title: "Quadratic Choice Voting",
    desc: "Distribute your vote power across choices",
  },
};
