export default function formatValue(amount: number | string | undefined, decimalPlaces: number = 3) {
  if (!amount) return amount;
  const value = typeof amount === "string" ? parseFloat(amount) : amount;

  // Nine Zeroes for Billions
  return Math.abs(Number(value)) >= 1.0e9
    ? parseFloat((Math.abs(Number(value)) / 1.0e9).toFixed(decimalPlaces)) + "B"
    : // Six Zeroes for Millions
    Math.abs(Number(value)) >= 1.0e6
    ? parseFloat((Math.abs(Number(value)) / 1.0e6).toFixed(decimalPlaces)) + "M"
    : // Three Zeroes for Thousands
    Math.abs(Number(value)) >= 1.0e3
    ? parseFloat((Math.abs(Number(value)) / 1.0e3).toFixed(decimalPlaces)) + "K"
    : parseFloat(Math.abs(Number(value)).toFixed(decimalPlaces)).toLocaleString("en-US", {
        maximumFractionDigits: 3,
      });
}
