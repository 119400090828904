import React, { useCallback, useContext } from "react";
import styled, { css } from "styled-components";
import { PropGetters } from "downshift";
import { Link, useLocation } from "react-router-dom";

import { COLORS } from "../../constants/colors";
import { Item } from "./types";
import { SettingsAlternateIcon } from "../icons";
import { useMixpanel } from "../../hooks";
import { CurrentAccountContext } from "../../reducers/CurrentAccount";
import mediaQuery from "../../media-query";
import { isTeamView } from "../../utils/teamUtils";
import { useSiweFunction } from "../../hooks/useSiweFunction";
import { RoutePaths } from "../../constants/Routes";

interface Props {
  items: Item[];
  selected: Item | Item[] | null;
  isOpen: boolean;
  getMenuProps: PropGetters<Item>["getMenuProps"];
  getItemProps: PropGetters<Item>["getItemProps"];
  removeItem?: (item: Item) => void;
  hasCtaButton?: boolean;
  hasTeam?: boolean;
  hasCustomized?: boolean;
  isProjectsFilter?: boolean;
  isVotingSystemsFilter?: boolean;
  isSignedIn?: boolean;
  removeRight0?: boolean;
}

const Container = styled.div``;

const List = styled.ul<{ isOpen: boolean; $hasCtaButton?: boolean; $removeRight0?: boolean }>`
  position: absolute;
  margin-top: 10px;
  padding: ${({ $hasCtaButton }) => ($hasCtaButton ? "10px 10px 0" : "10px")};
  width: auto;
  ${({ $hasCtaButton }) => $hasCtaButton && "min-width: 218px;"}
  list-style: none;
  background: #fff;
  box-shadow: 0px 4px 8px rgba(7, 3, 40, 0.09);
  border-radius: 8px;
  text-align: left;
  z-index: 2;
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  display: ${({ isOpen }) => (isOpen ? "block" : "none")};
  visibility: ${({ isOpen }) => (isOpen ? "visible" : "hidden")};
  transition: ${({ isOpen }) => (isOpen ? "all 0.2s" : "none")};
  ${({ $removeRight0 }) =>
    !$removeRight0
      ? css`
          ${mediaQuery.lessThan("640px")`
    right: 0;
  `}
        `
      : css`
          ${mediaQuery.lessThan("640px")`
          z-index: 10;
    `}
        `}
`;

export const DropdownItem = styled.li`
  padding: 10px 4px;
  height: 36px;
  display: flex;
  align-items: center;
  background: none;
  justify-content: space-between;
  color: ${COLORS.primary.grayDarkLightest};
  border-radius: 5px;
  font-size: 14px;
  line-height: 16px;
  font-style: normal;
  font-weight: normal;
  cursor: pointer;
  transition: all 0.2s;
  user-select: none;
  &:hover {
    background: ${COLORS.primary.accentLighter};
    color: ${COLORS.primary.accent};
  }
`;

const DropdownItemText = styled.span`
  margin-right: auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  justify-content: space-between;
  margin: auto 0;
`;

const StyledSpan = styled.span<{ $hasCustomized?: boolean }>`
  font-style: italic;
  font-weight: 300;
  font-size: 10px;
  line-height: 28px;
  color: ${({ $hasCustomized }) => ($hasCustomized ? "#EE3FD9" : COLORS.primary.grayDarkLightest)};
  margin-left: 22px;
  padding-right: 4px;
`;

export const CtaButtonDiv = styled(Link)<{ $hasCustomized?: boolean; $borderRadiusAll?: boolean }>`
  display: flex;
  color: ${({ $hasCustomized }) => ($hasCustomized ? "#191540" : COLORS.primary.accent)};
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  align-items: center;
  justify-content: center;
  background: white;
  width: calc(100% + 20px);
  margin-left: -10px;
  border-radius: ${({ $borderRadiusAll }) => ($borderRadiusAll ? "6px" : "0px 0px 6px 6px")};
  padding: 12px 16px;
  transition: all 0.3s;
  ${({ $hasCustomized }) => $hasCustomized && "opacity: 0.4;"}
  border-top: 1px solid #F0EFF8;
  cursor: pointer;
  margin-top: 8px;
  svg {
    margin-right: 4px;
    ${({ $hasCustomized }) => $hasCustomized && "color: #191540;"}
  }
  :hover {
    background: #f0eff8;
    ${({ $hasCustomized }) => $hasCustomized && "opacity: 0.8;"}
  }
`;

export const UpgradeButtonDiv = styled(Link)<{ $borderRadiusAll?: boolean }>`
  display: flex;
  color: #fff;
  align-items: center;
  justify-content: center;
  font-weight: 300;
  font-size: 14px;
  background: #7b7893;
  width: calc(100% + 20px);
  margin-left: -10px;
  border-radius: ${({ $borderRadiusAll }) => ($borderRadiusAll ? "6px" : "0px 0px 6px 6px")};
  padding: 12px 16px;
  cursor: pointer;
  transition: all 0.3s;
  margin-top: 8px;
  svg {
    margin-right: 4px;
  }
  :hover {
    color: #fff;
    background: #4235e1;
  }
`;

export const LeanDropdownItemList = ({
  items,
  selected,
  isOpen,
  getMenuProps,
  getItemProps,
  removeItem,
  hasCtaButton,
  hasTeam,
  hasCustomized,
  isProjectsFilter,
  isVotingSystemsFilter,
  isSignedIn,
  removeRight0,
}: Props) => {
  const { account } = useContext(CurrentAccountContext);
  const { pathname, search } = useLocation();
  const isOnTeamView = isTeamView(pathname, search);
  const siweFunction = useSiweFunction();
  const {
    trackClickEditPreferencesButtonOnDropdown,
    trackClickProjectsFilterOption,
    trackClickVotingSystemsFilterOption,
  } = useMixpanel();
  const isSelected = useCallback(
    (item: Item) => {
      if (Array.isArray(selected)) {
        return !!selected.find((selectedItem) => selectedItem.value === item.value);
      }

      return selected?.value === item.value;
    },
    [selected],
  );

  const getRemoveItemClickHandler = useCallback(
    (item: Item) => {
      if (!isSignedIn && item.value === "custom") {
        return {
          onClick: () => siweFunction(),
        };
      }
      if (isSelected(item)) {
        return {
          onClick: () => removeItem && removeItem(item),
        };
      }

      return {};
    },
    [isSelected, removeItem, isSignedIn, siweFunction],
  );

  const handleEditPreferencesClick = useCallback(() => {
    trackClickEditPreferencesButtonOnDropdown({
      userId: account,
    });
  }, [trackClickEditPreferencesButtonOnDropdown, account]);

  const handleOptionClick = useCallback(
    (item: Item) => {
      if (isProjectsFilter) {
        trackClickProjectsFilterOption({
          userId: account,
          projectsFilter: item.name as any,
        });
      } else if (isVotingSystemsFilter) {
        trackClickVotingSystemsFilterOption({
          userId: account,
          votingSystem: item.name as any,
        });
      }
    },
    [
      trackClickProjectsFilterOption,
      trackClickVotingSystemsFilterOption,
      account,
      isProjectsFilter,
      isVotingSystemsFilter,
    ],
  );

  return (
    <Container>
      <List
        style={{ left: pathname === RoutePaths.discuss || pathname === RoutePaths.dailyBriefs ? "-80px" : "" }}
        $hasCtaButton={hasCtaButton}
        isOpen={isOpen}
        $removeRight0={removeRight0}
        {...getMenuProps()}
      >
        {items.map((item, index) => (
          <DropdownItem
            key={`${item.value}-${index}`}
            {...getItemProps({ item, index })}
            {...getRemoveItemClickHandler(item)}
          >
            <div
              style={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }}
              onClick={() => handleOptionClick(item)}
            >
              <DropdownItemText>{item.name}</DropdownItemText>
              {index === 0 && hasCtaButton && (
                <StyledSpan $hasCustomized={hasCustomized && hasTeam}>
                  {hasCustomized && hasTeam ? "Customized" : "Default"}
                </StyledSpan>
              )}
            </div>
          </DropdownItem>
        ))}
        {hasCtaButton ? (
          <CtaButtonDiv
            onClick={handleEditPreferencesClick}
            to={isOnTeamView ? "/settings/bundle-feed" : "/settings/feed"}
            $hasCustomized={false}
          >
            <SettingsAlternateIcon width={16} height={16} /> Edit Preferences
          </CtaButtonDiv>
        ) : null}
      </List>
    </Container>
  );
};
