import * as React from "react";

const SvgGridIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M14 13a1 1 0 01-1 1H9a1 1 0 01-1-1V9a1 1 0 011-1h4a1 1 0 011 1v4zm-8 0a1 1 0 01-1 1H1a1 1 0 01-1-1V9a1 1 0 011-1h4a1 1 0 011 1v4zm8-8a1 1 0 01-1 1H9a1 1 0 01-1-1V1a1 1 0 011-1h4a1 1 0 011 1v4zM6 5a1 1 0 01-1 1H1a1 1 0 01-1-1V1a1 1 0 011-1h4a1 1 0 011 1v4z"
      fill="currentColor"
    />
  </svg>
);

export default SvgGridIcon;
