import React from "react";
import styled from "styled-components";

interface Props {
  category: string;
}

export const handleColorType = (category: string) => {
  switch (category) {
    case "Uncategorized":
      return "color: #7B7893; background: #F0F0F0; border: solid 1px #7B7893;";
    case "Protocol":
      return "color: #5162F9; background: #EEF0FF; border: solid 1px #8D98FA;";
    case "Product":
      return "color: #36B6A6; background: #EFFEFC; border: solid 1px #A2EFE6;";
    case "Investment":
      return "color: #46B633; background: #E7FFE3; border: solid 1px #65DB52;";
    case "Grants":
      return "color: #168983; background: #D9FFF6; border: solid 1px #168983;";
    case "Social":
      return "color: #1487B8; background: #EEFBFF; border: solid 1px #35C9F8;";
    case "Collector":
      return "color: #36B6A6; background: #EFFEFC; border: solid 1px #A2EFE6;";
    case "Service":
      return "color: #D225BE; background: #FFF0FD; border: solid 1px #EE3FD9;";
    case "Media":
      return "color: #FF7D34; background: #FFF5F0; border: solid 1px #FFC09D;";
    default:
      return "color: transparent; background:transparent;";
  }
};

const Badge = styled.div<{ category: string }>`
  ${({ category }) => handleColorType(category)}
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 8px 4px 8px;
  box-sizing: border-box;
  border-radius: 4px;
  width: fit-content;
  margin: auto;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 13px;
  letter-spacing: 0em;
  text-align: left;
`;

function CategoryBadge({ category }: Props) {
  return <Badge category={category}>{category}</Badge>;
}

export default CategoryBadge;
