import * as React from "react";

const SvgVoterPowerIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 18A9 9 0 109 0a9 9 0 000 18zm-1.563-7.2H8.5c.236 0 .354 0 .427.073.073.073.073.191.073.427v2.808c0 .759 0 1.138.186 1.188.186.049.374-.281.749-.941l2.367-4.167c.756-1.33 1.133-1.994.844-2.491-.29-.497-1.054-.497-2.583-.497H9.5c-.236 0-.354 0-.427-.073C9 7.054 9 6.936 9 6.7V3.892c0-.759 0-1.138-.186-1.188-.186-.049-.374.281-.749.941L5.698 7.812c-.756 1.33-1.133 1.994-.844 2.491.29.497 1.054.497 2.583.497z"
      fill="currentColor"
    />
  </svg>
);

export default SvgVoterPowerIcon;
