import { PendingVoteDetails } from "@boardroom/boardroom-api";
import React, { useContext, useMemo } from "react";
import { PendingVoteDetailsContext } from "../../reducers/PendingVotes";
import { PendingVoteToast } from "./PendingVoteToast";

export const PendingVoteTransactionsStatus = () => {
  const { pendingVoteDetails } = useContext(PendingVoteDetailsContext);
  const filteredPendinTransactions = useMemo(() => {
    const refIds = Object.keys(pendingVoteDetails);
    const filteredPending = refIds.reduce((acc: Array<PendingVoteDetails>, refId: string) => {
      if (pendingVoteDetails[refId].status === "pending") {
        return [...acc, pendingVoteDetails[refId]];
      }
      return acc;
    }, []);
    return filteredPending;
  }, [pendingVoteDetails]);

  if (!filteredPendinTransactions.length) return null;

  return (
    <>
      {filteredPendinTransactions.map((pendingVote: PendingVoteDetails) => {
        return <PendingVoteToast vote={pendingVote} key={pendingVote.txnHash} />;
      })}
    </>
  );
};
