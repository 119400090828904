import React, { ErrorInfo } from "react";
import styled from "styled-components";
import { ErrorBoundary as ErrorBoundarySentry } from "@sentry/react";

import { Header } from "../Typography";
import { Logo } from "../Logo/index";
import { Button } from "../../components/Button";
import { COLORS } from "../../constants/colors";
import media from "../../media-query";

interface Props {
  trackError: () => void;
  children: React.ReactNode;
}

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${COLORS.primary.grayDark};
`;

const LogoContainer = styled.div`
  width: 400px;
  ${media.lessThan("767px")`
    width: 80%; 
  `}
`;

const StyledHeader = styled(Header)`
  color: ${COLORS.primary.grayLight};
`;

const ButtonsContainer = styled.div`
  margin-top: 20px;
  width: 365px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  ${media.lessThan("767px")`
    display: flex;
    flex-direction: column;
  `}

  .ant-btn {
    > p {
      margin-top: 17px;
    }
  }
`;

const StyledButton = styled(Button)`
  && {
    padding: 0 44px;
  }
  span {
    height: 100%;
    display: flex;
    align-items: center;
  }
`;

export default class ErrorBoundary extends React.Component<Props> {
  state = { hasError: false };
  loggedError = false;
  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    if (!this.loggedError) {
      console.log(error, errorInfo);
      this.props.trackError();
    }
    this.loggedError = true;
  }

  render() {
    if (this.state.hasError) {
      return (
        <ErrorBoundarySentry
          fallback={
            <>
              <Container>
                <LogoContainer>
                  <Logo type="light" />
                </LogoContainer>
                <StyledHeader level={4}>Something went wrong.</StyledHeader>

                <ButtonsContainer>
                  <Button
                    onClick={() => {
                      history.back();
                      this.setState({ hasError: false });
                    }}
                    type="primary"
                  >
                    Go back
                  </Button>
                  <StyledButton
                    type="secondary"
                    href="https://roadmap.boardroom.info/b/bugs/"
                    target="_blank"
                    rel="noopener"
                  >
                    <span>Report bug</span>
                  </StyledButton>
                </ButtonsContainer>
              </Container>
            </>
          }
        >
          <Container>
            <LogoContainer>
              <Logo type="light" />
            </LogoContainer>
            <StyledHeader level={4}>Something went wrong.</StyledHeader>

            <ButtonsContainer>
              <Button
                onClick={() => {
                  history.back();
                  this.setState({ hasError: false });
                }}
                type="primary"
              >
                Go back
              </Button>
              <StyledButton
                type="secondary"
                href="https://roadmap.boardroom.info/b/bugs/"
                target="_blank"
                rel="noopener"
              >
                <span>Report bug</span>
              </StyledButton>
            </ButtonsContainer>
          </Container>
        </ErrorBoundarySentry>
      );
    }

    return this.props.children;
  }
}
