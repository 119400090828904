import React, { useReducer, createContext } from "react";

interface Props {
  children: React.ReactNode;
}

type Action = { type: "SAVE_DAO_LOGIN"; data: boolean };

interface CurrentDaoLoginType {
  daoLogin: boolean;
  dispatchDaoLogin: React.Dispatch<any>;
}

const initialState = false;

export const CurrentDaoLoginContext = createContext({} as CurrentDaoLoginType);

const reducer = (state: boolean, action: Action) => {
  switch (action.type) {
    case "SAVE_DAO_LOGIN":
      return action.data;
    default:
      return state;
  }
};

export const CurrentDaoLoginProvider = (props: Props) => {
  const [daoLogin, dispatchDaoLogin] = useReducer(reducer, initialState);

  return (
    <CurrentDaoLoginContext.Provider value={{ daoLogin, dispatchDaoLogin }}>
      {props.children}
    </CurrentDaoLoginContext.Provider>
  );
};
