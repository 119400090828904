import React from "react";
import styled from "styled-components";
import ContentLoader from "react-content-loader";

import { COLORS } from "../../constants/colors";
import { Table } from "../Table";
import {
  CommentsWrapper,
  DelegateDashboardTitle,
  DelegatorsTitle,
  ProtocolName,
  ProtocolNameWrapper,
  SeeMore,
  ShowAllButton,
  StyledSelect,
  TableFooter,
  Wrapper,
} from "./styles";

const Container = styled.div`
  line-height: 0;
`;

const columns = [
  {
    title: "Delegator Name",
    key: "delegator",
    render: () => (
      <Container>
        <ContentLoader
          speed={2}
          width="100%"
          height={30}
          backgroundColor={COLORS.primary.grayLight}
          foregroundColor={COLORS.primary.grayLighter}
        >
          <circle cx="15" cy="15" r="15" />
          <rect x="55" y="8" rx="5" ry="5" width="40%" height="15" />
        </ContentLoader>
      </Container>
    ),
  },
  {
    title: "Power Delegated",
    dataIndex: "amount",
    width: "25%",
    align: "right" as const,
    sorter: () => 0,
    render: () => (
      <Container>
        <ContentLoader
          speed={2}
          width="100%"
          height={30}
          rtl
          backgroundColor={COLORS.primary.grayLight}
          foregroundColor={COLORS.primary.grayLighter}
        >
          <rect x="0" y="8" rx="5" ry="5" width="60" height="16" />
        </ContentLoader>
      </Container>
    ),
  },
  {
    title: "Delegation date",
    key: "timestamp",
    width: "25%",
    align: "right" as const,
    sorter: () => 0,
    render: () => (
      <Container>
        <ContentLoader
          speed={2}
          width="100%"
          height={30}
          rtl
          backgroundColor={COLORS.primary.grayLight}
          foregroundColor={COLORS.primary.grayLighter}
        >
          <rect x="0" y="8" rx="5" ry="5" width="40" height="16" />
          <rect x="45" y="8" rx="5" ry="5" width="80" height="16" />
        </ContentLoader>
      </Container>
    ),
  },
];

const data = new Array(5).fill(null);

export const TableLoader = () => {
  return (
    <div style={{ padding: "0 24px" }}>
      <DelegatorsTitle>Delegators</DelegatorsTitle>
      <Table
        inACardContainer={true}
        backgroundColor="#fafafa"
        addLetterSpacing
        columns={columns}
        dataSource={data}
        pagination={false}
      />

      <TableFooter>
        <ShowAllButton>SEE MORE</ShowAllButton>
      </TableFooter>
    </div>
  );
};

export const MessagesLoader = () => {
  return (
    <div style={{ padding: "0 24px" }}>
      <ContentLoader
        speed={2}
        backgroundColor={COLORS.primary.grayLight}
        foregroundColor={COLORS.primary.grayLighter}
        height={40}
      >
        <rect x="0" y="22" rx="5" ry="5" width="80%" height="16" />
      </ContentLoader>

      <StyledSelect defaultValue="newest">
        <option value="newest">Newest</option>
        <option value="oldest">Oldest</option>
      </StyledSelect>
      <CommentsWrapper>
        {[1, 2].map((value) => (
          <ContentLoader
            speed={2}
            width="100%"
            height={100}
            backgroundColor={COLORS.primary.grayLight}
            foregroundColor={COLORS.primary.grayLighter}
            key={value}
          >
            <circle x="0" cx="30" cy="30" r="16" />
            <rect x="60" y="22" rx="5" ry="5" width="80%" height="16" />
          </ContentLoader>
        ))}
      </CommentsWrapper>
      <SeeMore>SEE MORE</SeeMore>
    </div>
  );
};

export const DelegateMessageBoardSkeleton = () => {
  return (
    <Wrapper>
      <DelegateDashboardTitle>
        <ContentLoader
          speed={2}
          width={"40px"}
          height={"40px"}
          viewBox={"0 0 40 40"}
          backgroundColor={COLORS.primary.grayLight}
          foregroundColor={COLORS.primary.grayLighter}
        >
          <circle cx={20} cy={20} r={20} />
        </ContentLoader>
        <ProtocolNameWrapper>
          <ProtocolName>
            <ContentLoader
              speed={2}
              backgroundColor={COLORS.primary.grayLight}
              foregroundColor={COLORS.primary.grayLighter}
              height={40}
            >
              <rect x="20" y="16" rx="5" ry="5" width="80%" height="16" />
            </ContentLoader>
          </ProtocolName>
        </ProtocolNameWrapper>
      </DelegateDashboardTitle>

      <MessagesLoader />
    </Wrapper>
  );
};
