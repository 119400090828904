import Col from "antd/es/col";
import Row from "antd/es/row";
import styled from "styled-components";

import media from "../../media-query";
import { pageHeaderHeight } from "../../constants/styles";

export const Container = styled.div`
  margin: auto;
  height: auto;
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;
  background-color: #fafafa;
  padding-top: 0;
  ${media.lessThan("991px")`
    padding: 0px;
    height: calc(100vh - ${pageHeaderHeight});
  `}
`;

export const PaddingContainer = styled.div`
  ${media.lessThan("991px")`
    padding: 0;
  `}
`;

export const PageMax = styled.div`
  width: 100%;
  min-height: calc(100% - 320px);
  ${media.lessThan("1100px")`
    min-height: calc(100% - 360px);
  `}
`;

export const StyledRowNoMargins = styled(Row)<{ $isTeamView?: boolean }>`
  ${({ $isTeamView }) => $isTeamView && "margin-top: 64px;"}
  ${media.lessThan("640px")`
    margin-left: 0 !important;
    margin-right: 0 !important;
  `}
`;

export const StyledCol = styled(Col)`
  height: calc(100vh - ${pageHeaderHeight});
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  ${media.lessThan("991px")`
    width: 100%;
    padding-left: 12px !important;
`}
`;

export const StyledAbsoluteCol = styled(Col)<{ $left: string; $isTeamView?: boolean }>`
  height: calc(100vh - ${({ $isTeamView }) => ($isTeamView ? "134px" : pageHeaderHeight)});
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  background: #f6f6f6;
  position: fixed;
  left: ${({ $left }) => $left};
  width: 216px;
  z-index: 2;
  &::-webkit-scrollbar {
    display: none;
  }
  ${media.lessThan("991px")`
    width: 100%;
    padding-left: 12px !important;
`}
`;

export const StyledTeamTxt = styled.div`
  position: fixed;
  left: 0;
  z-index: 9;
  background: #f0eff8;
  color: #191540;
  top: 64px;
  transition: 0.3s all;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  padding: 12px 0;
  height: 64px;
`;

export const TeamFeedText = styled.span`
  font-weight: 400;
  padding-right: 24px;
  margin-left: 4px;
`;

export const ViewOnlyWrapper = styled.div`
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #7b7893;
  padding-left: 24px;
  border-left: 1px solid #7b7893;
  svg {
    margin-right: 8px;
  }
`;

export const StyledStickyCol = styled(Col)`
  position: sticky;
  align-self: flex-start;
  top: 0;
  z-index: 10;
  background: #fafafa;
  flex: 0 0 100%;
  max-width: 26%;
  height: calc(100vh - ${pageHeaderHeight});
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;
