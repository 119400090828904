import { useQuery } from "@tanstack/react-query";
import { GetProtocolsResponse } from "@boardroom/boardroom-api";
import { protocolInfoList } from "@boardroom/protocol-info";
import { apiClient } from "../utils/apiClient";
import { partnerProtocols, pinnedProtocols } from "../constants/protocols";
import { ProtocolDescription } from "../types";
import { useMemo } from "react";

export const useProtocols = (excludeTokenInfo = false, suspense = true) => {
  const { data: protocols } = useQuery<GetProtocolsResponse, Error>(
    [`protocols-${excludeTokenInfo}`],
    async () => await apiClient.getProtocols({ excludeTokenInfo }),
    {
      suspense,
    },
  );

  // Filter out protocols not supported by API

  const filteredProtocols = useMemo(() => {
    const protocolList = protocolInfoList.filter((protocolInfo) =>
      protocols?.data.find((p) => p.cname === protocolInfo.cname),
    );

    const data: Record<string, ProtocolDescription & { categories: string[] }> = protocolList
      .map((protocolInfo) => {
        const protocol = protocols?.data.find((p) => p.cname === protocolInfo.cname);

        return Object.assign(protocol || {}, protocolInfo);
      })
      .reduce(
        (protocols, protocol: typeof protocolList[0]) => ({
          ...protocols,
          [protocol.cname]: Object.assign(protocol, {
            order: pinnedProtocols.indexOf(protocol.cname) === -1 && partnerProtocols.indexOf(protocol.cname) === -1 ? Infinity : partnerProtocols.indexOf(protocol.cname) === -1 ? pinnedProtocols.indexOf(protocol.cname) + 3 : partnerProtocols.indexOf(protocol.cname),
          }),
        }),
        {},
      );
    return data;
  }, [protocols?.data]);

  return { protocols: filteredProtocols };
};

export const useProtocolsTokens = () => {
  const { protocols } = useProtocols();
  const protocolsArray = Object.values(protocols);
  const protocolsTokens = protocolsArray
    .map((protocol) => protocol.tokens)
    .filter(Boolean)
    .flat();
  return protocolsTokens;
};
