import React, { useReducer, createContext } from "react";

interface Props {
  children: React.ReactNode;
}

type Action = { type: "SHOW_LOADER"; data: boolean };

interface VotingPowerCardIsLoadingType {
  votingPowerCardIsLoading: boolean;
  dispatchVotingPowerCardIsLoading: React.Dispatch<any>;
}

const initialState = false;

export const VotingPowerCardIsLoadingContext = createContext({} as VotingPowerCardIsLoadingType);

const reducer = (state: boolean, action: Action) => {
  switch (action.type) {
    case "SHOW_LOADER":
      return action.data;
    default:
      return state;
  }
};

export const VotingPowerCardIsLoadingProvider = (props: Props) => {
  const [votingPowerCardIsLoading, dispatchVotingPowerCardIsLoading] = useReducer(reducer, initialState);

  return (
    <VotingPowerCardIsLoadingContext.Provider value={{ votingPowerCardIsLoading, dispatchVotingPowerCardIsLoading }}>
      {props.children}
    </VotingPowerCardIsLoadingContext.Provider>
  );
};
