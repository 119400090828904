import React from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

interface Props {
  children: React.ReactNode;
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      suspense: true,
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

export const ReactQueryClientProvider = ({ children }: Props) => (
  <QueryClientProvider client={queryClient}>
    <ReactQueryDevtools position="bottom-right" initialIsOpen={false} />
    {children}
  </QueryClientProvider>
);
