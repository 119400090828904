import moment from "moment";

export const getProposalMainStatus = (status: string) => {
  if (status === "active") {
    return "active";
  }
  if (status === "executed") {
    return "executed";
  }
  if (status === "pending" || status === "wip" || status === "proposed") {
    return "pending";
  }
  if (
    status === "closed" ||
    status === "expired" ||
    status === "canceled" ||
    status === "approved" ||
    status === "rejected" ||
    status === "No status" ||
    status === "queued"
  ) {
    return "closed";
  }
};

export const getProposalTimeAgoText = (
  status: string,
  startsIn: string,
  endsIn: string,
  proposalEndTimestamp: number,
) => {
  const parsedStatus = getProposalMainStatus(status);
  const currentDate = moment();
  const isAfterEndDate = currentDate.isAfter(moment(proposalEndTimestamp * 1000));
  const currentDateFromNow = moment(new Date()).fromNow();

  return parsedStatus === "pending"
    ? `Starts: ${startsIn}`
    : status === "canceled"
    ? isAfterEndDate
      ? `Ended ${endsIn}`
      : `Ends ${endsIn}`
    : parsedStatus === "closed"
    ? `Ended ${endsIn}`
    : parsedStatus === "executed" && endsIn > currentDateFromNow
    ? `Ends ${endsIn}`
    : parsedStatus === "executed" && endsIn < currentDateFromNow
    ? `Ended ${endsIn}`
    : `Ends ${endsIn}`;
};
