import Row from "antd/es/row";
import Col from "antd/es/col";
import React from "react";
import ContentLoader from "react-content-loader";

import { COLORS } from "../../../constants/colors";
import { InfoCard } from "../../../components";

function CalendarSuspense() {
  return (
    <>
      <Row justify="center" style={{ paddingBottom: "120px", paddingTop: "14px", marginLeft: "24px" }}>
        <Col xxl={20} md={22} sm={24}>
          <InfoCard>
            <ContentLoader
              speed={2}
              width="100%"
              height={402}
              backgroundColor={COLORS.primary.grayLight}
              foregroundColor={COLORS.primary.grayLighter}
            >
              <rect x="0" y="10" rx="5" ry="5" width="100%" height="12" />
              <rect x="0" y="34" rx="5" ry="5" width="70%" height="12" />

              <rect x="0" y="75" rx="5" ry="5" width="100%" height="12" />
              <rect x="0" y="99" rx="5" ry="5" width="100%" height="12" />
              <rect x="0" y="123" rx="5" ry="5" width="100%" height="12" />
              <rect x="0" y="147" rx="5" ry="5" width="20%" height="12" />

              <rect x="0" y="188" rx="5" ry="5" width="100%" height="12" />
              <rect x="0" y="212" rx="5" ry="5" width="100%" height="12" />
              <rect x="0" y="236" rx="5" ry="5" width="40%" height="12" />

              <rect x="0" y="277" rx="5" ry="5" width="100%" height="12" />
              <rect x="0" y="301" rx="5" ry="5" width="25%" height="12" />

              <rect x="0" y="342" rx="5" ry="5" width="100%" height="12" />
              <rect x="0" y="366" rx="5" ry="5" width="100%" height="12" />
              <rect x="0" y="390" rx="5" ry="5" width="70%" height="12" />
            </ContentLoader>
          </InfoCard>
        </Col>
      </Row>
    </>
  );
}
export default CalendarSuspense;
