import { useQuery } from "@tanstack/react-query";
import { ProposalComment, OrgNote } from "@boardroom/boardroom-api";

import { apiClient } from "../utils/apiClient";

interface Props {
  filterBy: "author" | "proposalId";
  suspense?: boolean;
  author?: string;
  proposalId?: string;
  uuid?: string;
  includeOrgComments?: boolean;
}

const emptyResponse: any[] = [];

export const useGetComments = ({
  filterBy,
  suspense = false,
  author,
  proposalId,
  uuid,
  includeOrgComments = false,
}: Props) => {
  const formattedAddress = author;
  const { data: comments } = useQuery<ProposalComment[] | OrgNote[] | undefined, Error>(
    [`comments:${filterBy}:${filterBy === "author" ? author : proposalId}:${uuid}:${includeOrgComments}`],
    async () => {
      if (filterBy === "author") {
        const comments = await apiClient.getCommentsByAuthor(formattedAddress || "", {
          author: formattedAddress || "",
        });
        return comments.data;
      } else if (filterBy === "proposalId" && !includeOrgComments) {
        const comments = await apiClient.getCommentsByProposalId(proposalId || "");
        return comments.data;
      } else if (includeOrgComments) {
        const orgNotes = apiClient.getOrgNotesByProposalId(proposalId || "", {
          author: formattedAddress || "",
        });

        const comments = apiClient.getCommentsByProposalId(proposalId || "");

        const awaitedPromises = await Promise.allSettled([orgNotes, comments]);
        const [orgNotesResponse, commentsResponse] = awaitedPromises.map((settledResult) => {
          if (settledResult.status === "fulfilled") return settledResult.value;
        });

        return [...(orgNotesResponse?.data || []), ...(commentsResponse?.data || [])];
      }
    },
    {
      suspense,
    },
  );

  return { comments: comments || emptyResponse };
};
