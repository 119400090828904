import Select from "antd/es/select";
import Drawer from "antd/es/drawer";
import React, { useState, useContext, useEffect, useCallback, useMemo } from "react";
import { useCurrentWidth } from "react-socks";
import styled from "styled-components";
import { useLocation } from "react-router-dom";

import { COLORS } from "../../constants/colors";
import { CurrentAccountContext } from "../../reducers/CurrentAccount";
import { SubmitComment, editComment } from "../../components/Proposals/ProposalComments/utils";
import { MdEditorWithButtons } from "../../components/Proposals/ProposalComments/ProposalComments";
import { useGetComments } from "../../hooks/useGetComments";
import { useProposal } from "../../hooks/useProposal";
import media from "../../media-query";
import ProposalComment from "../../components/Proposals/ProposalComments/ProposalComment";
import { removeComment } from "../../components/Proposals/ProposalComments/utils";
import { useSiwe } from "../../hooks/useSiwe";
import { useOnboardWallet } from "../../hooks/useOnboardWallet";
import { getUuid } from "../../utils/getUuid";
import { NotesContext } from "../../reducers/Comments";
import { useMixpanel } from "../../hooks";
import { CurrentUuidContext } from "../../reducers/CurrentUuid";

const StyledSelect = styled(Select)`
  border: none;
  transition: all 0.3s;
  box-shadow: 0;
  height: auto;
  border-radius: 6px;
  width: auto;
  color: #191540;
  margin: 32px 0 0;
  &:placeholder-shown {
    font-weight: 300;
    color: ${COLORS.primary.grayDarkLightest};
  }
  &:focus,
  &:active,
  &:hover {
    box-shadow: 0;
  }
  .ant-select-selector {
    height: 100% !important;
    display: flex;
    align-items: center;
    background: transparent !important;
    border: none !important;
  }
  .ant-select-arrow {
    border-radius: 4px;
    width: 0;
    height: 0;
    color: transparent;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 10px solid ${({ disabled }) => (disabled ? COLORS.primary.accentLight : COLORS.primary.accent)};
  }
  ${media.lessThan("991px")`
    margin: 0;
  `};
`;

const CommentsWrapper = styled.div`
  margin: 20px 0 64px;
`;

const StyledDrawer = styled(Drawer)`
  .ant-drawer-header {
    border-bottom: 0;
    padding: 24px;
    .ant-drawer-header-title {
      flex-direction: row-reverse;
      .ant-drawer-title {
        h3 {
          font-weight: 300;
          font-size: 28px;
          line-height: 30px;
          color: #191540;
          span {
            font-weight: 100;
            font-size: 28px;
            line-height: 30px;
            color: #191540;
          }
        }
      }
    }
  }
  .ant-drawer-body {
    padding: 24px 16px;
    position: relative;
  }
  ${media.lessThan("991px")`
  .ant-drawer-body {
    padding: 8px 16px;
  }
  .ant-drawer-content {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  `};
`;

const EditText = styled.span`
  font-size: 14px;
  color: ${COLORS.secondary.pinkDark};
  cursor: pointer;
  display: flex;
  justify-content: end;
`;

const StyledTeamTxt = styled.span`
  position: absolute;
  margin-top: -82px;
  margin-left: 10px;
  color: ${COLORS.primary.accent};
`;

interface ModalProps {
  noteModal: boolean;
  setNoteModal: React.Dispatch<React.SetStateAction<boolean>>;
  refId: string;
}

export const NoteModalContent = ({
  refId,
  setNoteModal,
  isTeamView,
}: {
  refId: string;
  setNoteModal?: React.Dispatch<React.SetStateAction<boolean>>;
  isTeamView: boolean;
}) => {
  const [editorContent, setEditorContent] = useState("");
  const [loading, setLoading] = useState(false);
  const [, setDeleteLoading] = useState(false);
  const [editingId, setEditingId] = useState("");
  const [sortingValue, setSortingValue] = useState("newest");
  const { account } = useContext(CurrentAccountContext);
  const { uuid } = useContext(CurrentUuidContext);
  const { dispatchNotes, notes } = useContext(NotesContext);
  useSiwe({});

  const { comments: commentsData } = useGetComments({
    filterBy: "proposalId",
    author: account,
    uuid,
    includeOrgComments: !!isTeamView,
    proposalId: refId,
  });

  const { proposal } = useProposal({ refId, suspense: false });
  const wallet = useOnboardWallet();
  const { trackClickConnectWalletOnNotesModal, trackClickEditNote, trackClickDeleteNote } = useMixpanel();

  const filteredAndSortedNotes = useMemo(() => {
    const filteredProposalNotes = commentsData ?? notes?.filter((comment) => comment?.proposalId === refId);

    return filteredProposalNotes.sort((commentA, commentB) =>
      sortingValue === "newest"
        ? (commentB.createdAt || 0) - (commentA.createdAt || 0)
        : (commentA.createdAt || 0) - (commentB.createdAt || 0),
    );
  }, [commentsData, notes, refId, sortingValue]);

  const handleClick = useCallback(() => {
    trackClickConnectWalletOnNotesModal({
      proposalRefId: refId,
    });
    wallet?.openWalletModal();
    if (setNoteModal) {
      setNoteModal(false);
    }
  }, [wallet, setNoteModal, trackClickConnectWalletOnNotesModal, refId]);

  const handleRemove = useCallback(
    (id = "") => {
      trackClickDeleteNote({
        userId: account,
        proposalRefId: refId,
      });
      removeComment(id, notes, setDeleteLoading, dispatchNotes, isTeamView);
    },
    [account, dispatchNotes, notes, refId, trackClickDeleteNote, isTeamView],
  );

  const handleEdit = useCallback(
    (id = "") => {
      trackClickEditNote({
        userId: account,
        proposalRefId: refId,
      });
      setEditingId(id);
      const commentIndex = notes.findIndex((comment) => comment.commentId === id);
      setEditorContent(notes[commentIndex].commentBody || "");
    },
    [account, notes, refId, trackClickEditNote],
  );

  return (
    <>
      {!!editingId && <EditText>Editing comment</EditText>}
      <MdEditorWithButtons
        editorContent={editorContent}
        editComment={() =>
          editComment(
            editingId,
            notes,
            setLoading,
            setEditingId,
            editorContent,
            setEditorContent,
            dispatchNotes,
            !!isTeamView,
          )
        }
        submitComment={() =>
          SubmitComment(
            refId,
            setLoading,
            setEditingId,
            editorContent,
            setEditorContent,
            dispatchNotes,
            account,
            proposal?.protocol as string,
            isTeamView,
          )
        }
        loading={loading}
        editingId={editingId}
        setEditingId={setEditingId}
        setEditorContent={setEditorContent}
        account={account}
        proposalRefId={refId}
        handleClick={handleClick}
      />
      {isTeamView && <StyledTeamTxt>Visible to all bundle members</StyledTeamTxt>}
      {!!notes?.length && (
        <StyledSelect
          value={sortingValue}
          onChange={(value) => setSortingValue(String(value) || "")}
          defaultValue="newest"
        >
          <option value="newest">Newest</option>
          <option value="oldest">Oldest</option>
        </StyledSelect>
      )}
      <CommentsWrapper>
        {filteredAndSortedNotes.map((comment) => (
          <ProposalComment
            proposer={proposal?.proposer}
            removeComment={handleRemove}
            edit={handleEdit}
            key={comment.commentId}
            comment={comment}
          />
        ))}
      </CommentsWrapper>
    </>
  );
};

const NoteModal = ({ noteModal, setNoteModal, refId }: ModalProps) => {
  const isMobile = useCurrentWidth() < 991;

  const { account } = useContext(CurrentAccountContext);
  const { dispatchNotes, notes } = useContext(NotesContext);

  const uuid = getUuid(account);
  const query = new URLSearchParams(useLocation().search);
  const isTeamView = query.get("bundle");

  const { comments: commentsData } = useGetComments({
    filterBy: "proposalId",
    author: account,
    uuid,
    includeOrgComments: !!isTeamView,
    proposalId: refId,
  });

  const proposalNotes = useMemo(() => {
    return commentsData ?? notes?.filter((comment) => comment?.proposalId === refId);
  }, [commentsData, notes, refId]);

  useSiwe({ trigger: noteModal });

  useEffect(() => {
    if (commentsData) {
      dispatchNotes({
        type: "SAVE_NOTES",
        data: commentsData,
      });
    }
  }, [commentsData, dispatchNotes]);

  const closeNoteModal = useCallback(() => {
    setNoteModal(false);
  }, [setNoteModal]);

  return (
    <>
      <StyledDrawer
        height={isMobile ? "calc(100% - 32px)" : "auto"}
        width={isMobile ? "100%" : "38%"}
        title={
          <h3>
            Notes <span>{proposalNotes.length || 0}</span>
          </h3>
        }
        placement={isMobile ? "bottom" : "right"}
        onClose={closeNoteModal}
        visible={noteModal}
      >
        <NoteModalContent setNoteModal={setNoteModal} refId={refId} isTeamView={!!isTeamView} />
      </StyledDrawer>
    </>
  );
};
export default NoteModal;
