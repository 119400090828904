import React from "react";
import styled from "styled-components";

import { COLORS } from "../../constants/colors";

const StyledDiv = styled.div<{ $isEvents?: boolean }>`
  text-align: center;
  margin-bottom: ${({ $isEvents }) => ($isEvents ? "40px" : "120px")};
  margin-top: 20px;
`;

const StyledTitle = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: ${COLORS.secondary.purple};
  text-transform: capitalize;
`;

const StyledImg = styled("img")<{ $isEvents?: boolean }>`
  margin: ${({ $isEvents }) => ($isEvents ? "16px" : "32px")} 0;
`;

const Container = styled.div`
  border-radius: 8px;
  background: #fff;
  padding: 12px 0 16px 8px;
  display: flex;
  align-items: center;
  gap: 36px;
`;

const StyledImgForFeed = styled.img`
  width: 70px;
  height: 44px;
`;

const CaughtUpText = styled.span`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #7b7893;
`;

const NoProposalsFound = ({ isEvents }: { isEvents?: boolean }) => {
  return (
    <>
      <StyledDiv $isEvents={isEvents}>
        <StyledImg $isEvents={isEvents} src={`${process.env.PUBLIC_URL}/assets/YourProposalsEmptyState.png`} />
        <StyledTitle>No {isEvents ? "Events" : "Proposals"} found</StyledTitle>
      </StyledDiv>
    </>
  );
};

export const NoProposalsFoundFeed = () => {
  return (
    <Container>
      <StyledImgForFeed src={`${process.env.PUBLIC_URL}/assets/YourProposalsEmptyState.png`} />
      <CaughtUpText>You’re All Caught Up For Now</CaughtUpText>
    </Container>
  );
};

export default NoProposalsFound;
