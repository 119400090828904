import * as React from "react";

const SvgHalfCircleCheckIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M9 10l3.258 2.444a1 1 0 001.353-.142L20 5M21 12a9 9 0 11-6.67-8.693"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
    />
  </svg>
);

export default SvgHalfCircleCheckIcon;
