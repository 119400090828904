import * as React from "react";

const SvgBookIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 16 16" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.333 2.167a1.5 1.5 0 00-1.5 1.5h-1a2.5 2.5 0 012.5-2.5h7.334a.5.5 0 01.5.5v8.666a.5.5 0 01-.5.5H4.333c-.824 0-1.5.672-1.5 1.5a.5.5 0 11-1 0V3.667h1v6.667a2.489 2.489 0 011.5-.5h6.834V2.166H4.333z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.667 1.833a.5.5 0 01.5.5v12a.5.5 0 01-.5.5H4.333a2.5 2.5 0 010-5h7.333v1H4.334a1.5 1.5 0 000 3h8.834v-11.5a.5.5 0 01.5-.5zm-1.5 8.5a.5.5 0 01-.5.5v-1a.5.5 0 01.5.5z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.167 12.333a.5.5 0 01.5-.5h6.666a.5.5 0 110 1H4.667a.5.5 0 01-.5-.5z"
      fill="currentColor"
    />
  </svg>
);

export default SvgBookIcon;
