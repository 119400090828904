import Col from "antd/es/col";
import Row from "antd/es/row";
import React from "react";

import { MainContainer } from "../../components";
import { ContentArea } from "../../components/Shared.styles";
import { useNavScroll } from "../../hooks/useNavScroll";
import { useWindowDimensions } from "../../hooks/useWindowDimensions";
import { Container, PageMax } from "./styles";

function DashboardSuspense({ children }: { children: React.ReactNode }): React.ReactElement {
  const { scrollFromTop } = useNavScroll();
  const { width } = useWindowDimensions();
  return (
    <>
      <MainContainer $isDashboard $scrollFromTop={scrollFromTop}>
        <ContentArea>
          <Container
            className="DashboardContainer"
            id="scrollElement"
            isSettings={location.pathname.includes("settings")}
            $isCalendar
            $width={width}
          >
            <PageMax>
              <Row justify="center" align="middle" gutter={24}>
                <Col md={20}>{children}</Col>
              </Row>
            </PageMax>
          </Container>
        </ContentArea>
      </MainContainer>
    </>
  );
}
export default DashboardSuspense;
