import Button from "antd/es/button";
import Col from "antd/es/col";
import Layout from "antd/es/layout";
import React from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

import { COLORS } from "../../constants/colors";
import { boardroomDiscordLink, boardRoomOrgGithubLink, boardroomTwitterLink } from "../../constants/general";
import { DiscordIcon, GithubIcon, TwitterIcon, NotifIcon, CooliconIcon, BoardroomFooterLogo } from "../icons";
import media from "../../media-query";
import { RoutePaths } from "../../constants/Routes";

const { Footer } = Layout;

const BoardroomText = styled(Link)`
  position: absolute;
  bottom: 0;
  margin-bottom: 0;
  width: 114px;
  height: 16px;
  left: 0;
  ${media.lessThan("991px")`
  display: none;
`}
`;

const BoardroomMobileText = styled(Link)`
  margin-bottom: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  order: 4;
  ${media.greaterThan("991px")`
  display: none;
`}
`;

const FlexItem = styled.div`
  display: flex;
  justify-content: space-between;
  width: 80%;
  ${media.lessThan("991px")`
  width: 100%;
`}
`;

const FooterContainer = styled(Footer)<{ bg?: string }>`
  height: 320px;
  background: ${({ bg }) => (bg ? bg : COLORS.violet)};
  padding: 0;
  display: block;
  width: 100%;
  z-index: 2;
  position: relative;
  ${media.lessThan("1100px")`
    height: 360px;
    padding-bottom: 32px;
  `}
  ${media.lessThan("991px")`
    padding-bottom: 80px;
    width: 100%;
    height: auto;
  `}
   ${({ bg }) =>
    bg &&
    css`
      background: ${bg};
    `};
`;

const FooterLayout = styled(Col)`
  width: 1200px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  padding: 56px 0 28px;
  height: 100%;
  ${media.lessThan("1200px")`
    width: 85%;
  `}
  ${media.lessThan("991px")`
    flex-direction: column;
    padding: 0;
    width: 100%;
  `}
`;

const IconContainer = styled(Col)`
  display: flex;
  align-items: center;
  gap: 32px;
  order: 2;
  margin-top: auto;
  ${media.lessThan("991px")`
  margin: 56px auto 0;
  order: 3;
`}
`;

const InfoText = styled.p`
  font-size: 16px;
  line-height: 20px;
  color: #fff;
  width: 70%;
  line-height: 22px;
  ${media.lessThan("991px")`
  text-align: center;
  width: 100%;
  padding: 0 8px;
`}
`;

const NavContainer = styled(Col)`
  width: auto;
  display: block;
`;

const NavSection = styled(Col)`
  width: 40%;
  order: 3;
  height: 100%;
  display: flex;
  flex-direction: column;
  ${media.lessThan("1100px")`
  width: 50%;
`}
  ${media.lessThan("991px")`
  width: 80%;
  margin: auto;
  padding: 68px 16px;
  order: 2;
`}
  ${media.lessThan("640px")`
  width: 100%;
  margin: auto;
  padding: 68px 16px 56px;
  order: 2;
`}
`;

const StyledLinkTag = styled.a`
  font-size: 18px;
  line-height: 30px;
  cursor: pointer;
  display: block;
  color: #fff;
  &:hover {
    color: ${COLORS.primary.grayLight};
  }
`;

const StyledLink = styled(Link)`
  font-size: 18px;
  line-height: 30px;
  cursor: pointer;
  display: block;
  color: #fff;
  &:hover {
    color: ${COLORS.primary.grayLight};
  }
`;

const StyledCooliconIcon = styled(CooliconIcon)`
  margin: auto 6px;
  width: 10px;
  height: 10px;
  transition: transform 0.3s ease-out;
`;

const ExternalLink = styled.div`
  display: flex;
  margin-bottom: 8px;
  color: #fff;
  &:hover {
    color: ${COLORS.primary.grayLight};
    ${StyledCooliconIcon} {
      transform: translateX(1px) translateY(-2px);
    }
  }
`;

const NewsLetter = styled(Col)<{ bg?: string }>`
  width: 35%;
  position: relative;
  height: 100%;
  order: 1;
  ${media.lessThan("991px")`
  text-align: center;
  width: 100%;
  padding: 48px 0px;
`}
  ${({ bg }) =>
    bg &&
    css`
      background: ${bg};
    `};
`;

const NewsLetterText = styled.p`
  font-weight: bold;
  font-size: 22px;
  line-height: 30px;
  color: #fff;
  margin-bottom: 12px;
  font-family: Inter;
  ${media.lessThan("991px")`
  display: none;
`}
`;

const StyledNotifIcon = styled(NotifIcon)`
  width: 20px;
  height: 20px;
  color: #fff;
  margin-bottom: 12px;
  ${media.greaterThan("991px")`
  display: none;
`}
`;

const FooterTitle = styled.p`
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  font-family: Inter;
  color: #fff;
  ${media.greaterThan("991px")`
    display: none;
`}
`;

const StyledButton = styled(Button)`
  border-radius: 8px;
  background: rgba(240, 239, 248, 0.2);
  border: 1px solid #ffffff;
  color: #fff;
  width: 156px;
  padding: 4px 0;
  font-weight: 600;
  font-size: 14px;
  height: 40px;
  margin-top: 12px;
`;

const Tab = styled.div`
  width: 32px;
  height: 0px;
  border-top: 4px solid #ffffff;
  margin-bottom: 14px;
  margin-top: -24px;
`;

const StyledGithubIcon = styled(GithubIcon)`
  width: 24px;
  height: 24px;
  cursor: pointer;
  transition: transform 0.3s ease-out;
  :hover {
    transform: scale(1.2);
  }
`;

const StyledTwitterIcon = styled(TwitterIcon)`
  width: 24px;
  height: 24px;
  cursor: pointer;
  transition: transform 0.3s ease-out;
  :hover {
    transform: scale(1.2);
  }
`;

const StyledDiscordIcon = styled(DiscordIcon)`
  width: 24px;
  height: 24px;
  cursor: pointer;
  transition: transform 0.3s ease-out;
  :hover {
    transform: scale(1.2);
  }
`;

const GlobalFooter = ({ bg, textColor }: { bg?: string; textColor?: string }) => {
  return (
    <>
      <FooterContainer bg={bg}>
        <FooterLayout>
          <NewsLetter bg={bg}>
            <NewsLetterText style={{ color: textColor || "" }}>Newsletter</NewsLetterText>
            <StyledNotifIcon />
            <FooterTitle style={{ color: textColor || "" }}>Stay up to date</FooterTitle>
            <InfoText style={{ color: textColor || "" }}>
              Subscribe to our emails to get the latest on community news and updates.
            </InfoText>
            <a href="https://governance.substack.com/">
              <StyledButton style={{ backgroundColor: bg === "white" ? "#4235e1" : "" }}>Subscribe</StyledButton>
            </a>
            <BoardroomText to={RoutePaths.feed}>
              <BoardroomFooterLogo height="100%" width="100%" color={textColor || "#fff"} />
            </BoardroomText>
          </NewsLetter>

          <NavSection>
            <FlexItem>
              <NavContainer>
                <Tab style={{ borderColor: textColor || "" }} />
                <ExternalLink>
                  <StyledLink style={{ color: textColor || "" }} to={RoutePaths.feed}>
                    Home
                  </StyledLink>
                </ExternalLink>
                <ExternalLink>
                  <StyledLinkTag
                    style={{ color: textColor || "" }}
                    href="https://home.boardroom.io/developers/"
                    target="_blank"
                    rel="noopener"
                  >
                    Developers
                  </StyledLinkTag>
                  <StyledCooliconIcon color={textColor || "#fff"} />
                </ExternalLink>
                <ExternalLink>
                  <StyledLinkTag
                    style={{ color: textColor || "" }}
                    href="https://roadmap.boardroom.info/b/bugs/"
                    target="_blank"
                    rel="noopener"
                  >
                    Submit an Issue
                  </StyledLinkTag>
                  <StyledCooliconIcon color={textColor || "#fff"} />
                </ExternalLink>
                <ExternalLink>
                  <StyledLinkTag style={{ color: textColor || "" }} href="https://docs.boardroom.io/" target="_blank">
                    Documentation
                  </StyledLinkTag>
                  <StyledCooliconIcon style={{ color: textColor || "" }} />
                </ExternalLink>
              </NavContainer>
              <NavContainer>
                <Tab style={{ borderColor: textColor || "" }} />
                <ExternalLink>
                  <StyledLinkTag style={{ color: textColor || "" }} href={boardroomDiscordLink} target="_blank">
                    Contact Us
                  </StyledLinkTag>
                </ExternalLink>
                <ExternalLink>
                  <StyledLinkTag
                    href="https://www.notion.so/boardroom/Job-Board-433b71bbb7424eaf910d9791e7a4fbd2"
                    target="_blank"
                    style={{ color: textColor || "" }}
                  >
                    Careers
                  </StyledLinkTag>
                  <StyledCooliconIcon color={textColor || "#fff"} />
                </ExternalLink>
                <ExternalLink>
                  <StyledLinkTag
                    href="https://boardroom.notion.site/200b74eb26a24ac8a49f0c83ca6f53fd?v=f784892f708f422cac6336e5e74dd9ab"
                    target="_blank"
                    style={{ color: textColor || "" }}
                  >
                    Branding
                  </StyledLinkTag>
                  <StyledCooliconIcon style={{ color: textColor || "" }} />
                </ExternalLink>
              </NavContainer>
            </FlexItem>
            <IconContainer>
              <a href={boardRoomOrgGithubLink} target="_blank">
                <StyledGithubIcon color={textColor || "#fff"} />
              </a>
              <a href={boardroomTwitterLink} target="_blank">
                <StyledTwitterIcon color={textColor || "#fff"} />
              </a>
              <a href={boardroomDiscordLink} target="_blank">
                <StyledDiscordIcon color={textColor || "#fff"} />
              </a>
              <a href="#">
                <img
                  onClick={() => (window as any)?.logBadgeClick()}
                  id="badge-button"
                  style={{ width: "192px", height: "42px" }}
                  src="https://static.alchemyapi.io/images/marketing/badge.png"
                  alt="Alchemy Supercharged"
                />
              </a>
            </IconContainer>
          </NavSection>
          <BoardroomMobileText to={RoutePaths.feed}>
            <BoardroomFooterLogo height={36} width={120} color="#fff" />
          </BoardroomMobileText>
        </FooterLayout>
      </FooterContainer>
    </>
  );
};
export default GlobalFooter;
