import { useInfiniteQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { GetDelegatorsToAddressDetails } from "@boardroom/boardroom-api";
import { getAddress } from "ethers/lib/utils.js";

import { apiClient } from "../utils/apiClient";
import { isAddress } from "web3-utils";
import { getProtocolAdapterInstances } from "../constants/protocols";

interface Props {
  address: string;
  protocol: string;
  suspense?: boolean;
}

export const useDelegatorsToAddress = ({ address, protocol, suspense = true }: Props) => {
  const adapterInstances = getProtocolAdapterInstances({ adapter: "delegation", protocol });
  const { data, fetchNextPage, hasNextPage } = useInfiniteQuery(
    [`delegatorsToAddress:${address?.toLowerCase()}:${protocol}`],
    async ({ pageParam: cursor }) => {
      try {
        const normalizedAddress = isAddress(address) ? getAddress(address) : address;
        const returnedData = await apiClient.getDelegatorsToAddress(normalizedAddress, {
          protocol,
          adapters: JSON.stringify(adapterInstances) as any as string[],
          address: normalizedAddress,
          ...(cursor ? { cursor } : {}),
        });
        return returnedData;
      } catch (error) {
        console.error(error);
      }
    },
    {
      getNextPageParam: (params) => params?.nextCursor,
      enabled: !!address && !!protocol && (adapterInstances?.length || 0) > 0,
      keepPreviousData: true,
      suspense,
    },
  );

  const delegators: GetDelegatorsToAddressDetails[] = useMemo(
    () =>
      (data?.pages
        .map((page) => page?.data)
        .flat()
        .sort((delegatorA, delegatorB) => (Number(delegatorB?.amount) || 0) - (Number(delegatorA?.amount) || 0))
        .filter(Boolean) as GetDelegatorsToAddressDetails[]) || [],
    [data],
  );

  return { delegators, fetchNextPage, hasNextPage };
};
