import React from "react";
import styled from "styled-components";
import { ProposalState } from "@boardroom/gov-sdk";
import { UtilityTag } from "./Tags";
import { getTagType } from "../utils/snapshot";

interface Props {
  status: ProposalState;
  borderRadius?: string;
  size?: "small" | "medium";
  marginRight?: string;
}

const StyledUtilityTag = styled(UtilityTag)<{ $borderRadius?: string; $marginRight?: string }>`
  text-transform: capitalize;
  border-radius: ${({ $borderRadius }) => $borderRadius || "none"};
  margin-right: ${({ $marginRight }) => $marginRight || "8px"};
`;

function StatusTag(props: Props) {
  const { status, borderRadius, size, marginRight } = props;
  const tagType = getTagType(status);

  return (
    <StyledUtilityTag
      type={tagType}
      label={status}
      $borderRadius={borderRadius}
      $marginRight={marginRight}
      size={size}
    />
  );
}

export default StatusTag;
