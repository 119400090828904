import * as React from "react";

const SvgCheckCircleIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" width="1em" height="1em" {...props}>
    <path
      d="M10 0a10 10 0 1010 10A10 10 0 0010 0zm4.89 6.9L9.6 14a.78.78 0 01-.54.3H9a.75.75 0 01-.53-.22l-3.29-3.24a.75.75 0 111-1.1l2.67 2.64L13.69 6a.75.75 0 011.2.9z"
      fill="currentColor"
    />
  </svg>
);

export default SvgCheckCircleIcon;
