import { useEffect, useState } from "react";
import { renderMarkdown } from "../utils/markdown";

const useRenderMd = (description?: string) => {
  const [result, setResult] = useState<any>(renderMarkdown(description || ""));

  useEffect(() => {
    const res = renderMarkdown(description || "");

    setResult(res);
  }, [description]);

  return result;
};

export default useRenderMd;
