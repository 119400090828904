import { CustomWeb3Provider } from "../reducers/CurrentWeb3Provider";

const EXTERNALLY_OWNED_ACCOUNT = Symbol("EXTERNALLY_OWNED_ACCOUNT");
export const CONTRACT_ACCOUNT = Symbol("CONTRACT_ACCOUNT");

type EthereumAccountType = typeof EXTERNALLY_OWNED_ACCOUNT | typeof CONTRACT_ACCOUNT;
// The contract accounts that Boardroom supports (Gnosis, Argent, etc).
type ContractAccount = { name: string; id: number };

// Externally Owned Accounts will always have Wallet Type ID = 0 because the backend will handle all EOAs the same way.
export const DEFAULT_WALLET_TYPE_ID = 0;
export const GNOSIS_SAFE_WALLET_TYPE_ID = 1;
// List of contract account wallets that Boardroom supports. Used to detect if the user is trying to log in with a contract account
const SUPPORTED_CONTRACT_ACCOUNTS: [ContractAccount] = [{ name: "Gnosis Safe", id: GNOSIS_SAFE_WALLET_TYPE_ID }];

// Checks if the wallet is one of the supported contract account types. If so, return the matching ContractAccount object.
// Otherwise returns null
function checkIfContractAccount(library: CustomWeb3Provider): ContractAccount | null {
  const walletName = library.provider?.wc?.peerMeta?.name;

  if (!walletName) {
    return null;
  }

  const contractAccount = SUPPORTED_CONTRACT_ACCOUNTS.find((contractAccount: ContractAccount) =>
    walletName.startsWith(contractAccount.name),
  );

  // If the wallet name doesn't match any supported contract accounts, it is either an EOA or we don't support it yet
  if (!contractAccount) {
    return null;
  }

  return contractAccount;
}

// Determines if the account is an EOA or a Contract Account
export function getEthereumAccountType(library?: CustomWeb3Provider): EthereumAccountType {
  // Currently we only support CONTRACT_ACCOUNT via WalletConnect so if it's a different connector, automatically use EOA
  if (!library?.provider?.isWalletConnect) {
    return EXTERNALLY_OWNED_ACCOUNT;
  }

  const contractAccount = checkIfContractAccount(library);

  if (contractAccount) {
    return CONTRACT_ACCOUNT;
  }

  return EXTERNALLY_OWNED_ACCOUNT;
}
