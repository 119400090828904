import React, { useCallback, useContext, useEffect, useMemo, useState, Suspense } from "react";
import Col from "antd/es/col";
import Row from "antd/es/row";
import notification from "antd/es/notification";
import Text from "antd/es/typography/Text";
import styled, { css } from "styled-components";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import ContentLoader from "react-content-loader";

import { Paragraph } from "../../../components/Typography";
import NotificationsProtocolsList from "../../../components/NotificationsProtocolsList/NotificationsProtocolsList";
import { COLORS } from "../../../constants/colors";
import { Input } from "../../../components";
import { CurrentAccountContext } from "../../../reducers/CurrentAccount";
import { Loader } from "../../../components/Loader";
import { useUserDetails } from "../../../hooks/useUserDetails";
import { updateUserDetails } from "../../../utils/updateUserDetails";
import { CurrentUuidContext } from "../../../reducers/CurrentUuid";
import mediaQuery from "../../../media-query";
import { TrackPageView } from "../../../components/TrackPageView";
import { ToggleSwitch } from "../../../components/Checkbox/ToggleSwitch";
import { useNavScroll } from "../../../hooks/useNavScroll";
import UnconnectedWalletState from "../../../components/UnconnectedWalletState";
import { useOnboardWallet } from "../../../hooks/useOnboardWallet";
import { Popover } from "../../../components/Popover";
import { CtaButtonDiv } from "../../../components/Dropdown/LeanDropdownItemList";
import { SettingsAlternateIcon, SettingsAngularIcon, WalletIcon } from "../../../components/icons";
import { useMixpanel } from "../../../hooks";
import { SettingsText, StyledRowMobile } from "./SettingsProfile";
import { StyledSaveButton, StyledSaveButtonWrapper } from "./SettingsFeed";
import { useSiweFunction } from "../../../hooks/useSiweFunction";

const StyledParagraph = styled(Paragraph)`
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: ${COLORS.primary.grayDarkLightest};
  margin-bottom: 8px;
`;

const StyledInput = styled(Input)<{ $validEmail: boolean; $invalidEmail: boolean }>`
  border: 1px solid
    ${(props) =>
      !props.$validEmail && !props.$invalidEmail
        ? `${COLORS.primary.accent}`
        : props.$validEmail
        ? `${COLORS.primary.accent}`
        : "#FF0000"};
  border-radius: 8px;
  margin-top: 32px;
  background: ${(props) => (props.$validEmail ? `${COLORS.primary.grayLighter}` : "transparent")};
`;

const ErrorMessage = styled("span")`
  font-style: italic;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #ff0000;
  margin-top: 8px;
  margin-left: 20px;
  display: flex;
`;

const StyledRow = styled(Row)`
  margin-bottom: 120px;
  border-radius: 8px;
  padding-bottom: 48px;
`;

const StyledDivAlt = styled.div`
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  ::-webkit-scrollbar {
    display: none;
  }
  ${mediaQuery.lessThan("991px")`
    overflow-x: auto;
  `}
`;

const StyledTab = styled(Link)<{ $active?: boolean }>`
  margin-right: 16px;
  color: ${({ $active }) => ($active ? COLORS.primary.grayDark : COLORS.primary.grayDarkLightest)};
  font-weight: ${({ $active }) => ($active ? "500" : "400")};
  font-size: 18px;
  line-height: 28px;
  display: inline-block;
  min-width: auto;
  ${mediaQuery.lessThan("991px")`
    line-height: 30px;
    font-size: 22px;
    min-width: auto;
  `}

  &:after {
    ${(props) =>
      props.$active &&
      css`
        height: 8px;
        display: block;
        border-bottom: 4px solid ${COLORS.primary.accent};
        content: "";
        ${mediaQuery.lessThan("640px")`
          height: 16px;
          width: calc(100% + 24px);
          font-weight: 400;
        `}
      `}
  }
`;

const TabsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 28px;
  border-bottom: 1px solid ${COLORS.primary.grayLighter};
  position: relative;
  ${mediaQuery.lessThan("991px")`
      height: 42px;
      left: 0px;
      width: 100%;
      display: flex;
      margin-right: 0px;
    `}
  ${mediaQuery.lessThan("640px")`
      width: 100%;
      background: #fafafa;
      z-index: +2;
      padding-left: 2px;
      height: 64px;
      position: sticky;
      position: -webkit-sticky;
      top: 80px;
      display: inline-block;
      padding-top: 16px;
      white-space: nowrap;
    `}
`;

const StyledDiv = styled("div")`
  padding: 20px;
  padding-top: 0;
`;

const StyledTitle = styled("span")`
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
  color: #191540;
  margin-bottom: 12px;
  display: block;
`;

const ContentContainer = styled("div")<{ $removeBorderBottom?: boolean }>`
  padding: 24px 0;
  ${({ $removeBorderBottom }) => !$removeBorderBottom && "border-bottom: 1px solid #f0eff8;"}
`;

const FlexDiv = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledCol = styled(Col)`
  background: white;
  padding-top: 12px;
  padding-left: 16px !important;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  box-shadow: 0px 2px 6px rgba(25, 21, 64, 0.05);
  width: 100%;
`;

const StyledText = styled.div`
  font-weight: 600;
  font-size: 16px;
  margin-left: 16px;
  ${mediaQuery.lessThan("640px")`
    font-size: 14px;
  `}
`;

const ConnectWalletWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const SettingsNotifications = () => {
  const [email, setEmail] = useState<string | undefined>();
  const [isEmailValid, setIsEmailValid] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [newlySavedProtocols, setNewlySavedProtocols] = useState([""]);
  const { account } = useContext(CurrentAccountContext);
  const [checkedList, setCheckedList] = useState<string[]>([]);
  const [contentCheckedList, setContentCheckedList] = useState<string[]>([]);
  const { user } = useUserDetails({ address: account, byPassCache: true });
  const { pathname } = useLocation();
  const { scrollFromTop } = useNavScroll();
  const wallet = useOnboardWallet();
  const { uuid } = useContext(CurrentUuidContext);
  const { trackClickSavePreferencesInNotificationsSettingsPage } = useMixpanel();
  const siweFunction = useSiweFunction();

  useEffect(() => {
    if (user && user.email) {
      setEmail(user.email);
    }
  }, [user]);

  const emailRegex = useMemo(() => /^[^\s@]+@[^\s@]+\.[^\s@]+$/, []);

  const validateEmail = useCallback(() => {
    if (!email?.length) {
      setIsEmailValid("Email is required");
      return;
    }
    const isValid = emailRegex.test(email);
    if (!isValid) {
      setIsEmailValid("That doesn’t look like a valid email address");
    } else {
      setIsEmailValid("valid");
    }
  }, [email, emailRegex, setIsEmailValid]);

  const saveEmail = useCallback(async () => {
    setIsSaving(true);
    if (!emailRegex.test(email || "")) {
      notification.error({
        message: "Error",
        description: <Text>Please enter a valid email</Text>,
      });
      setIsSaving(false);
      return;
    }
    if (!account) {
      notification.error({
        message: "Error",
        description: <Text>Please connect your wallet to save your preferences</Text>,
      });
      setIsSaving(false);
      return;
    }
    if (account && email) {
      const parsedProtocolsPreference: {
        [key: string]: boolean;
      } = {};
      checkedList?.filter(Boolean).map((protocolCname) => (parsedProtocolsPreference[protocolCname] = true));
      const parsedNotificationPreferences = contentCheckedList.includes("sameAsFeed")
        ? user?.savedProtocols
        : JSON.stringify(parsedProtocolsPreference);
      try {
        const data = {
          address: account,
          ...user,
          email,
          notificationPreferences: parsedNotificationPreferences,

          uuid,
        };
        await updateUserDetails({
          address: account,
          userData: data,
          siweFunction,
          siweErrorDescription: "You need to sign in with wallet to save your preferences",
        });
        notification.success({
          message: "Success",
          description: <Text>Your preferences have been saved</Text>,
          duration: 10,
        });
        setNewlySavedProtocols(checkedList || [""]);
        setIsSaved(true);
        trackClickSavePreferencesInNotificationsSettingsPage({
          userId: account,
        });
      } catch (e: any) {
        console.error(e);
        if (e.message !== "SIWE Error") {
          notification.error({
            message: "Error",
            description: <Text>Something went wrong... Please try again later</Text>,
          });
        }
      } finally {
        setIsSaving(false);
      }
    }
  }, [
    account,
    checkedList,
    contentCheckedList,
    email,
    emailRegex,
    siweFunction,
    user,
    uuid,
    trackClickSavePreferencesInNotificationsSettingsPage,
  ]);

  const toggleItem = (item: string) => {
    if (contentCheckedList.includes(item)) {
      setContentCheckedList(contentCheckedList.filter((selected) => selected !== item));
    } else {
      setContentCheckedList([...contentCheckedList, item]);
    }
  };

  return (
    <>
      <TrackPageView name={"Dashboard Settings Notifications"} />
      <StyledRowMobile style={{ marginLeft: 0 }} gutter={[16, 16]}>
        <Col xl={{ span: 20, push: 0 }} lg={{ span: 20, push: 0 }} md={{ span: 20, push: 0 }}>
          <StyledRow style={{ marginLeft: "24px" }} justify="start" gutter={20}>
            <Col style={{ paddingLeft: 0 }} xs={24} lg={16}>
              <SettingsText>
                <SettingsAngularIcon />
                Settings <span>Preferences</span>
              </SettingsText>
            </Col>
            {account ? (
              <>
                <StyledCol xl={18} xxl={16}>
                  <TabsWrapper>
                    <StyledDivAlt>
                      <StyledTab to={"/settings/feed"} $active={pathname.endsWith("feed")}>
                        Projects&nbsp;
                      </StyledTab>
                      <StyledTab to={"/settings/notifications"} $active={pathname.includes("notifications")}>
                        Notifications&nbsp;
                      </StyledTab>
                    </StyledDivAlt>
                  </TabsWrapper>
                </StyledCol>
                <Col
                  style={{
                    background: "white",
                    padding: 0,
                    borderBottomLeftRadius: "8px",
                    borderBottomRightRadius: "8px",
                    boxShadow: "0px 4px 6px rgba(25, 21, 64, 0.05)",
                    width: "100%",
                  }}
                  xl={18}
                  xxl={16}
                >
                  <StyledDiv>
                    <ContentContainer style={{ paddingBottom: "40px", paddingTop: "0" }}>
                      <StyledTitle>Enable Email Notifications</StyledTitle>
                      <StyledParagraph>
                        Stay in the know with an email digest of active and ending proposals from projects you are
                        interested in. Just three or fewer emails per week, we promise.
                      </StyledParagraph>
                      <StyledInput
                        type="email"
                        onChange={(event) => setEmail(event.target.value)}
                        $removeBorder
                        value={email}
                        onBlur={validateEmail}
                        $validEmail={isEmailValid === "valid"}
                        $invalidEmail={
                          isEmailValid === "Email is required" ||
                          isEmailValid === "That doesn’t look like a valid email address"
                        }
                        placeholder="Your Email"
                        name="email"
                      />
                      {isEmailValid === "Email is required" ||
                      isEmailValid === "That doesn’t look like a valid email address" ? (
                        <ErrorMessage>{isEmailValid}</ErrorMessage>
                      ) : null}
                    </ContentContainer>
                    <ContentContainer>
                      <FlexDiv>
                        <div>
                          <StyledTitle>Same as Projects Preferences</StyledTitle>
                          <StyledParagraph>
                            Reflect the same preferences you’ve set in the <Link to="/settings/feed">Projects</Link>{" "}
                            settings tab.
                          </StyledParagraph>
                        </div>
                        <Popover
                          trigger="hover"
                          zIndex={1001}
                          placement="top"
                          overlayClassName={"customizeButton"}
                          content={
                            <>
                              <CtaButtonDiv $borderRadiusAll to="/settings/feed" $hasCustomized={false}>
                                <SettingsAlternateIcon width={16} height={16} /> Edit Preferences
                              </CtaButtonDiv>
                            </>
                          }
                        >
                          <div>
                            <ToggleSwitch onClick={toggleItem} checkedList={contentCheckedList} value="sameAsFeed" />
                          </div>
                        </Popover>
                      </FlexDiv>
                      {!contentCheckedList.includes("sameAsFeed") && (
                        <>
                          <NotificationsProtocolsList
                            checkedList={checkedList}
                            setCheckedList={setCheckedList}
                            newlySavedProtocols={newlySavedProtocols}
                            userPropKey="notificationPreferences"
                          />
                        </>
                      )}
                    </ContentContainer>
                    <ContentContainer style={{ borderBottom: "0", opacity: "0.5" }}>
                      <FlexDiv>
                        <div>
                          <StyledTitle>Enable Push Notifications</StyledTitle>
                          <StyledParagraph>Coming Soon</StyledParagraph>
                        </div>
                        <ToggleSwitch
                          disabled
                          onClick={toggleItem}
                          checkedList={contentCheckedList}
                          value="pushNotifications"
                        />
                      </FlexDiv>
                    </ContentContainer>
                    <StyledSaveButtonWrapper $addHeight={scrollFromTop > 70}>
                      <StyledSaveButton disabled={isSaving || isSaved} $isSaved={isSaved} onClick={saveEmail}>
                        {isSaving && <Loader size="xsmall" />}
                        <span>{!isSaving && !isSaved ? "Save Preferences" : isSaving ? "Saving" : "Saved"}</span>
                      </StyledSaveButton>
                    </StyledSaveButtonWrapper>
                  </StyledDiv>
                </Col>
              </>
            ) : (
              <Col sm={24} md={22} xl={16}>
                <Suspense
                  fallback={
                    <ContentLoader
                      speed={2}
                      width="100%"
                      height={450}
                      backgroundColor={COLORS.primary.grayLight}
                      foregroundColor={COLORS.primary.grayLighter}
                    >
                      <circle cx="30" cy="38" r="20" />
                      <rect x="60" y="23" rx="5" ry="5" width="50%" height="12" />
                      <rect x="60" y="43" rx="5" ry="5" width="70%" height="12" />

                      <circle cx="30" cy="114" r="20" />
                      <rect x="60" y="99" rx="5" ry="5" width="50%" height="12" />
                      <rect x="60" y="119" rx="5" ry="5" width="70%" height="12" />

                      <circle cx="30" cy="190" r="20" />
                      <rect x="60" y="175" rx="5" ry="5" width="50%" height="12" />
                      <rect x="60" y="195" rx="5" ry="5" width="70%" height="12" />

                      <circle cx="30" cy="266" r="20" />
                      <rect x="60" y="251" rx="5" ry="5" width="50%" height="12" />
                      <rect x="60" y="271" rx="5" ry="5" width="70%" height="12" />

                      <circle cx="30" cy="342" r="20" />
                      <rect x="60" y="327" rx="5" ry="5" width="50%" height="12" />
                      <rect x="60" y="347" rx="5" ry="5" width="70%" height="12" />
                    </ContentLoader>
                  }
                >
                  <UnconnectedWalletState
                    handleClick={wallet?.openWalletModal}
                    title="A Missed Connection"
                    subTitle={
                      <>
                        Want a more personalized experience? <br /> Connect that wallet to see more.
                      </>
                    }
                  />
                </Suspense>
              </Col>
            )}
          </StyledRow>
        </Col>
      </StyledRowMobile>
    </>
  );
};

export const SettingsNotificationsModal = () => {
  const [email, setEmail] = useState<string | undefined>();
  const [isEmailValid, setIsEmailValid] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [newlySavedProtocols, setNewlySavedProtocols] = useState([""]);
  const { account } = useContext(CurrentAccountContext);
  const [checkedList, setCheckedList] = useState<string[]>([]);
  const [contentCheckedList, setContentCheckedList] = useState<string[]>([]);
  const { user } = useUserDetails({ address: account, byPassCache: true });
  const { scrollFromTop } = useNavScroll();
  const wallet = useOnboardWallet();
  const {
    trackClickSavePreferencesOnNotificationsModal,
    trackClickConnectWalletOnNotificationsModal,
    trackClickPreferencesLinkOnNotificationsModal,
    trackClickEditPreferencesButtonOnNotificationsModal,
  } = useMixpanel();
  const { uuid } = useContext(CurrentUuidContext);
  const siweFunction = useSiweFunction();

  useEffect(() => {
    if (user && user.email) {
      setEmail(user.email);
    }
  }, [user]);

  const emailRegex = useMemo(() => /^[^\s@]+@[^\s@]+\.[^\s@]+$/, []);

  const validateEmail = useCallback(() => {
    if (!email?.length) {
      setIsEmailValid("Email is required");
      return;
    }
    const isValid = emailRegex.test(email);
    if (!isValid) {
      setIsEmailValid("That doesn’t look like a valid email address");
    } else {
      setIsEmailValid("valid");
    }
  }, [email, emailRegex, setIsEmailValid]);

  const saveEmail = useCallback(async () => {
    setIsSaving(true);

    if (!emailRegex.test(email || "")) {
      notification.error({
        message: "Error",
        description: <Text>Please enter a valid email</Text>,
      });
      setIsSaving(false);
      return;
    }
    if (!account) {
      notification.error({
        message: "Error",
        description: <Text>Please connect your wallet to save your preferences</Text>,
      });
      setIsSaving(false);
      return;
    }
    if (account && email) {
      const parsedProtocolsPreference: {
        [key: string]: boolean;
      } = {};
      checkedList?.filter(Boolean).map((protocolCname) => (parsedProtocolsPreference[protocolCname] = true));
      const parsedNotificationPreferences = contentCheckedList.includes("sameAsFeed")
        ? user?.savedProtocols
        : JSON.stringify(parsedProtocolsPreference);
      try {
        const data = {
          address: account,
          ...user,
          email,
          notificationPreferences: parsedNotificationPreferences,

          uuid,
        };
        await updateUserDetails({
          address: account,
          userData: data,
          siweFunction,
          siweErrorDescription: "You need to sign in with wallet to save your preferences",
        });
        notification.success({
          message: "Success",
          description: <Text>Your preferences have been saved</Text>,
          duration: 10,
        });
        setNewlySavedProtocols(checkedList || [""]);
        setIsSaved(true);
        trackClickSavePreferencesOnNotificationsModal({
          userId: account,
        });
      } catch (e: any) {
        console.error(e);
        if (e?.message !== "SIWE Error") {
          notification.error({
            message: "Error",
            description: <Text>Something went wrong... Please try again later</Text>,
          });
        }
      } finally {
        setIsSaving(false);
      }
    }
  }, [
    account,
    checkedList,
    contentCheckedList,
    email,
    emailRegex,
    user,
    uuid,
    trackClickSavePreferencesOnNotificationsModal,
    siweFunction,
  ]);

  const toggleItem = useCallback(
    (item: string) => {
      if (contentCheckedList.includes(item)) {
        setContentCheckedList(contentCheckedList.filter((selected) => selected !== item));
      } else {
        setContentCheckedList([...contentCheckedList, item]);
      }
    },
    [contentCheckedList],
  );

  const handleClickEditPreferences = useCallback(() => {
    trackClickEditPreferencesButtonOnNotificationsModal({
      userId: account,
    });
  }, [trackClickEditPreferencesButtonOnNotificationsModal, account]);

  const handleClickPreferencesLink = useCallback(() => {
    trackClickPreferencesLinkOnNotificationsModal({
      userId: account,
    });
  }, [trackClickPreferencesLinkOnNotificationsModal, account]);

  const handleClickConnectWalletButton = useCallback(() => {
    trackClickConnectWalletOnNotificationsModal();
    wallet?.openWalletModal();
  }, [trackClickConnectWalletOnNotificationsModal, wallet]);

  return (
    <>
      <ContentContainer style={{ paddingBottom: "40px", paddingTop: "0" }}>
        <StyledTitle>Enable Email Notifications</StyledTitle>
        <StyledParagraph>
          Stay in the know with an email digest of active and ending proposals from projects you are interested in. Just
          three or fewer emails per week, we promise.
        </StyledParagraph>
        <StyledInput
          type="email"
          onChange={(event) => setEmail(event.target.value)}
          $removeBorder
          value={email}
          onBlur={validateEmail}
          $validEmail={isEmailValid === "valid"}
          $invalidEmail={
            isEmailValid === "Email is required" || isEmailValid === "That doesn’t look like a valid email address"
          }
          placeholder="Your Email"
          name="email"
        />
        {isEmailValid === "Email is required" || isEmailValid === "That doesn’t look like a valid email address" ? (
          <ErrorMessage>{isEmailValid}</ErrorMessage>
        ) : null}
      </ContentContainer>
      <ContentContainer $removeBorderBottom>
        <FlexDiv>
          <div>
            <StyledTitle>Same as Projects Preferences</StyledTitle>
            <StyledParagraph>
              Reflect the same preferences you’ve set in the{" "}
              <Link onClick={handleClickPreferencesLink} to="/settings/feed">
                Projects
              </Link>{" "}
              settings tab.
            </StyledParagraph>
          </div>
          <Popover
            trigger="hover"
            zIndex={1001}
            placement="top"
            overlayClassName={"customizeButton"}
            content={
              <>
                <CtaButtonDiv
                  onClick={handleClickEditPreferences}
                  $borderRadiusAll
                  to="/settings/feed"
                  $hasCustomized={false}
                >
                  <SettingsAlternateIcon width={16} height={16} /> Edit Preferences
                </CtaButtonDiv>
              </>
            }
          >
            <div>
              <ToggleSwitch onClick={toggleItem} checkedList={contentCheckedList} value="sameAsFeed" />
            </div>
          </Popover>
        </FlexDiv>
        {!contentCheckedList.includes("sameAsFeed") && (
          <>
            <NotificationsProtocolsList
              checkedList={checkedList}
              setCheckedList={setCheckedList}
              newlySavedProtocols={newlySavedProtocols}
              userPropKey="notificationPreferences"
            />
          </>
        )}
      </ContentContainer>
      <StyledSaveButtonWrapper $addHeight={scrollFromTop > 70}>
        {account ? (
          <StyledSaveButton disabled={isSaving || isSaved} $isSaved={isSaved} onClick={saveEmail}>
            {isSaving && <Loader size="xsmall" />}
            <span>{!isSaving && !isSaved ? "Save Preferences" : isSaving ? "Saving" : "Saved"}</span>
          </StyledSaveButton>
        ) : (
          <StyledSaveButton $isSaved={false} onClick={handleClickConnectWalletButton}>
            <ConnectWalletWrapper>
              <WalletIcon height={24} width={24} color="#ffffff" /> <StyledText>Connect Your Wallet to Save</StyledText>
            </ConnectWalletWrapper>
          </StyledSaveButton>
        )}
      </StyledSaveButtonWrapper>
    </>
  );
};

export default React.memo(SettingsNotifications);
