// import axios from "axios";
import { getAddress } from "ethers/lib/utils";
import notification from "antd/es/notification";

import { apiClient } from "../utils/apiClient";
import { getUuid } from "./getUuid";

interface Props {
  protocol: string;
  address: string;
  delegationPitch: string;
  siweFunction?: any;
  trackUpdateDelegationPitch: ({ userId }: { userId: string }) => void;
  team?: string;
}

export const updateDelegationPitch = async ({
  address,
  protocol,
  delegationPitch,
  siweFunction,
  trackUpdateDelegationPitch,
  team,
}: Props) => {
  const uuid = getUuid(address);
  if (!uuid) {
    notification.error({
      message: "Error",
      description: "You need to sign in with wallet to update your delegation settings",
    });
    if (siweFunction) {
      siweFunction();
    }
    throw new Error("SIWE Error");
  }
  if (!protocol || !address) {
    throw new Error();
  }
  try {
    const normalizedAddress = getAddress(address);
    const data = (await apiClient.updateDelegationPitch(protocol, normalizedAddress, delegationPitch, uuid, team)).data;
    trackUpdateDelegationPitch({
      userId: normalizedAddress,
    });
    return data;
  } catch (error) {
    console.error(error);
  }
};
