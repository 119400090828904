import * as React from "react";

const SvgHeartOutlinedIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.08 15.64" width="1em" height="1em" {...props}>
    <path
      d="M4.79 2a2.93 2.93 0 01.88.13 2.86 2.86 0 011.1.68l.08.07 1.33 1.2a2 2 0 001.34.52 2 2 0 001.35-.52l1.32-1.2.06-.07a2.87 2.87 0 011.12-.68 2.82 2.82 0 01.87-.13 2.77 2.77 0 011.66.54 2.67 2.67 0 01.84 1 1.67 1.67 0 00.18.28 2.7 2.7 0 01-.65 2.86l-6.71 6.9-6.71-6.85H2.8A2.75 2.75 0 012 5.2a2.7 2.7 0 01.25-1.64 2.9 2.9 0 01.89-1A3.08 3.08 0 014.32 2a3.71 3.71 0 01.47 0m0-2A5.25 5.25 0 004 .06a5.09 5.09 0 00-2 .86A5 5 0 00.51 2.63a4.73 4.73 0 00-.45 2.89 4.65 4.65 0 001.35 2.6L8.08 15a2 2 0 001.46.63 2 2 0 001.37-.54L11 15l6.67-6.9a4.7 4.7 0 00.9-5.49 4.64 4.64 0 00-1.5-1.69A4.78 4.78 0 0014.24 0a4.67 4.67 0 00-1.48.23 4.77 4.77 0 00-1.92 1.17L9.52 2.6 8.19 1.4A4.67 4.67 0 006.28.23 4.73 4.73 0 004.79 0z"
      fill="currentColor"
    />
  </svg>
);

export default SvgHeartOutlinedIcon;
