import * as React from "react";

const SvgFlagCircleIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7.6 17.6v-4.8m0 0V5.997a.48.48 0 01.705-.424l5.335 2.825a.48.48 0 01.02.838L7.6 12.8zM18 10a8 8 0 11-16 0 8 8 0 0116 0z"
      stroke="currentColor"
      strokeWidth={1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgFlagCircleIcon;
