import React, { useState } from "react";
import styled from "styled-components";
import notification from "antd/es/notification";
import CopyToClipboard from "react-copy-to-clipboard";

import { Button } from "../Button";
import { COLORS } from "../../constants/colors";
import { ExpandCollapseArrowThin, CheckIcon, CopyIcon } from "../icons";
import InfoCard from "../InfoCard";

const StyledBackButton = styled(Button)`
  border-radius: 8px !important;
  margin: 4px auto 4px;
  width: 90%;
  border: 0 !important;
  font-weight: 500 !important;
  font-size: 14px;
  line-height: 16px;
`;

const VerifiedAddressContainer = styled("div")<{ $addMargin?: boolean }>`
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 16px;
  color: #54c63f;
  font-style: italic;
  margin: ${({ $addMargin }) => ($addMargin ? "0 0 24px 16px" : "0")};
  span {
    margin-left: 8px;
  }
`;

const ReviewLabel = styled("span")`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: ${COLORS.primary.grayDark};
  margin: 0 0 4px 10px;
`;

const ReviewValue = styled("span")<{ $noMaxWidth?: boolean }>`
  font-size: 14px;
  line-height: 18px;
  color: ${COLORS.primary.grayDarkLightest};
  margin: 0 0 24px 10px;
  ${({ $noMaxWidth }) => !$noMaxWidth && "max-width: 70%;"}
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ContractAddressContainer = styled("div")`
  display: flex;
`;

const ConfirmedCommandContainer = styled("div")`
  padding-left: 12px;
  border-left: 2px solid ${COLORS.primary.grayLighter};
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  margin-top: 14px;
`;

const ConfirmedCommandItemContainer = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ConfirmedCommandMethodContainer = styled("div")`
  display: flex;
  flex-direction: column;
`;

const ConfirmedCommandLabel = styled("span")`
  font-size: 14px;
  line-height: 18px;
  color: ${COLORS.primary.grayDark};
  margin: 0 0 4px;
`;

const ConfirmedCommandValue = styled("span")`
  font-size: 14px;
  line-height: 18px;
  color: ${COLORS.primary.grayDarkLightest};
  max-width: 70%;
`;

const ExpandCollapseIcon = styled(ExpandCollapseArrowThin)<{ $isExpanded: boolean }>`
  margin-left: auto;
  transform: rotate(${({ $isExpanded }) => ($isExpanded ? "180deg" : "0deg")});
  transition: transform 0.2s;
  cursor: pointer;
`;

const ExpandedConfirmedCommandItemContainer = styled("div")`
  margin: 24px 0 0 12px;
  display: flex;
  flex-direction: column;
`;

const StyledInfoCard = styled(InfoCard)`
  margin-bottom: 16px;
  .ant-card-head-title h5 {
    color: ${COLORS.primary.grayDark};
    letter-spacing: 0.1em;
  }
`;

const CopyToClipboardContainer = styled("span")`
  display: flex;
  align-items: center;
  color: #4235e1;
  gap: 8px;
  cursor: pointer;
`;

interface Props {
  target: string;
  value: string;
  method: string;
  callDataTopicValues: string[];
  deleteThisCommandFunction: () => void;
}

const ConfirmedCommand = ({ callDataTopicValues, method, target, value, deleteThisCommandFunction }: Props) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <ConfirmedCommandContainer>
      <ConfirmedCommandItemContainer>
        <ConfirmedCommandMethodContainer>
          <ConfirmedCommandLabel>Method</ConfirmedCommandLabel>
          <ConfirmedCommandValue>{method}</ConfirmedCommandValue>
        </ConfirmedCommandMethodContainer>
        <ExpandCollapseIcon
          width={16}
          height={16}
          color={COLORS.primary.accent}
          onClick={() => setIsExpanded(!isExpanded)}
          $isExpanded={isExpanded}
        />
      </ConfirmedCommandItemContainer>
      {isExpanded && (
        <ExpandedConfirmedCommandItemContainer>
          <ReviewLabel>Contract Address</ReviewLabel>
          <ContractAddressContainer>
            <ReviewValue>{target}</ReviewValue>{" "}
            <VerifiedAddressContainer $addMargin>
              <CheckIcon width={16} height={16} /> <span>Verified Address</span>
            </VerifiedAddressContainer>
          </ContractAddressContainer>
          <ReviewLabel>Value</ReviewLabel>
          <ReviewValue>{value} Wei</ReviewValue>
          <ReviewLabel>Signature</ReviewLabel>
          <ReviewValue>{method}</ReviewValue>
          <ReviewLabel>Call Data</ReviewLabel>
          {callDataTopicValues?.map((value) => (
            <ReviewValue key={value}>{value}</ReviewValue>
          ))}
          <StyledBackButton onClick={deleteThisCommandFunction} type="secondary">
            Delete This Command
          </StyledBackButton>
        </ExpandedConfirmedCommandItemContainer>
      )}
    </ConfirmedCommandContainer>
  );
};

interface ExecutableCommandProps {
  target: string;
  value: any;
  method: string;
  callData: string;
  index: number;
}

export const ExecutableCommand = ({ callData, method, target, value, index }: ExecutableCommandProps) => {
  const values = Object.entries(value);

  return (
    <StyledInfoCard title={`FUNCTION ${index}`}>
      <ConfirmedCommandContainer style={{ borderLeft: 0, paddingLeft: "0" }}>
        <ConfirmedCommandItemContainer>
          <ConfirmedCommandMethodContainer>
            <ConfirmedCommandLabel>Method</ConfirmedCommandLabel>
            <ConfirmedCommandValue>{method}</ConfirmedCommandValue>
          </ConfirmedCommandMethodContainer>
        </ConfirmedCommandItemContainer>
        <ExpandedConfirmedCommandItemContainer>
          <ReviewLabel>Contract Address</ReviewLabel>
          <ContractAddressContainer>
            <ReviewValue $noMaxWidth>{target}</ReviewValue>{" "}
          </ContractAddressContainer>
          <ReviewLabel>Params</ReviewLabel>
          <ReviewValue $noMaxWidth>
            {values?.map((param, index) => (
              <span style={{ display: "block" }} key={index}>
                <span style={{ fontWeight: "500" }}>{param[0]}: </span>
                {param[1] as String}
              </span>
            ))}
          </ReviewValue>
          <ReviewLabel>Signature</ReviewLabel>
          <ReviewValue $noMaxWidth>{method}</ReviewValue>
          <ReviewLabel style={{ display: "flex", justifyContent: "space-between" }}>
            Call Data{" "}
            <CopyToClipboard
              onCopy={() =>
                notification.success({
                  message: "Copied to clipboard",
                })
              }
              text={callData}
            >
              <CopyToClipboardContainer>
                <CopyIcon width={16} height={16} style={{ marginLeft: "8px", cursor: "pointer" }} />
                Copy
              </CopyToClipboardContainer>
            </CopyToClipboard>
          </ReviewLabel>
          <ReviewValue $noMaxWidth style={{ wordWrap: "break-word" }} key={value}>
            {callData}
          </ReviewValue>
        </ExpandedConfirmedCommandItemContainer>
      </ConfirmedCommandContainer>
    </StyledInfoCard>
  );
};

export default ConfirmedCommand;
