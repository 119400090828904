import { Paywall } from "@unlock-protocol/paywall";
import { networks } from "@unlock-protocol/networks";
import React, { useContext } from "react";

import { CurrentWeb3Context } from "../reducers/CurrentWeb3Provider";
import { CurrentAccountContext } from "../reducers/CurrentAccount";
import {
  monthlySubscriptionLockAddress,
  networkId,
  yearlySubscriptionLockAddress,
} from "../hooks/useHasValidNftSubscription";
import { SubscriptionButton } from "./PaymentGate/PaymentGate";

export function MintSubscriptionButton({ type }: { type: string }) {
  const { web3Instance } = useContext(CurrentWeb3Context);
  const { account } = useContext(CurrentAccountContext);
  const title = type === "yearly" ? "Subscribe Yearly" : "Subscribe Monthly";
  const lockAddress = type === "yearly" ? yearlySubscriptionLockAddress : monthlySubscriptionLockAddress;
  async function mint() {
    const config = {
      pessimistic: true,
      title: "",
      locks: {
        [lockAddress]: {
          network: networkId,
          recurringPayments: type === "yearly" ? "2" : "24",
        },
      },
      metadataInputs: [
        {
          name: "Email",
          defaultValue: "",
          type: "email",
          required: true,
          placeholder: "john@baordroom.info",
          public: false,
        },
      ],
      recipient: account,
    };
    const provider = web3Instance?.provider;
    const paywall = new Paywall(networks);
    paywall.connect(provider);
    await paywall.loadCheckoutModal(config, "https://app.unlock-protocol.com/");
  }

  return (
    <>
      <SubscriptionButton onClick={mint}>{title}</SubscriptionButton>
    </>
  );
}
