import React, { ReactNode, useMemo } from "react";
import AntdFormItem from "antd/es/form/FormItem";
import styled from "styled-components";

import { Input } from "../Input";

interface Props {
  name: string;
  label: string;
  initialValue?: string | number;
  isRequired?: boolean;
  disabled?: boolean;
  suffix?: ReactNode;
  prefix?: ReactNode;
  addMarginBottom?: boolean;
}

export const StyledFormItem = styled(AntdFormItem)<{ $addMarginBottom?: boolean }>`
  .ant-form-item-explain-error {
    display: none;
  }
  margin-bottom: ${(props) => (props.$addMarginBottom ? "24px" : "0")};
`;

const ProposalInput = ({
  name,
  label,
  initialValue,
  isRequired = false,
  disabled = false,
  suffix,
  prefix,
  addMarginBottom = false,
}: Props) => {
  const validationRules = useMemo(() => [{ required: isRequired, message: "" }], [isRequired]);

  return (
    <StyledFormItem
      name={name}
      initialValue={initialValue}
      rules={validationRules}
      validateTrigger={["onChange", "onBlur"]}
      $addMarginBottom={addMarginBottom}
    >
      <Input prefix={prefix} suffix={suffix} disabled={disabled} placeholder={label} size="large" />
    </StyledFormItem>
  );
};

export default React.memo(ProposalInput);
