import React, { useMemo, useState, useCallback, Suspense, useContext } from "react";
import Tooltip from "antd/es/tooltip";
import CopyToClipboard from "react-copy-to-clipboard";
import { Link, useLocation } from "react-router-dom";
import ContentLoader from "react-content-loader";

import { useGetEnsAvatar, useGetEns } from "../../hooks/useEns";
import { useUserDetails } from "../../hooks/useUserDetails";
import Blockie from "../Blockie";
import { LightningIcon, PersonEditIcon } from "../icons";
import { formatEns, toShortAddress } from "../../utils";
import { useVoter } from "../../hooks/useVoter";
import { protocols } from "../../constants/protocols";
import { useDelegateActivity } from "../../hooks/useDelegatesTable";
import formatValue from "../../utils/formatValue";
import { useMixpanel } from "../../hooks";
import DelegationModal from "../Voting/DelegationModal";
import {
  ActiveProtocolsWrapper,
  ActivitySectionTitle,
  BannerImage,
  BannerWrapper,
  EditYourDelegationButton,
  FirstSectionWrapper,
  FlexDiv,
  FlexItem,
  ImgWrapper,
  MoreProjectsDiv,
  ProfileImageBorder,
  ProfileName,
  SecondSectionWrapper,
  StyledCopyIcon,
  StyledDelegateIcon,
  StyledImg,
  StyledInfo,
  StyledProtocolIcon,
  VisitProfile,
  VotePowerWrapper,
  VPText,
  VPValue,
  Wrapper,
} from "./styles";
import { ActivityLoader, FirstSectionLoader } from "./DelegatorProfileSidebarSkeleton";
import { CurrentAccountContext } from "../../reducers/CurrentAccount";
import { COLORS } from "../../constants/colors";

const ProfileDetails = ({
  address,
  protocolSelected,
}: {
  address: string;
  protocolSelected: string;
  isDelegator: boolean;
}) => {
  const { user } = useUserDetails({ address });
  const ensAvatar = useGetEnsAvatar(address);
  const ens = useGetEns(address);
  const [showImage, setShowImage] = useState(true);
  const [copied, setCopied] = useState(false);
  const { voter } = useVoter({ address, suspense: false });
  const votedProtocols = useMemo(
    () => voter?.protocols?.map((protocol) => protocol.protocol)?.filter((protocol) => protocols[protocol]?.isEnabled),
    [voter],
  );
  const { data: activityData, votePowerIsLoading } = useDelegateActivity({
    address,
    protocol: protocolSelected,
    suspense: false,
  });

  const addDefaultSrcPfp = () => {
    setShowImage(false);
  };

  const randomNumber = useMemo(() => Math.ceil(Math.random() * 5), []);

  const addDefaultSrc = (event: any) => {
    event.target.src = `${process.env.PUBLIC_URL}/assets/ProfileBanner${randomNumber}.png`;
  };

  const profileNameValue = user?.username || (ens !== "" && !!ens && formatEns(ens)) || toShortAddress(address);

  const handleVisibleChange = (visible: boolean) => {
    if (!visible) {
      setTimeout(() => {
        setCopied(false);
      }, 100);
    }
  };
  const onCopy = useCallback(() => setCopied(true), [setCopied]);
  return (
    <FirstSectionWrapper>
      <BannerWrapper>
        <BannerImage
          src={user?.bannerUrl || `${process.env.PUBLIC_URL}/assets/ProfileBanner${randomNumber}.png`}
          onError={addDefaultSrc}
        />
      </BannerWrapper>
      <ImgWrapper>
        <ProfileImageBorder>
          {(user?.pfpUrl || ensAvatar) && showImage ? (
            <StyledImg
              src={user?.pfpUrl || ensAvatar}
              style={{
                width: "72px",
                height: "72px",
              }}
              onError={addDefaultSrcPfp}
            />
          ) : (
            <Blockie seed={address} dimension={72} />
          )}
        </ProfileImageBorder>
        <StyledDelegateIcon />
        <VotePowerWrapper>
          <LightningIcon color="#9E97F3" />
          <VPText>VP</VPText>{" "}
          {votePowerIsLoading ? (
            <span style={{ fontWeight: 300 }}>
              <ContentLoader
                speed={2}
                backgroundColor={COLORS.primary.grayLight}
                foregroundColor={COLORS.primary.grayLighter}
                height={32}
              >
                <rect x="0" y="8" rx="5" ry="5" width="24px" height="16" />
              </ContentLoader>
            </span>
          ) : (
            <VPValue>{formatValue(activityData?.votePower)}</VPValue>
          )}
        </VotePowerWrapper>
      </ImgWrapper>
      <FlexItem>
        <Tooltip title={address}>
          <ProfileName
            $lengthBiggerThan20={(profileNameValue?.length || 0) >= 20}
            $lengthBiggerThan200={(profileNameValue?.length || 0) >= 200}
            level={3}
          >
            {profileNameValue}
          </ProfileName>
        </Tooltip>
        <CopyToClipboard text={address} onCopy={onCopy}>
          <Tooltip title={copied ? "copied" : "copy"} onVisibleChange={handleVisibleChange}>
            <StyledCopyIcon />
          </Tooltip>
        </CopyToClipboard>
      </FlexItem>
      <StyledInfo>Delegated by {activityData?.delegatorCount} Voters</StyledInfo>
      <ActiveProtocolsWrapper>
        {votedProtocols?.slice(0, 8)?.map((protocol) => {
          const protocolInfo = protocols[protocol];
          return (
            <Tooltip title={protocolInfo?.name} key={protocolInfo?.cname}>
              <Link to={`/${protocolInfo?.path}/overview`}>
                <StyledProtocolIcon size="xsmall" protocol={protocolInfo} />
              </Link>
            </Tooltip>
          );
        })}
        {(votedProtocols?.length || 0) > 8 && (
          <Tooltip title={`${(votedProtocols?.length || 0) - 8} more`}>
            <MoreProjectsDiv>+{(votedProtocols?.length || 0) - 8}</MoreProjectsDiv>
          </Tooltip>
        )}
      </ActiveProtocolsWrapper>
    </FirstSectionWrapper>
  );
};

const ProfileActivity = ({ address, protocolSelected }: { address: string; protocolSelected: string }) => {
  const { data: activityData, votePowerIsLoading } = useDelegateActivity({ address, protocol: protocolSelected });
  return (
    <>
      <div>
        <FlexDiv>
          <div>
            <ActivitySectionTitle>
              Votes <span style={{ fontWeight: 300 }}>{activityData?.votesCast}</span>
            </ActivitySectionTitle>
          </div>
          <div>
            <ActivitySectionTitle style={{ display: "flex", alignItems: "center" }}>
              VP
              {votePowerIsLoading ? (
                <span style={{ fontWeight: 300 }}>
                  <ContentLoader
                    speed={2}
                    backgroundColor={COLORS.primary.grayLight}
                    foregroundColor={COLORS.primary.grayLighter}
                    height={32}
                  >
                    <rect x="0" y="8" rx="5" ry="5" width="24px" height="16" />
                  </ContentLoader>
                </span>
              ) : (
                <span
                  style={{
                    fontWeight: 300,
                    color: "#4235e1",
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "4px",
                  }}
                >
                  <LightningIcon />
                  {formatValue(activityData?.votePower, 2)}
                </span>
              )}
            </ActivitySectionTitle>
          </div>
        </FlexDiv>
        <FlexDiv style={{ marginTop: "6px" }}>
          <div>
            <ActivitySectionTitle>
              Proposals <span style={{ fontWeight: 300 }}> {activityData?.proposalsMade}</span>
            </ActivitySectionTitle>
          </div>
          <div>
            <ActivitySectionTitle>
              Delegators <span style={{ fontWeight: 300 }}>{activityData?.delegatorCount}</span>
            </ActivitySectionTitle>
          </div>
        </FlexDiv>
      </div>
    </>
  );
};

const DelegatorProfileSidebar = ({
  address,
  protocolSelected,
  undelegated,
  isDelegator,
  updateAddressToUseWithDefinedAddress,
}: {
  address: string;
  protocolSelected: string;
  undelegated: boolean;
  isDelegator: boolean;
  updateAddressToUseWithDefinedAddress: (address: string) => void;
}) => {
  const { account } = useContext(CurrentAccountContext);
  const { search } = useLocation();
  const protocolInfo = protocols[protocolSelected];
  const [visible, setVisible] = useState<boolean>(false);
  const { trackStartSetupDelegation, trackClickVisitProfileButtonDelegationTab } = useMixpanel();

  const handleVisible = useCallback(
    (protocolName: string) => {
      setVisible(true);
      trackStartSetupDelegation({
        protocol: protocolName,
        userId: `${address}`,
      });
    },
    [address, trackStartSetupDelegation],
  );

  const updateAddressFunc = useCallback(
    (address: string) => {
      window.history.pushState(null, "", `/people/${protocolSelected}/${address}${search}`);
      updateAddressToUseWithDefinedAddress(address);
    },
    [protocolSelected, updateAddressToUseWithDefinedAddress, search],
  );

  const onVisitProfileClick = useCallback(() => {
    trackClickVisitProfileButtonDelegationTab({
      userId: account,
      protocol: protocolSelected,
    });
  }, [account, protocolSelected, trackClickVisitProfileButtonDelegationTab]);

  return (
    <>
      <DelegationModal
        updateAddressFunc={updateAddressFunc}
        visible={visible}
        setVisible={setVisible}
        address={address}
        protocolName={protocolInfo?.cname}
      />

      {undelegated ? (
        <>
          <EditYourDelegationButton onClick={() => handleVisible(protocolInfo?.cname)}>
            <PersonEditIcon />
            <span>Edit Your Delegation</span>
          </EditYourDelegationButton>
        </>
      ) : (
        <Wrapper>
          <EditYourDelegationButton onClick={() => handleVisible(protocolInfo?.cname)}>
            <PersonEditIcon />
            <span>Edit Your Delegation</span>
          </EditYourDelegationButton>
          <Suspense fallback={<FirstSectionLoader />}>
            <ProfileDetails address={address} protocolSelected={protocolSelected} isDelegator={isDelegator} />
          </Suspense>
          <SecondSectionWrapper>
            <Suspense fallback={<ActivityLoader />}>
              <ProfileActivity address={address} protocolSelected={protocolSelected} />
            </Suspense>
            <VisitProfile onClick={onVisitProfileClick} to={`/voter/${address}`}>
              Visit Profile
            </VisitProfile>
          </SecondSectionWrapper>
        </Wrapper>
      )}
    </>
  );
};

export { DelegatorProfileSidebar };
