import * as React from "react";

const SvgCommentIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M1.333 4c0-.736.597-1.333 1.334-1.333h10.666c.737 0 1.334.597 1.334 1.333v7.333c0 .737-.597 1.334-1.334 1.334h-3.057L8.471 14.47a.667.667 0 01-.942 0l-1.805-1.804H2.667a1.333 1.333 0 01-1.334-1.334V4zm12 0H2.667v7.333H6c.177 0 .346.07.471.196L8 13.057l1.529-1.528a.667.667 0 01.471-.196h3.333V4zM4 6.333c0-.368.298-.666.667-.666h6.666a.667.667 0 110 1.333H4.667A.667.667 0 014 6.333zM4 9c0-.368.298-.667.667-.667h4a.667.667 0 010 1.334h-4A.667.667 0 014 9z"
      fill="currentColor"
    />
  </svg>
);

export default SvgCommentIcon;
