import * as React from "react";

const SvgInfoIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" {...props}>
    <path
      d="M14.98.903A15.256 15.256 0 004.342 5.468 14.748 14.748 0 00.003 16.16 14.722 14.722 0 0014.75 30.9h.267A15.138 15.138 0 0030 15.64 14.706 14.706 0 0014.98.903zm-1.854 20.675a1.846 1.846 0 011.811-1.912h.034a1.908 1.908 0 011.903 1.837 1.845 1.845 0 01-1.81 1.912h-.034a1.91 1.91 0 01-1.904-1.837zm.625-5.052v-7.5a1.25 1.25 0 012.5 0v7.5a1.25 1.25 0 01-2.5 0z"
      fill="currentColor"
    />
  </svg>
);

export default SvgInfoIcon;
