import * as React from "react";

const SvgFunnelFilterIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M11.333 0H.667A.666.666 0 000 .667v1.726c0 .349.142.692.389.938L4 6.943V12a.668.668 0 00.965.597l2.666-1.334A.667.667 0 008 10.667V6.943l3.611-3.612c.247-.246.389-.589.389-.938V.667A.666.666 0 0011.333 0zM6.862 6.195a.664.664 0 00-.195.472v3.588l-1.334.666V6.667a.664.664 0 00-.195-.472L1.333 2.393v-1.06h9.334l.002 1.056-3.807 3.806z"
      fill="currentColor"
    />
  </svg>
);

export default SvgFunnelFilterIcon;
