import React, { useState, createContext, SetStateAction, useContext, useEffect } from "react";
import { useLocation } from "react-router";
import { CurrentProjectsFilterContext } from "./CurrentProjectsFilter";

interface Props {
  children: React.ReactNode;
}

interface CurrentRefIdType {
  currentRefId: string;
  setCurrentRefId: React.Dispatch<SetStateAction<string>>;
}

export const CurrentRefIdContext = createContext({} as CurrentRefIdType);

export const CurrentRefIdProvider = ({ children }: Props) => {
  const { pathname } = useLocation();
  const [currentRefId, setCurrentRefId] = useState<string>("");
  const { projectsFilter } = useContext(CurrentProjectsFilterContext);

  useEffect(() => {
    setCurrentRefId("");
  }, [pathname, projectsFilter]);

  return (
    <CurrentRefIdContext.Provider value={{ currentRefId, setCurrentRefId }}>{children}</CurrentRefIdContext.Provider>
  );
};

export const useCurrentRefId = () => useContext(CurrentRefIdContext);
