import React, { useCallback, useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import Row from "antd/es/row";
import Col from "antd/es/col";
import Comment from "antd/es/comment";
import { Link, useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import parse from "html-react-parser";
import { useCurrentWidth } from "react-socks";
import InfiniteScroll from "react-infinite-scroller";
import moment from "moment";
import axios from "axios";
import { DiscourseTopicsData, DiscourseTopicPostsData } from "@boardroom/boardroom-api";
import { TwitterShareButton } from "react-share";

import { CardsContainer } from "../components";
import { useMixpanel } from "../hooks";
import { useCurrentProtocol__Deprecated } from "../hooks/useCurrentProtocol__Deprecated";
import { DiscourseCategory, DiscoursePost, DiscourseTopic, ProtocolDescription } from "../types";
import ProtocolInnerTabNav from "../components/NavBar/ProtocolInnerTabNav";
import { CategoryAndTagsWrapper, ParsedTopic, StyledCategory, StyledTag, TopicsTable } from "./ProtocolForum";
import { Dot } from "../components/ProtocolForumFilters/ProtocolForumFilters";
import { AuthorWrapper, PostedByText } from "../components/Proposals/ProposalComments/ProposalComment";
import { LinkStyles } from "../components/Typography/Link";
import ExpandCollapseRows from "../components/common/ExpandCollapseRows";
import {
  ArrowRightIcon,
  CommunicationBoldIcon,
  CommunicationIcon,
  ExternalLinkIcon,
  EyeIcon,
  HeartOutlinedIcon,
  Link2Icon,
  MultipleUsersIcons,
  PinnedIcon,
  ThinShareIcon,
} from "../components/icons";
import { Table } from "../components/Table";
import { CurrentAccountContext } from "../reducers/CurrentAccount";
import media from "../media-query";
import { ProtocolOverviewContainerRefContext } from "../reducers/ProtocolOverviewContainerRef";
import SvgGlassesIcon from "../components/icons/GlassesIcon";
import { Popover } from "../components/Popover";
import { TrackPageView } from "../components/TrackPageView";
import { useDiscourseCategories } from "../hooks/useDiscourseCategories";
import { protocols } from "../constants/protocols";
import { useDiscourseTopicPosts } from "../hooks/useDiscourseTopicPosts";
import { COLORS } from "../constants/colors";
import { ContentWrapper } from "./ProtocolOverview/styles";
import { SeeMoreText } from "../components/Calendar/ListView/styles";

const TopicPost = styled(Comment)<{ $removePadding?: boolean }>`
  .ant-comment-content-detail p,
  .ant-comment-content-detail ul {
    &:not(:last-child) {
      padding-bottom: 40px;
    }
  }
  .ant-comment-inner {
    img:not(.emoji, .thumbnail, .avatar) {
      max-width: 100%;
      max-height: 100%;
      width: 100%;
      height: 100%;
    }
    aside.quote {
      font-style: italic;
      color: #7b7893;
      border-left: 4px solid #f0eff8;
      padding-left: 16px;
    }
    aside.onebox {
      border: 1px solid #f0eff8;
      padding: 16px;
      background-color: #fafafa;
      margin-bottom: 16px;
      .source {
        font-size: 12px;
        line-height: 16px;
        color: #7b7893;
        margin-bottom: 8px;
        display: block;
        img.site-icon {
          width: 16px;
          height: 16px;
          margin-right: 8px;
        }
      }
      .aspect-image {
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .ant-comment-avatar img {
    height: 40px !important;
    width: 40px !important;
    border-radius: 50%;
  }
  .ant-comment-avatar {
    position: absolute;
  }
  .ant-comment-content-author {
    flex-direction: column;
    margin-bottom: 20px;
    margin-left: 48px;
    .ant-comment-content-author-name {
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      color: #7b7893;
    }
    .ant-comment-content-author-time {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #7b7893;
    }
  }
  p {
    img {
      width: 20px;
      height: 20px;
    }
  }
  .source {
    img {
      width: 20px;
      height: 20px;
    }
  }
  .mention {
    pointer-events: none;
  }
  .emoji {
    width: 20px;
    height: 20px;
  }
  .poll {
    display: none;
  }
  .meta {
    display: none;
  }
  ${({ $removePadding }) => $removePadding && ".ant-comment-inner { padding: 0; }"}
`;

const StyledTitle = styled("h2")`
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  color: #191540;
  margin-top: 4px;
  display: flex;
  align-items: center;
`;

const AuthorLink = styled("span")`
  ${LinkStyles}
`;

const Divider = styled("div")<{ $marginTop?: string; $marginBottom?: string }>`
  width: 100%;
  border-top: 1px solid #f0eff8;
  margin-bottom: ${({ $marginBottom }) => $marginBottom || "32px"};
  margin-top: ${({ $marginTop }) => $marginTop || "60px"};
  ${media.lessThan("991px")`
    margin-top: 24px;
    margin-bottom: 24px;
  `}
`;

const StatsValue = styled("span")`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #7b7893;
  margin-left: 10px;
`;

const StatsWrapper = styled("div")`
  display: flex;
  align-items: center;
  margin-right: 20px;
`;

const AllStatsWrapper = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
`;

const SuggestedDiscussionsTitle = styled("h3")`
  font-weight: 200;
  font-size: 20px;
  line-height: 28px;
  color: #191540;
  margin-bottom: 20px;
  ${media.lessThan("991px")`
    margin-top: 60px;
  `}
`;

const ScrollLine = styled("div")`
  height: 232px;
  width: 1px;
  background-color: #f0eff8;
  position: relative;
  display: flex;
  justify-content: center;
  margin: 16px 0;
`;

const ScrollWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: sticky;
  top: 0;
  margin-bottom: 44px;
`;

const CurrentScroll = styled("span")<{ $topPosition?: number }>`
  height: 64px;
  width: 6px;
  background-color: #9e97f3;
  position: absolute;
  top: ${({ $topPosition }) => $topPosition}px;
`;

const ScrollDate = styled("span")`
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  color: #7b7893;
`;

const IconsStatsGroupWrapper = styled("div")`
  display: flex;
`;

const ReadOnlyWrapper = styled("div")<{ $column?: boolean }>`
  display: flex;
  align-items: center;
  ${({ $column }) => $column && "margin-top: 16px;"}
  ${({ $column }) => $column && "margin-bottom: -12px;"}
`;

const HeaderInfoWrapper = styled("div")<{ $column?: boolean }>`
  display: flex;
  align-items: ${({ $column }) => ($column ? "start" : "center")};
  justify-content: ${({ $column }) => ($column ? "center" : "space-between")};
  flex-direction: ${({ $column }) => ($column ? "column" : "row")};
`;

const ReadOnlyText = styled("span")`
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  color: #7b7893;
  margin-left: 4px;
`;

const PopoverContainer = styled("div")`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  max-width: 212px;
`;

const PopoverTitle = styled("span")`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #191540;
  margin: 8px 0 16px;
`;

const PopoverParagraph = styled("p")`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #7b7893;
`;

const RepliesText = styled("span")`
  font-weight: 200;
  font-size: 20px;
  line-height: 28px;
  color: #191540;
  margin-top: 48px;
  margin-bottom: 16px;
  display: block;
`;

const FullDiscussionLink = styled(Link)`
  color: ${COLORS.primary.accent};
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  display: block;
  margin 16px auto 28px;
`;

const DrawerBottomSection = styled("div")`
  position: fixed;
  bottom: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  width: 100%;
  max-width: 674px;
  margin-left: -36px;
`;

const ArrowLeftIcon = styled(ArrowRightIcon)`
  transform: rotate(180deg);
`;

const DrawerHeaderSection = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 12px;
  border-bottom: 1px solid #f0eff8;
`;

const AllDiscussionsLink = styled(Link)`
  color: ${COLORS.primary.accent};
  font-size: 14px;
  font-weight: 400;
  line-height: 28px;
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 8px;
`;

const DiscourseLink = styled("a")`
  color: ${COLORS.primary.grayDarkLightest};
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  display: flex;
  align-items: center;
  gap: 4px;
  :hover {
    color: ${COLORS.primary.accent};
  }
`;

const ShareDiv = styled("div")`
  cursor: pointer;
  color: ${COLORS.primary.grayDarkLightest};
  display: flex;
  :hover {
    color: ${COLORS.primary.accent};
  }
`;

const FlexDiv = styled("div")`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const DrawerRow = styled(Row)`
  margin: 0 24px !important;
  ${media.lessThan("991px")`
    margin: 0 12px !important;
  `}
`;

const PopoverContent = ({ url }: { url: string }) => {
  return (
    <PopoverContainer>
      <SvgGlassesIcon width={20} height={8} />
      <PopoverTitle>Read Only</PopoverTitle>
      <PopoverParagraph>
        This discussion is read-only. To participate, comment or like,{" "}
        <a rel="noopener noreferrer" target="_blank" href={url}>
          visit the protocol’s Discourse page.
        </a>
      </PopoverParagraph>
    </PopoverContainer>
  );
};

const DiscourseAvatar = ({ src, id, protocolInfo }: { src: string; id: number; protocolInfo: ProtocolDescription }) => {
  const imageSrc = src.replace("{size}", "90");
  if (imageSrc.includes("discourse-cdn")) {
    return <img src={imageSrc} key={id} />;
  }
  return <img src={`${protocolInfo?.discourseForum?.url}${imageSrc}`} key={id} />;
};

const PostWrapper = ({
  post,
  index,
  topicData,
  areResultsExpanded,
  protocolInfo,
  isMobile,
  length,
}: {
  post: DiscoursePost;
  index: number;
  topicData?: DiscourseTopic;
  areResultsExpanded: boolean;
  protocolInfo: ProtocolDescription;
  isMobile: boolean;
  length: number;
}) => {
  const likesAmount = post.actions_summary?.find((action: any) => action.id === 2)?.count;

  return (
    <div>
      {index === 1 && <RepliesText>Replies</RepliesText>}
      <ExpandCollapseRows.Row index={index} cutoffIndex={4} isExpanded={areResultsExpanded}>
        {parse(post.cooked).toString() ? (
          <>
            <TopicPost
              author={
                <AuthorWrapper>
                  <PostedByText style={{ marginRight: "4px" }}>Posted by</PostedByText>
                  <AuthorLink>{post.display_username}</AuthorLink>
                </AuthorWrapper>
              }
              avatar={<DiscourseAvatar protocolInfo={protocolInfo} src={post.avatar_template} id={post.user_id} />}
              content={parse(post.cooked)}
              datetime={
                <span>
                  {moment(post.created_at).format("D MMMM YYYY hh:mm")}{" "}
                  {post.version && post.version !== 1 && `· ${post.version - 1} edits`}
                </span>
              }
            />
            <AllStatsWrapper>
              <IconsStatsGroupWrapper>
                <Popover
                  placement="topLeft"
                  trigger="hover"
                  content={<PopoverContent url={`${protocolInfo?.discourseForum?.url}/t/${topicData?.id}`} />}
                >
                  <StatsWrapper>
                    <HeartOutlinedIcon width={20} height={18} color="#C4C4C4" />
                    <StatsValue>{likesAmount || 0}</StatsValue>
                  </StatsWrapper>
                </Popover>
                {index === 0 && (
                  <Popover
                    placement="topLeft"
                    trigger="hover"
                    content={<PopoverContent url={`${protocolInfo?.discourseForum?.url}/t/${topicData?.id}`} />}
                  >
                    <StatsWrapper>
                      <CommunicationIcon width={24} height={24} color="#C4C4C4" />
                      <StatsValue>{(topicData?.posts_count || 0) - 1}</StatsValue>
                    </StatsWrapper>
                  </Popover>
                )}
              </IconsStatsGroupWrapper>
              {index === 0 && (
                <IconsStatsGroupWrapper>
                  <Popover
                    placement="topLeft"
                    trigger="hover"
                    content={<PopoverContent url={`${protocolInfo?.discourseForum?.url}/t/${topicData?.id}`} />}
                  >
                    <StatsWrapper>
                      <EyeIcon width={24} height={24} color="#C4C4C4" />
                      <StatsValue>{topicData?.views || 0}</StatsValue>
                    </StatsWrapper>
                  </Popover>
                  <Popover
                    placement="topLeft"
                    trigger="hover"
                    content={<PopoverContent url={`${protocolInfo?.discourseForum?.url}/t/${topicData?.id}`} />}
                  >
                    <StatsWrapper>
                      <MultipleUsersIcons width={20} height={20} color="#C4C4C4" />
                      <StatsValue>{topicData?.participant_count || 0}</StatsValue>
                    </StatsWrapper>
                  </Popover>
                  <Popover
                    placement="topLeft"
                    trigger="hover"
                    content={<PopoverContent url={`${protocolInfo?.discourseForum?.url}/t/${topicData?.id}`} />}
                  >
                    <StatsWrapper>
                      <Link2Icon width={20} height={20} color="#C4C4C4" />
                      <StatsValue>{post?.link_counts?.length || 0}</StatsValue>
                    </StatsWrapper>
                  </Popover>
                </IconsStatsGroupWrapper>
              )}
            </AllStatsWrapper>
            {(index === 0 && length > 1) || isMobile ? <Divider /> : null}
            {index > 0 && index + 1 < length && !isMobile && index !== 3 ? (
              <Divider $marginBottom="24px" $marginTop="24px" />
            ) : null}
            {index === 3 && areResultsExpanded ? <Divider $marginBottom="24px" $marginTop="24px" /> : null}
          </>
        ) : null}
      </ExpandCollapseRows.Row>
    </div>
  );
};

const columns = [
  {
    title: "Topic",
    dataIndex: "title",
    key: "title",
    width: "200px",
    render: (text: React.ReactNode, data: ParsedTopic) => {
      return {
        children: (
          <div className="topicTitle">
            {data.pinned && <PinnedIcon width={16} height={16} />}
            <div>
              <h3>{text}</h3>
              {data.pinned ? (
                <p>{data.excerpt}</p>
              ) : (
                <CategoryAndTagsWrapper>
                  {data.category && (
                    <>
                      <Dot style={{ backgroundColor: `#${data.category?.color}` }} />
                      <StyledCategory>{data.category?.name}</StyledCategory>
                    </>
                  )}
                  {data.tags?.map((tag) => (
                    <StyledTag key={tag}>{tag}</StyledTag>
                  ))}
                </CategoryAndTagsWrapper>
              )}
            </div>
          </div>
        ),
      };
    },
  },
  {
    title: "Replies",
    dataIndex: "replies",
    key: "replies",
    width: "10%",
    render: (text: React.ReactNode) => {
      return {
        children: <div className="topicStats">{text}</div>,
      };
    },
  },
  {
    title: "Views",
    dataIndex: "views",
    key: "views",
    width: "10%",
    render: (text: React.ReactNode) => {
      return {
        children: <div className="topicStats">{text}</div>,
      };
    },
  },
  {
    title: "Activity",
    dataIndex: "activity",
    key: "activity",
    width: "10%",
    render: (text: React.ReactNode) => {
      return {
        children: <div className="topicStats">{text}</div>,
      };
    },
  },
];

const ProtocolTopic = () => {
  const { trackPageView, trackSelectForumTopic } = useMixpanel();
  const { protocol, id } = useParams<any>();
  const protocolInfo = useCurrentProtocol__Deprecated();
  const [topicData, setTopicData] = useState<DiscourseTopic | undefined>();
  const [category, setCategory] = useState<DiscourseCategory | undefined>();
  const [categories, setCategories] = useState<Array<DiscourseCategory>>([]);
  const [areResultsExpanded, setAreResultsExpanded] = useState(false);
  const { account } = useContext(CurrentAccountContext);
  const history = useHistory();
  const currentWidth = useCurrentWidth();
  const isMobile = currentWidth <= 991;
  const discussionContent = useRef<HTMLDivElement>(null);
  const [scrollFromTop, setScrollFromTop] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const { containerRef } = useContext(ProtocolOverviewContainerRefContext);
  const [posts, setPosts] = useState<DiscoursePost[]>([]);

  const toggleResultsExpandCollapse = useCallback(() => {
    setAreResultsExpanded(!areResultsExpanded);
  }, [areResultsExpanded]);

  useEffect(() => {
    const fetchData = async () => {
      const request = await axios.post("https://my-app.boardroom.workers.dev/", {
        apiUrl: `${protocolInfo?.discourseForum?.url}/t/${id}.json`,
      });

      const response: DiscourseTopic = await request.data;
      setTopicData(response);
      setPosts([...(response?.post_stream?.posts || [])]);

      const requestCategories = await axios.post("https://my-app.boardroom.workers.dev/", {
        apiUrl: `${protocolInfo?.discourseForum?.url}/categories.json`,
      });
      const responseCategories: DiscourseCategory[] = await requestCategories.data?.category_list?.categories;

      setCategory(responseCategories?.find((category) => category.id === response.category_id));
      setCategories(responseCategories);
    };

    if (!posts.length) fetchData();
  }, [trackPageView, setTopicData, id, protocolInfo, protocol, posts]);

  const fetchNextPage = useCallback(async () => {
    if (!(posts.length < (topicData?.posts_count || 20))) {
      return;
    } else {
      try {
        const request = await axios.post("https://my-app.boardroom.workers.dev/", {
          apiUrl: `${protocolInfo?.discourseForum?.url}/t/${id}.json?page=${pageNumber + 1}`,
        });

        const response: DiscourseTopic = await request.data;

        setPosts((currentPosts) => [...currentPosts, ...(response?.post_stream?.posts || [])]);
        setPageNumber((currentPage) => currentPage + 1);
      } catch (e) {
        console.error(e);
      } finally {
        setIsLoading(false);
      }
    }
  }, [id, pageNumber, posts?.length, topicData?.posts_count, protocolInfo?.discourseForum?.url]);

  const tableData = useMemo(
    () =>
      topicData?.suggested_topics?.map((forum) => ({
        title: forum.title || "",
        replies: forum.posts_count || 0 - 1,
        views: forum.views || 0,
        id: forum.id,
        activity: moment(forum.created_at).format("MMM YY"),
        pinned: forum.pinned || false,
        category: categories.find((category) => category.id === forum.category_id),
        tags: forum.tags || [],
      })),
    [categories, topicData?.suggested_topics],
  );

  useLayoutEffect(() => {
    const handleScroll = (e: any) => {
      if (e.srcElement.classList?.contains("ProposalContainer")) {
        const containerScrollFromTop = e.srcElement.scrollTop;
        if (containerScrollFromTop >= (discussionContent?.current?.scrollHeight || 3000)) {
          setScrollFromTop(discussionContent?.current?.scrollHeight || 3000);
        } else {
          setScrollFromTop(containerScrollFromTop);
        }
      }
    };

    window.addEventListener("scroll", handleScroll, true);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollFromTop]);

  const LoadMore = useCallback(() => {
    setIsLoading(true);
    fetchNextPage();
  }, [fetchNextPage]);

  return (
    <CardsContainer>
      <TrackPageView name="Forum Topic" protocol={protocolInfo?.cname} />
      <Row justify="start" gutter={[48, 48]} style={{ marginLeft: "0", marginRight: "0" }}>
        <Col lg={{ span: 22, push: 0 }} md={{ span: 24, push: 0 }} sm={{ span: 24, push: 0 }}>
          <ContentWrapper>
            <Row gutter={[12, { xs: 12, sm: 12, md: 12 }]}>
              <Col span={24}>
                <ProtocolInnerTabNav />
              </Col>
              <Col lg={{ span: 16, push: 0 }} md={{ span: 24, push: 0 }} sm={{ span: 24, push: 0 }}>
                <Row gutter={[24, { xs: 12, sm: 12, md: 24 }]} justify="space-around">
                  <Col span={24}>
                    <HeaderInfoWrapper $column={(topicData?.tags?.length || 0) > 1}>
                      <CategoryAndTagsWrapper $column={(topicData?.tags?.length || 0) > 1}>
                        {category && (
                          <>
                            <Dot style={{ backgroundColor: `#${category?.color}` }} />
                            <StyledCategory>{category.name}</StyledCategory>
                          </>
                        )}
                        {topicData?.tags?.map((tag) => (
                          <StyledTag key={tag}>{tag}</StyledTag>
                        ))}
                      </CategoryAndTagsWrapper>
                      {topicData?.title && (
                        <ReadOnlyWrapper $column={(topicData?.tags?.length || 0) > 1}>
                          <SvgGlassesIcon color="#7B7893" width={30} height={12} />
                          <ReadOnlyText>Read-Only</ReadOnlyText>
                        </ReadOnlyWrapper>
                      )}
                    </HeaderInfoWrapper>
                  </Col>
                  <Col span={24}>
                    <StyledTitle>
                      {topicData?.title}{" "}
                      {topicData?.title && (
                        <a
                          href={`${protocolInfo?.discourseForum?.url}/t/${topicData?.id}`}
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          <ExternalLinkIcon width={16} height={16} />
                        </a>
                      )}
                    </StyledTitle>
                  </Col>
                  <Col span={24} ref={discussionContent}>
                    {!Object.keys(topicData || {}).length ? null : (
                      <ExpandCollapseRows
                        seeMoreAsText
                        addDivider={!isMobile}
                        isExpanded={areResultsExpanded}
                        totalRows={topicData?.posts_count || 0}
                        onToggle={toggleResultsExpandCollapse}
                      >
                        <InfiniteScroll
                          getScrollParent={() => containerRef?.current || null}
                          loadMore={LoadMore}
                          hasMore={!isLoading && posts?.length < (topicData?.posts_count || 20)}
                          initialLoad={false}
                          threshold={50}
                          useWindow={false}
                        >
                          {posts
                            ?.filter((post) => post.cooked)
                            .map((post: DiscoursePost, index: number) => (
                              <PostWrapper
                                protocolInfo={protocolInfo}
                                isMobile={isMobile}
                                post={post}
                                key={index}
                                index={index}
                                topicData={topicData}
                                areResultsExpanded={areResultsExpanded}
                                length={posts?.filter((post) => post.cooked)?.length}
                              />
                            ))}
                        </InfiniteScroll>
                      </ExpandCollapseRows>
                    )}
                  </Col>
                  {!!tableData?.length && (
                    <Col span={24}>
                      <SuggestedDiscussionsTitle>Suggested Discussions</SuggestedDiscussionsTitle>
                      <TopicsTable>
                        <Table
                          onRow={(topic: ParsedTopic) => {
                            return {
                              onClick: () => {
                                trackSelectForumTopic({
                                  protocol: protocolInfo?.cname || "",
                                  topicId: String(topic.id),
                                  userId: `${account}`,
                                });
                                history.push(`/${protocol}/forum/${topic.id}`);
                              },
                            };
                          }}
                          rowIsLink={true}
                          pagination={false}
                          columns={columns}
                          dataSource={tableData}
                          inACardContainer={true}
                          rowKey={(topic: ParsedTopic) => String(topic.id)}
                          backgroundColor="#fafafa"
                        />
                      </TopicsTable>
                    </Col>
                  )}
                </Row>
              </Col>
              <Col
                style={{ marginLeft: currentWidth >= 767 ? "28px" : "" }}
                md={{ span: 6, push: 0 }}
                sm={{ span: 24, push: 0 }}
              >
                {currentWidth >= 991 && (
                  <ScrollWrapper>
                    <ScrollDate>{moment(topicData?.created_at).format("MMM YYYY")}</ScrollDate>
                    <ScrollLine>
                      <CurrentScroll
                        $topPosition={scrollFromTop / ((discussionContent?.current?.scrollHeight || 0) / 168)}
                      />
                    </ScrollLine>
                    <ScrollDate>{moment(topicData?.last_posted_at).format("MMM YYYY")}</ScrollDate>
                  </ScrollWrapper>
                )}
              </Col>
            </Row>
          </ContentWrapper>
        </Col>
      </Row>
    </CardsContainer>
  );
};

export const ProtocolTopicDrawer = ({ topicData }: { topicData: DiscourseTopicsData }) => {
  const { discourseCategories } = useDiscourseCategories({ protocol: topicData?.protocol });
  const {
    discourseTopicPosts: posts,
    hasNextPage,
    fetchNextPage,
  } = useDiscourseTopicPosts({
    topicId: topicData?.id,
    protocol: topicData?.protocol,
  });
  const selectedCategory = discourseCategories?.find(
    (category) => Number(category.id) === Number(topicData?.categoryId),
  );
  const protocolInfo = protocols[topicData?.protocol];
  const parsedTags =
    topicData?.tags
      ?.replace("{", "")
      .replace("}", "")
      .split(",")
      .map((s) => s.replace(/"/g, "").replace(/\[\]/g, ""))
      .filter(Boolean) || [];

  return (
    <DrawerRow gutter={[24, { xs: 12, sm: 12, md: 24 }]} justify="space-around">
      <Col span={24}>
        <DrawerHeaderSection>
          <AllDiscussionsLink to={`${protocolInfo?.path}/discussions`} target="_blank" rel="noopener">
            <ArrowLeftIcon /> <span>All Discussions</span>
          </AllDiscussionsLink>
          <FlexDiv>
            <DiscourseLink
              href={`${protocolInfo?.discourseForum?.url}/t/${topicData?.id}`}
              target="_blank"
              rel="noopener"
            >
              <CommunicationBoldIcon width={20} height={20} />
              <span>Discourse</span>
            </DiscourseLink>
            <TwitterShareButton
              url={`https://boardroom.io/${protocolInfo?.path}/discussions/${topicData?.id}`}
              via={"boardroom_info"}
              title={`View this discussion from the ${protocolInfo?.name} project:`}
            >
              <ShareDiv>
                <ThinShareIcon width={20} height={20} />
              </ShareDiv>
            </TwitterShareButton>
          </FlexDiv>
        </DrawerHeaderSection>
        <HeaderInfoWrapper style={{ marginTop: "28px" }}>
          <CategoryAndTagsWrapper>
            {selectedCategory && (
              <>
                <Dot style={{ backgroundColor: `#${selectedCategory?.color}` }} />
                <StyledCategory>{selectedCategory?.name}</StyledCategory>
              </>
            )}
            {parsedTags?.map((tag) => (
              <StyledTag key={tag}>{tag}</StyledTag>
            ))}
          </CategoryAndTagsWrapper>
          {topicData?.title && (
            <ReadOnlyWrapper>
              <SvgGlassesIcon color="#7B7893" width={30} height={12} />
              <ReadOnlyText>Read-Only</ReadOnlyText>
            </ReadOnlyWrapper>
          )}
        </HeaderInfoWrapper>
      </Col>
      <Col span={24}>
        <StyledTitle style={{ marginTop: "-12px" }}>
          {topicData?.title}{" "}
          {topicData?.title && (
            <a
              href={`${protocolInfo?.discourseForum?.url}/t/${topicData?.id}`}
              rel="noopener noreferrer"
              target="_blank"
            >
              <ExternalLinkIcon width={16} height={16} />
            </a>
          )}
        </StyledTitle>
      </Col>
      <Col span={24}>
        {!Object.keys(topicData || {}).length ? null : (
          <>
            {posts?.map((post: DiscourseTopicPostsData, index: number) => (
              <>
                {index === 1 && <RepliesText>Replies</RepliesText>}
                <TopicPost
                  $removePadding
                  author={
                    <AuthorWrapper>
                      <PostedByText style={{ marginRight: "4px" }}>Posted by</PostedByText>
                      <AuthorLink>{post.authorUsername}</AuthorLink>
                    </AuthorWrapper>
                  }
                  avatar={
                    <DiscourseAvatar protocolInfo={protocolInfo} src={post.authorAvatar} id={Number(post.authorId)} />
                  }
                  content={parse(post.body)}
                  datetime={<span>{moment(post.createdAt).format("D MMMM YYYY hh:mm")} </span>}
                />
                {posts?.length > 1 && !(index === posts?.length - 1) ? (
                  <Divider
                    $marginBottom={index === 0 ? undefined : "20px"}
                    $marginTop={index === 0 ? undefined : "12px"}
                  />
                ) : null}
                {hasNextPage && index === posts?.length - 1 && (
                  <div style={{ marginTop: "64px" }}>
                    <SeeMoreText onClick={() => fetchNextPage()}>See More Replies</SeeMoreText>
                  </div>
                )}
                {index === posts?.length - 1 && !hasNextPage && <div style={{ marginTop: "64px" }} />}
              </>
            ))}
            <DrawerBottomSection>
              <FullDiscussionLink to={`/${protocolInfo?.path}/discussions/${topicData?.id}`}>
                SEE FULL DISCUSSION <ArrowRightIcon />
              </FullDiscussionLink>
            </DrawerBottomSection>
          </>
        )}
      </Col>
    </DrawerRow>
  );
};

export default React.memo(ProtocolTopic);
