import * as React from "react";

const SvgCheckIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8.44 7.26" width="1em" height="1em" {...props}>
    <path
      d="M3.38 7.26A.75.75 0 012.85 7L.22 4.41a.75.75 0 011.06-1.06l2 2L7.09.3a.75.75 0 011.2.9L4 7a.78.78 0 01-.55.3z"
      fill="currentColor"
    />
  </svg>
);

export default SvgCheckIcon;
