import { createGlobalStyle } from "styled-components";
import { COLORS } from "../../constants/colors";
import { fontFamily } from "../../constants/typography";

export const WalletStyles = createGlobalStyle`
  .bn-onboard-custom {
    width: 37em;
    max-width: none;
    z-index: 99;
    font-family: ${fontFamily};
    .bn-onboard-modal-content-header-icon {
      display: none;
    }
    .bn-onboard-modal {
      font-family: inherit;
    }

    .bn-onboard-modal-content {
      padding: 2em;
    }
    .bn-onboard-modal-content-header-heading {
      margin-left: 0;
      text-align: left !important;
      font-weight: inherit;
    }
    .bn-onboard-icon-button {
      padding: 10px 20px;
      border: 1px solid transparent;
      > div {
        height: 30px;
        width: 30px;
        line-height: 30px;
      }

      &.bn-onboard-selected-wallet {
        background: ${COLORS.primary.accent};
        color: white;
      }
      i {
        text-decoration: none;
        color: ${COLORS.primary.accent};        
      }
      span {
        font-weight: inherit;
      }

      svg {
        width: 30px!important;
        min-width: 30px!important;
        max-width: 30px!important;
        height: auto;
      }
      &:hover {
        background: white;
        border: 1px solid ${COLORS.primary.accent};
        color: initial;        
      }
    }
    .bn-onboard-select-description {
      display: none;
    }
    .bn-onboard-select-info-container span.bn-onboard-select-wallet-info {
      color: ${COLORS.primary.accent};
      width: 100%;
    }
    .bn-onboard-custom {
      text-align: center;
    }

    .bn-onboard-wallet-check-section {
      margin-bottom: 40px;

      > div {
        flex-direction: column;
          text-align: center;

        button {
          display: block !important;
          margin-top: 1em !important;
          border: none;
          width: 100%;
          height: auto !important;
          font-size: 14px;
          color: ${COLORS.primary.accent} !important;
          border: none;
          text-align: right !important;
        }
      }
    }

    .bn-onboard-modal-content-close {
      height: 2em;
      width: 2em;
      border-radius: 50%;
    }
    && .bn-onboard-prepare-button-container {
      display: block;

      > div {
        display: none;
      }
    }

    && .bn-onboard-prepare-button {
      padding: 12px 20px;
      position: static;
      color: ${COLORS.primary.accent};
      border: 1px solid ${COLORS.primary.accent}
    }

    && .bn-onboard-prepare-button-left {
      width: 100%;
      display: block;
      border-radius: 40px;
      border: 1px solid transparent;

      &:hover {
        border: 1px solid ${COLORS.primary.accent};
      }
    }

    && .bn-onboard-prepare-button-right {
      margin-bottom: 10px;
      background: ${COLORS.primary.accent};
      color: #fff;
      width: 100%;
      display: block;
      transition: background 0.3s;

      &:hover {
        background: ${COLORS.primary.accentDark};
      }
    }

    #account-select {
      padding: 10px;
      border: 2px solid ${COLORS.primary.accent};
      border-radius: 40px;

      + button {
        border: none !important;
      }
    }

    #derivation-select {
      margin-bottom: 30px;
      width: 100%;

      button {
        padding: 10px 20px !important;
        width: 100%;
        display: block !important;
        border: 1px solid ${COLORS.primary.accent};
        text-align: center !important;
      }
    }

    .bn-onboard-prepare-description {
      text-align: left !important;
    }

    .bn-onboard-prepare-error {
      width: 100%;
    }

    .bn-onboard-select-wallet-info {
      font-size: 14px !important;
    }
  }
`;
