import { useMemo } from "react";
import { useContentByProtocol } from "./useContent";

export const useGetResourceFolders = ({
  protocol
}: {
  protocol?: string;
}) => {
  const {data} = useContentByProtocol({ protocol });
  const types = useMemo(() => {
    const types = Array.from(new Set(data?.map((content) => content.type)));
    return types;
  }
  , [data]);
  return types;
};
