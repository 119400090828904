import React, { useCallback } from "react";
import styled from "styled-components";

export interface Props {
  value: string;
  checkedList: string[];
  children?: React.ReactNode;
  addMarginTop?: boolean;
  removeMarginBottom?: boolean;
  onClick: (item: string) => void;
  disabled?: boolean;
}

const Container = styled.div<{ $removeMarginBottom?: boolean; $disabled?: boolean }>`
  margin-bottom: ${(props) => (props.$removeMarginBottom ? "0" : "12px")};
  cursor: ${(props) => (props.$disabled ? "not-allowed" : "auto")};
  display: flex;
`;

const StyledCheckbox = styled.input`
  display: none;
`;

const Label = styled.label<{ $isChecked: boolean; $addMarginTop?: boolean; $disabled?: boolean }>`
  background-color: ${(props) => (props.$isChecked ? "#4235E1" : "#F0EFF8")};
  border-radius: 50px;
  cursor: ${(props) => (props.$disabled ? "not-allowed" : "pointer")};
  display: inline-block;
  position: relative;
  transition: all ease-in-out 0.3s;
  width: 32px;
  height: 16px;
  ${(props) => props.$addMarginTop && "margin-top: 4px;"}
  :after {
    background-color: #fff;
    border-radius: 50%;
    content: " ";
    cursor: ${(props) => (props.$disabled ? "not-allowed" : "pointer")};
    display: inline-block;
    position: absolute;
    left: 2px;
    top: 2px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.75);
    transition: all ease-in-out 0.3s;
    width: 12px;
    height: 12px;
    transform: ${(props) => (props.$isChecked ? "translateX(15px)" : "0")};
  }
`;

export const ToggleSwitch = ({
  value,
  children,
  addMarginTop = false,
  removeMarginBottom = false,
  checkedList,
  onClick,
  disabled,
}: Props) => {
  const onClickFunction = useCallback(() => {
    if (!disabled) onClick(value);
  }, [disabled, onClick, value]);

  return (
    <Container onClick={onClickFunction} $disabled={disabled} $removeMarginBottom={removeMarginBottom}>
      <StyledCheckbox type="checkbox" />
      <Label $disabled={disabled} $isChecked={checkedList.includes(value)} $addMarginTop={addMarginTop}>
        {children}
      </Label>
    </Container>
  );
};
