import * as React from "react";

const SvgPaperOutlineIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M14 20H2a2 2 0 01-2-2V2a2 2 0 012-2h7c.01 0 .022.002.032.006a.15.15 0 00.03.006c.088.006.175.023.259.051l.028.009A.987.987 0 019.708.3l6 6a.987.987 0 01.2.293c.01.022.017.045.025.068l.009.026c.028.083.044.17.049.258l.007.027C16 6.982 16 6.991 16 7v11a2 2 0 01-2 2zM2 2v16h12V8H9a1 1 0 01-1-1V2H2zm8 1.414V6h2.586L10 3.414z"
      fill="currentColor"
    />
  </svg>
);

export default SvgPaperOutlineIcon;
