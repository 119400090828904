import Row from "antd/es/row";
import Col from "antd/es/col";
import React, { Suspense, useContext, useEffect, useMemo, useRef, useState, useCallback } from "react";
import { useLocation, useParams } from "react-router";
import ContentLoader from "react-content-loader";

import { BackgroundForMobile, MainContainer } from "../../components";
import { ArrowUpIcon } from "../../components/icons";
import { TrackPageView } from "../../components/TrackPageView";
import { COLORS } from "../../constants/colors";
import { BackToTopSection, BackToTopText } from "../Dashboard/styles";
import {
  Container,
  PaddingContainer,
  PageMax,
  StyledAbsoluteCol,
  StyledCol,
  StyledRowNoMargins,
  StyledStickyCol,
  StyledTeamTxt,
  TeamFeedText,
} from "./styles";
import { DelegateHub } from "../../components/DelegateHub/DelegateHub";
import { DelegatorProfileSidebar } from "../../components/DelegatorProfileSidebar/DelegatorProfileSidebar";
import { CurrentAccountContext } from "../../reducers/CurrentAccount";
import DelegateMessageBoard from "../../components/DelegateMessageBoard/DelegateMessageBoard";
import { useProtocolsInWallets } from "../../hooks/useUserProtocols";
import { protocols } from "../../constants/protocols";
import { useDelegatesFromAddress } from "../../hooks/useDelegatesFromAddress";
import { useDelegatesForProtocols } from "../../hooks/useDelegateForProtocols";
import { useHasDelegationMultipleProtocols } from "../../hooks/useHasDelegation";
import UnconnectedWalletState from "../../components/UnconnectedWalletState";
import { useOnboardWallet } from "../../hooks/useOnboardWallet";
import { DelegatorProfileSidebarSkeleton } from "../../components/DelegatorProfileSidebar/DelegatorProfileSidebarSkeleton";
import { useGetTeam } from "../../hooks/useGetTeam";
import { CurrentUserDetailsContext } from "../../reducers/CurrentUserDetails";
import { useIsSidebarExpanded } from "../../reducers/IsSidebarExpanded";
import { DelegateYourProjects } from "../../components/DelegateYourProjects/DelegateYourProjects";
import { DelegateMessageBoardSkeleton } from "../../components/DelegateMessageBoard/DelegateMessageBoardSkeleton";
import { CollapsedMainNavWidth, ExpandedMainNavWidth } from "../../constants/styles";

interface Params {
  protocol: string;
  identifier: string;
}

const Delegation = () => {
  const { protocol, identifier } = useParams<Params>();
  const { account } = useContext(CurrentAccountContext);
  const [showButton, setShowButton] = useState(false);
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const teamId = query.get("bundle");
  const teamWallet = query.get("bundleWallet");
  const teamDetails = useGetTeam(teamId || "");
  const { userDetails } = useContext(CurrentUserDetailsContext);
  const orgMemberships = userDetails?.orgMemberships;
  const { isSidebarExpanded } = useIsSidebarExpanded();

  const teamWallets = teamDetails?.orgWallets;
  const { delegationsFromAddress } = useDelegatesFromAddress({ address: teamWallet || account });
  const { delegateFor, delegateForNoPitches } = useDelegatesForProtocols({ address: teamWallet || account });
  const wallet = useOnboardWallet();
  const delegateToSelf = useMemo(
    () =>
      delegationsFromAddress
        ?.filter(
          (delegate) =>
            delegate.address?.toLowerCase() === delegate.addressDelegatedTo?.toLowerCase() &&
            protocols[delegate.protocol],
        )
        .map((delegate) => delegate.protocol),
    [delegationsFromAddress],
  );
  const delegateToOthers = useMemo(
    () =>
      Array.from(
        new Set(
          delegationsFromAddress
            ?.filter((delegate) => delegate.address?.toLowerCase() !== delegate.addressDelegatedTo?.toLowerCase())
            .map((delegate) => delegate.protocol),
        ),
      ),
    [delegationsFromAddress],
  );
  const protocolsInWallet = useProtocolsInWallets({ addresses: teamWallet ? [teamWallet] : [account] });
  const delegationsFromAddressAsString = useMemo(
    () => delegationsFromAddress.map((delegate) => delegate.protocol),
    [delegationsFromAddress],
  );
  const hasDelegationProtocols = useHasDelegationMultipleProtocols(protocolsInWallet);
  const undelegatedProtocolsInWallet = useMemo(
    () =>
      Array.from(
        new Set(
          protocolsInWallet.filter(
            (protocol) =>
              !delegationsFromAddressAsString.includes(protocol) &&
              !delegateForNoPitches.includes(protocol) &&
              hasDelegationProtocols?.includes(protocol),
          ),
        ),
      ),
    [delegationsFromAddressAsString, hasDelegationProtocols, protocolsInWallet, delegateForNoPitches],
  );

  const [selectedProtocol, setSelectedProtocol] = useState(protocol || "your-projects");

  const [addressToUse, setAddressToUse] = useState(
    protocol === selectedProtocol
      ? identifier
      : delegateToOthers.includes(selectedProtocol)
      ? delegationsFromAddress?.find((delegation) => delegation.protocol === selectedProtocol)?.addressDelegatedTo ||
        teamWallet ||
        account
      : teamWallet || account,
  );
  const mainSection = useRef<HTMLDivElement>(null);

  const allProtocols = useMemo(
    () =>
      Array.from(new Set([...delegateFor, ...delegateToSelf, ...delegateToOthers, ...undelegatedProtocolsInWallet])),
    [delegateFor, delegateToSelf, delegateToOthers, undelegatedProtocolsInWallet],
  );

  const delegateForProtocols = useMemo(
    () =>
      Array.from(new Set([...delegateFor, ...delegateToSelf])).filter(
        (protocol) => !delegateToOthers.includes(protocol),
      ),
    [delegateFor, delegateToSelf, delegateToOthers],
  );

  useEffect(() => {
    const handleScroll = (e: any) => {
      if (e.srcElement.classList?.contains("DashboardContainer")) {
        const containerScrollFromTop = e.srcElement.scrollTop;
        if (containerScrollFromTop > 1500) {
          setShowButton(true);
        } else {
          setShowButton(false);
        }
      }
    };

    window.addEventListener("scroll", handleScroll, true);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [showButton]);

  const scrollToTop = () => {
    if (mainSection.current) {
      mainSection.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const updateAddressToUse = useCallback(
    (selectedProtocol: string) => {
      const addressDelegated = delegateToOthers.includes(selectedProtocol)
        ? delegationsFromAddress?.find((delegation) => delegation.protocol === selectedProtocol)?.addressDelegatedTo
        : "";
      const addressToSet = addressDelegated || teamWallet || account;
      setAddressToUse(addressToSet);
      return addressToSet;
    },
    [account, delegateToOthers, delegationsFromAddress, teamWallet],
  );

  useEffect(() => {
    setAddressToUse(identifier);
  }, [identifier]);

  const updateAddressToUseWithDefinedAddress = useCallback((address: string) => {
    setAddressToUse(address);
  }, []);

  const delegateToOthersWithDefinedAddress = useMemo(() => {
    const addressesMap: Map<string, string> = new Map();
    delegateToOthers.map((protocol) => {
      const address = delegationsFromAddress?.find(
        (delegation) => delegation.protocol === protocol,
      )?.addressDelegatedTo;
      if (address) {
        addressesMap.set(protocol, address);
      }
    });
    return addressesMap;
  }, [delegateToOthers, delegationsFromAddress]);

  const mainContentProps = useMemo(() => {
    const isTeamView = !!teamWallets && !!account;
    const styles = {
      left: !isTeamView ? "0px" : "170px",
      borderRight: "1px solid #F0EFF8",
    };

    const colPropsLg = {
      span: 14,
      offset: isTeamView ? 0 : 2,
    };

    return {
      styles,
      colPropsLg,
    };
  }, [teamWallets, account]);

  return (
    <>
      {teamWallets && account ? (
        <StyledTeamTxt>
          {teamDetails.orgName} <TeamFeedText>Feed</TeamFeedText>{" "}
        </StyledTeamTxt>
      ) : (
        <></>
      )}
      <TrackPageView name={"Delegation"} />
      {account || !!(protocol && identifier) ? (
        <MainContainer $isDashboard>
          <BackgroundForMobile />
          <Container ref={mainSection}>
            <PaddingContainer>
              <PageMax>
                <StyledRowNoMargins
                  $isTeamView={!!teamWallets && !!account}
                  justify="start"
                  gutter={[48, 48]}
                  style={{ marginLeft: "0", marginRight: "0" }}
                >
                  {!!teamWallets && !!account && (
                    <StyledAbsoluteCol
                      xs={24}
                      lg={5}
                      style={{
                        background:
                          allProtocols?.length ||
                          !!(protocol && identifier) ||
                          (!!teamId && (teamDetails?.orgWallets?.length || 0) > 1)
                            ? "#f6f6f6"
                            : "#fafafa",
                        paddingLeft: "0",
                        paddingRight: "0",
                      }}
                      $left={isSidebarExpanded ? ExpandedMainNavWidth : CollapsedMainNavWidth}
                      $isTeamView={!!teamWallets && !!account}
                    >
                      <DelegateHub
                        selectedProtocol={selectedProtocol}
                        setSelectedProtocol={setSelectedProtocol}
                        protocols={allProtocols}
                        showPostButton={!!delegateFor?.length}
                        delegateForProtocols={delegateForProtocols}
                        updateAddressToUse={updateAddressToUse}
                        addressBeingUsed={addressToUse}
                        delegatedToOthersProtocols={delegateToOthers}
                        delegatedToYouProtocols={delegateForProtocols}
                        undelegatedProtocols={undelegatedProtocolsInWallet}
                      />
                    </StyledAbsoluteCol>
                  )}
                  {allProtocols?.length || !!(protocol && identifier && protocol !== "your-projects") ? (
                    selectedProtocol && selectedProtocol !== "your-projects" ? (
                      <>
                        <StyledCol style={mainContentProps.styles} xs={24} lg={mainContentProps.colPropsLg}>
                          <Suspense fallback={<DelegateMessageBoardSkeleton />}>
                            <DelegateMessageBoard
                              undelegated={
                                protocol && protocol === selectedProtocol
                                  ? false
                                  : undelegatedProtocolsInWallet.includes(selectedProtocol)
                              }
                              isShare={!!(protocol && protocol === selectedProtocol)}
                              isDelegator={
                                teamWallet && !!orgMemberships && orgMemberships === teamId
                                  ? addressToUse?.toLowerCase() !== teamWallet?.toLowerCase()
                                  : addressToUse?.toLowerCase() !== account?.toLowerCase()
                              }
                              protocol={selectedProtocol}
                              address={addressToUse}
                              updateAddressToUseWithDefinedAddress={updateAddressToUseWithDefinedAddress}
                            />
                          </Suspense>
                        </StyledCol>
                        <StyledStickyCol
                          style={{
                            height: "calc(100vh - 88px)",
                            paddingBottom: teamId ? "40px" : "0",
                          }}
                          xs={24}
                          lg={{ span: 8, offset: !!teamWallets && !!account ? 2 : 0 }}
                        >
                          <Suspense fallback={<DelegatorProfileSidebarSkeleton />}>
                            <DelegatorProfileSidebar
                              undelegated={
                                protocol && protocol === selectedProtocol
                                  ? false
                                  : undelegatedProtocolsInWallet.includes(selectedProtocol)
                              }
                              isDelegator={
                                teamWallet && !!orgMemberships && orgMemberships === teamId
                                  ? addressToUse?.toLowerCase() !== teamWallet?.toLowerCase()
                                  : addressToUse?.toLowerCase() !== account?.toLowerCase()
                              }
                              address={addressToUse}
                              protocolSelected={selectedProtocol}
                              updateAddressToUseWithDefinedAddress={updateAddressToUseWithDefinedAddress}
                            />
                          </Suspense>
                        </StyledStickyCol>
                      </>
                    ) : selectedProtocol === "your-projects" ? (
                      <StyledCol style={mainContentProps.styles} xs={24} lg={mainContentProps.colPropsLg}>
                        <DelegateYourProjects
                          undelegatedProjects={undelegatedProtocolsInWallet}
                          delegatedToSelfProjects={delegateForProtocols}
                          delegatedProjects={delegateToOthersWithDefinedAddress}
                          address={teamWallet || account}
                          setSelectedProtocol={setSelectedProtocol}
                          updateAddressToUse={updateAddressToUse}
                          hasBanner={!!(teamWallets && account)}
                        />
                      </StyledCol>
                    ) : (
                      <></>
                    )
                  ) : (
                    <StyledStickyCol
                      style={{
                        paddingTop: "48px",
                        borderLeft: "1px solid #f0eff8",
                        zIndex: 1,
                        background: "#fafafa",
                      }}
                      xs={24}
                      lg={16}
                    >
                      <UnconnectedWalletState
                        handleClick={wallet?.openWalletModal}
                        title="No actions available for this wallet"
                        subTitle={<>Please select another wallet.</>}
                        showButton={false}
                      />
                    </StyledStickyCol>
                  )}

                  {showButton && (
                    <BackToTopSection onClick={scrollToTop}>
                      <ArrowUpIcon color={COLORS.primary.accent} width={48} height={48} />
                      <BackToTopText>Back to Top</BackToTopText>
                    </BackToTopSection>
                  )}
                </StyledRowNoMargins>
              </PageMax>
            </PaddingContainer>
          </Container>
        </MainContainer>
      ) : (
        <Row style={{ marginLeft: "0", marginRight: "0" }} justify="start" gutter={[48, 48]}>
          <Col sm={24} md={22} xl={16}>
            <Suspense
              fallback={
                <ContentLoader
                  speed={2}
                  width="100%"
                  height={450}
                  backgroundColor={COLORS.primary.grayLight}
                  foregroundColor={COLORS.primary.grayLighter}
                >
                  <circle cx="30" cy="38" r="20" />
                  <rect x="60" y="23" rx="5" ry="5" width="50%" height="12" />
                  <rect x="60" y="43" rx="5" ry="5" width="70%" height="12" />

                  <circle cx="30" cy="114" r="20" />
                  <rect x="60" y="99" rx="5" ry="5" width="50%" height="12" />
                  <rect x="60" y="119" rx="5" ry="5" width="70%" height="12" />

                  <circle cx="30" cy="190" r="20" />
                  <rect x="60" y="175" rx="5" ry="5" width="50%" height="12" />
                  <rect x="60" y="195" rx="5" ry="5" width="70%" height="12" />

                  <circle cx="30" cy="266" r="20" />
                  <rect x="60" y="251" rx="5" ry="5" width="50%" height="12" />
                  <rect x="60" y="271" rx="5" ry="5" width="70%" height="12" />

                  <circle cx="30" cy="342" r="20" />
                  <rect x="60" y="327" rx="5" ry="5" width="50%" height="12" />
                  <rect x="60" y="347" rx="5" ry="5" width="70%" height="12" />
                </ContentLoader>
              }
            >
              <UnconnectedWalletState
                handleClick={wallet?.openWalletModal}
                title="A Missed Connection"
                subTitle={
                  <>
                    Want a more personalized experience? <br /> Connect that wallet to see more.
                  </>
                }
              />
            </Suspense>
          </Col>
        </Row>
      )}
    </>
  );
};

export default React.memo(Delegation);
