import React, { Suspense, useContext, useState, useEffect, useMemo, useCallback, useRef } from "react";
import Drawer from "antd/es/drawer";
import Input from "antd/es/input";
import Select from "antd/es/select";
import ContentLoader from "react-content-loader";
import { useParams, useLocation } from "react-router-dom";
import styled from "styled-components";
import { useCurrentWidth } from "react-socks";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import { Button } from "../../Button";
import { COLORS } from "../../../constants/colors";
import { CurrentAccountContext } from "../../../reducers/CurrentAccount";
import { Loader } from "../../Loader";
import ProposalComment from "./ProposalComment";
import ProfileAvatar from "../../VoterProfile/ProfileAvatar";
import { useUserDetails } from "../../../hooks/useUserDetails";
import { NoteIcon, WalletIcon } from "../../icons";
import { useProposal } from "../../../hooks/useProposal";
import media from "../../../media-query";
import { useGetComments } from "../../../hooks/useGetComments";
import { useSiwe } from "../../../hooks/useSiwe";
import { useOnboardWallet } from "../../../hooks/useOnboardWallet";
import { useGetEnsAvatar } from "../../../hooks/useEns";
import { editComment, SubmitComment, removeComment } from "./utils";
import { NotesContext } from "../../../reducers/Comments";
import { getUuid } from "../../../utils/getUuid";
import { useMixpanel } from "../../../hooks";

const StyledSubmitButton = styled(Button)`
  && {
    color: white;
    cursor: pointer;
    text-align: center;
    font-weight: normal;
    font-size: 16px;
    background: #4235e1;
    :hover {
      background: #4235e1;
    }
    border-radius: 8px;
    border: 0;
    position: relative;
    display: flex;
    justify-content: center;
    padding: 12px 32px;
    height: auto;
  }
  :disabled {
    cursor: not-allowed;
    background: #9e97f3 !important;
    color: #fff;
    :hover {
      color: #fff;
    }
  }
  display: flex;
  padding: 12px 16px;
  span {
    margin: auto;
  }
`;

const CommentsWrapper = styled.div`
  margin: 20px 0 64px;
`;

const StyledMDEditor = styled(ReactQuill)`
  margin-bottom: 40px;
  .ql-toolbar {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  .ql-container {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  border-radius: 8px;
  border: 1px solid transparent;
  &:hover,
  &:focus,
  &:active {
    border: 1px solid ${COLORS.primary.accent};
  }
`;

const EditText = styled.span`
  font-size: 14px;
  color: ${COLORS.secondary.pinkDark};
  cursor: pointer;
  line-height: 22px;
  display: flex;
  justify-content: end;
`;

const OpenTrigger = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s;
  color: #9e97f3;
  :hover {
    color: ${COLORS.primary.accent};
  }
  :hover {
    .noteIcon > path {
      fill: ${COLORS.primary.accent};
    }
  }
`;

const StyledCommentInput = styled(Input)<{ $removeMarginRight?: boolean }>`
  && {
    margin-left: 4px;
    margin-right: ${({ $removeMarginRight }) => ($removeMarginRight ? "0px" : "20px")};
    border: 1px solid #f0eff8;
    box-shadow: 0px 2px 6px rgba(7, 3, 40, 0.05);
    border-radius: 6px;
    height: 40px;
  }
  &:hover {
    border: 1px solid ${COLORS.primary.accent};
  }
`;

const TotalNumberOfComments = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: ${COLORS.primary.grayDarkLightest};
  margin-left: 4px;
`;

const StyledNoteIcon = styled(NoteIcon)``;

const CancelButton = styled.button`
  background: transparent;
  color: #7b7893;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  padding: 12px 12px;
  border: none;
  display: flex;
  justify-content: end;
  align-items: center;
  margin-right: 8px;
  cursor: pointer;
`;

const EditorButtonsWrapper = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 8px;
  align-items: center;
`;

const StyledDrawer = styled(Drawer)`
  .ant-drawer-header {
    border-bottom: 0;
    padding: 24px;
    .ant-drawer-header-title {
      flex-direction: row-reverse;
      .ant-drawer-title {
        h3 {
          font-weight: 300;
          font-size: 28px;
          line-height: 30px;
          color: #191540;
          span {
            font-weight: 100;
            font-size: 28px;
            line-height: 30px;
            color: #191540;
          }
        }
      }
    }
  }
  .ant-drawer-body {
    padding: 24px 16px;
  }
  ${media.lessThan("991px")`
  .ant-drawer-body {
    padding: 8px 16px;
  }
  .ant-drawer-content {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  `};
`;

const ConnectWalletWrapper = styled.div<{ $removeMarginRight?: boolean }>`
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  border: 1px solid #f0eff8;
  box-shadow: 0px 2px 6px rgba(7, 3, 40, 0.05);
  border-radius: 6px;
  width: 100%;
  margin-right: ${($removeMarginRight) => ($removeMarginRight ? "0px" : "20px")};
  height: 40px;
  color: #9e97f3;
  background: white;
  :hover {
    color: ${COLORS.primary.accent};
  }
  ${media.lessThan("640px")`
  margin-bottom: 24px;
  `};
`;

const ConnectWalletText = styled.span`
  margin-left: 8px;
`;

const StyledSelect = styled(Select)`
  border: none;
  transition: all 0.3s;
  box-shadow: 0;
  height: auto;
  border-radius: 6px;
  width: auto;
  color: #191540;
  margin: 32px 0 0;
  &:placeholder-shown {
    font-weight: 300;
    color: ${COLORS.primary.grayDarkLightest};
  }
  &:focus,
  &:active,
  &:hover {
    box-shadow: 0;
  }
  .ant-select-selector {
    height: 100% !important;
    display: flex;
    align-items: center;
    background: transparent !important;
    border: none !important;
  }
  .ant-select-arrow {
    border-radius: 4px;
    width: 0;
    height: 0;
    color: transparent;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 10px solid ${({ disabled }) => (disabled ? COLORS.primary.accentLight : COLORS.primary.accent)};
  }
  ${media.lessThan("991px")`
    margin: 0;
  `};
`;

const Divider = styled.div`
  width: 100%;
  border-top: 1px solid ${COLORS.primary.grayLighter};
  margin-bottom: 24px;
  margin-top: 0;
`;

interface Params {
  proposalId: string;
}

interface MdEditorWithButtonsProps {
  editorContent: string;
  setEditorContent: any;
  account?: string;
  setEditingId?: any;
  editingId?: string;
  loading?: boolean;
  editComment?: any;
  submitComment?: any;
  handleClick?: () => void;
  proposalRefId?: string;
}

export const MdEditorWithButtons = ({
  editorContent,
  setEditingId,
  setEditorContent,
  account,
  editingId,
  loading,
  submitComment,
  editComment,
  handleClick,
  proposalRefId,
}: MdEditorWithButtonsProps) => {
  const reactQuillRef = useRef<null | ReactQuill>(null);
  const { trackClickPostNote } = useMixpanel();
  const handleSubmit = useCallback(() => {
    trackClickPostNote({
      proposalRefId: proposalRefId || "",
      userId: account || "",
    });
    if (editingId) {
      if (editComment) editComment(editingId);
      return;
    } else {
      if (submitComment) submitComment();
    }
  }, [account, editComment, editingId, proposalRefId, submitComment, trackClickPostNote]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      reactQuillRef?.current?.focus();
    }, 1);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <>
      <StyledMDEditor
        placeholder="Add your note here"
        ref={reactQuillRef}
        value={editorContent}
        onChange={(value) => setEditorContent(value || "")}
      />
      {account ? (
        <EditorButtonsWrapper>
          <CancelButton
            onClick={() => {
              if (setEditingId) setEditingId("");
              setEditorContent("");
            }}
          >
            Cancel
          </CancelButton>
          <StyledSubmitButton
            onClick={handleSubmit}
            disabled={!(Array.from(editorContent.matchAll(/[a-zA-Z0-9áàâãéèêíïóôõöúçñ]/g)).length > 0) || loading}
          >
            {loading && <Loader size="xsmall" />}
            <span>{loading ? "Loading" : editingId ? "Save Edit" : "Post"}</span>
          </StyledSubmitButton>
        </EditorButtonsWrapper>
      ) : (
        <>
          <ConnectWalletWrapper onClick={handleClick}>
            <WalletIcon height={20} width={20} /> <ConnectWalletText>Connect Wallet to Add Note</ConnectWalletText>
          </ConnectWalletWrapper>
        </>
      )}
    </>
  );
};

const ProposalComments = () => {
  const { account } = useContext(CurrentAccountContext);
  const [loading, setLoading] = useState(false);
  const [editingId, setEditingId] = useState("");
  const [editorContent, setEditorContent] = useState("");
  const [sortingValue, setSortingValue] = useState("newest");
  const [visible, setVisible] = useState(false);
  const { user: userDetails } = useUserDetails({ address: account, suspense: false });
  const ensAvatar = useGetEnsAvatar(account);
  const isMobile = useCurrentWidth() < 991;
  const { proposalId: refId } = useParams<Params>();
  const { proposal } = useProposal({ refId });
  const uuid = getUuid(account);
  const { trackClickConnectWalletOnNotesModal, trackClickDeleteNote, trackClickEditNote } = useMixpanel();
  const query = new URLSearchParams(useLocation().search);

  const isTeamView = query.get("bundle");

  const { comments: commentsData } = useGetComments({
    filterBy: "proposalId",
    proposalId: refId,
    author: account,
    uuid,
    includeOrgComments: !!isTeamView,
  });

  const { dispatchNotes, notes } = useContext(NotesContext);

  useSiwe({ trigger: visible });
  const wallet = useOnboardWallet();
  const proposalNotes = useMemo(() => {
    return commentsData ?? notes?.filter((comment) => comment?.proposalId === refId);
  }, [commentsData, notes, refId]);

  useEffect(() => {
    if (window.location.href.includes("comment")) {
      setVisible(true);
    }
  }, []);

  useEffect(() => {
    if (commentsData) {
      dispatchNotes({
        type: "SAVE_NOTES",
        data: commentsData,
      });
    }
  }, [commentsData, dispatchNotes]);

  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  const handleClick = useCallback(() => {
    trackClickConnectWalletOnNotesModal({
      proposalRefId: refId,
    });
    wallet?.openWalletModal();
    setVisible(false);
  }, [wallet, setVisible, trackClickConnectWalletOnNotesModal, refId]);

  const handleRemove = useCallback(
    (id = "") => {
      trackClickDeleteNote({
        userId: account,
        proposalRefId: refId,
      });
      removeComment(id, notes, setLoading, dispatchNotes);
    },
    [account, dispatchNotes, notes, refId, trackClickDeleteNote],
  );

  const handleEdit = useCallback(
    (id = "") => {
      trackClickEditNote({
        userId: account,
        proposalRefId: refId,
      });
      setEditingId(id);
      const commentIndex = notes.findIndex((comment) => comment.commentId === id);
      setEditorContent(notes[commentIndex].commentBody || "");
    },
    [account, notes, refId, trackClickEditNote],
  );

  return (
    <>
      <OpenTrigger
        onClick={() => {
          if (account || isMobile) showDrawer();
        }}
      >
        {account && !isMobile ? (
          <>
            {account && (
              <ProfileAvatar
                customImageDimensions={32}
                address={account}
                size="small"
                pfp={userDetails?.pfpUrl || ensAvatar}
              />
            )}
            <StyledCommentInput placeholder="Add your note here" />
          </>
        ) : isMobile ? (
          <>
            <ConnectWalletWrapper $removeMarginRight>
              <StyledNoteIcon height={16} width={16} />
              <TotalNumberOfComments>
                {proposalNotes?.filter((comment) => comment?.proposalId === refId).length || 0} Notes
              </TotalNumberOfComments>
            </ConnectWalletWrapper>
          </>
        ) : (
          <ConnectWalletWrapper style={{ marginRight: "20px" }} onClick={wallet?.openWalletModal}>
            <WalletIcon height={20} width={20} />
            <ConnectWalletText>Connect Wallet to Add Note</ConnectWalletText>
          </ConnectWalletWrapper>
        )}
        {!isMobile && (
          <>
            <StyledNoteIcon onClick={showDrawer} className="noteIcon" width={20} height={20} />
            <TotalNumberOfComments onClick={showDrawer}>
              {proposalNotes?.filter((comment) => comment?.proposalId === refId).length || 0}
            </TotalNumberOfComments>
          </>
        )}
      </OpenTrigger>
      {isMobile && <Divider />}
      <StyledDrawer
        height={isMobile ? "92%" : "auto"}
        width={isMobile ? "100%" : "38%"}
        title={
          <h3>
            Notes <span>{proposalNotes.length || 0}</span>
          </h3>
        }
        placement={isMobile ? "bottom" : "right"}
        onClose={onClose}
        visible={visible}
      >
        {!!editingId && <EditText>Editing comment</EditText>}

        {!isMobile && !account && (
          <ConnectWalletWrapper
            onClick={() => {
              wallet?.openWalletModal();
              setVisible(false);
            }}
          >
            <WalletIcon height={20} width={20} /> <ConnectWalletText>Connect Wallet to Add Note</ConnectWalletText>
          </ConnectWalletWrapper>
        )}
        {!isMobile && (
          <MdEditorWithButtons
            editorContent={editorContent}
            editComment={() =>
              editComment(
                editingId,
                proposalNotes,
                setLoading,
                setEditingId,
                editorContent,
                setEditorContent,
                dispatchNotes,
              )
            }
            submitComment={() =>
              SubmitComment(
                refId,
                setLoading,
                setEditingId,
                editorContent,
                setEditorContent,
                dispatchNotes,
                account,
                proposal?.protocol as string,
                !!isTeamView,
              )
            }
            loading={loading}
            editingId={editingId}
            setEditingId={setEditingId}
            setEditorContent={setEditorContent}
            account={account}
            proposalRefId={refId}
            handleClick={handleClick}
          />
        )}
        {!!proposalNotes?.length && (
          <StyledSelect
            value={sortingValue}
            onChange={(value) => setSortingValue(String(value) || "")}
            defaultValue="newest"
          >
            <option value="newest">Newest</option>
            <option value="oldest">Oldest</option>
          </StyledSelect>
        )}
        <CommentsWrapper>
          {proposalNotes
            ?.sort((commentA, commentB) =>
              sortingValue === "newest"
                ? (commentB.createdAt || 0) - (commentA.createdAt || 0)
                : (commentA.createdAt || 0) - (commentB.createdAt || 0),
            )
            .map((comment) => (
              <ProposalComment
                proposer={proposal?.proposer}
                removeComment={handleRemove}
                edit={handleEdit}
                key={comment.commentId}
                comment={comment}
              />
            ))}
        </CommentsWrapper>
      </StyledDrawer>
    </>
  );
};

export default ({ margin, addDividerBottom }: { margin?: string; addDividerBottom?: boolean }) => (
  <>
    <Divider style={{ marginBottom: margin || "" }} />
    <Suspense
      fallback={
        <ContentLoader
          speed={2}
          width="100%"
          height={100}
          backgroundColor={COLORS.primary.grayLight}
          foregroundColor={COLORS.primary.grayLighter}
        >
          <circle x="0" cx="30" cy="30" r="16" />
          <rect x="60" y="22" rx="5" ry="5" width="80%" height="16" />
        </ContentLoader>
      }
    >
      <ProposalComments />
    </Suspense>
    {addDividerBottom && <div style={{ marginTop: margin || "" }} />}
  </>
);
