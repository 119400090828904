import React, { useContext, useState, useRef, SetStateAction, useCallback, useEffect } from "react";
import Col from "antd/es/col";
import Row from "antd/es/row";
import styled from "styled-components";
import Tooltip from "antd/es/tooltip";
import { DelegationDetails } from "@boardroom/boardroom-api";

import media from "../../media-query";
import ProtocolIcon from "../../components/ProtocolIcon";
import { COLORS } from "../../constants/colors";
import { ExpandCollapseArrowThin } from "../../components/icons";
import { protocols } from "../../constants/protocols";
import { useHasDelegation } from "../../hooks/useHasDelegation";
import { formatEns, toShortAddress } from "../../utils";
import { useGetEns } from "../../hooks/useEns";
import { useUserDetails } from "../../hooks/useUserDetails";
import { CurrentAccountContext } from "../../reducers/CurrentAccount";
import DelegationModal from "../../components/Voting/DelegationModal";
import { useMixpanel } from "../../hooks";
import SvgDelegateIconWithBg from "../../components/icons/DelegateIconWithBg";
import { Pfp } from "../../components/Pfp/Pfp";

const StyledVotePowerRow = styled(Row)<{ $isExpanded?: boolean }>`
  padding: 16px 20px 20px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  &:hover {
    background: ${({ $isExpanded }) => ($isExpanded ? "#fff" : "#f9f9fc")};
    cursor: pointer;
  }
  ${media.lessThan("991px")`
     padding: 16px 8px;
  `}
`;

const StyledName = styled.span`
  font-weight: 500;
  align-items: center;
  color: ${COLORS.primary.accent};
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 12rem;
`;

const TextWrapper = styled.div`
  padding-left: 8px;
  width: 100%;
`;

const AvatarWrapper = styled.div<{ $isExpanded?: boolean }>`
  margin: ${({ $isExpanded }) => ($isExpanded ? "7px 0 0 auto" : "auto 0")};
  position: relative;
`;

const HelperText = styled.div`
  font-size: 11px;
  line-height: 16px;
  color: ${COLORS.primary.grayDarkLightest};
  position: relative;
  display: flex;
  width: 100%;
`;

const StyledDelegateIcon = styled(SvgDelegateIconWithBg)`
  position: absolute;
  right: -2px;
  top: 11px;
`;

const StyledCol = styled(Col)`
  display: flex;
`;

const StyledText = styled.div<{ fontWeight: number; color: string; fontSize: string }>`
  font-size: ${({ fontSize }) => fontSize};
  line-height: 16px;
  color: ${({ color }) => color};
  fontweight: ${({ fontWeight }) => fontWeight};
`;

const StyledProtocols = styled.div`
  text-align: right;
  margin: auto 0;
  display: flex;
`;

const StyledProtocolIcon = styled(ProtocolIcon)`
  margin-left: -4px;
  &:first-child {
    margin-left: 0;
  }
`;

const StyledSpan = styled.span`
  font-size: 12px;
  line-height: 16px;
  color: ${COLORS.primary.accent};
`;

const StyledDiv = styled.div`
  width: 100%;
  background: inherit;
  height: auto;
  margin-top: 2px;
  padding: 8px 0px;
  margin-left: 32px;
`;

const StyledDivRow = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  padding: 8px 0px;
  color: rgba(25, 21, 64, 0.4);

  &:hover {
    background: ${COLORS.primary.grayLighter};
    border-radius: 4px;
    color: ${COLORS.primary.accent};
  }
`;

const StyledArrowIcon = styled(ExpandCollapseArrowThin)<{ $isExpanded: boolean }>`
  margin: ${({ $isExpanded }) => ($isExpanded ? "8px 0 0 auto" : "8px 0 auto auto")};
  width: 16px;
  height: 16px;
  transform: rotate(${({ $isExpanded }) => ($isExpanded ? "180deg" : "0deg")});
  transition: transform 0.2s;
  color: ${COLORS.primary.grayDarkLightest};
`;

const StyledPwrCol = styled(Col)`
  text-align: right;
  margin: auto 0;
`;

interface Props {
  protocol: string;
  setNoDelegationCounter?: React.Dispatch<SetStateAction<string[]>>;
}

interface DelegateProps {
  delegate: DelegationDetails;
  isVerified?: boolean;
}

interface SelfDelegateProps {
  delegates?: DelegationDetails[];
}

interface DropdownProps extends SelfDelegateProps {
  showDropdown: boolean;
}

export const SetupDelegationRow = ({ protocol, setNoDelegationCounter }: Props) => {
  const protocolDetail = protocols[protocol];
  const hasDelegation = useHasDelegation(protocolDetail?.cname);

  useEffect(() => {
    if (!hasDelegation && setNoDelegationCounter) {
      setNoDelegationCounter((current) => [...current, protocol]);
    }
  }, [hasDelegation, protocol, setNoDelegationCounter]);

  const [visible, setVisible] = useState<boolean>(false);
  const { account } = useContext(CurrentAccountContext);
  const { trackStartSetupDelegation } = useMixpanel();

  const handleVisible = useCallback(
    (protocolName: string) => {
      setVisible(true);
      trackStartSetupDelegation({
        protocol: protocolName,
        userId: `${account}`,
      });
    },
    [account, trackStartSetupDelegation],
  );

  if (hasDelegation) {
    return (
      <>
        <StyledVotePowerRow>
          <StyledCol span={15}>
            <AvatarWrapper>
              <ProtocolIcon size="standard" protocol={protocolDetail} />
            </AvatarWrapper>
            <TextWrapper>
              <StyledText fontWeight={500} color={COLORS.primary.grayDark} fontSize="14px">
                {protocolDetail.name}
              </StyledText>
              <StyledText
                fontWeight={400}
                color={COLORS.primary.accent}
                fontSize="12px"
                onClick={() => handleVisible(protocolDetail.cname)}
              >
                Manage Delegation
              </StyledText>
            </TextWrapper>
          </StyledCol>
        </StyledVotePowerRow>
        <DelegationModal visible={visible} setVisible={setVisible} protocolName={protocolDetail.cname} />
      </>
    );
  } else return null;
};

const ProtocolDropdown = ({ delegates, showDropdown }: DropdownProps) => {
  const dropDownRef = useRef(null);
  const { account } = useContext(CurrentAccountContext);
  const { trackStartSetupDelegation } = useMixpanel();
  const [visible, setVisible] = useState<boolean>(false);
  const [protocolCname, setProtocolCname] = useState(delegates![0]?.protocol || "");

  const handleVisible = useCallback(
    (protocolName: string) => {
      setVisible(true);
      setProtocolCname(protocolName);
      trackStartSetupDelegation({
        protocol: protocolName,
        userId: `${account}`,
      });
    },
    [account, trackStartSetupDelegation, setProtocolCname, setVisible],
  );

  if (!showDropdown) return null;
  return (
    <>
      <StyledDiv ref={dropDownRef}>
        {delegates?.map((delegate, index) => {
          const protocolDetail = protocols[delegate.protocol];
          return (
            <>
              <StyledDivRow
                style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
                key={`${index}-${delegate.timestamp}`}
                onClick={() => handleVisible(protocolDetail.cname)}
              >
                <div style={{ fontSize: "16px", textTransform: "capitalize", fontWeight: 300, margin: "auto 0" }}>
                  {<StyledProtocolIcon size="xxsmall" protocol={protocolDetail} key={index} />}
                  &nbsp;
                  {delegate.protocol}
                </div>
                <span style={{ color: COLORS.primary.accent, fontSize: "12px" }}>&nbsp;Edit</span>
              </StyledDivRow>
            </>
          );
        })}
      </StyledDiv>
      <DelegationModal visible={visible} setVisible={setVisible} protocolName={protocolCname} />
    </>
  );
};

export const DelegateToSelf = ({ delegates }: SelfDelegateProps) => {
  const { account } = useContext(CurrentAccountContext);
  const { user } = useUserDetails({ address: account });
  const [showDropdown, setShowDropdown] = useState(false);

  return (
    <>
      <StyledVotePowerRow $isExpanded={showDropdown}>
        <StyledCol span={24} onClick={() => setShowDropdown(!showDropdown)}>
          <AvatarWrapper $isExpanded={showDropdown}>
            <Pfp pfpUrl={user?.pfpUrl} size="standard" address={account} dimension={24} />
            <StyledDelegateIcon />
          </AvatarWrapper>
          <TextWrapper>
            <StyledName>Delegating to Self</StyledName>
            <HelperText>
              {delegates!.length} {delegates!.length > 1 ? "Projects" : "Project"} <i>&nbsp;&#183;&nbsp;&nbsp;</i>
              <StyledProtocols>
                {delegates!.slice(0, 3).map((delegate, index) => {
                  const protocolDetail = protocols[delegate.protocol];
                  return <StyledProtocolIcon size="xxsmall" protocol={protocolDetail} key={index} />;
                })}
                {delegates!.length > 3 && <StyledSpan>+{delegates!.length - 3}</StyledSpan>}
              </StyledProtocols>
            </HelperText>
          </TextWrapper>
          <StyledArrowIcon $isExpanded={showDropdown} />
        </StyledCol>
        <ProtocolDropdown delegates={delegates} showDropdown={showDropdown} />
      </StyledVotePowerRow>
    </>
  );
};

const DelegateRow = ({ delegate, isVerified }: DelegateProps) => {
  const protocolDetail = protocols[delegate.protocol];
  const ens = useGetEns(delegate.addressDelegatedTo);
  const { user } = useUserDetails({ address: delegate.addressDelegatedTo });
  const [visible, setVisible] = useState<boolean>(false);
  const { account } = useContext(CurrentAccountContext);
  const { trackStartSetupDelegation } = useMixpanel();

  const handleVisible = useCallback(
    (protocolName: string) => {
      setVisible(true);
      trackStartSetupDelegation({
        protocol: protocolName,
        userId: `${account}`,
      });
    },
    [account, trackStartSetupDelegation],
  );
  return (
    <>
      <StyledVotePowerRow>
        <StyledCol span={20}>
          <AvatarWrapper>
            <Pfp pfpUrl={user?.pfpUrl} size="standard" address={delegate.addressDelegatedTo} dimension={24} />
            {isVerified && <StyledDelegateIcon />}
          </AvatarWrapper>
          <TextWrapper>
            <StyledName>{user?.username || formatEns(ens) || toShortAddress(delegate.addressDelegatedTo)}</StyledName>
            {protocolDetail.cname !== "makerdao" && (
              <HelperText>
                <span style={{ color: COLORS.primary.accent }} onClick={() => handleVisible(protocolDetail.cname)}>
                  Edit Delegation
                </span>
              </HelperText>
            )}
          </TextWrapper>
          <DelegationModal visible={visible} setVisible={setVisible} protocolName={protocolDetail.cname} />
        </StyledCol>
        <StyledPwrCol span={4}>
          <Tooltip title={protocolDetail.name}>
            <ProtocolIcon size="standard" protocol={protocolDetail} />
          </Tooltip>
        </StyledPwrCol>
      </StyledVotePowerRow>
    </>
  );
};
export default DelegateRow;
