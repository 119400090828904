import React, { useCallback, useContext, useEffect, useState } from "react";
import moment from "moment";
import styled from "styled-components";
import axios from "axios";

import { Paragraph } from "../Typography";
import { COLORS } from "../../constants/colors";
import { CurrentAccountContext } from "../../reducers/CurrentAccount";
import { CurrentUuidContext } from "../../reducers/CurrentUuid";
import { baseAPIUrl, BOARDROOM_API_KEY } from "../../constants/general";
import loadChargeBee from "../../utils/loadChargeBee";
import AutoRefreshModal from "../../components/common/AutoRefresh";
import { FormattedSubscriptionData } from "@boardroom/boardroom-api/dist/subscriptions";
import { useGetApiSubscriptionDetails } from "../../hooks/useGetApiMembership";

const StyledDiv = styled("div")`
  padding-top: 24px;
`;

const MembershipWrapper = styled("div")`
  background: #f0eff8;
  border-radius: 4px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 8px;
  margin-top: 16px;
`;

const MembershipTitle = styled("span")`
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #191540;
  margin-bottom: 4px;
  display: block;
`;

const MembershipRenews = styled("span")`
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #191540;
`;

const MembershipContentWrapper = styled("div")`
  flex-direction: column;
  flex: 1;
`;

const ManageButton = styled("button")`
  background: #ffffff;
  border: 1px solid #4235e1;
  border-radius: 8px;
  color: #4235e1;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  cursor: pointer;
  :disabled {
    cursor: not-allowed;
  }
`;

const StyledParagraph = styled(Paragraph)`
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: ${COLORS.primary.grayDarkLightest};
  margin-bottom: 8px;
`;

const ManageSubscription = () => {
  const { account } = useContext(CurrentAccountContext);
  const { uuid } = useContext(CurrentUuidContext);
  const [isRefreshModalOpen] = useState(false);
  const { data: apiSubscriptionDetails } = useGetApiSubscriptionDetails(account, uuid);

  const [subscriptionData, setSubscriptionDetails] = useState<FormattedSubscriptionData | null>(null);

  useEffect(() => {
    loadChargeBee();
  }, []);

  useEffect(() => {
    if (apiSubscriptionDetails) {
      setSubscriptionDetails(apiSubscriptionDetails);
    }
  }, [apiSubscriptionDetails]);

  const updateSubscriptionData = useCallback((data: FormattedSubscriptionData) => {
    if (data) {
      setSubscriptionDetails(data);
    }
  }, []);

  const updateSubscriptionDataFromChargebee = useCallback(
    async (id: string) => {
      const response = await axios.get(
        `${baseAPIUrl}getSubscriptionDetailsFromChargebee/${id}?key=${BOARDROOM_API_KEY}`,
      );
      updateSubscriptionData(response.data);
    },
    [updateSubscriptionData],
  );

  const manageSub = useCallback(async () => {
    // @ts-ignore
    const chargeBeeInstance = window.Chargebee.getInstance();

    await chargeBeeInstance.setPortalSession(async () => {
      const data = { address: account, uuid };
      return axios
        .post(`${baseAPIUrl}createManagePaymentPortalSession?key=${BOARDROOM_API_KEY}`, data)
        .then((response) => response.data);
    });

    const cbPortal = chargeBeeInstance.createChargebeePortal();

    cbPortal.open({
      close() {
        chargeBeeInstance.closeAll();
      },
      subscriptionChanged(data: any) {
        updateSubscriptionDataFromChargebee(data.subscription.id);
      },
      subscriptionCancelled: function (data: any) {
        updateSubscriptionDataFromChargebee(data.subscription.id);
      },
      subscriptionPaused: function (data: any) {
        updateSubscriptionDataFromChargebee(data.subscription.id);
      },
      subscriptionResumed: function (data: any) {
        updateSubscriptionDataFromChargebee(data.subscription.id);
      },
      scheduledPauseRemoved: function (data: any) {
        updateSubscriptionDataFromChargebee(data.subscription.id);
      },
      scheduledCancellationRemoved: function (data: any) {
        updateSubscriptionDataFromChargebee(data.subscription.id);
      },

      subscriptionReactivated: function (data: any) {
        updateSubscriptionDataFromChargebee(data.subscription.id);
      },
    });
  }, [account, updateSubscriptionDataFromChargebee, uuid]);

  const renderSubscriptionInfo = () => {
    if (subscriptionData && (subscriptionData as any).cancelled_at) {
      return (
        <MembershipRenews>
          {"Cancels On " + moment(((subscriptionData as any).cancelled_at || 0) * 1000).calendar()}
        </MembershipRenews>
      );
    }

    return (
      <MembershipRenews>
        {" "}
        {"Renews " + moment((subscriptionData?.next_billing_at || 0) * 1000).calendar()}
      </MembershipRenews>
    );
  };

  return (
    <StyledDiv>
      <AutoRefreshModal isModalOpen={isRefreshModalOpen} />
      {<StyledParagraph>Manage your account and payment methods for your Boardroom API subscription</StyledParagraph>}
      <MembershipWrapper>
        <MembershipContentWrapper>
          {<MembershipTitle>API Membership</MembershipTitle>}
          {renderSubscriptionInfo()}
        </MembershipContentWrapper>
        <ManageButton onClick={manageSub}>Manage Billing</ManageButton>
      </MembershipWrapper>
    </StyledDiv>
  );
};

export default React.memo(ManageSubscription);
