import React, { useMemo, useContext, useCallback, useRef } from "react";
import Button from "antd/es/button";
import styled, { css } from "styled-components";
import { useSelect } from "downshift";
import { useHoverDirty } from "react-use";

import {
  BookmarkFilledIcon,
  BookmarkIcon,
  ExpandCollapseArrowThin,
  SettingsAlternateIcon,
  ThinCheckIcon,
  ThreeDotMenuIcon,
} from "../icons";
import { LeanDropdownItemList } from "../Dropdown/LeanDropdownItemList";
import { getSingleSelectConfig } from "../Dropdown/config";
import { COLORS } from "../../constants/colors";
import {
  DelegateFrameWorkFilteritems,
  DelegateFrameWorkFilteritemsByValue,
  proposalsTypeByValue,
  projectsFilterItems,
  projectsFilterItemsByValue,
} from "../../constants/general";
import { CurrentAccountContext } from "../../reducers/CurrentAccount";
import { useUserDetails } from "../../hooks/useUserDetails";
import { savedProtocolsToArray } from "../../utils/savedProtocolsToArray";
import { CurrentProjectsFilterContext } from "../../reducers/CurrentProjectsFilter";
import { useMixpanel } from "../../hooks";
import mediaQuery from "../../media-query";
import { useWindowDimensions } from "../../hooks/useWindowDimensions";
import { useCurrentRefId } from "../../reducers/CurrentRefId";
import { CurrentUuidContext } from "../../reducers/CurrentUuid";
import { getUuid } from "../../utils/getUuid";
import { useGetTeam } from "../../hooks/useGetTeam";
import { useLocation } from "react-router";
import { isTeamView } from "../../utils/teamUtils";

const standAloneProtocolCname = process.env.REACT_APP_STANDALONE_PROTOCOL;

interface CalendarFilterProps {
  tab: "all" | "today" | "open" | "pending" | "closed";
  onTabClick: (tab: "all" | "today" | "open" | "pending" | "closed") => void;
  votingSystems: "allFrameworks" | "snapshot" | "onchain";
  setVotingSystemsFilter: React.Dispatch<React.SetStateAction<"allFrameworks" | "snapshot" | "onchain">>;
  margin?: string;
  savedOrVotedFilter: "saved" | "voted" | "";
  setSavedOrVotedFilter: React.Dispatch<React.SetStateAction<"saved" | "voted" | "">>;
}

const StyledWrapper = styled.div<{ $addBorderTop?: boolean; $addPaddingTop?: boolean }>`
  display: flex;
  border-bottom: 1px solid ${COLORS.primary.grayLighter};
  ${({ $addBorderTop }) => $addBorderTop && `border-top: 1px solid ${COLORS.primary.grayLighter};`}
  ${({ $addPaddingTop }) => $addPaddingTop && "padding-top: 12px;"}
  padding-bottom: 4px;
  width: 100%;
  transition: width 1s ease-out;
  ${mediaQuery.lessThan("640px")`
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
    display: none;
    }
  `}
`;

const TabWrapperCalendar = styled.div`
  margin: auto 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0 12px 0 0;
  position: relative;
  &:after {
    content: "";
    height: 20px;
    width: 1px;
    position: absolute;
    background: ${COLORS.primary.grayLighter};
    right: 0;
  }
  ${mediaQuery.lessThan("991px")`
    margin: 0;
    padding-left: 0;
    position: inherit;
    border-right: 1px solid #F0EFF8;
    &:before {
      width: 0;
    }
  `}
`;

const StyledTab = styled.div<{ active: boolean; margin?: string }>`
  position: relative;
  width: auto;
  color: #7b7893;
  cursor: pointer;
  transition: all 0.2s ease-in;
  margin: ${({ margin }) => (margin ? margin : "0 8px 0 0")};
  font-weight: 400;
  white-space: nowrap;
  opacity: 0.5;
  :hover {
    color: ${COLORS.primary.accent};
    opacity: 1;
  }
  ${({ active }) =>
    active &&
    css`
      color: #191540;
      font-weight: 500;
      opacity: 1;
      &:after {
        content: "";
        height: 2px;
        width: 100%;
        position: absolute;
        background: #191540;
        bottom: -10px;
        left: 0;
      }
    `};
`;

const DropDownWrapper = styled.div`
  margin: auto 0;
  width: 100%;
  & [ant-click-animating-without-extra-node]:after {
    animation: 0s !important;
  }
`;

const StyledButton = styled(Button)<{ $isVotingSystemsFilter?: boolean; $isSplitView?: boolean }>`
  border: none;
  display: flex;
  color: ${COLORS.primary.accent};
  box-shadow: none;
  width: 100%;
  justify-content: space-between;
  padding: 0;
  align-items: center;
  background: transparent;
  :disabled {
    background: transparent;
  }
  :disabled:hover {
    background: transparent;
  }
  :hover,
  :focus {
    background: transparent;
    color: ${COLORS.primary.accent};
  }
  :active,
  :focus {
    color: ${COLORS.primary.accent};
  }
  ${mediaQuery.lessThan("991px")`
    span {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  `}
`;

const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

const ButtonText = styled.span<{ $isDropdownOpen?: boolean }>`
  ${({ $isDropdownOpen }) => $isDropdownOpen && "color: #4235e1 !important;"}
`;

const ArrowIconContainer = styled.div<{ isOpen: boolean }>`
  margin: auto 0 auto 8px;
  transform: rotate(${({ isOpen }) => (isOpen ? "180deg" : 0)});
  transition: transform 0.2s;
`;

const Wrapper = styled.div<{ $removeDivider?: boolean; $removeMarginLeft?: boolean; $isMobile?: boolean }>`
  ${({ $removeMarginLeft }) => !$removeMarginLeft && "margin-left: auto;"}
  min-width: 144px;
  ${({ $removeDivider, $isMobile }) =>
    $removeDivider ? ($isMobile ? "padding-right: 12px;" : "padding-right: 20px;") : ""}
  padding-left: 20px;
  .dropdown-text {
    color: #191540;
  }
  :hover {
    .dropdown-text {
      color: ${COLORS.primary.accent};
    }
  }
  ${({ $removeDivider }) =>
    !$removeDivider &&
    `
    &:before {
    content: "";
    height: 20px;
    width: 1px;
    position: absolute;
    background: ${COLORS.primary.grayLighter};
    left: 0;
    top: 5px;
  }
  `}
  ${mediaQuery.greaterThan("991px")`
    position: relative;
  `}
  ${mediaQuery.lessThan("1100px")`
    padding-left: 12px;
    padding-right: 12px;
    min-width: auto;
  `}
`;

const StyledThreeDotMenuIcon = styled(ThreeDotMenuIcon)<{ $active: boolean }>`
  transform: rotate(90deg);
  opacity: ${({ $active }) => ($active ? 1 : 0.3)};
`;

const SavedOrVotedWrapper = styled.div<{ $active?: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  color: #7b7893;
  line-height: 16px;
  opacity: 0.5;
  transition: 0.3s all;
  svg {
    margin-right: 2px;
  }
  :hover {
    color: ${COLORS.primary.accent};
    opacity: 1;
  }
  :active,
  :focus {
    color: #191540;
    opacity: 1;
  }
  ${({ $active }) =>
    $active &&
    css`
      font-weight: 500;
      color: #191540;
      opacity: 1;
    `}
`;

export interface Item {
  name: string;
  value: string;
}

interface DropdownProps {
  onChange: (a: any) => void;
  items: Item[];
  currentValue: Item;
  hasCtaButton?: boolean;
  hasTeam?: boolean;
  hasCustomized?: boolean;
  addSettingsIcon?: boolean;
  disabled?: boolean;
  isProjectsFilter?: boolean;
  isVotingSystemsFilter?: boolean;
  isSignedIn?: boolean;
}

export const DropDown = ({
  items,
  currentValue,
  onChange,
  hasCtaButton,
  hasTeam,
  hasCustomized,
  addSettingsIcon,
  disabled,
  isProjectsFilter,
  isVotingSystemsFilter,
  isSignedIn,
}: DropdownProps) => {
  const { isOpen, getMenuProps, getToggleButtonProps, getItemProps } = useSelect(
    getSingleSelectConfig(items, currentValue, onChange),
  );
  const { account } = useContext(CurrentAccountContext);
  const { currentRefId } = useCurrentRefId();
  const { trackExpandProjectsFilterDropdown, trackExpandVotingSystemsFilterDropdown } = useMixpanel();

  const handleExpandClick = useCallback(() => {
    if (isProjectsFilter) {
      trackExpandProjectsFilterDropdown({
        userId: account,
      });
    } else if (isVotingSystemsFilter) {
      trackExpandVotingSystemsFilterDropdown({
        userId: account,
      });
    }
  }, [
    trackExpandVotingSystemsFilterDropdown,
    trackExpandProjectsFilterDropdown,
    account,
    isProjectsFilter,
    isVotingSystemsFilter,
  ]);

  return (
    <DropDownWrapper>
      <StyledButton
        $isVotingSystemsFilter={isVotingSystemsFilter}
        $isSplitView={!!currentRefId}
        disabled={disabled}
        {...getToggleButtonProps()}
      >
        <ButtonWrapper className="button-wrapper" onClick={handleExpandClick}>
          <ButtonText $isDropdownOpen={isOpen} className="dropdown-text">
            {currentValue?.name}{" "}
          </ButtonText>
          {addSettingsIcon && currentValue?.value === "custom" && (
            <SettingsAlternateIcon style={{ marginLeft: "4px" }} />
          )}
          <ArrowIconContainer isOpen={isOpen}>
            <ExpandCollapseArrowThin
              color={disabled ? COLORS.primary.grayDarkLightest : COLORS.primary.accent}
              width={10}
              height={8}
            />
          </ArrowIconContainer>
        </ButtonWrapper>
      </StyledButton>
      <LeanDropdownItemList
        items={items}
        isOpen={isOpen}
        selected={currentValue}
        getMenuProps={getMenuProps}
        getItemProps={getItemProps}
        hasCtaButton={hasCtaButton}
        hasTeam={hasTeam}
        hasCustomized={hasCustomized}
        isProjectsFilter={isProjectsFilter}
        isVotingSystemsFilter={isVotingSystemsFilter}
        isSignedIn={isSignedIn}
      />
    </DropDownWrapper>
  );
};

const CalendarFiltersContent = ({
  tab,
  onTabClick,
  votingSystems,
  setVotingSystemsFilter,
  margin,
  savedOrVotedFilter,
  setSavedOrVotedFilter,
}: CalendarFilterProps) => {
  const dropdownItems = [
    { value: "pending", name: "Pending" },
    { value: "closed", name: "Closed" },
  ];
  const { account } = useContext(CurrentAccountContext);
  const { projectsFilter, setProjectsFilter } = useContext(CurrentProjectsFilterContext);
  const { user } = useUserDetails({ address: account, suspense: false });
  const hasTeam = !!user?.orgMemberships;
  const teamDetails = useGetTeam(user?.orgMemberships || "", false);
  const teamSavedProtocolsArray = savedProtocolsToArray(teamDetails?.savedProtocols);
  const { width } = useWindowDimensions();
  const isMobile = width < 991;
  const protocols = savedProtocolsToArray(user?.savedProtocols);
  const {
    trackClickAllFilter,
    trackClickPendingFilter,
    trackClickClosedFilter,
    trackClickTodayFilter,
    trackClickOpenFilter,
    trackClickSavedProposalFilterOnFeed,
    trackClickVotedProposalFilterOnFeed,
  } = useMixpanel();
  const { uuid: updatedUuid } = useContext(CurrentUuidContext);
  const uuidInCookie = getUuid(account.toLowerCase());
  const uuid = updatedUuid || uuidInCookie;
  const menuRef = useRef<HTMLDivElement>(null);
  const isHovering = useHoverDirty(menuRef);
  const { pathname, search } = useLocation();
  const isOnTeamView = isTeamView(pathname, search);

  const projectsFilterValue = useMemo(
    () => ({
      value: projectsFilter,
      name: projectsFilterItemsByValue[projectsFilter],
    }),
    [projectsFilter],
  );

  const votingSystemsValue = useMemo(
    () => ({
      value: votingSystems,
      name: DelegateFrameWorkFilteritemsByValue[votingSystems],
    }),
    [votingSystems],
  );

  const handleClickAllFilter = useCallback(() => {
    trackClickAllFilter({
      userId: account,
    });
    onTabClick("all");
  }, [account, trackClickAllFilter, onTabClick]);

  const handleClickTodayFilter = useCallback(() => {
    trackClickTodayFilter({
      userId: account,
    });
    onTabClick("today");
  }, [account, trackClickTodayFilter, onTabClick]);

  const handleClickOpenFilter = useCallback(() => {
    trackClickOpenFilter({
      userId: account,
    });
    onTabClick("open");
  }, [account, trackClickOpenFilter, onTabClick]);

  const handleClickPendingOrClosedFilter = useCallback(
    (value: string) => {
      if (value === "pending") {
        trackClickPendingFilter({
          userId: account,
        });
        onTabClick("pending");
      } else if (value === "closed") {
        trackClickClosedFilter({
          userId: account,
        });
        onTabClick("closed");
      } else {
        onTabClick("all");
      }
    },
    [account, trackClickPendingFilter, onTabClick, trackClickClosedFilter],
  );

  const handleClickSavedFilter = useCallback(() => {
    if (savedOrVotedFilter === "saved") {
      setSavedOrVotedFilter("");
    } else {
      setSavedOrVotedFilter("saved");
    }
    trackClickSavedProposalFilterOnFeed({
      userId: account,
    });
  }, [savedOrVotedFilter, setSavedOrVotedFilter, trackClickSavedProposalFilterOnFeed, account]);

  const handleClickVotedFilter = useCallback(() => {
    if (savedOrVotedFilter === "voted") {
      setSavedOrVotedFilter("");
    } else {
      setSavedOrVotedFilter("voted");
    }
    trackClickVotedProposalFilterOnFeed({
      userId: account,
    });
  }, [account, savedOrVotedFilter, setSavedOrVotedFilter, trackClickVotedProposalFilterOnFeed]);

  const { getMenuProps, getItemProps } = useSelect(
    getSingleSelectConfig(dropdownItems, proposalsTypeByValue[tab], (value) =>
      handleClickPendingOrClosedFilter(value?.value || "all"),
    ),
  );

  return (
    <>
      <StyledWrapper $addBorderTop $addPaddingTop>
        <TabWrapperCalendar>
          <StyledTab onClick={handleClickAllFilter} active={tab === "all"} margin={margin}>
            All
          </StyledTab>
          <StyledTab onClick={handleClickTodayFilter} active={tab === "today"} margin={margin}>
            Today
          </StyledTab>
          <StyledTab onClick={handleClickOpenFilter} active={tab === "open"} margin={margin}>
            Open
          </StyledTab>
          <div ref={menuRef} style={{ height: "24px", marginBottom: "-10px" }}>
            <div style={{ display: "flex" }}>
              <StyledThreeDotMenuIcon $active={tab === "pending" || tab === "closed"} color="#4235E1" />
            </div>
            <LeanDropdownItemList
              items={dropdownItems}
              isOpen={isHovering}
              selected={proposalsTypeByValue[tab]}
              getMenuProps={getMenuProps}
              getItemProps={getItemProps}
              removeRight0
            />
          </div>
        </TabWrapperCalendar>
        {account && (
          <Wrapper
            style={{
              borderRight: isMobile ? "1px solid #F0EFF8" : 0,
              display: "flex",
              alignItems: "center",
              gap: "20px",
            }}
            $removeDivider
            $removeMarginLeft
            $isMobile={isMobile}
          >
            {!isOnTeamView && (
              <SavedOrVotedWrapper $active={savedOrVotedFilter === "saved"} onClick={handleClickSavedFilter}>
                {savedOrVotedFilter === "saved" ? <BookmarkFilledIcon color="#4235e1" /> : <BookmarkIcon />}{" "}
                <span>Saved</span>
              </SavedOrVotedWrapper>
            )}
            <SavedOrVotedWrapper $active={savedOrVotedFilter === "voted"} onClick={handleClickVotedFilter}>
              <ThinCheckIcon color={savedOrVotedFilter === "voted" ? "#4235e1" : ""} /> <span>Voted</span>
            </SavedOrVotedWrapper>
          </Wrapper>
        )}
        {!standAloneProtocolCname && (
          <Wrapper style={{ borderRight: isMobile ? "1px solid #F0EFF8" : 0 }} $isMobile={isMobile}>
            <DropDown
              items={account ? projectsFilterItems : projectsFilterItems.slice(2)}
              currentValue={projectsFilterValue}
              onChange={(value) => setProjectsFilter(value?.value || "all")}
              hasCtaButton
              hasTeam={hasTeam}
              hasCustomized={!!protocols.length || !!teamSavedProtocolsArray.length}
              addSettingsIcon={!!protocols.length || !!teamSavedProtocolsArray.length}
              isProjectsFilter
              isSignedIn={!!uuid}
            />
          </Wrapper>
        )}
        <Wrapper $removeMarginLeft>
          <DropDown
            items={DelegateFrameWorkFilteritems}
            currentValue={votingSystemsValue}
            onChange={(value) => setVotingSystemsFilter(value?.value || "allFrameworks")}
            isVotingSystemsFilter
          />
        </Wrapper>
      </StyledWrapper>
    </>
  );
};

export const DiscussFiltersContent = () => {
  const { account } = useContext(CurrentAccountContext);
  const { projectsFilter, setProjectsFilter } = useContext(CurrentProjectsFilterContext);
  const { user } = useUserDetails({ address: account, suspense: false });
  const hasTeam = !!user?.orgMemberships;
  const teamDetails = useGetTeam(user?.orgMemberships || "", false);
  const teamSavedProtocolsArray = savedProtocolsToArray(teamDetails?.savedProtocols);
  const { width } = useWindowDimensions();
  const isMobile = width < 991;
  const protocols = savedProtocolsToArray(user?.savedProtocols);
  const { uuid: updatedUuid } = useContext(CurrentUuidContext);
  const uuidInCookie = getUuid(account.toLowerCase());
  const uuid = updatedUuid || uuidInCookie;

  const projectsFilterValue = useMemo(
    () => ({
      value: projectsFilter,
      name: projectsFilterItemsByValue[projectsFilter],
    }),
    [projectsFilter],
  );

  return (
    <StyledWrapper style={{ paddingBottom: "0px", paddingTop: "8px" }}>
      <Wrapper style={{ borderRight: isMobile ? "1px solid #F0EFF8" : 0 }} $isMobile={isMobile}>
        <DropDown
          items={account ? projectsFilterItems : projectsFilterItems.slice(2)}
          currentValue={projectsFilterValue}
          onChange={(value) => setProjectsFilter(value?.value || "all")}
          hasCtaButton
          hasTeam={hasTeam}
          hasCustomized={!!protocols.length || !!teamSavedProtocolsArray.length}
          addSettingsIcon={!!protocols.length || !!teamSavedProtocolsArray.length}
          isProjectsFilter
          isSignedIn={!!uuid}
        />
      </Wrapper>
    </StyledWrapper>
  );
};

export const CalendarFilters = (props: CalendarFilterProps) => <CalendarFiltersContent {...props} />;
