import React from "react";
import styled from "styled-components";
import AntdAvatar from "antd/es/avatar";
import { COLORS } from "../../constants/colors";

type AvatarProps = {
  size?: "xxsmall" | "xsmall" | "small" | "standard" | "large" | "medium" | "xlarge" | "xxlarge";
  src?: React.ReactNode;
  icon?: React.ReactNode;
  children?: React.ReactNode;
  color?: string;
  backgroundColor?: string;
  style?: React.CSSProperties;
  shape?: "circle" | "square";
};

const fontSizes = {
  xxsmall: "9px",
  xsmall: "9px",
  small: "12px",
  standard: "14px",
  medium: "16px",
  large: "20px",
  xlarge: "26px",
  xxlarge: "44px",
};

export const AvatarDimensions = {
  xxsmall: 16,
  xsmall: 20,
  standard: 24,
  small: 30,
  medium: 40,
  large: 56,
  xlarge: 72,
  xxlarge: 120,
};

const getDimensions = (props: AvatarProps) => `${AvatarDimensions[props.size || "medium"]}px`;
const getSize = (props: AvatarProps) => fontSizes[props.size || "medium"];

const StyledAvatar = styled(AntdAvatar)<AvatarProps & { $backgroundColor?: string }>`
  font-size: ${getSize};
  height: ${getDimensions};
  width: ${getDimensions};
  line-height: ${getDimensions};
  background-color: ${(props) => props.$backgroundColor || COLORS.primary.accent};
  color: ${(props) => props.color || COLORS.primary.grayLightest};
  .anticon {
    font-size: ${getSize};
    line-height: ${getDimensions};
    vertical-align: inherit;
  }
  &.ant-avatar-square {
    border-radius: 4px;
  }

  > img {
    object-fit: contain;
  }
`;

function Avatar({ backgroundColor, ...props }: AvatarProps) {
  return <StyledAvatar shape={props?.shape ?? "circle"} $backgroundColor={backgroundColor} {...props} />;
}

export default Avatar;
export type { AvatarProps };
