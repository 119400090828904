import React from "react";
import styled from "styled-components";

import media from "../../media-query";

const CardsContainer = styled.div`
  padding: 0;
  display: contents;
  position: relative;
  border: 1px solid red;
  ${media.lessThan("991px")`
    padding: 10px 0px 100px 0px;
  `}
`;

export default React.memo(CardsContainer);
