import { useQuery } from "@tanstack/react-query";
import { PendingVoteResponse } from "@boardroom/boardroom-api";
import { apiClient } from "../utils/apiClient";

interface VotesByProposalProps {
  refId: string;
  address?: string;
}

interface VotesByAddressProps {
  refId?: string;
  address: string;
}

export const usePendingVotesByProposal = ({ refId, address }: VotesByProposalProps) => {
  const { data: pendingVotes } = useQuery<PendingVoteResponse, Error>(
    [`PendingVotesProtocol:${refId}:${address}`],
    () =>
      apiClient.getPendingProposalVotes(refId, {
        address,
      }),
    {
      enabled: !!refId && !!address,
      suspense: false,
    },
  );

  return pendingVotes?.data;
};

export const usePendingVotesByAddress = ({ address }: VotesByAddressProps) => {
  const { data: pendingVotes } = useQuery<PendingVoteResponse, Error>(
    [`PendingVotesAddress:${address}`],
    () => apiClient.getPendingVoterVotes(address),
    {
      enabled: !!address,
      suspense: false,
    },
  );

  return pendingVotes?.data;
};
