import React, { useReducer, createContext, useContext, useEffect } from "react";
import Cookies from "universal-cookie";
import { CurrentAccountContext } from "./CurrentAccount";

interface Props {
  children: React.ReactNode;
}

type Action = { type: "SAVE_SUBSCRIPTION"; data: boolean };

interface CurrentSubscriptionType {
  subscription: boolean;
  dispatchSubscription: React.Dispatch<any>;
}

const cookies = new Cookies();
const subscribed: boolean = cookies.get("subscribed");
const initialState = subscribed || false;

export const CurrentSubscriptionContext = createContext({} as CurrentSubscriptionType);

const reducer = (state: boolean, action: Action) => {
  switch (action.type) {
    case "SAVE_SUBSCRIPTION":
      return action.data;
    default:
      return state;
  }
};

export const CurrentSubscriptionProvider = (props: Props) => {
  const [subscription, dispatchSubscription] = useReducer(reducer, initialState);
  const { account } = useContext(CurrentAccountContext);

  useEffect(() => {
    dispatchSubscription({ type: "SAVE_SUBSCRIPTION", data: false });
    cookies.remove("subscribed", { path: "/" });
  }, [account]);

  return (
    <CurrentSubscriptionContext.Provider value={{ subscription, dispatchSubscription }}>
      {props.children}
    </CurrentSubscriptionContext.Provider>
  );
};
