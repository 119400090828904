import List from "antd/es/list";
import React, { useCallback } from "react";
import parse from "html-react-parser";
import styled from "styled-components";

import { Modal } from "../../components";
import { useWindowDimensions } from "../../hooks/useWindowDimensions";
import { StyledDrawer } from "../../components/Calendar/ListView/styles";

const StyledDiv = styled.div`
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
`;

interface ModalProps {
  memoModal: boolean;
  setMemoModal: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  summary: any;
}
const SummaryModal = ({ memoModal, setMemoModal, title, summary }: ModalProps) => {
  const { width } = useWindowDimensions();
  const isMobile = width <= 640;

  const closeMemoModal = useCallback(() => {
    setMemoModal(false);
  }, [setMemoModal]);
  return (
    <>
      {isMobile ? (
        <StyledDrawer
          visible={memoModal}
          onClose={closeMemoModal}
          zIndex={20}
          placement="bottom"
          size="large"
          maskStyle={{ backgroundColor: "transparent" }}
          height="calc(100% - 32px)"
          bodyStyle={{ padding: 16, paddingTop: 0 }}
          headerStyle={{ marginLeft: "auto" }}
        >
          <>
            <StyledDiv style={{ marginBottom: "16px" }}>{title}</StyledDiv>
            <div className="markdown-body">
              {summary && parse(summary)}
              {!summary && <List />}
            </div>
          </>
        </StyledDrawer>
      ) : (
        <Modal
          customMinWidth={isMobile ? "0" : undefined}
          customMaxHeight={isMobile ? "650px" : undefined}
          open={memoModal}
          onClose={closeMemoModal}
          size="large"
        >
          <>
            <StyledDiv style={{ marginTop: "24px", marginBottom: "16px" }}>{title}</StyledDiv>
            <div className="markdown-body">
              {summary && parse(summary)}
              {!summary && <List />}
            </div>
          </>
        </Modal>
      )}
    </>
  );
};
export default SummaryModal;
