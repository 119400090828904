import * as React from "react";

const SvgPersonEditIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M10 2.292a2.292 2.292 0 100 4.583 2.292 2.292 0 000-4.583zM6.458 4.582a3.542 3.542 0 117.084 0 3.542 3.542 0 01-7.084 0zM9.167 11.041c-3.868 0-6.875 2.773-6.875 6.042a.625.625 0 01-1.25 0c0-4.094 3.709-7.291 8.125-7.291a.625.625 0 110 1.25zM14.975 11.225l-4.167 4.166a.625.625 0 00-.183.442V17.5c0 .345.28.625.625.625h1.667a.625.625 0 00.442-.183l4.166-4.167a.625.625 0 000-.884l-1.666-1.666a.625.625 0 00-.884 0zm-3.1 4.867l3.542-3.542.783.783-3.542 3.542h-.783v-.783z"
      fill="currentColor"
    />
  </svg>
);

export default SvgPersonEditIcon;
