import React, { useMemo, useState } from "react";
import AntdForm from "antd/es/form";
import AntdFormItem from "antd/es/form/FormItem";
import CloseOutlined from "@ant-design/icons/CloseOutlined";
import styled from "styled-components";

import { Input } from "../Input";
import AddAnotherCommandButton from "../CreateProposalForm/AddAnotherCommandButton";

const StyledPrefix = styled("span")`
  margin-right: 12px;
`;

const StyledFormItem = styled(AntdFormItem)<{ $addMarginBottom?: boolean }>`
  .ant-form-item-explain-error {
    display: none;
  }
  margin-bottom: ${(props) => (props.$addMarginBottom ? "24px" : "0")};
`;

const SuffixWrapper = styled("div")`
  display: flex;
`;

const MustHaveAtLeastTwoChoices = styled("span")<{ $atLeastTwoChoicesError: boolean }>`
  font-style: italic;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #ff0000;
  display: ${(props) => (props.$atLeastTwoChoicesError ? "flex" : "none")};
  margin-right: 12px;
`;

const DEFAULT_VALUE = new Array(2).fill("");

const ProposalChoices = () => {
  const [atLeastTwoChoicesError, setAtLeastTwoChoicesError] = useState(false);

  const listValidationRules = [
    {
      validator: async (_: any, values: string[]) => {
        if (values.length < 2) {
          return Promise.reject(new Error("Must have at least 2 choices"));
        }

        return Promise.resolve();
      },
    },
  ];

  const fieldValidationRules = useMemo(() => [{ required: true, message: "" }], []);

  return (
    <AntdForm.List name="choices" rules={listValidationRules} initialValue={DEFAULT_VALUE}>
      {(fields, { add, remove }) => (
        <>
          {fields.map((field, i) => (
            <StyledFormItem
              {...field}
              key={field.key}
              validateTrigger={["onChange", "onBlur"]}
              rules={fieldValidationRules}
              $addMarginBottom
            >
              <Input
                placeholder="Enter a choice..."
                size="large"
                prefix={<StyledPrefix>{i + 1}</StyledPrefix>}
                suffix={
                  <SuffixWrapper>
                    <MustHaveAtLeastTwoChoices
                      $atLeastTwoChoicesError={atLeastTwoChoicesError && i === fields.length - 1}
                    >
                      Must have at least 2 choices
                    </MustHaveAtLeastTwoChoices>
                    <CloseOutlined
                      onClick={() => {
                        if (fields.length > 2) remove(field.name);
                        else setAtLeastTwoChoicesError(true);
                      }}
                    />
                  </SuffixWrapper>
                }
              />
            </StyledFormItem>
          ))}
          <AddAnotherCommandButton
            text="Add another choice"
            addAnotherCommandFunction={() => {
              add();
              setAtLeastTwoChoicesError(false);
            }}
          />
        </>
      )}
    </AntdForm.List>
  );
};

export default React.memo(ProposalChoices);
