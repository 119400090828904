import React from "react";
import Button from "antd/es/button";
import Alert from "antd/es/alert";
import { Draggable, Droppable } from "react-beautiful-dnd";
import styled from "styled-components";
import { useCurrentWidth } from "react-socks";

import { COLORS } from "../../../constants/colors";
import { MoveIcon } from "../../icons";
import media from "../../../media-query";

const ChoicesContainer = styled.div`
  width: 90%;
  margin: 32px auto 0;
  ${media.lessThan("991px")`
      margin: 8px auto 0;
  }
  `}
`;

const AlertContainer = styled.div`
  margin-bottom: 20px;
`;

const ChoiceCard = styled(Button)<{
  chosen?: boolean;
  checked?: boolean;
  padding?: string;
  justify?: string;
  currentWidth: number;
}>`
  border: 1px solid ${({ chosen, checked }) => (chosen || checked ? COLORS.primary.accent : COLORS.primary.grayLighter)};
  border-radius: 8px;
  font-size: 16px;
  min-height: 64px;
  line-height: 30px;
  color: ${({ chosen, checked }) => (chosen || checked ? COLORS.primary.accent : COLORS.primary.grayDark)};
  font-weight: ${({ chosen, checked }) => (chosen ? 600 : checked ? 700 : "normal")};
  text-align: center;
  padding: ${({ padding, currentWidth }) => (padding ? padding : currentWidth < 640 ? "16px" : "16px 28px")};
  margin-bottom: 24px;
  text-transform: capitalize;
  white-space: normal;
  background: ${({ chosen }) =>
    chosen ? "linear-gradient(89.99deg, #FFFFFF 2.03%, #F0EFF8 54.1%, #FFFFFF 99.99%)" : "#fff"};
  box-shadow: ${({ chosen, checked }) =>
    chosen ? "0px 0px 10px rgba(25, 21, 64, 0.2)" : checked ? "0px 2px 4px rgba(25, 21, 64, 0.15);" : "none"};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: ${({ justify }) => (justify ? justify : "space-between")};
  &:disabled {
    color: ${COLORS.primary.grayDarkLightest};
    background: #fff;
    text-transform: capitalize;
  }
  &:disabled:hover {
    background: #fff;
  }
`;

const StyledIconWrapper = styled.span`
  width: 18px;
  height: 8px;
  align-self: start;
`;

const StyledNumber = styled.span`
  font-weight: 600;
  font-size: 16px;
  line-height: 14px;
  margin-right: 20px;
  ${media.lessThan("991px")`
     margin-right: 16px;
  }
  `}
`;

const StyledCheckbox = styled.input`
  width: 16px;
  height: 16px;
`;

const StyledDiv = styled.div`
  height: 40px;
  border-radius: 8px;
  width: 188px;
  border: 1px solid ${COLORS.primary.grayLighter};
  position: relative;
  display: flex;
  align-items: center;
  &:after {
    content: "";
    position: absolute;
    width: 1px;
    height: 32px;
    background: ${COLORS.primary.grayLighter};
    right: 35%;
  }
  ${media.lessThan("640px")`
     display: none;
  }
  `}
`;

const StyledMobileDiv = styled.div`
  width: 96px;
  display: flex;
  justify-content: space-between;
  ${media.greaterThan("640px")`
     display: none;
  }
  `}
`;

const StyledButton = styled(Button)`
  border-radius: 50%;
  width: 18px;
  height: 18px;
  margin: auto 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0 2px;
  border-color: ${COLORS.primary.grayDark};
  cursor: pointer;
  color: ${COLORS.primary.grayDark};
  &:disabled,
  &:disabled:hover {
    border-color: ${COLORS.primary.grayDarkLightest};
    background: white;
    color: ${COLORS.primary.grayDarkLightest};
    opacity: 0.5;
  }
`;

const StyledInput = styled.input`
  width: 32px;
  border: none;
  text-align: center;
  color: ${COLORS.primary.grayDark};
  -moz-appearance: textfield;
  &:focus {
    background: #fff;
    outline: none;
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const PercentDiv = styled.div<{ checked: boolean }>`
  width: 35%;
  text-align: center;
  color: ${({ checked }) => (checked ? COLORS.primary.accent : COLORS.primary.grayDark)};
  font-weight: 400;
  ${media.lessThan("640px")`
    width: auto;
  }
  `}
`;

const StyledSpan = styled.span<{ width?: string; textAlign?: string }>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: ${({ width }) => (width ? width : "100%")};
  text-align: ${({ textAlign }) => (textAlign ? textAlign : "")};
`;

const StyledLabel = styled.label`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 75%;
  text-align: left;
`;

const StyleDivWrapper = styled.div`
  width: 75%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
`;
interface Props {
  choices: any[];
  account: string;
  votePower: number;
}

interface BasicVotingProps extends Props {
  error: string;
  warning: string;
  chosen?: { index: number; text: string };
  handleSelectChoice: (index: number, text: string) => void;
}

interface ApprovalVotingProps extends Props {
  chosenArray: number[];
  handleApprovalChoices: (index: number) => void;
}

interface WeightedVotingProps extends Props {
  chosenObject: { [x: string]: number; [x: number]: number };
  setChosenObject: React.Dispatch<{ [key: number]: number }>;
  handleWeightedChoices: (event: React.ChangeEvent<any>, index: number) => void;
}

export const BasicChoices = ({
  choices,
  account,
  error,
  warning,
  chosen,
  handleSelectChoice,
  votePower,
}: BasicVotingProps) => {
  const currentWidth = useCurrentWidth();

  return (
    <ChoicesContainer>
      <AlertContainer>
        {error && <Alert message={error} type="error" />}
        {warning && <Alert message={warning} type="warning" />}
      </AlertContainer>
      {choices.map((choice, index) => (
        <ChoiceCard
          currentWidth={currentWidth}
          disabled={!account || votePower === 0}
          chosen={chosen?.index === index}
          key={index}
          onClick={() => handleSelectChoice(index, choice)}
          justify="center"
        >
          <StyledSpan>{choice}</StyledSpan>
        </ChoiceCard>
      ))}
    </ChoicesContainer>
  );
};

export const RankedChoices = ({ choices, account, votePower }: Props) => {
  const currentWidth = useCurrentWidth();
  return (
    <>
      <Droppable droppableId="droppable-1">
        {(provided, _) => (
          <ChoicesContainer ref={provided.innerRef} {...provided.droppableProps}>
            {choices.map((choice, index) => (
              <Draggable key={index + 1} index={index} draggableId={`draggable-${index + 1}`}>
                {(provided, snapshot) => (
                  <ChoiceCard
                    currentWidth={currentWidth}
                    disabled={!account || votePower === 0}
                    chosen={false}
                    key={index}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    style={{
                      ...provided.draggableProps.style,
                      boxShadow: snapshot.isDragging ? "0px 0px 10px rgba(25, 21, 64, 0.2)" : "none",
                      background: snapshot.isDragging
                        ? "linear-gradient(89.99deg, #FFFFFF 2.03%, #F0EFF8 54.1%, #FFFFFF 99.99%)"
                        : "white",
                    }}
                  >
                    <StyleDivWrapper>
                      <StyledNumber>{index + 1}</StyledNumber>
                      <StyledSpan textAlign="left">{choice}</StyledSpan>
                    </StyleDivWrapper>
                    <StyledIconWrapper {...provided.dragHandleProps}>
                      <MoveIcon width="inherit" height="inherit" color="inherit" />
                    </StyledIconWrapper>
                  </ChoiceCard>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </ChoicesContainer>
        )}
      </Droppable>
    </>
  );
};

export const ApprovalChoices = ({
  choices,
  account,
  chosenArray,
  handleApprovalChoices,
  votePower,
}: ApprovalVotingProps) => {
  const currentWidth = useCurrentWidth();

  return (
    <>
      <ChoicesContainer>
        {choices.map((choice, index) => (
          <ChoiceCard
            currentWidth={currentWidth}
            disabled={!account || votePower === 0}
            key={index}
            checked={chosenArray.includes(index + 1)}
            onClick={() => handleApprovalChoices(index)}
          >
            <StyledLabel htmlFor="choice">{choice}</StyledLabel>
            <StyledCheckbox
              type="checkbox"
              name={choice}
              onChange={() => handleApprovalChoices(index)}
              checked={chosenArray.includes(index + 1)}
            />
          </ChoiceCard>
        ))}
      </ChoicesContainer>
    </>
  );
};

export const ApprovalVotingChoices = ({
  choices,
  account,
  chosenArray,
  handleApprovalChoices,
  votePower,
}: ApprovalVotingProps) => {
  const currentWidth = useCurrentWidth();

  return (
    <>
      <ChoicesContainer>
        {choices.map((choice, index) => (
          <ChoiceCard
            currentWidth={currentWidth}
            disabled={!account || votePower === 0}
            key={index}
            checked={chosenArray.includes(index)}
            onClick={() => handleApprovalChoices(index)}
          >
            <StyledLabel htmlFor="choice">{choice}</StyledLabel>
            <StyledCheckbox
              type="checkbox"
              name={choice}
              onChange={() => handleApprovalChoices(index)}
              checked={chosenArray.includes(index)}
            />
          </ChoiceCard>
        ))}
      </ChoicesContainer>
    </>
  );
};

export const WeightedChoices = ({
  choices,
  account,
  chosenObject,
  setChosenObject,
  handleWeightedChoices,
  votePower,
}: WeightedVotingProps) => {
  const currentWidth = useCurrentWidth();

  const getPercentage = (value: number) => {
    const total: any = Object.values(chosenObject).reduce((a: any, b: any) => a + b, 0);
    const percent = (value / total) * 100;
    return Number.isInteger(percent) ? percent : percent.toFixed(1);
  };

  const handleIncrementOrDecrement = (action: "increment" | "decrement", index: number) => {
    let val = Number(chosenObject[index + 1]);
    if (action === "increment") setChosenObject({ ...chosenObject, [index + 1]: val ? ++val : 1 });
    if (action === "decrement" && val > 0) {
      if (val === 1) {
        delete chosenObject[index + 1];
        setChosenObject({ ...chosenObject });
      } else {
        setChosenObject({ ...chosenObject, [index + 1]: --val });
      }
    }
  };
  return (
    <>
      <ChoicesContainer>
        {choices.map((choice, index) => (
          <ChoiceCard
            currentWidth={currentWidth}
            disabled={!account || votePower === 0}
            chosen={false}
            key={index}
            padding="16px 16px 16px 20px"
            checked={chosenObject[index + 1] > 0}
          >
            <StyledSpan width="65%" textAlign="left">
              {choice}
            </StyledSpan>
            <StyledDiv>
              <div
                style={{
                  width: "65%",
                  textAlign: "center",
                  padding: "0 8px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <StyledButton
                  onClick={() => handleIncrementOrDecrement("decrement", index)}
                  disabled={chosenObject[index + 1] === 0 || chosenObject[index + 1] === undefined}
                >
                  -
                </StyledButton>
                <span>
                  <StyledInput
                    // type="number"
                    placeholder="0"
                    value={chosenObject[index + 1] || ""}
                    onChange={(e) => handleWeightedChoices(e, index)}
                    // min="1"
                    name={choice}
                  />
                </span>
                <StyledButton onClick={() => handleIncrementOrDecrement("increment", index)}>+</StyledButton>
              </div>
              <PercentDiv checked={chosenObject[index + 1] > 0}>
                {Object.keys(chosenObject).length === 0 || chosenObject[index + 1] === undefined
                  ? 0
                  : getPercentage(chosenObject[index + 1])}
                %
              </PercentDiv>
            </StyledDiv>
            <StyledMobileDiv>
              <StyledButton
                onClick={() => handleIncrementOrDecrement("decrement", index)}
                disabled={chosenObject[index] === 0}
              >
                -
              </StyledButton>
              <PercentDiv checked={chosenObject[index] > 0}>
                {Object.keys(chosenObject).length === 0 ? 0 : getPercentage(chosenObject[index])}%
              </PercentDiv>
              <StyledButton onClick={() => handleIncrementOrDecrement("increment", index)}>+</StyledButton>
            </StyledMobileDiv>
          </ChoiceCard>
        ))}
      </ChoicesContainer>
    </>
  );
};
