import * as React from "react";

const SvgChevronRightIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M9.293 19.307a1 1 0 010-1.414l5.293-5.293-5.293-5.293a1 1 0 011.414-1.414l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414 0z"
      fill="currentColor"
    />
  </svg>
);

export default SvgChevronRightIcon;
