import React from "react";
import styled from "styled-components";

import { Loader } from "../Loader";
import { COLORS } from "../../constants/colors";
import { CheckIcon, CrossIcon } from "../icons";

const VerifyingAddressContainer = styled("div")`
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 16px;
  color: ${COLORS.primary.accent};
  font-style: italic;
  span {
    margin-left: 8px;
  }
`;

const VerifiedAddressContainer = styled("div")<{ $addMargin?: boolean }>`
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 16px;
  color: #54c63f;
  font-style: italic;
  margin: ${({ $addMargin }) => ($addMargin ? "0 0 24px 16px" : "0")};
  span {
    margin-left: 8px;
  }
`;

const InvalidAddressContainer = styled("div")`
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 16px;
  color: #ff4848;
  font-style: italic;
  span {
    margin-left: 8px;
  }
`;

interface Props {
  state: string;
  addMargin?: boolean;
}

const VerifyAddress = ({ state, addMargin }: Props) => {
  if (state === "verifying") {
    return (
      <VerifyingAddressContainer>
        <Loader size="xsmall" /> <span>Verifying Smart Contract</span>
      </VerifyingAddressContainer>
    );
  }
  if (state === "invalid") {
    return (
      <InvalidAddressContainer>
        <CrossIcon width={16} height={16} /> <span>Invalid Address</span>
      </InvalidAddressContainer>
    );
  }
  if (state === "verified") {
    return (
      <VerifiedAddressContainer $addMargin={addMargin}>
        <CheckIcon width={16} height={16} /> <span>Verified Address</span>
      </VerifiedAddressContainer>
    );
  }
  return null;
};

export default VerifyAddress;
