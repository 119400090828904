import { useQuery } from "@tanstack/react-query";
import { DiscourseTopicsData } from "@boardroom/boardroom-api";

import { BOARDROOM_API_KEY, baseAPIUrl } from "../constants/general";

export interface KeywordSearch {
  id: string;
  keyword: string;
  protocol: string;
  address: string;
}

export interface KeywordMatch {
  id: string;
  keyword: string;
  protocol: string;
  address: string;
  contentRefId: string;
  postNumber: number;
}

export const useGetKeywordSearchesByAddress = ({ address }: { address: string }) => {
  const { data } = useQuery<KeywordSearch[]>({
    queryKey: ["getAlertsByAddress", address],
    queryFn: async () => {
      const response = await fetch(
        `${baseAPIUrl}discourse/discourseKeywordToSearch/${address}?key=${BOARDROOM_API_KEY}&bypassCache=${Date.now()}`,
      );
      const data = await response.json();
      return data;
    },
    enabled: !!address,
  });

  return {
    keywordSearches: data,
  };
};

interface GetKeywordMatchesResponse {
  keywords: KeywordMatch[];
  topics: DiscourseTopicsData[];
}

export const useGetKeywordMatchesByAddress = ({ address }: { address: string }) => {
  const { data } = useQuery<GetKeywordMatchesResponse>({
    queryKey: ["getKeywordMatchesByAddress", address],
    queryFn: async () => {
      const response = await fetch(
        `${baseAPIUrl}discourse/discourseKeywordMatches/${address}?key=${BOARDROOM_API_KEY}&bypassCache=${Date.now()}`,
      );
      const data = await response.json();
      return data;
    },
    enabled: !!address,
  });

  return {
    keywordMatches: data,
  };
};
