import * as React from "react";

const SvgMoveIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 18 8" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M18 7a1 1 0 01-1 1H1a1 1 0 010-2h16a1 1 0 011 1zm0-6a1 1 0 01-1 1H1a1 1 0 010-2h16a1 1 0 011 1z"
      fill="currentColor"
    />
  </svg>
);

export default SvgMoveIcon;
