import { useContext } from "react";
import { useQuery } from "@tanstack/react-query";
import { getAddress } from "ethers/lib/utils";
import { GetUserResponse } from "@boardroom/boardroom-api";
import { isAddress } from "web3-utils";

import { apiClient } from "../utils/apiClient";
import { CurrentUuidContext } from "../reducers/CurrentUuid";
import { getUuid } from "../utils/getUuid";

interface Props {
  address: string;
  suspense?: boolean;
  byPassCache?: boolean;
}

export const useUserDetails = ({ address, suspense = true, byPassCache = false }: Props) => {
  const { uuid } = useContext(CurrentUuidContext);
  const uuidInCookie = getUuid(address);
  const uuidToUse = uuid || uuidInCookie;
  const normalizedAddress = isAddress(address) ? getAddress(address) : address;
  const timestamp = byPassCache ? Date.now() : 0;
  const { data: user } = useQuery<GetUserResponse | undefined, Error>(
    [`user:${normalizedAddress}:${uuidToUse}`],
    () => {
      return apiClient.getUserDetails(normalizedAddress, timestamp, uuidToUse);
    },
    {
      suspense,
      enabled: !!normalizedAddress,
    },
  );

  return { user: user?.data };
};
