import * as React from "react";

const SvgSwitchIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M10.293.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 6H1a1 1 0 010-2h11.586l-2.293-2.293a1 1 0 010-1.414zm-4.586 10a1 1 0 010 1.414L3.414 14H15a1 1 0 110 2H3.414l2.293 2.293a1 1 0 11-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
      fill="currentColor"
    />
  </svg>
);

export default SvgSwitchIcon;
