import * as React from "react";

const SvgNotifIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 19 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.137.61a6.44 6.44 0 00-9.143 5.166l-.318 3.073c-.03.282-.043.412-.061.54a7.5 7.5 0 01-.762 2.402c-.059.114-.122.228-.261.476l-.636 1.134-.02.035C.6 14.04.319 14.54.16 14.95c-.163.422-.25.876-.02 1.31.02.04.043.077.067.115.267.412.705.559 1.152.623.436.062 1.01.062 1.702.062H15.74c.692 0 1.265 0 1.7-.062.448-.064.886-.21 1.153-.623.024-.038.046-.076.067-.115.23-.434.143-.888-.02-1.31-.158-.41-.439-.91-.777-1.514l-.02-.035-.635-1.134a16.44 16.44 0 01-.261-.476 7.5 7.5 0 01-.762-2.403 16.482 16.482 0 01-.06-.54l-.085-.818c-.311.103-.64.17-.986.195l.076.727v.01h.001c.028.27.043.418.064.564a8.5 8.5 0 00.863 2.723c.068.132.14.261.273.498l.005.01.635 1.133c.363.647.607 1.085.737 1.421.128.332.092.436.068.482a.47.47 0 01-.023.038c-.028.045-.101.126-.454.177-.356.05-.857.052-1.6.052H3.1c-.742 0-1.243-.002-1.6-.052-.352-.05-.425-.132-.453-.177a.495.495 0 01-.023-.038c-.024-.046-.06-.15.068-.482.13-.336.374-.774.737-1.421l.635-1.134.005-.01c.133-.236.205-.365.273-.497a8.5 8.5 0 00.863-2.723c.02-.146.036-.294.064-.564l.001-.01.319-3.073a5.44 5.44 0 017.437-4.489 4.26 4.26 0 01.711-.78zm1.766 1.226c-.306.143-.576.36-.788.63a5.426 5.426 0 011.696 3.413l.037.354c.353-.016.685-.123.974-.301l-.016-.157a6.423 6.423 0 00-1.903-3.939z"
      fill="currentColor"
    />
    <path
      d="M6.296 17.025c.184.854.587 1.608 1.148 2.146.561.538 1.249.83 1.956.83.708 0 1.395-.292 1.957-.83.56-.538.964-1.292 1.147-2.146"
      stroke="#fff"
      strokeLinecap="round"
    />
    <ellipse cx={14.756} cy={3.941} rx={2.142} ry={2.294} fill="currentColor" />
  </svg>
);

export default SvgNotifIcon;
