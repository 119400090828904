import { BoardroomProposalStatus } from "../types";

export const getTagType = (status: BoardroomProposalStatus) => {
  if (status === "active" || status === "executed") {
    return "executed";
  }
  if (status === "pending" || status === "wip" || status === "proposed" || status === "queued") {
    return "proposed";
  }
  if (
    status === "closed" ||
    status === "expired" ||
    status === "canceled" ||
    status === "approved" ||
    status === "rejected"
  ) {
    return "expired";
  }

  return "no status";
};
