import { OrgNote, ProposalComment } from "@boardroom/boardroom-api";
import React, { useReducer, createContext } from "react";

interface Props {
  children: React.ReactNode;
}

type SingleNoteAction = { type: "SAVE_NOTE" | "DELETE_NOTE"; data: ProposalComment | OrgNote };
type MultipleNoteActions = { type: "SAVE_NOTES"; data: OrgNote[] };

interface NotesContextType {
  notes: OrgNote[];
  dispatchNotes: React.Dispatch<any>;
}

const initialState: OrgNote[] = [];

export const NotesContext = createContext({} as NotesContextType);

const reducer = (state: OrgNote[] | [], action: MultipleNoteActions | SingleNoteAction) => {
  switch (action.type) {
    case "SAVE_NOTES": {
      const data = action.data;
      let filteredProposalNotes = state;
      data.map((note) => {
        filteredProposalNotes = filteredProposalNotes.filter(
          (existingNotes) => existingNotes.commentId !== note.commentId,
        );
      });
      return [...filteredProposalNotes, ...data];
    }
    case "SAVE_NOTE": {
      const filteredProposalNotes = state.filter((note) => note.commentId !== action.data.commentId);

      return [...filteredProposalNotes, action.data];
    }
    case "DELETE_NOTE": {
      const filteredProposalNotes = state.filter((note) => note.commentId !== action.data.commentId);
      return filteredProposalNotes.filter((note) => note.parentId !== action.data.commentId);
    }
    default:
      return state;
  }
};

export const NotesProvider = (props: Props) => {
  const [notes, dispatchNotes] = useReducer(reducer, initialState as never);

  return <NotesContext.Provider value={{ notes, dispatchNotes }}>{props.children}</NotesContext.Provider>;
};
