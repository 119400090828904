import React, { Suspense, useCallback, useContext, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import ContentLoader from "react-content-loader";
import { ErrorBoundary } from "react-error-boundary";
import { useLocation } from "react-router";

import InfoCard from "../InfoCard";
import { COLORS } from "../../constants/colors";
import { useVoter } from "../../hooks/useVoter";
import VotePowerRow from "./VotePowerRow";
import ExpandCollapseRows from "../common/ExpandCollapseRows";
import { Paragraph } from "../Typography";
import { useCurrentWidth } from "react-socks";
import CollapsibleMobileInfoCard from "../common/CollapsibleMobileInfoCard";
import { useProtocols } from "../../hooks/useProtocols";
import { useUserProtocols } from "../../hooks/useUserProtocols";
import { useDelegateProtocolsByAddress } from "../../hooks/useDelegateVotingPowerByAddress";
import {
  EmptyStateWrapper,
  StyledEmptyStateButton,
  StyledImg,
  StyledTitleEmptyState,
} from "../../features/Dashboard/YourDelegates";
import { useOnboardWallet } from "../../hooks/useOnboardWallet";
import { useMixpanel } from "../../hooks";
import { CurrentProjectsFilterContext } from "../../reducers/CurrentProjectsFilter";
import { CurrentUserDetailsContext } from "../../reducers/CurrentUserDetails";
import { savedProtocolsToArray } from "../../utils/savedProtocolsToArray";
import { useVotePowerFromApi } from "../../hooks/useVotePowerFromApi";

interface Props {
  address: string;
  removeMarginTop?: boolean;
}

export const VotingPowerRowsWrapper = styled.div<{ $isExpanded: boolean }>`
  max-height: ${({ $isExpanded }) => ($isExpanded ? "auto" : "420px")};
  overflow: hidden;
  .ant-row {
    border-top: 1px solid ${COLORS.primary.grayLighter};
    &:first-child {
      border-top: none;
    }
  }
`;

export const StyledEmptyMessage = styled(Paragraph)`
  text-align: center;
  margin-top: 30px;
`;

export const StyledInfoCard = styled(InfoCard)<{ $removeMarginTop?: boolean }>`
  margin-top: ${({ $removeMarginTop }) => ($removeMarginTop ? "0" : "24px")};
  .ant-card-body {
    padding: 0;
  }
`;

export const StyledCollapsibleMobileInfoCard = styled(CollapsibleMobileInfoCard)`
  margin-top: 24px;
`;

export const CardTitle = styled.span`
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #7b7893;
`;

export const StyledText = styled.span`
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: #7b7893;
`;

function VotePowerCard(props: Props) {
  const { address, removeMarginTop } = props;
  const { voter } = useVoter({ address });
  const [areResultsExpanded, setAreResultsExpanded] = useState(false);
  const isMobile = useCurrentWidth() < 991;
  const { protocols } = useProtocols();
  const { protocolsInWallet } = useUserProtocols({ address });
  const { protocols: delegateProtocols } = useDelegateProtocolsByAddress({ address });
  const wallet = useOnboardWallet();
  const { trackClickConnectWalletOnVotePowerCard } = useMixpanel();
  const { projectsFilter } = useContext(CurrentProjectsFilterContext);
  const { userDetails } = useContext(CurrentUserDetailsContext);
  const userSavedProtocolsArray = savedProtocolsToArray(userDetails?.savedProtocols);
  const [nullChildren, setNullChildren] = useState<string[]>([]);
  const { pathname } = useLocation();
  const votePowerFromApi = useVotePowerFromApi({
    addresses: [address],
  });
  const protocolsFromVotePowerApi = useMemo(
    () => votePowerFromApi?.map((vp) => vp?.protocol) || [],
    [votePowerFromApi],
  );

  useEffect(() => {
    setNullChildren([]);
  }, [address]);

  const handleConnectWalletClick = useCallback(() => {
    trackClickConnectWalletOnVotePowerCard();
    wallet?.openWalletModal();
  }, [wallet, trackClickConnectWalletOnVotePowerCard]);

  const protocolsSupported = useMemo(
    () =>
      voter?.protocols
        .filter((protocolObj) => protocols[protocolObj.protocol] && protocols[protocolObj.protocol]?.isEnabled)
        .sort((a: { totalPowerCast: number }, b: { totalPowerCast: number }) => b.totalPowerCast - a.totalPowerCast) ||
      [],
    [protocols, voter?.protocols],
  );

  const protocolsSupportedWithoutVoteHistory = useMemo(
    () =>
      protocolsInWallet.filter((protocolCname) => {
        const index = protocolsSupported?.findIndex((protocol) => protocol.protocol === protocolCname);
        return index !== undefined ? index < 0 : true;
      }) || [],
    [protocolsInWallet, protocolsSupported],
  );

  const delegateProtocolsToShow = useMemo(() => {
    const otherProtocols = [
      ...(voter?.protocols.map((protocol) => protocol.protocol) || []),
      ...protocolsSupportedWithoutVoteHistory,
    ];
    const filteredProtocols = Array.from(
      new Set(delegateProtocols?.filter((protocol) => !otherProtocols.includes(protocol))),
    );
    return filteredProtocols || [];
  }, [protocolsSupportedWithoutVoteHistory, voter?.protocols, delegateProtocols]);

  const allProtocols = useMemo(() => {
    const allProtocols = Array.from(
      new Set([
        ...protocolsSupported?.map((protocol) => protocol.protocol),
        ...protocolsSupportedWithoutVoteHistory,
        ...delegateProtocolsToShow,
        ...protocolsFromVotePowerApi,
      ]),
    )
      .filter((protocol) => {
        if (projectsFilter === "custom" && pathname === "/") {
          return userSavedProtocolsArray.includes(protocol);
        } else {
          return true;
        }
      })
      .sort();
    return allProtocols;
  }, [
    protocolsSupported,
    protocolsSupportedWithoutVoteHistory,
    delegateProtocolsToShow,
    protocolsFromVotePowerApi,
    projectsFilter,
    pathname,
    userSavedProtocolsArray,
  ]);

  const toggleResultsExpandCollapse = useCallback(() => {
    setAreResultsExpanded(!areResultsExpanded);
    setNullChildren((cur) => cur.filter((p) => allProtocols.slice(0, 3).includes(p)));
  }, [allProtocols, areResultsExpanded]);

  if (isMobile) {
    return (
      <StyledCollapsibleMobileInfoCard title={<CardTitle>Vote Power</CardTitle>} $noPaddingBody={true}>
        {allProtocols?.map((protocol, index) => (
          <Suspense key={`${index}-${protocol}`} fallback={<></>}>
            <VotePowerRow protocol={protocol} address={address} />
          </Suspense>
        ))}
      </StyledCollapsibleMobileInfoCard>
    );
  }

  return (
    <StyledInfoCard
      height="100%"
      title={<CardTitle>Vote Power</CardTitle>}
      $noPaddingBody={true}
      $removeMarginTop={removeMarginTop}
    >
      <VotingPowerRowsWrapper $isExpanded={areResultsExpanded}>
        {address ? (
          <>
            <ExpandCollapseRows
              isExpanded={areResultsExpanded}
              totalRows={allProtocols?.length || 0}
              onToggle={toggleResultsExpandCollapse}
            >
              {allProtocols?.map((protocol, index) => (
                <ExpandCollapseRows.Row
                  index={index}
                  cutoffIndex={3 + nullChildren?.length}
                  isExpanded={areResultsExpanded}
                  key={`${index}-${protocol}`}
                >
                  <Suspense fallback={<></>}>
                    <VotePowerRow setNullChildren={setNullChildren} protocol={protocol} address={address} />
                  </Suspense>
                </ExpandCollapseRows.Row>
              ))}
            </ExpandCollapseRows>
            {allProtocols?.length === 0 && <StyledEmptyMessage>No Voting Power</StyledEmptyMessage>}
          </>
        ) : (
          <EmptyStateWrapper>
            <StyledImg src={`${process.env.PUBLIC_URL}/assets/VoteHistoryEmptyState.png`} />
            <StyledTitleEmptyState>You have no voting power </StyledTitleEmptyState>
            <StyledEmptyStateButton $addMarginTop onClick={handleConnectWalletClick}>
              Connect Wallet
            </StyledEmptyStateButton>
          </EmptyStateWrapper>
        )}
      </VotingPowerRowsWrapper>
    </StyledInfoCard>
  );
}

function VotePowerCardWrapper(props: Props) {
  return (
    <Suspense
      fallback={
        <StyledInfoCard height="100%" title="Vote power" $noPaddingBody={true}>
          <ContentLoader
            speed={2}
            width="100%"
            height={115}
            backgroundColor={COLORS.primary.grayLight}
            foregroundColor={COLORS.primary.grayLighter}
          >
            <circle cx="36" cy="41" r="12" />
            <rect x="65" y="37" rx="4" ry="4" width="92" height="5" />
            <rect x="272" y="35" rx="4" ry="4" width="27" height="8" />

            <circle cx="36" cy="99" r="12" />
            <rect x="65" y="95" rx="4" ry="4" width="92" height="5" />
            <rect x="272" y="93" rx="4" ry="4" width="27" height="8" />
          </ContentLoader>
        </StyledInfoCard>
      }
    >
      <VotePowerCard {...props} />
    </Suspense>
  );
}

function VoterPowerErrorBoundary(props: Props) {
  return (
    <ErrorBoundary fallback={<></>} resetKeys={[props.address]}>
      <VotePowerCardWrapper {...props} />
    </ErrorBoundary>
  );
}

export default React.memo(VoterPowerErrorBoundary);
