import React, { Suspense } from "react";
import { ProposalDetails } from "@boardroom/boardroom-api";
import Row from "antd/es/row";
import Col from "antd/es/col";
import moment from "moment";
import ContentLoader from "react-content-loader";
import { useCurrentWidth } from "react-socks";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import MainContainer from "../components/layout/MainContainer";
import MetaTags from "../components/common/MetaTags";
import { CardsContainer, InfoCard } from "../components";
import { MonthlyCalendar } from "../components/Calendar/Monthly";
import { boardroomProtocolInfoRepo, rawGithubBoardRoomOrgLink } from "../constants/general";
import { useCurrentProtocol__Deprecated } from "../hooks/useCurrentProtocol__Deprecated";
import { CalendarEvent, CalendarEvents } from "../types";
import { COLORS } from "../constants/colors";
import { protocols } from "../constants/protocols";
import { dateKeyFormat } from "../constants/date";
import media from "../media-query";
import ProtocolInnerTabNav from "../components/NavBar/ProtocolInnerTabNav";
import { TrackPageView } from "../components/TrackPageView";
import { useProposals } from "../hooks/useProposals";
import { ContentWrapper } from "./ProtocolOverview/styles";

interface Params {
  protocol: string;
}

const CalendarWrapper = styled("div")`
  margin: 0 auto 120px;
  ${media.lessThan("640px")`
     width:100%;
     margin: 0px auto 120px;
  `}
`;

export function proposalsAsEvents({
  proposals,
  removeProposalStart = false,
  removeProposalEnd = false,
}: {
  proposals: ProposalDetails[];
  removeProposalStart?: boolean;
  removeProposalEnd?: boolean;
}): CalendarEvents {
  const results: CalendarEvents = {};

  proposals.map((proposal) => {
    const path = protocols[proposal.protocol]?.path;

    const proposalStart: CalendarEvent = {
      title: proposal.title,
      date: proposal.startTimestamp * 1000,
      url: `/${path}/proposal/${proposal.refId}`,
      protocolCname: protocols[proposal?.protocol].cname,
      type: "proposalStart",
      proposalRefId: proposal.refId,
    };

    const proposalEnd: CalendarEvent = {
      ...proposalStart,
      type: "proposalEnd",
      date: proposal.endTimestamp * 1000,
    };

    const startKey = moment(proposal.startTimestamp * 1000).format(dateKeyFormat);
    const endKey = moment(proposal.endTimestamp * 1000).format(dateKeyFormat);

    if (!removeProposalStart) {
      if (results[startKey]) {
        results[startKey].push(proposalStart);
      } else {
        results[startKey] = [proposalStart];
      }
    }

    if (!removeProposalEnd) {
      if (results[endKey]) {
        results[endKey].push(proposalEnd);
      } else {
        results[endKey] = [proposalEnd];
      }
    }
  });

  return results;
}

const ProtocolCalendar = () => {
  const { protocol } = useParams<Params>();
  const currentWidth = useCurrentWidth();
  const protocolInfo = useCurrentProtocol__Deprecated();
  const protocolToUpper = protocol[0].toUpperCase() + protocol.slice(1);
  const { proposals } = useProposals({ protocol: protocolInfo, limit: 1000 });
  const proposalEvents = proposalsAsEvents({ proposals });

  const metaTagImageUrl = `${rawGithubBoardRoomOrgLink}/${boardroomProtocolInfoRepo}/main/${protocolInfo?.folder}/header.png`;

  return (
    <CardsContainer>
      <MetaTags
        title={`${protocolToUpper} Calendar`}
        description={`Upcoming events for ${protocol}`}
        imageUrl={metaTagImageUrl}
      />
      <TrackPageView name="Protocol Calendar" protocol={protocolInfo?.cname} />
      <Row justify="start" gutter={[48, 48]} style={{ marginLeft: "0", marginRight: "0" }}>
        <Col lg={{ span: 16, push: 0 }} md={{ span: 24, push: 0 }} sm={{ span: 24, push: 0 }}>
          <ContentWrapper>
            <Row gutter={[12, { xs: 12, sm: 12, md: 12 }]}>
              <Col span={24}>
                <ProtocolInnerTabNav hideShareButton />
              </Col>
              <Col span={24}>
                <CalendarWrapper>
                  <MonthlyCalendar proposalEvents={proposalEvents} isCompact={currentWidth <= 768} />
                </CalendarWrapper>
              </Col>
            </Row>
          </ContentWrapper>
        </Col>
      </Row>
    </CardsContainer>
  );
};

export default () => (
  <Suspense
    fallback={
      <MainContainer>
        <Row>
          <Col span={20} push={2} pull={2}>
            <InfoCard>
              <ContentLoader
                speed={2}
                width="100%"
                height={402}
                backgroundColor={COLORS.primary.grayLight}
                foregroundColor={COLORS.primary.grayLighter}
              >
                <rect x="0" y="10" rx="5" ry="5" width="100%" height="12" />
                <rect x="0" y="34" rx="5" ry="5" width="70%" height="12" />

                <rect x="0" y="75" rx="5" ry="5" width="100%" height="12" />
                <rect x="0" y="99" rx="5" ry="5" width="100%" height="12" />
                <rect x="0" y="123" rx="5" ry="5" width="100%" height="12" />
                <rect x="0" y="147" rx="5" ry="5" width="20%" height="12" />

                <rect x="0" y="188" rx="5" ry="5" width="100%" height="12" />
                <rect x="0" y="212" rx="5" ry="5" width="100%" height="12" />
                <rect x="0" y="236" rx="5" ry="5" width="40%" height="12" />

                <rect x="0" y="277" rx="5" ry="5" width="100%" height="12" />
                <rect x="0" y="301" rx="5" ry="5" width="25%" height="12" />

                <rect x="0" y="342" rx="5" ry="5" width="100%" height="12" />
                <rect x="0" y="366" rx="5" ry="5" width="100%" height="12" />
                <rect x="0" y="390" rx="5" ry="5" width="70%" height="12" />
              </ContentLoader>
            </InfoCard>
          </Col>
        </Row>
      </MainContainer>
    }
  >
    <ProtocolCalendar />
  </Suspense>
);
