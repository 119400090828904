import React from "react";
import { LinkProps, Link as ReactDomLink } from "react-router-dom";

import styled, { css } from "styled-components";
import { COLORS } from "../../constants/colors";

export const LinkStyles = css`
  color: ${COLORS.primary.accent};
  &:hover {
    color: ${COLORS.primary.accentDarker};
  }
`;

const StyledLink = styled(ReactDomLink)`
  ${LinkStyles};
`;

const Link = (props: LinkProps) => <StyledLink {...props} />;

export default Link;
