import * as React from "react";

const SvgSettingsAngularIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" {...props}>
    <path
      d="M7.604 1.365a.625.625 0 01.724.319 1.875 1.875 0 003.343 0 .625.625 0 01.723-.319 8.944 8.944 0 013.704 2.073.625.625 0 01.135.734 1.875 1.875 0 001.683 2.703h.026a.625.625 0 01.605.437 8.995 8.995 0 01.228 4.492.625.625 0 01-.673.497 1.875 1.875 0 00-1.62 3.074.625.625 0 01-.027.836 8.956 8.956 0 01-3.896 2.376.625.625 0 01-.773-.409 1.876 1.876 0 00-3.573 0 .625.625 0 01-.774.409 8.957 8.957 0 01-3.895-2.376.625.625 0 01-.027-.836 1.875 1.875 0 00-1.62-3.074.625.625 0 01-.674-.497 8.995 8.995 0 01.228-4.492.625.625 0 01.606-.437h.026a1.875 1.875 0 001.683-2.703.625.625 0 01.135-.734 8.945 8.945 0 013.703-2.073zm-2.536 2.71a3.126 3.126 0 01-2.54 4.019 7.725 7.725 0 00-.166 2.96 3.125 3.125 0 012.445 4.644 7.708 7.708 0 002.47 1.516 3.123 3.123 0 015.444 0 7.707 7.707 0 002.47-1.516 3.125 3.125 0 012.445-4.644 7.79 7.79 0 00-.166-2.96 3.126 3.126 0 01-2.54-4.02 7.695 7.695 0 00-2.43-1.368A3.12 3.12 0 0110 3.958a3.12 3.12 0 01-2.502-1.252 7.695 7.695 0 00-2.43 1.369z"
      fill="currentColor"
    />
    <path
      d="M6.458 10a3.542 3.542 0 117.083 0 3.542 3.542 0 01-7.083 0zm3.541-2.292a2.292 2.292 0 100 4.584 2.292 2.292 0 000-4.584z"
      fill="currentColor"
    />
  </svg>
);

export default SvgSettingsAngularIcon;
