import React from "react";

import Blockie from "../Blockie";
import { Avatar } from "../Avatar";
import { useGetEnsAvatar } from "../../hooks/useEns";

export const Pfp = ({
  size,
  address,
  pfpUrl,
  marginTop,
  dimension,
  noShrink,
  shape,
  addOutline,
}: {
  size: "xxsmall" | "xsmall" | "small" | "standard" | "large" | "medium" | "xlarge" | "xxlarge";
  address: string;
  pfpUrl?: string | null;
  marginTop?: string;
  dimension?: number;
  noShrink?: boolean;
  shape?: "circle" | "square";
  addOutline?: boolean;
}) => {
  const ensAvatar = useGetEnsAvatar(address);

  if (pfpUrl) {
    return (
      <Avatar
        shape={shape}
        style={{
          marginTop: marginTop || "0",
          flexShrink: noShrink ? "0" : "",
          outline: addOutline ? "1px solid white" : "",
          outlineOffset: addOutline ? "-2px" : "",
        }}
        src={pfpUrl}
        size={size}
      />
    );
  }
  if (ensAvatar) {
    return (
      <Avatar
        shape={shape}
        style={{
          marginTop: marginTop || "0",
          flexShrink: noShrink ? "0" : "",
          outline: addOutline ? "1px solid white" : "",
          outlineOffset: addOutline ? "-2px" : "",
        }}
        src={ensAvatar}
        size={size}
      />
    );
  }
  return <Blockie addOutline={addOutline} shape={shape} dimension={dimension} marginTop={marginTop} seed={address} />;
};
