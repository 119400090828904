import Button from "antd/es/button";
import Row from "antd/lib/row";
import Switch from "antd/es/switch";
import Spin from "antd/es/spin";
import Message from "antd/es/message";
import Col from "antd/es/col";
import LoadingOutlined from "@ant-design/icons/LoadingOutlined";
import MDEditor from "@uiw/react-md-editor";
import React, { useState, useCallback, Suspense, useEffect, useContext } from "react";
import styled, { css } from "styled-components";
import { getAddress } from "@ethersproject/address";
import { useMutation } from "@tanstack/react-query";
import { User } from "@boardroom/boardroom-api";
import { IKContext, IKUpload } from "imagekitio-react";
import { isAddress } from "web3-utils";
import notification from "antd/es/notification";

import { COLORS } from "../../constants/colors";
import { Modal } from "../Modal";
import { CrossIcon, CameraIcon } from "../icons";
import { updateUserDetails } from "../../utils/updateUserDetails";
import { API_URL, BOARDROOM_API_KEY } from "../../constants/general";
import { useSiwe } from "../../hooks/useSiwe";
import { CurrentUuidContext } from "../../reducers/CurrentUuid";
import { CurrentAccountContext } from "../../reducers/CurrentAccount";
import DelegateSettings from "./DelegateSettings";
import { useMixpanel } from "../../hooks";
import { useSiweFunction } from "../../hooks/useSiweFunction";
import { Text } from "../Typography";

interface Props {
  open: boolean;
  onClose: () => void;
  address: string;
  user: User;
  updateUserState: (profile: User) => void;
  isGnosisOwner?: boolean;
}

const Title = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${COLORS.primary.grayDark};
  text-transform: capitalize;
  font-family: Inter;
  font-style: normal;
  font-weight: 300;
  font-size: 22px;
  line-height: 30px;
  position: relative;
  cursor: pointer;
  &:hover {
    opacity: ${({ active }) => (active ? 1 : 0.6)};
  }
  ${({ active }) =>
    active &&
    css`
      &:after {
        content: "";
        border-bottom: 4px solid ${COLORS.primary.accent};
        position: absolute;
        width: 100%;
        bottom: -12px;
      }
    `}
`;

const ModalHeader = styled.div`
  border-bottom: 1px solid ${COLORS.primary.grayLight};
  padding: 12px;
`;

const StyledCloseIcon = styled(CrossIcon)`
  position: absolute;
  top: 20px;
  right: 22px;
  cursor: pointer;
`;

const StyledImg = styled("img")`
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-size: cover;
  background-position: center;
`;

const StyledDiv = styled("div")`
  padding: 0 20px;
  margin-top: -30px;
`;

const InputDiv = styled.div`
  margin-top: 14px;
  display: inline-block;
  border: 1px solid ${COLORS.secondary.purpleLighter};
  padding: 4px 12px;
  width: 100%;
  border-radius: 4px;
  position: relative;
  display: block;
  &:hover {
    border: 1px solid ${COLORS.primary.accent};
    label {
      color: ${COLORS.primary.accent};
    }
  }
`;

const StyledLabel = styled.label`
  display: block;
  font-size: 14px;
  color: ${COLORS.primary.grayDarkLightest};
`;

const StyledInput = styled.input<{ width?: string }>`
  display: block;
  width: ${({ width }) => (width ? width : "100%")};
  outline: none;
  border: none;
`;

const ButtonGroup = styled.div`
  padding: 24px;
  display: flex;
  justify-content: center;
  gap: 12px;
  margin-top: 64px;
  position: fixed;
  bottom: 0;
  width: 100%;
  background: white;
`;

const StyledButton = styled(Button)`
  font-size: 14px;
  line-height: 16px;
  height: 40px;
  border-radius: 8px;
`;

const StyledCancelButton = styled(StyledButton)`
  color: ${COLORS.primary.accent};
  font-weight: normal;
  border: none;
  width: 200px;
`;

const StyledSaveButton = styled(StyledButton)`
  font-weight: 600;
  color: #fff;
  background: ${COLORS.primary.accent};
  width: 200px;
`;

const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  margin-bottom: 24px;
`;

const StyledAbout = styled(StyledLabel)`
  position: absolute;
  top: 68px;
  left: 4%;
`;

const StyledMDEditor = styled(MDEditor)`
  margin-top: 18px;
  .w-md-editor-content {
    border-radius: 7px;
    border: 1px solid ${COLORS.secondary.purpleLighter};
    padding-top: 16px;
    &:hover {
      border: 1px solid ${COLORS.primary.accent};
      label {
        color: ${COLORS.primary.accent};
      }
    }
  }
  &.w-md-editor {
    box-shadow: none;
  }
  .w-md-editor-preview {
    padding-top: 24px;
  }
  .w-md-editor-toolbar {
    border-bottom: none;
    background: transparent;
    margin-bottom: 8px;
    li > button {
      padding: 6px;
      height: auto;
    }
  }
  .w-md-editor-text-input {
    -webkit-text-fill-color: inherit;
  }
`;

const StyledSwitch = styled(Switch)`
  float: right;
  &.ant-switch-checked {
    background: #4235e1;
  }
`;

const StyledPreviewWrapper = styled.div`
  position: absolute;
  right: 14px;
  top: 20px;
`;

const Label = styled.label`
  margin-left: 4px;
  font-size: 10px;
  line-height: 16px;
  color: #7b7893;
`;

const StyledPreviewLabel = styled(Label)`
  float: right;
  line-height: 22px;
  margin-right: 8px;
`;

const Flex = styled.div`
  display: flex;
`;

const StyledSpan = styled.span`
  font-weight: 500;
  margin-right: 2px;
  color: ${COLORS.secondary.purpleLighter};
`;

const StyledHeading = styled.div`
  margin-top: 28px;
`;
const StyledHeadingText = styled.div<{ size: string; color: string }>`
  font-size: ${({ size }) => size};
  color: ${({ color }) => color};
`;

const StyledCameraIcon = styled(CameraIcon)`
  color: #fff;
  width: 24px;
  height: 24px;
`;

const IconWrapper = styled.button`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  background: transparent;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  &:hover {
    background: #000;
    opacity: 0.2;
    color: #fff;
  }
`;

const HeaderWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 148px;
`;

const StyledProfileImg = styled("img")`
  border: 2px solid #ffffff;
  background: #000;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
`;

const ProfileImageWrapper = styled.div`
  position: relative;
  width: 80px;
  height: 80px;
  border-radius: 50%;
`;

const StyledTabWrapper = styled.div`
  display: flex;
  gap: 40px;
`;

const urlEndpoint = "https://ik.imagekit.io/4fqrcsooovf";
const publicKey = "public_/bTVi2IN587BeoLfZIQcNM0GvAU=";
const authenticationEndpoint = `${API_URL}/getImageAuthentication?key=${BOARDROOM_API_KEY}`;

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const EditProfile = (props: Props) => {
  const [preview, setPreview] = useState<"edit" | "live" | "preview">("edit");
  const { open, onClose, address, user, updateUserState, isGnosisOwner } = props;
  const [about, setAbout] = useState(user?.about);
  const normalizedAddress = isAddress(address) ? getAddress(address) : address;
  const [addressType, setAddressType] = useState(user?.type || "User");
  const { account } = useContext(CurrentAccountContext);
  useSiwe({ trigger: open });
  const { trackUpdateProfileSettingsInProfileModal } = useMixpanel();
  const { uuid } = useContext(CurrentUuidContext);
  const siweFunction = useSiweFunction();

  const setUserDefaults = useCallback(
    (user: User) => ({
      address: user?.address || address,
      ens: user?.ens || "",
      twitter: user?.twitter || "",
      username: user?.username || "",
      website: user?.website || "",
      pfpUrl: user?.pfpUrl || "",
      pfpImageId: user?.pfpImageId || "",
      email: user?.email || "",
      pfpThumbnailUrl: user?.pfpThumbnailUrl || "",
      bannerImageId: user?.bannerImageId || "",
      bannerUrl: user?.bannerUrl || "",
      bannerThumbnailUrl: user?.bannerThumbnailUrl || "",
      notificationPreferences: user?.notificationPreferences || "",
      associatedAddresses: user?.associatedAddresses || "",
      github: user?.github || "",
      discord: user?.discord || "",
      delegationPitches: user?.delegationPitches || "",
      savedProtocols: user?.savedProtocols || "",
      loginEmail: user?.loginEmail || "",
      orgMemberships: user?.orgMemberships || "",
      bookmarkedProposals: user?.bookmarkedProposals || "",
    }),
    [address],
  );

  const initialValue = setUserDefaults(user);

  const [inputState, setInputState] = useState(initialValue);

  useEffect(() => {
    setAbout(user?.about);
    setAddressType(user?.type || "User");
    setInputState(setUserDefaults(user));
  }, [user, setUserDefaults]);

  const updateProfile = useCallback(async () => {
    try {
      const userProfile = {
        ...inputState,
        type: addressType,
        about,
        notificationPreferences: user?.notificationPreferences,
        address,
        uuid,
      };

      const { data } = await updateUserDetails({
        address: normalizedAddress,
        userData: userProfile,
        gnosisOwnerAddress: isGnosisOwner ? account : undefined,
        siweFunction,
      });

      updateUserState(data);
      trackUpdateProfileSettingsInProfileModal({
        userId: normalizedAddress,
      });
      onClose();
    } catch (e: any) {
      console.error(e);
      if (e?.message !== "SIWE Error") {
        notification.error({
          message: "Error",
          description: <Text>Something went wrong... Please try again later</Text>,
        });
      }
      throw new Error();
    }
  }, [
    about,
    account,
    address,
    addressType,
    inputState,
    isGnosisOwner,
    normalizedAddress,
    onClose,
    siweFunction,
    trackUpdateProfileSettingsInProfileModal,
    updateUserState,
    user?.notificationPreferences,
    uuid,
  ]);

  const { isLoading, isError, isSuccess, mutate, reset } = useMutation(updateProfile);

  const handleInputChange = (e: React.ChangeEvent<any>) => {
    const { name, value } = e.target;
    setInputState({ ...inputState, [name]: value });
  };

  const handleSubmit = () => {
    mutate();
  };

  const toggleMdEditorView = useCallback((checked: boolean) => {
    if (checked) {
      setPreview("preview");
    } else {
      setPreview("edit");
    }
  }, []);

  //toasts
  const success = useCallback(() => {
    Message.success("Your profile has been updated successfully.");
    reset();
  }, [reset]);

  const error = useCallback(() => {
    Message.error("Sorry, an error occurred. Please try again");
    reset();
  }, [reset]);

  const onUploadPfpError = (err: any) => {
    console.log("Error", err);
    Message.error("Sorry, an error occurred. Please try again");
  };

  const onUploadPfpSuccess = async (res: any) => {
    try {
      const userProfile = {
        ...user,
        email: user?.email || "",
        uuid,
        pfpUrl: res.url,
        pfpImageId: res.fileId,
        pfpThumbnailUrl: res.thumbnailUrl,
      };
      const { data } = await updateUserDetails({
        address: normalizedAddress,
        userData: userProfile,
        gnosisOwnerAddress: isGnosisOwner ? account : undefined,
        siweFunction,
      });
      updateUserState(data);
      Message.success({ content: "Successfully uploaded profile image", key: "savingPfp", duration: 1.5 });
      trackUpdateProfileSettingsInProfileModal({
        userId: normalizedAddress,
      });
    } catch (e: any) {
      console.error(e);
      if (e?.message !== "SIWE Error") {
        notification.error({
          message: "Error",
          description: <Text>Something went wrong... Please try again later</Text>,
        });
      }
      throw new Error();
    }
  };

  const triggerPfpInput = (): void => {
    const element = document.querySelector("#uploadPfp");
    if (element instanceof HTMLElement) {
      element?.click();
    }
  };

  const triggerBannerInput = (): void => {
    const element = document.querySelector("#uploadBanner");
    if (element instanceof HTMLElement) {
      element?.click();
    }
  };

  const onUploadBannerError = (err: any) => {
    console.log("Error", err);
    Message.error("Sorry, an error occurred. Please try again");
  };

  const onUploadBannerSuccess = useCallback(
    async (res: any) => {
      try {
        const userProfile = {
          ...user,
          email: user?.email || "",
          uuid,
          bannerUrl: res.url,
          bannerImageId: res.fileId,
          bannerThumbnailUrl: res.thumbnailUrl,
        };
        const { data } = await updateUserDetails({
          address: normalizedAddress,
          userData: userProfile,
          gnosisOwnerAddress: isGnosisOwner ? account : undefined,
          siweFunction,
        });
        updateUserState(data);
        Message.success({ content: "Successfully uploaded banner image", key: "savingBanner", duration: 1.5 });
        trackUpdateProfileSettingsInProfileModal({
          userId: normalizedAddress,
        });
      } catch (e: any) {
        console.error(e);
        if (e?.message !== "SIWE Error") {
          notification.error({
            message: "Error",
            description: <Text>Something went wrong... Please try again later</Text>,
          });
        }
        throw new Error();
      }
    },
    [
      account,
      isGnosisOwner,
      normalizedAddress,
      siweFunction,
      trackUpdateProfileSettingsInProfileModal,
      updateUserState,
      user,
      uuid,
    ],
  );

  const onchangeOfpfp = (e: React.ChangeEvent<any>) => {
    const file = e.target.files[0];
    if (file) {
      Message.loading({ content: "Uploading profile image..", key: "savingPfp", duration: 0 });
    }
  };

  const onchangeOfBanner = (e: React.ChangeEvent<any>) => {
    const file = e.target.files[0];
    if (file) {
      Message.loading({ content: "Uploading banner image..", key: "savingBanner", duration: 0 });
    }
  };

  const [activeTab, setActiveTab] = useState<"editProfile" | "delegateSettings">("editProfile");
  const handleTab = (value: "editProfile" | "delegateSettings"): void => {
    setActiveTab(value);
  };

  return (
    <>
      {isSuccess && success()}
      {isError && error()}
      <Modal open={open} onClose={onClose} size="large" withPadding={false}>
        <ModalHeader>
          <StyledTabWrapper>
            <Title active={activeTab === "editProfile"} onClick={() => handleTab("editProfile")}>
              Profile
            </Title>
            <Title active={activeTab === "delegateSettings"} onClick={() => handleTab("delegateSettings")}>
              Delegation
            </Title>
          </StyledTabWrapper>
          <StyledCloseIcon onClick={onClose} height={12} width={12} color={COLORS.primary.grayDarkLighter} />
        </ModalHeader>
        <Modal.Body>
          {activeTab === "editProfile" && (
            <>
              <HeaderWrapper>
                <StyledImg src={user?.bannerUrl || `${process.env.PUBLIC_URL}/assets/ProfileBanner3.png`} />
                <IKContext
                  publicKey={publicKey}
                  urlEndpoint={urlEndpoint}
                  authenticationEndpoint={`${authenticationEndpoint}&bypassCache=${Date.now()}`}
                >
                  <IKUpload
                    onError={onUploadBannerError}
                    onSuccess={onUploadBannerSuccess}
                    style={{ display: "none" }}
                    id="uploadBanner"
                    onChange={onchangeOfBanner}
                  />
                </IKContext>
                <IconWrapper onClick={triggerBannerInput}>
                  <StyledCameraIcon />
                </IconWrapper>
              </HeaderWrapper>

              <Row style={{ marginBottom: "96px" }} gutter={[8, 8]} justify="end">
                <Col span={24}>
                  <StyledDiv>
                    <ProfileImageWrapper>
                      <StyledProfileImg src={user?.pfpUrl || `${process.env.PUBLIC_URL}/assets/pfp.png`} />
                      <IconWrapper onClick={triggerPfpInput}>
                        <StyledCameraIcon />
                      </IconWrapper>
                    </ProfileImageWrapper>
                    <IKContext
                      publicKey={publicKey}
                      urlEndpoint={urlEndpoint}
                      authenticationEndpoint={`${authenticationEndpoint}&bypassCache=${Date.now()}`}
                    >
                      <IKUpload
                        onError={onUploadPfpError}
                        onSuccess={onUploadPfpSuccess}
                        style={{ display: "none" }}
                        id="uploadPfp"
                        onChange={onchangeOfpfp}
                      />
                    </IKContext>

                    <InputDiv>
                      <StyledLabel>Display Name</StyledLabel>
                      <StyledInput
                        placeholder="0x23....1234d"
                        value={inputState.username}
                        onChange={handleInputChange}
                        name="username"
                      />
                    </InputDiv>
                    <InputDiv>
                      <StyledLabel>ENS</StyledLabel>
                      <StyledInput
                        width="70%"
                        placeholder="Your ENS address"
                        value={inputState.ens}
                        onChange={handleInputChange}
                        name="ens"
                      />
                    </InputDiv>
                    <InputsContainer>
                      <div>
                        <Row gutter={[20, 8]} justify="end">
                          <Col span={24}>
                            <StyledMDEditor
                              placeholder="i.e. Bio, Services provided, Delegation pitch..."
                              height={150}
                              value={about}
                              onChange={(value) => setAbout(value || "")}
                              preview={preview}
                            />
                            <StyledAbout>About</StyledAbout>
                            <StyledPreviewWrapper>
                              <StyledSwitch defaultChecked={false} onChange={toggleMdEditorView} />
                              <StyledPreviewLabel>{preview === "edit" ? "Preview" : "Edit"}</StyledPreviewLabel>
                            </StyledPreviewWrapper>
                          </Col>
                        </Row>
                      </div>
                    </InputsContainer>
                    <InputDiv>
                      <StyledLabel>Twitter</StyledLabel>
                      <Flex>
                        <StyledSpan>@</StyledSpan>
                        <StyledInput
                          width="90%"
                          placeholder="Your Twitter Handle"
                          value={inputState.twitter}
                          onChange={handleInputChange}
                          name="twitter"
                        />
                      </Flex>
                    </InputDiv>
                    <InputDiv>
                      <StyledLabel>Website</StyledLabel>
                      <StyledInput
                        placeholder="Your link here"
                        name="website"
                        value={inputState.website}
                        onChange={handleInputChange}
                      />
                    </InputDiv>
                    <StyledHeading>
                      <StyledHeadingText size="18px" color={COLORS.primary.grayDark}>
                        Connect Your Email
                      </StyledHeadingText>
                      <StyledHeadingText size="16px" color={COLORS.primary.grayDarkLightest}>
                        Receive notifications from Boardroom
                      </StyledHeadingText>
                    </StyledHeading>

                    <InputDiv>
                      <StyledLabel>Email</StyledLabel>
                      <StyledInput
                        width="80%"
                        placeholder="email@address.com"
                        name="email"
                        value={inputState.email}
                        onChange={handleInputChange}
                      />
                    </InputDiv>
                  </StyledDiv>
                </Col>
              </Row>
              <ButtonGroup>
                <StyledCancelButton onClick={onClose}>Cancel</StyledCancelButton>
                <StyledSaveButton onClick={handleSubmit}>
                  {isLoading ? <Spin indicator={antIcon} /> : "Save"}
                </StyledSaveButton>
              </ButtonGroup>
            </>
          )}
          {activeTab === "delegateSettings" && <DelegateSettings buttonWidthFull user={user} onClose={onClose} />}
        </Modal.Body>
      </Modal>
    </>
  );
};

function EditProfileModal(props: Props) {
  return (
    <Suspense fallback={<></>}>
      <EditProfile {...props} />
    </Suspense>
  );
}
export default EditProfileModal;
