import * as React from "react";

const SvgLogOutIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 17a1 1 0 00-1-1H2V2h5a1 1 0 000-2H2a2 2 0 00-2 2v14a2 2 0 002 2h5a1 1 0 001-1z"
      fill="currentColor"
    />
    <path
      d="M18.714 9.7A.996.996 0 0019 9.004v-.006a.997.997 0 00-.293-.704l-4-4a1 1 0 10-1.414 1.414L15.586 8H6a1 1 0 100 2h9.586l-2.293 2.293a1 1 0 001.414 1.414l4-4 .007-.006z"
      fill="currentColor"
    />
  </svg>
);

export default SvgLogOutIcon;
