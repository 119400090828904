import styled, { css } from "styled-components";

export const Title = styled.h2`
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #191540;
  padding: 48px 20px 16px;
  border-bottom: 1px solid #f0eff8;
  text-align: center;
  position: sticky;
  top: 0;
  background: #fafafa;
  z-index: 1;
`;

export const ProtocolWrapper = styled.div<{ $isSelected?: boolean }>`
  display: flex;
  align-items: center;
  padding: 14px 18px;
  text-align: center;
  cursor: pointer;

  h3 {
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #7b7893;
  }
  :hover {
    h3 {
      font-weight: 600;
      color: #4235e1;
    }
  }
  &:active,
  focus {
    h3 {
      font-weight: 600;
      color: #291fa7;
    }
  }
  ${({ $isSelected }) =>
    $isSelected &&
    css`
      background: rgba(123, 120, 147, 0.05);
      mix-blend-mode: multiply;
      h3 {
        color: #191540 !important;
      }
    `}
  span {
    flex-shrink: 0;
  }
`;

export const Circle = styled.div`
  width: 8px;
  height: 8px;
  background: #4235e1;
  border-radius: 50%;
  margin-left: -4px;
  top: 25px;
  position: absolute;
`;

export const WrapperProtocolAndCircle = styled.div`
  position: relative;
`;

export const ProtocolName = styled.h3`
  margin-bottom: 0;
  margin-left: 8px;
  text-align: left;
`;

export const ProtocolsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-right: 6px;
  margin-bottom: 40px;
`;

export const PostButton = styled.button`
  background: #191540;
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: white;
  text-align: center;
  padding: 12px 0;
  width: 176px;
  border: none;
  margin 72px auto 0;
  cursor: pointer;
  display: block;
  transition: 0.3s all;
  :hover {
    background: #4235e1;
  }
  :active, :focus {
    background: #291fa7;
  }
`;

export const PostButtonWrapper = styled.div`
  position: sticky;
  bottom: 0;
  background: #f6f6f6;
  padding: 0 0 24px;
`;

export const DropdownWrapper = styled("div")`
  ul {
    width: 100%;
  }
  border-bottom: 1px solid #f0eff8;
  padding-bottom: 16px;
  position: fixed;
  width: 216px;
  background: #f6f6f6;
  z-index: 2;
`;

export const FilterByText = styled.span`
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.02em;
  color: #7b7893;
  margin-bottom: 8px;
  display: block;
  margin-left: 4px;
  padding-top: 12px;
`;

export const ProtocolsSectionTitle = styled.span`
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.2px;
  text-transform: capitalize;
  color: #191540;
  padding-top: 8px;
  margin: 8px 16px;
  border-top: 1px solid #f0eff8;
`;
