import React, { Suspense, useMemo } from "react";
import styled from "styled-components";
import { ErrorBoundary } from "react-error-boundary";

import media from "../../media-query";
import { COLORS } from "../../constants/colors";
import { ProposalTimeLineItem } from "../ProposalTimeLineItem";
import { ProposalsListSkeleton } from "../ProposalsListing/ProposalsListSkeleton";
import { useUserProposals } from "../../hooks/useUserProposals";
import { protocols } from "../../constants/protocols";

const SeeMoreText = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  text-align: right;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: ${COLORS.primary.accent};
  margin-top: 24px;
  cursor: pointer;
  ${media.lessThan("large")`
    text-align: center;
  `}
`;

const StyledDiv = styled.div`
  text-align: center;
  margin-bottom: 160px;
`;

const StyledTitle = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: ${COLORS.secondary.purple};
`;

const StyledImg = styled("img")`
  margin: 32px 0;
`;

const EmptyStateText = styled.div`
  font-size: 16px;
  line-height: 20px;
  color: ${COLORS.primary.grayDarkLightest};
  margin: 24px 0 32px;
`;

interface Props {
  proposer?: string | Array<string>;
  addressFilter?: string;
}

export const EmptyProposal = ({
  title = "No Proposals Found",
  description = "There was an error loading proposals. If you have created a proposal, it will show up in your history here.",
}: {
  title?: string;
  description?: string;
}) => {
  return (
    <>
      <StyledDiv>
        <StyledImg src={`${process.env.PUBLIC_URL}/assets/YourProposalsEmptyState.png`} />
        <StyledTitle>{title}</StyledTitle>
        <EmptyStateText>{description}</EmptyStateText>
      </StyledDiv>
    </>
  );
};

const YourProposals = ({ proposer, addressFilter }: Props) => {
  const fetchOptions = useMemo(() => {
    if (typeof proposer === "string") {
      return { proposer };
    }

    if (addressFilter !== "allMembers" && addressFilter) {
      return { proposer: addressFilter };
    }

    if (proposer?.length === 1) {
      return { proposer: proposer[0] };
    }

    return { proposers: proposer?.join(",") };
  }, [proposer, addressFilter]);

  const { proposals, fetchNextPage, hasNextPage } = useUserProposals(fetchOptions);

  const EnabledProposals = proposals?.filter((proposal) => protocols[proposal.protocol]?.isEnabled);

  return (
    <>
      {(proposals?.length === 0 || EnabledProposals?.length === 0) && <EmptyProposal />}

      {EnabledProposals && EnabledProposals.length > 0 && (
        <>
          {EnabledProposals.map((proposal) => (
            <ProposalTimeLineItem renderStartDate proposal={proposal} key={proposal.refId} />
          ))}
        </>
      )}
      {hasNextPage && <SeeMoreText onClick={() => fetchNextPage()}>see more</SeeMoreText>}
    </>
  );
};

export default (props: Props) => (
  <ErrorBoundary fallback={<EmptyProposal />}>
    <Suspense fallback={<ProposalsListSkeleton />}>
      <YourProposals {...props} />
    </Suspense>
  </ErrorBoundary>
);
