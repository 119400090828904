import Col from "antd/es/col";
import Empty from "antd/es/empty";
import Row from "antd/es/row";
import React, { ForwardedRef, forwardRef, Suspense, useMemo } from "react";
import styled from "styled-components";
import InfiniteScroll from "react-infinite-scroller";
import ContentLoader from "react-content-loader";
import { VoteDetails } from "@boardroom/boardroom-api";
import { ErrorBoundary } from "react-error-boundary";

import { ListWrapper } from "../../components/List";
import { useVoterVotesForMultipleAddresses } from "../../hooks/useVoterVotes";
import { COLORS } from "../../constants/colors";
import { getOnchainProposalVoteAdapterName, protocols } from "../../constants/protocols";
import StatusTag from "../../components/StatusTag";
import { CheckCircleIcon, CrossCircleIcon, HalfCircleCheckIcon } from "../icons";
import { ProposalListItem } from "../ProposalListItem";
import formatValue from "../../utils/formatValue";
import { useMixpanel } from "../../hooks";
import { Paragraph } from "../Typography";
import { PowerIcon, SnapshotIcon, OnchainIcon } from "../icons";
import media from "../../media-query";
import { displayVotedChoice } from "../Proposals/VotedChoiceFormatting";
import { ProposalType } from "../../types";
import { useAdapterFramework } from "../../hooks/useAdapterFramework";
import SvgAbstainIcon from "../icons/AbstainIcon";

const StyledVoteInfo = styled("span")`
  margin-left: 4px;
  color: ${COLORS.primary.grayDarkLight};
  font-size: 14px;
  line-height: 16px;
  display: inline-block;
`;

const StyledVoteInfoWrapper = styled("div")`
  display: flex;
  align-items: center;
  margin-top: 12px;
`;

const StyledTitle = styled(Paragraph)`
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  margin-top: 8px;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const StyledPowerIcon = styled(PowerIcon)`
  margin-right: 4px;
  vertical-align: bottom;
`;

const StyledListWrapper = styled(ListWrapper)`
  border-top: none;
`;

const StyledTag = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  margin-left: auto;
  ${media.lessThan("767px")`
    top: 70px;
  `}
`;

const StyledSpan = styled.span`
  display: flex;
  ${media.lessThan("640px")`
    display: none;
  `}
`;

interface Props {
  protocol?: string;
  address?: string;
  teamWallets?: string[];
}

function Vote({ vote }: { vote: VoteDetails }) {
  const { trackClickVoteHistoryItem } = useMixpanel();
  const protocol = protocols[vote.protocol];
  const { adapterFramework } = useAdapterFramework(protocol?.cname);
  const choice = displayVotedChoice(
    vote.proposalInfo?.type as ProposalType,
    vote.choices || vote.choice,
    vote.proposalInfo?.choices as string[],
    vote.adapter,
    adapterFramework,
  );

  if (!protocol?.isEnabled) {
    return null;
  }

  return (
    <ProposalListItem
      protocol={protocol.cname}
      refId={vote.proposalRefId}
      key={vote.proposalRefId}
      adapter={vote.adapter}
      onClick={() => {
        trackClickVoteHistoryItem({
          userId: vote.address,
          protocol: protocol.cname,
          proposalRefId: vote.proposalRefId,
        });
      }}
    >
      <Row justify="space-between" align="middle" gutter={[24, { xs: 12, sm: 12, md: 24 }]}>
        <Col xs={24} sm={24} md={14} lg={24} xl={18}>
          <ProposalListItem.HeaderWithoutTag>
            {`Voted on ${new Date(Number(vote.timestamp) * 1000).toDateString()}`} &#8231;&nbsp;
            <StyledPowerIcon color={COLORS.primary.grayDarkLightest} height="16" width="16" />
            {`${formatValue(vote.power)}`}
            {vote.adapter === "snapshot" || (vote.adapter === "default" && adapterFramework === "snapshot") ? (
              <StyledSpan>
                <i>&nbsp;&#183;&nbsp;</i>
                <SnapshotIcon width={16} height={16} />
                &nbsp;<p style={{ margin: "auto 0" }}>Offchain</p>
              </StyledSpan>
            ) : (
              <StyledSpan>
                <i>&nbsp;&#183;&nbsp;</i>
                <OnchainIcon width={16} height={16} color={COLORS.secondary.blue} />
                &nbsp;
                <p style={{ margin: "auto 0" }}>{getOnchainProposalVoteAdapterName(vote.protocol, vote.adapter)}</p>
              </StyledSpan>
            )}
          </ProposalListItem.HeaderWithoutTag>
          <ProposalListItem.Description>
            <StyledTitle>{vote.proposalInfo?.title && vote.proposalInfo?.title}</StyledTitle>
          </ProposalListItem.Description>
          <StyledVoteInfoWrapper>
            {choice?.toLowerCase().includes("for") ||
            choice?.toLowerCase().includes("yay") ||
            choice?.toLowerCase().includes("yes") ||
            choice?.toLowerCase().includes("yae") ||
            // gets both yea and yeah
            choice?.toLowerCase().includes("yea") ||
            choice?.toLowerCase().includes("approve") ||
            // gets both in favor and in favour
            choice?.toLowerCase().includes("in favo") ||
            choice?.toLowerCase().includes("support") ||
            choice?.toLowerCase().includes("abstain") ||
            choice?.toLowerCase().includes("against") ||
            choice?.toLowerCase().includes("nay") ||
            choice?.toLowerCase().includes("no") ? (
              <>
                {(choice?.toLowerCase().includes("for") ||
                  choice?.toLowerCase().includes("yay") ||
                  choice?.toLowerCase().includes("yes") ||
                  choice?.toLowerCase().includes("yae") ||
                  choice?.toLowerCase().includes("yea") ||
                  choice?.toLowerCase().includes("approve") ||
                  choice?.toLowerCase().includes("in favo") ||
                  choice?.toLowerCase().includes("support")) && (
                  <CheckCircleIcon height="12" width="12" color={COLORS.secondary.green} />
                )}
                {(choice.toLowerCase().includes("against") ||
                  choice?.toLowerCase().includes("nay") ||
                  choice?.toLowerCase().includes("no")) && (
                  <CrossCircleIcon height="12" width="12" color={COLORS.secondary.red} />
                )}
                {choice.toLowerCase().includes("abstain") && <SvgAbstainIcon height="18" width="18" />}
                <StyledVoteInfo>{choice}</StyledVoteInfo>
              </>
            ) : (
              <>
                <HalfCircleCheckIcon color={COLORS.secondary.green} height="12" width="12" />
                <StyledVoteInfo>{choice}</StyledVoteInfo>
              </>
            )}
          </StyledVoteInfoWrapper>
        </Col>
        <StyledTag>
          {vote.proposalInfo?.currentState ? <StatusTag status={vote.proposalInfo?.currentState} /> : null}
        </StyledTag>
      </Row>
    </ProposalListItem>
  );
}

function VoteHistory({ protocol, address, teamWallets }: Props, ref: any) {
  const allAddresses = useMemo(() => {
    if (teamWallets) {
      return teamWallets;
    }
    return address ? [address] : [];
  }, [teamWallets, address]);

  const { votes, hasNextPage, fetchNextPage } = useVoterVotesForMultipleAddresses({
    addresses: allAddresses,
    protocol,
    limit: 50,
  });

  if (!votes?.length) {
    return (
      <Empty
        style={{ color: "black" }}
        description="This account hasn't cast any votes yet (for supported protocols)."
      ></Empty>
    );
  }

  if (ref) {
    return (
      <InfiniteScroll
        loadMore={() => fetchNextPage()}
        hasMore={hasNextPage}
        initialLoad={false}
        threshold={2000}
        getScrollParent={() => ref?.current}
        useWindow={false}
      >
        <StyledListWrapper
          dataSource={votes}
          pagination={false}
          rowKey={(vote: any) => vote?.refId}
          renderItem={(vote) => <Vote vote={vote as VoteDetails} />}
        />
      </InfiniteScroll>
    );
  }

  return <></>;
}

const RefForwordedVoteHistory = forwardRef(VoteHistory);

function VoteHistoryWrapper(props: Props, ref: ForwardedRef<HTMLDivElement>) {
  return (
    <Suspense
      fallback={
        <ContentLoader
          speed={2}
          width="100%"
          height={375}
          backgroundColor={COLORS.primary.grayLight}
          foregroundColor={COLORS.primary.grayLighter}
        >
          <circle cx="30" cy="38" r="20" />
          <rect x="60" y="23" rx="5" ry="5" width="50%" height="12" />
          <rect x="60" y="43" rx="5" ry="5" width="70%" height="12" />

          <circle cx="30" cy="114" r="20" />
          <rect x="60" y="99" rx="5" ry="5" width="50%" height="12" />
          <rect x="60" y="119" rx="5" ry="5" width="70%" height="12" />

          <circle cx="30" cy="190" r="20" />
          <rect x="60" y="175" rx="5" ry="5" width="50%" height="12" />
          <rect x="60" y="195" rx="5" ry="5" width="70%" height="12" />

          <circle cx="30" cy="266" r="20" />
          <rect x="60" y="251" rx="5" ry="5" width="50%" height="12" />
          <rect x="60" y="271" rx="5" ry="5" width="70%" height="12" />

          <circle cx="30" cy="342" r="20" />
          <rect x="60" y="327" rx="5" ry="5" width="50%" height="12" />
          <rect x="60" y="347" rx="5" ry="5" width="70%" height="12" />
        </ContentLoader>
      }
    >
      <RefForwordedVoteHistory {...props} ref={ref} />
    </Suspense>
  );
}

const RefForwordedWrapper = forwardRef(VoteHistoryWrapper);

function VoteHistoryErrorBoundary(props: Props, ref: ForwardedRef<HTMLDivElement>) {
  const { protocol } = props;

  return (
    <ErrorBoundary fallback={<></>} resetKeys={[protocol]}>
      <RefForwordedWrapper {...props} ref={ref} />
    </ErrorBoundary>
  );
}

export default React.memo(forwardRef(VoteHistoryErrorBoundary));
