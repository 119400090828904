import { createGlobalStyle, css } from "styled-components";
import { LinkStyles } from "../components/Typography/Link";
import { H2Styles, H3Styles, H4Styles, H5Styles, H6Styles } from "../components/Typography/Header";
import { ParagraphStyles } from "../components/Typography/Paragraph";
import { TextStyles } from "../components/Typography/Text";
import { fontFamily } from "../constants/typography";
import { COLORS } from "../constants/colors";

const UnorderedListStyles = css`
  color: ${COLORS.primary.grayDarkLightest};
  font-size: 14px;
  line-height: 18px;
`;

const OrderedListStyles = css`
  color: ${COLORS.primary.grayDarkLightest};
  font-size: 14px;
  line-height: 18px;
`;

export const MarkdownStyle = createGlobalStyle`
  body .markdown-body {
    padding-top: 10px;
    font-family: ${fontFamily};
  }

  body .markdown-body th,
  body .markdown-body td {
    ${TextStyles}
  }

  body .markdown-body a {
    ${LinkStyles}
  }

  body .markdown-body h1 {
    ${H2Styles}
  }

  body .markdown-body h2 {
    ${H3Styles}
  }

  body .markdown-body h3 {
    ${H4Styles}
  }

  body .markdown-body h4 {
    ${H5Styles}
  }

  body .markdown-body h5,
  body .markdown-body h6 {
    ${H6Styles}
  }

  body .markdown-body p {
    ${ParagraphStyles}
  }

  body .markdown-body ol li {
    ${OrderedListStyles}
  }

  body .markdown-body ul li {
    ${UnorderedListStyles}
  }
  
  body .markdown-body img {
    width: 100%;
    width: -moz-available;
    width: -webkit-fill-available;
    width: fill-available;
  }
`;
