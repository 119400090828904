import React, { useContext, useState, createContext, SetStateAction } from "react";

type TxnHashObj = {
  isSnapshotProposal: boolean;
  txnHash: string;
};

interface Props {
  children: React.ReactNode;
}

interface VoteTxnHashType {
  voteTxnHash: TxnHashObj;
  setVoteTxnHash: React.Dispatch<SetStateAction<TxnHashObj>>;
}

export const VoteTxnHashContext = createContext({} as VoteTxnHashType);

export const VoteTxnHashProvider = ({ children }: Props) => {
  const [voteTxnHash, setVoteTxnHash] = useState<TxnHashObj>({} as TxnHashObj);
  return <VoteTxnHashContext.Provider value={{ voteTxnHash, setVoteTxnHash }}>{children}</VoteTxnHashContext.Provider>;
};

export const useVoteTxnHash = () => {
  const { voteTxnHash, setVoteTxnHash } = useContext(VoteTxnHashContext);
  return { voteTxnHash, setVoteTxnHash };
};
