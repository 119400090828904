import { nth } from "../../utils/ordinalSuffix";
import { ProposalType } from "../../types";

export const displayVotedChoice = (
  type: ProposalType,
  votedChoices: any,
  proposalChoices: string[],
  proposalAdapter?: string,
  adapterFramework?: string,
) => {
  if (!proposalChoices.length || votedChoices === null || votedChoices === undefined) {
    return "";
  }
  const isSnapshotProposal =
    proposalAdapter === "snapshot" ||
    (proposalAdapter === "default" && adapterFramework === "snapshot") ||
    (proposalAdapter === "archive" && adapterFramework === "snapshot");
  if (type === "basic" || type === "single-choice" || type === null) {
    if (typeof votedChoices !== "number") {
      if (isSnapshotProposal) {
        return proposalChoices[Number(votedChoices[0]) - 1];
      } else {
        return proposalChoices[Number(votedChoices?.[0])];
      }
    } else {
      if (isSnapshotProposal) {
        return proposalChoices[Number(votedChoices) - 1];
      } else {
        return proposalChoices[Number(votedChoices)];
      }
    }
  }
  if (type === "weighted" || type === "quadratic") {
    let weightedResult = "";

    const getPercentage = (value: number) => {
      const total: any = Object.values(votedChoices).reduce((a: any, b: any) => a + b, 0);
      const percent = (value / total) * 100;
      return Number.isInteger(percent) ? percent : percent.toFixed(1);
    };

    if (typeof votedChoices !== "number") {
      for (const key in votedChoices) {
        const last = Object.keys(votedChoices)[Object.keys(votedChoices).length - 1];
        if (key === last) {
          if (isSnapshotProposal) {
            weightedResult += `${getPercentage(votedChoices[key])}% for ${proposalChoices[Number(key) - 1]}`;
          } else {
            weightedResult += `${getPercentage(votedChoices[key])}% for ${proposalChoices[Number(key)]}`;
          }
        } else {
          if (isSnapshotProposal) {
            weightedResult += `${getPercentage(votedChoices[key])}% for ${proposalChoices[Number(key) - 1]}, `;
          } else {
            weightedResult += `${getPercentage(votedChoices[key])}% for ${proposalChoices[Number(key)]}, `;
          }
        }
      }
    } else {
      if (isSnapshotProposal) {
        weightedResult = `$100% for ${proposalChoices[Number(votedChoices) - 1]}`;
      } else {
        weightedResult = `$100% for ${proposalChoices[Number(votedChoices)]}`;
      }
    }

    return weightedResult;
  }

  if (type === "approval" || type === "approvalVoting") {
    let approvalResult = "";
    if (typeof votedChoices !== "number") {
      for (const item of votedChoices) {
        const last = votedChoices[votedChoices.length - 1];
        if (last === item) {
          if (isSnapshotProposal) {
            approvalResult += `${proposalChoices[Number(item) - 1]}`;
          } else {
            approvalResult += `${proposalChoices[Number(item)]}`;
          }
        } else {
          if (isSnapshotProposal) {
            approvalResult += `${proposalChoices[Number(item) - 1]}, `;
          } else {
            approvalResult += `${proposalChoices[Number(item)]}, `;
          }
        }
      }
    } else {
      if (isSnapshotProposal) {
        approvalResult = `${proposalChoices[Number(votedChoices) - 1]}`;
      } else {
        approvalResult = `${proposalChoices[Number(votedChoices)]}`;
      }
    }
    return approvalResult;
  }

  if (type === "ranked-choice") {
    let rankedResult = "";
    if (typeof votedChoices !== "number") {
      for (const item of votedChoices) {
        const last = votedChoices[votedChoices.length - 1];
        if (item === last) {
          if (isSnapshotProposal) {
            rankedResult += `(${votedChoices.indexOf(item) + 1}${nth(votedChoices.indexOf(item) + 1)}) ${
              proposalChoices[Number(item) - 1]
            }`;
          } else {
            rankedResult += `(${votedChoices.indexOf(item) + 1}${nth(votedChoices.indexOf(item) + 1)}) ${
              proposalChoices[Number(item)]
            }`;
          }
        } else {
          if (isSnapshotProposal) {
            rankedResult += `(${votedChoices.indexOf(item) + 1}${nth(votedChoices.indexOf(item) + 1)}) ${
              proposalChoices[Number(item) - 1]
            }, `;
          } else {
            rankedResult += `(${votedChoices.indexOf(item) + 1}${nth(votedChoices.indexOf(item) + 1)}) ${
              proposalChoices[Number(item)]
            }, `;
          }
        }
      }
    } else {
      if (isSnapshotProposal) {
        rankedResult = `(1st) ${proposalChoices[Number(votedChoices) - 1]}`;
      } else {
        rankedResult = `(1st) ${proposalChoices[Number(votedChoices)]}`;
      }
    }
    return rankedResult;
  }
};
