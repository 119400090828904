import React, { useReducer, createContext } from "react";

interface Props {
  children: React.ReactNode;
}

type Action = { type: "SAVE_DISMISSED_DISCUSSIONS"; data: string };

interface DismissedDiscussionsContextType {
  dismissedDiscussions: Map<string, boolean>;
  dispatchDismissedDiscussions: React.Dispatch<any>;
}

const initialState: Map<string, boolean> = new Map();

export const DismissedDiscussionsContext = createContext({} as DismissedDiscussionsContextType);

const reducer = (state: Map<string, boolean>, action: Action) => {
  switch (action.type) {
    case "SAVE_DISMISSED_DISCUSSIONS": {
      return state.set(action.data, true);
    }
    default:
      return state;
  }
};

export const DismissedDiscussionsProvider = (props: Props) => {
  const [dismissedDiscussions, dispatchDismissedDiscussions] = useReducer(reducer, initialState);

  return (
    <DismissedDiscussionsContext.Provider value={{ dismissedDiscussions, dispatchDismissedDiscussions }}>
      {props.children}
    </DismissedDiscussionsContext.Provider>
  );
};
