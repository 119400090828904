import { useQuery } from "@tanstack/react-query";
import { GetProtocolResponse } from "@boardroom/boardroom-api";
import { protocolInfoList } from "@boardroom/protocol-info";
import { apiClient } from "../utils/apiClient";
import { ProtocolDescription } from "../types";

interface Props {
  protocol: string;
  suspense?: boolean;
}

export const useProtocol = ({ protocol, suspense = true }: Props) => {
  const { data: protocolDetails } = useQuery<GetProtocolResponse, Error>([`protocol:${protocol}`], () => apiClient.getProtocolDetails(protocol), {
    enabled: !!protocol,
    suspense,
  });

  const protocolInfo = protocolInfoList.find((protocol) => protocol.cname === protocolDetails?.data.cname);

  if (!protocolDetails?.data) {
    return null;
  }

  // TEMP - Needed until we cut over from old SDK, which is still needed for mutations
  const data = Object.assign(protocolDetails?.data, protocolInfo, {
    order: 0,
  }) as ProtocolDescription;

  return data;
};
