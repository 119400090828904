import { useQuery } from "@tanstack/react-query";
import { APIResponse } from "@boardroom/boardroom-api";
import axios from "axios";
import { BOARDROOM_API_KEY, baseAPIUrl } from "../constants/general";
import { useMemo } from "react";

export interface TeamSubscriptionsMembers {
  admin_id: string;
  team_members: Array<{
    name: string;
    address: string;
  }>;
}

export type TeamSubscriptionsMembersResponse = APIResponse<TeamSubscriptionsMembers>;

interface Props {
  address: string;
  uuid: string;
  isTeamAdmin?: boolean;
}

export const useGetTeamMembers = ({ address, uuid, isTeamAdmin = true }: Props) => {
  const { data: teamMembers } = useQuery<TeamSubscriptionsMembersResponse, Error>(
    [`teamMembers:${address}:${uuid}`],
    async () => {
      try {
        return (
          await axios.get(`${baseAPIUrl}getTeamSubscriptionMembers/${address}?uuid=${uuid}&key=${BOARDROOM_API_KEY}`)
        )?.data;
      } catch (_) {
        return { data: [] };
      }
    },
    {
      enabled: !!uuid && !!address && isTeamAdmin,
    },
  );

  return useMemo(() => teamMembers?.data, [teamMembers]);
};
