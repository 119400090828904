import React, { useReducer, createContext } from "react";
import Cookies from "universal-cookie";
import Web3Utils from "web3-utils";

import { getUuid } from "../utils/getUuid";

interface Props {
  children: React.ReactNode;
}

type Action = { type: "SAVE_UUID"; data: string };

interface CurrentUuidContextType {
  uuid: string;
  dispatchUuid: React.Dispatch<any>;
}

const cookies = new Cookies();
const initialAddress: string = cookies.get("lastUsedAddressForUUID");

const parsedInitialAddress: string = initialAddress !== undefined ? Web3Utils.toChecksumAddress(initialAddress) : "";

const initialState = getUuid(parsedInitialAddress) || "";

export const CurrentUuidContext = createContext({} as CurrentUuidContextType);

const reducer = (state: string, action: Action) => {
  switch (action.type) {
    case "SAVE_UUID":
      return action.data;
    default:
      return state;
  }
};

export const CurrentUuidProvider = (props: Props) => {
  const [uuid, dispatchUuid] = useReducer(reducer, initialState);

  return <CurrentUuidContext.Provider value={{ uuid, dispatchUuid }}>{props.children}</CurrentUuidContext.Provider>;
};
