import * as React from "react";

const SvgCopyIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M13 21H3a1.934 1.934 0 01-2-2V9a1.934 1.934 0 012-2h4V3a1.934 1.934 0 012-2h10a1.934 1.934 0 012 2v10a1.935 1.935 0 01-2 2h-4v4a1.935 1.935 0 01-2 2zM3 9v10h10v-4H9a1.935 1.935 0 01-2-2V9H3zm6-6v10h10V3H9z"
      fill="currentColor"
    />
  </svg>
);

export default SvgCopyIcon;
