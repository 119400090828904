import { useContext } from "react";
import { useQuery } from "@tanstack/react-query";
import { JsonRpcProvider } from "@ethersproject/providers";
import { CurrentWeb3Context } from "../reducers/CurrentWeb3Provider";

export const useCurrentBlock = () => {
  const { web3Instance } = useContext(CurrentWeb3Context);

  const { data = 0 } = useQuery<number, Error>(["currentBlock"], async () => {
    if (!web3Instance) {
      const provider = new JsonRpcProvider(process.env.REACT_APP_RPC_URL_1);
      return provider.getBlockNumber();
    } else {
      return web3Instance.getBlockNumber();
    }
  });

  return data;
};

export default useCurrentBlock;
