import React, { useContext } from "react";
import styled from "styled-components";
import Col from "antd/es/col";
import Row from "antd/es/row";
import { useCurrentWidth } from "react-socks";

import media from "../../media-query";
import { ProfileBanner } from "../../components";
import ProfileAvatar from "../../components/VoterProfile/ProfileAvatar";
import { CurrentAccountContext } from "../../reducers/CurrentAccount";
import ContentLoader from "react-content-loader";
import { COLORS } from "../../constants/colors";

import { CloseIcon, StyledClosedText, StyledCloseProfileTrigger, StyledVoterOverlay, PageMax } from "./VoterProfile";
import { pageHeaderHeight } from "../../constants/styles";

const StyledYourDelegatesWrapper = styled.div`
  margin: 0 auto;
  width: 75%;
`;

const MainContentWrapper = styled.div`
  background-color: white;
  overflow-x: hidden;
  position: relative;
  box-shadow: 0px -4px 4px rgb(0 0 0 / 10%);
  ${media.greaterThan("991px")`
    height: calc(100vh - ${pageHeaderHeight});
    margin-right: -40px;
  `}
`;

const MainContent = ({ account, isMobile }: { account: string; isMobile: boolean }) => {
  return (
    <MainContentWrapper>
      <div>
        <ProfileBanner scrolledHeight={0} />
        <PageMax>
          <Col md={{ span: 20, push: 2 }} xs={{ span: 22, push: 1 }}>
            <Row gutter={[24, { xs: 12, sm: 12, md: 24 }]}>
              <Col md={2} xs={24}>
                <ProfileAvatar size={isMobile ? "small" : "big"} isDelegate={false} />
              </Col>
              <Col md={24}>
                <Row gutter={[16, 16]}>
                  <Col xl={{ span: 24, push: 1 }} lg={{ span: 24, push: 0 }} md={{ span: 24, push: 0 }}>
                    <Row justify="center" style={{ paddingBottom: "120px" }}>
                      {account && (
                        <>
                          <Col xl={14}>
                            <ContentLoader
                              speed={2}
                              width="100%"
                              height={450}
                              backgroundColor={COLORS.primary.grayLight}
                              foregroundColor={COLORS.primary.grayLighter}
                            >
                              <circle cx="30" cy="38" r="20" />
                              <rect x="60" y="23" rx="5" ry="5" width="50%" height="12" />
                              <rect x="60" y="43" rx="5" ry="5" width="70%" height="12" />

                              <circle cx="30" cy="114" r="20" />
                              <rect x="60" y="99" rx="5" ry="5" width="50%" height="12" />
                              <rect x="60" y="119" rx="5" ry="5" width="70%" height="12" />

                              <circle cx="30" cy="190" r="20" />
                              <rect x="60" y="175" rx="5" ry="5" width="50%" height="12" />
                              <rect x="60" y="195" rx="5" ry="5" width="70%" height="12" />

                              <circle cx="30" cy="266" r="20" />
                              <rect x="60" y="251" rx="5" ry="5" width="50%" height="12" />
                              <rect x="60" y="271" rx="5" ry="5" width="70%" height="12" />

                              <circle cx="30" cy="342" r="20" />
                              <rect x="60" y="327" rx="5" ry="5" width="50%" height="12" />
                              <rect x="60" y="347" rx="5" ry="5" width="70%" height="12" />

                              <circle cx="30" cy="418" r="20" />
                              <rect x="60" y="403" rx="5" ry="5" width="50%" height="12" />
                              <rect x="60" y="423" rx="5" ry="5" width="70%" height="12" />

                              <circle cx="30" cy="494" r="20" />
                              <rect x="60" y="479" rx="5" ry="5" width="50%" height="12" />
                              <rect x="60" y="499" rx="5" ry="5" width="70%" height="12" />
                            </ContentLoader>
                          </Col>

                          <Col xl={{ span: 10, push: 0 }} lg={{ span: 10, push: 0 }} md={{ span: 10, push: 0 }}>
                            <StyledYourDelegatesWrapper>
                              {/* TD: skeleton for your delegate card and vp card */}
                              <ContentLoader
                                speed={2}
                                width="100%"
                                height={450}
                                backgroundColor={COLORS.primary.grayLight}
                                foregroundColor={COLORS.primary.grayLighter}
                              >
                                <circle cx="30" cy="38" r="20" />
                                <rect x="60" y="23" rx="5" ry="5" width="50%" height="12" />
                                <rect x="60" y="43" rx="5" ry="5" width="70%" height="12" />

                                <circle cx="30" cy="114" r="20" />
                                <rect x="60" y="99" rx="5" ry="5" width="50%" height="12" />
                                <rect x="60" y="119" rx="5" ry="5" width="70%" height="12" />

                                <circle cx="30" cy="190" r="20" />
                                <rect x="60" y="175" rx="5" ry="5" width="50%" height="12" />
                                <rect x="60" y="195" rx="5" ry="5" width="70%" height="12" />

                                <circle cx="30" cy="266" r="20" />
                                <rect x="60" y="251" rx="5" ry="5" width="50%" height="12" />
                                <rect x="60" y="271" rx="5" ry="5" width="70%" height="12" />

                                <circle cx="30" cy="342" r="20" />
                                <rect x="60" y="327" rx="5" ry="5" width="50%" height="12" />
                                <rect x="60" y="347" rx="5" ry="5" width="70%" height="12" />
                              </ContentLoader>
                            </StyledYourDelegatesWrapper>
                          </Col>
                        </>
                      )}
                      {!account && (
                        <Col span={22}>
                          <ContentLoader
                            speed={2}
                            width="100%"
                            height={450}
                            backgroundColor={COLORS.primary.grayLight}
                            foregroundColor={COLORS.primary.grayLighter}
                          >
                            <circle cx="30" cy="38" r="20" />
                            <rect x="60" y="23" rx="5" ry="5" width="50%" height="12" />
                            <rect x="60" y="43" rx="5" ry="5" width="70%" height="12" />

                            <circle cx="30" cy="114" r="20" />
                            <rect x="60" y="99" rx="5" ry="5" width="50%" height="12" />
                            <rect x="60" y="119" rx="5" ry="5" width="70%" height="12" />

                            <circle cx="30" cy="190" r="20" />
                            <rect x="60" y="175" rx="5" ry="5" width="50%" height="12" />
                            <rect x="60" y="195" rx="5" ry="5" width="70%" height="12" />

                            <circle cx="30" cy="266" r="20" />
                            <rect x="60" y="251" rx="5" ry="5" width="50%" height="12" />
                            <rect x="60" y="271" rx="5" ry="5" width="70%" height="12" />

                            <circle cx="30" cy="342" r="20" />
                            <rect x="60" y="327" rx="5" ry="5" width="50%" height="12" />
                            <rect x="60" y="347" rx="5" ry="5" width="70%" height="12" />
                          </ContentLoader>
                        </Col>
                      )}
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </PageMax>
      </div>
    </MainContentWrapper>
  );
};

export default () => {
  const isMobile = useCurrentWidth() < 991;
  const { account } = useContext(CurrentAccountContext);

  if (isMobile) {
    return <MainContent account={account} isMobile={isMobile} />;
  }

  return (
    <StyledVoterOverlay>
      <StyledCloseProfileTrigger>
        <StyledClosedText>Exit Profile</StyledClosedText>
        <CloseIcon height="16px" width="16px" color="#fff" />
      </StyledCloseProfileTrigger>
      <MainContent account={account} isMobile={isMobile} />
    </StyledVoterOverlay>
  );
};
