import { Web3Provider } from "@ethersproject/providers";
import React, { useReducer, createContext } from "react";

interface Props {
  children: React.ReactNode;
}

type Action = { type: "SAVE_WEB3"; data: Web3Provider | null };

export interface CustomWeb3Provider extends Web3Provider {
  provider: {
    isMetaMask?: boolean;
    isWalletConnect?: boolean;
    wc?: any;
    isStatus?: boolean;
    host?: string;
    path?: string;
    sendAsync?: (
      request: { method: string; params?: Array<any> },
      callback: (error: any, response: any) => void,
    ) => void;
    send?: (request: { method: string; params?: Array<any> }, callback: (error: any, response: any) => void) => void;
    request?: (request: { method: string; params?: Array<any> }) => Promise<any>;
  };
}

interface CurrentWeb3ContextType {
  web3Instance: CustomWeb3Provider | null;
  dispatchWeb3: React.Dispatch<any>;
}

const initialState = null;

export const CurrentWeb3Context = createContext({} as CurrentWeb3ContextType);

const reducer = (state: Web3Provider | null, action: Action) => {
  switch (action.type) {
    case "SAVE_WEB3":
      return action.data;
    default:
      return state;
  }
};

export const CurrentWeb3Provider = (props: Props) => {
  const [web3Instance, dispatchWeb3] = useReducer(reducer, initialState);

  return (
    <CurrentWeb3Context.Provider value={{ web3Instance, dispatchWeb3 }}>{props.children}</CurrentWeb3Context.Provider>
  );
};
