import { useWallets } from "@web3-onboard/react";
import { useContext } from "react";

import { CurrentAccountContext } from "../reducers/CurrentAccount";

export const useIsAccountConnectedWallet = () => {
  const { account } = useContext(CurrentAccountContext);
  const connectedWallets = useWallets();
  const connectedWalletAddress = connectedWallets[0]?.accounts?.[0]?.address;
  const isAccountConnectedWallet = connectedWalletAddress?.toLowerCase() === account?.toLowerCase();

  return isAccountConnectedWallet && !!account?.length;
};
