import React, { useState, useCallback, useContext, useMemo, Suspense } from "react";
import styled, { css } from "styled-components";
import { ProtocolDetail, Voter } from "@boardroom/boardroom-api";

import InfoCard from "../../components/InfoCard";
import { COLORS } from "../../constants/colors";
import ExpandCollapseRows from "../../components/common/ExpandCollapseRows";
import DelegateRow, { SetupDelegationRow, DelegateToSelf } from "./DelegateRow";
import { useDelegatesFromAddress } from "../../hooks/useDelegatesFromAddress";
import { CurrentAccountContext } from "../../reducers/CurrentAccount";
import { LearnIcon } from "../../components/icons";
import { CurrentTxHashContext } from "../../reducers/CurrentTxHash";
import DelegationConfirmModal from "../../components/Voting/DelegationConfirmModal";
import { useOnboardWallet } from "../../hooks/useOnboardWallet";
import { useMixpanel } from "../../hooks";
import { useCyberConnectTwitterVerifiedBatch } from "../../hooks/useCyberConnectTwitterVerifiedBatch";
import { ProtocolInfo } from "../../types";

const StyledInfoCard = styled(InfoCard)`
  margin-top: 24px;
  padding-bottom: 0;
  .ant-card-body {
    padding: 0;
    padding-bottom: 0;
  }
`;

const StyledTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0;
  width: 100%;
`;

const Title = styled.div<{ active: boolean }>`
  text-transform: capitalize;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  cursor: pointer;
  position: relative;

  ${(props) =>
    props.active &&
    css`
      font-weight: 500;
      color: ${COLORS.primary.grayDark};
      &:after {
        content: "";
        height: 2px;
        width: 100%;
        position: absolute;
        background: ${COLORS.primary.accent};
        bottom: -15px;
        left: 0;
      }
    `};
`;

const StyledLink = styled.a`
  margin: auto -20px auto 0;
  float: right;
`;

const DelegateRowsWrapper = styled.div`
  overflow: hidden;
  transition: max-height 1s ease-in-out;
  position: relative;
  .ant-row {
    border-top: 1px solid ${COLORS.primary.grayLighter};
    &:first-child {
      border-top: none;
    }
  }
`;

const FlexItem = styled.div`
  display: flex;
  gap: 12px;
`;

const EmptyStateText = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: ${COLORS.primary.grayDarkLightest};
  margin: 4px auto 24px;
  width: 14rem;
`;

export const StyledTitleEmptyState = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: ${COLORS.secondary.purple};
`;

export const StyledImg = styled("img")`
  margin: 12px 0;
`;

export const StyledEmptyStateButton = styled("button")<{ $addMarginTop?: boolean }>`
  background: rgba(240, 239, 248, 0.2);
  border: 1px solid ${COLORS.secondary.purple};
  box-sizing: border-box;
  border-radius: 4px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: ${COLORS.secondary.purple};
  margin: 0 auto;
  height: 24px;
  width: 8rem;
  transition: 0.3s all;
  cursor: pointer;
  ${({ $addMarginTop }) => $addMarginTop && "margin-top: 16px;"}
  :hover {
    border: 1px solid #4235e1;
  }
  :focus {
    background: #4235e1;
    border: 1px solid #4235e1;
    color: white;
  }
`;

export const EmptyStateWrapper = styled("div")`
  padding: 20px 0 32px;
  text-align: center;
  img {
    height: 40px;
    width: 56px;
  }
`;

interface Props {
  voter?: Voter;
  protocolsInWallet: string[];
  protocols: Record<string, ProtocolDetail & ProtocolInfo & {
    categories: string[];
  }>
}

const YourDelegates = ({ voter, protocolsInWallet, protocols }: Props) => {
  const { account } = useContext(CurrentAccountContext);
  const { txHash } = useContext(CurrentTxHashContext);
  const { delegationsFromAddress } = useDelegatesFromAddress({ address: account, suspense: false });

  const [activeTab, setActiveTab] = useState<"Your Delegates" | "Undelegated">("Your Delegates");
  const [noDelegationCounter, setNoDelegationCounter] = useState<string[]>([]);
  const wallet = useOnboardWallet();
  const { trackClickDocsLinkOnDelegatesCard, trackClickConnectWalletOnDelegatesCard } = useMixpanel();

  const delegateToSelf = useMemo(
    () =>
      delegationsFromAddress?.filter(
        (delegate) => delegate.address === delegate.addressDelegatedTo && protocols[delegate.protocol],
      ),
    [delegationsFromAddress, protocols],
  );

  const delegateToOthers = useMemo(
    () => delegationsFromAddress?.filter((delegate) => delegate.address !== delegate.addressDelegatedTo),
    [delegationsFromAddress],
  );

  const delegateToOthersAddressesOnly = useMemo(
    () => delegateToOthers?.map((delegate) => delegate.addressDelegatedTo),
    [delegateToOthers],
  );

  const { parsedAddresses: twitterData } = useCyberConnectTwitterVerifiedBatch({
    addresses: delegateToOthersAddressesOnly,
  });

  const delegationsFromAddressAsString = delegationsFromAddress.map((delegate) => delegate.protocol);
  const voterProtocolsAsString = voter?.protocols
    .map((protocol) => protocol.protocol)
    .filter((protocol) => !delegationsFromAddressAsString.includes(protocol));

  const undelegatedProtocolsInWallet = protocolsInWallet.filter(
    (protocol) => !delegationsFromAddressAsString.includes(protocol),
  );

  const sortedVotesHistoryProtocols = useMemo(
    () =>
      (voter &&
        voter?.protocols &&
        voter?.protocols
          .filter((protocol) => voterProtocolsAsString?.includes(protocol.protocol))
          .sort(
            (a: { totalPowerCast: number }, b: { totalPowerCast: number }) => b.totalPowerCast - a.totalPowerCast,
          )) ||
      [],
    [voter, voterProtocolsAsString],
  );

  const protocolsSupported = useMemo(
    () =>
      voter?.protocols.filter(
        (protocolObj) => protocols[protocolObj.protocol] && protocols[protocolObj.protocol]?.isEnabled,
      ),
    [protocols, voter?.protocols],
  );

  const protocolsSupportedWithoutVoteHistory = useMemo(
    () =>
      undelegatedProtocolsInWallet.filter((protocolCname) => {
        const index = protocolsSupported?.findIndex((protocol) => protocol.protocol === protocolCname);
        return index !== undefined ? index < 0 : true;
      }),
    [undelegatedProtocolsInWallet, protocolsSupported],
  );

  const handleClick = useCallback((val: "Your Delegates" | "Undelegated") => {
    setActiveTab(val);
  }, []);

  const handleDocsClick = useCallback(() => {
    trackClickDocsLinkOnDelegatesCard({
      userId: account,
    });
  }, [account, trackClickDocsLinkOnDelegatesCard]);

  const handleConnectWalletClick = useCallback(() => {
    wallet?.openWalletModal();
    trackClickConnectWalletOnDelegatesCard();
  }, [trackClickConnectWalletOnDelegatesCard, wallet]);

  const [areDelegatesExpanded, setAreDelegatesExpanded] = useState(false);

  const toggleDelegatesExpandCollapse = useCallback(() => {
    setAreDelegatesExpanded(!areDelegatesExpanded);
  }, [areDelegatesExpanded]);

  const uniqueNoDelegation = useMemo(
    () => Array.from(new Set(noDelegationCounter)).filter(Boolean),
    [noDelegationCounter],
  );

  const unDelegatedProtocolsCount =
    protocolsSupportedWithoutVoteHistory?.length + sortedVotesHistoryProtocols?.length - uniqueNoDelegation?.length - 1;

  const CardTitle = (
    <StyledTitleWrapper>
      <FlexItem>
        <Title active={activeTab === "Your Delegates"} onClick={() => handleClick("Your Delegates")}>
          Delegated
          <span style={{ fontWeight: activeTab === "Your Delegates" ? 300 : 200, marginLeft: "4px" }}>
            {delegateToOthers.length + (delegateToSelf.length ? 1 : 0)}
          </span>
        </Title>
        <Title active={activeTab === "Undelegated"} onClick={() => handleClick("Undelegated")}>
          Undelegated
          <span style={{ fontWeight: activeTab === "Undelegated" ? 300 : 200, marginLeft: "4px" }}>
            {/* {unDelegatedProtocolsCount} */}
          </span>
        </Title>
      </FlexItem>
      <StyledLink
        onClick={handleDocsClick}
        href="https://docs.boardroom.io/docs/learn-hub-1/xp79r5x50ceha-delegating-your-vote"
        target="_blank"
      >
        <LearnIcon width={16} height={16} />
      </StyledLink>
    </StyledTitleWrapper>
  );

  return (
    <>
      {txHash?.txHash && <DelegationConfirmModal visible={!!txHash?.txHash} />}
      <StyledInfoCard height="100%" title={CardTitle} $noPaddingBody={true}>
        {activeTab === "Your Delegates" && (
          <>
            {account ? (
              <>
                {(delegationsFromAddress?.length || 0) >= 1 ? (
                  <DelegateRowsWrapper>
                    <ExpandCollapseRows
                      isExpanded={areDelegatesExpanded}
                      totalRows={delegateToOthers.length + (delegateToSelf.length ? 1 : 0) || 0}
                      onToggle={toggleDelegatesExpandCollapse}
                    >
                      {!!delegateToSelf.length && <DelegateToSelf delegates={delegateToSelf} />}
                      {delegateToOthers?.map((delegate, index) => (
                        <ExpandCollapseRows.Row
                          cutoffIndex={3}
                          isExpanded={areDelegatesExpanded}
                          index={index}
                          key={`${index}-${delegate.timestamp}`}
                        >
                          <Suspense fallback={<></>}>
                            <DelegateRow isVerified={twitterData?.[delegate.addressDelegatedTo]} delegate={delegate} />
                          </Suspense>
                        </ExpandCollapseRows.Row>
                      ))}
                    </ExpandCollapseRows>
                  </DelegateRowsWrapper>
                ) : (
                  <EmptyStateWrapper>
                    <StyledImg src={`${process.env.PUBLIC_URL}/assets/VoteHistoryEmptyState.png`} />
                    <StyledTitleEmptyState>You’re not Delegating Yet </StyledTitleEmptyState>
                    <EmptyStateText>Did you know you can also delegate votes to yourself?</EmptyStateText>
                    <StyledEmptyStateButton onClick={() => handleClick("Undelegated")}>
                      Get Started
                    </StyledEmptyStateButton>
                  </EmptyStateWrapper>
                )}
              </>
            ) : (
              <EmptyStateWrapper>
                <StyledImg src={`${process.env.PUBLIC_URL}/assets/VoteHistoryEmptyState.png`} />
                <StyledTitleEmptyState>You’re not Delegating Yet</StyledTitleEmptyState>
                <StyledEmptyStateButton $addMarginTop onClick={handleConnectWalletClick}>
                  Connect Wallet
                </StyledEmptyStateButton>
              </EmptyStateWrapper>
            )}
          </>
        )}
        {activeTab === "Undelegated" && (
          <>
            {account ? (
              <>
                {[...(sortedVotesHistoryProtocols || []), ...protocolsSupportedWithoutVoteHistory].length >
                uniqueNoDelegation.length ? (
                  <DelegateRowsWrapper>
                    <ExpandCollapseRows
                      isExpanded={areDelegatesExpanded}
                      totalRows={unDelegatedProtocolsCount}
                      onToggle={toggleDelegatesExpandCollapse}
                    >
                      {sortedVotesHistoryProtocols &&
                        sortedVotesHistoryProtocols.map((protocol, index) => {
                          if (protocol.protocol !== "makerdao") {
                            return (
                              <ExpandCollapseRows.Row
                                cutoffIndex={3}
                                isExpanded={areDelegatesExpanded}
                                index={index}
                                key={`${index}-${protocol}`}
                              >
                                <SetupDelegationRow
                                  setNoDelegationCounter={setNoDelegationCounter}
                                  protocol={typeof protocol === "string" ? protocol : protocol?.protocol}
                                />
                              </ExpandCollapseRows.Row>
                            );
                          }
                        })}
                      {protocolsSupportedWithoutVoteHistory &&
                        protocolsSupportedWithoutVoteHistory?.map((protocol, index) => {
                          if (protocol !== "makerdao") {
                            return (
                              <ExpandCollapseRows.Row
                                cutoffIndex={3}
                                isExpanded={areDelegatesExpanded}
                                index={index}
                                key={`${index}-${protocol}`}
                              >
                                <SetupDelegationRow
                                  setNoDelegationCounter={setNoDelegationCounter}
                                  protocol={protocol}
                                />
                              </ExpandCollapseRows.Row>
                            );
                          }
                        })}
                    </ExpandCollapseRows>
                  </DelegateRowsWrapper>
                ) : (
                  <EmptyStateWrapper>
                    <StyledImg src={`${process.env.PUBLIC_URL}/assets/VoteHistoryEmptyState.png`} />
                    <StyledTitleEmptyState>Nothing To See Here</StyledTitleEmptyState>
                    <EmptyStateText>Nice. You’ve delegated all of your projects.</EmptyStateText>
                  </EmptyStateWrapper>
                )}
              </>
            ) : (
              <EmptyStateWrapper>
                <StyledImg src={`${process.env.PUBLIC_URL}/assets/VoteHistoryEmptyState.png`} />
                <StyledTitleEmptyState>You’re not Delegating Yet</StyledTitleEmptyState>
                <StyledEmptyStateButton $addMarginTop onClick={handleConnectWalletClick}>
                  Connect Wallet
                </StyledEmptyStateButton>
              </EmptyStateWrapper>
            )}
          </>
        )}
      </StyledInfoCard>
    </>
  );
};
export default YourDelegates;
