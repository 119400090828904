import React from "react";
import styled, { css } from "styled-components";
import { COLORS } from "../../constants/colors";

interface TextProps {
  children: React.ReactNode;
  bold?: boolean;
  italic?: boolean;
  ellipsis?: boolean;
}

export const TextStyles = css`
  color: ${COLORS.primary.grayDark};
  font-size: 14px;
  line-height: 22px;
`;

const StyledText = styled("span")`
  ${TextStyles}
  margin-bottom: 0px;
  font-weight: ${({ bold }: TextProps) => (bold ? 600 : "normal")};
  font-style: ${({ italic }: TextProps) => (italic ? "italic" : "normal")};
  ${(props) =>
    props.ellipsis &&
    css`
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    `}
`;

function Text(props: TextProps) {
  return <StyledText {...props}>{props.children}</StyledText>;
}

export default Text;
