import * as React from "react";

const SvgThinCheckIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" {...props}>
    <path
      d="M6.85 11.771l-3.3-3.3.943-.942L6.85 9.884v.001l5.656-5.656.942.942-5.656 5.658-.942.942z"
      fill="currentColor"
    />
  </svg>
);

export default SvgThinCheckIcon;
