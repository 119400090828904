export default function toShortAddress(t?: string | null, extra: boolean | undefined = false) {
  if (!t) {
    return;
  }

  const limit = extra ? 4 : 6;

  return t
    .slice(0, limit)
    .concat("...")
    .concat(t.slice(t.length - limit, t.length));
}

export function toLongAddress(t?: string | null, extra: boolean | undefined = false) {
  if (!t) {
    return;
  }

  const limit = extra ? 4 : 12;

  return t
    .slice(0, limit)
    .concat("...")
    .concat(t.slice(t.length - limit, t.length));
}
