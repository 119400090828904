import * as React from "react";

const SvgCircularShareIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.086 13.069l-1.702-1.362a7 7 0 1013.232 0l-1.702 1.362a5 5 0 11-9.827 0z"
      fill="#4235E1"
    />
    <path
      d="M12 4l-.625-.78.625-.5.625.5L12 4zm1 9a1 1 0 11-2 0h2zM6.375 7.22l5-4 1.25 1.56-5 4-1.25-1.56zm6.25-4l5 4-1.25 1.56-5-4 1.25-1.56zM13 4v9h-2V4h2z"
      fill="#4235E1"
    />
  </svg>
);

export default SvgCircularShareIcon;
