import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";

import ProtocolIcon from "../ProtocolIcon";
import { getProtocolPath } from "../../constants/protocols";
import { COLORS } from "../../constants/colors";
import { protocols } from "../../constants/protocols";
import { UtilityTag } from "../Tags";
import { Paragraph, Text } from "../Typography";
import media from "../../media-query";
import { SnapshotIcon, OnchainIcon } from "../../components/icons";
import { useSdkWithoutSigner } from "../../hooks/useSdkWithoutSigner";

interface ProposalListItemProps {
  protocol: string;
  refId: string;
  onClick?: Function;
  children: React.ReactNode;
  adapter: string;
}

interface ProposalListItemHeaderProps {
  children: React.ReactNode;
  proposalStatus?: string | null;
  proposalTagType?: string | null;
}

const StyledContent = styled("div")<any>`
  display: inline-block;
  width: calc(100% - 48px);
  vertical-align: middle;
  padding-left: 24px;
  &:hover a {
    color: ${COLORS.primary.accent};
  }
`;

const StyledProposalItem = styled("div")`
  position: relative;
  background: white;
  width: 100%;
  padding: 15px 24px;
  border-bottom: 1px solid ${COLORS.primary.grayLighter};
  display: flex;
  ${media.lessThan("767px")`
     padding: 15px 0px;
  `};
  &:hover {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    cursor: pointer;
  }
`;

const StyledTag = styled(UtilityTag)`
  margin-left: 10px;
`;

const StyledDescription = styled(Paragraph)`
  color: ${COLORS.primary.grayDarkLightest};
`;

const StyledListHeader = styled(Paragraph)`
  ${media.lessThan("767px")`
    display: flex;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  `};
`;

const StyledListHeaderContent = styled(Text)`
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  font-size: 12px;
  line-height: 14px;
  color: ${COLORS.primary.grayDarkLightest};
  display: flex;
  ${media.lessThan("767px")`
    display: inline-block;
    width: 92%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  `};
`;

const StyledProtocolIcon = styled(ProtocolIcon)`
  height: 48px;
  width: 48px;
`;

const FrameworkWrapper = styled.div`
  position: absolute;
  display: block;
  right: -4px;
  bottom: -6px;
`;

const StyledOnchainIcon = styled(OnchainIcon)`
  width: 20px;
  height: 20px;
  color: #fff;
  border: 2px solid #fff;
  background: ${COLORS.secondary.blue};
  border-radius: 50%;
  padding: 2px;
  ${media.lessThan("991px")`
    width: 20px;
    height: 20px;
  `}
  ${media.lessThan("640px")`
    width: 16px;
    height: 16px;
  `}
`;

const StyledSnapshotIcon = styled(SnapshotIcon)`
  width: 20px;
  height: 20px;
  ${media.lessThan("991px")`
    width: 20px;
    height: 20px;
  `}
  ${media.lessThan("640px")`
    width: 16px;
    height: 16px;
  `}
`;

const StyledDiv = styled.div`
  position: relative;
  vertical-align: top;
  margin-top: 16px;
  height: 48px;
  width: 48px;
`;

function ProposalListItem({ protocol, refId, onClick, children, adapter }: ProposalListItemProps) {
  const { search } = useLocation();
  const protocolPath = getProtocolPath(protocol);
  const proposalProtocol = protocols[protocol];
  const sdk = useSdkWithoutSigner();

  const [adapterFramework, setAdapterFramework] = useState("");
  const protocolInSdk = sdk?.getProtocol(proposalProtocol.cname);

  useEffect(() => {
    try {
      const adapterFramework = protocolInSdk?.adapter("proposals", adapter);
      adapterFramework?.getFramework().then((response) => {
        setAdapterFramework(response);
      });
    } catch (error) {
      console.error(error);
    }
  }, [protocolInSdk, adapter]);

  return (
    <Link to={{ pathname: `/${protocolPath}/proposal/${refId}`, search }} onClick={() => onClick && onClick()}>
      <StyledProposalItem>
        <StyledDiv>
          <StyledProtocolIcon protocol={proposalProtocol} />
          <FrameworkWrapper>
            {adapter === "snapshot" || (adapter === "default" && adapterFramework === "snapshot") ? (
              <StyledSnapshotIcon />
            ) : (
              <StyledOnchainIcon />
            )}
          </FrameworkWrapper>
        </StyledDiv>
        <StyledContent>{children}</StyledContent>
      </StyledProposalItem>
    </Link>
  );
}

function ProposalListItemHeader({ children, proposalTagType, proposalStatus }: ProposalListItemHeaderProps) {
  return (
    <StyledListHeader>
      <StyledListHeaderContent bold>{children}</StyledListHeaderContent>
      {proposalTagType && proposalStatus ? <StyledTag type={proposalTagType} label={proposalStatus} /> : null}
    </StyledListHeader>
  );
}

function ProposalListItemHeaderWithoutTag({ children }: ProposalListItemHeaderProps) {
  return (
    <StyledListHeader>
      <StyledListHeaderContent>{children}</StyledListHeaderContent>
    </StyledListHeader>
  );
}

function ProposalDescription({ children }: { children: React.ReactNode }) {
  return <StyledDescription ellipsis>{children}</StyledDescription>;
}

ProposalListItem.Header = ProposalListItemHeader;
ProposalListItem.Description = ProposalDescription;
ProposalListItem.HeaderWithoutTag = ProposalListItemHeaderWithoutTag;

export default ProposalListItem;
export type { ProposalListItemProps };
