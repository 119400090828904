import React from "react";
import styled from "styled-components";
import { Moment } from "moment";
import { Popover } from "../../Popover";
import { Date } from "./Date";
import { COLORS } from "../../../constants/colors";
import { CalendarEvent } from "../../../types";

interface Props {
  date: Moment;
  events: CalendarEvent[];
  isToday: boolean;
  isSelected: boolean;
  isInView: boolean;
  isInFirstRow: boolean;
  container: HTMLElement | null;
  containerWidth: number;
}

const getContainerHeight = ({ containerWidth }: Pick<Props, "containerWidth" | "isSelected">) => {
  if (containerWidth <= 400) {
    return "40px";
  }

  if (containerWidth <= 530) {
    return "58px";
  }

  return "97px";
};

const getContainerWidth = ({ containerWidth }: Pick<Props, "containerWidth" | "isSelected">) => {
  if (containerWidth <= 400) {
    return "40px";
  }

  if (containerWidth <= 530) {
    return "58px";
  }

  return "97px";
};

const Container = styled.div<Pick<Props, "containerWidth" | "isSelected">>`
  position: relative;
  height: ${getContainerHeight};
  width: ${getContainerWidth};
  background: ${({ isSelected }) => (isSelected ? COLORS.primary.accent : "#fff")};
  transition: background 0.2s;
  border-radius: 100px;
  overflow: hidden;
  margin: 0 auto;

  :hover {
    background: ${({ isSelected }) => (isSelected ? COLORS.primary.accent : COLORS.primary.accentLighter)};
  }
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  text-align: left;
  padding: 5px;
`;

const DateCell = ({ date, events, isToday, isSelected, isInView, isInFirstRow, container, containerWidth }: Props) => {
  if (events.length === 0) {
    return (
      <Container isSelected={isSelected} containerWidth={containerWidth}>
        <Content>
          <Date
            date={date.format("D")}
            isToday={isToday}
            isSelected={isSelected}
            isInView={isInView}
            isInFirstRow={isInFirstRow}
          />
        </Content>
      </Container>
    );
  }

  return (
    <Popover content={<></>} title={date.format("dddd, MMMM D")} container={container}>
      <Container isSelected={isSelected} containerWidth={containerWidth}>
        <Content>
          <Date
            date={date.format("D")}
            isToday={isToday}
            isSelected={isSelected}
            isInView={isInView}
            isInFirstRow={isInFirstRow}
          />
        </Content>
      </Container>
    </Popover>
  );
};

export default React.memo(DateCell);
