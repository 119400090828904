import React, { useState, useContext, useMemo, useRef, useCallback, Suspense } from "react";
import Col from "antd/es/col";
import Row from "antd/es/row";
import { useHistory, useLocation, useParams } from "react-router-dom";
import styled, { css } from "styled-components";
import { useCurrentWidth } from "react-socks";
import notification from "antd/es/notification";
import CopyToClipboard from "react-copy-to-clipboard";

import media from "../../media-query";
import { ProfileInfoCard, ProfileMainContent, ProfileHeader, ProfileBanner } from "../../components";
import { TrackPageView } from "../../components/TrackPageView";
import ProtocolsDropdown from "../../components/VoterProfile/ProtocolsDropdown";
import ProfileShareButton from "../../components/VoterProfile/ProfileShareButton";
import ProfileAvatar from "../../components/VoterProfile/ProfileAvatar";
import { VOTER_PROFILE_SCROLL_SHRINK, VOTER_PROFILE_SCROLL_SHRINK_MOBILE } from "../../constants/general";
import { CurrentAccountContext } from "../../reducers/CurrentAccount";
import ProfileActiveProtocols from "../../components/VoterProfile/ProfileActiveProtocols";
import { useMultipleAddressesVoter } from "../../hooks/useVoter";
import { ArrowLeftIcon, CrossIcon } from "../../components/icons";
import { COLORS } from "../../constants/colors";
import { ThreeDotMenuIcon } from "../../components/icons";
import { Popover } from "../../components/Popover";
import TwitterShare from "../../components/common/TwitterShareButton";
import { useMixpanel } from "../../hooks";
import MetaTags from "../../components/common/MetaTags";
import ErrorBoundary from "../../components/ErrorBoundary";
import VoterProfileSkeleton from "../VoterProfile/VoterProfileSkeleton";
import { useGetTeam } from "../../hooks/useGetTeam";
import MultipleAddressVotePowerCard from "../DashboardPages/Calendar/MultipleAddressVotePowerCard";
import { useDelegatesForProtocolsMultipleAddresses } from "../../hooks/useDelegateForProtocols";
import DelegationModal from "../../components/Voting/DelegationModal";
import { StyledCopyIcon, StyledCopyText } from "../../components/DelegateMessageBoard/styles";
import AboutSection from "../../components/VoterProfile/AboutSection";
import { RoutePaths } from "../../constants/Routes";

interface Params {
  identifier: string;
}

const Container = styled.div`
  position: relative;
  padding-bottom: 40px;
  margin: auto;
  overflow-y: scroll;
  ${media.greaterThan("991px")`
    height: calc(100vh - 80px);
  `}
  ${media.lessThan("991px")`
    margin-top: -7px;
    height: 100vh;
  `}
`;

const FlexItem = styled.div`
  display: flex;
  gap: 16px;
`;

const HeaderStickyWrapper = styled.div`
  position: sticky;
  width: 100%;
  z-index: 1;
  background-color: white;
  top: 0px;
  ${media.lessThan("991px")`
    position: relative;
  `}
`;

const MainContentWrapper = styled.div<{ isProfilePage: boolean }>`
  background-color: white;
  overflow-x: hidden;
  position: relative;
  ${(props) =>
    !props.isProfilePage &&
    css`
      border-top-right-radius: 20px;
      border-top-left-radius: 20px;
    `}
  box-shadow: 0px -4px 4px rgb(0 0 0 / 10%);
  ${media.greaterThan("991px")`
    height: calc(100% - 80px);
  `}
`;

const AbsoluteBgMobileHeader = styled.div`
  position: absolute;
  height: 68px;
  width: calc(100% + 32px);
  margin-left: 0px;
  border-top-right-radius: 0px;
  background: white;
  border-top-left-radius: 0px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  z-index: 10;
  top: 0;
`;

const StyledMobileNavContent = styled.div`
  margin-left: 80px;
  margin-top: 18px;
  ${media.lessThan("640px")`
    max-width: 14rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  `}
`;

const StyledProfileName = styled.span`
  font-weight: 600;
  font-size: 20px;
  margin-left: 8px;
  color: ${COLORS.primary.grayDark};
`;

export const PageMax = styled.div`
  width: 100%;
  ${media.greaterThan("1400px")`
    width: 1400px;
    margin: 0 auto;
  `}
`;

const StyledBackButton = styled.div`
  position: absolute;
  z-index: 11;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 50px;
  left: 16px;
  top: 14px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  color: white;
  ${media.greaterThan("991px")`
    display: none;
  `}
`;

const StyledBackButtonDark = styled(StyledBackButton)`
  background: none;
  color: #4235e1;
`;

const StyledDropdown = styled(StyledBackButton)`
  transform: rotate(90deg);
  left: 0px;
  top: 0px;
`;

const StyledDropdownDark = styled(StyledBackButtonDark)`
  transform: rotate(90deg);
  left: 0px;
  top: 0px;
`;

const StyledRightAbsoluteElement = styled.div`
  position: absolute;
  z-index: 11;
  right: 16px;
  top: 14px;
  width: 40px;
  height: 40px;
  ${media.greaterThan("991px")`
    display: none;
  `}
`;

const ProfileTwitterShareButton = styled(TwitterShare)`
  vertical-align: text-top;
  display: inline-flex;
  height: 21px;
  overflow: hidden;
  color: ${COLORS.primary.accent};
`;

export const StyledVoterOverlay = styled("div")`
  z-index: 18;
  left: 0px;
  width: 100%;
  position: fixed;
  height: -webkit-fill-available;
  overflow-y: auto;
  overflow-x: hidden;
  background: rgba(25, 21, 64, 0.7);
  top: 0;
  ${media.lessThan("991px")`
    display: none;
  `}
`;

export const CloseIcon = styled(CrossIcon)`
  z-index: 12;
  :hover {
    cursor: pointer;
  }
`;

export const StyledCloseProfileTrigger = styled.div`
  text-align: right;
  color: white;
  padding-right: 40px;
  height: 80px;
  line-height: 80px;
  background: rgba(25, 21, 64, 0.01);
`;

export const StyledClosedText = styled.span`
  font-size: 24px;
  padding-right: 20px;
`;

export const CopyDelegationLinkWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-top: 24px;
  border-radius: 8px;
  border: 1px solid ${COLORS.primary.accent};
  padding: 8px 16px;
  cursor: pointer;
  transition: 0.3s all;
  :hover {
    background-color: ${COLORS.primary.accent};
    div,
    svg {
      color: white !important;
    }
  }
`;

function TeamProfile() {
  const { identifier } = useParams<Params>();

  const isMobile = useCurrentWidth() < 991;

  const [ens] = useState<string>("");
  const teamDetails = useGetTeam(identifier);
  const [scrolledHeight, setScrolledHeight] = useState(0);
  const voteHistoryScrollRef = useRef<HTMLDivElement>(null);

  const [activeProtocol, setActiveProtocol] = useState<string>("");
  const { account } = useContext(CurrentAccountContext);

  const isDelegate = false;
  const history = useHistory();
  const containerElement = useRef<HTMLDivElement>(null);
  const { trackShareVoterProfileOnTwitter } = useMixpanel();

  const allAssociatedAddresses: Array<string> = useMemo(() => {
    return [...(teamDetails?.orgWallets || [])];
  }, [teamDetails?.orgWallets]);

  const { data: votesData } = useMultipleAddressesVoter({ addresses: allAssociatedAddresses, suspense: false });
  const { delegateFor } = useDelegatesForProtocolsMultipleAddresses({
    addresses: allAssociatedAddresses,
    teamId: identifier,
  });
  const { search, pathname } = useLocation();
  const query = useMemo(() => new URLSearchParams(search), [search]);
  const protocolOnUrl = query.get("protocol");
  const addressToDelegate = query.get("addressToDelegate");
  const delegationModal = query.get("delegationModal");
  const [delegationModalVisible, setDelegationModalVisible] = useState<boolean>(Boolean(delegationModal));

  const onScroll = useCallback(
    (e: any) => {
      const scrollShrinkHeight = isMobile ? VOTER_PROFILE_SCROLL_SHRINK_MOBILE : VOTER_PROFILE_SCROLL_SHRINK;
      if (e.target.scrollTop <= scrollShrinkHeight) {
        setScrolledHeight(e.target.scrollTop);
      } else if (e.target.scrollTop > scrollShrinkHeight && scrolledHeight < scrollShrinkHeight) {
        setScrolledHeight(scrollShrinkHeight);
      }
    },
    [isMobile, scrolledHeight],
  );

  const closeProfile = () => {
    if (history.length > 1) {
      history.goBack();
    } else {
      history.push(RoutePaths.feed);
    }
    return;
  };

  const addressToDelegateForButton = useMemo(() => {
    const entries = delegateFor?.entries();
    let address = "";
    for (const [key, value] of Array.from(entries || [])) {
      if (value.includes(activeProtocol)) {
        address = key;
      }
    }
    return address;
  }, [activeProtocol, delegateFor]);

  const onCopy = useCallback(() => {
    notification.success({
      message: "Copied!",
    });
  }, []);

  return (
    <ErrorBoundary>
      {!!protocolOnUrl && !!addressToDelegate && (
        <DelegationModal
          visible={delegationModalVisible}
          setVisible={setDelegationModalVisible}
          address={addressToDelegate}
          protocolName={protocolOnUrl}
          disableSelfDelegation={true}
          zIndex={19}
        />
      )}
      <MainContentWrapper isProfilePage={false}>
        <div>
          <StyledBackButton
            onClick={closeProfile}
            style={{
              opacity: `${VOTER_PROFILE_SCROLL_SHRINK_MOBILE - scrolledHeight / VOTER_PROFILE_SCROLL_SHRINK_MOBILE}`,
            }}
          >
            <ArrowLeftIcon height={20} width={20} />
          </StyledBackButton>
          <StyledBackButtonDark
            onClick={closeProfile}
            style={{ opacity: `${scrolledHeight / VOTER_PROFILE_SCROLL_SHRINK_MOBILE}` }}
          >
            <ArrowLeftIcon height={20} width={20} />
          </StyledBackButtonDark>
          <StyledRightAbsoluteElement ref={containerElement}>
            <Popover
              content={
                <ProfileTwitterShareButton
                  type="voter"
                  title="Check out this user profile on the @boardroom_info app"
                  clickCallBack={() => {
                    trackShareVoterProfileOnTwitter({
                      protocol: activeProtocol || "",
                      voterAddress: identifier || "",
                      userId: `${account}`,
                    });
                  }}
                  text={"Tweet This Profile"}
                  hashtags={[]}
                />
              }
              placement="leftBottom"
              trigger="click"
              zIndex={13}
              container={containerElement.current}
            >
              <div style={{ position: "relative" }}>
                <StyledDropdown
                  style={{
                    opacity: `${
                      VOTER_PROFILE_SCROLL_SHRINK_MOBILE - scrolledHeight / VOTER_PROFILE_SCROLL_SHRINK_MOBILE
                    }`,
                  }}
                >
                  <ThreeDotMenuIcon height={20} width={20} />
                </StyledDropdown>
                <StyledDropdownDark style={{ opacity: `${scrolledHeight / VOTER_PROFILE_SCROLL_SHRINK_MOBILE}` }}>
                  <ThreeDotMenuIcon height={20} width={20} />
                </StyledDropdownDark>
              </div>
            </Popover>
          </StyledRightAbsoluteElement>
          {isMobile && (
            <AbsoluteBgMobileHeader style={{ opacity: `${scrolledHeight / VOTER_PROFILE_SCROLL_SHRINK_MOBILE}` }}>
              <StyledMobileNavContent>
                <ProfileAvatar
                  isDelegate={isDelegate}
                  address={teamDetails?.orgWallets?.[0] || ""}
                  size="xsmall"
                  pfp={teamDetails?.pfpUrl}
                />
                <StyledProfileName>{identifier}</StyledProfileName>
              </StyledMobileNavContent>
            </AbsoluteBgMobileHeader>
          )}
          <Container ref={voteHistoryScrollRef} id="scrollElement" onScroll={onScroll}>
            <HeaderStickyWrapper style={{ top: isMobile ? "0px" : `-${scrolledHeight}px` }}>
              <ProfileBanner scrolledHeight={scrolledHeight} bannerImage={teamDetails?.bannerUrl} />
              <TrackPageView name={`Voter: ${identifier} Profile`} />
              <MetaTags
                title="Delegate Profile"
                description="Checkout My Governance Delegate Profile"
                imageUrl="https://uploads-ssl.webflow.com/5fea803f5ceaedd1ffd21e65/602447d1a489bc75f4f2478a_Twitter%20Banner%203.png"
              />
              <PageMax>
                <Col md={{ span: 20, push: 2 }} xs={{ span: 22, push: 1 }}>
                  <Row gutter={[24, { xs: 12, sm: 12, md: 24 }]}>
                    <Col md={2} xs={24}>
                      {!isMobile && (
                        <ProfileAvatar
                          address={teamDetails?.orgWallets?.[0] || ""}
                          size={isMobile ? "small" : "big"}
                          pfp={teamDetails?.pfpUrl}
                          isDelegate={isDelegate}
                        />
                      )}
                    </Col>
                    <Col md={13} xs={24}>
                      <>
                        <ProfileHeader
                          ens={ens}
                          scrolledHeight={isMobile ? scrolledHeight : 0}
                          teamWallets={allAssociatedAddresses}
                          votesByAddress={votesData?.votesByAddress}
                          profileName={teamDetails?.orgName || identifier}
                          twitter={teamDetails?.twitter}
                          website={teamDetails?.website}
                        />
                        <ProfileActiveProtocols
                          teamWallets={allAssociatedAddresses}
                          activeProtocols={votesData?.allProtocols}
                          addressType="Org"
                          isDelegate={isDelegate}
                        />
                      </>
                    </Col>
                    <Col xs={9} md={8}>
                      {!isMobile && (
                        <FlexItem>
                          <ProfileShareButton
                            ens={ens}
                            activeProtocol={activeProtocol}
                            team={identifier}
                            scrolledHeight={isMobile ? scrolledHeight : 0}
                          />
                        </FlexItem>
                      )}
                    </Col>
                  </Row>
                </Col>
              </PageMax>
            </HeaderStickyWrapper>
            <PageMax>
              <Row>
                <Col md={{ span: 20, push: 2 }} xs={{ span: 22, push: 1 }}>
                  <>
                    <Row gutter={[24, { xs: 12, sm: 12, md: 24 }]}>
                      <Col md={2}></Col>
                      <Col md={{ span: 7, push: 13 }} xs={24}>
                        {!isMobile && (
                          <>
                            <ProfileInfoCard
                              activeProtocol={activeProtocol}
                              ens={ens}
                              setActiveProtocol={setActiveProtocol}
                              teamWallets={allAssociatedAddresses}
                              delegateForProtocols={delegateFor}
                              website={teamDetails?.website}
                            />
                            {!!activeProtocol && (
                              <CopyToClipboard
                                text={`https://boardroom.io${pathname}?protocol=${activeProtocol}&delegationModal=true&addressToDelegate=${addressToDelegateForButton}`}
                                onCopy={onCopy}
                              >
                                <CopyDelegationLinkWrapper>
                                  <StyledCopyText style={{ marginBottom: 0 }}>
                                    <StyledCopyIcon />
                                    Copy 1-click delegation link
                                  </StyledCopyText>
                                </CopyDelegationLinkWrapper>
                              </CopyToClipboard>
                            )}
                            <MultipleAddressVotePowerCard wallets={allAssociatedAddresses} />
                          </>
                        )}
                      </Col>
                      <Col md={{ span: 13, pull: 7 }} xs={24}>
                        <div>
                          {teamDetails && (
                            <AboutSection
                              isTeam
                              teamDetails={teamDetails}
                              identifier={teamDetails?.orgId}
                              about={teamDetails?.about}
                            />
                          )}
                          {isMobile && (
                            <>
                              <ProfileInfoCard
                                activeProtocol={activeProtocol}
                                ens={ens}
                                setActiveProtocol={setActiveProtocol}
                                teamWallets={allAssociatedAddresses}
                                delegateForProtocols={delegateFor}
                                website={teamDetails?.website}
                              />
                              <MultipleAddressVotePowerCard wallets={allAssociatedAddresses} />
                            </>
                          )}
                          <ProtocolsDropdown
                            activeProtocol={activeProtocol}
                            setActiveProtocol={setActiveProtocol}
                            votedProtocols={votesData?.allProtocols}
                          />
                          <ProfileMainContent
                            key={activeProtocol}
                            activeProtocol={activeProtocol}
                            ens={ens}
                            ref={voteHistoryScrollRef}
                            teamWallets={allAssociatedAddresses}
                          />
                        </div>
                      </Col>
                    </Row>
                  </>
                </Col>
              </Row>
            </PageMax>
          </Container>
        </div>
      </MainContentWrapper>
    </ErrorBoundary>
  );
}

export default () => {
  const history = useHistory();
  const closeProfileOverlay = () => {
    if (history.location.key) {
      history.goBack();
    } else {
      history.push(RoutePaths.feed);
    }
    return;
  };
  const isMobile = useCurrentWidth() < 991;

  return (
    <Suspense fallback={<VoterProfileSkeleton />}>
      {isMobile ? (
        <TeamProfile />
      ) : (
        <StyledVoterOverlay>
          <StyledCloseProfileTrigger>
            <StyledClosedText>Exit Profile</StyledClosedText>
            <CloseIcon height="16px" width="16px" color="#fff" onClick={closeProfileOverlay} />
          </StyledCloseProfileTrigger>
          <TeamProfile />
        </StyledVoterOverlay>
      )}
    </Suspense>
  );
};
