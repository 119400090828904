import React, { useCallback, useContext, useEffect, useMemo, useState, Suspense } from "react";
import Col from "antd/es/col";
import Row from "antd/es/row";
import notification from "antd/es/notification";
import styled, { css } from "styled-components";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import ContentLoader from "react-content-loader";

import { Paragraph, Text } from "../../../components/Typography";
import { COLORS } from "../../../constants/colors";
import { Input } from "../../../components";
import mediaQuery from "../../../media-query";
import { TrackPageView } from "../../../components/TrackPageView";
import { useUserDetails } from "../../../hooks/useUserDetails";
import { CurrentAccountContext } from "../../../reducers/CurrentAccount";
import { Loader } from "../../../components/Loader";
import { CurrentUuidContext } from "../../../reducers/CurrentUuid";
import { updateUserDetails } from "../../../utils/updateUserDetails";
import { useOnboardWallet } from "../../../hooks/useOnboardWallet";
import UnconnectedWalletState from "../../../components/UnconnectedWalletState";
import { useMixpanel } from "../../../hooks";
import { SettingsText, StyledRowMobile } from "./SettingsProfile";
import { SettingsAngularIcon } from "../../../components/icons";
import { StyledSaveButton, StyledSaveButtonWrapper } from "./SettingsFeed";
import { useSiweFunction } from "../../../hooks/useSiweFunction";
import { NftSubscriptionContext } from "../../../reducers/NftSubscription";

const StyledParagraph = styled(Paragraph)`
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: ${COLORS.primary.grayDarkLightest};
  margin-bottom: 8px;
`;

const StyledRow = styled(Row)`
  margin-bottom: 120px;
  border-radius: 8px;
  padding-bottom: 48px;
`;

const StyledDivAlt = styled.div`
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  ::-webkit-scrollbar {
    display: none;
  }
  ${mediaQuery.lessThan("991px")`
    overflow-x: auto;
  `}
`;

const StyledTab = styled(Link)<{ $active?: boolean }>`
  margin-right: 16px;
  color: ${({ $active }) => ($active ? COLORS.primary.grayDark : COLORS.primary.grayDarkLightest)};
  font-weight: ${({ $active }) => ($active ? "500" : "400")};
  font-size: 18px;
  line-height: 28px;
  display: inline-block;
  min-width: auto;
  ${mediaQuery.lessThan("991px")`
    line-height: 30px;
    font-size: 22px;
    min-width: auto;
  `}

  &:after {
    ${(props) =>
      props.$active &&
      css`
        height: 8px;
        display: block;
        border-bottom: 4px solid ${COLORS.primary.accent};
        content: "";
        ${mediaQuery.lessThan("640px")`
          height: 16px;
          width: calc(100% + 24px);
          font-weight: 400;
        `}
      `}
  }
`;

const TabsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 28px;
  border-bottom: 1px solid ${COLORS.primary.grayLighter};
  position: relative;
  ${mediaQuery.lessThan("991px")`
      height: 42px;
      left: 0px;
      width: 100%;
      display: flex;
      margin-right: 0px;
    `}
  ${mediaQuery.lessThan("640px")`
      width: 100%;
      background: #fafafa;
      z-index: +2;
      padding-left: 2px;
      height: 64px;
      position: sticky;
      position: -webkit-sticky;
      top: 80px;
      display: inline-block;
      padding-top: 16px;
      white-space: nowrap;
    `}
`;

const StyledDiv = styled("div")`
  padding: 20px;
  padding-top: 0;
`;

const StyledTitle = styled("span")`
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
  color: #191540;
  margin-bottom: 12px;
  display: block;
`;

const StyledCol = styled(Col)`
  background: white;
  padding-top: 12px;
  padding-left: 16px !important;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  box-shadow: 0px 2px 6px rgba(25, 21, 64, 0.05);
  width: 100%;
`;

const StyledInput = styled(Input)<{ $validEmail: boolean; $invalidEmail: boolean }>`
  border: 1px solid
    ${(props) =>
      !props.$validEmail && !props.$invalidEmail
        ? `${COLORS.primary.accent}`
        : props.$validEmail
        ? `${COLORS.primary.accent}`
        : "#FF0000"};
  border-radius: 8px;
  margin-top: 16px;
  height: 56px;
  background: ${(props) => (props.$validEmail ? `${COLORS.primary.grayLighter}` : "transparent")};
`;

const ErrorMessage = styled("span")`
  font-style: italic;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #ff0000;
  margin-top: 8px;
  margin-left: 20px;
  display: flex;
`;

const SettingsEmail = () => {
  const { pathname } = useLocation();
  const { account } = useContext(CurrentAccountContext);
  const { user } = useUserDetails({ address: account, byPassCache: true });
  const [email, setEmail] = useState<string | undefined>();
  const [isEmailValid, setIsEmailValid] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const wallet = useOnboardWallet();
  const { trackUpdateEmailPreferencesInEmailSettingsPage } = useMixpanel();
  const { uuid } = useContext(CurrentUuidContext);
  const siweFunction = useSiweFunction();
  const { hasValidKey } = useContext(NftSubscriptionContext);

  useEffect(() => {
    if (user && user.loginEmail) {
      setEmail(user.loginEmail);
    }
  }, [user]);

  const emailRegex = useMemo(() => /^[^\s@]+@[^\s@]+\.[^\s@]+$/, []);

  const validateEmail = useCallback(() => {
    if (!email?.length) {
      setIsEmailValid("Email is required");
      return;
    }
    const isValid = emailRegex.test(email);
    if (!isValid) {
      setIsEmailValid("That doesn’t look like a valid email address");
    } else {
      setIsEmailValid("valid");
    }
  }, [email, emailRegex, setIsEmailValid]);

  const saveEmail = useCallback(async () => {
    setIsSaving(true);
    if (!emailRegex.test(email || "")) {
      notification.error({
        message: "Error",
        description: <Text>Please enter a valid email</Text>,
      });
      setIsSaving(false);
      return;
    }
    if (!account) {
      notification.error({
        message: "Error",
        description: <Text>Please connect your wallet to save your preferences</Text>,
      });
      setIsSaving(false);
      return;
    }
    if (account && email) {
      try {
        const data = {
          address: account,
          ...user,
          loginEmail: email,
          uuid,
        };
        await updateUserDetails({ address: account, userData: data, siweFunction });
        notification.success({
          message: "Success",
          description: <Text>Your email has been saved</Text>,
          duration: 10,
        });
        setIsSaving(false);
        setIsSaved(true);
        trackUpdateEmailPreferencesInEmailSettingsPage({
          userId: account,
        });
      } catch (e: any) {
        console.error(e);
        if (e?.message !== "SIWE Error") {
          notification.error({
            message: "Error",
            description: <Text>Something went wrong... Please try again later</Text>,
          });
        }
        setIsSaving(false);
      }
    }
  }, [account, email, emailRegex, user, uuid, trackUpdateEmailPreferencesInEmailSettingsPage, siweFunction]);

  return (
    <>
      <TrackPageView name={"Dashboard Settings Email"} />
      <StyledRowMobile style={{ marginLeft: 0 }} gutter={[16, 16]}>
        <Col xl={{ span: 20, push: 0 }} lg={{ span: 20, push: 0 }} md={{ span: 20, push: 0 }}>
          <StyledRow style={{ marginLeft: "24px" }} justify="start" gutter={20}>
            <Col style={{ paddingLeft: 0 }} xs={24} lg={16}>
              <SettingsText>
                <SettingsAngularIcon />
                Settings <span>Account</span>
              </SettingsText>
            </Col>
            {account ? (
              <>
                <StyledCol xl={18} xxl={16}>
                  <TabsWrapper>
                    <StyledDivAlt>
                      <StyledTab to={"/settings/email"} $active={pathname.includes("email")}>
                        Login&nbsp;
                      </StyledTab>
                      {hasValidKey && (
                        <StyledTab to={"/settings/billing"} $active={pathname.includes("billing")}>
                          Billing&nbsp;
                        </StyledTab>
                      )}
                    </StyledDivAlt>
                  </TabsWrapper>
                </StyledCol>
                <Col
                  style={{
                    background: "white",
                    padding: 0,
                    borderBottomLeftRadius: "8px",
                    borderBottomRightRadius: "8px",
                    boxShadow: "0px 4px 6px rgba(25, 21, 64, 0.05)",
                    width: "100%",
                    minHeight: "60vh",
                  }}
                  xl={18}
                  xxl={16}
                >
                  <StyledDiv style={{ height: "100%" }}>
                    <StyledTitle>Your Email Address</StyledTitle>
                    <StyledParagraph>You may use your email to opt-in to login with your e-mail.</StyledParagraph>
                    <StyledInput
                      type="email"
                      onChange={(event) => setEmail(event.target.value)}
                      $removeBorder
                      value={email}
                      onBlur={validateEmail}
                      $validEmail={isEmailValid === "valid"}
                      $invalidEmail={
                        isEmailValid === "Email is required" ||
                        isEmailValid === "That doesn’t look like a valid email address"
                      }
                      placeholder="Your Email"
                      name="email"
                    />
                    {isEmailValid === "Email is required" ||
                    isEmailValid === "That doesn’t look like a valid email address" ? (
                      <ErrorMessage>{isEmailValid}</ErrorMessage>
                    ) : null}
                    <StyledSaveButtonWrapper
                      style={{ marginBottom: 0, position: "absolute", width: "calc(100% - 40px)" }}
                    >
                      <StyledSaveButton disabled={isSaving || isSaved} $isSaved={isSaved} onClick={saveEmail}>
                        {isSaving && <Loader size="xsmall" />}
                        <span>{!isSaving && !isSaved ? "Save Preferences" : isSaving ? "Saving" : "Saved"}</span>
                      </StyledSaveButton>
                    </StyledSaveButtonWrapper>
                  </StyledDiv>
                </Col>
              </>
            ) : (
              <Col sm={24} md={22} xl={16}>
                <Suspense
                  fallback={
                    <ContentLoader
                      speed={2}
                      width="100%"
                      height={450}
                      backgroundColor={COLORS.primary.grayLight}
                      foregroundColor={COLORS.primary.grayLighter}
                    >
                      <circle cx="30" cy="38" r="20" />
                      <rect x="60" y="23" rx="5" ry="5" width="50%" height="12" />
                      <rect x="60" y="43" rx="5" ry="5" width="70%" height="12" />

                      <circle cx="30" cy="114" r="20" />
                      <rect x="60" y="99" rx="5" ry="5" width="50%" height="12" />
                      <rect x="60" y="119" rx="5" ry="5" width="70%" height="12" />

                      <circle cx="30" cy="190" r="20" />
                      <rect x="60" y="175" rx="5" ry="5" width="50%" height="12" />
                      <rect x="60" y="195" rx="5" ry="5" width="70%" height="12" />

                      <circle cx="30" cy="266" r="20" />
                      <rect x="60" y="251" rx="5" ry="5" width="50%" height="12" />
                      <rect x="60" y="271" rx="5" ry="5" width="70%" height="12" />

                      <circle cx="30" cy="342" r="20" />
                      <rect x="60" y="327" rx="5" ry="5" width="50%" height="12" />
                      <rect x="60" y="347" rx="5" ry="5" width="70%" height="12" />
                    </ContentLoader>
                  }
                >
                  <UnconnectedWalletState
                    handleClick={wallet?.openWalletModal}
                    title="A Missed Connection"
                    subTitle={
                      <>
                        Want a more personalized experience? <br /> Connect that wallet to see more.
                      </>
                    }
                  />
                </Suspense>
              </Col>
            )}
          </StyledRow>
        </Col>
      </StyledRowMobile>
    </>
  );
};
export default React.memo(SettingsEmail);
