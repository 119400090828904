import { DelegatePost } from "@boardroom/boardroom-api";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Select, { BaseOptionType, DefaultOptionType } from "antd/es/select";
import notification from "antd/es/notification";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import { useLocation } from "react-router";

import { COLORS } from "../../constants/colors";
import { protocols } from "../../constants/protocols";
import { useSiwe } from "../../hooks/useSiwe";
import { CurrentAccountContext } from "../../reducers/CurrentAccount";
import { apiClient } from "../../utils/apiClient";
import { getUuid } from "../../utils/getUuid";
import { Loader } from "../Loader";
import { Modal } from "../Modal";
import ProtocolIcon from "../ProtocolIcon";
import { useMixpanel } from "../../hooks";
import { ExpandCollapseArrowThin } from "../icons";
import { isTeamView } from "../../utils/teamUtils";

const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Title = styled.h3`
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;

  color: #191540;
  margin-bottom: 0;
`;

const PostToText = styled.span`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  color: #191540;
  margin: 24px 0 12px;
`;

export const ProjectSelect = styled(Select)`
  background: #f0eff8;

  .ant-select-selector {
    border: none !important;
    background: transparent !important;
    padding: 0 !important;
    .ant-select-selection-item {
      color: inherit;
    }
  }

  &.ant-select-open {
    .ant-select-arrow {
      div {
        transform: rotate(180deg);
        transition: transform 0.2s;
      }
    }
  }

  border: 1px solid #4235e1;
  border-radius: 8px;
  padding: 6px 8px;
  width: 50%;
  cursor: pointer;
  color: #4235e1;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  text-align: left;
`;

const ProjectSelectNoSelect = styled.button`
  background: #f0eff8;

  border: 1px solid #4235e1;
  border-radius: 8px;
  padding: 6px 8px;
  width: 50%;
  cursor: pointer;
  color: #4235e1;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  text-align: left;
`;

const PostInputLabel = styled.span`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  color: #191540;
  margin: 40px 0 12px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: end;
  margin: 24px 0;
`;

const SubmitPost = styled.button`
  background: #948fb9;
  border-radius: 8px;
  padding: 12px 0;
  width: 100%;
  border: none;
  color: white;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s all;
  :hover {
    background: #4235e1;
    color: white;
  }
  :focus,
  :active {
    background: #291fa7;
    color: white;
  }
  span {
    margin-left: 4px;
  }
  :disabled {
    cursor: not-allowed;
  }
`;

const CancelButton = styled.button`
  text-align: center;
  text-decoration-line: underline;
  text-transform: capitalize;

  color: #7b7893;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  background: transparent;
  border: none;
  cursor: pointer;
`;

const StyledMDEditor = styled(ReactQuill)<{ $disabled?: boolean }>`
  margin-bottom: 10px;
  ${({ $disabled }) =>
    $disabled &&
    css`
      cursor: not-allowed;
      .ql-editor > * {
        cursor: not-allowed;
      }
    `}
  .ql-toolbar {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  .ql-container {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  border-radius: 8px;
  border: 1px solid transparent;
  &:hover {
    border: 1px solid ${COLORS.primary.accent};
  }
`;

export const ContentWrapper = styled.div<{ $isDeactivated?: boolean }>`
  background: #ffffff;
  border: 1px solid #7b7893;
  box-shadow: 0px 2px 6px rgba(25, 21, 64, 0.05);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 16px;
  padding-bottom: 50px;
  ${({ $isDeactivated }) => $isDeactivated && "background-color: #fafafa; cursor: not-allowed; opacity: 0.4;"}
`;

export const ContentTitle = styled.h2`
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #7b7893;

  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin-bottom: 0;
  border-bottom: 1px solid #f0eff8;
  padding-bottom: 8px;
`;

export const SelectProjectText = styled.span`
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #191540;
  margin: 16px 0 12px;
`;

interface Props {
  open: boolean;
  onClose: any;
  protocol: string;
  post?: DelegatePost;
  refetch: any;
  delegateForProtocols?: string[];
  addressToPostTo?: string;
}

export const PostModalContent = ({ delegateForProtocols }: { delegateForProtocols: string[] }) => {
  const [protocolToUse, setProtocolToUse] = useState("");
  const [loading, setLoading] = useState(false);
  const reactQuillRef = useRef<any>(null);
  const { account } = useContext(CurrentAccountContext);
  const uuid = getUuid(account);
  const [editorContent, setEditorContent] = useState("");
  const { search, pathname } = useLocation();
  const query = new URLSearchParams(search);
  const teamWallet = query.get("bundleWallet");
  const isOnTeamView = isTeamView(pathname, search);
  const { trackCreatePostDelegationTab } = useMixpanel();

  const filterProjectSearch = useCallback(
    (input: string, option: DefaultOptionType | BaseOptionType | undefined) =>
      (option?.value ? protocols[option?.value].name : "").toLowerCase().includes(input.toLowerCase()),
    [],
  );

  const Submit = useCallback(async () => {
    try {
      setLoading(true);
      if (!uuid) {
        notification.error({
          message: "Error",
          description: "You need to sign in with wallet to create a post",
        });
        setLoading(false);
        return;
      }

      const data: any = {
        author: account,
        postBody: editorContent,
        protocol: protocolToUse,
        uuid,
        postedForAddress: isOnTeamView ? teamWallet : "",
      };
      await apiClient.createDelegatePost(data);
      notification.success({
        message: "Success",
        description: "Your post has been created",
        duration: 10,
      });
      trackCreatePostDelegationTab({
        userId: account,
        protocol: protocolToUse,
      });
      setEditorContent("");
    } catch (error) {
      console.error(error);
      notification.error({
        message: "Error",
        description: "Something went wrong... Please try again later",
      });
    } finally {
      setLoading(false);
    }
  }, [account, editorContent, isOnTeamView, protocolToUse, teamWallet, trackCreatePostDelegationTab, uuid]);

  useEffect(() => {
    reactQuillRef?.current?.focus();
  }, []);

  return (
    <>
      {!!delegateForProtocols?.length && (
        <>
          <ContentWrapper>
            <ContentTitle>POST TO A PROJECT</ContentTitle>
            <SelectProjectText>Select Project</SelectProjectText>
            <ProjectSelect
              style={{ padding: "0 8px", width: "100%", marginBottom: "32px" }}
              suffixIcon={
                <div>
                  <ExpandCollapseArrowThin color={COLORS.primary.accent} width={10} height={8} />
                </div>
              }
              showSearch
              onChange={(value: any) => setProtocolToUse(value)}
              filterOption={filterProjectSearch}
            >
              {delegateForProtocols?.map((protocol) => (
                <option value={protocol} key={protocol}>
                  <ProtocolIcon size="xsmall" protocol={protocols[protocol]} />
                  <span style={{ marginLeft: "8px" }}>{protocols[protocol]?.name}</span>
                </option>
              ))}
            </ProjectSelect>
            <SelectProjectText>Your Message</SelectProjectText>
            <StyledMDEditor
              ref={reactQuillRef}
              placeholder="Add your note here"
              value={editorContent}
              onChange={(value) => setEditorContent(value || "")}
            />
          </ContentWrapper>
          <ButtonsWrapper style={{ marginTop: "32px", paddingTop: "16px", borderTop: "1px solid #F0EFF8" }}>
            <SubmitPost disabled={loading || !editorContent} onClick={Submit}>
              {loading && <Loader size="xsmall" />} <span>Post</span>
            </SubmitPost>
          </ButtonsWrapper>
        </>
      )}
    </>
  );
};

const PostModal = ({ open, onClose, protocol, post, refetch, delegateForProtocols, addressToPostTo }: Props) => {
  const reactQuillRef = useRef<any>(null);
  const { account } = useContext(CurrentAccountContext);
  const uuid = getUuid(account);
  const [editorContent, setEditorContent] = useState(post ? post.postBody : "");
  const [loading, setLoading] = useState(false);
  const { pathname, search } = useLocation();
  useSiwe({ trigger: open });

  const [protocolToUse, setProtocolToUse] = useState(protocol);
  const { trackCreatePostDelegationTab, trackEditPostDelegationTab } = useMixpanel();
  const isOnTeamView = isTeamView(pathname, search);

  useEffect(() => {
    setProtocolToUse(protocol);
  }, [protocol]);

  const Submit = useCallback(async () => {
    try {
      setLoading(true);
      if (!uuid) {
        notification.error({
          message: "Error",
          description: post
            ? "You need to sign in with wallet to edit a post"
            : "You need to sign in with wallet to create a post",
        });
        setLoading(false);
        return;
      }
      if (post) {
        await apiClient.editDelegatePost({
          author: account,
          postBody: editorContent,
          postId: post.postId,
          uuid,
        });
        notification.success({
          message: "Success",
          description: "Your post has been edited",
          duration: 10,
        });
        trackEditPostDelegationTab({
          userId: account,
          protocol: post.protocol,
        });
      } else {
        const data: any = {
          author: account,
          postBody: editorContent,
          protocol: protocolToUse,
          uuid,
          postedForAddress: isOnTeamView ? addressToPostTo : "",
        };
        await apiClient.createDelegatePost(data);
        notification.success({
          message: "Success",
          description: "Your post has been created",
          duration: 10,
        });
        trackCreatePostDelegationTab({
          userId: account,
          protocol: protocolToUse,
        });
      }
      setEditorContent("");
      if (onClose) {
        onClose();
      }
      if (refetch) {
        refetch();
      }
    } catch (error) {
      console.error(error);
      notification.error({
        message: "Error",
        description: "Something went wrong... Please try again later",
      });
    } finally {
      setLoading(false);
    }
  }, [
    uuid,
    post,
    onClose,
    refetch,
    account,
    editorContent,
    trackEditPostDelegationTab,
    protocolToUse,
    isOnTeamView,
    addressToPostTo,
    trackCreatePostDelegationTab,
  ]);

  useEffect(() => {
    reactQuillRef?.current?.focus();
  }, []);

  return (
    <Modal zIndex={1001} size="large" customMaxWidth="650px" onClose={onClose} open={open}>
      <ModalWrapper>
        <Title>Create Post</Title>
        <PostToText>Post To</PostToText>
        {delegateForProtocols?.length ? (
          <ProjectSelect
            suffixIcon={
              <div>
                <ExpandCollapseArrowThin color={COLORS.primary.accent} width={10} height={8} />
              </div>
            }
            defaultValue={protocol}
            onChange={(value: any) => setProtocolToUse(value)}
          >
            {delegateForProtocols?.map((protocol) => (
              <option value={protocol} key={protocol}>
                <ProtocolIcon size="xsmall" protocol={protocols[protocol]} />
                <span style={{ marginLeft: "8px" }}>{protocols[protocol]?.name}</span>
              </option>
            ))}
          </ProjectSelect>
        ) : (
          <ProjectSelectNoSelect>
            <ProtocolIcon size="xsmall" protocol={protocols[protocol]} />
            <span style={{ marginLeft: "8px" }}>{protocols[protocol]?.name}</span>
          </ProjectSelectNoSelect>
        )}
        <PostInputLabel>Your Message</PostInputLabel>
        <StyledMDEditor
          ref={reactQuillRef}
          placeholder="Add your note here"
          value={editorContent}
          onChange={(value) => setEditorContent(value || "")}
        />
        <ButtonsWrapper>
          <SubmitPost disabled={loading || !editorContent} onClick={Submit}>
            {loading && <Loader size="xsmall" />} <span>Post</span>
          </SubmitPost>
        </ButtonsWrapper>
        <CancelButton onClick={onClose}>Cancel</CancelButton>
      </ModalWrapper>
    </Modal>
  );
};

export { PostModal };
