import { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import { Alchemy, Network, TokenBalancesResponse } from "alchemy-sdk";
import { ProtocolDetail } from "@boardroom/boardroom-api";

import { useProtocolsTokens, useProtocols } from "./useProtocols";
import { apiClient } from "../utils/apiClient";
import { ProtocolInfo } from "../types";

const providerUrl = process.env.REACT_APP_RPC_URL_1;
const providerUrlSplitted = providerUrl?.split("/");
const alchemyApiKey = providerUrlSplitted?.[providerUrlSplitted?.length - 1];

const config = {
  apiKey: alchemyApiKey,
  network: Network.ETH_MAINNET,
};
const alchemy = new Alchemy(config);

const configOptimism = {
  apiKey: alchemyApiKey,
  network: Network.OPT_MAINNET,
};
const alchemyOptimism = new Alchemy(configOptimism);

const configArbitrum = {
  apiKey: alchemyApiKey,
  network: Network.ARB_MAINNET,
};
const alchemyArbitrum = new Alchemy(configArbitrum);

const configPolygon = {
  apiKey: alchemyApiKey,
  network: Network.MATIC_MAINNET,
};
const alchemyPolygon = new Alchemy(configPolygon);

interface Props {
  address: string;
  suspense?: boolean;
}

const filterProtocolsSupported = (
  protocolsArray: (ProtocolDetail &
    ProtocolInfo & {
      categories: string[];
    })[],
) =>
  protocolsArray.reduce((allContractAddresses, protocol) => {
    const protocolContractAddresses = protocol.tokens?.map((token) => token.contractAddress);
    return {
      ...allContractAddresses,
      ...protocolContractAddresses?.reduce(
        (addressesOfContracts, address) => ({
          ...addressesOfContracts,
          [address]: true,
        }),
        {},
      ),
    };
  }, {});

export const useUserProtocols = ({ address, suspense = true }: Props) => {
  const { protocols } = useProtocols();
  const protocolsArray = useMemo(() => Object.values(protocols), [protocols]);
  const protocolsTokens = useProtocolsTokens();
  const protocolsTokenAddresses = protocolsTokens.map((token) => token?.contractAddress || "");

  const { data: balances1, status: status1 } = useQuery<TokenBalancesResponse, Error>(
    [`tokenBalances1:${address?.toLowerCase()}`],
    () => {
      try {
        return alchemy.core.getTokenBalances(address, protocolsTokenAddresses);
      } catch (error) {
        console.error(error);
        return {} as TokenBalancesResponse;
      }
    },
    { enabled: !!address && !!protocolsTokenAddresses.length, suspense },
  );
  const { data: balances10, status: status10 } = useQuery<TokenBalancesResponse, Error>(
    [`tokenBalances10:${address?.toLowerCase()}`],
    () => {
      try {
        return alchemyOptimism.core.getTokenBalances(address, protocolsTokenAddresses);
      } catch (error) {
        console.error(error);
        return {} as TokenBalancesResponse;
      }
    },
    { enabled: !!address && !!protocolsTokenAddresses.length, suspense },
  );

  const { data: balances42161, status: status42161 } = useQuery<TokenBalancesResponse, Error>(
    [`tokenBalances42161:${address?.toLowerCase()}`],
    () => {
      try {
        return alchemyArbitrum.core.getTokenBalances(address, protocolsTokenAddresses);
      } catch (error) {
        console.error(error);
        return {} as TokenBalancesResponse;
      }
    },
    { enabled: !!address && !!protocolsTokenAddresses.length, suspense },
  );

  const { data: balances137, status: status137 } = useQuery<TokenBalancesResponse, Error>(
    [`tokenBalances137:${address?.toLowerCase()}`],
    () => {
      try {
        return alchemyPolygon.core.getTokenBalances(address, protocolsTokenAddresses);
      } catch (error) {
        console.error(error);
        return {} as TokenBalancesResponse;
      }
    },
    { enabled: !!address && !!protocolsTokenAddresses.length, suspense },
  );

  const mergedAddressesFiltered = useMemo(
    () =>
      [
        ...(balances1?.tokenBalances || []),
        ...(balances10?.tokenBalances || []),
        ...(balances137?.tokenBalances || []),
        ...(balances42161?.tokenBalances || []),
      ]
        .filter((token) => {
          return (
            token.tokenBalance !== "0" &&
            token.tokenBalance !== "0x0000000000000000000000000000000000000000000000000000000000000000" &&
            token.tokenBalance !== "0x" &&
            token.tokenBalance !== "0x0" &&
            token.tokenBalance !== null &&
            token.tokenBalance !== undefined
          );
        })
        .map((token) => token.contractAddress),
    [balances1, balances10, balances137, balances42161],
  );

  const userProtocols = useMemo(() => {
    return mergedAddressesFiltered
      .map((contractAddress) => {
        const protocolFound = protocolsArray.find((protocol) =>
          protocol.tokens?.map((token) => token.contractAddress).includes(contractAddress),
        );
        return protocolFound?.cname;
      })
      .filter(Boolean) as string[];
  }, [mergedAddressesFiltered, protocolsArray]);

  return useMemo(
    () => ({
      protocolsInWallet: userProtocols,
      status:
        (status1 === "success" || status1 === "error") &&
        (status10 === "success" || status10 === "error") &&
        (status42161 === "success" || status42161 === "error") &&
        (status137 === "success" || status137 === "error")
          ? "success"
          : "loading",
    }),
    [status1, status10, status137, userProtocols, status42161],
  );
};

interface UseProtocolsInWalletsProps {
  addresses: Array<string>;
  suspense?: boolean;
}

export const useProtocolsInWallets = ({ addresses, suspense = true }: UseProtocolsInWalletsProps) => {
  const { protocols } = useProtocols();
  const protocolsArray = useMemo(() => Object.values(protocols), [protocols]);

  const { data: balances } = useQuery<any, Error>(
    [`useProtocolsInWallets:${addresses.toString()}`],
    async () => {
      try {
        return await apiClient.getTokenBalancesForAddresses(addresses);
      } catch (error) {
        console.error(error);
        return {} as any;
      }
    },
    { enabled: !!addresses.filter((address) => address !== "").length, suspense },
  );

  const tokensData = balances?.data;

  const mergedAddressesFiltered = useMemo(() => {
    const flatTokenBalances = tokensData
      ?.map((addressBalances: any) => {
        return [
          ...addressBalances.tokens.eth,
          ...addressBalances.tokens.polygon,
          ...addressBalances.tokens.optimism,
          ...(addressBalances.tokens.arbitrum || []),
        ];
      })
      .flat();

    const filteredBalance = flatTokenBalances?.filter((token: any) => {
      return (
        token.tokenBalance !== "0" &&
        token.tokenBalance !== "0x0000000000000000000000000000000000000000000000000000000000000000" &&
        token.tokenBalance !== "0x" &&
        token.tokenBalance !== "0x0" &&
        token.tokenBalance !== null &&
        token.tokenBalance !== undefined
      );
    });

    return filteredBalance || [];
  }, [tokensData]);

  const addressProtocols = mergedAddressesFiltered
    .map((token: any) => {
      const protocolFound = protocolsArray.find((protocol) =>
        protocol.tokens?.map((token) => token.contractAddress).includes(token.contractAddress),
      );
      return protocolFound?.cname;
    })
    .filter(Boolean) as string[];

  return addressProtocols;
};

const filterTokensBySupportedProtocols = (
  tokens: {
    contractAddress: string;
    tokenBalance: string;
  }[],
  protocolsContracts: Record<string, boolean>,
) => {
  return tokens.filter((token) => {
    return (
      token.tokenBalance !== "0" &&
      token.tokenBalance !== "0x0000000000000000000000000000000000000000000000000000000000000000" &&
      token.tokenBalance !== "0x" &&
      token.tokenBalance !== "0x0" &&
      token.tokenBalance !== null &&
      token.tokenBalance !== undefined &&
      protocolsContracts[token.contractAddress]
    );
  });
};

export const useBalancesInAddresses = (addresses: Array<string>): Record<string, Array<string>> => {
  const { protocols } = useProtocols();
  const protocolsArray = useMemo(() => Object.values(protocols), [protocols]);

  const { data: balances } = useQuery<any, Error>(
    [`useBalancesInAddresses:${addresses.toString()}`],
    async () => {
      try {
        return await apiClient.getTokenBalancesForAddresses(addresses);
      } catch (error) {
        console.error(error);
        return {} as any;
      }
    },
    { enabled: !!addresses.filter((address) => address !== "").length },
  );

  const tokensData = balances?.data;

  const ProtocolsMappedByAddress = useMemo(() => {
    const filteredBalances = tokensData?.map((addressBalance: any) => {
      return {
        address: addressBalance.address,
        tokens: [
          ...filterTokensBySupportedProtocols(addressBalance.tokens.eth, filterProtocolsSupported(protocolsArray)),
          ...filterTokensBySupportedProtocols(addressBalance.tokens.eth, filterProtocolsSupported(protocolsArray)),
          ...filterTokensBySupportedProtocols(addressBalance.tokens.optimism, filterProtocolsSupported(protocolsArray)),
          ...filterTokensBySupportedProtocols(
            addressBalance.tokens.arbitrum || [],
            filterProtocolsSupported(protocolsArray),
          ),
        ],
      };
    });

    return (
      filteredBalances ||
      addresses.map((address) => {
        return {
          address,
          tokens: [],
        };
      })
    );
  }, [tokensData, addresses, protocolsArray]);

  const tokenContractsGroupedByAddress = useMemo(() => {
    const tokenContractsByAddress = ProtocolsMappedByAddress.map((tokensByAddress: any) => {
      return {
        address: tokensByAddress.address,
        tokenContracts: tokensByAddress.tokens.reduce((acc: any, tokens: any) => {
          return {
            ...acc,
            [tokens.contractAddress]: true,
          };
        }, {}) as Record<string, Boolean>,
      };
    });

    const allTokenContracts = ProtocolsMappedByAddress.reduce(
      (allContractsAcc: string[], tokensContractsByAddress: any) => {
        const tokenContracts = tokensContractsByAddress.tokens.reduce(
          (contractByAddressAcc: string[], tokensByAddress: any) => {
            return [...contractByAddressAcc, tokensByAddress.contractAddress];
          },
          [],
        );

        return [...allContractsAcc, ...tokenContracts];
      },
      [],
    );

    const protocolSet = new Set(allTokenContracts);
    const uniqueProtocolContracts = Array.from(protocolSet);

    const formattedProtocolArray = uniqueProtocolContracts.reduce((acc: any, contractAddress: any) => {
      let tokensContractsAvailableInAddress: string[] = [];
      tokenContractsByAddress.map((tokensForAddress: any) => {
        if (tokensForAddress.tokenContracts[contractAddress]) {
          tokensContractsAvailableInAddress = [...tokensContractsAvailableInAddress, tokensForAddress.address];
        }
      });
      return {
        ...acc,
        [contractAddress]: tokensContractsAvailableInAddress,
      };
    }, {});
    return formattedProtocolArray;
  }, [ProtocolsMappedByAddress]);

  return tokenContractsGroupedByAddress as Record<string, string[]>;
};
