import * as React from "react";

const SvgSwitchWalletIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" {...props}>
    <path
      d="M6.8 16.882H4.202c-.358 0-.787-.15-1.075-.628-.195-.323-.325-.797-.325-1.477v-2.895"
      stroke="currentColor"
      strokeWidth={0.8}
      strokeLinecap="round"
    />
    <path d="M.8 13.882l2-2 2 2" stroke="currentColor" strokeWidth={0.8} strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M5 8.882h1.25c.447 0 .984.12 1.344.503.243.259.406.638.406 1.181v2.316"
      stroke="currentColor"
      strokeWidth={0.8}
      strokeLinecap="round"
    />
    <path
      d="M10 11.882l-2 2-2-2"
      stroke="currentColor"
      strokeWidth={0.8}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.7 5.4h13.4c.942 0 1.414 0 1.707.293.293.293.293.764.293 1.707v2.1M2.7 5.4v1.5m0-1.5l11.6-2.356c.555-.113.833-.17 1.016-.02.184.15.184.434.184 1V5.2m2.6 7.8v3.382c0 .236 0 .354-.074.427-.073.073-.19.073-.426.073h-7.2M18.1 13h-4.634c-.235 0-.353 0-.426-.073-.074-.073-.074-.191-.074-.427V10c0-.236 0-.354.074-.427.073-.073.19-.073.426-.073H18.1m0 3.5V9.5"
      stroke="currentColor"
      strokeWidth={1.1}
      strokeLinecap="round"
    />
  </svg>
);

export default SvgSwitchWalletIcon;
