import React, { useCallback, useContext } from "react";
import { useCurrentWidth } from "react-socks";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";

import { Modal } from "../Modal";
import { COLORS } from "../../constants/colors";
import { SuccessIcon } from "../icons";
import { CurrentTxHashContext } from "../../reducers/CurrentTxHash";

const StyledTitle = styled.div`
  text-align: center;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  margin-top: 40px;
`;

const StyledText = styled.div`
  color: ${COLORS.primary.grayDarkLightest};
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  width: 50%;
  margin: 16px auto 12px;
`;

const StyledLink = styled.a`
  text-align: center;
  text-decoration: underline;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: ${COLORS.primary.accent};
  cursor: pointer;
`;

const StyledFooter = styled.div`
  border-top: 1px solid ${COLORS.primary.grayLighter};
  width: 100%;
  display: flex;
  margin-top: 32px;
  padding: 24px 48px;
  justify-content: flex-end;
  gap: 48px;
`;

const StyledFooterText = styled.div<{ fontWeight: number; color: string }>`
  text-transform: uppercase;
  color: ${({ color }) => color};
  font-weight: ${({ fontWeight }) => fontWeight};
  cursor: pointer;
  &:hover {
    opacity: 0.9;
    color: ${COLORS.primary.accent};
  }
`;

interface Props {
  visible: boolean;
}

interface Params {
  protocol: string;
}

const DelegationConfirmModal = ({ visible }: Props) => {
  const currentWidth = useCurrentWidth();
  const { protocol } = useParams<Params>();
  const { txHash, dispatchTxHash } = useContext(CurrentTxHashContext);
  const { protocolName, txHash: txHashValue } = txHash!;

  const closeSetupDelegationModal = useCallback(() => {
    dispatchTxHash({ type: "SAVE_TXHASH", data: null });
  }, [dispatchTxHash]);

  return (
    <>
      <Modal
        onClose={closeSetupDelegationModal}
        open={visible}
        size={currentWidth < 640 ? "small" : "medium"}
        withPadding={false}
      >
        <Modal.Body>
          <StyledTitle>Delegation Confirmed</StyledTitle>
          <StyledText>
            Hang tight. It can take up to an hour for delegation changes to reflect on your dashboard and voter profile.
          </StyledText>
          <div style={{ textAlign: "center" }}>
            <div>
              <SuccessIcon width="56px" height="56px" color={COLORS.secondary.purple} style={{ margin: "16px 0" }} />
            </div>
            <StyledLink
              href={
                protocolName === "optimism"
                  ? `https://optimistic.etherscan.io/tx/${txHashValue}`
                  : protocolName === "arbitrum"
                  ? `https://arbiscan.io/tx/${txHashValue}`
                  : `https://etherscan.io/tx/${txHashValue}`
              }
              target="_blank"
            >
              View Transaction
            </StyledLink>
          </div>

          <StyledFooter>
            <Link to={protocol ? `/${protocol}/delegates` : `/${protocolName}/delegates`} target="_blank">
              <StyledFooterText fontWeight={400} color={COLORS.primary.grayDarkLightest}>
                See Delegates
              </StyledFooterText>
            </Link>
            <StyledFooterText fontWeight={600} color={COLORS.primary.accent} onClick={closeSetupDelegationModal}>
              Okay
            </StyledFooterText>
          </StyledFooter>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default DelegationConfirmModal;
