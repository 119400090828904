import React, { useReducer, createContext, useRef } from "react";

interface Props {
  children: React.ReactNode;
}

type Action = { type: "SAVE_CAST_VOTE_REF"; data: React.RefObject<HTMLElement> };

interface CastVoteRefContextType {
  castVoteRef: React.RefObject<HTMLElement>;
  dispatchCastVoteRef: React.Dispatch<any>;
}

export const CastVoteRefContext = createContext({} as CastVoteRefContextType);

const reducer = (state: React.RefObject<HTMLElement>, action: Action) => {
  switch (action.type) {
    case "SAVE_CAST_VOTE_REF":
      return action.data;
    default:
      return state;
  }
};

export const CastVoteRefProvider = (props: Props) => {
  const initialState = useRef(null);
  const [castVoteRef, dispatchCastVoteRef] = useReducer(reducer, initialState);

  return (
    <CastVoteRefContext.Provider value={{ castVoteRef, dispatchCastVoteRef }}>
      {props.children}
    </CastVoteRefContext.Provider>
  );
};
