import { useLocation, useParams } from "react-router-dom";
import { getProtocolCnameFromPath } from "../constants/protocols";

interface Params {
  protocol?: string;
}

export const useGetCnameFromUrl = () => {
  const { protocol } = useParams<Params>();

  const query = new URLSearchParams(useLocation().search);
  const protocolQuery = query.get("protocol");
  const cname = getProtocolCnameFromPath(protocol || protocolQuery || "");
  return cname;
};
