import React, { useReducer, createContext } from "react";

interface Props {
  children: React.ReactNode;
}

type Action = { type: "TOGGLE_STATE"; data: boolean };

interface SearchDropdownContextType {
  searchDropDownState: boolean;
  dispatchSearchDropDownState: React.Dispatch<any>;
}

const initialState = false;

export const SearchDropdownContext = createContext({} as SearchDropdownContextType);

const reducer = (state: boolean, action: Action) => {
  switch (action.type) {
      case "TOGGLE_STATE":
          return action.data;
    default:
      return state;
    }
};

export const SearchDropdownStateProvider = (props: Props) => {
  const [searchDropDownState, dispatchSearchDropDownState] = useReducer(reducer, initialState);

  return (
    <SearchDropdownContext.Provider value={{ searchDropDownState, dispatchSearchDropDownState }}>
      {props.children}
    </SearchDropdownContext.Provider>
  );
};
