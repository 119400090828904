import { useCallback, useContext, useEffect, useMemo } from "react";
import { Web3Provider } from "@ethersproject/providers";
import axios from "axios";
import { init, useConnectWallet, useWallets } from "@web3-onboard/react";
import injectedModule from "@web3-onboard/injected-wallets";
import coinbaseWalletModule from "@web3-onboard/coinbase";
import trezorModule from "@web3-onboard/trezor";
import keepkeyModule from "@web3-onboard/keepkey";
import ledgerModule from "@web3-onboard/ledger";
import gnosisModule from "@web3-onboard/gnosis";
import walletConnectModule from "@web3-onboard/walletconnect";
import Cookies from "universal-cookie";
import { singletonHook } from "react-singleton-hook";

import { useMixpanel } from "../hooks";
import { CurrentAccountContext } from "../reducers/CurrentAccount";
import { CurrentUuidContext } from "../reducers/CurrentUuid";
import { CurrentWeb3Context } from "../reducers/CurrentWeb3Provider";
import { WalletProviderContext } from "../reducers/WalletProvider";
import { getUuid } from "../utils/getUuid";
import { MixpanelContext, NotificationsContext } from "../contexts";
import { isProduction, isStaging } from "../utils";
import { baseAPIUrl, BOARDROOM_API_KEY } from "../constants/general";
import { useIsMultisigSignIn } from "./useIsMultisigSignIn";
import { CurrentDaoLoginContext } from "../reducers/DaoLogin";
import { useSiweDelegators } from "./useSiweDelegators";
import SafeAppsSDK from "@gnosis.pm/safe-apps-sdk";
import { CurrentUserDetailsContext } from "../reducers/CurrentUserDetails";
import moment from "moment";
import { NftSubscriptionContext } from "../reducers/NftSubscription";

type Opts = {
  allowedDomains?: RegExp[];
  debug?: boolean;
};

const opts: Opts = {
  allowedDomains: [/gnosis-safe.io$/, /app.safe.global$/],
  debug: false,
};

const appsSdk = new SafeAppsSDK(opts);

const cookies = new Cookies();

const injected: any = injectedModule();
const walletConnect = walletConnectModule({
  version: 2,
  projectId: "3aeb9102c2440b56fb9b9ad852f50ef0",
  dappUrl: "https://boardroom.io",
});
const coinbaseWallet = coinbaseWalletModule();
const keepkeyWallet = keepkeyModule();
const trezorWallet = trezorModule({
  email: "pedro@boardroom.info",
  appUrl: "https://boardroom.io",
});
const ledger = ledgerModule({
  walletConnectVersion: 2,
  projectId: "3aeb9102c2440b56fb9b9ad852f50ef0",
});
const gnosis = gnosisModule();
const MAINNET_RPC_URL = process.env.REACT_APP_RPC_URL_1;
const OPTIMISM_RPC_URL = process.env.REACT_APP_RPC_URL_10;
const ARBITRUM_RPC_URL = process.env.REACT_APP_RPC_URL_42161;
const GOERLI_RPC_URL = process.env.REACT_APP_RPC_URL_5;

init({
  wallets: [injected, coinbaseWallet, keepkeyWallet, trezorWallet, walletConnect, ledger, gnosis],
  accountCenter: {
    desktop: {
      enabled: false,
      containerElement: "body",
    },
    mobile: {
      enabled: false,
      containerElement: "body",
    },
  },
  appMetadata: {
    name: "Boardroom",
    description: "Boardroom",
    icon: `<svg width="377" height="71" viewBox="0 0 377 71" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0)">
<path d="M92.516 26.1063C88.2292 26.1063 85.1169 27.6918 83.0029 30.3931V15.1838H75.4276V56.29H83.0029V52.8254C85.1169 55.5266 88.2292 57.1122 92.516 57.1122C100.385 57.1122 106.844 50.359 106.844 41.6092C106.844 32.8595 100.385 26.1063 92.516 26.1063ZM91.1067 49.8892C86.4675 49.8892 83.0029 46.542 83.0029 41.6092C83.0029 36.6765 86.4675 33.3293 91.1067 33.3293C95.8045 33.3293 99.2692 36.6765 99.2692 41.6092C99.2692 46.542 95.8045 49.8892 91.1067 49.8892ZM126.163 57.1122C134.795 57.1122 141.725 50.359 141.725 41.6092C141.725 32.8595 134.795 26.1063 126.163 26.1063C117.531 26.1063 110.66 32.8595 110.66 41.6092C110.66 50.359 117.531 57.1122 126.163 57.1122ZM126.163 49.713C121.7 49.713 118.235 46.3658 118.235 41.6092C118.235 36.8527 121.7 33.5054 126.163 33.5054C130.685 33.5054 134.149 36.8527 134.149 41.6092C134.149 46.3658 130.685 49.713 126.163 49.713ZM169.369 26.9284V30.3931C167.255 27.7506 164.084 26.1063 159.797 26.1063C151.987 26.1063 145.527 32.8595 145.527 41.6092C145.527 50.359 151.987 57.1122 159.797 57.1122C164.084 57.1122 167.255 55.4679 169.369 52.8254V56.29H176.944V26.9284H169.369ZM161.206 49.8892C156.567 49.8892 153.102 46.542 153.102 41.6092C153.102 36.6765 156.567 33.3293 161.206 33.3293C165.904 33.3293 169.369 36.6765 169.369 41.6092C169.369 46.542 165.904 49.8892 161.206 49.8892ZM191.388 31.9786V26.9284H183.813V56.29H191.388V42.2552C191.388 36.0893 196.38 34.3276 200.314 34.7973V26.3412C196.615 26.3412 192.915 27.9854 191.388 31.9786ZM225.911 15.1838V30.3931C223.797 27.6918 220.685 26.1063 216.398 26.1063C208.529 26.1063 202.07 32.8595 202.07 41.6092C202.07 50.359 208.529 57.1122 216.398 57.1122C220.685 57.1122 223.797 55.5266 225.911 52.8254V56.29H233.487V15.1838H225.911ZM217.808 49.8892C213.11 49.8892 209.645 46.542 209.645 41.6092C209.645 36.6765 213.11 33.3293 217.808 33.3293C222.447 33.3293 225.911 36.6765 225.911 41.6092C225.911 46.542 222.447 49.8892 217.808 49.8892Z" fill="#191640"/>
<path d="M247.99 31.9786V26.9284H240.415V56.29H247.99V42.2552C247.99 36.0893 252.981 34.3276 256.916 34.7973V26.3412C253.216 26.3412 249.517 27.9854 247.99 31.9786ZM274.233 57.1122C282.865 57.1122 289.794 50.359 289.794 41.6092C289.794 32.8595 282.865 26.1063 274.233 26.1063C265.6 26.1063 258.73 32.8595 258.73 41.6092C258.73 50.359 265.6 57.1122 274.233 57.1122ZM274.233 49.713C269.77 49.713 266.305 46.3658 266.305 41.6092C266.305 36.8527 269.77 33.5054 274.233 33.5054C278.754 33.5054 282.219 36.8527 282.219 41.6092C282.219 46.3658 278.754 49.713 274.233 49.713ZM309.1 57.1122C317.732 57.1122 324.661 50.359 324.661 41.6092C324.661 32.8595 317.732 26.1063 309.1 26.1063C300.467 26.1063 293.597 32.8595 293.597 41.6092C293.597 50.359 300.467 57.1122 309.1 57.1122ZM309.1 49.713C304.637 49.713 301.172 46.3658 301.172 41.6092C301.172 36.8527 304.637 33.5054 309.1 33.5054C313.621 33.5054 317.086 36.8527 317.086 41.6092C317.086 46.3658 313.621 49.713 309.1 49.713ZM362.464 26.1063C358.471 26.1063 355.535 27.6918 353.715 30.3344C352.07 27.6331 349.369 26.1063 345.728 26.1063C341.97 26.1063 339.21 27.5744 337.566 30.0408V26.9284H329.99V56.29H337.566V39.7888C337.566 35.3846 339.856 33.1531 343.086 33.1531C346.257 33.1531 348.018 35.2671 348.018 38.7318V56.29H355.594V39.7888C355.594 35.3846 357.708 33.1531 361.055 33.1531C364.226 33.1531 365.988 35.2671 365.988 38.7318V56.29H373.563V38.2033C373.563 30.8629 369.159 26.1063 362.464 26.1063Z" fill="#4235E1"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M16.1025 44.327L28.2053 37.3413L40.3048 44.327L28.2053 51.316L16.1025 44.327Z" fill="#4235E1"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M40.3048 44.3271L28.2053 37.3414V23.3667L40.3048 30.3524V44.3271Z" fill="#5D51E9"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M16.1025 30.3524L28.2053 23.3667V37.3414L16.1025 44.3271V30.3524Z" fill="#5D51E9"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M7.70373 25.504V37.3408V49.1775L-0.695068 54.0253V37.3408V20.6562L7.70373 15.8085V25.504ZM16.0327 10.9973L28.202 3.97168V13.6705L16.0327 20.6961V10.9973Z" fill="#5D51E9"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M28.2053 61.0111L48.7035 49.1743L57.1023 54.0254L42.6555 62.3677L28.2053 70.7099L13.7551 62.3677L-0.695068 54.0254L7.70373 49.1743L28.2053 61.0111Z" fill="#4235E1"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M48.7036 25.5044L28.2053 13.6676V3.96875L42.6555 12.311L57.1024 20.6533V37.3412V54.0257L48.7036 49.1746V25.5044Z" fill="#5D51E9"/>
</g>
<defs>
<clipPath id="clip0">
<rect width="377" height="71" fill="white"/>
</clipPath>
</defs>
</svg>`,
  },
  chains: [
    {
      id: "0x1",
      token: "ETH",
      label: "Ethereum Mainnet",
      rpcUrl: MAINNET_RPC_URL || "",
    },
    {
      id: "0xa",
      token: "ETH",
      label: "Optimistic Ethereum",
      rpcUrl: OPTIMISM_RPC_URL || "",
    },
    {
      id: "0xA4B1",
      token: "ETH",
      label: "Arbitrum Mainnet",
      rpcUrl: ARBITRUM_RPC_URL || "",
    },
    {
      id: "0x5",
      token: "GETH",
      label: "Goerli Testnet",
      rpcUrl: GOERLI_RPC_URL || "",
    },
  ],
  connect: {
    autoConnectLastWallet: true,
  },
});

export const useOnboardWallet = singletonHook(null, () => {
  const { dispatchNftSubscriptionIsValid } = useContext(NftSubscriptionContext);
  const { dispatchAccount, account } = useContext(CurrentAccountContext);
  const { dispatchUuid, uuid } = useContext(CurrentUuidContext);
  const { dispatchWeb3 } = useContext(CurrentWeb3Context);
  const { dispatchWalletProvider } = useContext(WalletProviderContext);
  const { dispatchUserDetails } = useContext(CurrentUserDetailsContext);
  const uuidInCookie = getUuid(account);
  const [{ wallet, connecting }, connect, disconnect] = useConnectWallet();
  const { mixpanel, ip } = useContext(MixpanelContext);
  const connectedWallets = useWallets();
  const connectedWalletAddress = connectedWallets[0]?.accounts?.[0]?.address;
  const { dispatch } = useContext(NotificationsContext);
  const isMultisigSignIn = useIsMultisigSignIn();
  const delegators = useSiweDelegators({ address: wallet?.accounts[0].address || "" });
  const { dispatchDaoLogin } = useContext(CurrentDaoLoginContext);
  const { trackOpenConnectWalletDrawer } = useMixpanel();

  useEffect(() => {
    // disconnect if more than 1 wallet is connected
    if (connectedWallets.length > 1) {
      const unactiveWallet = connectedWallets.filter((connectedWallet) => connectedWallet.label !== wallet?.label);
      if (unactiveWallet.length) {
        disconnect({ label: unactiveWallet[0]?.label || "" });
      }
    }
  }, [connectedWallets, disconnect, wallet?.label]);

  const walletReset = useCallback(() => {
    disconnect({ label: wallet?.label || "" });
    window.localStorage.removeItem("selectedWallet");
    if (account && (uuid || uuidInCookie)) {
      try {
        axios.post(`${baseAPIUrl}siwe/signOut?key=${BOARDROOM_API_KEY}`, {
          address: account,
          uuid: uuid || uuidInCookie,
        });
        cookies.remove(`uuid:${account.toLowerCase()}`, { path: "/" });
        cookies.remove("lastUsedAddressForUUID", { path: "/" });
        cookies.remove(`emailLogin:${connectedWalletAddress?.toLowerCase()}>${account?.toLowerCase()}`, { path: "/" });
        dispatchUuid({ type: "SAVE_UUID", data: "" });
      } catch (error) {
        console.error(error);
      }
    }
    dispatchAccount({ type: "SAVE_ACCOUNT", data: "" });
    dispatchWeb3({ type: "SAVE_WEB3", data: null });
    dispatchWalletProvider({ type: "WALLET_PROVIDER", data: "" });
    dispatchUserDetails({ type: "SAVE_USER_DETAILS", data: null });
    window.localStorage.setItem("lastDisconnectedDate", Date.now().toString());
    dispatchNftSubscriptionIsValid({
      type: "NFT_SUBSCRIPTION_IS_VALID",
      data: false,
    });
  }, [
    account,
    connectedWalletAddress,
    disconnect,
    dispatchAccount,
    dispatchNftSubscriptionIsValid,
    dispatchUserDetails,
    dispatchUuid,
    dispatchWalletProvider,
    dispatchWeb3,
    uuid,
    uuidInCookie,
    wallet?.label,
  ]);

  useEffect(() => {
    const previouslySelectedWallet = window.localStorage.getItem("selectedWallet");
    const lastDisconnectedDate = Number(window.localStorage.getItem("lastDisconnectedDate"));
    const lastDisconnectedDatePlus1Hour = moment(lastDisconnectedDate || 0).add(1, "hour");
    const isEmailLogin = cookies.get(
      `emailLogin:${connectedWalletAddress?.toLowerCase() || ""}>${account?.toLowerCase() || ""}`,
    );
    const allCookies = cookies.getAll() || {};
    const emailLoginCookies = Object.keys(allCookies).filter((cookie) => cookie.includes("emailLogin"));

    if (connectedWalletAddress) {
      emailLoginCookies.map((cookie) => {
        cookies.remove(cookie, { path: "/" });
      });
    } else {
      const lastUsedAddressForUUID = cookies.get("lastUsedAddressForUUID");
      if (lastUsedAddressForUUID) {
        const uuidForAddress = cookies.get(`uuid:${lastUsedAddressForUUID?.toLowerCase()}`);
        const containsEmailLoginCookieForAddress = emailLoginCookies?.some((cookie) =>
          cookie.includes(lastUsedAddressForUUID?.toLowerCase()),
        );
        if (uuidForAddress && containsEmailLoginCookieForAddress && !account) {
          dispatchAccount({ type: "SAVE_ACCOUNT", data: lastUsedAddressForUUID });
        }
      }
    }
    if (wallet && !connecting) {
      if (wallet?.accounts[0] && !isMultisigSignIn && !isEmailLogin) {
        dispatchAccount({ type: "SAVE_ACCOUNT", data: wallet?.accounts[0].address });
        cookies.set("lastUsedAddressForUUID", wallet?.accounts[0].address);

        if (isStaging || isProduction) {
          mixpanel.identify(wallet.accounts[0].address);
          mixpanel.people.set({ name: wallet.accounts[0].address, $ip: ip });
        }
        window.localStorage.setItem("selectedWallet", wallet?.label);
        window.localStorage.setItem("lastConnectedDate", Date.now().toString());
        window.localStorage.removeItem("lastDisconnectedDate");
      }
      if (wallet?.provider) {
        const web3Instance = new Web3Provider(wallet?.provider, "any");
        dispatchWeb3({ type: "SAVE_WEB3", data: web3Instance });
        dispatchWalletProvider({ type: "WALLET_PROVIDER", data: wallet?.provider });
      } else {
        dispatchWeb3({ type: "SAVE_WEB3", data: null });
        window.localStorage.removeItem("selectedWallet");
      }
    } else if (
      !wallet &&
      lastDisconnectedDatePlus1Hour &&
      moment().isAfter(lastDisconnectedDatePlus1Hour) &&
      previouslySelectedWallet
    ) {
      connect({ autoSelect: { label: previouslySelectedWallet, disableModals: false } });
    }
    dispatchDaoLogin({ type: "SAVE_DAO_LOGIN", data: !!delegators?.length });
  }, [
    dispatchAccount,
    dispatchWalletProvider,
    dispatchWeb3,
    wallet,
    account,
    connect,
    mixpanel,
    ip,
    isMultisigSignIn,
    dispatchDaoLogin,
    delegators?.length,
    connectedWalletAddress,
    connecting,
  ]);

  useEffect(() => {
    const connectSafeAddress = async () => {
      const safeInfo = await appsSdk.safe.getInfo();
      const safeAddress = safeInfo?.safeAddress;
      if (safeAddress) {
        connect({ autoSelect: { label: "Safe", disableModals: true } });
      }
    };
    connectSafeAddress();
  }, [connect]);

  const openWalletModal = useCallback(async () => {
    trackOpenConnectWalletDrawer();
    try {
      await connect();
    } catch (error) {
      console.error(error);
      dispatch({
        type: "onError",
        payload: { message: "Please refresh the page and try again." },
      });
    }
  }, [connect, trackOpenConnectWalletDrawer, dispatch]);

  return useMemo(() => ({ openWalletModal, walletReset }), [openWalletModal, walletReset]);
});
