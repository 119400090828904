import React, { createContext, useEffect, useState } from "react";
import { DelegateFilterParams, DelegateFrameworkFilterParams, DelegateTagParams } from "../types";
import { useLocation } from "react-router";

interface Props {
  children: React.ReactNode;
}

interface DelegateFilterContextType {
  filter: DelegateFilterParams;
  setFilter: React.Dispatch<React.SetStateAction<DelegateFilterParams>>;
}

interface DelegateFrameworkFilterContextType {
  framework: DelegateFrameworkFilterParams;
  setFrameworkFilter: React.Dispatch<React.SetStateAction<DelegateFrameworkFilterParams>>;
}

interface DelegateTagFilterContextType {
  tag: DelegateTagParams;
  setTag: React.Dispatch<React.SetStateAction<DelegateTagParams>>;
}

export const DelegateFilterContext = createContext({} as DelegateFilterContextType);

export const DelegateFilterProvider = ({ children }: Props) => {
  const [filter, setFilter] = useState<DelegateFilterParams>("votePower");
  return <DelegateFilterContext.Provider value={{ filter, setFilter }}>{children}</DelegateFilterContext.Provider>;
};

export const DelegateFrameworkFilterContext = createContext({} as DelegateFrameworkFilterContextType);

export const DelegateFrameworkFilterProvider = ({ children }: Props) => {
  const [framework, setFrameworkFilter] = useState<DelegateFrameworkFilterParams>("allFrameworks");
  return (
    <DelegateFrameworkFilterContext.Provider value={{ framework, setFrameworkFilter }}>
      {children}
    </DelegateFrameworkFilterContext.Provider>
  );
};

export const DelegateTagFilterContext = createContext({} as DelegateTagFilterContextType);

export const DelegateTagFilterProvider = ({ children }: Props) => {
  const [tag, setTag] = useState<DelegateTagParams>("");
  const { pathname } = useLocation();

  useEffect(() => {
    setTag("");
  }, [pathname]);

  return <DelegateTagFilterContext.Provider value={{ tag, setTag }}>{children}</DelegateTagFilterContext.Provider>;
};
