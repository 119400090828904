import * as React from "react";

const SvgDiscordIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.7 13.35" width="1em" height="1em" {...props}>
    <path
      d="M6.77 0a8 8 0 00-4.46 1.67A21.13 21.13 0 000 10.93a5.82 5.82 0 004.89 2.42S5.48 12.64 6 12a5 5 0 01-2.8-1.87s.16.11.45.27h.06c.05 0 .1 0 .14.08a9.34 9.34 0 001.15.61 12.85 12.85 0 002.36.69 11.29 11.29 0 004.15 0 11 11 0 002.32-.69 8.68 8.68 0 001.84-.94 5.06 5.06 0 01-2.9 1.89l1.06 1.28a5.89 5.89 0 004.89-2.4 21.13 21.13 0 00-2.31-9.26A7.86 7.86 0 0011.91 0l-.23.26a10.33 10.33 0 014 2A13.38 13.38 0 0010.85.75a13.9 13.9 0 00-3.24 0 1.18 1.18 0 00-.27 0 12.26 12.26 0 00-3.64 1 9.75 9.75 0 00-.94.45A10.46 10.46 0 017 .19L6.79 0zm-.41 5.91A1.7 1.7 0 018 7.68a1.7 1.7 0 01-1.64 1.76 1.7 1.7 0 01-1.63-1.76 1.7 1.7 0 011.63-1.77zm5.85 0a1.7 1.7 0 011.63 1.77 1.69 1.69 0 01-1.63 1.76 1.7 1.7 0 01-1.63-1.76 1.7 1.7 0 011.63-1.77z"
      fill="currentColor"
    />
  </svg>
);

export default SvgDiscordIcon;
