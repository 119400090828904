import React, { useCallback, useContext, useState } from "react";
import styled from "styled-components";
import { useQueryClient } from "@tanstack/react-query";
import { notification } from "antd/es";
import axios from "axios";
import CopyToClipboard from "react-copy-to-clipboard";

import { COLORS } from "../../constants/colors";
import { CurrentAccountContext } from "../../reducers/CurrentAccount";
import { CurrentUuidContext } from "../../reducers/CurrentUuid";
import { protocols } from "../../constants/protocols";
import { Webhook } from "../../hooks/useGetAddressWebhooks";
import { BOARDROOM_API_KEY, baseAPIUrl } from "../../constants/general";
import ProtocolIcon from "../ProtocolIcon";
import { Popover } from "../Popover";
import { KeyIcon, ThreeDotMenuIcon, ToggleOffIcon } from "../icons";
import { CustomProtocolsPopoverContentWrapper } from "../Dropdown/DropdownButton";

const ExistingWebhookWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 0;
  cursor: pointer;
  gap: 16px;
  color: ${COLORS.primary.grayDark};
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
`;

const ProtocolWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 136px;
  flex-shrink: 0;
  overflow: hidden;
`;

export const PopoverItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  height: 32px;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: ${COLORS.primary.grayDark};
  padding: 0 8px;
  .content-wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  transition: 0.3s all;
  :hover {
    color: ${COLORS.primary.accent};
    font-weight: 500;
    background: ${COLORS.primary.grayLighter};
  }
`;

export const ThreeDotMenuWrapper = styled.div<{ $isActive?: boolean }>`
  background: #f6f6f6;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  color: ${COLORS.primary.grayDarkLightest};
  transition: 0.3s all;
  flex-shrink: 0;
  ${({ $isActive }) => $isActive && `background: ${COLORS.primary.accent}; color: #fff;`}
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  cursor: pointer;
  :hover {
    background: ${COLORS.primary.accent};
    color: #fff;
  }
`;

const ActiveTag = styled.div`
  display: flex;
  width: 56px;
  height: 20px;
  padding: 8px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid ${COLORS.secondary.green};
  background: ${COLORS.secondary.greenLighter};
  color: ${COLORS.secondary.greenDark};
  font-size: 10px;
  font-weight: 500;
  line-height: 15px;
`;

const InactiveTag = styled.div`
  display: flex;
  width: 56px;
  height: 20px;
  padding: 8px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid ${COLORS.secondary.red};
  background: ${COLORS.secondary.redLighter};
  color: ${COLORS.secondary.redDark};
  font-size: 10px;
  font-weight: 500;
  line-height: 15px;
`;

const UrlText = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
`;

const IdText = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
  width: 110px;
  flex-shrink: 0;
`;

const types: {
  [key: string]: string;
} = {
  proposalStateChanges: "Proposals",
  voteCastEvents: "Votes",
};

export const ExistingWebhookRow = ({ webhook, onClick }: { webhook: Webhook; onClick: () => void }) => {
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  const webhookProtocols = webhook.protocols.split(",");
  const protocolToShow = protocols[webhookProtocols[0]];
  const { account } = useContext(CurrentAccountContext);
  const { uuid } = useContext(CurrentUuidContext);
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState(false);
  const isMultipleProtocols = webhookProtocols.length > 1;

  const onCopy = useCallback(() => {
    notification.success({
      message: "Signing Key Copied",
      description: "Your signing key has been copied to your clipboard.",
    });
  }, []);

  const toggleStatus = useCallback(async () => {
    try {
      setIsLoading(true);
      await axios.post(`${baseAPIUrl}webhooks/updateSubscriberDetails?key=${BOARDROOM_API_KEY}&address=${account}`, {
        uuid: uuid,
        url: webhook.url,
        type: webhook.type,
        status: webhook.status === "active" ? "inactive" : "active",
        protocols: webhook.protocols,
        id: webhook.id,
        secret: webhook.secret,
      });
      notification.success({
        message: "Webhook Updated",
        description: "Your webhook has been successfully updated.",
      });
      queryClient.invalidateQueries(["webhooks", account, uuid]);
    } catch (e) {
      console.error(e);
      notification.error({
        message: "Error",
        description: "Something went wrong. Please try again.",
      });
    } finally {
      setIsLoading(false);
    }
  }, [
    account,
    queryClient,
    uuid,
    webhook.id,
    webhook.protocols,
    webhook.secret,
    webhook.status,
    webhook.type,
    webhook.url,
  ]);

  return (
    <ExistingWebhookWrapper onClick={onClick}>
      <IdText>{webhook.id}</IdText>
      <ProtocolWrapper>
        {isMultipleProtocols ? (
          <Popover
            trigger="hover"
            zIndex={20}
            content={
              <CustomProtocolsPopoverContentWrapper>
                {webhookProtocols?.map((protocol) => {
                  const protocolInfo = protocols[protocol];
                  return (
                    <div key={protocolInfo.cname}>
                      <ProtocolIcon protocol={protocolInfo} size="xsmall" />
                      <span style={{ marginLeft: "8px" }}>{protocolInfo.name}</span>
                    </div>
                  );
                })}
              </CustomProtocolsPopoverContentWrapper>
            }
          >
            <div style={{ display: "flex" }}>
              {webhookProtocols?.map((protocol, i) => {
                const protocolInfo = protocols[protocol];
                return (
                  <div
                    key={protocolInfo.cname}
                    style={{
                      ...(i > 0 && { marginLeft: "-4px" }),
                    }}
                  >
                    <ProtocolIcon size="xsmall" protocol={protocolInfo} />
                  </div>
                );
              })}
            </div>
          </Popover>
        ) : (
          <>
            <ProtocolIcon protocol={protocolToShow} size="xsmall" />
            <span>{protocolToShow.name}</span>
          </>
        )}
      </ProtocolWrapper>
      <span style={{ width: "75px", flexShrink: 0 }}>{types[webhook.type]}</span>
      {webhook.status === "active" ? <ActiveTag>Active</ActiveTag> : <InactiveTag>Inactive</InactiveTag>}
      <UrlText>{webhook.url}</UrlText>
      <ThreeDotMenuWrapper $isActive={isPopoverVisible} onClick={(e) => e.stopPropagation()}>
        <Popover
          trigger="click"
          visible={isPopoverVisible}
          placement="bottom"
          onVisibleChange={(visible) => {
            setIsPopoverVisible(visible);
          }}
          overlayClassName="webhook-popover"
          content={
            <div>
              <PopoverItem>
                <CopyToClipboard onCopy={onCopy} text={webhook.secret}>
                  <div className="content-wrapper">
                    <KeyIcon width={16} height={16} />
                    <span>Copy Signing Key</span>
                  </div>
                </CopyToClipboard>
              </PopoverItem>
              <PopoverItem onClick={toggleStatus}>
                <div className="content-wrapper">
                  <ToggleOffIcon width={16} height={16} />
                  <span>
                    {webhook.status === "active"
                      ? isLoading
                        ? "Deactivating..."
                        : "Deactivate"
                      : isLoading
                      ? "Activating..."
                      : "Activate"}
                  </span>
                </div>
              </PopoverItem>
            </div>
          }
        >
          <ThreeDotMenuIcon width={16} height={16} />
        </Popover>
      </ThreeDotMenuWrapper>
    </ExistingWebhookWrapper>
  );
};
