import styled from "styled-components";
import { Link } from "react-router-dom";

import { CopyIcon, DelegateIcon } from "../icons";
import { Header } from "../Typography";
import { COLORS } from "../../constants/colors";
import mediaQuery from "../../media-query";
import ProtocolIcon from "../ProtocolIcon";

export const BannerWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 88px;
  margin-bottom: -56px;
`;

export const BannerImage = styled("img")`
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center;
  object-fit: cover;
  border-radius: 8px;
`;

export const ImgWrapper = styled.div`
  position: relative;
  margin-left: 12px;
  display: flex;
`;

export const StyledDelegateIcon = styled(DelegateIcon)`
  position: absolute;
  color: #ffffff;
  top: 44px;
  left: 52px;
  background: ${COLORS.primary.accent};
  border: 2px solid #ffffff;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  padding: 6px;
  ${mediaQuery.lessThan("991px")`
    width: 28px;
  height: 28px;
  padding: 5px;
  `}
`;

export const ProfileImageBorder = styled.div`
  border-radius: 50%;
  border: 4px solid white;
  display: inline-block;
  img {
    object-fit: cover;
  }
  ${mediaQuery.lessThan("991px")`
    border-width: 2px;
  `}
`;

export const StyledImg = styled.img`
  border-radius: 50%;
`;

export const VotePowerWrapper = styled.div`
  border-radius: 12px 0px 0px 12px;
  background: #ffffff;
  width: 100%;
  margin-top: 42px;
  margin-left: 33%;
  padding: 6px 12px;
  height: fit-content;
  display: flex;
  align-items: center;
`;

export const VPText = styled.span`
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  margin-left: 8px;

  color: #191540;
`;

export const VPValue = styled.span`
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;

  color: #191540;
  margin-left: 4px;
`;

export const FlexItem = styled.div<{ marginBottom?: boolean; cursor?: boolean }>`
  display: flex;
  margin-bottom: ${({ marginBottom }) => (marginBottom ? "8px" : 0)};
  margin-left: 12px;
  cursor: ${({ cursor }) => (cursor ? "pointer" : "")};
  ${mediaQuery.lessThan("991px")`
    display: inline-block;
    margin-right: 15px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  `}
`;

export const ProfileName = styled(Header)<{ $lengthBiggerThan20?: boolean; $lengthBiggerThan200?: boolean }>`
  margin-top: 12px;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 0;
  width: ${({ $lengthBiggerThan200 }) => ($lengthBiggerThan200 ? "100%" : "max-content")};
  ${mediaQuery.lessThan("991px")`
    display: contents;
  `}
`;

export const StyledCopyIcon = styled(CopyIcon)`
  width: 16px;
  height: 16px;
  margin-left: 12px;
  margin-top: 12px;
  color: ${COLORS.primary.accent};
  cursor: pointer;
  ${mediaQuery.lessThan("991px")`
    margin-top: 0px;
  `}
`;

export const StyledInfo = styled.span`
  color: ${COLORS.primary.grayDarkLightest};
  font-weight: 300;
  font-size: 10px;
  margin-right: 8px;
  margin-left: 12px;
`;

export const StyledProtocolIcon = styled(ProtocolIcon)`
  margin-right: 8px;
  :hover {
    cursor: pointer;
  }
`;

export const YourProfileWrapper = styled.div`
  margin-top: 24px;
  text-align: right;
  margin-right: 5rem;
  position: absolute;
  right: 0;
`;

export const YourProfileButton = styled(Link)`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #4235e1;
  padding: 8px 20px;
  background: #fafafa;

  border: 1px solid #4235e1;
  border-radius: 40px;
`;

export const ActiveProtocolsWrapper = styled.div`
  margin-left: 12px;
  margin-top: 10px;
`;

export const FirstSectionWrapper = styled.div`
  background: #fff;
`;

export const SecondSectionWrapper = styled.div`
  margin: 32px 16px 88px;
`;

export const DelegationStatsWrapper = styled.div`
  margin-top: 56px;
  margin-left: 16px;
  margin-right: 5rem;
`;

export const DelegationStatsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 12px;
  border-bottom: 1px solid #f0eff8;
`;

export const DelegationStatsHeaderTitle = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  text-transform: uppercase;
  color: #7b7893;
  svg {
    margin-right: 8px;
  }
`;

export const StatsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 12px;
`;

export const StatWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #191540;
`;

export const StatTitle = styled.span``;

export const StatValue = styled.span`
  text-align: right;
`;

export const StatInfo = styled.span`
  font-weight: 500;
  font-size: 10px;
  line-height: 22px;
  color: #7b7893;
`;

export const EditButton = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #4235e1;
  cursor: pointer;
`;

export const PitchText = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #191540;
  margin-top: 16px;
`;

export const EditYourDelegationButton = styled.button`
  border: none;
  width: 100%;
  max-width: 480px;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

  color: #4235e1;
  transition: 0.3s all;
  padding: 24px 0;
  position: absolute;
  bottom: 0;
  border: 1px solid #f0eff8;
  background: #fff;
  :hover {
    background: #4235e1;
    color: white;
  }
  :focus,
  :active {
    background: #291fa7;
    color: white;
  }
  svg {
    margin-right: 10px;
  }
`;

export const Wrapper = styled.div`
  margin: 88px 0 8px;
  background: #ffffff;
  border: 1px solid #f0eff8;
  border-radius: 12px;
  position: relative;
`;

export const CardHeader = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  padding: 16px 16px 12px;
  letter-spacing: 0.1em;
  color: #7b7893;
  text-transform: uppercase;
`;

export const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 32px;
`;

export const ActivitySectionTitle = styled.span`
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #191540;
  width: 100px;
  display: block;
`;

export const VisitProfile = styled(Link)`
  border: 1px solid #7b7893;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.05));
  border-radius: 40px;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #7b7893;
  padding: 8px 20px;
  transition: 0.3s all;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 28px;
  :hover {
    background: #4235e1;
    color: white;
    border: 1px solid transparent;
  }
  :active,
  :focus {
    background: #291fa7;
    color: white;
    border: 1px solid transparent;
  }
`;

export const MoreProjectsDiv = styled.div`
  border: 1px solid #4235e1;
  color: #4235e1;
  border-radius: 40px;
  width: 20px;
  height: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 8px;
`;
