import * as React from "react";

const SvgLearnIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.333 1.167a1.5 1.5 0 00-1.5 1.5h-1a2.5 2.5 0 012.5-2.5h7.333a.5.5 0 01.5.5v8.666a.5.5 0 01-.5.5H3.333c-.825 0-1.5.672-1.5 1.5a.5.5 0 11-1 0V2.667h1v6.668a2.489 2.489 0 011.5-.502h6.833V1.167H3.333z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.666.833a.5.5 0 01.5.5v12a.5.5 0 01-.5.5H3.333a2.5 2.5 0 010-5h7.333v1H3.333a1.5 1.5 0 000 3h8.833v-11.5a.5.5 0 01.5-.5zm-1.5 8.5a.5.5 0 01-.5.5v-1a.5.5 0 01.5.5z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.166 11.333a.5.5 0 01.5-.5h6.667a.5.5 0 010 1H3.666a.5.5 0 01-.5-.5z"
      fill="currentColor"
    />
  </svg>
);

export default SvgLearnIcon;
