import * as React from "react";

const SvgListIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M22 7a1 1 0 01-1 1H3a1 1 0 01-1-1V5a1 1 0 011-1h18a1 1 0 011 1v2zM22 13a1 1 0 01-1 1H3a1 1 0 01-1-1v-2a1 1 0 011-1h18a1 1 0 011 1v2zM22 19a1 1 0 01-1 1H3a1 1 0 01-1-1v-2a1 1 0 011-1h18a1 1 0 011 1v2z"
      fill="currentColor"
    />
  </svg>
);

export default SvgListIcon;
