import { DelegationAdapter } from "@boardroom/gov-sdk";
import { getProtocolAdapterInstances } from "../constants/protocols";
import { useEffect, useState } from "react";

export const useHasDelegation = (cname?: string) => {
  if (!cname) {
    return false;
  }
  const adapterInstances = getProtocolAdapterInstances({ adapter: "delegation", protocol: cname });

  return (adapterInstances?.length || 0) > 0;
};

export const useHasDelegationMultipleProtocols = (cnames?: string[]) => {
  const filteredCnames = cnames?.filter((cname) => {
    if (!cname) {
      return false;
    }
    try {
      const adapterInstances = getProtocolAdapterInstances({ adapter: "delegation", protocol: cname });
      return (adapterInstances?.length || 0) > 0;
    } catch (e) {
      return false;
    }
  });
  return filteredCnames;
};

export const useGetDelegatedAddress = (delegationAdapter?: DelegationAdapter, address?: string) => {
  const [delegatedAddress, setDelegatedAddress] = useState<string>("");
  useEffect(() => {
    if (!delegationAdapter || !address) {
      return;
    }
    const fetchDelegatedAddress = async () => {
      const delegatedAddress = await delegationAdapter.getDelegation(address);
      setDelegatedAddress(delegatedAddress);
    };
    fetchDelegatedAddress();
  }, [delegationAdapter, address]);
  return delegatedAddress;
};
