import React from "react";
import { LogoLight } from "./LogoLight";
import { LogoDark } from "./LogoDark";
import { LogoWhite } from "./LogoWhite";

export interface Props {
  type?: "light" | "dark" | "white";
}

export const Logo = ({ type = "light" }: Props) => {
  if (type === "dark") {
    return <LogoDark />;
  }

  if (type === "white") {
    return <LogoWhite />;
  }

  return <LogoLight />;
};
