export const pageHeaderHeight = "64px";
export const ExpandedMainNavWidth = "184px";
export const CollapsedMainNavWidth = "80px";

export const mainGridStrucutre = (mainNavCollapsed: boolean) => {
 return mainNavCollapsed ? 16 : 14;
};

export const mainGridOffset = (mainNavCollapsed: boolean) => {
 return mainNavCollapsed ? 0 : 2;
};