import { AlchemyProvider } from "@ethersproject/providers";
import { useQuery, useQueryClient } from "@tanstack/react-query";

const providerUrl = process.env.REACT_APP_RPC_URL_1;
const providerUrlSplitted = providerUrl?.split("/");
const alchemyProvider = new AlchemyProvider(undefined, providerUrlSplitted?.[providerUrlSplitted?.length - 1]);

export function useGetAddress(ens?: string, addressFromUrl?: string) {
  const { data: address } = useQuery<any>(
    [`getAddressForEns:${addressFromUrl || ens}`],
    () => alchemyProvider.resolveName(ens || ""),
    {
      enabled: !!ens,
      refetchOnWindowFocus: false,
      cacheTime: 3600000,
    }
  );

  return address || "";
}

export function useGetEns(address?: string, ensFromUrl?: string, suspense = false) {
  const { data: ens } = useQuery<any>(
    [`getEnsForAddress:${ensFromUrl || address}`],
    () => alchemyProvider.lookupAddress(address || ""),
    {
      enabled: !!address,
      refetchOnWindowFocus: false,
      cacheTime: 3600000,
      suspense,
    }
  );

  return ens || "";
}

export const useGetEnsMultipleAddresses = ({ addresses }: { addresses?: string[] }) => {
  const queryClient = useQueryClient();

  const { data: ens } = useQuery<string[]>([`getEnsForAddresses:${addresses?.toString()}`], async () => {
    const returnedEns =
      addresses?.map(async (address) => {
        const queryData: string | null | undefined = await queryClient.getQueryData([`getEnsForAddress:${address}`]);
        if (queryData) {
          return queryData;
        } else {
          const ens = await alchemyProvider.lookupAddress(address || "");
          queryClient.setQueryData([`getEnsForAddress:${address}`], ens);
          return ens || "";
        }
      }) || [];
    const awaitedReturnedEns = await Promise.all(returnedEns);
    return awaitedReturnedEns;
  }, {
    enabled: !!addresses?.length,
    refetchOnWindowFocus: false,
    suspense: false,
  });

  return ens;
};

export function useGetEnsAvatar(address?: string, ensFromUrl?: string, suspense = false) {
  const { data: ensAvatar } = useQuery<any>(
    [`getEnsAvatar:${ensFromUrl || address}`],
    () => alchemyProvider.getAvatar(address || ""),
    {
      enabled: !!address,
      refetchOnWindowFocus: false,
      cacheTime: 3600000,
      suspense,
    }
  );

  return ensAvatar || "";
}
