import Button from "antd/es/button";
import Tooltip from "antd/es/tooltip";
import React, { useCallback, useContext, useMemo } from "react";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";

import { COLORS } from "../../../constants/colors";
import media from "../../../media-query";
import { useCurrentWidth } from "react-socks";
import { ArrowRightIcon, WalletIcon, HalfCircleCheckIcon, LightningIcon } from "../../icons";
import { useOnboardWallet } from "../../../hooks/useOnboardWallet";
import { Loader } from "../../Loader";
import formatValue from "../../../utils/formatValue";
import { useMixpanel } from "../../../hooks";
import { getProtocolCnameFromPath, protocols } from "../../../constants/protocols";
import { CurrentUserDetailsContext } from "../../../reducers/CurrentUserDetails";
import { WalletDrawerContext } from "../../../reducers/WalletDrawer";
import { Pfp } from "../../Pfp/Pfp";
import { useGetTeam } from "../../../hooks/useGetTeam";
import { CurrentAccountContext } from "../../../reducers/CurrentAccount";

const HeaderContainer = styled.div`
  height: 80px;
  width: 100%;
  border-radius: 8px 8px 0 0;
  border-bottom: 1px solid ${COLORS.primary.grayLighter};
  padding: 24px 32px;
  display: flex;
  position: relative;
  align-items: center;

  ${media.lessThan("991px")`
    justify-content: center;
    flex-direction: column;
    height: auto;
  `}

  ${media.greaterThan("991px")`
     &:after {
      content: "";
      position: absolute;
      height: 40px;
      width: 1px;
      background: ${COLORS.primary.grayLighter};
      left: 40%;
      bottom: 20%;
  }
  `}
`;

const HeaderText = styled.div`
  font-weight: 300;
  font-size: 28px;
  line-height: 30px;
  color: ${COLORS.primary.grayDark};
  width: 40%;
  ${media.lessThan("991px")`
     width: 100%;
     font-size: 22px;
     text-align: center;
  }
  `}
`;

const HelperText = styled.div`
  font-size: 14px;
  color: ${COLORS.primary.grayDarkLightest};
  ${media.greaterThan("991px")`
     display: none
  }
  `};
`;

const Text = styled.div<{ color: string; fontWeight: number; fontSize: string }>`
  color: ${({ color }) => color};
  font-size: ${({ fontSize }) => fontSize};
  font-weight: ${({ fontWeight }) => fontWeight};
  line-height: 22px;
`;

const SuccessFooterContainer = styled.div`
  width: 100%;
  height: 64px;
  display: flex;
`;

const SuccessHalfFooter = styled.div<{
  borderRadius: string;
  background: string;
  $cursorPointer?: boolean;
  width: string;
}>`
  width: ${({ width }) => width};
  color: #fff;
  height: 100%;
  border-radius: ${({ borderRadius }) => borderRadius};
  background: ${({ background }) => background};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 12px;
  cursor: ${({ $cursorPointer }) => $cursorPointer && "pointer"};
`;

const StyledSpan = styled.span`
  font-weight: normal;
`;

const StyledFooterText = styled.div<{ $isOnchainVote?: boolean }>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: ${({ $isOnchainVote }) => ($isOnchainVote ? "auto" : "80%")};
`;

const StyledText = styled.div`
  font-weight: 600;
  font-size: 16px;
  margin-left: 16px;
  display: flex;
  align-items: center;
  ${media.lessThan("640px")`
    font-size: 14px;
  `}
`;

const StyledHalfCircleCheckIcon = styled(HalfCircleCheckIcon)`
  margin-right: 8px;
`;

const FooterContainer = styled(Button)<{ background: string }>`
  height: 64px;
  width: 100%;
  border-radius: 0 0 8px 8px;
  background: ${({ background }) => background};
  margin-top: auto;
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    background: ${({ background }) => background};
    color: #fff;
    border: none;
    opacity: 0.9;
  }
  &:disabled {
    background: ${COLORS.primary.grayDarkLightest};
    color: #fff;
  }
`;

const CustomFooterContainer = styled(Button)<{
  $background: string;
  $hoverBackground: string;
  $activeBackground: string;
}>`
  height: 64px;
  width: 100%;
  border-radius: 0 0 8px 8px;
  background: ${({ $background }) => $background};
  margin-top: auto;
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.3s all;
  &:hover {
    background: ${({ $hoverBackground }) => $hoverBackground};
    color: #fff;
    border: none;
  }
  &:active,
  &:focus {
    background: ${({ $activeBackground }) => $activeBackground};
    color: #fff;
    border: none;
  }
  &:disabled {
    background: ${COLORS.primary.grayDarkLightest};
    color: #fff;
  }
`;

const NotificationsCTAWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 12px;
  line-height: 30px;
  color: #191540;
  background: #f0eff8;
  border: 1px solid #f0eff8;
  border-radius: 8px 8px 0px 0px;
  text-align: center;
  ${media.lessThan("991px")`
    margin: auto;
    border-radius: 8px;
    margin-top: 16px;
    flex-direction: column;
    width: calc(100% - 32px);
    margin: 16px auto 0;
    line-height: 18px;
  padding: 8px 0;
  `}
`;

const NeverMissAProposalText = styled.span`
  ${media.lessThan("991px")`
    display: block;
  `}
`;

const SignUpForNotifications = styled(Link)`
  font-weight: 500;
  color: #4235e1;
  font-weight: 400;
  font-size: 12px;
  margin-left: 4px;
`;

interface Props {
  title: string;
  desc: string;
  protocolPath?: string;
}

interface FooterProps {
  text: string | undefined;
  onClick: () => void;
  votePower: string | number | undefined;
  protocolPath?: string;
  isOnchainVote: boolean;
}

interface VoteProps {
  chosen: any;
  handleSubmit: () => void;
  loading: boolean;
  votePower: string | number | undefined;
}

export const RenderVote = ({ chosen, handleSubmit, votePower, loading }: VoteProps) => {
  const query = new URLSearchParams(useLocation().search);
  const teamId = query.get("bundle");
  const teamDetails = useGetTeam(teamId || "");
  const { dispatchIsWalletDrawerOpen } = useContext(WalletDrawerContext);
  const { account } = useContext(CurrentAccountContext);
  const { userDetails } = useContext(CurrentUserDetailsContext);
  const { trackOpenWalletDrawerFromCastVote } = useMixpanel();

  const onClick = useCallback(() => {
    if (teamId && !((Number(votePower) || 0) > 0)) {
      dispatchIsWalletDrawerOpen({ type: "TOGGLE_WALLET_DRAWER", data: true });
      trackOpenWalletDrawerFromCastVote({
        userId: account,
      });
    } else if (!chosen) {
      return;
    } else {
      handleSubmit();
    }
  }, [account, chosen, dispatchIsWalletDrawerOpen, handleSubmit, teamId, trackOpenWalletDrawerFromCastVote, votePower]);

  return (
    <Tooltip
      title={
        "Vote Power is Set at Proposal Creation: Your voting power is determined at the creation of the proposal. Adding vote power afterward won't affect your ability to vote on this proposal."
      }
      trigger="hover"
      color={"rgba(0, 0, 0, 0.75)"}
    >
      <CustomFooterContainer
        $background={
          teamId && !((Number(votePower) || 0) > 0)
            ? "#191540"
            : chosen
            ? "linear-gradient(90.03deg, #9E97F3 0.03%, #4235E1 99.99%)"
            : COLORS.primary.grayDarkLightest
        }
        $hoverBackground={
          teamId && !((Number(votePower) || 0) > 0)
            ? "linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(89.93deg, #191540 -1.05%, #4235E1 51.29%, #191540 101.49%)"
            : chosen
            ? "#4235E1"
            : COLORS.primary.grayDarkLightest
        }
        $activeBackground={
          teamId && !((Number(votePower) || 0) > 0) ? "#291FA7" : chosen ? "#291FA7" : COLORS.primary.grayDarkLightest
        }
        style={{
          color: "#fff",
        }}
        onClick={onClick}
        disabled={loading}
      >
        {loading ? (
          <Loader />
        ) : (
          <div style={{ display: "flex", alignItems: "center" }}>
            {!!teamId && !!((Number(votePower) || 0) > 0) && (
              <Pfp size="small" pfpUrl={userDetails?.pfpUrl} address={account} noShrink />
            )}
            {teamId && !((Number(votePower) || 0) > 0) ? (
              <Pfp size="small" pfpUrl={teamDetails?.pfpUrl} address={account} noShrink />
            ) : (
              <LightningIcon style={{ marginLeft: teamId ? "24px" : "" }} height={22} width={13} />
            )}
            {teamId ? (
              <>
                {(Number(votePower) || 0) > 0 ? (
                  <StyledText>
                    Cast Vote&nbsp;&#183;&nbsp;<StyledSpan>{formatValue(votePower)}</StyledSpan>
                  </StyledText>
                ) : (
                  <StyledText>Connect to a Bundle Wallet to Vote</StyledText>
                )}
              </>
            ) : (
              <StyledText>
                Cast Vote
                {!!votePower && (
                  <>
                    &nbsp;&#183;&nbsp;<StyledSpan>{formatValue(votePower)}</StyledSpan>
                  </>
                )}
              </StyledText>
            )}
          </div>
        )}
      </CustomFooterContainer>
    </Tooltip>
  );
};

export const RenderWallet = ({ refId }: { refId: string }) => {
  const wallet = useOnboardWallet();
  const { trackClickConnectWalletOnVotingModal } = useMixpanel();

  const handleClick = useCallback(() => {
    trackClickConnectWalletOnVotingModal({
      proposalRefId: refId,
    });
    wallet?.openWalletModal();
  }, [wallet, trackClickConnectWalletOnVotingModal, refId]);

  return (
    <FooterContainer background={COLORS.primary.grayDarkLightest} onClick={handleClick}>
      <WalletIcon height={24} width={24} color="#ffffff" /> <StyledText>Connect Your Wallet to Vote</StyledText>
    </FooterContainer>
  );
};

export const RenderSuccessFooter = ({ text, onClick, votePower, protocolPath, isOnchainVote }: FooterProps) => {
  const currentWidth = useCurrentWidth();
  const votedWrappedWidth = useMemo(() => {
    if (isOnchainVote && protocolPath) {
      return "100%";
    } else if (currentWidth > 640) {
      return "50%";
    }
    return "65%";
  }, [currentWidth, isOnchainVote, protocolPath]);

  const changeVoteWrapperWidth = useMemo(() => {
    if (isOnchainVote && protocolPath) {
      return "0%";
    } else if (currentWidth > 640) {
      return "50%";
    }
    return "35%";
  }, [currentWidth, isOnchainVote, protocolPath]);

  return (
    <>
      <SuccessFooterContainer>
        <SuccessHalfFooter
          borderRadius={!isOnchainVote || !protocolPath ? "0 0 0 8px" : "0px 0px 8px 8px"}
          background={COLORS.primary.grayDarkLightest}
          width={votedWrappedWidth}
        >
          <StyledHalfCircleCheckIcon height={24} width={24} />{" "}
          {currentWidth > 640 && (
            <StyledFooterText $isOnchainVote={isOnchainVote}>
              You Voted:&nbsp;
              <StyledSpan>{text}</StyledSpan>
            </StyledFooterText>
          )}
          {currentWidth < 640 && (
            <StyledFooterText $isOnchainVote={isOnchainVote}>
              &nbsp;
              <StyledSpan>{text}</StyledSpan>
            </StyledFooterText>
          )}
        </SuccessHalfFooter>

        <SuccessHalfFooter
          $cursorPointer
          width={changeVoteWrapperWidth}
          borderRadius="0 0 8px 0"
          background={"linear-gradient(90.06deg, #9E97F3 1.38%, #4235E1 99.96%)"}
          onClick={onClick}
          style={{ display: isOnchainVote && protocolPath ? "none" : "inherit" }}
        >
          {protocolPath ? (
            <>
              {currentWidth < 640 && <StyledFooterText style={{ textAlign: "center" }}>Edit Vote</StyledFooterText>}
              {currentWidth > 640 && (
                <>
                  <LightningIcon height={22} width={13} />
                  <StyledText>
                    Change Vote&nbsp;&#183;&nbsp;<StyledSpan>{votePower}</StyledSpan>
                  </StyledText>
                </>
              )}
            </>
          ) : (
            <>
              {currentWidth < 640 && <StyledFooterText style={{ textAlign: "center" }}>Next Proposal</StyledFooterText>}
              {currentWidth > 640 && (
                <>
                  <ArrowRightIcon width={18} />
                  <StyledText>Next Proposal</StyledText>
                </>
              )}
            </>
          )}
        </SuccessHalfFooter>
      </SuccessFooterContainer>
    </>
  );
};

export const RenderHeader = ({ title, desc, protocolPath }: Props) => {
  const { userDetails } = useContext(CurrentUserDetailsContext);
  const notificationPreferences = JSON.parse(userDetails?.notificationPreferences || "{}");
  const protocolCname = getProtocolCnameFromPath(protocolPath || "");
  const isSubscribedToProtocol = notificationPreferences[protocolCname || ""];
  const currentWidth = useCurrentWidth();
  const protocol = protocols[protocolCname];
  return (
    <>
      {!isSubscribedToProtocol && (
        <NotificationsCTAWrapper>
          <NeverMissAProposalText>Never Miss a Proposal</NeverMissAProposalText>
          <SignUpForNotifications to="/settings/notifications">
            Sign up for {protocol?.name} notifications
          </SignUpForNotifications>
        </NotificationsCTAWrapper>
      )}
      <HeaderContainer>
        <HeaderText>Cast Vote</HeaderText>
        <HelperText>{title}</HelperText>
        <div
          style={{
            margin: "auto 0 auto auto",
            display: currentWidth < 640 ? "none" : "block",
          }}
        >
          <Text color={COLORS.primary.grayDark} fontWeight={500} fontSize="16px">
            {title}
          </Text>
          <Text color={COLORS.primary.grayDarkLightest} fontWeight={400} fontSize="14px">
            {desc}
          </Text>
        </div>
      </HeaderContainer>
    </>
  );
};
