import React from "react";
import styled from "styled-components";
import moment, { Moment } from "moment";
import { Popover } from "../../Popover";
import { EventList } from "./EventList";
import { Date } from "./Date";
import { Weekday } from "./Weekday";
import { COLORS } from "../../../constants/colors";
import { CalendarEvent } from "../../../types";

interface Props {
  date: Moment;
  events: CalendarEvent[];
  isToday: boolean;
  isSelected: boolean;
  isInView: boolean;
  isInFirstRow: boolean;
  isDisabled: boolean;
  container: HTMLElement | null;
  containerWidth: number;
  isCompact?: boolean;
}

const getContainerHeight = ({
  isCompact,
  containerWidth,
}: Pick<Props, "containerWidth" | "isSelected" | "isCompact">) => {
  if (isCompact) {
    if (containerWidth <= 400) {
      return "46px";
    }

    if (containerWidth <= 530) {
      return "58px";
    }

    return "97px";
  }

  return "150px";
};

const Container = styled.div<Pick<Props, "containerWidth" | "isSelected" | "isCompact">>`
  position: relative;
  height: ${getContainerHeight};
  background: ${({ isSelected }) => (isSelected ? COLORS.primary.accent : "#fefefe")};
  transition: background 0.2s;
  overflow: hidden;

  :hover {
    background: ${({ isSelected }) => (isSelected ? COLORS.primary.accent : COLORS.primary.accentLighter)};
  }
`;

const Content = styled.div<Pick<Props, "isCompact"> & { hasEvents?: boolean; $isFutureDate?: boolean }>`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  text-align: left;
  padding: ${({ isCompact }) => (isCompact ? "5px" : "40px 10px 0")};

  :after {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 5px;
    height: 5px;
    content: "";
    background: ${({ $isFutureDate }) => ($isFutureDate ? COLORS.primary.accent : COLORS.primary.accentLight)};
    border-radius: 50%;
    display: ${({ isCompact, hasEvents }) => (isCompact && hasEvents ? "block" : "none")};
  }
`;

const DateCell = ({
  date,
  events,
  isToday,
  isSelected,
  isInView,
  isInFirstRow,
  isDisabled,
  container,
  containerWidth,
  isCompact = false,
}: Props) => {
  if (events.length === 0) {
    return (
      <Container isSelected={isSelected} isCompact={isCompact} containerWidth={containerWidth}>
        <Content isCompact={isCompact}>
          <Date
            date={date.format("D")}
            isToday={isToday}
            isSelected={isSelected}
            isInView={isInView}
            isInFirstRow={isInFirstRow}
            isCompact={isCompact}
          />
          {!isCompact && <Weekday day={date.format("ddd")} isCompact={isCompact} isInFirstRow={isInFirstRow} />}
        </Content>
      </Container>
    );
  }

  return (
    <Popover
      content={<EventList events={events} shouldLink />}
      title={date.format("dddd, MMMM D")}
      container={container}
      zIndex={20}
    >
      <Container isSelected={isSelected} isCompact={isCompact} containerWidth={containerWidth}>
        <Content isCompact={isCompact} hasEvents $isFutureDate={date.isSameOrAfter(moment(), "day")}>
          <Date
            date={date.format("D")}
            isToday={isToday}
            isSelected={isSelected}
            isInView={isInView}
            isInFirstRow={isInFirstRow}
            isCompact={isCompact}
          />
          {!isCompact && <Weekday day={date.format("ddd")} isCompact={isCompact} isInFirstRow={isInFirstRow} />}
          {!isCompact && <EventList events={events} max={3} isDisabled={isDisabled} />}
        </Content>
      </Container>
    </Popover>
  );
};

export default React.memo(DateCell);
