import React, { useCallback } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useCurrentWidth } from "react-socks";

import ProtocolIcon from "../ProtocolIcon";
import { ProtocolDescription } from "../../types";
import { COLORS } from "../../constants/colors";
import { StarFilled } from "../icons";
import { StarOutline } from "../icons";
import { LOCAL_STORAGE_FAVORITES_KEY } from "../../constants/general";
import media from "../../media-query";

interface Props {
  protocol: ProtocolDescription;
  onClick?: () => void;
  favourite?: boolean;
  changeOfFav?: () => void;
}

const Container = styled.li`
  list-style: none;
  padding: 16px 0px;
  list-style: none;
  position: relative;
  &:not(:last-child) {
    border-bottom: 1px solid ${COLORS.primary.grayLighter};
  }
`;

const ProtocolLink = styled(Link)`
  display: flex;
  align-items: center;
  margin-right: 36px;
`;

const ProtocolName = styled.div`
  margin-left: 12px;
  font-size: 24px;
  line-height: 30px;
  font-weight: 400;
  font-style: normal;
  color: ${COLORS.primary.grayDark};

  ${media.lessThan("640px")`
    font-size: 20px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 80%;
  `}
`;

const FavoriteStarFilled = styled(StarFilled)`
  position: absolute;
  top: 15px;
  right: 12px;
  color: ${COLORS.primary.accent};
  height: 1.8rem;
  width: 1.8rem;
  cursor: pointer;
  fill: #5d51e9;
  ${media.lessThan("640px")`
    top: 20px;
    right: 0;
  `}
`;

const FavoriteStarOutline = styled(StarOutline)`
  position: absolute;
  top: 15px;
  right: 12px;
  height: 1.8rem;
  width: 1.8rem;
  cursor: pointer;
  fill: ${COLORS.primary.accent};
  ${media.lessThan("640px")`
    top: 20px;
    right: 0;
  `}
`;

export const ProtocolListItem = ({ protocol, onClick, favourite, changeOfFav }: Props) => {
  const { cname } = protocol;

  const currentWidth = useCurrentWidth();

  const handleUnfavorite = useCallback(() => {
    const favs = localStorage.getItem(LOCAL_STORAGE_FAVORITES_KEY) || "{}";
    const favorites = JSON.parse(favs);
    delete favorites[cname];
    localStorage.setItem(LOCAL_STORAGE_FAVORITES_KEY, JSON.stringify(favorites));
    if (changeOfFav) {
      changeOfFav();
    }
  }, [cname, changeOfFav]);

  const handleFavorite = useCallback(() => {
    const favs = localStorage.getItem(LOCAL_STORAGE_FAVORITES_KEY) || "{}";
    const favorites = JSON.parse(favs);
    favorites[cname] = true;
    localStorage.setItem(LOCAL_STORAGE_FAVORITES_KEY, JSON.stringify(favorites));
    if (changeOfFav) {
      changeOfFav();
    }
  }, [cname, changeOfFav]);

  return (
    <Container>
      <ProtocolLink to={`/${protocol.path}`} onClick={onClick}>
        <ProtocolIcon protocol={protocol} theme="dark" size={currentWidth < 640 ? "medium" : "large"} />

        <ProtocolName>{protocol.name}</ProtocolName>
      </ProtocolLink>
      {favourite ? <FavoriteStarFilled onClick={handleUnfavorite} /> : <FavoriteStarOutline onClick={handleFavorite} />}
    </Container>
  );
};
