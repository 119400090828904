import { useWallets } from "@web3-onboard/react";
import { useContext, useMemo } from "react";

import { CurrentAccountContext } from "../reducers/CurrentAccount";
import { useSiweDelegators } from "./useSiweDelegators";

export const useIsMultisigSignIn = () => {
  const { account } = useContext(CurrentAccountContext);
  const connectedWallets = useWallets();
  const delegators = useSiweDelegators({ address: connectedWallets[0]?.accounts?.[0]?.address });

  const connectedWalletAddress = useMemo(() => connectedWallets[0]?.accounts?.[0]?.address, [connectedWallets]);
  const isMultisigSignIn =
    connectedWalletAddress?.toLowerCase() !== account?.toLowerCase() && !!account && delegators?.includes(account);

  return isMultisigSignIn;
};
