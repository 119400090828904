import React, { useContext } from "react";
import styled, { css } from "styled-components";
import AntdTable, { TableProps as AntdTableProps, ColumnsType, TablePaginationConfig } from "antd/es/table";

import { SearchDropdownContext } from "../../reducers/ProjectSearchDropdown";
import { COLORS } from "../../constants/colors";
import { Loader } from "../Loader";
import media from "../../media-query";

interface TableProps<RecordType> {
  columns: ColumnsType<RecordType>;
  dataSource: AntdTableProps<RecordType>["dataSource"];
  pagination?: false | TablePaginationConfig;
  loading?: boolean;
  showHeader?: boolean;
  rowKey?: ((item: any) => string) | string;
  inACardContainer?: boolean;
  rowClassName?: ((item: any, index?: number) => string) | string;
  rowIsLink?: boolean;
  addLetterSpacing?: boolean;
  onRow?: AntdTableProps<RecordType>["onRow"];
  backgroundColor?: string;
}

const StyledWrapper = styled("div")<{
  inACardContainer?: boolean;
  searchDropDownState?: boolean;
  $addLetterSpacing?: boolean;
  $backgroundColor?: string;
}>`
  background-color: ${({ $backgroundColor }) => $backgroundColor || "white"};
  border-radius: 8px;
  overflow: auto;
  width: 100%;
  position: relative;

  ${({ inACardContainer }) =>
    !inACardContainer &&
    css`
      padding: 0px 10px;
    `}
  .ant-table-thead {
    .ant-table-cell {
      padding: 20px 14px;
      white-space: nowrap;
      background: ${({ $backgroundColor }) => $backgroundColor || "white"};
      ${media.lessThan("640px")`
        padding: 20px 14px;
  `}
    }
    > tr > th {
      font-size: 12px;
      line-height: 14px;
      font-weight: 600;
      text-transform: uppercase;
      ${({ $addLetterSpacing }) => $addLetterSpacing && "letter-spacing: 0.1em;"}
      color: ${({ searchDropDownState }) => (searchDropDownState ? "#fff" : COLORS.primary.grayDarkLightest)};
    }
  }
  .ant-table-thead > tr > th {
    border-right: none;
  }
  .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    padding-left: 14px;
    border-bottom: none;
    &:last-child {
      border-right: none;
    }
  }
  table {
    border-collapse: collapse;
  }
  tr.ant-table-row {
    border-collapse: collapse;
  }
  .ant-table-column-sort {
    background-color: ${({ $backgroundColor }) => $backgroundColor || "white"};
  }
  .ant-table-tbody {
    background-color: ${({ $backgroundColor }) => $backgroundColor || "white"};
  }
  .ant-table-tbody > tr.ant-table-row:hover > td {
    background-color: ${COLORS.primary.accentLighter};
  }
  .ant-table-tbody > tr.ant-table-row:hover {
    border-radius: 8px;
  }
  .ant-table-container table > thead > tr:first-child th:first-child {
    padding-left: 0;
  }
  .ant-table-column-sorters {
    padding: 0px;
  }
  .ant-table-column-sorter-up.active,
  .ant-table-column-sorter-down.active {
    color: ${COLORS.primary.accent};
  }

  .ant-table-cell .ant-row {
    align-items: center;
  }

  .ant-spin-nested-loading .ant-spin {
    display: none;
  }
`;

const LoaderWrapper = styled("div")`
  min-height: 300px;
`;

function Table<RecordType extends object = any>(props: TableProps<RecordType>) {
  const { loading, ...restOfProps } = props;

  const { searchDropDownState } = useContext(SearchDropdownContext);
  return (
    <StyledWrapper
      $backgroundColor={props.backgroundColor}
      $addLetterSpacing={props.addLetterSpacing}
      inACardContainer={props.inACardContainer}
      searchDropDownState={searchDropDownState}
    >
      {loading ? (
        <LoaderWrapper>
          <Loader verticalAlign />
        </LoaderWrapper>
      ) : (
        <AntdTable {...restOfProps} />
      )}
    </StyledWrapper>
  );
}

export default Table;
export type { TableProps };
