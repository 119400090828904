import { useInfiniteQuery } from "@tanstack/react-query";

import { BOARDROOM_API_KEY, baseAPIUrl } from "../constants/general";

export interface DailyBrief {
  id: string;
  dailyBrief: string;
  protocol: string;
  timestamp: number;
}

interface DailyBriefsResponse {
  items: DailyBrief[];
  nextCursor?: string;
}

export const useGetDailyBriefsByProtocols = ({ protocols }: { protocols: string[] }) => {
  const { data, fetchNextPage, hasNextPage } = useInfiniteQuery<DailyBriefsResponse>({
    queryKey: ["getDailyBriefsByProtocols", protocols.toString()],
    queryFn: async ({ pageParam: cursor }) => {
      const response = await fetch(
        `${baseAPIUrl}discourse/getDailyBriefsByProtocol?key=${BOARDROOM_API_KEY}&limit=500&protocols=${protocols.join(
          ",",
        )}${cursor ? `&cursor=${cursor}` : ""}`,
      );
      const data = await response.json();
      return data;
    },
    getNextPageParam: (lastPage) => lastPage.nextCursor,
    enabled: !!protocols.length,
    suspense: true,
    keepPreviousData: true,
  });

  const allDailyBriefs = data?.pages.flatMap((page) => page.items) || [];

  return {
    dailyBriefs: allDailyBriefs,
    hasNextPage,
    fetchNextPage,
  };
};
