import React, { useState } from "react";
import { useCurrentWidth } from "react-socks";
import styled from "styled-components";
import { InfoCard } from "..";
import { ExpandCollapseArrowThin } from "../icons";
import { InfoCardProps } from "../InfoCard";

interface Props extends InfoCardProps {
  children: React.ReactNode;
}

const ResultsExpandCollapseIcon = styled(ExpandCollapseArrowThin)<{ $isCollapsed: boolean }>`
  margin-left: auto;
  transform: rotate(${({ $isCollapsed }) => ($isCollapsed ? "0deg" : "180deg")});
  transition: transform 0.2s;
  cursor: pointer;
`;

const StyledInfoCard = styled(InfoCard)<{ $isCollapsed: boolean }>`
  && .ant-card-body {
    height: ${({ $isCollapsed }) => ($isCollapsed ? 0 : "auto")};
    padding: ${({ $isCollapsed }) => ($isCollapsed ? 0 : "20px")};
    overflow: hidden;
    min-height: 0px;
  }
  &.ant-card {
    padding-bottom: ${({ $isCollapsed }) => ($isCollapsed ? 0 : "20px")};
  }
`;

const CollapsibleMobileInfoCard = (props: Props) => {
  const [collapsedState, setCollapsedState] = useState(true);
  const isMobile = useCurrentWidth() < 991;

  const toggleContent = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setCollapsedState(!collapsedState);
  };

  if (isMobile) {
    return (
      <StyledInfoCard
        {...props}
        extra={<ResultsExpandCollapseIcon onClick={toggleContent} $isCollapsed={collapsedState} />}
        $isCollapsed={collapsedState}
        $noPaddingBody={true}
      >
        {props.children}
      </StyledInfoCard>
    );
  }
  return <InfoCard {...props} $noPaddingBody={true}>{props.children}</InfoCard>;
};

export default CollapsibleMobileInfoCard;
