import React from "react";
import styled from "styled-components";

import { COLORS } from "../../constants/colors";

const StyledDiv = styled.div`
  text-align: center;
  margin-bottom: 160px;
  width: 100%;
`;

const StyledTitle = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: ${COLORS.secondary.purple};
`;

const EmptyStateText = styled.div`
  font-size: 16px;
  line-height: 20px;
  color: ${COLORS.primary.grayDarkLightest};
  margin: 24px 0 32px;
`;

const StyledImg = styled("img")`
  margin: 32px 0;
`;


const EmptyProjectSearchState = () => {
  return (
    <>
      <StyledDiv>
        <StyledImg src={`${process.env.PUBLIC_URL}/assets/YourProposalsEmptyState.png`} />
        <StyledTitle>No Projects Found</StyledTitle>
        <EmptyStateText>
          Try other categories.
        </EmptyStateText>
      </StyledDiv>
    </>
  );
};
export default EmptyProjectSearchState;
