import React, { useCallback, useContext, useState } from "react";

import { MonthProps } from "./ListView";
import { DayInfo, Divider, EventsWrapper, ListType, MonthContainer, SeeMoreText, Today, TodayWrapper } from "./styles";
import ExpandCollapseSomeRows from "../../common/ExpandCollapseSomeRows";
import SuspendedEventRow from "./EventRow";
import { NoProposalsFoundFeed } from "../../NoProposalsFound/NoProposalsFound";
import { useMixpanel } from "../../../hooks";
import { CurrentAccountContext } from "../../../reducers/CurrentAccount";

const CurrentMonth = ({
  proposalData,
  votes,
  setSplitViewTab,
  proposalTypeFilter,
  nextEvents = [],
  nextEventsToday = [],
  pastEvents = [],
  flatProposalEvents,
  bookmarkedProposals,
}: MonthProps) => {
  const [rowsBeingShownPast, setRowsBeingShownPast] = useState(3);
  const [rowsBeingShownNext, setRowsBeingShownNext] = useState(5);
  const { trackExpandPastEventsCurrentMonth, trackExpandUpcomingEventsCurrentMonth } = useMixpanel();
  const { account } = useContext(CurrentAccountContext);

  const toggleSeeMoreNext = useCallback(() => {
    if (rowsBeingShownNext >= nextEvents?.length) {
      setRowsBeingShownNext(5);
    } else {
      setRowsBeingShownNext((current) => current + 10);
    }
    trackExpandUpcomingEventsCurrentMonth({
      userId: account,
    });
  }, [nextEvents?.length, rowsBeingShownNext, trackExpandUpcomingEventsCurrentMonth, account]);

  const toggleSeeMorePast = useCallback(() => {
    if (rowsBeingShownPast >= pastEvents?.length) {
      setRowsBeingShownPast(3);
    } else {
      setRowsBeingShownPast((current) => current + 10);
    }
    trackExpandPastEventsCurrentMonth({
      userId: account,
    });
  }, [pastEvents?.length, rowsBeingShownPast, trackExpandPastEventsCurrentMonth, account]);

  return (
    <MonthContainer>
      <TodayWrapper>
        <Today>
          HAPPENING TODAY <span style={{ fontWeight: "300" }}>{nextEventsToday?.length || 0}</span>{" "}
        </Today>
      </TodayWrapper>
      {!!nextEventsToday?.length && (
        <EventsWrapper style={{ marginBottom: "24px" }} $isToday={!!nextEventsToday?.length}>
          {nextEventsToday?.map((event, index) => (
            <SuspendedEventRow
              isLastItem={index === nextEventsToday?.length - 1}
              isFirstItem={index === 0}
              isToday
              votes={votes}
              proposalData={proposalData}
              event={event}
              key={event.id}
              setSplitViewTab={setSplitViewTab}
              flatProposalEvents={flatProposalEvents}
              bookmarkedProposals={bookmarkedProposals}
            />
          ))}
        </EventsWrapper>
      )}
      {nextEventsToday.length === 0 && <NoProposalsFoundFeed />}
      {proposalTypeFilter === "today" ? (
        <></>
      ) : (
        <>
          <DayInfo>
            <ListType>
              Upcoming & Active <span style={{ fontWeight: "300" }}>{nextEvents?.length || 0}</span>
            </ListType>
          </DayInfo>
          {!!nextEvents?.length && (
            <EventsWrapper style={{ marginBottom: "30px" }}>
              <ExpandCollapseSomeRows
                cutoffIndex={5}
                seeMoreComponent={
                  <SeeMoreText onClick={toggleSeeMoreNext}>
                    {rowsBeingShownNext >= nextEvents?.length
                      ? "see less"
                      : `SEE ${nextEvents.length - rowsBeingShownNext} MORE EVENTS`}
                  </SeeMoreText>
                }
                totalRows={nextEvents?.length}
              >
                {nextEvents?.map((event, index) => (
                  <ExpandCollapseSomeRows.Row
                    index={index}
                    cutoffIndex={5}
                    rowsBeingShown={rowsBeingShownNext}
                    key={event.id}
                  >
                    <SuspendedEventRow
                      isFirstItem={index === 0}
                      lastItemBorderWidth={1}
                      votes={votes}
                      proposalData={proposalData}
                      event={event}
                      key={event.id}
                      setSplitViewTab={setSplitViewTab}
                      flatProposalEvents={flatProposalEvents}
                      bookmarkedProposals={bookmarkedProposals}
                    />
                  </ExpandCollapseSomeRows.Row>
                ))}
              </ExpandCollapseSomeRows>
            </EventsWrapper>
          )}
          {nextEvents.length === 0 && <NoProposalsFoundFeed />}
        </>
      )}

      {proposalTypeFilter === "today" || proposalTypeFilter === "open" ? (
        <></>
      ) : (
        <>
          <Divider />

          <DayInfo>
            <ListType>
              Past Events <span style={{ fontWeight: "300" }}>{pastEvents?.length || 0}</span>
            </ListType>
          </DayInfo>
          {!!pastEvents?.length && (
            <EventsWrapper>
              <ExpandCollapseSomeRows
                cutoffIndex={3}
                seeMoreComponent={
                  <SeeMoreText $isPastEvents onClick={toggleSeeMorePast}>
                    {rowsBeingShownPast >= pastEvents?.length
                      ? "see less"
                      : `SEE ${pastEvents.length - rowsBeingShownPast} MORE PAST EVENTS THIS MONTH` || "see more"}
                  </SeeMoreText>
                }
                totalRows={pastEvents?.length}
              >
                {pastEvents?.map((event, index) => (
                  <ExpandCollapseSomeRows.Row
                    index={index}
                    cutoffIndex={3}
                    rowsBeingShown={rowsBeingShownPast}
                    key={event.id}
                  >
                    <SuspendedEventRow
                      isFirstItem={index === 0}
                      lastItemBorderWidth={1}
                      votes={votes}
                      proposalData={proposalData}
                      event={event}
                      key={event.id}
                      setSplitViewTab={setSplitViewTab}
                      flatProposalEvents={flatProposalEvents}
                      bookmarkedProposals={bookmarkedProposals}
                    />
                  </ExpandCollapseSomeRows.Row>
                ))}
              </ExpandCollapseSomeRows>
            </EventsWrapper>
          )}
          {pastEvents.length === 0 && <NoProposalsFoundFeed />}
        </>
      )}
    </MonthContainer>
  );
};

export default CurrentMonth;
