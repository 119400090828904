import React, { useReducer, createContext } from "react";

interface Props {
  children: React.ReactNode;
}

type Action = { type: "WALLET_PROVIDER"; data: any };

interface WalletProviderContextType {
  walletProvider: boolean;
  dispatchWalletProvider: React.Dispatch<any>;
}

const initialState: boolean = false;

export const WalletProviderContext = createContext({} as WalletProviderContextType);

const reducer = (state: boolean, action: Action) => {
  switch (action.type) {
    case "WALLET_PROVIDER":
      return action.data;
    default:
      return state;
  }
};

export const WalletProvider = (props: Props) => {
  const [walletProvider, dispatchWalletProvider] = useReducer(reducer, initialState);

  return (
    <WalletProviderContext.Provider value={{ walletProvider, dispatchWalletProvider }}>
      {props.children}
    </WalletProviderContext.Provider>
  );
};
