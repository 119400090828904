import React, { useMemo } from "react";
import styled from "styled-components";
import { PropGetters } from "downshift";
import { useHover } from "use-events";

import { ArrowIcon } from "../../../components/icons";
import { COLORS } from "../../../constants/colors";
import media from "../../../media-query";
import { Item } from "./types";

interface Props {
  selected: Item | Item[] | null;
  isOpen: boolean;
  placeholder?: React.ReactNode;
  prefix?: React.ReactNode;
  icon?: React.FC<React.SVGProps<SVGSVGElement>>;
  getButtonProps: PropGetters<Item>["getToggleButtonProps"];
  size?: string;
  justifyEnd?: boolean;
}

export const Button = styled.button<{ isOpen: boolean; size: string; $justifyEnd?: boolean }>`
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  text-align: left;
  background: inherit;
  border: none;
  font-size: ${({ size }) => size};
  font-style: normal;
  font-weight: 300;
  color: ${COLORS.primary.accent};
  outline: 0;
  transition: all 0.2s;
  ${({ $justifyEnd }) => $justifyEnd && "justify-content: end;"}

  :hover {
    cursor: pointer;
  }

  ${media.lessThan("991px")`
    justify-content: center;
    font-size: 16px;
  `}
`;

export const ButtonText = styled.span`
  width: auto;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const ArrowIconContainer = styled.div<{ isOpen: boolean; $justifyEnd?: boolean }>`
  transform: rotate(${({ isOpen }) => (isOpen ? "180deg" : 0)});
  transition: transform 0.2s;
  margin: auto auto auto 8px;
  ${({ $justifyEnd }) => $justifyEnd && "margin-right: 0;"}
  ${media.lessThan("991px")`
    margin: 0 0 0 8px;
  `}
`;

export const DropdownButton = ({
  selected,
  isOpen,
  placeholder = "",
  prefix = "",
  getButtonProps,
  size = "32px",
  justifyEnd,
}: Props) => {
  const [isHovered, bind] = useHover();

  const buttonText = useMemo(() => {
    if (Array.isArray(selected)) {
      return selected.length > 0 ? selected.map((item) => item.name).join(", ") : placeholder;
    }

    return selected?.name || placeholder;
  }, [placeholder, selected]);

  const arrowIconColor = isOpen || isHovered ? COLORS.primary.accent : COLORS.primary.accent;

  return (
    <Button $justifyEnd={justifyEnd} isOpen={isOpen} {...getButtonProps()} {...bind} size={size}>
      <ButtonText>
        {prefix}
        {buttonText}
      </ButtonText>

      <ArrowIconContainer $justifyEnd={justifyEnd} isOpen={isOpen}>
        <ArrowIcon width={14} height={16} color={arrowIconColor} />
      </ArrowIconContainer>
    </Button>
  );
};
