import React from "react";
import styled from "styled-components";
import { COLORS } from "../../../constants/colors";

interface Props {
  date: string;
  isToday: boolean;
  isSelected: boolean;
  isInView: boolean;
  isInFirstRow?: boolean;
}

const getColor = ({ isSelected }: Pick<Props, "isSelected">) => {
  if (isSelected) {
    return COLORS.primary.grayLightest;
  }

  return COLORS.primary.grayDark;
};

const Container = styled.div<Pick<Props, "isToday" | "isSelected" | "isInView" | "isInFirstRow">>`
  height: 100%;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -1px;
  color: ${getColor};
  background: none;
  border-radius: 50%;
  opacity: ${({ isInView }) => (isInView ? "1" : "0.5")};
  padding-top: ${({ isInFirstRow }) => (isInFirstRow ? "0px" : "0px")};
`;

export const Date = ({ date, isToday, isSelected, isInView, isInFirstRow }: Props) => (
  <Container isToday={isToday} isSelected={isSelected} isInView={isInView} isInFirstRow={isInFirstRow}>
    {date}
  </Container>
);
