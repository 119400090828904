import { useQuery } from "@tanstack/react-query";
import { useSdk } from "./useSdk";

export const useOpenZeppelinProtocols = () => {
  const sdk = useSdk();

  const protocols = sdk?.getAllProtocols();

  const { data, isLoading } = useQuery([`openZeppelinProtocols:${protocols?.length}`], async () => {
    const openZeppelinProtocols = protocols?.map(async (protocol) => {
      try {
        const frameworksPromises = protocol.adapterInstances("proposals").map(async (adapter) => {
          const framework = await protocol.adapter("proposals", adapter).getFramework();
          return framework;
        });
        const frameworks = await Promise.all(frameworksPromises);
        const includesOpenZeppelin = frameworks.includes("openZeppelin");
        if (includesOpenZeppelin) {
          return protocol.cname;
        }
      } catch (error) {
        console.error(error);
      }
    });

    const awaitedProtocols = await Promise.all(openZeppelinProtocols || []);

    return (awaitedProtocols?.filter(Boolean) as unknown as string[]) || [];
  });

  return { openZeppelinProtocols: data, isLoading };
};
