import Layout from "antd/es/layout";
import React, { useMemo, useContext, useCallback, Suspense } from "react";
import { useLocation, Switch, Redirect, Route } from "react-router-dom";
import { BreakpointProvider } from "react-socks";
import styled, { createGlobalStyle, ThemeProvider, css } from "styled-components";
import { SSXProvider, SSXProviderProps } from "@spruceid/ssx-react";
import { useConnectWallet } from "@web3-onboard/react";

import { GlobalNav } from "./components";
import { MarkdownStyle } from "./styles/MarkdownStyle";
import styledTheme from "./theme";
import { COLORS } from "./constants/colors";
import { fontFamily } from "./constants/typography";
import media from "./media-query";
import { WalletStyles } from "./components/NavBar/WalletStyles";
import ProtocolsList from "./pages/ProtocolsList";

const AddDao = React.lazy(() => import("./pages/AddDAO"));
const Dashboard = React.lazy(() => import("./pages/Dashboard/Dashboard"));
const VoterProfile = React.lazy(() => import("./pages/VoterProfile/VoterProfile"));
const ProtocolOverview = React.lazy(() => import("./pages/ProtocolOverview/ProtocolOverview"));
const Create = React.lazy(() => import("./pages/Create"));
const UpdateTags = React.lazy(() => import("./pages/UpdateTags"));
const Billing = React.lazy(() => import("./pages/Billing"));
const Api = React.lazy(() => import("./pages/Api"));
const Usage = React.lazy(() => import("./pages/Usage"));
const Webhooks = React.lazy(() => import("./pages/Webhooks"));

import Custom404 from "./pages/Custom404";
import { RoutePaths } from "./constants/Routes";
import { Toast } from "./components/Toast";
import { SearchDropdownContext } from "./reducers/ProjectSearchDropdown";
import "./global.css";
import DelegateMobileFilter from "./components/Delegates/MobileFilter";
import { bg1, bg2, bg3, bg4, bg5, bg6, bg7, bg8, bg9 } from "./constants/protocolBgImages";
import { baseAPIUrl, VOTER_PROFILE_SCROLL_SHRINK_PROPOSALS } from "./constants/general";
import { useScrolledHeight } from "./reducers/ScrolledHeight";
import { useMobileGlobalMenu } from "./reducers/MobileGlobalMenu";
import { PendingVoteDetailsProvider } from "./reducers/PendingVotes";
import ProtocolOverviewSkeleton from "./pages/ProtocolOverview/ProtocolOverviewSuspense";
import { PendingVoteTransactionsStatus } from "./components/PendingTransactionsStatus";

import CalendarSuspense from "./pages/DashboardPages/Calendar/CalendarSuspense";
import { SettingsSuspense } from "./pages/DashboardPages/Settings";
import DashboardSuspense from "./pages/Dashboard/DashboardSuspense";
import VoterProfileSkeleton from "./pages/VoterProfile/VoterProfileSkeleton";
import TeamProfile from "./pages/Team/TeamProfile";

import { CurrentDaoLoginContext } from "./reducers/DaoLogin";
import Delegation from "./pages/Delegation/Delegation";
import DelegationSkeleton from "./pages/Delegation/DelegationSkeleton";
import TeamDetails from "./pages/TeamDetails";
import Onboard from "./pages/Onboard/Onboard";
import Discuss from "./pages/Discuss/Discuss";
import DailyBriefs from "./pages/DailyBriefs/DailyBriefs";
import { pageHeaderHeight } from "./constants/styles";
import { MainSidebarNav } from "./components/NavRail/MainSidebarNav";
import NftGate from "./components/NftGate";

const { Content } = Layout;

const standAloneProtocolCname = process.env.REACT_APP_STANDALONE_PROTOCOL;

export const GlobalStyle = createGlobalStyle`
  html {
    --background-primary-nav: #001529;
    --color-text-nav: #e7e8eb;
    --background-switcher: #032e58;
    --background-switcher-hover: #004180;
    --background-switcher-active: #000000;
  }
  body {
    font-family: ${fontFamily};
    background: ${COLORS.primary.grayLightest};
    &.ReactModal__Body--open {
      height: 100vh;
      overflow-y: hidden;
      position: fixed;
    }
    .bm-burger-button {
      z-index: 1000;
      position: absolute;
      width: 40px;
      height: 47px;
      color: var(--color-text-nav);
      font-size: 20px;
      top: 6px;
      left: 9px;
      padding: 10px;
    }
    .protocolDropdown {
      padding-left: 10px;
      background-color: transparent;
      left: 0px !important;
      &:not(.ant-select-dropdown-hidden) {
        left: 5px !important;
        z-index: 1500;
      }
      > div {
        background-color: var(--background-switcher);
      }
      .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
          background-color: var(--background-switcher-hover);
        }
        .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
          background-color: var(--background-switcher-active);
          color: var(--color-text-nav);
        }
        .ant-select-item-option-content {
          color: var(--color-text-nav);
          height: 40px;
          padding-top: 8px;
        }
      }
      .ant-empty {
        color: var(--color-text-nav);
        padding: 10px;
      }
    }
    a:hover {
       color: ${COLORS.primary.accentDarker}
    }
  }
  ::selection {
    background: ${COLORS.primary.accentLight};
    color: inherit;
    text-fill-color: white;
    -webkit-text-fill-color: white;
  }
`;

const LayoutContent = styled(Content)<{ $isDropdownOpen: boolean }>`
  background: ${({ $isDropdownOpen }) => ($isDropdownOpen ? "transparent" : "white")};
  position: relative;
  flex: none;
  min-height: calc(100vh - ${pageHeaderHeight});
  background: #fafafa;
  ${({ $isDropdownOpen }) => $isDropdownOpen && "min-height: calc(100vh + 8px);"}
  padding-bottom: 0px;
`;

const SiteLayoutContent = styled.div`
  width: 100%;
  margin: auto;
  ${media.lessThan("991px")`
    top: 0px;
    padding: 0;
  `};
`;

const MainLayout = styled(Layout)<{ $backgroundNumber: any; $isDropdownOpen: boolean; $isMenuOpen: boolean }>`
  background: ${({ $backgroundNumber }) => `url(${$backgroundNumber})`};
  background-size: cover;
  overflow: ${({ $isDropdownOpen }) => ($isDropdownOpen ? "auto" : "hidden")};

  ${media.greaterThan("991px")`
    height:100vh;
  `};
  ${media.lessThan("991px")`
    height: auto;
  `};
  ${({ $isMenuOpen }) =>
    $isMenuOpen &&
    css`
      ${media.lessThan("640px")`
      overflow: hidden;
      height: 70vh;
      `};
    `};
`;

const MainContentLayout = styled(Layout)`
  background: transparent;
  height: auto;
  ${media.lessThan("991px")`
    margin-left: 0px;
  `}
  .ant-layout {
    background: #fafafa;
  }
`;

const NavWrapper = styled.div`
  background-color: #fff;
  padding-left: 100px;
  border-bottom: 1px solid ${COLORS.primary.grayLighter};
`;

const ContentDiv = styled.div``;

const GridWrapper = styled.div`
  padding-left: 100px;
  background-color: #fafafa;
  margin: auto;
  width: 100%;
  ${media.greaterThan("2000px")`
    padding-left: 0px;
    max-width: 1680px;
  `};
  ${media.lessThan("991px")`
    padding-left: 0px;
  `};
`;

function App() {
  const { searchDropDownState } = useContext(SearchDropdownContext);
  const { daoLogin: isDaoLoginEnabled } = useContext(CurrentDaoLoginContext);
  const [{ wallet }] = useConnectWallet();
  const { pathname } = useLocation();
  const mainRoutePath = pathname.split("/")[1];

  const { isMenuOpen } = useMobileGlobalMenu();

  const backgroundNumber = useMemo(() => {
    const bgImages = [bg2, bg3, bg4, bg5, bg6, bg7, bg8, bg9];
    if (mainRoutePath) {
      const index = Math.ceil(Math.random() * 7);
      return bgImages[index];
    }
    return bg1;
  }, [mainRoutePath]);

  const { scrolledHeight, setScrolledHeight } = useScrolledHeight();

  const onScroll = useCallback(
    (e: any) => {
      const scrollShrinkHeight = VOTER_PROFILE_SCROLL_SHRINK_PROPOSALS;
      if (e.target.scrollTop <= scrollShrinkHeight) {
        setScrolledHeight(e.target.scrollTop);
      } else if (e.target.scrollTop > scrollShrinkHeight && scrolledHeight < scrollShrinkHeight) {
        setScrolledHeight(scrollShrinkHeight);
      }
    },
    [scrolledHeight, setScrolledHeight],
  );

  const web3Provider = useMemo(() => {
    return {
      provider: wallet?.provider,
      providerLoaded: !!wallet?.provider,
    };
  }, [wallet?.provider]);

  const ssxConfig: SSXProviderProps["ssxConfig"] = useMemo(
    () => ({
      siweConfig: {
        statement: "Sign into Boardroom with this wallet",
        chainId: 1,
      },
      enableDaoLogin: isDaoLoginEnabled,
      providers: {
        server: {
          host: baseAPIUrl,
          routes: {
            nonce: {
              url: "/siwe/nonceV2?key=c909030d97aaa3f635a09eb4a3df58fc",
              method: "post",
              withCredentials: false,
            } as any,
            login: {
              url: "/siwe/signIn?key=c909030d97aaa3f635a09eb4a3df58fc",
              method: "post",
              withCredentials: false,
            } as any,
            logout: {
              url: "/siwe/signOut?key=c909030d97aaa3f635a09eb4a3df58fc",
              method: "post",
              withCredentials: false,
            } as any,
          },
        },
      },
    }),
    [isDaoLoginEnabled],
  );

  return (
    <ThemeProvider theme={styledTheme}>
      <BreakpointProvider>
        <Toast />
        <GlobalStyle />
        <MarkdownStyle />
        <WalletStyles />
        <MainLayout
          className="ant-layout hidden"
          $backgroundNumber={backgroundNumber}
          $isDropdownOpen={searchDropDownState}
          onScroll={onScroll}
          $isMenuOpen={isMenuOpen}
        >
          <SSXProvider ssxConfig={ssxConfig} web3Provider={web3Provider}>
            <PendingVoteDetailsProvider>
              <PendingVoteTransactionsStatus />
              <MainContentLayout>
                {!standAloneProtocolCname && <MainSidebarNav />}
                <Layout>
                  <NavWrapper>
                    <GlobalNav />
                  </NavWrapper>
                  <GridWrapper>
                    <ContentDiv>
                      <LayoutContent $isDropdownOpen={searchDropDownState}>
                        <SiteLayoutContent>
                          <DelegateMobileFilter />
                          <Switch>
                            <Redirect from="/screener" to="/" />
                            {standAloneProtocolCname ? (
                              <Redirect from="/" exact to={`/${standAloneProtocolCname}/proposals`} />
                            ) : (
                              <Route
                                exact
                                path="/feed"
                                render={(props: any) => <Redirect to={`/${props?.location?.search}`} />}
                              />
                            )}
                            {standAloneProtocolCname && (
                              <Redirect from="/feed" exact to={`/${standAloneProtocolCname}/proposals`} />
                            )}
                            <Redirect from="/feed/settings/feed" to="/settings/feed" />
                            <Redirect from="/feed/settings/notifications" to="/settings/notifications" />
                            <Redirect from="/feed/settings/profile" to="/settings/profile" />
                            <Redirect from="/feed/settings/team-profile" to="/settings/bundle-profile" />
                            <Redirect from="/feed/settings/delegate" to="/settings/delegate" />
                            <Redirect from="/feed/settings/email" to="/settings/email" />
                            <Redirect from="/feed/settings/access" to="/settings/access" />
                            <Redirect from="/feed/settings/api" to="/developers/billing" />
                            <Redirect from="/settings/api" to="/developers/billing" />
                            <Redirect exact from="/delegation" to="/people" />
                            <Redirect from="/delegation/your-projects" to="/people/your-projects" />
                            <Route
                              exact
                              path="/team/:identifier"
                              render={(props: any) => <Redirect to={`/bundle/${props?.match.params.identifier}`} />}
                            />
                            <Route
                              exact
                              path="/team-details/:identifier"
                              render={(props: any) => (
                                <Redirect to={`/bundle-details/${props?.match.params.identifier}`} />
                              )}
                            />
                            <Route
                              exact
                              path="/delegation/:protocol/:identifier"
                              render={(props: any) => (
                                <Redirect
                                  to={`/people/${props?.match.params.protocol}/${props?.match.params.identifier}`}
                                />
                              )}
                            />
                            <Route
                              exact
                              path={RoutePaths.addDao}
                              render={() => (
                                <Suspense fallback={<></>}>
                                  <AddDao />
                                </Suspense>
                              )}
                              key={RoutePaths.addDao}
                            />
                            <Route
                              exact
                              path={RoutePaths.create}
                              render={() => (
                                <Suspense
                                  fallback={
                                    <DashboardSuspense>
                                      <CalendarSuspense />
                                    </DashboardSuspense>
                                  }
                                >
                                  <Create />
                                </Suspense>
                              )}
                              key={RoutePaths.create}
                            />
                            <Route
                              exact
                              path={RoutePaths.updateTags}
                              render={() => (
                                <Suspense
                                  fallback={
                                    <DashboardSuspense>
                                      <CalendarSuspense />
                                    </DashboardSuspense>
                                  }
                                >
                                  <UpdateTags />
                                </Suspense>
                              )}
                              key={RoutePaths.updateTags}
                            />
                            <Route
                              exact
                              path={RoutePaths.webhooks}
                              render={() => (
                                <Suspense
                                  fallback={
                                    <DashboardSuspense>
                                      <CalendarSuspense />
                                    </DashboardSuspense>
                                  }
                                >
                                  <Webhooks />
                                </Suspense>
                              )}
                              key={RoutePaths.webhooks}
                            />
                            <Route
                              exact
                              path={RoutePaths.billing}
                              render={() => (
                                <Suspense
                                  fallback={
                                    <DashboardSuspense>
                                      <CalendarSuspense />
                                    </DashboardSuspense>
                                  }
                                >
                                  <Billing />
                                </Suspense>
                              )}
                              key={RoutePaths.billing}
                            />
                            <Route
                              exact
                              path={RoutePaths.api}
                              render={() => (
                                <Suspense
                                  fallback={
                                    <DashboardSuspense>
                                      <CalendarSuspense />
                                    </DashboardSuspense>
                                  }
                                >
                                  <Api />
                                </Suspense>
                              )}
                              key={RoutePaths.api}
                            />
                            <Route
                              exact
                              path={RoutePaths.usage}
                              render={() => (
                                <Suspense
                                  fallback={
                                    <DashboardSuspense>
                                      <CalendarSuspense />
                                    </DashboardSuspense>
                                  }
                                >
                                  <Usage />
                                </Suspense>
                              )}
                              key={RoutePaths.api}
                            />
                            <Route
                              exact
                              path={RoutePaths.onboard}
                              render={() => (
                                <Suspense
                                  fallback={
                                    <DashboardSuspense>
                                      <CalendarSuspense />
                                    </DashboardSuspense>
                                  }
                                >
                                  <NftGate>
                                    <Onboard />
                                  </NftGate>
                                </Suspense>
                              )}
                              key={RoutePaths.onboard}
                            />
                            <Route
                              exact
                              path={RoutePaths.delegation}
                              render={() => (
                                <Suspense fallback={<DelegationSkeleton />}>
                                  <Delegation />
                                </Suspense>
                              )}
                              key={RoutePaths.delegation}
                            />
                            <Route
                              exact
                              path={RoutePaths.delegationYourProjects}
                              render={() => (
                                <Suspense fallback={<DelegationSkeleton />}>
                                  <Delegation />
                                </Suspense>
                              )}
                              key={RoutePaths.delegationYourProjects}
                            />

                            <Route
                              exact
                              path={RoutePaths.teamDetails}
                              render={() => (
                                <Suspense fallback={<SettingsSuspense />}>
                                  <TeamDetails />
                                </Suspense>
                              )}
                              key={RoutePaths.teamDetails}
                            />

                            <Route
                              exact
                              path={RoutePaths.delegationUser}
                              render={() => (
                                <Suspense fallback={<DelegationSkeleton />}>
                                  <Delegation />
                                </Suspense>
                              )}
                              key={RoutePaths.delegationUser}
                            />

                            <Route
                              exact
                              path={RoutePaths.feed}
                              render={() => (
                                <Suspense
                                  fallback={
                                    <DashboardSuspense>
                                      <CalendarSuspense />
                                    </DashboardSuspense>
                                  }
                                >
                                  <Dashboard />
                                </Suspense>
                              )}
                              key={RoutePaths.feed}
                            />
                            <Route
                              exact
                              path={RoutePaths.discuss}
                              render={() => (
                                <Suspense
                                  fallback={
                                    <DashboardSuspense>
                                      <CalendarSuspense />
                                    </DashboardSuspense>
                                  }
                                >
                                  <NftGate>
                                    <Discuss />
                                  </NftGate>
                                </Suspense>
                              )}
                              key={RoutePaths.discuss}
                            />
                            <Route
                              exact
                              path={RoutePaths.dailyBriefs}
                              render={() => (
                                <Suspense
                                  fallback={
                                    <DashboardSuspense>
                                      <CalendarSuspense />
                                    </DashboardSuspense>
                                  }
                                >
                                  <NftGate>
                                    <DailyBriefs />
                                  </NftGate>
                                </Suspense>
                              )}
                              key={RoutePaths.dailyBriefs}
                            />
                            <Route
                              exact
                              path={RoutePaths.settingsProfile}
                              render={() => (
                                <Suspense
                                  fallback={
                                    <DashboardSuspense>
                                      <SettingsSuspense />
                                    </DashboardSuspense>
                                  }
                                >
                                  <Dashboard />
                                </Suspense>
                              )}
                              key={RoutePaths.settingsProfile}
                            />
                            <Route
                              exact
                              path={RoutePaths.settingsTeamProfile}
                              render={() => (
                                <Suspense
                                  fallback={
                                    <DashboardSuspense>
                                      <SettingsSuspense />
                                    </DashboardSuspense>
                                  }
                                >
                                  <Dashboard />
                                </Suspense>
                              )}
                              key={RoutePaths.settingsTeamProfile}
                            />
                            <Route
                              exact
                              path={RoutePaths.settingsTeamFeed}
                              render={() => (
                                <Suspense
                                  fallback={
                                    <DashboardSuspense>
                                      <SettingsSuspense />
                                    </DashboardSuspense>
                                  }
                                >
                                  <Dashboard />
                                </Suspense>
                              )}
                              key={RoutePaths.settingsTeamFeed}
                            />
                            <Route
                              exact
                              path={RoutePaths.settingsDelegate}
                              render={() => (
                                <Suspense
                                  fallback={
                                    <DashboardSuspense>
                                      <SettingsSuspense />
                                    </DashboardSuspense>
                                  }
                                >
                                  <Dashboard />
                                </Suspense>
                              )}
                              key={RoutePaths.settingsDelegate}
                            />
                            <Route
                              exact
                              path={RoutePaths.settingsFeed}
                              render={() => (
                                <Suspense
                                  fallback={
                                    <DashboardSuspense>
                                      <SettingsSuspense />
                                    </DashboardSuspense>
                                  }
                                >
                                  <Dashboard />
                                </Suspense>
                              )}
                              key={RoutePaths.settingsFeed}
                            />
                            <Route
                              exact
                              path={RoutePaths.settingsNotifications}
                              render={() => (
                                <Suspense
                                  fallback={
                                    <DashboardSuspense>
                                      <SettingsSuspense />
                                    </DashboardSuspense>
                                  }
                                >
                                  <Dashboard />
                                </Suspense>
                              )}
                              key={RoutePaths.settingsNotifications}
                            />
                            <Route
                              exact
                              path={RoutePaths.settingsEmail}
                              render={() => (
                                <Suspense
                                  fallback={
                                    <DashboardSuspense>
                                      <SettingsSuspense />
                                    </DashboardSuspense>
                                  }
                                >
                                  <Dashboard />
                                </Suspense>
                              )}
                              key={RoutePaths.settingsEmail}
                            />
                            <Route
                              exact
                              path={RoutePaths.subscriptionBilling}
                              render={() => (
                                <Suspense
                                  fallback={
                                    <DashboardSuspense>
                                      <SettingsSuspense />
                                    </DashboardSuspense>
                                  }
                                >
                                  <Dashboard />
                                </Suspense>
                              )}
                              key={RoutePaths.subscriptionBilling}
                            />
                            <Route
                              exact
                              path={RoutePaths.projects}
                              component={ProtocolsList}
                              key={RoutePaths.projects}
                            />
                            <Route
                              exact
                              path={RoutePaths.protocols}
                              component={ProtocolsList}
                              key={RoutePaths.protocols}
                            />
                            <Route
                              exact
                              path={RoutePaths.voterProfile}
                              render={() => (
                                <Suspense fallback={<VoterProfileSkeleton />}>
                                  <VoterProfile />
                                </Suspense>
                              )}
                              key={RoutePaths.voterProfile}
                            />
                            <Route
                              exact
                              path={RoutePaths.teamProfile}
                              render={() => (
                                <Suspense fallback={<VoterProfileSkeleton />}>
                                  <TeamProfile />
                                </Suspense>
                              )}
                              key={RoutePaths.teamProfile}
                            />
                            <Route
                              exact={false}
                              path={RoutePaths.protocol.overview}
                              render={() => (
                                <Suspense fallback={<ProtocolOverviewSkeleton />}>
                                  <ProtocolOverview />
                                </Suspense>
                              )}
                              key={RoutePaths.protocol.overview}
                            />
                            <Route path="*" component={Custom404} />
                          </Switch>
                        </SiteLayoutContent>
                      </LayoutContent>
                    </ContentDiv>
                  </GridWrapper>
                </Layout>
              </MainContentLayout>
            </PendingVoteDetailsProvider>
          </SSXProvider>
        </MainLayout>
      </BreakpointProvider>
    </ThemeProvider>
  );
}
export default App;
