import React, { useState } from "react";
import { useCurrentWidth } from "react-socks";
import styled from "styled-components";
import parse from "html-react-parser";
import Select from "antd/es/select";

import media from "../../media-query";
import { ArrowIcon } from "../icons";

const { Option } = Select;

const FiltersWrapper = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #f0eff8;
`;

export const Dot = styled("div")`
  height: 8px;
  width: 8px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 4px;
`;

const LatestOrTopWrapper = styled("div")`
  display: flex;
  align-items: center;
`;

const LatestOrTopSelector = styled("span")<{ $marginRight?: string; $isSelected?: boolean }>`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #4235e1;
  margin-right: ${({ $marginRight }) => $marginRight || "24px"};
  cursor: pointer;
  opacity: ${({ $isSelected }) => ($isSelected ? "1" : "0.3")};
`;

const LatestOrTopSelectedIndicator = styled("div")`
  width: 16px;
  background-color: #4235e1;
  height: 2.5px;
  position: absolute;
  bottom: 0;
`;

const StyledSelect = styled(Select)<{
  $removeBorderRight?: boolean;
  $paddingLeft?: string;
  $paddingTop?: string;
  $isOpen?: boolean;
  $margin?: string;
}>`
  color: #4235e1;
  border-right: ${({ $removeBorderRight }) => ($removeBorderRight ? "0px" : "1px solid #f0eff8")};
  padding-top: ${({ $paddingTop }) => $paddingTop || "0px"};
  ${({ $margin }) => `margin: ${$margin};`}
  .ant-select-selector {
    padding-left: ${({ $paddingLeft }) => $paddingLeft || "11px"} !important;
    padding-right: 24px !important;
  }
  .ant-select-selection-item {
    display: flex;
  }
  .ant-select-arrow {
    transition: all 0.3s;
    ${({ $isOpen }) => $isOpen && "transform: rotate(180deg);"}
  }
  ${media.lessThan("991px")`
    font-weight: 300;
    font-size: 18px;
    line-height: 28px;
    .ant-select-arrow {
      margin-top: -5px;
    }
  `}
`;

const CategoryWrapper = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CategoryNameWrapper = styled("div")`
  display: flex;
  align-items: center;
`;

const CategoryName = styled("span")<{ $isSelected?: boolean }>`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: ${({ $isSelected }) => ($isSelected ? "#4235e1" : "#7b7893")};
`;

const TagName = styled("span")<{ $isSelected?: boolean }>`
  font-weight: 300;
  font-size: 14px;
  line-height: 28px;
  color: ${({ $isSelected }) => ($isSelected ? "#4235e1" : "#7b7893")};
`;

const CategoryTopicCount = styled("span")`
  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: #7b7893;
`;

const CategoryDescription = styled("p")`
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  color: #7b7893;
  margin-top: 8px;
  max-width: 276px;
  white-space: normal;
`;

const FilterByText = styled("span")`
  font-weight: 300;
  font-size: 18px;
  line-height: 28px;
  color: #191540;
`;

const FilterByWrapper = styled("div")`
  padding-bottom: 16px;
  margin-top: -10px;
  border-bottom: 1px solid #f0eff8;
  width: calc(100vw - 40px);
`;

const MobileFiltersWrapper = styled("div")`
  display: flex;
  flex-direction: column;
`;

export const SelectWrapper = ({
  onChange,
  value,
  children,
  defaultValue,
  $removeBorderRight,
  $paddingLeft,
  $paddingTop,
  $margin,
}: {
  onChange: any;
  value: string;
  children: React.ReactNode;
  defaultValue: string;
  $removeBorderRight?: boolean;
  $paddingLeft?: string;
  $paddingTop?: string;
  $margin?: string;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <StyledSelect
      onChange={onChange}
      value={value}
      suffixIcon={<ArrowIcon width={14} height={14} color="#4235e1" />}
      bordered={false}
      dropdownMatchSelectWidth={false}
      defaultValue={defaultValue}
      $removeBorderRight={$removeBorderRight}
      $paddingLeft={$paddingLeft}
      $paddingTop={$paddingTop}
      $margin={$margin}
      $isOpen={isOpen}
      open={isOpen}
      onClick={() => setIsOpen((current) => !current)}
      onBlur={() => setIsOpen(false)}
    >
      {children}
    </StyledSelect>
  );
};

interface Props {
  categories: {
    id: number;
    name: string;
    slug: string;
    description: string;
    topic_count: number;
    color: string;
  }[];
  tags: string[];
  selectedCategory: string;
  selectedTag: string;
  latestOrTop: string;
  topPeriod: string;
  setSelectedCategory: any;
  setSelectedTag: any;
  setLatestOrTop: any;
  setTopPeriod: any;
}

const ProtocolForumFilters = ({
  categories,
  tags,
  selectedCategory,
  selectedTag,
  setSelectedCategory,
  setSelectedTag,
  setLatestOrTop,
  setTopPeriod,
  latestOrTop,
  topPeriod,
}: Props) => {
  const currentWidth = useCurrentWidth();
  const isMobile = currentWidth <= 991;

  return (
    <FiltersWrapper>
      {!isMobile ? (
        <>
          <LatestOrTopWrapper>
            <div>
              <LatestOrTopSelector $isSelected={latestOrTop === "latest"} onClick={() => setLatestOrTop("latest")}>
                Latest
              </LatestOrTopSelector>
              {latestOrTop === "latest" && <LatestOrTopSelectedIndicator />}
            </div>
            <div>
              <LatestOrTopSelector
                $isSelected={latestOrTop === "top"}
                $marginRight="4px"
                onClick={() => setLatestOrTop("top")}
              >
                Top
              </LatestOrTopSelector>
              {latestOrTop === "top" && <LatestOrTopSelectedIndicator />}
            </div>
            {latestOrTop === "top" && (
              <>
                <span style={{ color: "#4235e1" }}>/</span>
                <SelectWrapper
                  value={topPeriod}
                  $paddingLeft="4px"
                  $removeBorderRight
                  defaultValue="all"
                  onChange={setTopPeriod}
                >
                  <Option value="all">All time</Option>
                  <Option value="yearly">Year</Option>
                  <Option value="quarterly">Quarter</Option>
                  <Option value="monthly">Month</Option>
                  <Option value="weekly">Week</Option>
                  <Option value="daily">Today</Option>
                </SelectWrapper>
              </>
            )}
          </LatestOrTopWrapper>
          <div>
            <SelectWrapper
              onChange={(value: any) => {
                setSelectedCategory(value);
                setSelectedTag("all");
              }}
              value={selectedCategory}
              defaultValue="all"
            >
              <Option value="all">All Categories</Option>
              {categories?.map((category) => {
                const isSelected = category.slug === selectedCategory;

                return (
                  <Option key={category.id} value={category.slug}>
                    <CategoryWrapper>
                      <CategoryNameWrapper>
                        <Dot style={{ backgroundColor: `#${category?.color}` }} />
                        <CategoryName $isSelected={isSelected}>{category.name}</CategoryName>
                      </CategoryNameWrapper>
                      {!isSelected && <CategoryTopicCount>{category.topic_count}</CategoryTopicCount>}
                    </CategoryWrapper>
                    {!isSelected && <CategoryDescription>{parse(category.description || "")}</CategoryDescription>}
                  </Option>
                );
              })}
            </SelectWrapper>
            <SelectWrapper
              onChange={(value: any) => {
                setSelectedTag(value);
                setSelectedCategory("all");
              }}
              value={selectedTag}
              defaultValue="all"
              $removeBorderRight
            >
              <Option value="all">All Tags</Option>
              {tags?.map((tag) => (
                <Option key={tag} value={tag}>
                  <TagName $isSelected={tag === selectedTag}>{tag}</TagName>
                </Option>
              ))}
            </SelectWrapper>
          </div>
        </>
      ) : (
        <MobileFiltersWrapper>
          <FilterByWrapper>
            <FilterByText>Filter by</FilterByText>
            <SelectWrapper $removeBorderRight value={latestOrTop} onChange={setLatestOrTop} defaultValue="latest">
              <Option value="latest">Latest</Option>
              <Option value="top">Top</Option>
              <Option value="category">Category</Option>
              <Option value="tag">Tag</Option>
            </SelectWrapper>
          </FilterByWrapper>
          {latestOrTop === "top" && (
            <SelectWrapper
              $paddingLeft="0px"
              defaultValue="all"
              $removeBorderRight
              onChange={setTopPeriod}
              value={topPeriod}
              $margin="8px 0"
            >
              <Option value="all">All time</Option>
              <Option value="yearly">Year</Option>
              <Option value="quarterly">Quarter</Option>
              <Option value="monthly">Month</Option>
              <Option value="weekly">Week</Option>
              <Option value="daily">Today</Option>
            </SelectWrapper>
          )}
          {latestOrTop === "category" && (
            <SelectWrapper
              onChange={(value: any) => {
                setSelectedCategory(value);
                setSelectedTag("all");
              }}
              value={selectedCategory}
              $paddingLeft="0px"
              defaultValue="all"
              $margin="8px 0"
            >
              <Option value="all">All Categories</Option>
              {categories?.map((category) => {
                const isSelected = category.slug === selectedCategory;

                return (
                  <Option key={category.id} value={category.slug}>
                    <CategoryWrapper>
                      <CategoryNameWrapper>
                        <Dot style={{ backgroundColor: `#${category?.color}` }} />
                        <CategoryName $isSelected={isSelected}>{category.name}</CategoryName>
                      </CategoryNameWrapper>
                      {!isSelected && <CategoryTopicCount>{category.topic_count}</CategoryTopicCount>}
                    </CategoryWrapper>
                    {!isSelected && <CategoryDescription>{category.description}</CategoryDescription>}
                  </Option>
                );
              })}
            </SelectWrapper>
          )}
          {latestOrTop === "tag" && (
            <SelectWrapper
              onChange={(value: any) => {
                setSelectedTag(value);
                setSelectedCategory("all");
              }}
              value={selectedTag}
              defaultValue="all"
              $removeBorderRight
              $paddingLeft="0px"
              $margin="8px 0"
            >
              <Option value="all">All Tags</Option>
              {tags?.map((tag) => (
                <Option key={tag} value={tag}>
                  <TagName $isSelected={tag === selectedTag}>{tag}</TagName>
                </Option>
              ))}
            </SelectWrapper>
          )}
        </MobileFiltersWrapper>
      )}
    </FiltersWrapper>
  );
};

export default ProtocolForumFilters;
