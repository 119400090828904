import React, { useMemo, Suspense } from "react";
import styled from "styled-components";
import { useCurrentWidth } from "react-socks";
import ContentLoader from "react-content-loader";

import media from "../../media-query";
import { VOTER_PROFILE_SCROLL_SHRINK_MOBILE } from "../../constants/general";

interface Props {
  scrolledHeight: number;
  bannerImage?: any;
}

const BannerWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 224px;
  margin-bottom: -20px;
  ${media.lessThan("991px")`
    height: 124px;
  `}
`;

const BannerImage = styled("img")`
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center;
  object-fit: cover;
`;

const ProfileBanner = ({ scrolledHeight, bannerImage }: Props) => {
  const isMobile = useCurrentWidth() < 991;

  const randomNumber = useMemo(() => {
    return Math.ceil(Math.random() * 5);
  }, []);

  const addDefaultSrc = (event: any) => {
    event.target.src = `${process.env.PUBLIC_URL}/assets/ProfileBanner${randomNumber}.png`;
  };

  return (
    <BannerWrapper>
      <BannerImage
        src={bannerImage || `${process.env.PUBLIC_URL}/assets/ProfileBanner${randomNumber}.png`}
        onError={addDefaultSrc}
        style={{
          opacity: isMobile
            ? `${(VOTER_PROFILE_SCROLL_SHRINK_MOBILE - scrolledHeight) / VOTER_PROFILE_SCROLL_SHRINK_MOBILE}`
            : 1,
        }}
      />
    </BannerWrapper>
  );
};

function ProfileBannerWrapper(props: Props) {
  return (
    <Suspense
      fallback={
        <ContentLoader
          speed={2}
          width="100%"
          height={224}
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
          {...props}
        >
          <rect x="0" y="0" rx="5" ry="5" width="100%" height="224" />
        </ContentLoader>
      }
    >
      <ProfileBanner {...props} />
    </Suspense>
  );
}

export default React.memo(ProfileBannerWrapper);
