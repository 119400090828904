import React, { useReducer, createContext } from "react";

interface Props {
  children: React.ReactNode;
}

type Action = { type: "SAVE_POPOVER_OPEN"; data: boolean };

interface CurrentPopoverOpenType {
  popoverOpen: boolean;
  dispatchPopoverOpen: React.Dispatch<any>;
}

const initialState = false;

export const CurrentPopoverOpenContext = createContext({} as CurrentPopoverOpenType);

const reducer = (state: boolean, action: Action) => {
  switch (action.type) {
    case "SAVE_POPOVER_OPEN":
      return action.data;
    default:
      return state;
  }
};

export const CurrentPopoverOpenProvider = (props: Props) => {
  const [popoverOpen, dispatchPopoverOpen] = useReducer(reducer, initialState);

  return (
    <CurrentPopoverOpenContext.Provider value={{ popoverOpen, dispatchPopoverOpen }}>
      {props.children}
    </CurrentPopoverOpenContext.Provider>
  );
};
