import React, { useCallback, useContext, useState } from "react";
import styled from "styled-components";
import WalletOutlined from "@ant-design/icons/lib/icons/WalletOutlined";
import { useLocation } from "react-router";
import { LoginOutlined } from "@ant-design/icons";

import { Modal, NavAvatar } from "../";
import { Button } from "../Button";
import { useOnboardWallet } from "../../hooks/useOnboardWallet";
import { CurrentAccountContext } from "../../reducers/CurrentAccount";
import media from "../../media-query";
import { EmailLoginModal } from "../EmailLoginModal/EmailLoginModal";
import { RoutePaths } from "../../constants/Routes";

const ConnectedWrapper = styled.span`
  color: #fff;
  display: flex;
  justify-content: space-between;
  width: 100%;
  min-width: auto;
  ${media.lessThan("991px")`
    width: 75px;
    display: inline-block;
    padding: 0px 10px;
  `}
`;

const StyledLabel = styled.span`
  && {
    ${media.lessThan("991px")`
    display: none;
  `}
  }
`;

const StyledButton = styled(Button)<{ $isFeatureLandingPage?: boolean }>`
  && {
    background: linear-gradient(89.28deg, #4235e1 3.44%, #ee3fd9 175.86%);
    box-shadow: 0px 0px 8px rgba(25, 21, 64, 0.2);
    ${({ $isFeatureLandingPage }) => $isFeatureLandingPage && "border: 1px solid #FFFFFF;"}
    &:hover {
      background: linear-gradient(0deg, #4235e1, #4235e1);
    }
  }
`;

const StyledLogOutIcon = styled(LoginOutlined)<{ $isPortal?: boolean }>`
  color: ${({ $isPortal }) => ($isPortal ? "#4235e1" : "white")};
  display: inline-block;
  font-size: 18px;
  margin: auto 0;
  margin-right: 30px;
  cursor: pointer;
`;

const StyledLoginWrapper = styled.div`
  display: flex;
`;

const Wallet = ({ isFeatureLandingPage }: { isFeatureLandingPage?: boolean }) => {
  const { account } = useContext(CurrentAccountContext);
  const wallet = useOnboardWallet();
  const [isEmailLoginModalOpen, setIsEmailLoginModalOpen] = useState(false);
  const { pathname } = useLocation();
  const isPortal = pathname === RoutePaths.feed || pathname.includes("/people");

  const onClose = useCallback(() => {
    setIsEmailLoginModalOpen(false);
  }, []);

  const onOpen = useCallback(() => {
    setIsEmailLoginModalOpen(true);
  }, []);

  return (
    <>
      {!account && <StyledLogOutIcon $isPortal={isPortal} onClick={onOpen} />}
      <Modal customMinHeight="auto" zIndex={20} size="large" open={isEmailLoginModalOpen} onClose={onClose}>
        <EmailLoginModal />
      </Modal>
      {account && wallet ? (
        <>
          <ConnectedWrapper>
            <NavAvatar openWallet={wallet?.openWalletModal} walletReset={wallet?.walletReset} />
          </ConnectedWrapper>
        </>
      ) : (
        <StyledLoginWrapper>
          <StyledButton
            $isFeatureLandingPage={isFeatureLandingPage}
            onClick={wallet?.openWalletModal}
            type="primary"
            size="small"
          >
            <WalletOutlined />
            <StyledLabel>Connect Wallet</StyledLabel>
          </StyledButton>
        </StyledLoginWrapper>
      )}
    </>
  );
};

export default Wallet;
