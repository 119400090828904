import React, { useEffect } from "react";
import styled from "styled-components";
import Row from "antd/lib/row";
import Col from "antd/es/col";

import { Modal } from "../Modal";
import { Paragraph } from "../Typography";

interface Props {
  isModalOpen: boolean;
  msToRefresh?: number;
}

const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  text-align: center;
`;

const StyledParagraph = styled(Paragraph)`
  margin-top: 20px;
  margin-bottom: 20px;
`;

function AutoRefreshModal(props: Props) {
  const { isModalOpen, msToRefresh } = props;

  useEffect(() => {
    if (isModalOpen) {
      setTimeout(() => {
        window.location.reload();
      }, msToRefresh ?? 3000);
    }
  }, [isModalOpen, msToRefresh]);

  return (
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    <Modal open={isModalOpen} size="small" zIndex={1001} onClose={() => {}}>
      <Modal.Title>Refreshing Soon..</Modal.Title>
      <Modal.Body>
        <InputsContainer>
          <Row gutter={[8, 8]} justify="end">
            <Col span={24}>
              <StyledParagraph>
                We will be auto refreshing the page in {msToRefresh ? msToRefresh / 1000 : 3} secs for subscription
                changes to take effect.
              </StyledParagraph>
            </Col>
          </Row>
        </InputsContainer>
      </Modal.Body>
    </Modal>
  );
}

export default React.memo(AutoRefreshModal);
