import React, { useReducer, createContext } from "react";
import Web3Utils from "web3-utils";

interface Props {
  children: React.ReactNode;
}

type Action = { type: "SAVE_ACCOUNT"; data: string };

interface CurrentAccountContextType {
  account: string;
  dispatchAccount: React.Dispatch<any>;
}

const initialState: string = "";
export const CurrentAccountContext = createContext({} as CurrentAccountContextType);

const reducer = (state: string, action: Action) => {
  switch (action.type) {
    case "SAVE_ACCOUNT":
      return action.data === "" ? "" : Web3Utils.toChecksumAddress(action.data);
    default:
      return state;
  }
};

export const CurrentAccountProvider = (props: Props) => {
  const [account, dispatchAccount] = useReducer(reducer, initialState);

  return (
    <CurrentAccountContext.Provider value={{ account, dispatchAccount }}>
      {props.children}
    </CurrentAccountContext.Provider>
  );
};
