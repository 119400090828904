import Button from "antd/es/button/button";
import React from "react";
import { useCurrentWidth } from "react-socks";
import styled from "styled-components";

import { COLORS } from "../../constants/colors";
import media from "../../media-query";
import { FollowIcon, SettingsIcon } from "../icons";
import { VOTER_PROFILE_SCROLL_SHRINK } from "../../constants/general";

interface Props {
  scrolledHeight: number;
  isOwnProfile: boolean;
  onEditClick?: () => void;
  onFollowClick?: () => void;
}

const StyledButtonText = styled.p`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  margin: auto 0 auto 4px;
`;

const StyledButtonWrapper = styled(Button)<{ $isOwnProfile: boolean }>`
  background: ${({ $isOwnProfile }) => ($isOwnProfile ? "#fff" : COLORS.primary.accent)};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 40px;
  color: ${({ $isOwnProfile }) => ($isOwnProfile ? COLORS.primary.grayDarkLightest : "#fff")};
  border-color: ${({ $isOwnProfile }) => ($isOwnProfile ? "#fff" : COLORS.primary.accent)};
  height: 48px;
  width: 154px;
  display: flex;
  justify-content: center;
  ${media.lessThan("991px")`
    box-shadow: none;
    border: none;
    background: transparent;
    float: right;
    margin-right: 10px;
  `}
`;

const StyledFollowIcon = styled(FollowIcon)`
  width: 24px;
  height: 24px;
  margin: auto 0;
  ${media.lessThan("991px")`
    margin: 0px;
  `}
`;

const StyledSettingsIcon = styled(SettingsIcon)`
  width: 24px;
  height: 24px;
  margin: auto 0;
`;

const ProfileFollowButton = ({ scrolledHeight, isOwnProfile, onEditClick, onFollowClick }: Props) => {
  const isMobile = useCurrentWidth() < 991;
  const imageMarginTop = isMobile ? 25 - (scrolledHeight / VOTER_PROFILE_SCROLL_SHRINK) * 15 : 0;
  return (
    <>
      {isOwnProfile ? (
        <StyledButtonWrapper style={{ marginTop: imageMarginTop }} $isOwnProfile={true} onClick={onEditClick}>
          <StyledSettingsIcon color={COLORS.primary.accent} />
          {!isMobile && <StyledButtonText>&nbsp;Edit Profile</StyledButtonText>}
        </StyledButtonWrapper>
      ) : (
        <StyledButtonWrapper style={{ marginTop: imageMarginTop }} $isOwnProfile={false} onClick={onFollowClick}>
          <StyledFollowIcon color="inherit" />
          {!isMobile && <StyledButtonText>&nbsp;Follow</StyledButtonText>}
        </StyledButtonWrapper>
      )}
    </>
  );
};
export default ProfileFollowButton;
