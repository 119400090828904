import { useState, useLayoutEffect } from "react";

export const useNavScroll = () => {
  const [scrollFromTop, setScrollFromTop] = useState(0);

  useLayoutEffect(() => {
    const handleScroll = (e: any) => {
      if (e.srcElement.classList?.contains("DashboardContainer")) {
        const containerScrollFromTop = e.srcElement.scrollTop;
        if (containerScrollFromTop < 74) {
          setScrollFromTop(containerScrollFromTop);
        } else if (scrollFromTop !== 74) {
          setScrollFromTop(74);
        }
      }
    };

    window.addEventListener("scroll", handleScroll, true);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollFromTop]);

  return { scrollFromTop, setScrollFromTop };
};
