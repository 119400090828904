import * as React from "react";

const SvgOutlinedDevelopersIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M9.778 17.555h-.74a2.222 2.222 0 01-2.223-2.222v-2.099c0-.617-1.482-1.234-1.482-1.234s1.482-.617 1.482-1.235V8.667c0-1.228.995-2.223 2.222-2.223h.74M14.222 17.555h.74a2.222 2.222 0 002.223-2.222v-2.099c0-.617 1.482-1.234 1.482-1.234s-1.482-.617-1.482-1.235V8.667a2.222 2.222 0 00-2.222-2.223h-.74"
      stroke="currentColor"
      strokeWidth={1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22 2.667v18.666a.667.667 0 01-.667.667H2.667A.667.667 0 012 21.333V2.667C2 2.298 2.298 2 2.667 2h18.666c.369 0 .667.298.667.667z"
      stroke="currentColor"
      strokeWidth={1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgOutlinedDevelopersIcon;
