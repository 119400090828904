import React, { useReducer, createContext } from "react";

interface Props {
  children: React.ReactNode;
}

type Action = { type: "NFT_SUBSCRIPTION_IS_VALID"; data?: boolean };

interface NftSubscriptionContextType {
  hasValidKey?: boolean;
  dispatchNftSubscriptionIsValid: React.Dispatch<Action>;
}

export const NftSubscriptionContext = createContext({} as NftSubscriptionContextType);

const reducer = (state?: boolean, action?: Action) => {
  switch (action?.type) {
    case "NFT_SUBSCRIPTION_IS_VALID":
      return action.data;
    default:
      return state;
  }
};

export const NftSubscriptionProvider = (props: Props) => {
  const [hasValidKey, dispatchNftSubscriptionIsValid] = useReducer(reducer, undefined);

  return (
    <NftSubscriptionContext.Provider value={{ hasValidKey, dispatchNftSubscriptionIsValid }}>
      {props.children}
    </NftSubscriptionContext.Provider>
  );
};
