import CheckCircleFilled from "@ant-design/icons/lib/icons/CheckCircleFilled";
import CloseCircleFilled from "@ant-design/icons/lib/icons/CloseCircleFilled";
import React from "react";
import styled from "styled-components";
import AntdInput from "antd/es/input";

import { COLORS } from "../../constants/colors";
import { Text } from "../Typography";

interface InputProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "size" | "prefix"> {
  inputState?: "success" | "error";
  label?: string;
  size?: "large";
  placeholder?: string;
  suffix?: React.ReactNode;
  prefix?: React.ReactNode;
  $removeBorder?: boolean;
}

const getBorder = (props: InputProps) => {
  if (props.$removeBorder) {
    return "0";
  }
  if (props.inputState === "success") {
    return `1px solid ${COLORS.secondary.green}`;
  } else if (props.inputState === "error") {
    return `1px solid ${COLORS.secondary.red}`;
  }
  return `1px solid ${COLORS.primary.grayLight}`;
};

const getHoverBorder = (props: InputProps) => {
  if (props.inputState === "success") {
    return COLORS.secondary.green;
  } else if (props.inputState === "error") {
    return COLORS.secondary.red;
  }
  return COLORS.primary.accent;
};

const StyledInput = styled(AntdInput)`
  border: ${getBorder};
  box-shadow: 0px 2px 6px rgba(7, 3, 40, 0.05);
  background: transparent;
  height: 44px;
  border-radius: 6px;
  &:placeholder-shown {
    font-weight: 300;
    color: ${COLORS.primary.grayDarkLightest};
  }
  &:focus,
  &:hover {
    border-color: ${getHoverBorder} !important;
    box-shadow: 0px 4px 7px rgba(37, 26, 171, 0.1);
  }
  .ant-input {
    background: transparent;
  }
`;

const getSuffix = (inputState: string | undefined) => {
  if (inputState === "success") {
    return (
      <CheckCircleFilled
        style={{
          fontSize: "20px",
          color: COLORS.secondary.green,
        }}
      />
    );
  } else if (inputState === "error") {
    return (
      <CloseCircleFilled
        style={{
          fontSize: "20px",
          color: COLORS.secondary.red,
        }}
      />
    );
  }
  return null;
};

function Input(props: InputProps) {
  const suffix = getSuffix(props.inputState);
  return (
    <>
      {props.label ? <Text>{props.label}</Text> : null}
      <StyledInput
        suffix={suffix}
        placeholder={props.placeholder}
        {...props}
        size="large"
        onChange={props.onChange}
        prefix={props.prefix}
      />
    </>
  );
}

export default Input;
export type { InputProps };
