import AntdCard from "antd/es/card";
import { CardProps } from "antd/es/card";
import React from "react";
import styled from "styled-components";
import { COLORS } from "../constants/colors";
import { Header } from "./Typography";
import media from "../media-query";

export interface InfoCardProps extends CardProps {
  $noPaddingBody?: boolean;
  ref?: React.Ref<HTMLDivElement>;
  height?: string;
}

const InfoCard = ({ $noPaddingBody, ...props }: InfoCardProps) => {
  return (
    <AntdCard
      {...props}
      bordered={true}
      style={{
        borderWidth: 1,
        maxHeight: "100%",
        overflow: props.style?.overflow || "auto",
        width: "100%",
      }}
      title={props.title && <Header level={5}>{props.title}</Header>}
    />
  );
};

export default styled(InfoCard)<InfoCardProps>`
  padding: 0px;
  padding-bottom: ${({ $noPaddingBody }) => ($noPaddingBody ? "0px" : "20px")};
  border-radius: 8px;

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;

  .ant-pagination {
    margin: 24px;
  }

  .ant-card-head-wrapper {
    ${media.lessThan("991px")`
      flex-direction: row;
      font-size: 12px;
    `}
  }

  .ant-card-extra {
    padding: 0px;

    ${media.lessThan("991px")`
      margin: auto 0;
      padding-bottom: 0px;
      float: none;
    `}

    .ant-menu {
      border: 0px;
    }
  }

  .ant-menu-submenu-title {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .ant-card-head-title h5 {
    color: ${COLORS.primary.grayDarkLightest};
    text-transform: uppercase;
    ${media.lessThan("991px")`
      font-size: 12px;
    `}
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 0px;
  }
  .ant-card-head-title {
    padding: 16px 24px 16px 0px;
    ${media.lessThan("991px")`
      padding-right: 0;
      font-size: 12px;
    `}
  }
  .ant-card-body {
    padding: ${({ $noPaddingBody }) => ($noPaddingBody ? "0px" : "10px 24px")};
    min-height: 80px;
  }
  .ant-card-head {
    padding: 0 24px;
    margin-bottom: 0px;
  }
`;
