import { useInfiniteQuery } from "@tanstack/react-query";
import { getAddress } from "@ethersproject/address";
import { useMemo } from "react";

import { protocols } from "../constants/protocols";
import { apiClient } from "../utils/apiClient";
import { isAddress } from "web3-utils";

interface Props {
  address: string;
  protocol?: string;
  limit?: number;
  suspense?: boolean;
}

interface MultipleAddressProps {
  addresses: Array<string>;
  protocol?: string;
  limit?: number;
}

export const useVoterVotes = ({ address, protocol, limit, suspense = true }: Props) => {
  const { data, fetchNextPage, hasNextPage } = useInfiniteQuery(
    [`votes:${address?.toLowerCase()}:${protocol || "all"}`],
    ({ pageParam: cursor }) => {
      if (!address) {
        return null;
      }
      const normalizedAddress = isAddress(address) ? getAddress(address) : address;
      return apiClient.getVoterVotes(normalizedAddress, {
        limit,
        ...(protocol ? { cname: protocol } : {}),
        ...(cursor ? { cursor } : {}),
      });
    },
    {
      getNextPageParam: (params) => params?.nextCursor,
      suspense,
    },
  );

  const votes = useMemo(() => {
    return data?.pages
      .flat()
      .map((d) => d?.data)
      .flat()
      .filter((d) => d?.protocol && protocols[d?.protocol] && protocols[d?.protocol].isEnabled);
  }, [data]);

  return { votes, fetchNextPage, hasNextPage };
};

export const useVoterVotesForMultipleAddresses = ({ addresses, protocol, limit }: MultipleAddressProps) => {
  const { data, fetchNextPage, hasNextPage } = useInfiniteQuery(
    [`votes:${addresses.toLocaleString()}:${protocol ? protocol : "all"}`],
    ({ pageParam: cursor }) =>
      apiClient.getVotesByAddresses(addresses, {
        limit,
        ...(protocol ? { cname: protocol } : {}),
        ...(cursor ? { cursor } : {}),
      }),
    {
      getNextPageParam: (params) => params?.nextCursor,
      enabled: addresses?.filter(Boolean).length > 0,
    },
  );
  const votes = useMemo(() => {
    return data?.pages
      .flat()
      .map((d) => d?.data)
      .flat()
      .filter((d) => d?.protocol && protocols[d?.protocol] && protocols[d?.protocol].isEnabled);
  }, [data]);

  return { votes, fetchNextPage, hasNextPage };
};
