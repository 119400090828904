import { ProposalDetails } from "@boardroom/boardroom-api";
import moment from "moment";
import React, { useCallback } from "react";
import styled from "styled-components";
import { useHistory, useLocation } from "react-router-dom";
import { useCurrentWidth } from "react-socks";

import { getOnchainProposalVoteAdapterName, protocols } from "../../constants/protocols";
import { useProtocolIcon } from "../../hooks/useProtocolIcon";
import { formatEns, toShortAddress } from "../../utils";
import media from "../../media-query";
import StatusTag from "../StatusTag";
import { COLORS } from "../../constants/colors";
import Avatar from "../Avatar/Avatar";
import Tooltip from "antd/es/tooltip";
import { getProposalTimeAgoText } from "../../utils/getProposalTimeAgoText";
import { SnapshotIcon, OnchainIcon } from "../icons";
import { useAdapterFramework } from "../../hooks/useAdapterFramework";
import { useGetEns } from "../../hooks/useEns";

const StackedHorizontal = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-width: 100%;
  height: 100%;
`;

const Stacked = styled.div`
  display: flex;
  flex-direction: column;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: wrap;
  max-width: 35rem;
`;

const StackedRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StatusContainer = styled.div`
  display: flex;
  ${media.lessThan("large")`
    display: none;
  `}
`;

const MobileStatusContainer = styled.div`
  display: none;
  ${media.lessThan("large")`
    display: flex;
    margin: 0 0 auto 0;
  `}
`;

const TimelineListItem = styled.div`
  width: 100%;
  padding: 20px 8px;
  cursor: pointer;
  margin: 0 auto;
  transition: all 400ms ease-in-out;
  position: relative;
  height: 124px;
  display: inline-block;
  &:hover {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    border-radius: 20px;
  }

  &:after {
    position: absolute;
    content: "";
    height: 1px;
    width: 95%;
    border-bottom: 1px solid ${COLORS.primary.grayLighter};
    margin: 0 auto;
    bottom: 0;
    right: 0;
    left: 0;
  }

  ${media.lessThan("large")`
    padding: 16px 0;
  `}

  ${media.lessThan("640px")`
    height: 110px;
  `}
`;

const TopText = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #7b7893;
  padding: 3px 0 0 0;
  margin: auto 0;
  display: flex;

  > :nth-child(1) {
    padding-right: 5px;
  }

  > :nth-child(3) {
    padding-left: 5px;
  }

  ${media.lessThan("640px")`
    font-size: 10px;
  `}
`;

const FlexItem = styled.div<any>`
  flex: ${(props) => props.flex};
  margin: 0 5px;

  ${media.lessThan("large")`
    width: 190px;
  `}
`;

const AvatarWrapper = styled(FlexItem)`
  margin-right: 20px;
  position: relative;
  max-width: fit-content;
`;

const FrameworkWrapper = styled.div`
  position: absolute;
  display: block;
  right: -4px;
  bottom: -6px;
`;

const StyledOnchainIcon = styled(OnchainIcon)`
  width: 22px;
  height: 22px;
  color: #fff;
  border: 2px solid #fff;
  background: ${COLORS.secondary.blue};
  border-radius: 50%;
  padding: 2px;
  ${media.lessThan("991px")`
    width: 20px;
    height: 20px;
  `}
  ${media.lessThan("640px")`
    width: 16px;
    height: 16px;
  `}
`;

const StyledSnapshotIcon = styled(SnapshotIcon)`
  width: 20px;
  height: 20px;
  ${media.lessThan("991px")`
    width: 20px;
    height: 20px;
  `}
  ${media.lessThan("640px")`
    width: 16px;
    height: 16px;
  `}
`;

const ProposalTitle = styled.p`
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #191540;
  padding: 4px 0;
  margin-bottom: 2px !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  display: -webkit-box;
  width: 90%;
  text-overflow: ellipsis;
  white-space: wrap;
  ${media.lessThan("large")`
    font-size: 16px;
    padding: 4px 6px 4px 0;
  `}
`;

const StyledAuthor = styled.div`
  color: ${COLORS.primary.accent};
  display: inline;
`;

const StyledAvatar = styled(Avatar)`
  width: 48px;
  height: 48px;
  ${media.lessThan("991px")`
    width: 36px;
    height: 36px;
  `}
`;

const StyledSpan = styled.span`
  display: flex;
  ${media.lessThan("640px")`
    display: none;
  `}
`;

const StyledTimeAgo = styled.span<{ $isCanceled?: boolean }>`
  ${({ $isCanceled }) => $isCanceled && "text-decoration: line-through;"}
`;

interface Props {
  proposal: ProposalDetails;
  renderStartDate?: boolean;
}

const ProposalTimeLineItem = ({ proposal, renderStartDate = false }: Props) => {
  const { search } = useLocation();
  const endsIn = moment(proposal.endTimestamp * 1000).fromNow();
  const startsIn = moment(proposal.startTimestamp * 1000).fromNow();
  const protocol = protocols[proposal?.protocol];
  const { url } = useProtocolIcon({ protocolCname: protocol.cname });
  const history = useHistory();
  const startDate = moment(proposal.startTimestamp * 1000).format("D MMM YYYY");
  const isMobile = useCurrentWidth() < 991;
  const { adapterFramework } = useAdapterFramework(protocol?.cname);
  const ens = useGetEns(proposal?.proposer);

  const toAuthor = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      history.push(`/voter/${proposal.proposer}`);
    },
    [history, proposal.proposer],
  );

  const toProposal = useCallback(() => {
    history.push({ pathname: `/${protocol.path}/proposal/${proposal.refId}`, search });
  }, [history, protocol.path, proposal.refId, search]);

  const isOffChain = proposal.adapter === "snapshot" ||
    (proposal.adapter === "default" && adapterFramework === "snapshot") ||
    (proposal.adapter === "archive" && ["babydogearmy", "ybaby"].includes(protocol.cname));

  return (
    <>
      <TimelineListItem onClick={toProposal} key={proposal.refId}>
        <StackedHorizontal>
          <AvatarWrapper flex={1}>
            <Tooltip title={protocol.name}>
              <StyledAvatar backgroundColor="transparent" src={url as unknown as string} />
            </Tooltip>
            <FrameworkWrapper>{isOffChain ? <StyledSnapshotIcon /> : <StyledOnchainIcon />}</FrameworkWrapper>
          </AvatarWrapper>
          <FlexItem flex={10}>
            <StackedRow>
              <TopText>
                {renderStartDate ? (
                  <span>Created On {startDate}</span>
                ) : (
                  <span>
                    By &nbsp;
                    <StyledAuthor onClick={toAuthor}>
                      {isMobile
                        ? formatEns(ens) || toShortAddress(proposal.proposer, true)
                        : formatEns(ens) || toShortAddress(proposal.proposer)}
                    </StyledAuthor>
                  </span>
                )}
                <i>&#183;</i>
                <StyledTimeAgo $isCanceled={proposal.currentState === "canceled"}>
                  {getProposalTimeAgoText(proposal.currentState, startsIn, endsIn, proposal.endTimestamp)}&nbsp;
                </StyledTimeAgo>

                {isOffChain ? (
                  <StyledSpan>
                    <i>&#183;&nbsp;</i>
                    <SnapshotIcon width={16} height={16} />
                    &nbsp;<p style={{ margin: "auto 0" }}>Offchain</p>
                  </StyledSpan>
                ) : (
                  <StyledSpan>
                    <i>&#183;&nbsp;</i>
                    <OnchainIcon width={16} height={16} color={COLORS.secondary.blue} />
                    &nbsp;
                    <p style={{ margin: "auto 0" }}>
                      {getOnchainProposalVoteAdapterName(proposal.protocol, proposal.adapter)}
                    </p>
                  </StyledSpan>
                )}
              </TopText>
              <StatusContainer>
                <StatusTag status={proposal.currentState} />
              </StatusContainer>
            </StackedRow>
            <Stacked>
              <ProposalTitle>{proposal.title}</ProposalTitle>
            </Stacked>
          </FlexItem>
          <MobileStatusContainer>
            <StatusTag status={proposal.currentState} size="small" />
          </MobileStatusContainer>
        </StackedHorizontal>
      </TimelineListItem>
    </>
  );
};

export default ProposalTimeLineItem;
