import { GetDelegatePostsResponse } from "@boardroom/boardroom-api";
import { useQuery } from "@tanstack/react-query";

import { apiClient } from "../utils/apiClient";

interface Props {
  protocol: string;
  author: string;
  byPassCache?: boolean;
  suspense?: boolean;
}

export const useDelegatePosts = ({ protocol, author, byPassCache = false, suspense = true }: Props) => {
  const timestamp = byPassCache ? Date.now() : 0;
  const { data, refetch } = useQuery<GetDelegatePostsResponse | undefined, Error>(
    [`delegatePosts:${protocol?.toLowerCase()}:${author?.toLowerCase()}`],
    async () => {
      const returnedData = await apiClient.getDelegatePosts(
        {
          author,
          protocol,
        },
        timestamp,
      );
      return returnedData;
    },
    {
      enabled: !!protocol && !!author,
      suspense,
      useErrorBoundary: false,
    },
  );

  return { delegatePosts: data?.data, refetch };
};
