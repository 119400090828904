import React from "react";
import styled from "styled-components";
import media from "../../media-query";

const MainContainer = styled.div<{ $isDashboard?: boolean }>`
  margin-top: ${({ $isDashboard }) => ($isDashboard ? "0" : "-58px")};
  width: 100%;
  height: inherit;
  position: relative;

  > div {
    margin-top: ${({ $isDashboard }) => ($isDashboard ? "0" : "24px")};
  }

  ${media.lessThan("1260px")`
    padding: 0;
  `}

  ${media.lessThan("991px")`
    margin-top: 1px;
    padding: 0 0;
  `}
`;

export default React.memo(MainContainer);
