import React, { useCallback, useContext, useState } from "react";
import { ProposalComment as ProposalCommentInterface } from "@boardroom/boardroom-api";
import CopyToClipboard from "react-copy-to-clipboard";
import Comment from "antd/es/comment";
import Popover from "antd/es/popover";
import parse from "html-react-parser";
import styled from "styled-components";
import moment from "moment";
import { useCurrentWidth } from "react-socks";
import { TwitterShareButton } from "react-share";

import { useRenderMd } from "../../../hooks";
import { useGetEns } from "../../../hooks/useEns";
import { useUserDetails } from "../../../hooks/useUserDetails";
import { CurrentAccountContext } from "../../../reducers/CurrentAccount";
import ProfileAvatar from "../../VoterProfile/ProfileAvatar";
import { Link } from "../../Typography";
import { CopyIcon, EditIcon, ThreeDotMenuIcon, TrashIcon, TwitterIcon } from "../../icons";
import { formatEns, toShortAddress } from "../../../utils";
import { COLORS } from "../../../constants/colors";
import { useLocation } from "react-router-dom";

export const StyledTrashIcon = styled(TrashIcon)`
  cursor: pointer;
  margin-right: 8px;
`;

export const StyledEditIcon = styled(EditIcon)`
  cursor: pointer;
  margin-right: 4px;
`;

export const StyledTwitterIcon = styled(TwitterIcon)`
  cursor: pointer;
  margin-right: 8px;
`;

export const StyledCopyIcon = styled(CopyIcon)`
  width: 16px;
  height: 16px;
  vertical-align: text-bottom;
  cursor: pointer;
  margin-right: 8px;
`;

export const StyledComment = styled(Comment)<{ $removeBorderBottom?: boolean }>`
  border-bottom: ${({ $removeBorderBottom }) => ($removeBorderBottom ? "0px" : "1px solid #f0eff8")};
  padding: 16px 16px;
  .ant-comment-actions {
    display: flex;
    justify-content: end;
  }
  .ant-comment-inner {
    padding: 8px 0;
  }
  .ant-comment-nested {
    margin-left: 0;
    padding-left: 12px;
    border-left: 1px solid #f0eff8;
    border-bottom: 0;
  }
  .ant-comment-actions > li {
    display: flex;
    align-items: center;
    > span {
      color: #4235e1;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
    }
  }
  .markdown-body p {
    color: #7b7893;
  }
  .ant-comment-content-author {
    flex-direction: column;
    > span {
      font-size: 12px;
      line-height: 16px;
      color: #7b7893;
    }
  }
  .ant-comment-content-author-name > * {
    color: #7b7893;
    margin-top: 4px;
  }
  .ant-comment-avatar {
    margin-right: 8px;
  }
`;

export const AuthorWrapper = styled.div`
  display: flex;
`;

export const PostedByText = styled.span`
  margin-right: 4px;
`;

export const StyledThreeDotMenuIcon = styled(ThreeDotMenuIcon)`
  transform: rotate(90deg);
  color: #7b7893;
  margin-left: auto;
`;

export const StyledPopover = styled(Popover)`
  cursor: pointer;
  &:hover {
    color: ${COLORS.secondary.purple};
  }
`;

export const PopoverContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const PopoverAction = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  color: ${COLORS.primary.accent};
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  &:hover {
    background: ${COLORS.primary.grayLighter};
    color: ${COLORS.primary.grayDarkLighter};
  }
`;

export const StyledTwitterShareButton = styled(TwitterShareButton)`
  display: flex;
  align-items: center;
`;

export const OpTag = styled.span`
  font-weight: 500;
  font-size: 8px;
  line-height: 16px;
  color: #ee3fd9;
  margin-left: 4px;
  margin-top: -2px;
`;

export const StyledCommentWrapper = styled.div<{ $isAuthor?: boolean }>`
  background: inherit;
  width: ${({ $isAuthor }) => ($isAuthor ? "calc(100% + 32px)" : "inherit")};
  margin-left: ${({ $isAuthor }) => ($isAuthor ? "-16px" : "0")};
`;

const StyledLink = styled(Link)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 12rem;
`;

const StyledCopiedText = styled.div`
  font-weight: normal;
  font-size: 12px;
  line-height: 24px;
  color: ${COLORS.primary.gray};
`;

const StyledCopiedIcon = styled(CopyIcon)`
  width: 16px;
  height: 16px;
  vertical-align: text-bottom;
  margin-right: 8px;
  color: ${COLORS.primary.gray};
`;

const ProposalComment = ({
  comment,
  removeComment,
  edit,
  proposer,
  removeBorderBottom,
}: {
  comment: ProposalCommentInterface;
  removeComment: any;
  edit: any;
  proposer?: string;
  removeBorderBottom?: boolean;
}) => {
  const { account } = useContext(CurrentAccountContext);
  const { user: userDetails } = useUserDetails({ address: comment.author, suspense: false });
  const summary = useRenderMd(comment.commentBody);
  const ens = useGetEns(comment.author);
  const isMobile = useCurrentWidth() < 991;
  const isProposer = account?.toLowerCase() === proposer?.toLowerCase();
  const isAuthor = account?.toLowerCase() === comment?.author?.toLowerCase();
  const shortAddress = String(toShortAddress(comment.author));
  const query = new URLSearchParams(useLocation().search);
  const teamId = query.get("bundle");
  const [copied, setCopied] = useState(false);

  const onCopy = useCallback(() => setCopied(true), [setCopied]);

  const handleVisibleChange = useCallback((visible: boolean) => {
    if (!visible) {
      setTimeout(() => {
        setCopied(false);
      }, 100);
    }
  }, []);

  return (
    <StyledCommentWrapper $isAuthor={isAuthor} id={`comment${comment.commentId}`}>
      <StyledComment
        $removeBorderBottom={removeBorderBottom}
        author={
          <AuthorWrapper>
            <PostedByText style={{ marginRight: "4px" }}>Posted by</PostedByText>
            <StyledLink to={`/voter/${comment.author}`}>
              {isAuthor ? "YOU" : userDetails?.username || formatEns(ens) || shortAddress}
            </StyledLink>
            {isProposer && <OpTag>OP</OpTag>}
            <StyledPopover
              trigger="hover"
              placement="topRight"
              overlayInnerStyle={{
                boxShadow: "0px 4px 7.68px rgba(7, 3, 40, 0.1)",
                borderRadius: "6px",
              }}
              onVisibleChange={handleVisibleChange}
              content={
                <PopoverContainer>
                  {!isMobile && (
                    <>
                      {account?.toLowerCase() === comment?.author?.toLowerCase() && (
                        <PopoverAction onClick={() => edit(comment.commentId)}>
                          <StyledEditIcon width={20} height={20} />
                          <span>Edit</span>
                        </PopoverAction>
                      )}
                      {teamId && !copied && (
                        <CopyToClipboard
                          text={`${window.location.hostname}/?bundle=${teamId}&proposalId=${comment.proposalId}`}
                          onCopy={onCopy}
                        >
                          <PopoverAction>
                            <StyledCopyIcon />
                            Copy Link
                          </PopoverAction>
                        </CopyToClipboard>
                      )}
                      {teamId && copied && (
                        <StyledCopiedText>
                          <StyledCopiedIcon />
                          Link Copied!
                        </StyledCopiedText>
                      )}
                      {account?.toLowerCase() === comment?.author?.toLowerCase() && (
                        <PopoverAction onClick={() => removeComment(comment.commentId)}>
                          <StyledTrashIcon key="delete-comment" width={16} height={16} color="red" />
                          <span>Delete</span>
                        </PopoverAction>
                      )}
                    </>
                  )}
                </PopoverContainer>
              }
            >
              <StyledThreeDotMenuIcon width={4} height={20} />
            </StyledPopover>
          </AuthorWrapper>
        }
        avatar={<ProfileAvatar address={comment.author} customImageDimensions={32} pfp={userDetails?.pfpUrl} />}
        content={<div className="markdown-body">{parse(summary)}</div>}
        datetime={
          <span key={comment.createdAt}>
            {moment(comment.createdAt)
              .calendar({
                sameDay: "[Today]",
                nextDay: "[Tomorrow]",
                nextWeek: "dddd",
                lastDay: "[Yesterday]",
                lastWeek: "[Last] dddd",
                sameElse: "DD/MM/YYYY",
              })
              .toString()}{" "}
            at&nbsp;{moment(comment.createdAt).format("HH:mm")}
          </span>
        }
      ></StyledComment>
    </StyledCommentWrapper>
  );
};

export default ProposalComment;
