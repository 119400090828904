import { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";

import { apiClient } from "../utils/apiClient";
import { useDelegatesByProtocol } from "./useDelegatesByProtocol";
import { useGetTotalDelegatedToken } from "./useGetTotalDelegatedTokens";
import { DelegatesTableData } from "../components/Delegates/types";
import { useCyberConnectTwitterVerifiedBatch } from "./useCyberConnectTwitterVerifiedBatch";
import { useVotePower } from "./useVotePower";
import { checkAdapterFrameworkToUse, useVotePowerAdapter } from "./useVotePowerAdapter";
import { useDelegatorCount } from "./useDelegatorCount";
import { useDelegatesByAddresses } from "./useDelegateVotingPowerByAddress";
import { useMultipleAddressesVoter } from "./useVoter";
import { useProposals } from "./useProposals";
import { protocols } from "../constants/protocols";
import { useGetProfileTagsByProtocol } from "./useProfileTags";
import { useSdkWithoutSigner } from "./useSdkWithoutSigner";

interface Props {
  protocol: string;
  framework: string;
  forcedAddresses?: string[];
}

export const useDelegatesTable = ({ protocol, framework = "allFrameworks", forcedAddresses }: Props) => {
  const { totalVotePower: votePowerOnChain } = useGetTotalDelegatedToken({
    protocol: protocol,
    adapter: "onchain",
  });
  const { totalVotePower: votePowerSnapshot } = useGetTotalDelegatedToken({
    protocol: protocol,
    adapter: "snapshot",
  });
  const { totalVotePower: votePowerOptimism } = useGetTotalDelegatedToken({
    protocol: protocol,
    adapter: "onchain-optimism",
  });
  const { totalVotePower: votePowerArbitrum } = useGetTotalDelegatedToken({
    protocol: protocol,
    adapter: "onchain-arbitrum",
  });
  const { profileTags } = useGetProfileTagsByProtocol({
    protocol: protocol,
  });
  const { delegates, hasNextPage, fetchNextPage } = useDelegatesByProtocol(
    framework === "allFrameworks"
      ? {
          protocol: protocol || "",
          limit: 24,
        }
      : {
          protocol: protocol || "",
          limit: 12,
          adapter: framework,
        },
  );
  const { delegates: forcedDelegates } = useDelegatesByAddresses({
    addresses: forcedAddresses || [],
    protocol,
  });
  const delegatesToUse = useMemo(() => {
    if (forcedAddresses) {
      const notIncludedDelegates = forcedAddresses.filter(
        (forcedAddress) => !forcedDelegates?.find((delegate) => delegate.address === forcedAddress),
      );
      return [
        ...(forcedDelegates || []),
        ...notIncludedDelegates.map((notIncludedDelegate) => ({
          address: notIncludedDelegate,
          votePower: 0,
          adapter: forcedDelegates?.[0]?.adapter,
          protocol,
        })),
      ];
    }
    return delegates;
  }, [forcedAddresses, delegates, forcedDelegates, protocol]);
  const delegateAddresses = useMemo(() => delegatesToUse?.map((delegate) => delegate.address) || [], [delegatesToUse]);
  const { parsedAddressesLength, parsedAddresses: twitterData } = useCyberConnectTwitterVerifiedBatch({
    addresses: delegateAddresses,
  });

  const { data: tableData } = useQuery(
    [
      `delegatesTable:${protocol}:${framework}:${
        delegatesToUse?.length
      }:${parsedAddressesLength}:${forcedAddresses?.toString()}`,
    ],
    async () => {
      const promises = delegatesToUse?.map(async (delegate) => {
        let votingWeight, delegatorCount;
        let proposalsMadeForProtocol = [];
        try {
          const totalVotePower =
            delegate.adapter === "onchain"
              ? votePowerOnChain
              : delegate.adapter === "onchain-optimism"
              ? votePowerOptimism
              : delegate.adapter === "onchain-arbitrum"
              ? votePowerArbitrum
              : votePowerSnapshot;
          const proposals = (
            await apiClient.getProposals({
              proposer: delegate.address,
            })
          )?.data;
          proposalsMadeForProtocol = proposals?.filter(
            (protocolInProposals) => protocolInProposals.protocol === protocol,
          );

          const totalPower = totalVotePower ? totalVotePower?.total : 0;
          votingWeight = (delegate.votePower / totalPower) * 100;
          const adapterToUse =
            delegate.adapter === "onchain"
              ? { adapters: JSON.stringify(["onchain", "onchain-secondary"]) as any as string[] }
              : { adapter: delegate.adapter };
          delegatorCount = (
            await apiClient.getDelegatorCount(delegate.address, {
              address: delegate.address,
              ...adapterToUse,
              protocol,
            })
          )?.data?.delegatorCount;

          // apiClient.getVoter throws if the user haven't cast any votes
          const voter = (await apiClient.getVoter(delegate.address))?.data;
          const totalVotesCast = voter?.protocols.find(
            (protocolInVoter) => protocolInVoter.protocol === protocol,
          )?.totalVotesCast;
          const protocolsData = voter?.protocols;

          return {
            address: delegate.address,
            votePower: delegate.votePower,
            votingWeight: votingWeight,
            proposalsMade: proposalsMadeForProtocol?.length || 0,
            votesCast: totalVotesCast || 0,
            protocolsData,
            delegatorCount,
            twitterVerified: twitterData?.[delegate.address],
            type: profileTags?.find((tag) => tag.address === delegate.address)?.tag,
          };
        } catch (error) {
          console.error(error);
          return {
            address: delegate.address,
            votePower: delegate.votePower,
            votingWeight: votingWeight,
            proposalsMade: proposalsMadeForProtocol?.length || 0,
            votesCast: 0,
            protocolsData: [
              {
                protocol: delegate.protocol,
              },
            ],
            delegatorCount,
            twitterVerified: twitterData?.[delegate.address],
            type: profileTags?.find((tag) => tag.address === delegate.address)?.tag,
          };
        }
      });
      const awaitedPromises = await Promise.allSettled(promises || []);
      const filteredTableData = awaitedPromises.map((settledResult) => {
        if (settledResult.status === "fulfilled") return settledResult.value;
      });
      return filteredTableData;
    },
    {
      keepPreviousData: true,
    },
  );

  const parsedTableData: DelegatesTableData[] = (tableData?.filter(Boolean) as DelegatesTableData[]) || [];

  return { tableData: parsedTableData, hasNextPage, fetchNextPage };
};

interface PropsActivity {
  protocol: string;
  address: string;
  suspense?: boolean;
}

export const useDelegateActivity = ({ protocol, address, suspense = true }: PropsActivity) => {
  const { power: votePower, isLoading } = useVotePower(protocol, false, address);
  const adapterFramework = useVotePowerAdapter(protocol);
  const { delegatorCount } = useDelegatorCount({ address, protocol });
  const { totalVotePower } = useGetTotalDelegatedToken({
    protocol: protocol,
    adapter: adapterFramework,
  });
  let votingWeight = 0;
  if (votePower !== "-") {
    votingWeight =
      (typeof votePower === "string" ? parseFloat(votePower) : votePower / (totalVotePower?.total || 0)) * 100;
  }

  const { data } = useQuery(
    [`delegateActivity:${protocol}:${address}:${votePower}:${delegatorCount?.delegatorCount}`],
    async () => {
      let proposalsMadeForProtocolLength = 0;
      try {
        const proposals = (
          await apiClient.getProposals({
            proposer: address,
            cnames: [protocol],
          })
        )?.data;
        proposalsMadeForProtocolLength = proposals?.length;
        // apiClient.getVoter throws if the user haven't cast any votes
        const voter = (await apiClient.getVoter(address))?.data;
        const protocolInVoter = voter?.protocols.find(
          (protocolInVoterList) => protocolInVoterList.protocol === protocol,
        );
        const totalVotesCast = protocolInVoter?.totalVotesCast;

        return {
          votePower: votePower,
          votingWeight: votingWeight,
          proposalsMade: proposalsMadeForProtocolLength,
          votesCast: totalVotesCast || 0,
          delegatorCount: delegatorCount?.delegatorCount || 0,
        };
      } catch (error) {
        console.error(error);
        return {
          votePower: votePower,
          votingWeight: votingWeight,
          proposalsMade: proposalsMadeForProtocolLength,
          votesCast: 0,
          delegatorCount: delegatorCount?.delegatorCount || 0,
        };
      }
    },
    {
      suspense,
    },
  );

  return { data, votePowerIsLoading: isLoading };
};

export const useDelegatesStatus = ({ protocol }: { protocol: string }) => {
  let adapterFrameworkToBeUsed;
  try {
    const sdk = useSdkWithoutSigner();
    const protocolInSdk = sdk?.getProtocol(protocol);
    const adapterFramework = protocolInSdk?.adapterInstances("votePower");
    adapterFrameworkToBeUsed = checkAdapterFrameworkToUse(adapterFramework);
  } catch (error) {
    console.error(error);
  }
  const { delegates } = useDelegatesByProtocol({
    protocol: protocol || "",
    limit: 1000,
    suspense: false,
    adapter: adapterFrameworkToBeUsed,
  });
  const { data } = useMultipleAddressesVoter({
    addresses: delegates?.map((delegate) => delegate.address) || [],
    suspense: false,
  });
  const { proposals } = useProposals({ protocol: protocols[protocol], limit: 3 });
  const voters = Object.values(data?.votesByAddress || {});
  const { data: statusData } = useQuery({
    queryKey: [`delegatesStatus:${protocol}:${voters?.length}:${proposals?.length}`],
    queryFn: () => {
      if (!voters || !proposals)
        return {
          actives: 0,
          inactives: 0,
          ghosts: 0,
          total: 0,
        };
      const thirdProposalStart = proposals[2].startTimestamp;
      const actives = voters.filter((voter) => {
        const protocolToUse = voter.protocols.find((protocolObj) => protocolObj.protocol === protocol);
        return (protocolToUse?.lastVoteCast || 0) >= thirdProposalStart;
      });
      const inactives = voters.filter((voter) => {
        const protocolToUse = voter.protocols.find((protocolObj) => protocolObj.protocol === protocol);
        return (protocolToUse?.lastVoteCast || 0) < thirdProposalStart && (protocolToUse?.totalVotesCast || 0) > 0;
      });
      const ghosts = voters.filter((voter) => {
        const protocolToUse = voter.protocols.find((protocolObj) => protocolObj.protocol === protocol);
        return (protocolToUse?.totalVotesCast || 0) === 0;
      });
      return {
        actives: actives?.length,
        inactives: inactives?.length,
        ghosts: ghosts?.length,
        total: delegates?.length,
      };
    },
  });

  return (
    statusData || {
      actives: 0,
      inactives: 0,
      ghosts: 0,
      total: 0,
    }
  );
};
