import React, { ReactNode } from "react";
import styled, { css } from "styled-components";
import { TwitterShareButton } from "react-share";

import { ThinShareIcon, TwitterIcon } from "../icons";
import { COLORS } from "../../constants/colors";

interface Props {
  url?: string;
  text?: ReactNode;
  title: string;
  type?: "mini" | "standard" | "default" | "light" | "minimal" | "voter";
  hashtags: Array<string>;
  clickCallBack?: Function;
  removeDefaultColor?: boolean;
}

const ButtonContent = styled.div`
  padding: 12px 20px;
  height: 38px;
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  background: #1da1f2;
  color: #fff;
  border-radius: 24px;
  border: none;
`;

const ButtonText = styled.span<{ $removeDefaultColor?: boolean }>`
  margin: auto 0 auto 5px;
  line-height: 14px;
  display: inline-block;
  ${({ $removeDefaultColor }) =>
    $removeDefaultColor ? "color: #4235e1" : `color: ${COLORS.primary.grayDarkLightest}`};
`;

const StandardButtonStyles = css`
  height: 25px;
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
`;

const StandardizedSharedButton = styled(TwitterShareButton)`
  ${StandardButtonStyles};
  background-color: #1da1f2 !important;
  color: #fff !important;
`;

const LightShareButton = styled(TwitterShareButton)`
  ${StandardButtonStyles};
  background-color: #fff !important;
  color: #1da1f2 !important;
  border: 1px solid #1da1f2 !important;
  svg {
    color: #1da1f2;
  }
  :hover {
    background-color: #1da1f2 !important;
    color: #fff !important;
    svg {
      color: #fff;
    }
  }
`;

const MinimalShareButton = styled(TwitterShareButton)`
  display: flex;
  color: ${COLORS.primary.grayDarkLightest} !important;
  span {
    vertical-align: text-top;
    display: inline-block;
    font-size: 12px;
  }
  svg {
    vertical-align: text-bottom;
    margin: auto 0;
  }
  :hover {
    color: ${COLORS.primary.accent} !important;
  }
`;

const VoterShareButton = styled(TwitterShareButton)`
  span {
    vertical-align: text-top;
    display: inline-block;
    font-size: 12px;
    color: ${COLORS.primary.accent};
  }
  svg {
    vertical-align: text-bottom;
  }
`;

const StackedHorizontal = styled.div`
  display: flex;
  justify-content: space-between;
`;

function TwitterShare(props: Props) {
  const { url, text = undefined, title, type = "default", hashtags, clickCallBack, removeDefaultColor } = props;

  if (type === "mini") {
    return (
      <TwitterShareButton
        url={url || window.location.href}
        title={title}
        hashtags={hashtags}
        via={"boardroom_info"}
        onClick={() => clickCallBack && clickCallBack()}
      >
        <StackedHorizontal>
          <TwitterIcon width={16} height={16} color="#1da1f2" />
          {text}
        </StackedHorizontal>
      </TwitterShareButton>
    );
  } else if (type === "standard") {
    return (
      <StandardizedSharedButton
        url={url || window.location.href}
        title={title}
        hashtags={hashtags}
        via={"boardroom_info"}
        onClick={() => clickCallBack && clickCallBack()}
      >
        <TwitterIcon width={16} height={16} color="#fff" />
        <ButtonText $removeDefaultColor={removeDefaultColor}>Tweet</ButtonText>
      </StandardizedSharedButton>
    );
  } else if (type === "light") {
    return (
      <LightShareButton
        url={url || window.location.href}
        title={title}
        hashtags={hashtags}
        via={"boardroom_info"}
        onClick={() => clickCallBack && clickCallBack()}
      >
        <TwitterIcon width={16} height={16} color="#fff" />
        <ButtonText $removeDefaultColor={removeDefaultColor}>Tweet</ButtonText>
      </LightShareButton>
    );
  } else if (type === "minimal") {
    return (
      <MinimalShareButton
        url={url || window.location.href}
        title={title}
        hashtags={hashtags}
        via={"boardroom_info"}
        onClick={() => clickCallBack && clickCallBack()}
      >
        <ThinShareIcon width={20} height={20} color={COLORS.primary.accent} />
        <ButtonText $removeDefaultColor={removeDefaultColor}>{text || "Share"}</ButtonText>
      </MinimalShareButton>
    );
  } else if (type === "voter") {
    return (
      <VoterShareButton
        url={url || window.location.href}
        title={title}
        hashtags={hashtags}
        onClick={() => clickCallBack && clickCallBack()}
      >
        <TwitterIcon width={16} height={16} color={COLORS.primary.accent} />
        <ButtonText $removeDefaultColor={removeDefaultColor}>{text}</ButtonText>
      </VoterShareButton>
    );
  }

  return (
    <TwitterShareButton
      url={url || window.location.href}
      title={title}
      hashtags={hashtags}
      onClick={() => clickCallBack && clickCallBack()}
    >
      <ButtonContent>
        <TwitterIcon width={16} height={16} />
        <ButtonText $removeDefaultColor={removeDefaultColor}>Share on Twitter</ButtonText>
      </ButtonContent>
    </TwitterShareButton>
  );
}

export default TwitterShare;
