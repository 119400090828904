import React from "react";
import { useSelect, UseSelectStateChange } from "downshift";
import styled from "styled-components";

import { Label } from "../../../components/Label";
import { DropdownItemList } from "./DropdownListItem";
import { DropdownButton } from "./DropdownButton";
import { Item, DropdownProps } from "./types";

export interface Props {
  prefix?: string;
  initialValue?: Item;
  items: Item[];
  onChange?: DropdownProps["onChange"];
  size?: string;
  justifyEnd?: boolean;
}

const Container = styled.div`
  position: relative;
`;

const getSingleSelectConfig = (items: Item[], initialValue?: Item, onChange?: DropdownProps["onChange"]) => ({
  items,
  initialSelectedItem: initialValue,
  onSelectedItemChange: ({ selectedItem }: UseSelectStateChange<Item>) => onChange && onChange(selectedItem || null),
});

export const Dropdown = ({
  label,
  items,
  initialValue,
  placeholder = "Select option",
  prefix = "",
  onChange,
  size,
  justifyEnd,
}: DropdownProps) => {
  const { isOpen, selectedItem, getToggleButtonProps, getLabelProps, getMenuProps, getItemProps } = useSelect(
    getSingleSelectConfig(items, initialValue, onChange),
  );

  return (
    <>
      {label && <Label {...getLabelProps()}>{label}</Label>}
      <Container>
        <DropdownButton
          selected={selectedItem}
          isOpen={isOpen}
          placeholder={placeholder}
          prefix={prefix}
          getButtonProps={getToggleButtonProps}
          size={size}
          justifyEnd={justifyEnd}
        />

        <DropdownItemList
          items={items}
          isOpen={isOpen}
          selected={selectedItem}
          getMenuProps={getMenuProps}
          getItemProps={getItemProps}
        />
      </Container>
    </>
  );
};

export const FilterDropdown = ({ prefix, initialValue, items, onChange, size, justifyEnd }: Props) => (
  <Dropdown
    label={null}
    placeholder=""
    initialValue={initialValue}
    items={items}
    prefix={prefix}
    onChange={onChange}
    size={size}
    justifyEnd={justifyEnd}
  />
);
