import Col from "antd/es/col";
import React, { useEffect, useState, useRef, useCallback, useContext, useMemo } from "react";
import Row from "antd/es/row";
import InfiniteScroll from "react-infinite-scroller";
import { useLocation } from "react-router";

import { MainContainer, BackgroundForMobile, Modal } from "../../components";
import { COLORS } from "../../constants/colors";
import { ArrowUpIcon, EyeIcon } from "../../components/icons";
import MetaTags from "../../components/common/MetaTags";
import {
  Container,
  PageMax,
  BackToTopSection,
  BackToTopText,
  StyledRowNoMargins,
  PaddingContainer,
  MobileBgWrapper,
} from "../Dashboard/styles";
import { useCurrentRefId } from "../../reducers/CurrentRefId";
import { useWindowDimensions } from "../../hooks/useWindowDimensions";
import { TrackPageView } from "../../components/TrackPageView";
import { SettingsNotificationsModal } from "../DashboardPages/Settings/SettingsNotifications";
import {
  FeedFooter,
  StickyDiv,
  StyledCol,
  StyledColSplitView,
  StyledTeamTxt,
  TeamFeedText,
  ViewOnlyWrapper,
} from "../DashboardPages/Calendar/Calendar";
import { AlertsCard, NotificationsCard } from "../../components/MoreResourcesCard/MoreResourcesCard";
import { CurrentAccountContext } from "../../reducers/CurrentAccount";
import { useGetTeam } from "../../hooks/useGetTeam";
import { useDiscourseTopics } from "../../hooks/useDiscourseTopics";
import SuspendedEventRow, { SplitViewContent } from "../../components/Discuss/DiscussEventRow";
import { CalendarEventTypes } from "../../types";
import {
  EventsWrapper,
  SeeMoreText,
  SplitViewDrawer,
  Today,
  TodayWrapper,
} from "../../components/Calendar/ListView/styles";
import { isTeamView } from "../../utils/teamUtils";
import { WalletDrawerContext } from "../../reducers/WalletDrawer";
import { useCurrentSplitViewIndex } from "../../reducers/CurrentSplitViewIndex";
import { DiscussFiltersContent } from "../../components/ProposalsFilters/ProposalsFilters";
import { CurrentProjectsFilterContext } from "../../reducers/CurrentProjectsFilter";
import { CurrentUserDetailsContext } from "../../reducers/CurrentUserDetails";
import { savedProtocolsToArray } from "../../utils/savedProtocolsToArray";
import { NoProposalsFoundFeed } from "../../components/NoProposalsFound/NoProposalsFound";
import { mainGridOffset, mainGridStrucutre } from "../../constants/styles";
import ExpandCollapseSomeRows from "../../components/common/ExpandCollapseSomeRows";
import { StyledDrawer } from "../Webhooks";
import { useGetKeywordMatchesByAddress } from "../../hooks/useGetAlerts";
import { AlertsModal } from "../../components/Alerts/Alerts";
import { useGetProtocolsToAdd } from "../../hooks/useGetProtocolsToAdd";
import { DismissedDiscussionsContext } from "../../reducers/DismissedDiscussions";
import { useHasValidNftSubscription } from "../../hooks/useHasValidNftSubscription";

function Discuss() {
  const { account } = useContext(CurrentAccountContext);
  const { dismissedDiscussions } = useContext(DismissedDiscussionsContext);
  const { search, pathname } = useLocation();
  const query = new URLSearchParams(search);
  const teamId = query.get("bundle");
  const teamDetails = useGetTeam(teamId || "");
  const isOnTeamView = isTeamView(pathname, search);
  const { isWalletDrawerOpen } = useContext(WalletDrawerContext);
  const { projectsFilter } = useContext(CurrentProjectsFilterContext);
  const { userDetails } = useContext(CurrentUserDetailsContext);
  const savedProtocolsArray = savedProtocolsToArray(userDetails?.savedProtocols);
  const teamSavedProtocolsArray = savedProtocolsToArray(teamDetails?.savedProtocols);
  const [rowsBeingShown, setRowsBeingShown] = useState(10);

  const [isNotificationsModalOpen, setIsNotificationsModalOpen] = useState(false);
  const [isAlertsModalOpen, setIsAlertsModalOpen] = useState(false);
  const { width } = useWindowDimensions();
  const isMobile = width <= 991;
  const dashboardSection = useRef<HTMLDivElement>(null);
  const splitViewRef = useRef<HTMLDivElement>(null);
  const protocolsToAdd = useGetProtocolsToAdd();

  const { discourseTopics, hasNextPage, fetchNextPage } = useDiscourseTopics({
    limit: 200,
  });

  const { keywordMatches } = useGetKeywordMatchesByAddress({ address: account || "" });

  const discourseEventsFromMatches = useMemo(() => {
    const events = keywordMatches?.keywords
      ?.filter((match) => !dismissedDiscussions.has(match?.id))
      .map((match) => {
        const topic = keywordMatches?.topics?.find((topic) => topic?.refId === match?.contentRefId);
        return {
          protocolCname: match?.protocol,
          type: "discussion" as CalendarEventTypes,
          id: match?.id,
          proposalRefId: match?.contentRefId,
          title: topic?.title || "",
          date: Date.parse(topic?.bumpedAt || ""),
          url: topic?.slug || "",
          keyword: match?.keyword,
        };
      });
    return events || [];
  }, [dismissedDiscussions, keywordMatches?.keywords, keywordMatches?.topics]);

  const [showButton, setShowButton] = useState(false);

  const { currentRefId, setCurrentRefId } = useCurrentRefId();
  const { setCurrentSplitViewIndex } = useCurrentSplitViewIndex();
  const hasValidKey = useHasValidNftSubscription(account);

  const handleCloseModal = useCallback(() => {
    setIsNotificationsModalOpen(false);
  }, []);

  const handleOpenModal = useCallback(() => {
    setIsNotificationsModalOpen(true);
  }, []);

  const handleOpenAlertsModal = useCallback(() => {
    setIsAlertsModalOpen(true);
  }, []);

  const handleCloseAlertsModal = useCallback(() => {
    setIsAlertsModalOpen(false);
  }, []);

  useEffect(() => {
    const handleScroll = (e: any) => {
      if (e.srcElement.classList?.contains("DashboardContainer")) {
        const containerScrollFromTop = e.srcElement.scrollTop;
        if (containerScrollFromTop > 1500) {
          setShowButton(true);
        } else {
          setShowButton(false);
        }
      }
    };

    window.addEventListener("scroll", handleScroll, true);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [showButton]);

  const scrollToTop = () => {
    if (dashboardSection.current) {
      dashboardSection.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const discourseEvents = useMemo(() => {
    const events = discourseTopics
      ?.map((topic) => ({
        title: topic?.title,
        date: Date.parse(topic?.bumpedAt),
        protocolCname: topic?.protocol,
        type: "discussion" as CalendarEventTypes,
        id: topic?.id,
        proposalRefId: topic?.refId,
        url: topic?.slug,
      }))
      ?.filter((discussion) => {
        if (projectsFilter === "custom" && !teamId && savedProtocolsArray.length) {
          return savedProtocolsArray.includes(discussion.protocolCname);
        } else if (projectsFilter === "custom" && !savedProtocolsArray.length && !teamSavedProtocolsArray?.length) {
          return protocolsToAdd.includes(discussion.protocolCname);
        } else if (projectsFilter === "custom" && teamId && teamSavedProtocolsArray?.length) {
          return teamSavedProtocolsArray.includes(discussion.protocolCname);
        } else {
          return true;
        }
      });
    return events;
  }, [discourseTopics, projectsFilter, protocolsToAdd, savedProtocolsArray, teamId, teamSavedProtocolsArray]);

  const allTopics = useMemo(
    () => [...(keywordMatches?.topics || []), ...discourseTopics],
    [discourseTopics, keywordMatches?.topics],
  );

  const allEvents = useMemo(
    () => [...discourseEventsFromMatches, ...discourseEvents],
    [discourseEvents, discourseEventsFromMatches],
  );

  const currentTopic = useMemo(
    () => allTopics?.find((topic) => topic?.refId === currentRefId),
    [currentRefId, allTopics],
  );

  const handleClose = useCallback(() => {
    setCurrentRefId("");
    setCurrentSplitViewIndex(undefined);
  }, [setCurrentRefId, setCurrentSplitViewIndex]);

  const toggleSeeMorePast = useCallback(() => {
    if (rowsBeingShown >= discourseEventsFromMatches?.length) {
      setRowsBeingShown(10);
    } else {
      setRowsBeingShown((current) => current + 10);
    }
  }, [discourseEventsFromMatches?.length, rowsBeingShown]);

  useEffect(() => {
    let timeoutRef: string | number | NodeJS.Timeout | undefined;
    if (!hasValidKey && allTopics?.length) {
      timeoutRef = setTimeout(() => {
        setCurrentRefId(allTopics[0]?.refId);
      }, 500);
    }
    return () => {
      clearTimeout(timeoutRef);
    };
  }, [allTopics, hasValidKey, setCurrentRefId]);

  return (
    <>
      <TrackPageView name={"Discuss"} />
      <MetaTags
        title="Discuss | Boardroom"
        description="Boardroom is a web3 governance management portal helping users make faster, smarter, and more informed decisions"
        imageUrl="https://uploads-ssl.webflow.com/5fea803f5ceaedd1ffd21e65/602447d1a489bc75f4f2478a_Twitter%20Banner%203.png"
      />
      <MainContainer $isDashboard>
        <MobileBgWrapper>
          <BackgroundForMobile />
        </MobileBgWrapper>
        <Container
          className="DashboardContainer"
          ref={dashboardSection}
          id="scrollElement"
          isSettings={false}
          $isCalendar={location.pathname === "/discuss"}
          $isSplitView={!!currentRefId}
          $width={width}
        >
          <PaddingContainer>
            <PageMax>
              <StyledRowNoMargins justify={"start"} align="middle">
                <Col style={{ position: currentRefId ? "static" : "relative" }} span={24}>
                  <>
                    {teamId && account ? (
                      <StyledTeamTxt>
                        {teamDetails?.orgName} <TeamFeedText>Feed</TeamFeedText>{" "}
                        <ViewOnlyWrapper>
                          <EyeIcon width={20} height={20} /> View Only
                        </ViewOnlyWrapper>
                      </StyledTeamTxt>
                    ) : (
                      <></>
                    )}
                    <Row style={{ marginLeft: "0", marginRight: "0" }} justify="start" gutter={[48, 48]}>
                      <StyledColSplitView
                        xs={24}
                        lg={mainGridStrucutre(!!currentRefId)}
                        offset={mainGridOffset(!!currentRefId)}
                        ref={splitViewRef}
                        $width={width}
                        $isSplitView={!!currentRefId}
                      >
                        <StickyDiv $hasMintBanner={false} $isTeamView={!!teamId && !!account}>
                          <DiscussFiltersContent />
                        </StickyDiv>
                        <div style={{ marginTop: "20px" }} />
                        {!!discourseEventsFromMatches?.length && (
                          <>
                            <TodayWrapper>
                              <Today>YOUR SEARCHES</Today>
                            </TodayWrapper>
                            <EventsWrapper $isToday>
                              <ExpandCollapseSomeRows
                                cutoffIndex={10}
                                seeMoreComponent={
                                  <SeeMoreText $isPastEvents onClick={toggleSeeMorePast}>
                                    {rowsBeingShown >= discourseEventsFromMatches?.length
                                      ? "see less"
                                      : `SEE ${discourseEventsFromMatches.length - rowsBeingShown} MORE ALERTS` ||
                                        "see more"}
                                  </SeeMoreText>
                                }
                                totalRows={discourseEventsFromMatches?.length}
                              >
                                {discourseEventsFromMatches?.map((topic, index) => (
                                  <ExpandCollapseSomeRows.Row
                                    index={index}
                                    cutoffIndex={10}
                                    rowsBeingShown={rowsBeingShown}
                                    key={topic?.proposalRefId}
                                  >
                                    <SuspendedEventRow
                                      isDismissible
                                      discourseEvents={discourseEventsFromMatches}
                                      event={topic}
                                    />
                                  </ExpandCollapseSomeRows.Row>
                                ))}
                              </ExpandCollapseSomeRows>
                            </EventsWrapper>
                            {discourseEventsFromMatches?.length === 0 && <NoProposalsFoundFeed />}
                            <div style={{ marginTop: "20px" }} />
                          </>
                        )}
                        <TodayWrapper>
                          <Today>ALL DISCUSSIONS</Today>
                        </TodayWrapper>
                        <EventsWrapper $isToday>
                          <InfiniteScroll
                            loadMore={() => fetchNextPage()}
                            hasMore={hasNextPage}
                            initialLoad={false}
                            threshold={1000}
                            useWindow={false}
                            getScrollParent={() => splitViewRef?.current}
                          >
                            {discourseEvents?.map((topic) => (
                              <SuspendedEventRow
                                key={topic?.proposalRefId}
                                discourseEvents={discourseEvents}
                                event={topic}
                              />
                            ))}
                          </InfiniteScroll>
                        </EventsWrapper>
                        {currentTopic && (
                          <SplitViewDrawer
                            zIndex={currentRefId ? 16 : -1}
                            visible={!!currentRefId}
                            onClose={handleClose}
                            placement="right"
                            contentWrapperStyle={{ width: "100%" }}
                            destroyOnClose
                            $isTeamView={!!isOnTeamView && !!account}
                            $isWalletDrawerOpen={isWalletDrawerOpen}
                            $width={width}
                            bodyStyle={{ zIndex: 0 }}
                          >
                            <SplitViewContent discourseTopic={currentTopic} discourseEvents={allEvents} />
                          </SplitViewDrawer>
                        )}
                      </StyledColSplitView>
                      <StyledCol
                        $hasMintBanner={false}
                        $isTeamView={!!teamId && !!account}
                        $isSplitView={!!currentRefId}
                        xs={24}
                        lg={8}
                        style={{ paddingTop: !!teamId && !!account ? "136px" : "76px", zIndex: "8" }}
                      >
                        {!teamId ? (
                          <>
                            <AlertsCard protocolsToAdd={savedProtocolsArray} onClick={handleOpenAlertsModal} />
                            <NotificationsCard onClick={handleOpenModal} protocolsToAdd={protocolsToAdd} />
                          </>
                        ) : (
                          <></>
                        )}
                        <div style={{ marginTop: "32px" }} />
                        <FeedFooter />
                      </StyledCol>
                    </Row>
                    <Modal
                      customMinWidth={isMobile ? "0" : undefined}
                      customMaxHeight={isMobile ? "650px" : undefined}
                      zIndex={20}
                      removePaddingBottom
                      size="large"
                      open={isNotificationsModalOpen}
                      onClose={handleCloseModal}
                    >
                      <SettingsNotificationsModal />
                    </Modal>
                    <StyledDrawer
                      destroyOnClose
                      zIndex={15}
                      visible={isAlertsModalOpen}
                      onClose={handleCloseAlertsModal}
                    >
                      <AlertsModal closeDrawer={handleCloseAlertsModal} protocolsToAdd={savedProtocolsArray} />
                    </StyledDrawer>
                  </>
                  {showButton && (
                    <BackToTopSection onClick={scrollToTop}>
                      <ArrowUpIcon color={COLORS.primary.accent} width={48} height={48} />
                      <BackToTopText>Back to Top</BackToTopText>
                    </BackToTopSection>
                  )}
                </Col>
              </StyledRowNoMargins>
            </PageMax>
          </PaddingContainer>
        </Container>
      </MainContainer>
    </>
  );
}

export default React.memo(Discuss);
