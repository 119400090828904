import React from "react";
import styled, { css, keyframes } from "styled-components";
import { COLORS } from "../../constants/colors";

interface Props {
  size?: "xsmall" | "small" | "medium" | "large";
  verticalAlign?: boolean;
  pageAlign?: boolean;
}

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const dimensions = {
  xsmall: "20px",
  small: "40px",
  medium: "60px",
  large: "75px",
};

const relativeSize = {
  xsmall: "3px",
  small: "6px",
  medium: "8px",
  large: "10px",
};

const getDimensions = (props: Props) => dimensions[props.size || "small"];
const getRelativeSize = (props: Props) => relativeSize[props.size || "small"];

const StyledLoader = styled("div")`
  margin: auto;
  font-size: ${getRelativeSize};
  position: relative;
  border-top: ${getRelativeSize} solid ${COLORS.primary.accent};
  border-right: ${getRelativeSize} solid ${COLORS.primary.grayLighter};
  border-bottom: ${getRelativeSize} solid ${COLORS.primary.grayLighter};
  border-left: ${getRelativeSize} solid ${COLORS.primary.accent};
  transform: translateZ(0);
  animation: ${rotate} 1.1s infinite linear;
  &,
  &:after {
    border-radius: 50%;
    width: ${getDimensions};
    height: ${getDimensions};
  }
`;

const LoaderWrapper = styled("div")<{ pageAlign?: boolean; verticalAlign?: boolean }>`
  ${({ pageAlign }) =>
    pageAlign &&
    css`
      height: 100vh;
      display: flex;
      width: 100%;
    `}
  ${({ verticalAlign }) =>
    verticalAlign &&
    css`
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    `}
`;

export const Loader = ({ size = "small", verticalAlign = false, pageAlign = false }: Props) => (
  <LoaderWrapper verticalAlign={verticalAlign} pageAlign={pageAlign}>
    <StyledLoader size={size} />
  </LoaderWrapper>
);
