import * as React from "react";

const SvgCalendarWithBoxesIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 4.75a.25.25 0 00-.25.25v16c0 .138.112.25.25.25h18a.25.25 0 00.25-.25V5a.25.25 0 00-.25-.25H3zM1.25 5c0-.966.784-1.75 1.75-1.75h18c.966 0 1.75.784 1.75 1.75v16A1.75 1.75 0 0121 22.75H3A1.75 1.75 0 011.25 21V5z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.25 10A.75.75 0 012 9.25h20a.75.75 0 010 1.5H2a.75.75 0 01-.75-.75zM1.25 16a.75.75 0 01.75-.75h20a.75.75 0 010 1.5H2a.75.75 0 01-.75-.75zM8.5 1.25a.75.75 0 01.75.75v4a.75.75 0 01-1.5 0V2a.75.75 0 01.75-.75zM15.5 1.25a.75.75 0 01.75.75v4a.75.75 0 01-1.5 0V2a.75.75 0 01.75-.75z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.5 9.25a.75.75 0 01.75.75v12a.75.75 0 01-1.5 0V10a.75.75 0 01.75-.75zM15.5 9.25a.75.75 0 01.75.75v12a.75.75 0 01-1.5 0V10a.75.75 0 01.75-.75zM22 5.75a.75.75 0 01.75.75v13a.75.75 0 01-1.5 0v-13a.75.75 0 01.75-.75zM2 5.75a.75.75 0 01.75.75v13a.75.75 0 01-1.5 0v-13A.75.75 0 012 5.75z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.25 22a.75.75 0 01.75-.75h10a.75.75 0 010 1.5H7a.75.75 0 01-.75-.75z"
      fill="currentColor"
    />
  </svg>
);

export default SvgCalendarWithBoxesIcon;
