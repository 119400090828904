import * as React from "react";

const SvgKeyIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7.276 8.46a2.788 2.788 0 01.74 2.706 2.809 2.809 0 01-2 1.988 2.83 2.83 0 01-2.728-.739 2.788 2.788 0 01.036-3.92 2.83 2.83 0 013.952-.035zm.67-.743a3.83 3.83 0 00-5.327.068 3.788 3.788 0 00-.046 5.329l.003.004a3.83 3.83 0 003.696 1.003 3.809 3.809 0 002.71-2.697 3.788 3.788 0 00-1.036-3.707z"
      fill="currentColor"
    />
    <path
      d="M13.687 1.98a.5.5 0 010 .707L8.02 8.353a.5.5 0 01-.707-.707L12.98 1.98a.5.5 0 01.707 0z"
      fill="currentColor"
    />
    <path
      d="M11.86 3.179a.5.5 0 01.705 0l1.81 1.8a.5.5 0 010 .709l-2.111 2.1a.5.5 0 01-.706 0l-1.81-1.8a.5.5 0 010-.71l2.112-2.1zm.353 1.06L10.81 5.632l1.1 1.095 1.402-1.395-1.1-1.095z"
      fill="currentColor"
    />
  </svg>
);

export default SvgKeyIcon;
