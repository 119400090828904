import { useInfiniteQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { DiscourseTopicsData } from "@boardroom/boardroom-api";

import { apiClient } from "../utils/apiClient";

interface Props {
  protocols?: string[];
  limit?: number;
  suspense?: boolean;
}

export const useDiscourseTopics = ({ protocols, limit = 20, suspense = true }: Props) => {
  const { data, fetchNextPage, hasNextPage } = useInfiniteQuery(
    [`discourseTopics:${protocols?.join(",")}:${limit}`],
    async ({ pageParam: cursor }) => {
      try {
        const returnedData = await apiClient.getDiscourseTopics({
          protocol: protocols?.join(","),
          limit,
          ...(cursor ? { cursor } : {}),
        });
        return returnedData;
      } catch (error) {
        console.error(error);
      }
    },
    {
      getNextPageParam: (params) => params?.nextCursor,
      suspense,
    },
  );

  const discourseTopics: DiscourseTopicsData[] = useMemo(
    () =>
      (data?.pages
        .map((page) => page?.data)
        .flat()
        .filter(Boolean) as any) || [],
    [data],
  );

  return { discourseTopics, fetchNextPage, hasNextPage };
};
