import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { BoardroomFooterLogo, DiscordIcon, GithubIcon, TwitterIcon } from "../icons";
import { boardRoomOrgGithubLink, boardroomDiscordLink, boardroomTwitterLink } from "../../constants/general";
import mediaQuery from "../../media-query";
import { RoutePaths } from "../../constants/Routes";

const StyledFooterWrapper = styled.div<{ $isNavRailExpanded?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 36px 28px 28px ${({ $isNavRailExpanded }) => ($isNavRailExpanded ? "202px" : "98px")};
  border-top: 1px solid #f0eff8;
  height: 80px;
  a {
    font-weight: 400;
    font-size: 12px;
    line-height: 30px;
    color: #7b7893;
  }
  ${mediaQuery.lessThan("991px")`
    display: none;
  `}
`;

const FlexDiv = styled.div`
  display: flex;
  gap: 40px;
  svg {
    width: 20px;
    height: 20px;
  }
`;

export const LeanFooter = () => {
  return (
    <StyledFooterWrapper>
      <Link to={RoutePaths.feed}>
        <BoardroomFooterLogo width={85} height={12} color="#7B7893" />
      </Link>
      <FlexDiv>
        <a href="https://docs.boardroom.io/" target="_blank">
          Documentation
        </a>
        <a
          href="https://boardroom.notion.site/200b74eb26a24ac8a49f0c83ca6f53fd?v=f784892f708f422cac6336e5e74dd9ab"
          target="_blank"
        >
          Branding
        </a>
        <a href={boardroomDiscordLink} target="_blank">
          Contact Us
        </a>
        <a href={boardRoomOrgGithubLink} target="_blank">
          <GithubIcon />
        </a>
        <a href={boardroomTwitterLink} target="_blank">
          <TwitterIcon />
        </a>
        <a href={boardroomDiscordLink} target="_blank">
          <DiscordIcon />
        </a>
      </FlexDiv>
    </StyledFooterWrapper>
  );
};
