import { useQuery } from "@tanstack/react-query";
import { apiClient } from "../utils/apiClient";
import { CurrentUuidContext } from "../reducers/CurrentUuid";
import { useContext } from "react";
import { ApiKeyResponse } from "@boardroom/boardroom-api";

export const useGetApiKey = ({ address, suspense = true }: { address: string; suspense?: boolean }) => {
  const { uuid } = useContext(CurrentUuidContext);
  const { data, isLoading } = useQuery<ApiKeyResponse, Error>(
    [`apiKey:${address}:${uuid}`],
    async () => {
      try {
        const awaitedResponse = await apiClient.getApiKey(address, {
          address,
          uuid,
        });
        return awaitedResponse;
      } catch (e) {
        console.error(e);
        const response: ApiKeyResponse = {
          data: {
            key: "",
          },
        };
        return response;
      }
    },
    {
      enabled: !!uuid && !!address,
      suspense,
    },
  );
  return { apiKey: data?.data?.key, isLoading: isLoading && !!uuid && !!address };
};
