import React from "react";
import styled from "styled-components";
import AntdPopover, { PopoverProps } from "antd/es/popover";
import { COLORS } from "../../constants/colors";

interface Props {
  title?: React.ReactNode;
  content: React.ReactNode;
  children: React.ReactNode;
  placement?: PopoverProps["placement"];
  container?: HTMLElement | null;
  trigger?: "click" | "hover";
  visible?: boolean;
  zIndex?: number;
  onVisibleChange?: (visible: boolean) => void;
  overlayClassName?: string;
}

const Title = styled.div`
  margin: 10px 0;
  font-size: 14px;
  line-height: 14px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: ${COLORS.primary.grayDarkLight};
`;

export const Popover = ({
  title = null,
  content,
  children,
  placement = "left",
  container,
  trigger = "click",
  visible,
  zIndex = 9,
  onVisibleChange,
  overlayClassName,
}: Props) => {
  let conditionalProps = {};
  if (visible !== undefined) {
    conditionalProps = {
      visible: visible,
    };
  }
  return (
    <AntdPopover
      {...conditionalProps}
      content={content}
      title={title ? <Title>{title}</Title> : null}
      placement={placement}
      trigger={trigger}
      onVisibleChange={onVisibleChange}
      overlayStyle={{
        zIndex,
      }}
      overlayInnerStyle={{
        boxShadow: "0px 4px 7.68px rgba(7, 3, 40, 0.1)",
        borderRadius: "6px",
      }}
      getPopupContainer={() => (container as HTMLElement) || document.body}
      overlayClassName={overlayClassName}
    >
      {children}
    </AntdPopover>
  );
};
