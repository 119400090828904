import React from "react";
import styled, { css } from "styled-components";
import { COLORS } from "../../constants/colors";

interface HeaderProps {
  level?: number;
  children: React.ReactNode;
  style?: React.CSSProperties;
}

const HeaderStyles = css`
  font-weight: 600;
  color: ${COLORS.primary.grayDark};
  margin-bottom: 6px;
`;

export const H1Styles = css`
  ${HeaderStyles}
  font-size: 42px;
  line-height: 44px;
`;

const StyledH1 = styled("h1")`
  ${H1Styles}
`;

export const H2Styles = css`
  ${HeaderStyles}
  font-size: 32px;
  line-height: 36px;
`;

const StyledH2 = styled("h2")`
  ${H2Styles}
`;

export const H3Styles = css`
  ${HeaderStyles}
  font-size: 24px;
  line-height: 30px;
`;

const StyledH3 = styled("h3")`
  ${H3Styles}
`;

export const H4Styles = css`
  ${HeaderStyles}
  font-size: 20px;
  line-height: 24px;
`;

const StyledH4 = styled("h4")`
  ${H4Styles}
`;

export const H5Styles = css`
  ${HeaderStyles}
  font-size: 16px;
  line-height: 24px;
`;

const StyledH5 = styled("h5")`
  ${H5Styles}
`;

export const H6Styles = css`
  ${HeaderStyles}
  font-size: 12px;
  line-height: 16px;
`;

const StyledH6 = styled("h6")`
  ${H6Styles}
`;

const headers: Record<number, React.ComponentType> = {
  1: StyledH1,
  2: StyledH2,
  3: StyledH3,
  4: StyledH4,
  5: StyledH5,
  6: StyledH6,
};

const HeaderComponentWithChildren = ({
  children,
  component: Component,
  ...props
}: {
  children: React.ReactNode;
  style?: React.CSSProperties;
  component: any;
}) => {
  return <Component {...props}>{children}</Component>;
};

function Header({ level = 6, children, ...props }: HeaderProps) {
  const HeaderComponent = headers[level];
  return (
    <HeaderComponentWithChildren component={HeaderComponent} {...props}>
      {children}
    </HeaderComponentWithChildren>
  );
}

export default Header;
