import * as React from "react";

const SvgOnchainIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.159 5.499l-.91-.81a.75.75 0 10-.997 1.122l.773.687.103-.091.068-.06c.337-.3.657-.585.963-.848zm1.15 2.984c.21.169.412.32.606.452.666.456 1.322.746 2.085.746.763 0 1.42-.29 2.085-.746.64-.437 1.36-1.078 2.25-1.869l.039-.034 1.374-1.221a.75.75 0 10-.996-1.122l-1.375 1.222c-.937.833-1.587 1.41-2.138 1.786-.534.365-.893.484-1.239.484-.346 0-.705-.12-1.239-.484a7.57 7.57 0 01-.3-.217c-.322.268-.696.597-1.153 1.003z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.692 5.517c-.212-.169-.413-.32-.607-.453C8.42 4.61 7.763 4.32 7 4.32c-.763 0-1.42.29-2.085.745-.64.437-1.36 1.079-2.25 1.87l-.039.033L1.252 8.19a.75.75 0 10.996 1.121L3.623 8.09c.937-.833 1.587-1.41 2.138-1.786.534-.365.893-.484 1.239-.484.346 0 .705.12 1.239.484.097.066.196.138.3.217.322-.268.696-.597 1.153-1.003zm2.283 1.985l-.103.09-.068.061c-.337.3-.657.585-.963.848l.91.81a.75.75 0 00.997-1.122l-.773-.687z"
      fill="currentColor"
    />
  </svg>
);

export default SvgOnchainIcon;
