import * as React from "react";

const SvgCrossCircleIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="1em" height="1em" {...props}>
    <path
      fill="currentColor"
      d="M12 23.5A11.5 11.5 0 10.5 12 11.513 11.513 0 0012 23.5zM7.05 8.465a1 1 0 111.414-1.414l3.359 3.359a.25.25 0 00.353 0l3.359-3.359a1 1 0 111.414 1.414l-3.359 3.359a.25.25 0 000 .353l3.359 3.359a1 1 0 11-1.414 1.414l-3.359-3.359a.25.25 0 00-.353 0L8.464 16.95a1 1 0 01-1.414-1.414l3.359-3.359a.25.25 0 000-.353z"
    />
  </svg>
);

export default SvgCrossCircleIcon;
