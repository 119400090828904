import * as React from "react";

const SvgExpandCollapseArrowThin = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.824 2.176a.6.6 0 010 .85L5 6.85 1.176 3.026a.6.6 0 11.85-.85L5 5.15l2.974-2.974a.6.6 0 01.85 0z"
      fill="currentColor"
    />
  </svg>
);

export default SvgExpandCollapseArrowThin;
