import * as React from "react";

const SvgCircleMinusIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx={8} cy={8} r={6} stroke="currentColor" />
    <path d="M5 8h6" stroke="currentColor" />
  </svg>
);

export default SvgCircleMinusIcon;
