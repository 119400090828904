//uncomment the below line to debug re-render issues
// import "./wdyr";
import "antd/dist/antd.css";
// Include only the reset
import "instantsearch.css/themes/reset.css";
// or include the full Algolia theme
import "instantsearch.css/themes/algolia.css";
import "react-spring-bottom-sheet/dist/style.css";
import dotenv from "dotenv";

import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { SingletonHooksContainer } from "react-singleton-hook";
import { ApolloProvider } from "@apollo/client";
import * as Sentry from "@sentry/react";
import { HttpClient as HttpClientIntegration } from "@sentry/integrations";
import ReactGA from "react-ga4";

import App from "./App";
import ErrorBoundary from "./components/ErrorBoundary";
import { MixpanelContextProvider, NotificationsContextProvider, ReactQueryClientProvider } from "./contexts";
import { CurrentAccountProvider } from "./reducers/CurrentAccount";
import { CurrentDaoLoginProvider } from "./reducers/DaoLogin";
import { CurrentWeb3Provider } from "./reducers/CurrentWeb3Provider";
import { WalletProvider } from "./reducers/WalletProvider";
import { SearchDropdownStateProvider } from "./reducers/ProjectSearchDropdown";
import { CurrentUuidProvider } from "./reducers/CurrentUuid";
import { ProtocolOverviewContainerRefProvider } from "./reducers/ProtocolOverviewContainerRef";
import { CastVoteRefProvider } from "./reducers/CastVoteRef";
import { CurrentResultRefProvider } from "./reducers/CurrentResultRef";
import { ShowFilter } from "./reducers/ShowMobileFilter";
import { GlobalSearchStateProvider } from "./reducers/GlobalSearch";
import { GlobalSearchMobileStateProvider } from "./reducers/GlobalSearchMobile";
import {
  DelegateFilterProvider,
  DelegateFrameworkFilterProvider,
  DelegateTagFilterProvider,
} from "./reducers/DelegateFilter";
import client from "./utils/cyberConnect";
import { ScrolledHeightProvider } from "./reducers/ScrolledHeight";
import { MobileGlobalMenuProvider } from "./reducers/MobileGlobalMenu";
import { CurrentHasVisitedDashboardProvider } from "./reducers/CurrentHasVisitedDashboard";
import { NotesProvider } from "./reducers/Comments";
import { CurrentSubscriptionProvider } from "./reducers/Subscription";
import { CurrentTxHashProvider } from "./reducers/CurrentTxHash";
import { IsLandingPageProvider } from "./reducers/isLandingPage";
import { CurrentProjectsFilterProvider } from "./reducers/CurrentProjectsFilter";
import { CurrentRefIdProvider } from "./reducers/CurrentRefId";
import { CurrentPopoverOpenProvider } from "./reducers/PopoverOpen";
import { CurrentSplitViewIndexProvider } from "./reducers/CurrentSplitViewIndex";
import { IsSidebarExpandedProvider } from "./reducers/IsSidebarExpanded";
import { CurrentSubscriptionDataProvider } from "./reducers/SubscriptionData";
import { WalletDrawerProvider } from "./reducers/WalletDrawer";
import { ClaimedNFTProvider } from "./reducers/ClaimedNFT";
import { CurrentUserDetailsProvider } from "./reducers/CurrentUserDetails";
import { VotingPowerCardIsLoadingProvider } from "./reducers/VotingPowerCard";
import { NftSubscriptionProvider } from "./reducers/NftSubscription";
import { DismissedDiscussionsProvider } from "./reducers/DismissedDiscussions";

dotenv.config();

ReactGA.initialize("G-YTBNB8526V");

const container = document.getElementById("root");
const root = createRoot(container!);

let environment = "local";
let release = "local";

if (process.env.REACT_APP_PR_NUM) {
  environment = "prs";
  release = process.env.REACT_APP_PR_NUM;
} else if (process.env.REACT_APP_NODE_ENV === "staging") {
  environment = "staging";
  release = "staging";
} else if (process.env.REACT_APP_STANDALONE_PROTOCOL) {
  environment = "standalone";
  release = process.env.REACT_APP_STANDALONE_PROTOCOL;
} else if (process.env.REACT_APP_NODE_ENV === "production") {
  environment = "production";
  release = "production";
}

Sentry.init({
  dsn: "https://a3c2898bec624bd38da11e40aa854fae@o1317787.ingest.sentry.io/6582091",
  integrations: [new Sentry.BrowserTracing(), new HttpClientIntegration()],
  environment,
  release,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

root.render(
  <MixpanelContextProvider>
    <NotificationsContextProvider>
      <NftSubscriptionProvider>
        <CurrentWeb3Provider>
          <WalletProvider>
            <CurrentAccountProvider>
              <CurrentUserDetailsProvider>
                <CurrentDaoLoginProvider>
                  <CurrentUuidProvider>
                    <ReactQueryClientProvider>
                      <ApolloProvider client={client}>
                        <ErrorBoundary>
                          <SearchDropdownStateProvider>
                            <GlobalSearchStateProvider>
                              <GlobalSearchMobileStateProvider>
                                <CurrentHasVisitedDashboardProvider>
                                  <ProtocolOverviewContainerRefProvider>
                                    <CurrentPopoverOpenProvider>
                                      <CastVoteRefProvider>
                                        <CurrentResultRefProvider>
                                          <DelegateFilterProvider>
                                            <DelegateFrameworkFilterProvider>
                                              <ShowFilter>
                                                <MobileGlobalMenuProvider>
                                                  <ScrolledHeightProvider>
                                                    <BrowserRouter>
                                                      <DelegateTagFilterProvider>
                                                        <NotesProvider>
                                                          <IsSidebarExpandedProvider>
                                                            <CurrentSubscriptionProvider>
                                                              <CurrentProjectsFilterProvider>
                                                                <CurrentRefIdProvider>
                                                                  <CurrentSplitViewIndexProvider>
                                                                    <IsLandingPageProvider>
                                                                      <CurrentTxHashProvider>
                                                                        <CurrentSubscriptionDataProvider>
                                                                          <WalletDrawerProvider>
                                                                            <ClaimedNFTProvider>
                                                                              <VotingPowerCardIsLoadingProvider>
                                                                                <DismissedDiscussionsProvider>
                                                                                  <App />
                                                                                </DismissedDiscussionsProvider>
                                                                              </VotingPowerCardIsLoadingProvider>
                                                                            </ClaimedNFTProvider>
                                                                          </WalletDrawerProvider>
                                                                        </CurrentSubscriptionDataProvider>
                                                                      </CurrentTxHashProvider>
                                                                    </IsLandingPageProvider>
                                                                  </CurrentSplitViewIndexProvider>
                                                                </CurrentRefIdProvider>
                                                              </CurrentProjectsFilterProvider>
                                                            </CurrentSubscriptionProvider>
                                                          </IsSidebarExpandedProvider>
                                                        </NotesProvider>
                                                      </DelegateTagFilterProvider>
                                                    </BrowserRouter>
                                                  </ScrolledHeightProvider>
                                                </MobileGlobalMenuProvider>
                                              </ShowFilter>
                                            </DelegateFrameworkFilterProvider>
                                          </DelegateFilterProvider>
                                        </CurrentResultRefProvider>
                                      </CastVoteRefProvider>
                                    </CurrentPopoverOpenProvider>
                                  </ProtocolOverviewContainerRefProvider>
                                </CurrentHasVisitedDashboardProvider>
                              </GlobalSearchMobileStateProvider>
                            </GlobalSearchStateProvider>
                          </SearchDropdownStateProvider>
                          <SingletonHooksContainer />
                        </ErrorBoundary>
                      </ApolloProvider>
                    </ReactQueryClientProvider>
                  </CurrentUuidProvider>
                </CurrentDaoLoginProvider>
              </CurrentUserDetailsProvider>
            </CurrentAccountProvider>
          </WalletProvider>
        </CurrentWeb3Provider>
      </NftSubscriptionProvider>
    </NotificationsContextProvider>
  </MixpanelContextProvider>,
);
