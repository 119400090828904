import React, { useReducer, createContext } from "react";

interface Props {
  children: React.ReactNode;
}

type Action = { type: "SAVE_HAS_VISITED_DASHBOARD"; data: boolean };

interface CurrentHasVisitedDashboardContextType {
  hasVisitedDashboard: boolean;
  dispatchHasVisitedDashboard: React.Dispatch<any>;
}

const initialState = false;

export const CurrentHasVisitedDashboardContext = createContext({} as CurrentHasVisitedDashboardContextType);

const reducer = (state: boolean, action: Action) => {
  switch (action.type) {
    case "SAVE_HAS_VISITED_DASHBOARD":
      return action.data;
    default:
      return state;
  }
};

export const CurrentHasVisitedDashboardProvider = (props: Props) => {
  const [hasVisitedDashboard, dispatchHasVisitedDashboard] = useReducer(reducer, initialState);

  return (
    <CurrentHasVisitedDashboardContext.Provider value={{ hasVisitedDashboard, dispatchHasVisitedDashboard }}>
      {props.children}
    </CurrentHasVisitedDashboardContext.Provider>
  );
};
