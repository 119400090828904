//
import { useQuery } from "@tanstack/react-query";
import { apiClient } from "../utils/apiClient";
import { GetTotalDelegatedTokensForProtocolResponse } from "@boardroom/boardroom-api";
import { aaveAdapterProtocols } from "../constants/protocols";

interface Props {
  protocol: string;
  adapter?: string;
}

export const useGetTotalDelegatedToken = ({ protocol, adapter = "onchain" }: Props) => {
  const adapterToUse =
    adapter === "onchain" && aaveAdapterProtocols.includes(protocol)
      ? { adapters: JSON.stringify(["onchain", "onchain-secondary"]) as any as string[] }
      : { adapter };
  const { data: totalDelegatedToken } = useQuery<GetTotalDelegatedTokensForProtocolResponse, Error>(
    [`totalDelegatedToken:${protocol}:${adapter}`],
    () =>
      apiClient.getTotalDelegatedTokensForProtocol(protocol, {
        protocol,
        ...adapterToUse,
      }),
    { enabled: !!protocol && !!adapter },
  );

  return { totalVotePower: totalDelegatedToken?.data };
};
