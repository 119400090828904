import * as React from "react";

const SvgLightBulbIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.765.735a.375.375 0 010 .53l-.5.5a.375.375 0 01-.53-.53l.5-.5a.375.375 0 01.53 0zM1.235.735a.375.375 0 01.53 0l.5.5a.375.375 0 11-.53.53l-.5-.5a.375.375 0 010-.53zM9.735 7.235a.375.375 0 01.53 0l.5.5a.375.375 0 01-.53.53l-.5-.5a.375.375 0 010-.53zM2.265 7.235a.375.375 0 010 .53l-.5.5a.375.375 0 01-.53-.53l.5-.5a.375.375 0 01.53 0zM4.125 9c0-.207.168-.375.375-.375h3a.375.375 0 110 .75h-3A.375.375 0 014.125 9zM4.625 10.5c0-.207.168-.375.375-.375h2a.375.375 0 010 .75H5a.375.375 0 01-.375-.375zM3.496 1.894c.593-.512 1.448-.769 2.504-.769s1.91.257 2.504.77c.602.519.884 1.258.87 2.11-.012.848-.295 1.446-.858 2.01v-.001L8.25 5.75l.265.265c-.26.26-.4.404-.492.59-.088.177-.148.424-.148.895a.375.375 0 01-.375.375h-3a.375.375 0 01-.375-.375c0-.471-.06-.718-.148-.895-.093-.186-.233-.33-.492-.59-.564-.564-.847-1.162-.86-2.01-.013-.852.27-1.591.87-2.11zm.49.568c-.404.35-.622.86-.611 1.532.01.64.204 1.055.64 1.49l.021.022c.233.233.46.459.612.764.12.241.188.513.214.855h2.276c.026-.342.093-.614.214-.855.153-.305.378-.53.611-.764l.021-.02v-.001c.437-.436.63-.851.64-1.49.012-.673-.206-1.184-.61-1.533C7.6 2.105 6.944 1.875 6 1.875c-.944 0-1.601.23-2.014.587z"
      fill="currentColor"
    />
  </svg>
);

export default SvgLightBulbIcon;
