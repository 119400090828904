import { useQuery } from "@tanstack/react-query";
import { GetAlchemyTransactionDataResponse } from "@boardroom/boardroom-api";

import { apiClient } from "../utils/apiClient";
import { sdk } from "../constants/protocols";

export const useTransactionVoteStatus = (txnHash: string, protocol: string) => {
  const data = useQuery<GetAlchemyTransactionDataResponse, Error>([`${txnHash}-${protocol}`], async () => {
    const chainId = await sdk?.getProtocol(protocol).adapter("vote", "onchain").getChainId();
    return apiClient.getTransactionByHash({ txnHashes: [txnHash], chainId });
  }, {
    enabled: !!txnHash && !!protocol,
    suspense: false,
    refetchInterval: 30000,
  });

  return data;
};
