import React from "react";
import Typography from "antd/es/typography";
import { Moment } from "moment";
import styled from "styled-components";

import { NavigationControl } from "../Monthly/NavigationControl";
import { COLORS } from "../../../constants/colors";
import media from "../../../media-query";

const { Title } = Typography;

interface Props {
  date: Moment;
  goToPreviousMonth: () => void;
  goToNextMonth: () => void;
}

const Container = styled.div`
  width: 100%;
`;

const NavigationControlContainer = styled.div`
  margin: 0;
  display: flex;
  align-items: center;
`;

const Month = styled(Title)`
  && {
    margin: 0;
    padding: 0 0 0 24px;
    font-weight: 300;
    font-size: 24px;
    line-height: 24px;
    text-align: center;
    color: ${COLORS.primary.grayDark};
    ${media.lessThan("640px")`
      line-height: 32px;
    `}
  }
`;

const FirstLineWrapper = styled("div")`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 40px;
`;

const Header = ({ date, goToPreviousMonth, goToNextMonth }: Props) => {
  return (
    <Container>
      <FirstLineWrapper>
        <NavigationControlContainer>
          <NavigationControl type="prev" onClick={goToPreviousMonth} />
          <NavigationControl type="next" onClick={goToNextMonth} />
          <Month level={4}>{date.format("MMMM YYYY")}</Month>
        </NavigationControlContainer>
      </FirstLineWrapper>
    </Container>
  );
};

export default React.memo(Header);
