import React, { useContext } from "react";
import ErrorBoundary from "./ErrorBoundary";
import { useMixpanel } from "../../hooks";
import { CurrentAccountContext } from "../../reducers/CurrentAccount";

interface Props {
  children: React.ReactNode;
}

export default ({ children }: Props) => {
  const { trackUncaughtAppError } = useMixpanel();
  const { account } = useContext(CurrentAccountContext);

  return <ErrorBoundary trackError={() => trackUncaughtAppError({ userId: `${account}` })}>{children}</ErrorBoundary>;
};
