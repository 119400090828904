import { useLocation, useParams } from "react-router-dom";
import { protocols, getProtocolCnameFromPath } from "../constants/protocols";

interface Params {
  protocol?: string;
}

export const useCurrentProtocol__Deprecated = () => {
  const { protocol } = useParams<Params>();

  const query = new URLSearchParams(useLocation().search);
  const protocolQuery = query.get("protocol");
  const cname = getProtocolCnameFromPath(protocol || protocolQuery || "");

  return protocols[cname];
};
