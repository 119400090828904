import React from "react";
import AntdForm, { FormInstance } from "antd/es/form";

interface Props {
  name: string;
  form: FormInstance;
  children: React.ReactNode;
  initialValues?: Object;
  onSubmit: (data: Object) => void;
  onValuesChange?: (changedValues: any, values: Object) => void;
}

const Form = ({ name, form, children, initialValues = {}, onSubmit, onValuesChange }: Props) => {
  return (
    <AntdForm
      onValuesChange={onValuesChange}
      name={name}
      form={form}
      initialValues={initialValues}
      requiredMark={false}
      onFinish={onSubmit}
    >
      {children}
    </AntdForm>
  );
};

export default React.memo(Form);
