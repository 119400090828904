import Drawer from "antd/es/drawer";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

import { COLORS } from "../../../constants/colors";
import mediaQuery from "../../../media-query";
import { LogOutIcon } from "../../icons";
import { pageHeaderHeight } from "../../../constants/styles";

export const DayInfo = styled.div`
  margin: 16px 0 8px;
`;

export const ListType = styled.span`
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #7b7893;
`;

export const Today = styled.span`
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  color: #191540;
  text-transform: uppercase;
`;

export const TodayWrapper = styled.div`
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background: #f0eff8;
  box-shadow: 0px 2px 6px 0px rgba(25, 21, 64, 0.05);
  padding: 8px 0 8px 16px;
`;

export const EventsCount = styled.span`
  font-weight: 300;
  font-size: 14px;
  line-height: 14px;
  color: #7b7893;
`;

export const EventsWrapper = styled.div<{ $isToday?: boolean }>`
  background: #ffffff;
  border-radius: 8px;
  ${({ $isToday }) =>
    $isToday &&
    "box-shadow: 0px 2px 6px 0px rgba(25, 21, 64, 0.05); border-top-left-radius: 0; border-top-right-radius: 0;"}
`;

export const SeeMoreText = styled.span<{ $isPastEvents?: boolean }>`
  font-family: Inter;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
  color: #7b7893;
  cursor: pointer;
  display: block;
  margin: 16px 0;
  padding-bottom: 16px;
  :hover {
    color: #4235e1;
  }
  :active,
  :focus {
    color: #291fa7;
  }
  ${mediaQuery.lessThan("large")`
    text-align: center;
  `}
`;

export const MonthContainer = styled.div`
  padding-bottom: 40px;
`;

export const EventColoredMarker = styled.div<{
  $color?: string;
  $isTodayAndIsLastItem?: boolean;
  $isTodayAndIsFirstItem?: boolean;
}>`
  width: 4px;
  background: ${({ $color }) => $color};
  flex-shrink: 0;
  ${({ $isTodayAndIsLastItem }) => $isTodayAndIsLastItem && "border-bottom-left-radius: 2px;"}
  ${({ $isTodayAndIsFirstItem }) => $isTodayAndIsFirstItem && "border-top-left-radius: 2px;"}
`;

export const Event = styled.div`
  display: flex;
  cursor: pointer;

  ${mediaQuery.lessThan("640px")`
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
    display: none;
    }
  `}
`;

export const EventPadding = styled.div<{
  $lastItem?: boolean;
  $firstItem?: boolean;
  $lastItemBorderBottomWidth?: number;
  $isActive?: boolean;
}>`
  padding: 12px 8px;
  display: flex;
  width: 100%;
  ${({ $lastItem, $lastItemBorderBottomWidth }) =>
    !$lastItem && `border-bottom: ${$lastItemBorderBottomWidth || "0.5"}px solid #f0eff8;`}
  ${({ $firstItem }) => !$firstItem && "border-top: 0.5px solid #f0eff8;"}
  ${({ $isActive }) =>
    $isActive &&
    "background: linear-gradient(89.99deg, #FFFFFF 2.03%, #F0EFF8 54.1%, #FFFFFF 99.99%); box-shadow: 0px 4px 4px rgb(0 0 0 / 15%);"}
    ${mediaQuery.lessThan("991px")`
      padding: 12px 8px 0;
    `}
`;

export const EventMetaInfo = styled.div`
  display: flex;
  ${mediaQuery.lessThan("991px")`
      align-items: center;
    `}
`;

export const EventDate = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 16px;
  justify-content: center;
  margin-left: 8px;
  text-align: right;
  ${mediaQuery.lessThan("640px")`
    margin-right: 12px;
  `}
`;

export const EventTitle = styled.span<{ $isActive?: boolean; $isHovered?: boolean; $width: number; $voted?: boolean }>`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${({ $voted }) => ($voted ? "#7b7893" : "#191540")};
  max-width: 28rem;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${({ $isActive }) => $isActive && "font-weight: 500; color: #4235e1;"}}
  :hover {
    font-weight: 500;
    color: #4235e1;
  }
  ${mediaQuery.greaterThan("1800px")`
  max-width: 24rem;
  `}
  ${mediaQuery.greaterThan("2000px")`
    max-width: 28rem;
  `}
  ${mediaQuery.lessThan("1400px")`
    max-width: 21rem;
  `}
  ${mediaQuery.lessThan("1100px")`
    max-width: 17rem;
  `}
  ${mediaQuery.lessThan("640px")`
    max-width: 12rem;
    line-height: 16px;
  `}
  ${mediaQuery.lessThan("500px")`
    max-width: 11rem;
  `}
  ${mediaQuery.lessThan("375px")`
    max-width: 10rem;
  `}
  ${mediaQuery.lessThan("320px")`
    max-width: 7rem;
  `}
  ${({ $isHovered }) => $isHovered && "max-width: 20rem !important;"}
  ${({ $isHovered, $width }) => $isHovered && $width <= 1400 && "max-width: 16rem !important;"}
  ${({ $isHovered, $width }) => $isHovered && $width <= 1300 && "max-width: 12rem !important;"}
  `;

export const Divider = styled.div`
  height: 1px;
  width: 100%;
  background: #f0eff8;
  margin-bottom: 22px;
`;

export const EventProtocolAndType = styled.div<{ $color?: string; $inPast?: boolean; $voted?: boolean }>`
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  color: ${({ $color, $inPast, $voted }) => ($inPast || $voted ? "#7B7893" : $color)};
  display: flex;
  align-items: center;
`;

export const EventHour = styled.span<{ $inPast?: boolean; $voted?: boolean }>`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${({ $inPast, $voted }) => ($inPast || $voted ? "#7B7893" : "#4235e1")};
`;

export const EventDateValue = styled.span`
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  color: #7b7893;
  white-space: nowrap;
`;

export const ThreeDotMenuWrapper = styled.div<{ $isOpen: boolean; $width: number }>`
  display: flex;
  align-items: center;
  padding-left: 20px;
  color: ${({ $width, $isOpen }) => ($width <= 640 ? ($isOpen ? "#4235e1" : "#7b7893") : "#f0eff8")};
  margin: 8px 16px 8px 16px;
  border-left: 1px solid #f0eff8;
  ${mediaQuery.lessThan("640px")`
  border-left: 0;
  padding-left: 0;
  margin-right: 8px
  `}
`;

export const FlexItem = styled.div<{ $svgMarginRight?: string }>`
  display: flex;
  align-items: center;
  color: ${COLORS.primary.grayDarkLightest};
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  svg {
    margin-right: ${({ $svgMarginRight }) => $svgMarginRight || "8px"};
  }
  &:hover {
    color: ${COLORS.primary.accent};
    svg {
      color: ${COLORS.primary.accent};
    }
  }
`;

export const StyledLogOutIcon = styled(LogOutIcon)`
  transform: rotate(-90deg);
`;

export const FlexDiv = styled.div<{ $customMargins?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  ${({ $customMargins }) => $customMargins && "margin-top: -10px; margin-bottom: 12px;"}
`;

export const DefaultFlexDiv = styled.div`
  display: flex;
  align-items: center;
`;

export const StackedRow = styled.div`
  display: flex;
`;

export const TopText = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  color: #7b7893;
  padding: 3px 0 0 0;
  margin: auto 0;
  display: flex;
  > :nth-child(1) {
    padding-right: 5px;
  }
  > :nth-child(3) {
    padding-left: 5px;
  }
  ${mediaQuery.lessThan("640px")`
   font-size: 10px;
  `}
`;

export const StyledTimeAgo = styled.span<{ $isCanceled?: boolean }>`
  ${({ $isCanceled }) => $isCanceled && "text-decoration: line-through;"}
`;

export const StatusContainer = styled.div`
  display: flex;
  span {
    padding: 2px 10px;
    line-height: 16px;
  }
  ${mediaQuery.lessThan("large")`
    display: none;
  `}
`;

export const PopoverMask = styled.div`
  position: absolute;
  inset: 0;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.05);
  pointer-events: auto;
`;

export const PopoverWrapper = styled.div`
  position: fixed;
  inset: 0;
  z-index: 1000;
  pointer-events: none;
`;

export const PopoverContentWrapper = styled.div<{ $top: string }>`
  padding-top: 8px;
  position: absolute;
  z-index: 1000;
  background: #fff;
  right: 40px;
  border-radius: 6px;
  top: ${({ $top }) => $top};
  pointer-events: all;
  width: 200px;
`;

export const StyledPopover = styled.div``;

export const DropdownItemText = styled.span`
  font-weight: 300;
  font-size: 14px;
  line-height: 28px;
  color: #191540;
  margin-left: 10px;
`;

export const GoVoteButton = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  color: #4235e1;
  background: #ffffff;
  border-top: 1px solid #f0eff8;
  padding: 8px 0;
  border-radius: 0px 0px 6px 6px;
  margin-top: 8px;
  span {
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    margin-left: 4px;
  }
  :hover {
    color: #ffffff;
    background: linear-gradient(89.88deg, #9e97f3 0.06%, #4235e1 99.9%);
  }
  :active {
    color: #ffffff;
    background: #291fa7;
  }
`;

export const VoteButton = styled.div`
  padding: 8px 0;
  margin-top: 8px;
  border-top: 1px solid #f0eff8;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  color: #191540;
  :hover {
    color: #4235e1;
  }
  :active {
    color: #291fa7;
  }
`;

export const VoteButtonMainText = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;
`;

export const VoteButtonDescriptionText = styled.span`
  font-weight: 300;
  font-size: 14px;
  line-height: 28px;
`;

export const DropdownItem = styled.div`
  padding: 8px 16px;
  display: flex;
  align-items: center;
`;

export const StyledDrawer = styled(Drawer)`
  .ant-drawer-content {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  .ant-drawer-header {
    padding: 16px;
    padding-bottom: 0;
    .ant-drawer-header-title {
      .ant-drawer-close {
        margin-right: 0;
        background: #fafafa;
        border-radius: 12px;
        padding: 4px;
        font-size: 12px;
      }
    }
  }
`;

export const SplitViewDrawer = styled(Drawer)<{
  $isTeamView?: boolean;
  $isWalletDrawerOpen?: boolean;
  $width?: number;
}>`
  .ant-drawer-mask {
    display: none;
  }
  .ant-drawer-body {
    padding: 0;
  }
  .ant-drawer-content {
    max-width: 738px;
  }
  .ant-drawer-content-wrapper {
    box-shadow: none !important;
    border-left: 1px solid ${COLORS.primary.grayLighter};
    border-top: 1px solid ${COLORS.primary.grayLighter};
    background: #fff;
  }
  .ant-drawer-header-close-only {
    display: none;
  }
  width: calc(55% - 120px) !important;
  height: calc(100% - ${pageHeaderHeight});
  top: ${pageHeaderHeight};
  right: ${({ $isWalletDrawerOpen }) => ($isWalletDrawerOpen ? "calc(33% - 28px)" : "0")};
  ${mediaQuery.greaterThan("1600px")`
    width: calc(55% - 200px) !important;
  `}
  ${mediaQuery.greaterThan("1800px")`
    width: calc(55% - 280px) !important;
  `}
  ${mediaQuery.greaterThan("2200px")`
    width: calc(55% - 380px) !important;
  `}
  ${mediaQuery.lessThan("991px")`
    width: 100% !important;
  `}
  ${({ $isWalletDrawerOpen, $width }) =>
    $isWalletDrawerOpen &&
    css`
      ${mediaQuery.greaterThan("2000px")`
    width: calc(55% - 430px) !important;
  `}
      ${mediaQuery.greaterThan("2200px")`
    width: calc(55% - ${((($width || 2200) - 2200) / 200) * 110 + 535}px) !important;
  `}
    `}
`;

export const SplitViewBottomNavigationWrapper = styled.div`
  display: flex;
  position: absolute;
  bottom: 0;
  background: #ffffff;
  border-top: 1px solid #f0eff8;
  height: 48px;
  width: calc(100% - 48px);
  margin: 0 24px;
  z-index: 13;
`;

export const SplitViewHeaderWrapper = styled.div<{ $isExpanded?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  padding: 16px 32px;
  height: 64px;
  position: absolute;
  z-index: 5;
  width: 100%;
  top: 0;
  ${({ $isExpanded }) => $isExpanded && "border-bottom: 1px solid #F0EFF8;"}
  ${mediaQuery.lessThan("991px")`
    padding: 16px 12px;
  `}
`;

export const CloseSplitViewWrapper = styled.div`
  border-radius: 12px;
  cursor: pointer;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 88px;
  svg {
    color: #7b7893;
    width: 14px;
    height: 14px;
  }
  :hover {
    background: #fafafa;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.08);
    svg {
      color: #4235e1;
    }
  }
  :active,
  :focus {
    background: #fafafa;
    svg {
      color: #291fa7;
    }
  }
`;

export const SplitViewHeaderNavigationWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const NextProposalHeaderWrapper = styled.div<{ $type: string }>`
  border-radius: 12px;
  margin-right: 32px;
  transform: ${({ $type }) => ($type === "previous" ? "rotate(0deg);" : "rotate(180deg);")};
  cursor: pointer;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    color: #7b7893;
    width: 24px;
    height: 24px;
  }
  :hover {
    background: #fafafa;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.08);
    svg {
      color: #4235e1;
    }
  }
  :active,
  :focus {
    background: #fafafa;
    svg {
      color: #291fa7;
    }
  }
`;

export const BottomNavigationItemWrapper = styled.div<{ $type?: string }>`
  color: #7b7893;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  cursor: pointer;
  ${({ $type }) => $type === "previous" && "border-right: 1px solid rgb(66, 53, 225, 0.1);"}
  svg {
    width: 24px;
    height: 24px;
    color: #7b7893;
    margin-right: 10px;
    transform: ${({ $type }) => ($type === "previous" ? "rotate(0);" : "rotate(-180deg);")};
  }
  :hover {
    color: #4235e1;
    font-weight: 500;
    svg {
      color: #4235e1;
    }
  }
  :active,
  :focus {
    font-weight: 400;
    color: #291fa7;
    background: #fafaff;
    svg {
      color: #291fa7;
    }
  }
`;

export const SplitViewProtocolDropdownToggleWrapper = styled.div<{ $isExpanded?: boolean }>`
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  display: flex;
  align-items: center;
  color: #191540;
  cursor: pointer;
  justify-content: center;
  span {
    margin-left: 8px;
    margin-right: 4px;
  }
  svg {
    ${({ $isExpanded }) => $isExpanded && "transform: rotate(180deg);"}
  }
  ${mediaQuery.lessThan("991px")`
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    justify-content: flex-start;
    .ant-avatar {
      flex-shrink: 0;
    }
  `}
`;

export const ProtocolPageLinksWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 16px;
  align-items: center;
  padding: 16px 48px;
  position: absolute;
  top: 64px;
  background: #fff;
  width: 100%;
  z-index: 4;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.15);
`;

export const ProtocolPageLink = styled(Link)`
  background: #ffffff;
  border: 1px solid rgba(123, 120, 147, 0.2);
  border-radius: 20px;
  padding: 0px 12px;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: rgba(25, 21, 64, 0.4);
`;

export const SummaryTitle = styled.div`
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
`;

export const SplitViewTabsWrapper = styled.div`
  display: flex;
  width: calc(100% - 48px);
  align-items: center;
  border-bottom: 1px solid #f0eff8;
  padding: 20px 0 8px;
  margin: 0 24px;
`;

export const SplitViewTab = styled.div<{ $isActive?: boolean }>`
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: #7b7893;
  margin-right: 20px;
  cursor: pointer;
  ${({ $isActive }) =>
    $isActive &&
    `
      font-weight: 500;
      color: #191540;
      &:after {
        display: block;
        border-bottom: 4px solid ${COLORS.primary.accent};
        content: "";
        margin-bottom: -10px;
        height: 10px;
  }
  `}
`;

export const SplitViewContentWrapper = styled.div`
  padding: 0 24px;
  position: relative;
`;

export const NotesCount = styled.span`
  font-weight: 200;
  font-size: 18px;
  line-height: 28px;
  color: #7b7893;
`;

export const ShareText = styled.span`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #4235e1;
  margin-left: 6px;
`;

export const MobileProtocolName = styled.span`
  color: ${COLORS.primary.grayDarkLightest};
  font-size: 10px;
  font-weight: 400;
  line-height: 16px;
`;

export const RedCircle = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: ${COLORS.secondary.red};
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  ${mediaQuery.lessThan("500px")`
    left: -20px !important;
  `}
`;
