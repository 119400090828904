import React from "react";
import styled from "styled-components";
import { useHover } from "use-events";

import { COLORS } from "../../constants/colors";
import { ExpandCollapseArrowThin } from "../icons";
import media from "../../media-query";

interface Props {
  children: React.ReactNode;
  isExpanded: boolean;
  onToggle: () => void;
  totalRows: number;
  seeMoreAsText?: boolean;
  addDivider?: boolean;
  seeMoreComponent?: React.ReactNode;
  cutoffIndex?: number;
}

interface RowProps {
  children: any;
  cutoffIndex: number;
  isExpanded: boolean;
  index: number;
}

const ExpandCollapseDiv = styled.div`
  text-align: center;
`;

const ResultsExpandCollapseIcon = styled(ExpandCollapseArrowThin)<{ $isExpanded: boolean }>`
  margin-left: auto;
  margin-bottom: 20px;
  transform: rotate(${({ $isExpanded }) => ($isExpanded ? "180deg" : "0deg")});
  transition: transform 0.2s;
  cursor: pointer;
`;

const SeeMoreText = styled.span`
  font-family: Inter;
  display: block
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: ${COLORS.primary.accent};
  margin-top: 24px;
  margin-bottom: 100px;
  cursor: pointer;
  ${media.lessThan("large")`
    text-align: center;
  `}
`;

const Divider = styled("div")`
  width: 100%;
  border-top: 1px solid #f0eff8;
  margin-bottom: 52px;
  margin-top: 32px;
`;

function ExpandCollapseRows({
  children,
  isExpanded,
  onToggle,
  totalRows,
  seeMoreAsText,
  addDivider,
  seeMoreComponent,
  cutoffIndex,
}: Props) {
  const [isRssultsExpandButtonHovered, resultsExpandButtonBind] = useHover();

  return (
    <>
      {children}
      {totalRows > (cutoffIndex || 3) && (
        <ExpandCollapseDiv>
          {seeMoreComponent ? (
            seeMoreComponent
          ) : seeMoreAsText ? (
            <SeeMoreText onClick={onToggle}>{isExpanded ? "see less" : "see more"}</SeeMoreText>
          ) : (
            <ResultsExpandCollapseIcon
              $isExpanded={isExpanded}
              width={12}
              height={12}
              color={isRssultsExpandButtonHovered ? COLORS.primary.accent : COLORS.primary.accentDarker}
              onClick={onToggle}
              {...resultsExpandButtonBind}
            />
          )}
        </ExpandCollapseDiv>
      )}
      {addDivider && <Divider />}
    </>
  );
}

function Row({ children, index, cutoffIndex, isExpanded }: RowProps) {
  if (index >= cutoffIndex && !isExpanded) {
    return <></>;
  }

  return children || null;
}

ExpandCollapseRows.Row = Row;

export default ExpandCollapseRows;
