import { register0xGov as register0xGovTest } from "@boardroom/gov-protocols/dist/testProtocols/0xgov-test";

import { protocols } from "@boardroom/gov-protocols";
import dotenv from "dotenv";

dotenv.config();

const protocol = process.env.REACT_APP_STANDALONE_PROTOCOL;

let protocolsToImport = protocols;

if (protocol == "0xgov-test") {
  protocolsToImport = [...protocols, register0xGovTest];
}

export const exportedProtocols = protocolsToImport;
export const allTestProtocols = [register0xGovTest];
