import React from "react";
import { Suspense, useEffect } from "react";
import { useMixpanel } from "../../hooks";
import { useCurrentProtocol } from "../../hooks/useCurrentProtocol";

interface Props {
  name: string;
  protocol?: string;
  briefName?: string;
}

const TrackPageView = ({ name }: Props) => {
  const { trackPageView } = useMixpanel();
  const protocol = useCurrentProtocol();

  useEffect(() => {
    trackPageView({
      page: name,
      protocol: protocol?.cname,
    });
  }, [name, protocol, trackPageView]);

  return null;
};

export default (props: Props) => (
  <Suspense fallback={null}>
    <TrackPageView {...props} />
  </Suspense>
);
