import React from "react";
import styled, { css } from "styled-components";
import { COLORS } from "../../../constants/colors";

interface Props {
  date: string;
  isToday: boolean;
  isSelected: boolean;
  isInView: boolean;
  isCompact?: boolean;
  isInFirstRow?: boolean;
}

const getColor = ({
  isToday,
  isSelected,
  isInView,
  isCompact,
}: Pick<Props, "isToday" | "isSelected" | "isInView" | "isCompact">) => {
  if (!isInView) {
    return COLORS.primary.gray;
  }

  if ((isToday && !isCompact) || isSelected) {
    return COLORS.primary.grayLightest;
  }

  return COLORS.primary.grayDarkLight;
};

const Container = styled.div<Pick<Props, "isToday" | "isSelected" | "isInView" | "isCompact" | "isInFirstRow">>`
  ${({ isCompact, isToday, isInView }) =>
    !isCompact &&
    css`
      position: absolute;
      top: 6px;
      left: ${isToday && isInView ? "8px" : "12px"};
      width: ${isToday && isInView ? "30px" : "auto"};
      height: 30px;
    `}
  ${({ isCompact }) =>
    isCompact &&
    css`
      height: 100%;
      width: 100%;
    `}

  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  letter-spacing: -1px;
  color: ${getColor};
  background: ${({ isToday, isInView, isCompact }) =>
    isToday && isInView && !isCompact ? COLORS.primary.accent : "none"};
  border-radius: 50%;
  padding-top: ${({ isCompact, isInFirstRow }) => (isCompact && isInFirstRow ? "0px" : "0px")};
`;

export const Date = ({ date, isToday, isSelected, isInView, isCompact, isInFirstRow }: Props) => (
  <Container
    isToday={isToday}
    isSelected={isSelected}
    isInView={isInView}
    isCompact={isCompact}
    isInFirstRow={isInFirstRow}
  >
    {date}
  </Container>
);
