import React from "react";
import Row from "antd/es/row";
import Col from "antd/es/col";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { COLORS } from "../../constants/colors";
import { DelegateIcon } from "../icons";
import { Text } from "../Typography";
import { formatEns, toShortAddress } from "../../utils";
import { useUserDetails } from "../../hooks/useUserDetails";
import { useGetEns } from "../../hooks/useEns";
import { Pfp } from "../Pfp/Pfp";

const StyledRow = styled(Row)`
  flex-wrap: nowrap;
`;

const ImgWrapper = styled.div`
  margin-right: 12px;
  position: relative;
`;

const StyledDelegateIcon = styled(DelegateIcon)`
  position: absolute;
  color: #ffffff;
  top: 20px;
  right: -2px;
  background: ${COLORS.primary.accent};
  border: 1px solid #ffffff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  padding: 2px;
`;

const StyledText = styled(Text).attrs({
  className: "voter-name",
})<{ fontWeight?: number; fontSize?: string }>`
  display: inline-block;
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "20px")};
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : 300)};
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 12rem;
  &:hover {
    color: ${COLORS.primary.accent};
  }
`;

const LightText = styled("div")`
  font-weight: 300;
  font-size: 11px;
  line-height: 24px;
  color: ${COLORS.primary.grayDarkLightest};
  margin-top: -4px;
`;

interface Props {
  delegate: string;
  twitterVerified?: boolean;
}

const DelegateColumn = ({ delegate, twitterVerified = false }: Props) => {
  const address = delegate;
  const { user: userDetails } = useUserDetails({ address, suspense: false });
  const ens = useGetEns(address);
  const shortAddress = String(toShortAddress(address));

  return (
    <>
      <StyledRow justify="start">
        <Col>
          <ImgWrapper>
            <Pfp size="medium" address={address} dimension={40} pfpUrl={userDetails?.pfpUrl} />
            {twitterVerified && <StyledDelegateIcon />}
          </ImgWrapper>
        </Col>
        <Col>
          <Link to={`/voter/${address}`}>
            <StyledText fontWeight={500} fontSize="18px">
              {userDetails?.username || formatEns(ens) || shortAddress}
            </StyledText>
          </Link>
          <LightText></LightText>
        </Col>
      </StyledRow>
    </>
  );
};
export default DelegateColumn;
