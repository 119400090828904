import React, { useState, useCallback, useRef, useMemo } from "react";
import styled from "styled-components";
import AntdCalendar from "antd/es/calendar";
import moment, { Moment } from "moment";

import Header from "./Header";
import { WeekdayHeading } from "./WeekdayHeading";
import DateCell from "./DateCell";
import { getEventsByDate } from "../../../utils";
import { dateKeyFormat } from "../../../constants/date";
import { COLORS } from "../../../constants/colors";
import { CalendarEvents } from "../../../types";
import { isDateToday } from "../utils";

interface Props {
  events?: CalendarEvents;
  proposalEvents?: CalendarEvents;
  isSelectable?: boolean;
  onSelect?: (date: Moment) => void;
  isCompact?: boolean;
  showBorder?: boolean;
  showHeader?: boolean;
}

const Container = styled.div<Pick<Props, "isSelectable" | "isCompact" | "showBorder">>`
  user-select: none;

  && .ant-picker-body {
    padding: 0;
    background: inherit;
    border: ${(props) => props.showBorder && `1px solid ${COLORS.primary.grayLight}`};
    border-radius: 12px;
    border-top-left-radius: ${({ isCompact }) => (isCompact ? "0" : "12px")};
    border-top-right-radius: ${({ isCompact }) => (isCompact ? "0" : "12px")};
    overflow: hidden;
  }

  .ant-picker-content thead {
    display: none;
  }

  .ant-picker-content td {
    border: 1px solid ${COLORS.primary.grayLight};
  }

  .ant-picker-content tr:first-child td {
    border-top: 0;
  }

  .ant-picker-content tr th:first-child,
  .ant-picker-content tr td:first-child {
    border-left: 0;
  }

  .ant-picker-content tr th:last-child,
  .ant-picker-content tr td:last-child {
    border-right: 0;
  }

  .ant-picker-content tr:last-child td {
    border-bottom: 0;
  }
`;

const Calendar = ({
  events = {},
  proposalEvents = {},
  isSelectable = false,
  onSelect,
  isCompact = false,
  showBorder = true,
  showHeader = true,
}: Props) => {
  const [selectedDate, setSelectedDate] = useState(isSelectable ? moment() : moment().startOf("month"));
  const containerElement = useRef<HTMLDivElement>(null);

  const selectDate = useCallback(
    (date: Moment) => {
      setSelectedDate(moment(date));

      if (isSelectable && onSelect) {
        onSelect(date);
      }
    },
    [isSelectable, onSelect, setSelectedDate],
  );

  const goToPreviousMonth = useCallback(() => {
    setSelectedDate(moment(selectedDate).startOf("month").subtract(1, "month"));
  }, [selectedDate, setSelectedDate]);

  const goToNextMonth = useCallback(() => {
    setSelectedDate(moment(selectedDate).startOf("month").add(1, "month"));
  }, [selectedDate, setSelectedDate]);

  const isDateSelected = useCallback(
    (date: Moment) => isSelectable && moment(date).isSame(moment(selectedDate), "day"),
    [isSelectable, selectedDate],
  );

  const isDateInView = useCallback(
    (date: Moment) => moment(date).isSame(moment(selectedDate), "month"),
    [selectedDate],
  );

  const isDateInFirstRow = useCallback(
    (date: Moment) =>
      moment(date).isSame(moment(selectedDate).startOf("month"), "week") &&
      moment(date).subtract(1, "week").isSame(moment(selectedDate).subtract(1, "month"), "month"),
    [selectedDate],
  );

  const isDateDisabled = useCallback(
    (date: Moment) => {
      const dateKey = date.format(dateKeyFormat);
      const eventDates = events[dateKey];

      return (!isSelectable && eventDates?.length === 0) || !isDateInView(date);
    },
    [events, isDateInView, isSelectable],
  );

  const allEvents = useMemo(
    () => (date: moment.Moment) => {
      return [...getEventsByDate(proposalEvents, date), ...getEventsByDate(events, date)];
    },
    [proposalEvents, events],
  );

  return (
    <Container isSelectable={isSelectable} isCompact={isCompact} ref={containerElement} showBorder={showBorder}>
      <AntdCalendar
        value={selectedDate}
        fullscreen
        onSelect={selectDate}
        disabledDate={isDateDisabled}
        style={{ background: "inherit" }}
        headerRender={() =>
          showHeader && (
            <>
              <Header
                date={selectedDate}
                goToPreviousMonth={goToPreviousMonth}
                goToNextMonth={goToNextMonth}
                setSelectedDate={setSelectedDate}
              />
              {isCompact && <WeekdayHeading />}
            </>
          )
        }
        dateFullCellRender={(date) => (
          <DateCell
            date={date}
            events={allEvents(date)}
            isToday={isDateToday(date)}
            isSelected={isDateSelected(date)}
            isInView={isDateInView(date)}
            isInFirstRow={isDateInFirstRow(date)}
            isDisabled={isDateDisabled(date)}
            container={containerElement.current}
            containerWidth={containerElement.current?.offsetWidth || 0}
            isCompact={isCompact}
          />
        )}
      />
    </Container>
  );
};

export default React.memo(Calendar);
