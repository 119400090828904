import React from "react";
import styled from "styled-components";

import { Logo as BoardRoomLogo } from "../components/Logo/index";

const LogoContainer = styled.div`
  width: 155px;
  margin-left: 70px;
  margin-top: 8px;
`;

function Logo() {
  return (
    <LogoContainer>
      <BoardRoomLogo type="light" />
    </LogoContainer>
  );
}

export default Logo;
