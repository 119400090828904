import { useInfiniteQuery } from "@tanstack/react-query";
import { useMemo } from "react";

import { apiClient } from "../utils/apiClient";

interface Props {
  topicId?: string;
  protocol?: string;
  limit?: number;
  suspense?: boolean;
}

export const useDiscourseTopicPosts = ({ topicId, protocol, limit = 20, suspense = true }: Props) => {
  const { data, fetchNextPage, hasNextPage } = useInfiniteQuery(
    [`discourseTopics:${topicId}:${protocol}:${limit}`],
    async ({ pageParam: cursor }) => {
      try {
        const returnedData = await apiClient.getDiscourseTopicPosts({
          topicId,
          limit,
          protocol,
          ...(cursor ? { cursor } : {}),
        });
        return returnedData;
      } catch (error) {
        console.error(error);
      }
    },
    {
      getNextPageParam: (params) => params?.nextCursor,
      suspense,
      enabled: !!topicId,
    },
  );

  const discourseTopicPosts = useMemo(
    () =>
      data?.pages
        .map((page: any) => page?.data)
        .flat()
        .filter(Boolean) || [],
    [data],
  );

  return { discourseTopicPosts, fetchNextPage, hasNextPage };
};
