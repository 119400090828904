import makeBlockie from "ethereum-blockies-base64";
import React from "react";
import ContentLoader from "react-content-loader";
import styled from "styled-components";

interface Props {
  seed?: string | null;
  size?: string;
  dimension?: number;
  border?: string;
  marginTop?: string;
  shape?: "circle" | "square";
  addOutline?: boolean;
}

const sizes: Record<string, string> = {
  large: "100px",
  medium: "30px",
  small: "16px",
  tiny: "10px",
};

const CircleImg = styled.img`
  border-radius: 50%;
`;

const SquareImg = styled.img<{ $addOutline?: boolean }>`
  border-radius: 4px;
  ${(props) =>
    props.$addOutline &&
    `
  outline: 1px solid white;
  outline-offset: -2px;
  `}
`;

function Blockie({ seed, size = "medium", dimension, border, marginTop, shape, addOutline }: Props) {
  if (!seed) {
    const radius = (dimension || parseInt(sizes[size])) / 2;
    return (
      <ContentLoader
        speed={2}
        width={dimension || sizes[size]}
        height={dimension || sizes[size]}
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
      >
        <circle cx={radius} cy={radius} r={radius} />
      </ContentLoader>
    );
  }

  if (shape === "square") {
    return (
      <SquareImg
        height={dimension || sizes[size]}
        src={makeBlockie(seed)}
        width={dimension || sizes[size]}
        style={{ border, marginTop: marginTop || "0" }}
        $addOutline={addOutline}
        className="blockie"
      />
    );
  }

  return (
    <CircleImg
      height={dimension || sizes[size]}
      src={makeBlockie(seed)}
      width={dimension || sizes[size]}
      style={{ border, marginTop: marginTop || "0" }}
      className="blockie"
    />
  );
}

export default React.memo(Blockie);
