import React, { useReducer, createContext } from "react";

interface Props {
  children: React.ReactNode;
}

type Action = { type: "TOGGLE_STATE"; data: boolean };

interface GlobalSearchMobileContextType {
  globalSearchMobileState: boolean;
  dispatchGlobalSearchMobileState: React.Dispatch<any>;
}

const initialState = false;

export const GlobalSearchMobileContext = createContext({} as GlobalSearchMobileContextType);

const reducer = (state: boolean, action: Action) => {
  switch (action.type) {
    case "TOGGLE_STATE":
      return action.data;
    default:
      return state;
  }
};

export const GlobalSearchMobileStateProvider = (props: Props) => {
  const [globalSearchMobileState, dispatchGlobalSearchMobileState] = useReducer(reducer, initialState);

  return (
    <GlobalSearchMobileContext.Provider value={{ globalSearchMobileState, dispatchGlobalSearchMobileState }}>
      {props.children}
    </GlobalSearchMobileContext.Provider>
  );
};
