import React, { Suspense, useCallback, useContext, useMemo, useRef, useState } from "react";
import ContentLoader from "react-content-loader";
import { DiscourseTopicsData } from "@boardroom/boardroom-api";
import moment from "moment";
import styled from "styled-components";
import notification from "antd/es/notification";
import axios from "axios";
import { useQueryClient } from "@tanstack/react-query";

import { useCurrentRefId } from "../../reducers/CurrentRefId";
import { COLORS } from "../../constants/colors";
import { ThinCrossIcon, ArrowIcon, ThinChevronUpIcon } from "../icons";
import { protocols } from "../../constants/protocols";
import ProtocolIcon from "../ProtocolIcon";
import { eventTypeColorCoding, eventTypeColorCodingText, isDateToday } from "../Calendar/utils";
import { CalendarEvent } from "../../types";
import {
  BottomNavigationItemWrapper,
  CloseSplitViewWrapper,
  Event,
  EventColoredMarker,
  EventDate,
  EventDateValue,
  EventHour,
  EventMetaInfo,
  EventPadding,
  EventProtocolAndType,
  EventTitle,
  FlexDiv,
  NextProposalHeaderWrapper,
  ProtocolPageLink,
  ProtocolPageLinksWrapper,
  SplitViewBottomNavigationWrapper,
  SplitViewHeaderNavigationWrapper,
  SplitViewHeaderWrapper,
  SplitViewProtocolDropdownToggleWrapper,
} from "../Calendar/ListView/styles";
import { useWindowDimensions } from "../../hooks/useWindowDimensions";
import { useDelegatesByProtocol } from "../../hooks/useDelegatesByProtocol";
import { useCurrentSplitViewIndex } from "../../reducers/CurrentSplitViewIndex";
import { ProtocolTopicDrawer } from "../../pages/ProtocolTopic";
import { EventRowSuspense } from "../Calendar/ListView/EventRow";
import { useDiscourseTopics } from "../../hooks/useDiscourseTopics";
import { BOARDROOM_API_KEY, baseAPIUrl } from "../../constants/general";
import { CurrentAccountContext } from "../../reducers/CurrentAccount";
import { CurrentUuidContext } from "../../reducers/CurrentUuid";
import { useSiweFunction } from "../../hooks/useSiweFunction";
import { DismissedDiscussionsContext } from "../../reducers/DismissedDiscussions";

const DismissWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  cursor: pointer;
  margin-right: 16px;
`;

interface EventRowProps {
  event: CalendarEvent;
  discourseData?: DiscourseTopicsData[];
  isLastItem?: boolean;
  isFirstItem?: boolean;
  lastItemBorderWidth?: number;
  isToday?: boolean;
  discourseEvents: CalendarEvent[];
  isDismissible?: boolean;
}

export const SplitViewContent = ({
  discourseEvents,
  discourseTopic,
}: {
  discourseEvents: CalendarEvent[];
  discourseTopic: DiscourseTopicsData;
}) => {
  const protocolCname = discourseTopic.protocol;
  const [isSplitViewHeaderExpanded, setIsSplitViewHeaderExpanded] = useState(false);
  const { currentRefId, setCurrentRefId } = useCurrentRefId();

  const { setCurrentSplitViewIndex, currentSplitViewIndex } = useCurrentSplitViewIndex();

  const { discourseTopics } = useDiscourseTopics({
    suspense: false,
    protocols: [protocolCname || ""],
  });

  const { delegates } = useDelegatesByProtocol({
    protocol: protocolCname || "",
    limit: 24,
    suspense: false,
  });

  const handleClickNextProposal = useCallback(() => {
    if (discourseEvents) {
      if (currentSplitViewIndex) {
        const nextProposal = discourseEvents[currentSplitViewIndex + 1];
        setCurrentRefId(nextProposal?.proposalRefId || "");
        setCurrentSplitViewIndex(currentSplitViewIndex + 1);
      } else {
        const currProposalIndex = discourseEvents.findIndex((event) => event.proposalRefId === currentRefId);
        const nextProposal = discourseEvents[currProposalIndex + 1];
        setCurrentRefId(nextProposal?.proposalRefId || "");
        setCurrentSplitViewIndex(currProposalIndex + 1);
      }
    }
  }, [currentRefId, setCurrentRefId, discourseEvents, currentSplitViewIndex, setCurrentSplitViewIndex]);

  const handleClickPreviousProposal = useCallback(() => {
    if (discourseEvents) {
      if (currentSplitViewIndex) {
        const previousProposal = discourseEvents[currentSplitViewIndex - 1];
        setCurrentRefId(previousProposal?.proposalRefId || "");
        setCurrentSplitViewIndex(currentSplitViewIndex - 1);
      } else {
        const currProposalIndex = discourseEvents.findIndex((event) => event.proposalRefId === currentRefId);
        const previousProposal = discourseEvents[currProposalIndex - 1];
        setCurrentRefId(previousProposal?.proposalRefId || "");
        setCurrentSplitViewIndex(currProposalIndex - 1);
      }
    }
  }, [currentRefId, setCurrentRefId, discourseEvents, setCurrentSplitViewIndex, currentSplitViewIndex]);

  const handleClose = useCallback(() => {
    setCurrentRefId("");
    setCurrentSplitViewIndex(undefined);
  }, [setCurrentRefId, setCurrentSplitViewIndex]);

  const toggleSplitViewHeader = useCallback(() => {
    setIsSplitViewHeaderExpanded((curr) => !curr);
  }, []);

  return (
    <div>
      <SplitViewHeaderWrapper $isExpanded={isSplitViewHeaderExpanded}>
        <SplitViewHeaderNavigationWrapper>
          <NextProposalHeaderWrapper onClick={handleClickPreviousProposal} $type="previous">
            <ThinChevronUpIcon />
          </NextProposalHeaderWrapper>
          <NextProposalHeaderWrapper onClick={handleClickNextProposal} $type="next">
            <ThinChevronUpIcon />
          </NextProposalHeaderWrapper>
        </SplitViewHeaderNavigationWrapper>
        <div style={{ width: "100%" }}>
          <SplitViewProtocolDropdownToggleWrapper
            onClick={toggleSplitViewHeader}
            $isExpanded={isSplitViewHeaderExpanded}
          >
            <ProtocolIcon size="small" protocol={protocols[protocolCname]} />
            <span>{protocols[protocolCname]?.name}</span>
            <ArrowIcon width={14} height={14} color="#4235e1" />
          </SplitViewProtocolDropdownToggleWrapper>
        </div>
        <div style={{ width: "112px" }}>
          <CloseSplitViewWrapper onClick={handleClose}>
            <ThinCrossIcon />
          </CloseSplitViewWrapper>
        </div>
      </SplitViewHeaderWrapper>
      {isSplitViewHeaderExpanded && (
        <ProtocolPageLinksWrapper>
          <ProtocolPageLink target="_blank" to={`/${protocols[protocolCname]?.path}/proposals`}>
            Governance
          </ProtocolPageLink>
          {!!discourseTopics?.length && (
            <ProtocolPageLink target="_blank" to={`/${protocols[protocolCname]?.path}/discussions`}>
              Discussions
            </ProtocolPageLink>
          )}
          <ProtocolPageLink
            target="_blank"
            to={
              delegates?.length
                ? `/${protocols[protocolCname]?.path}/delegates`
                : `/${protocols[protocolCname]?.path}/voters`
            }
          >
            Members
          </ProtocolPageLink>
          <ProtocolPageLink target="_blank" to={`/${protocols[protocolCname]?.path}/overview`}>
            Overview
          </ProtocolPageLink>
        </ProtocolPageLinksWrapper>
      )}
      <div style={{ marginTop: isSplitViewHeaderExpanded ? "122px" : "64px" }} />
      <div style={{ marginTop: "24px" }} />
      <Suspense
        fallback={
          <div style={{ margin: "24px" }}>
            <ContentLoader
              speed={2}
              width="100%"
              height={402}
              backgroundColor={COLORS.primary.grayLight}
              foregroundColor={COLORS.primary.grayLighter}
            >
              <rect x="0" y="10" rx="5" ry="5" width="100%" height="12" />
              <rect x="0" y="34" rx="5" ry="5" width="70%" height="12" />

              <rect x="0" y="75" rx="5" ry="5" width="100%" height="12" />
              <rect x="0" y="99" rx="5" ry="5" width="100%" height="12" />
              <rect x="0" y="123" rx="5" ry="5" width="100%" height="12" />
              <rect x="0" y="147" rx="5" ry="5" width="20%" height="12" />

              <rect x="0" y="188" rx="5" ry="5" width="100%" height="12" />
              <rect x="0" y="212" rx="5" ry="5" width="100%" height="12" />
              <rect x="0" y="236" rx="5" ry="5" width="40%" height="12" />

              <rect x="0" y="277" rx="5" ry="5" width="100%" height="12" />
              <rect x="0" y="301" rx="5" ry="5" width="25%" height="12" />

              <rect x="0" y="342" rx="5" ry="5" width="100%" height="12" />
              <rect x="0" y="366" rx="5" ry="5" width="100%" height="12" />
              <rect x="0" y="390" rx="5" ry="5" width="70%" height="12" />
            </ContentLoader>
          </div>
        }
      >
        <ProtocolTopicDrawer topicData={discourseTopic} />
      </Suspense>
      <div style={{ marginTop: "96px" }} />
      <SplitViewBottomNavigationWrapper>
        <BottomNavigationItemWrapper $type="previous" onClick={handleClickPreviousProposal}>
          <ThinChevronUpIcon />
          <span>Previous Discussion</span>
        </BottomNavigationItemWrapper>
        <BottomNavigationItemWrapper onClick={handleClickNextProposal}>
          <ThinChevronUpIcon />
          <span>Next Discussion</span>
        </BottomNavigationItemWrapper>
      </SplitViewBottomNavigationWrapper>
    </div>
  );
};

const EventRow = ({
  event,
  isLastItem,
  isFirstItem,
  lastItemBorderWidth,
  discourseEvents,
  isDismissible,
}: EventRowProps) => {
  const { account } = useContext(CurrentAccountContext);
  const { dispatchDismissedDiscussions } = useContext(DismissedDiscussionsContext);
  const { uuid } = useContext(CurrentUuidContext);
  const eventRef = useRef<HTMLDivElement | null>(null);
  const siweFunction = useSiweFunction();

  const { currentRefId, setCurrentRefId } = useCurrentRefId();
  const { setCurrentSplitViewIndex } = useCurrentSplitViewIndex();
  const { width } = useWindowDimensions();
  const queryClient = useQueryClient();

  const eventIndex = discourseEvents?.findIndex(
    (eventInArray) => eventInArray.proposalRefId === event.proposalRefId && eventInArray.type === event.type,
  );

  const handleClick = useCallback(() => {
    setCurrentRefId(event.proposalRefId || "");
    setCurrentSplitViewIndex(eventIndex > -1 ? eventIndex : undefined);
  }, [event.proposalRefId, setCurrentRefId, eventIndex, setCurrentSplitViewIndex]);

  const isActive = useMemo(() => currentRefId === event.proposalRefId, [currentRefId, event.proposalRefId]);

  const dismiss = useCallback(
    async (e: any) => {
      e.stopPropagation();
      if (!isDismissible) return;
      if (!account || !uuid) {
        notification.error({
          message: "Error",
          description: "You need to sign in with wallet to update your alerts",
        });
        siweFunction();
        return;
      }
      try {
        dispatchDismissedDiscussions({ type: "SAVE_DISMISSED_DISCUSSIONS", data: event.id });
        await axios.post(`${baseAPIUrl}discourse/dismissDiscourseKeywordMatch?key=${BOARDROOM_API_KEY}`, {
          address: account,
          uuid,
          id: event.id,
        });
        queryClient.invalidateQueries(["getKeywordMatchesByAddress", account]);
        notification.success({
          message: "Success",
          description: "Alert deleted successfully",
        });
      } catch (e) {
        notification.error({
          message: "Error",
          description: "Error deleting alert",
        });
      }
    },
    [account, dispatchDismissedDiscussions, event.id, isDismissible, queryClient, siweFunction, uuid],
  );

  return (
    <>
      <Event ref={eventRef} key={event.id} onClick={handleClick}>
        <EventColoredMarker
          $isTodayAndIsFirstItem={isDateToday(moment(event.date)) && isFirstItem}
          $isTodayAndIsLastItem={isDateToday(moment(event.date)) && isLastItem}
          $color={eventTypeColorCoding[event.type]}
        />
        <EventPadding
          $isActive={isActive}
          $lastItem={isLastItem}
          $firstItem={isFirstItem}
          $lastItemBorderBottomWidth={lastItemBorderWidth}
        >
          <EventMetaInfo>
            <ProtocolIcon protocol={protocols[event.protocolCname]} />
            <EventDate>
              <EventHour>{moment(event.date).format("HH:mm")}</EventHour>
              <EventDateValue>{moment(event.date).format("MMM DD")?.toUpperCase()}</EventDateValue>
            </EventDate>
          </EventMetaInfo>
          <FlexDiv>
            <div className="opacityForArchiving">
              <EventTitle $width={width} $isActive={isActive}>
                {event.title}
              </EventTitle>
              <EventProtocolAndType $color={eventTypeColorCodingText[event.type]}>
                {protocols[event.protocolCname]?.name} ·{" "}
                {event?.keyword ? `Key word: ${event?.keyword}` : "Discourse Discussion"}
              </EventProtocolAndType>
            </div>
          </FlexDiv>
          {isDismissible && (
            <DismissWrapper onClick={dismiss}>
              <ThinCrossIcon />
            </DismissWrapper>
          )}
        </EventPadding>
      </Event>
    </>
  );
};

const SuspendedEventRow = (props: EventRowProps) => {
  return (
    <Suspense fallback={<EventRowSuspense />}>
      <EventRow {...props} />
    </Suspense>
  );
};

export default SuspendedEventRow;
