import React, { useReducer, createContext } from "react";

interface Props {
  children: React.ReactNode;
}

interface TxHash {
  txHash: string;
  protocolName: string;
}

type Action = { type: "SAVE_TXHASH"; data: TxHash | null };

interface CurrentTxHashContextType {
  txHash: TxHash | null;
  dispatchTxHash: React.Dispatch<any>;
}

const initialState = null;

export const CurrentTxHashContext = createContext({} as CurrentTxHashContextType);

const reducer = (state: TxHash | null, action: Action) => {
  switch (action.type) {
    case "SAVE_TXHASH":
      return action.data;
    default:
      return state;
  }
};

export const CurrentTxHashProvider = (props: Props) => {
  const [txHash, dispatchTxHash] = useReducer(reducer, initialState);

  return (
    <CurrentTxHashContext.Provider value={{ txHash, dispatchTxHash }}>{props.children}</CurrentTxHashContext.Provider>
  );
};
